import React from 'react';
import { toast } from "sonner";
import { Button } from "@/components/ui/button";
import { createRoot } from 'react-dom/client';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription
} from "@/components/ui/dialog";
import { VersionContext } from "@/contexts/VersionContext";

interface VersionToastProps {
  version: string;
  releaseNotes?: string;
  forceRefresh: boolean;
}

function VersionToast({ version, releaseNotes, forceRefresh }: VersionToastProps) {
  const handleUpdate = () => {
    // Update stored version before reload
    VersionContext.updateCurrentVersion(version);
    window.location.reload();
  };

  if (forceRefresh) {
    return (
      <Dialog open={true}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Application Update Required</DialogTitle>
            <DialogDescription className="sr-only">
              A new version of the application is available. Please refresh to continue.
            </DialogDescription>
          </DialogHeader>
          <div className="space-y-4">
            <p>Version {version} is now available.</p>
            {releaseNotes && (
              <div className="max-h-[300px] overflow-y-auto">
                <p className="text-sm">{releaseNotes}</p>
              </div>
            )}
            <Button onClick={handleUpdate} className="w-full">
              Update Now
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <div className="grid gap-1">
      <div className="font-semibold">New Version Available</div>
      <div className="text-sm text-muted-foreground">
        Version {version} is available. Refresh when convenient.
      </div>
      {releaseNotes && (
        <div className="text-sm text-muted-foreground">{releaseNotes}</div>
      )}
      <Button
        variant="secondary"
        size="sm"
        onClick={handleUpdate}
        className="mt-2"
      >
        Update Now
      </Button>
    </div>
  );
}

export function showVersionToast(props: VersionToastProps) {
  if (props.forceRefresh) {
    const container = document.createElement('div');
    document.body.appendChild(container);
    const root = createRoot(container);
    root.render(<VersionToast {...props} />);
    return () => {
      root.unmount();
      document.body.removeChild(container);
    };
  } else {
    toast(<VersionToast {...props} />, {
      duration: 0, // Stay until dismissed
      className: "version-toast",
    });
  }
} 