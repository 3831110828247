import { useState, useEffect } from 'react';
import { Button } from '@/components/ui/button';
import { Textarea } from '@/components/ui/textarea';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Label } from '@/components/ui/label';
import { cn } from '@/lib/utils';
import axios from '@/api/axiosConfig';
import { format } from 'date-fns';
import { Bug, PlusCircle, AlertTriangle, GitBranch, Check, Loader2, GitCommit, Plus, ToggleLeft, ToggleRight } from 'lucide-react';
import { Input } from "@/components/ui/input";
import ReactMarkdown from 'react-markdown';
import { Switch } from "@/components/ui/switch";

const VERSION_TYPES = [
  {
    type: 'patch' as const,
    title: 'Patch Update',
    description: 'Bug fixes and minor improvements',
    icon: Bug,
    examples: ['Security fixes', 'Bug fixes', 'Performance improvements']
  },
  {
    type: 'minor' as const,
    title: 'Minor Update',
    description: 'New features (backward compatible)',
    icon: PlusCircle,
    examples: ['New features', 'Non-breaking improvements', 'UI enhancements']
  },
  {
    type: 'major' as const,
    title: 'Major Update',
    description: 'Breaking changes',
    icon: AlertTriangle,
    examples: ['API changes', 'Major UI overhaul', 'Database schema changes']
  },
  {
    type: 'milestone' as const,
    title: 'Milestone Release',
    description: 'Major version milestone',
    icon: GitBranch,
    examples: ['Complete rewrites', 'Major platform updates', 'Architectural changes']
  }
];

interface Version {
  version: string;
  version_type: 'patch' | 'minor' | 'major' | 'milestone';
  requires_refresh: boolean;
  release_notes: {
    en: string;
    da: string;
  };
  created_at: string;
  created_by: string;
  is_deployed: boolean;
}

type VersionType = 'patch' | 'minor' | 'major' | 'milestone';

export default function AdminVersionManager() {
  const [currentVersion, setCurrentVersion] = useState<string>('');
  const [nextVersion, setNextVersion] = useState<string>('');
  const [versionType, setVersionType] = useState<VersionType>('patch');
  const [releaseNotes, setReleaseNotes] = useState<{ en: string; da: string }>({ en: '', da: '' });
  const [versions, setVersions] = useState<Version[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isGeneratingNotes, setIsGeneratingNotes] = useState(false);
  const [isFirstVersion, setIsFirstVersion] = useState(true);
  const [customVersion, setCustomVersion] = useState('1.0.0.0');
  const [customComment, setCustomComment] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState<'en' | 'da'>('en');
  const [useRecentPushed, setUseRecentPushed] = useState(false);
  const [recentCommitCount, setRecentCommitCount] = useState<number>(0);
  const [unpushedCommitCount, setUnpushedCommitCount] = useState<number | undefined>(undefined);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    fetchVersions();
  }, []);

  const fetchVersions = async () => {
    try {
      const response = await axios.get<Version[]>('/version/versions');
      setVersions(response.data);
      
      if (response.data.length > 0) {
        setIsFirstVersion(false);
        const latest = response.data[0].version;
        setCurrentVersion(latest);
        calculateNextVersion(latest, 'patch');
      } else {
        setIsFirstVersion(true);
        setCurrentVersion('No versions yet');
        setNextVersion('');
      }
    } catch (error) {
      console.error('Failed to fetch versions:', error);
    }
  };

  const calculateNextVersion = (current: string, type: VersionType) => {
    const [milestone, major, minor, patch] = current.split('.').map(Number);
    
    let next = '';
    switch (type) {
      case 'milestone':
        next = `${milestone + 1}.0.0.0`;
        break;
      case 'major':
        next = `${milestone}.${major + 1}.0.0`;
        break;
      case 'minor':
        next = `${milestone}.${major}.${minor + 1}.0`;
        break;
      case 'patch':
        next = `${milestone}.${major}.${minor}.${patch + 1}`;
        break;
    }
    setNextVersion(next);
  };

  const handleVersionTypeChange = (type: VersionType) => {
    setVersionType(type);
    calculateNextVersion(currentVersion, type);
  };

  const generateReleaseNotes = async () => {
    setIsGeneratingNotes(true);
    try {
      const response = await axios.post('/version/generate-release-notes', { 
        customComment,
        useRecentPushed,
        recentCommitCount: useRecentPushed ? recentCommitCount : undefined,
        unpushedCommitCount: !useRecentPushed ? unpushedCommitCount : undefined
      });
      setReleaseNotes(response.data.releaseNotes);
      setCustomComment('');
    } catch (error) {
      console.error('Failed to generate release notes:', error);
    } finally {
      setIsGeneratingNotes(false);
    }
  };

  const handleCreateVersion = async () => {
    setIsLoading(true);
    try {
      await axios.post('/version/version', {
        version: isFirstVersion ? customVersion : nextVersion,
        version_type: versionType,
        requires_refresh: versionType === 'major' || versionType === 'milestone',
        release_notes: releaseNotes
      });
      
      // Reset form and refresh list
      setReleaseNotes({ en: '', da: '' });
      if (isFirstVersion) {
        setCustomVersion('1.0.0.0');
      }
      fetchVersions();
    } catch (error) {
      console.error('Failed to create version:', error);
      // Simple error logging without type checking
      console.error('Error details:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleDeploy = async (version: string, currentStatus: boolean) => {
    try {
      console.log('Toggling deploy for version:', version); // Debug log
      const response = await axios.post(`/version/${version}/toggle-deploy`);
      if (response.data.is_deployed !== currentStatus) {
        fetchVersions();
      }
    } catch (error) {
      console.error('Failed to toggle deployment status:', error);
    }
  };

  const markdownStyles = {
    'h3': 'text-lg font-semibold mt-4 mb-2',  // For ### headers
    'ul': 'space-y-1 mb-4',  // For bullet point lists
    'li': 'ml-4',  // For list items
    'p': 'mb-2',   // For paragraphs
    'code': 'bg-muted px-1 py-0.5 rounded text-sm font-mono',  // For `code` blocks
    'strong': 'font-semibold',  // For **bold** text
  };

  return (
    <div className="space-y-8 p-6">
      <Card>
        <CardHeader>
          <CardTitle>Create New Version</CardTitle>
          <CardDescription>
            Select update type and provide release notes for the new version
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-6">
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <div>
                <Label>Current Version</Label>
                <p className="text-2xl font-bold">{currentVersion}</p>
              </div>
              <div>
                <Label>Next Version</Label>
                {isFirstVersion ? (
                  <div className="space-y-2">
                    <Input
                      placeholder="1.0.0.0"
                      value={customVersion}
                      onChange={(e) => setCustomVersion(e.target.value)}
                      className="text-lg font-bold"
                    />
                    <p className="text-sm text-muted-foreground">
                      Enter the first version number
                    </p>
                  </div>
                ) : (
                  <>
                    <p className="text-2xl font-bold text-primary">{nextVersion}</p>
                    {(versionType === 'major' || versionType === 'milestone') && (
                      <p className="text-sm text-muted-foreground">
                        Note: This update will require users to refresh
                      </p>
                    )}
                  </>
                )}
              </div>
            </div>
            
            {!isFirstVersion && (
              <div className="space-y-2">
                <Label>Update Type</Label>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {VERSION_TYPES.map((type) => {
                    const Icon = type.icon;
                    return (
                      <button
                        key={type.type}
                        onClick={() => handleVersionTypeChange(type.type)}
                        className={cn(
                          "relative p-4 rounded-lg border-2 text-left transition-all",
                          "hover:border-primary/50 hover:shadow-md",
                          versionType === type.type 
                            ? "border-primary bg-primary/5 shadow-sm" 
                            : "border-muted bg-card"
                        )}
                      >
                        <div className="flex items-start gap-4">
                          <div className={cn(
                            "p-2 rounded-full",
                            versionType === type.type 
                              ? "bg-primary text-primary-foreground" 
                              : "bg-muted text-muted-foreground"
                          )}>
                            <Icon className="h-4 w-4" />
                          </div>
                          <div className="flex-1">
                            <h3 className="font-semibold">{type.title}</h3>
                            <p className="text-sm text-muted-foreground">
                              {type.description}
                            </p>
                            <div className="mt-2">
                              <ul className="text-sm text-muted-foreground space-y-1">
                                {type.examples.map((example, i) => (
                                  <li key={i} className="flex items-center gap-2">
                                    <span className="h-1 w-1 rounded-full bg-muted-foreground" />
                                    {example}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                          {versionType === type.type && (
                            <div className="absolute top-4 right-4 text-primary">
                              <Check className="h-4 w-4" />
                            </div>
                          )}
                        </div>
                      </button>
                    );
                  })}
                </div>
              </div>
            )}
            
            <div className="space-y-2">
              <div className="space-y-4">
                <div>
                  <div className="flex justify-between items-center mb-4">
                    <Label className="text-lg font-semibold">Release Notes</Label>
                    <div className="flex items-center gap-2">
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() => setIsEditing(!isEditing)}
                      >
                        {isEditing ? 'Preview' : 'Edit'}
                      </Button>
                      <div className="flex rounded-md overflow-hidden">
                        <Button
                          variant={selectedLanguage === 'en' ? 'default' : 'outline'}
                          size="sm"
                          onClick={() => setSelectedLanguage('en')}
                          className="rounded-r-none"
                        >
                          English
                        </Button>
                        <Button
                          variant={selectedLanguage === 'da' ? 'default' : 'outline'}
                          size="sm"
                          onClick={() => setSelectedLanguage('da')}
                          className="rounded-l-none"
                        >
                          Danish
                        </Button>
                      </div>
                    </div>
                  </div>

                  <div className="grid grid-cols-[1fr,auto] gap-4">
                    <div className="space-y-4">
                      <div className="flex items-center gap-2 p-3 rounded-lg border bg-card">
                        <Switch
                          checked={useRecentPushed}
                          onCheckedChange={setUseRecentPushed}
                        />
                        <Label>Use recent pushed commits instead of unpushed</Label>
                      </div>

                      <div className="flex items-center gap-2 p-3 rounded-lg border bg-card">
                        {useRecentPushed ? (
                          <>
                            <Input
                              type="number"
                              min={1}
                              max={50}
                              value={recentCommitCount}
                              onChange={(e) => setRecentCommitCount(Number(e.target.value))}
                              className="w-24"
                            />
                            <span className="text-sm text-muted-foreground">
                              Number of pushed commits to include
                            </span>
                          </>
                        ) : (
                          <>
                            <Input
                              type="number"
                              min={1}
                              max={50}
                              value={unpushedCommitCount || ''}
                              onChange={(e) => setUnpushedCommitCount(e.target.value ? Number(e.target.value) : undefined)}
                              className="w-24"
                              placeholder="All"
                            />
                            <span className="text-sm text-muted-foreground">
                              Number of unpushed commits to include (optional)
                            </span>
                          </>
                        )}
                      </div>

                      <div className="p-3 rounded-lg border bg-card">
                        <Textarea
                          placeholder="Add any additional context for the AI to consider when generating notes..."
                          value={customComment}
                          onChange={(e) => setCustomComment(e.target.value)}
                          rows={3}
                          className="border-none bg-transparent resize-none focus-visible:ring-0"
                        />
                      </div>
                    </div>

                    <div>
                      <Button 
                        variant="outline"
                        onClick={generateReleaseNotes}
                        disabled={isGeneratingNotes}
                        className="h-auto py-8 px-6"
                      >
                        {isGeneratingNotes ? (
                          <>
                            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                            <div className="flex flex-col items-center">
                              <span>Generating</span>
                              <span className="text-xs text-muted-foreground">Please wait...</span>
                            </div>
                          </>
                        ) : (
                          <>
                            <GitCommit className="mr-2 h-4 w-4" />
                            <div className="flex flex-col items-center">
                              <span>Generate</span>
                              <span className="text-xs text-muted-foreground">from commits</span>
                            </div>
                          </>
                        )}
                      </Button>
                    </div>
                  </div>
                </div>

                {releaseNotes[selectedLanguage] && (
                  <div className="mt-4">
                    <div className="rounded-lg border bg-card">
                      {isEditing ? (
                        <Textarea
                          value={releaseNotes[selectedLanguage]}
                          onChange={(e) => {
                            setReleaseNotes(prev => ({
                              ...prev,
                              [selectedLanguage]: e.target.value
                            }));
                          }}
                          className="min-h-[300px] font-mono text-sm p-4"
                          placeholder={`Enter ${selectedLanguage === 'en' ? 'English' : 'Danish'} release notes...`}
                        />
                      ) : (
                        <div className="prose dark:prose-invert max-w-none p-4">
                          <ReactMarkdown
                            components={{
                              h3: ({node, ...props}) => <h3 className={markdownStyles.h3} {...props}/>,
                              ul: ({node, ...props}) => <ul className={markdownStyles.ul} {...props}/>,
                              li: ({node, ...props}) => <li className={markdownStyles.li} {...props}/>,
                              p: ({node, ...props}) => <p className={markdownStyles.p} {...props}/>,
                              code: ({node, ...props}) => <code className={markdownStyles.code} {...props}/>,
                              strong: ({node, ...props}) => <strong className={markdownStyles.strong} {...props}/>,
                            }}
                          >
                            {releaseNotes[selectedLanguage]}
                          </ReactMarkdown>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <Button 
            onClick={handleCreateVersion}
            disabled={isLoading || !releaseNotes.en || !releaseNotes.da}
            className="w-full"
          >
            {isLoading ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Creating...
              </>
            ) : (
              <>
                <Plus className="mr-2 h-4 w-4" />
                Create Version
              </>
            )}
          </Button>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>Version History</CardTitle>
          <CardDescription>
            List of all versions and their deployment status
          </CardDescription>
        </CardHeader>
        <CardContent>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Version</TableHead>
                <TableHead>Type</TableHead>
                <TableHead>Release Notes</TableHead>
                <TableHead>Created</TableHead>
                <TableHead>Status</TableHead>
                <TableHead className="w-[100px]">Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {versions.map((v) => (
                <TableRow key={v.version}>
                  <TableCell className="font-medium">{v.version}</TableCell>
                  <TableCell className="capitalize">{v.version_type}</TableCell>
                  <TableCell className="max-w-md truncate">
                    {typeof v.release_notes === 'object' ? v.release_notes[selectedLanguage] : v.release_notes}
                  </TableCell>
                  <TableCell>{format(new Date(v.created_at), 'MMM d, yyyy')}</TableCell>
                  <TableCell>
                    <span className={cn(
                      "inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium",
                      v.is_deployed 
                        ? "bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-100"
                        : "bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-100"
                    )}>
                      {v.is_deployed ? 'Deployed' : 'Pending'}
                    </span>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={() => toggleDeploy(v.version, v.is_deployed)}
                    >
                      {v.is_deployed ? (
                        <ToggleRight className="h-4 w-4" />
                      ) : (
                        <ToggleLeft className="h-4 w-4" />
                      )}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </div>
  );
} 