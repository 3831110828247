import { useState } from 'react'
import { 
  ShoppingBag, 
  DollarSign, 
  Hash, 
  Calendar, 
  Clock,
  Loader2,
  PencilIcon,
  Plus
} from 'lucide-react'

import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerDescription,
} from "@/components/ui/drawer"
import { ScrollArea } from "@/components/ui/scroll-area"
import {
  Card,
  CardContent,
} from "@/components/ui/card"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip"
import { Button } from "@/components/ui/button"

import { EditProductDialog, AddProductDialog } from '@/modals/ProductDialogs'
import type { Product } from '@/types/customers'

interface ProductsDrawerProps {
  visible: boolean
  onClose: () => void
  products: Product[]
  dealId?: number
  dealName?: string
  onProductsChange?: () => void
}

export function ProductsDrawer({ 
  visible, 
  onClose, 
  products, 
  dealId,
  dealName,
  onProductsChange 
}: ProductsDrawerProps) {
  const [loading] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [addModalOpen, setAddModalOpen] = useState(false)

  const handleProductUpdated = () => {
    if (onProductsChange) {
      onProductsChange()
    }
  }

  const renderProductDetails = (product: Product) => (
    <Card key={product.id} className="min-w-[300px] max-w-[300px] shrink-0 relative">
      <Button
        variant="ghost"
        size="icon"
        className="absolute top-2 right-2 h-8 w-8"
        onClick={(e) => {
          e.stopPropagation()
          setSelectedProduct(product)
          setEditModalOpen(true)
        }}
      >
        <PencilIcon className="h-4 w-4" />
      </Button>
      <CardContent className="pt-6 space-y-3">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <h3 className="font-semibold text-lg truncate pr-8">
                {product.name}
              </h3>
            </TooltipTrigger>
            <TooltipContent>
              <p>Product Name: {product.name}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>

        <div className="grid grid-cols-1 gap-2">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <div className="flex items-center gap-2 text-sm text-muted-foreground">
                  <ShoppingBag className="h-4 w-4" />
                  <span>Code: {product.code}</span>
                </div>
              </TooltipTrigger>
              <TooltipContent>
                <p>Product Code: {product.code}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>

          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <div className="flex items-center gap-2 text-sm text-muted-foreground">
                  <DollarSign className="h-4 w-4" />
                  <span>Price: {product.item_price.toLocaleString('da-DK')} DKK</span>
                </div>
              </TooltipTrigger>
              <TooltipContent>
                <p>Price: {product.item_price.toLocaleString('da-DK')} DKK</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>

          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <div className="flex items-center gap-2 text-sm text-muted-foreground">
                  <Hash className="h-4 w-4" />
                  <span>Qty: {product.quantity}</span>
                </div>
              </TooltipTrigger>
              <TooltipContent>
                <p>Quantity: {product.quantity}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>

          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <div className="flex items-center gap-2 text-sm text-muted-foreground">
                  <DollarSign className="h-4 w-4" />
                  <span>Sum: {(product.item_price * product.quantity).toLocaleString('da-DK')} DKK</span>
                </div>
              </TooltipTrigger>
              <TooltipContent>
                <p>Sum: {(product.item_price * product.quantity).toLocaleString('da-DK')} DKK</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>

          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <div className="flex items-center gap-2 text-sm text-muted-foreground">
                  <Calendar className="h-4 w-4" />
                  <span>Start: {product.billing_start_date || 'N/A'}</span>
                </div>
              </TooltipTrigger>
              <TooltipContent>
                <p>Billing Start Date: {product.billing_start_date || 'N/A'}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>

          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <div className="flex items-center gap-2 text-sm text-muted-foreground">
                  <Clock className="h-4 w-4" />
                  <span>Freq: {product.billing_frequency}</span>
                </div>
              </TooltipTrigger>
              <TooltipContent>
                <p>Billing Frequency: {product.billing_frequency}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </CardContent>
    </Card>
  )

  return (
    <Drawer open={visible} onOpenChange={onClose}>
      <DrawerContent className="h-[50vh]">
        <DrawerHeader className="flex flex-row items-center justify-between">
          <div className="flex-shrink-0">
            <DrawerTitle className="text-primary">
              Product Details{dealName ? ` - ${dealName}` : ''}
            </DrawerTitle>
            <DrawerDescription>
              Manage product information and settings
            </DrawerDescription>
          </div>
          <div className="flex gap-2">
            {dealId && (
              <Button
                onClick={() => setAddModalOpen(true)}
                className="gap-2"
              >
                <Plus className="h-4 w-4" />
                Add Product
              </Button>
            )}
            <Button 
              variant="outline" 
              onClick={onClose}
              className="shrink-0"
            >
              Close
            </Button>
          </div>
        </DrawerHeader>

        {loading ? (
          <div className="flex-1 flex justify-center items-center">
            <Loader2 className="h-8 w-8 animate-spin" />
          </div>
        ) : (
          <ScrollArea className="w-full px-6">
            <div className="pb-6">
              {products && products.length > 0 ? (
                <div className="flex gap-4 overflow-x-auto pb-4">
                  {products.map(renderProductDetails)}
                </div>
              ) : (
                <div className="text-center py-8 text-muted-foreground">
                  No products found
                </div>
              )}
            </div>
          </ScrollArea>
        )}

        {selectedProduct && (
          <EditProductDialog
            open={editModalOpen}
            onOpenChange={setEditModalOpen}
            product={selectedProduct}
            onProductUpdated={handleProductUpdated}
          />
        )}

        {dealId && (
          <AddProductDialog
            open={addModalOpen}
            onOpenChange={setAddModalOpen}
            dealId={dealId}
            onProductAdded={handleProductUpdated}
          />
        )}
      </DrawerContent>
    </Drawer>
  )
}

export default ProductsDrawer 