import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { CustomTask } from '@/types/custom-task';
import { cn } from "@/lib/utils";
import { KANBAN_CARD_WIDTH } from "@/components/ui/specialized/kanban-card";
import type { CSSProperties } from 'react';

interface SortableTaskCardProps {
  id: string;
  task: CustomTask;
  renderContent: (task: CustomTask) => React.ReactNode;
}

export function SortableTaskCard({ id, task, renderContent }: SortableTaskCardProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: id,
    data: {
      type: 'task',
      task: task
    }
  });

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
    cursor: isDragging ? 'grabbing' : 'grab',
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className={cn(
        "mb-2 touch-manipulation select-none px-2",
        isDragging && "cursor-grabbing z-50"
      )}
    >
      {renderContent(task)}
    </div>
  );
}