import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosPublic from '@/api/axiosPublic';
import logo from '@/assets/logos/logo.png';
import { socketService } from '@/services/socket';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Loader2 } from 'lucide-react';
import { useTheme } from '@/contexts/ThemeContext';
import { VersionContext } from '@/contexts/VersionContext';

interface LoginProps {
  setUser: (user: any) => void;
}

interface LoginResponse {
  access_token: string;
  refresh_token: string;
  user: {
    user_id: string;
    settings?: {
      theme?: string;
      color_scheme?: string;
      language?: string;
      avatar_color?: string;
      desktop_notifications?: boolean;
      email_notifications?: boolean;
      auto_login?: boolean;
    };
    is_registered: boolean;
    [key: string]: any;
  };
}

export default function Login({ setUser }: LoginProps) {
  const { theme } = useTheme();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [autoLoginAttempted, setAutoLoginAttempted] = useState(false);
  const navigate = useNavigate();
  const ENABLE_AUTO_LOGIN = false;

  useEffect(() => {
    const attemptAutoLogin = async () => {
      if (!ENABLE_AUTO_LOGIN) {
        setAutoLoginAttempted(true);
        return;
      }

      if (autoLoginAttempted || sessionStorage.getItem('manualLogout') === 'true') {
        setAutoLoginAttempted(true);
        return;
      }

      const storedUser = localStorage.getItem('user');
      if (!storedUser) {
        setAutoLoginAttempted(true);
        return;
      }

      const user = JSON.parse(storedUser);
      
      if (!user.settings?.auto_login) {
        setAutoLoginAttempted(true);
        return;
      }

      try {
        setLoading(true);
        const response = await axiosPublic.post<LoginResponse>('/auth/auto-login', {
          user_id: user.user_id
        });
        
        if (response.data.access_token) {
          localStorage.setItem('token', response.data.access_token);
          localStorage.setItem('refreshToken', response.data.refresh_token);
          localStorage.setItem('user', JSON.stringify(response.data.user));
          
          try {
            await socketService.connect();
          } catch (socketError) {
            console.error('Socket connection failed:', socketError);
          }
          
          setUser(response.data.user);
          navigate(response.data.user.is_registered ? '/main-menu' : '/complete-registration');
        }
      } catch (error) {
        console.error('Auto-login failed:', error);
        localStorage.removeItem('user');
      } finally {
        setLoading(false);
        setAutoLoginAttempted(true);
      }
    };

    attemptAutoLogin();
  }, [navigate, setUser, autoLoginAttempted]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setLoading(true);
    
    try {
      // Clear only auth-related storage items
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('user');
      sessionStorage.removeItem('manualLogout');
      
      const response = await axiosPublic.post<LoginResponse>('/auth/login', { 
        email, 
        password 
      });
      
      if (response.data.access_token) {
        const { access_token, refresh_token, user } = response.data;
        
        // Get current version after successful login
        try {
          const versionResponse = await axiosPublic.get('/version/versions');
          if (versionResponse.data && versionResponse.data.length > 0) {
            const latestVersion = versionResponse.data[0];
            if (latestVersion.is_deployed) {
              VersionContext.updateCurrentVersion(latestVersion.version);
            }
          }
        } catch (versionError) {
          console.error('Failed to get initial version:', versionError);
        }

        // Store tokens before anything else
        localStorage.setItem('token', access_token);
        localStorage.setItem('refreshToken', refresh_token);
        localStorage.setItem('user', JSON.stringify(user));
        
        // If user has theme settings, apply them
        if (user.settings?.theme) {
          localStorage.setItem('theme', user.settings.theme);
        }
        if (user.settings?.color_scheme) {
          localStorage.setItem('colorScheme', user.settings.color_scheme);
        }

        // Store initial user data
        const usersData = {
          [user.user_id]: {
            user_id: user.user_id,
            name: user.name,
            surname: user.surname,
            email: user.email,
            avatar_urls: user.avatar_urls,
            settings: user.settings,
            initials: `${user.name?.[0] || ''}${user.surname?.[0] || ''}`.toUpperCase() || '?'
          }
        };
        
        localStorage.setItem('usersData', JSON.stringify(usersData));
        
        socketService.connect().catch(socketError => {
          console.error('Socket connection failed:', socketError);
        });
        
        setUser(user);
        navigate(user.is_registered ? '/main-menu' : '/complete-registration');
      } else {
        setError('An unexpected error occurred. Please try again.');
      }
    } catch (error: any) {
      console.error('Login error:', error);
      setError(error.response?.data?.error || 'An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex min-h-screen flex-col items-center justify-center bg-background p-4">
      <div className="w-full max-w-md space-y-6">
        <div className="flex flex-col items-center space-y-4">
          <img src={logo} alt="Tuesday Logo" className="h-auto w-48 rounded-3xl" />
          <h1 className="text-3xl font-bold tracking-tight text-foreground">Digi-Tal ERP</h1>
        </div>

        <Card>
          <CardHeader>
            <CardTitle className="text-center text-2xl">Sign in to your account</CardTitle>
          </CardHeader>
          <CardContent>
            <form onSubmit={handleSubmit} className="space-y-4">
              {error && (
                <div className="text-sm text-destructive">{error}</div>
              )}
              
              <div className="space-y-2">
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  type="email"
                  value={email}
                  placeholder="navn@digi-tal.dk"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>

              <div className="space-y-2">
                <Label htmlFor="password">Password</Label>
                <Input
                  id="password"
                  type="password"
                  value={password}
                  placeholder="********"
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>

              <Button 
                type="submit" 
                className="w-full" 
                disabled={loading}
              >
                {loading ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Signing in...
                  </>
                ) : (
                  'Sign in'
                )}
              </Button>
            </form>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}