import React, { useState, useEffect } from 'react'
import { Button } from '@/components/ui/button'
import { Card, CardHeader, CardContent, CardFooter } from '@/components/ui/card'
import { Input } from '@/components/ui/input'
import { Bot as BotIcon, X as CloseIcon, Send as SendIcon } from 'lucide-react'
import { cn } from '@/lib/utils'
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
} from '@/components/ui/navigation-menu'
import { Badge } from "@/components/ui/badge"
import { hasRequiredPermission } from '@/utils/permissionUtils'
import axios from '@/api/axiosConfig'
import ReactMarkdown from 'react-markdown';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip"

export interface DigiAIProps {
  isVisible: boolean
  isMaximized?: boolean
  onOpen?: () => void
  onClose?: () => void
}

interface ChatMessage {
  type: 'user' | 'ai'
  content: string
  isLoading?: boolean
}

const DIGI_AI_DISABLED = false

const contextOptions = [
  {
    title: 'Customers and tasks',
    description: 'AI will access both customer and task-related data',
    requiredPermission: 'accounting'
  },
  {
    title: 'Sales & Analytics',
    description: 'AI will focus on sales data and analytical insights',
    requiredPermission: 'admin'
  },
  {
    title: 'Notion',
    description: 'AI will access and reference Notion workspace data',
    requiredPermission: 'admin'
  },
  {
    title: 'All Data',
    description: 'AI will have access to all available data in the system',
    requiredPermission: 'admin'
  }
]

const ListItem = React.forwardRef<
  React.ElementRef<"a">,
  React.ComponentPropsWithoutRef<"a"> & { title: string; liClassName?: string }
>(({ className, title, children, ...props }, ref) => {
  return (
    <li className={props.liClassName}>
      <NavigationMenuLink asChild>
        <a
          ref={ref}
          className={cn(
            "block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
            className
          )}
          {...props}
        >
          <div className="text-sm font-medium leading-none">{title}</div>
          <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
            {children}
          </p>
        </a>
      </NavigationMenuLink>
    </li>
  )
})
ListItem.displayName = "ListItem"

const LoadingDots = () => (
  <span className="inline-flex items-center">
    <span className="animate-pulse">.</span>
    <span className="animate-pulse animation-delay-200">.</span>
    <span className="animate-pulse animation-delay-400">.</span>
  </span>
);

const DigiAI: React.FC<DigiAIProps> = ({ isVisible, isMaximized, onOpen, onClose }) => {
  const [message, setMessage] = useState('')
  const [chatHistory, setChatHistory] = useState<ChatMessage[]>([])
  const [threadId, setThreadId] = useState<string | null>(null)
  const [selectedContext, setSelectedContext] = useState('Customers and tasks')
  const [isLoading, setIsLoading] = useState(false)
  const [retryCount, setRetryCount] = useState(0)
  // Get user from localStorage
  const user = JSON.parse(localStorage.getItem('user') || '{}')
  const token = localStorage.getItem('token')

  const availableContextOptions = contextOptions.filter(option => 
    hasRequiredPermission(user?.permissions, option.requiredPermission)
  )

  useEffect(() => {
    if (availableContextOptions.length > 0) {
      // Try to find "Customers and tasks" in available options
      const customersAndTasks = availableContextOptions.find(
        option => option.title === 'Customers and tasks'
      );
      
      // Set to "Customers and tasks" if available, otherwise use first available option
      setSelectedContext(customersAndTasks ? customersAndTasks.title : availableContextOptions[0].title);
    }
  }, [user?.permissions])

  const createThread = async (): Promise<string | null> => {
    try {
      console.log('Creating new thread...')
      const response = await axios.post('/chatbot/chat/create_thread')
      
      console.log('Thread created successfully:', response.data)
      setThreadId(response.data.thread_id)
      return response.data.thread_id
    } catch (error) {
      console.error('Detailed error:', error)
      if (retryCount < 3) {
        setRetryCount(prev => prev + 1)
        return new Promise(resolve => setTimeout(() => resolve(createThread()), 1000))
      }
      return null
    }
  }

  const sendMessage = async (messageContent: string) => {
    if (!messageContent.trim() || isLoading) return;

    try {
      setIsLoading(true);
      setMessage('');
      
      // Add user message immediately
      const userMessage = { type: 'user' as const, content: messageContent };
      setChatHistory(prev => [...prev, userMessage]);
      
      // Add placeholder for AI response
      setChatHistory(prev => [...prev, { 
        type: 'ai' as const, 
        content: '', 
        isLoading: true 
      }]);

      let currentThreadId = threadId;
      if (!currentThreadId) {
        const threadResponse = await axios.post('/chatbot/chat/create_thread');
        currentThreadId = threadResponse.data.thread_id;
        setThreadId(currentThreadId);
      }

      const params = new URLSearchParams({
        message: messageContent,
        context: selectedContext,
        thread_id: currentThreadId || '',
        token: token || ''
      });

      const eventSource = new EventSource(
        `${axios.defaults.baseURL}/chatbot/chat?${params.toString()}`
      );

      let accumulatedContent = '';

      eventSource.onmessage = (event) => {
        try {
          if (event.data === '[DONE]') {
            eventSource.close();
            setIsLoading(false);
            return;
          }

          const data = JSON.parse(event.data);
          if (data.content) {
            // Update the accumulated content
            accumulatedContent = data.content;
            
            // Update the chat history with the latest content
            setChatHistory(prev => {
              const newHistory = [...prev];
              const lastMessage = newHistory[newHistory.length - 1];
              if (lastMessage && lastMessage.type === 'ai') {
                lastMessage.content = accumulatedContent;
                lastMessage.isLoading = false;
              }
              return newHistory;
            });
          }
        } catch (e) {
          console.error('Error parsing SSE message:', e);
          eventSource.close();
          setIsLoading(false);
          setChatHistory(prev => {
            const newHistory = [...prev];
            const lastMessage = newHistory[newHistory.length - 1];
            if (lastMessage && lastMessage.type === 'ai') {
              lastMessage.content = e instanceof Error ? e.message : 'Error parsing response';
              lastMessage.isLoading = false;
            }
            return newHistory;
          });
        }
      };

      eventSource.onerror = (error) => {
        console.error('EventSource error:', error);
        eventSource.close();
        setIsLoading(false);
        setChatHistory(prev => {
          const newHistory = [...prev];
          const lastMessage = newHistory[newHistory.length - 1];
          if (lastMessage && lastMessage.type === 'ai') {
            lastMessage.content = 'Connection error. Please try again.';
            lastMessage.isLoading = false;
          }
          return newHistory;
        });
      };

    } catch (error) {
      console.error('Error sending message:', error);
      setIsLoading(false);
      setChatHistory(prev => {
        const newHistory = [...prev];
        const lastMessage = newHistory[newHistory.length - 1];
        if (lastMessage && lastMessage.type === 'ai') {
          lastMessage.content = error instanceof Error ? error.message : 'An unexpected error occurred.';
          lastMessage.isLoading = false;
        }
        return newHistory;
      });
    }
  };

  const toggleAI = () => {
    if (isMaximized) {
      onClose?.()
    } else {
      onOpen?.()
    }
  }

  const handleContextChange = (context: string) => {
    setSelectedContext(context)
  }

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      sendMessage(message)
    }
  }

  return (
    <div className={cn(
      'opacity-0 pointer-events-none transition-all duration-300',
      isVisible && 'opacity-100 pointer-events-auto',
      DIGI_AI_DISABLED && 'opacity-50',
      isMaximized && 'fixed bottom-6 right-6 z-50'
    )}>
      {!isMaximized ? (
        <TooltipProvider delayDuration={100}>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="ghost"
                size="icon"
                className={cn(
                  '!w-12 !h-12 !rounded-full !bg-gradient-to-br from-[hsl(var(--digiai-gradient-from))] to-[hsl(var(--digiai-gradient-to))]',
                  'hover:translate-y-[-2px] hover:scale-105 transition-all duration-300',
                  'shadow-lg hover:shadow-blue-500/20',
                  DIGI_AI_DISABLED && 'bg-gray-300 cursor-not-allowed'
                )}
                onClick={toggleAI}
                disabled={DIGI_AI_DISABLED}
              >
                <BotIcon size={32} className="text-white transform scale-150" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>AI Assistant</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      ) : (
        <Card className="w-[380px] h-[600px] shadow-2xl flex flex-col">
          <CardHeader className="flex-row items-center justify-between py-4">
            <div className="flex items-center gap-2">
              <div className="w-2 h-2 rounded-full bg-emerald-400" />
              <h3 className="text-sm font-medium flex flex-col items-center">
                <span className="bg-gradient-to-l from-[hsl(var(--digiai-gradient-from))] to-[hsl(var(--digiai-gradient-to))] text-transparent bg-clip-text">
                  AI Assistant
                </span>
                <Badge variant="secondary" className="mt-1">
                  {selectedContext}
                </Badge>
              </h3>
            </div>
            <div className="flex items-center gap-2">
              <NavigationMenu viewportPosition="right">
                <NavigationMenuList>
                  <NavigationMenuItem>
                    <NavigationMenuTrigger>
                      Context
                    </NavigationMenuTrigger>
                    <NavigationMenuContent>
                      <ul className="grid gap-3 p-4 w-[400px] lg:w-[500px] lg:grid-cols-2">
                        <li className="row-span-3">
                          <NavigationMenuLink asChild>
                            <a className="flex h-full w-full select-none flex-col justify-end rounded-md bg-gradient-to-b from-muted/50 to-muted p-6 no-underline outline-none focus:shadow-md hover:text-primary">
                              <BotIcon className="h-6 w-6" />
                              <div className="mb-2 mt-4 text-lg font-medium">
                                AI Context
                              </div>
                              <p className="text-sm leading-tight text-muted-foreground">
                                Select which data the AI assistant should use to answer your questions.
                                {!hasRequiredPermission(user?.permissions, 'admin') && 
                                  " Some options may be restricted based on your permissions."}
                              </p>
                            </a>
                          </NavigationMenuLink>
                        </li>
                        {availableContextOptions
                          .filter(option => option.title !== 'All Data')
                          .map(option => (
                            <ListItem
                              key={option.title}
                              title={option.title}
                              onClick={() => handleContextChange(option.title)}
                              className={cn(
                                selectedContext === option.title && 'bg-accent'
                              )}
                            >
                              {option.description}
                            </ListItem>
                          ))}
                        {availableContextOptions
                          .filter(option => option.title === 'All Data')
                          .map(option => (
                            <ListItem
                              key={option.title}
                              title={option.title}
                              onClick={() => handleContextChange(option.title)}
                              liClassName="order-last lg:col-span-2 !ml-0"
                              className={cn(
                                selectedContext === option.title && 'bg-accent'
                              )}
                            >
                              {option.description}
                            </ListItem>
                          ))}
                      </ul>
                    </NavigationMenuContent>
                  </NavigationMenuItem>
                </NavigationMenuList>
              </NavigationMenu>
              <Button
                variant="ghost"
                size="icon"
                onClick={toggleAI}
              >
                <CloseIcon className="h-4 w-4" />
              </Button>
            </div>
          </CardHeader>

          <CardContent className="flex-1 overflow-y-auto">
            {chatHistory.map((msg, index) => (
              <div
                key={index}
                className={cn(
                  'flex mb-4',
                  msg.type === 'user' ? 'justify-end' : 'justify-start'
                )}
              >
                <div
                  className={cn(
                    'max-w-[80%] rounded-2xl px-4 py-2 text-sm',
                    msg.type === 'user'
                      ? 'bg-primary text-primary-foreground rounded-br-sm text-right'
                      : 'bg-muted text-muted-foreground rounded-bl-sm'
                  )}
                >
                  {msg.type === 'ai' ? (
                    <div className="ai-message prose prose-sm max-w-none dark:prose-invert whitespace-pre-line">
                      {msg.isLoading ? (
                        <LoadingDots />
                      ) : (
                        <ReactMarkdown
                          components={{
                            p: ({children}) => <p className="mb-4 whitespace-pre-line">{children}</p>,
                            ul: ({children}) => <ul className="mb-4 mt-4 space-y-2">{children}</ul>,
                            li: ({children}) => <li className="ml-4 list-disc">{children}</li>,
                            strong: ({children}) => <strong className="font-semibold">{children}</strong>,
                          }}
                        >
                          {msg.content}
                        </ReactMarkdown>
                      )}
                    </div>
                  ) : (
                    <div className="text-right">{msg.content}</div>
                  )}
                </div>
              </div>
            ))}
          </CardContent>

          <CardFooter className="p-4">
            <div className="flex gap-2 w-full">
              <Input
                placeholder="Type a message..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyPress={handleKeyPress}
                disabled={isLoading}
                className="flex-1"
              />
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button 
                    disabled
                    className="bg-gradient-to-br from-[hsl(var(--digiai-gradient-from))] to-[hsl(var(--digiai-gradient-to))] text-white hover:opacity-90"
                  >
                    <SendIcon className="h-4 w-4" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Coming soon</p>
                </TooltipContent>
              </Tooltip>
            </div>
          </CardFooter>
        </Card>
      )}
    </div>
  )
}

export { DigiAI }