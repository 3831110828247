import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CheckSquare, Plus, ChevronRight, ChevronDown, MessageSquare, Clock } from 'lucide-react';
import { Card } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import { AvatarGroup } from '@/components/ui/avatars/avatar-group';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { StoredUser } from '@/types/user';
import { CustomTask } from '@/types/custom-task';
import { Board, SubTask, Comment, Update } from '@/types/custom-board';
import { cn } from '@/lib/utils';
import CreateSubTask from '../modals/CreateSubTask';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

interface TaskViewSubtasksProps {
  task: CustomTask;
  board: Board | null;
  getAssignedUsers: (task: CustomTask | SubTask) => StoredUser[];
  onSubtaskCreated: () => void;
  onTaskUpdate?: (updatedTask: CustomTask) => void;
}

interface ActivityItem {
  id: number;
  timestamp: string;
  type: 'comment' | 'status' | 'assignees' | 'work_date' | 'due_date';
  content?: string;
  user_id: number | string;
  new_value?: any;
}

interface CommentActivityItem extends ActivityItem {
  type: 'comment';
  content: string;
}

interface UpdateActivityItem extends ActivityItem {
  type: 'status' | 'assignees' | 'work_date' | 'due_date';
  new_value: any;
}

const isLightColor = (color: string): boolean => {
  const lightColors = [
    '#ffec3d', '#fff566', '#ffd666', '#fff1b8',   // Yellows
    '#ffa39e', '#ffccc7', '#ffd8bf', '#ffe7ba',   // Light reds and oranges
    '#b7eb8f', '#d9f7be', '#f6ffed',              // Light greens
    '#91caff', '#bae0ff', '#e6f4ff',              // Light blues
    '#efdbff', '#f9f0ff', '#fdf2ff',              // Light purples
    '#ffadd2', '#ffd6e7', '#fff0f6',              // Light pinks
    '#ffffff', '#fafafa', '#f5f5f5'               // Whites and light grays
  ];
  return lightColors.includes(color.toLowerCase());
};

const getLastActivity = (subtask: SubTask): string => {
  const dates: string[] = [];
  
  // Add comment timestamps
  if (subtask.comments?.length) {
    dates.push(...subtask.comments.map(c => c.timestamp));
  }
  
  // Add update timestamps
  if (subtask.updates?.length) {
    dates.push(...subtask.updates.map(u => u.timestamp));
  }
  
  // Add creation timestamp
  if (subtask.created_at) {
    dates.push(subtask.created_at);
  }
  
  if (!dates.length) return '';
  
  // Sort dates in descending order and take the most recent
  return dates.sort((a, b) => new Date(b).getTime() - new Date(a).getTime())[0];
};

const getRecentActivities = (subtask: SubTask): ActivityItem[] => {
  const activities: (CommentActivityItem | UpdateActivityItem)[] = [];
  
  // Add comments
  if (subtask.comments?.length) {
    activities.push(...subtask.comments.map(c => ({
      id: c.id,
      timestamp: c.timestamp,
      type: 'comment' as const,
      content: c.content,
      user_id: c.user_id
    })));
  }
  
  // Add updates
  if (subtask.updates?.length) {
    const updateActivities = subtask.updates
      .map(u => {
        let activityType: UpdateActivityItem['type'] | null = null;
        
        switch (u.type) {
          case 'progress_status':
            activityType = 'status';
            break;
          case 'assigned_users':
            activityType = 'assignees';
            break;
          case 'work_date':
            activityType = 'work_date';
            break;
          case 'due_date':
            activityType = 'due_date';
            break;
        }

        if (!activityType) return null;

        const activity: UpdateActivityItem = {
          id: u.id,
          timestamp: u.timestamp,
          type: activityType,
          user_id: u.user_id,
          new_value: u.new_value
        };

        return activity;
      })
      .filter((item): item is UpdateActivityItem => item !== null);

    activities.push(...updateActivities);
  }
  
  // Sort by timestamp and take the 5 most recent
  return activities
    .sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime())
    .slice(0, 5);
};

const getRecentComments = (subtask: SubTask): Comment[] => {
  if (!subtask.comments?.length) return [];
  
  return [...subtask.comments]
    .sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime())
    .slice(0, 3);
};

export function TaskViewSubtasks({ task, board, getAssignedUsers, onSubtaskCreated, onTaskUpdate }: TaskViewSubtasksProps) {
  const navigate = useNavigate();
  const subtasks = task.subtasks || [];
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);

  const getStatusColor = (subtask: SubTask, status: string): string => {
    if (!board?.subtask_config?.predefined_subtasks) return '#1890ff';

    const predefinedSubtask = board.subtask_config.predefined_subtasks.find(
      config => config.name === subtask.title
    );

    const statusOption = predefinedSubtask?.status_options?.find(opt => opt.id.toString() === status);
    return statusOption?.color || '#1890ff';
  };

  const getStatusLabel = (subtask: SubTask, status: string): string => {
    if (!board?.subtask_config?.predefined_subtasks) return status;

    const predefinedSubtask = board.subtask_config.predefined_subtasks.find(
      config => config.name === subtask.title
    );

    const statusOption = predefinedSubtask?.status_options?.find(opt => opt.id.toString() === status);
    return statusOption?.label || status;
  };

  const handleSubtaskClick = (subtask: SubTask) => {
    navigate(`/custom-boards/${board?.id}/task/${task.id}/subtask/${subtask.id}`);
  };

  const getSubtaskWithProgress = (subtask: any): SubTask => ({
    ...subtask,
    progress_status_id: Number(subtask.progress_status_id) || 0,
    created_by: subtask.created_by || 0,
    created_at: subtask.created_at || new Date().toISOString(),
    updated_at: subtask.updated_at || new Date().toISOString(),
  });

  if (!board?.board_config?.enable_subtasks) {
    return null;
  }

  return (
    <div className="w-full bg-background">
      <div 
        className="flex items-center justify-between p-3 cursor-pointer hover:bg-muted/50 transition-colors border-t"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div className="flex items-center gap-2">
          <CheckSquare className="h-4 w-4" />
          <h3 className="text-sm font-medium">Subtasks ({subtasks.length})</h3>
          {isExpanded ? (
            <ChevronDown className="h-4 w-4" />
          ) : (
            <ChevronRight className="h-4 w-4" />
          )}
        </div>
        <Button
          variant="ghost"
          size="sm"
          onClick={(e) => {
            e.stopPropagation();
            setIsCreateModalOpen(true);
          }}
          className="h-8"
        >
          <Plus className="h-4 w-4 mr-1" />
          Add
        </Button>
      </div>

      <div className={cn(
        "overflow-hidden transition-all duration-200",
        isExpanded ? "h-auto" : "h-0"
      )}>
        <div className="p-3 space-y-1">
          {subtasks.length > 0 ? (
            <div className="space-y-1">
              {subtasks.map((subtask: SubTask) => {
                const fullSubtask = getSubtaskWithProgress(subtask);
                const lastActivity = getLastActivity(fullSubtask);
                const commentCount = fullSubtask.comments?.length || 0;
                const recentActivities = getRecentActivities(fullSubtask);
                const recentComments = getRecentComments(fullSubtask);
                
                return (
                  <div 
                    key={subtask.id} 
                    className="flex items-center justify-between py-1.5 px-2 hover:bg-muted/50 rounded-md transition-colors cursor-pointer group"
                    onClick={() => handleSubtaskClick(fullSubtask)}
                  >
                    <div className="flex items-center gap-3 flex-1 min-w-0">
                      <span className="font-medium truncate">{subtask.title}</span>
                      <Badge
                        variant="custom"
                        className={cn(
                          "cursor-pointer text-xs",
                          "!text-[var(--badge-text-color)]"
                        )}
                        style={{
                          backgroundColor: getStatusColor(fullSubtask, fullSubtask.progress_status_id.toString()),
                          ['--badge-text-color' as string]: isLightColor(getStatusColor(fullSubtask, fullSubtask.progress_status_id.toString())) ? '#000000' : '#ffffff'
                        }}
                      >
                        {getStatusLabel(fullSubtask, fullSubtask.progress_status_id.toString())}
                      </Badge>
                    </div>
                    
                    <div className="flex items-center gap-4">
                      {lastActivity && (
                        <TooltipProvider>
                          <Tooltip delayDuration={0}>
                            <TooltipTrigger asChild>
                              <div className="flex items-center gap-1 text-xs text-muted-foreground">
                                <Clock className="h-3 w-3" />
                                {dayjs(lastActivity).fromNow()}
                              </div>
                            </TooltipTrigger>
                            <TooltipContent side="top" className="max-w-[300px]">
                              <div className="space-y-2">
                                <p className="font-medium">Recent Activity</p>
                                {recentActivities.map((activity, idx) => (
                                  <div key={idx} className="text-xs">
                                    {activity.type === 'comment' ? (
                                      <span>{activity.content}</span>
                                    ) : (
                                      <span>
                                        {activity.type === 'status' && (
                                          <>
                                            Status changed to{' '}
                                            <span className="font-medium">
                                              {board?.subtask_config?.predefined_subtasks
                                                ?.find(ps => ps.name === fullSubtask.title)
                                                ?.status_options
                                                ?.find(so => so.id.toString() === activity.new_value?.toString())
                                                ?.label || activity.new_value}
                                            </span>
                                          </>
                                        )}
                                        {activity.type === 'assignees' && 'Assignees updated'}
                                        {activity.type === 'work_date' && 'Work date updated'}
                                        {activity.type === 'due_date' && 'Due date updated'}
                                      </span>
                                    )}
                                    <span className="block text-muted-foreground">
                                      {dayjs(activity.timestamp).fromNow()}
                                    </span>
                                  </div>
                                ))}
                              </div>
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      )}
                      
                      {commentCount > 0 && (
                        <TooltipProvider>
                          <Tooltip delayDuration={0}>
                            <TooltipTrigger asChild>
                              <div className="flex items-center gap-1 text-xs">
                                <MessageSquare className="h-3 w-3" />
                                {commentCount}
                              </div>
                            </TooltipTrigger>
                            <TooltipContent side="top" className="max-w-[300px]">
                              <div className="space-y-2">
                                <p className="font-medium">Recent Comments</p>
                                {recentComments.map((comment, idx) => (
                                  <div key={idx} className="text-xs">
                                    <span>{comment.content}</span>
                                    <span className="block text-muted-foreground">
                                      {dayjs(comment.timestamp).fromNow()}
                                    </span>
                                  </div>
                                ))}
                              </div>
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      )}
                      
                      {board?.subtask_config?.enable_assignees && subtask.assigned_users && subtask.assigned_users.length > 0 && (
                        <div className="flex-shrink-0">
                          <AvatarGroup
                            users={getAssignedUsers(fullSubtask)}
                            max={2}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="text-sm text-muted-foreground text-center py-2">
              No subtasks yet
            </div>
          )}
        </div>
      </div>

      <CreateSubTask
        open={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
        onSubmit={async (values) => {
          setIsCreateModalOpen(false);
          onSubtaskCreated();
        }}
        parentTask={task}
        board={board}
      />
    </div>
  );
}

export default TaskViewSubtasks; 