import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm, useFieldArray } from 'react-hook-form'
import * as z from 'zod'
import axios from '@/api/axiosConfig'
import { toast } from 'sonner'
import { Plus, Minus, X } from 'lucide-react'
import { cn } from '@/lib/utils'

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  DialogBody,
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormDescription,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import { Switch } from '@/components/ui/switch'
import { Badge } from '@/components/ui/badge'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@/components/ui/tabs'
import { Separator } from '@/components/ui/separator'
import { ScrollArea } from '@/components/ui/scroll-area'
import { AssigneeSelection } from '@/modals/AssigneeSelection'
import { ANT_COLORS } from '@/configs/colors'
import { IconSelector } from '@/taskboards/custom/components/icon-selector'
import { StatusOptionsConfig } from '../components/StatusOptionsConfig'

import { useUsers } from '@/contexts/UserContext'

interface CreateBoardModalProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  currentUser: any;
}

interface AccessUser {
  user_id: string;
  is_admin: boolean;
  user: {
    user_id: string;
    name: string;
    surname: string;
    avatar_urls: string[];
  };
}

const formSchema = z.object({
  name: z.string().min(1, 'Please enter a board name'),
  description: z.string().optional(),
  is_public: z.boolean().default(true),
  board_type: z.enum(['taskboard', 'project']).default('taskboard'),
  board_config: z.object({
    requires_deal: z.boolean().default(false),
    requires_work_date: z.boolean().default(false),
    requires_due_date: z.boolean().default(false),
    show_in_my_work: z.boolean().default(true),
    enable_time_tracking: z.boolean().default(true),
    enable_subtasks: z.boolean().default(false),
    simplify_main_tasks: z.boolean().default(false),
    enable_automations: z.boolean().default(false),
    enable_in_flows: z.boolean().default(false),
    enable_calendar_view: z.boolean().default(false),
    enable_recurring_tasks: z.boolean().default(false),
    enable_completion_events: z.boolean().default(false),
    icon: z.string().default('FolderKanban'),
    kanban_source: z.string().default('progress'),
    default_view: z.enum(['kanban', 'table', 'calendar']).default('kanban'),
    progress_status_options: z.array(z.object({
      id: z.number(),
      label: z.string(),
      color: z.string(),
      order: z.number(),
      value: z.string(),
      description: z.string().optional().nullable(),
      is_completed_state: z.boolean().default(false)
    })),
    board_status_options: z.array(z.object({
      id: z.number(),
      label: z.string(),
      color: z.string(),
      order: z.number(),
      value: z.string(),
      description: z.string().optional().nullable(),
      is_completed_state: z.boolean().default(false)
    })).default([]),
    notifications: z.object({
      enabled: z.boolean().default(false),
      task_assignments: z.boolean().default(false),
      subtask_assignments: z.boolean().default(false),
      task_comments: z.boolean().default(false),
      subtask_comments: z.boolean().default(false),
      task_updates: z.boolean().default(false),
      subtask_updates: z.boolean().default(false)
    }),
    card_fields: z.array(z.string()).default([]),
    completion_settings: z.object({
      enable_task_completion_events: z.boolean().default(false),
      enable_subtask_completion_events: z.boolean().default(false),
      show_confetti: z.boolean().default(false),
      show_completion_drawer: z.boolean().default(false)
    }).default({}),
    recurring_task_settings: z.object({
      require_recurrence: z.boolean().default(false),
      recurrence_options: z.array(z.object({
        id: z.string(),
        name: z.string(),
        type: z.enum(['preset', 'custom']),
        frequency: z.enum([
          'daily',
          'weekly',
          'biweekly',
          'monthly',
          'quarterly',
          'semiannual',
          'annual',
          'custom'
        ]),
        custom_config: z.object({
          interval: z.number().optional(),
          weekday: z.number().optional(),
          dayOfMonth: z.number().optional(),
          monthOfYear: z.number().optional(),
          weekOfMonth: z.number().optional(),
        }).optional(),
      })).default([])
    }).default({})
  })
})

interface FieldArrayItem {
  id: string;
  label: string;
  color: string;
}

type StatusOption = {
  id: number;
  label: string;
  color: string;
  value: string;
  description: string | null;
  order: number;
  is_completed_state: boolean;
};

const CreateBoardModal = ({
  open,
  onClose,
  onSuccess,
  currentUser,
}: CreateBoardModalProps) => {
  const navigate = useNavigate()
  const [isPublic, setIsPublic] = useState(true)
  const [showAssigneeModal, setShowAssigneeModal] = useState(false)
  const [showAdminModal, setShowAdminModal] = useState(false)
  const [selectedUsers, setSelectedUsers] = useState<string[]>([])
  const [adminUsers, setAdminUsers] = useState<string[]>([])
  const [useBoardStatus, setUseBoardStatus] = useState(false)
  const [kanbanSource, setKanbanSource] = useState('progress')

  const { users } = useUsers();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: '',
      description: '',
      is_public: true,
      board_type: 'taskboard',
      board_config: {
        requires_deal: false,
        requires_work_date: false,
        requires_due_date: false,
        show_in_my_work: true,
        enable_time_tracking: true,
        enable_subtasks: false,
        simplify_main_tasks: false,
        enable_automations: false,
        enable_in_flows: false,
        enable_calendar_view: false,
        enable_recurring_tasks: false,
        enable_completion_events: false,
        icon: 'FolderKanban',
        kanban_source: 'progress',
        default_view: 'kanban',
        progress_status_options: [
          { id: 1, label: 'Not Started', color: '#ff4d4f', order: 0, value: 'status_0', description: null, is_completed_state: false },
          { id: 2, label: 'In Progress', color: '#ffa940', order: 1, value: 'status_1', description: null, is_completed_state: false },
          { id: 3, label: 'Completed', color: '#52c41a', order: 2, value: 'status_2', description: null, is_completed_state: true }
        ],
        board_status_options: [],
        notifications: {
          enabled: false,
          task_assignments: false,
          subtask_assignments: false,
          task_comments: false,
          subtask_comments: false,
          task_updates: false,
          subtask_updates: false
        },
        card_fields: [],
        completion_settings: {
          enable_task_completion_events: false,
          enable_subtask_completion_events: false,
          show_confetti: false,
          show_completion_drawer: false
        },
        recurring_task_settings: {
          require_recurrence: false,
          recurrence_options: []
        }
      }
    }
  })

  const progressStatusArray = useFieldArray({
    control: form.control,
    name: 'board_config.progress_status_options'
  });

  const boardStatusArray = useFieldArray({
    control: form.control,
    name: 'board_config.board_status_options'
  });

  const handleAssigneeSave = (users: string[]) => {
    setSelectedUsers(users);
    // Ensure admin users are still in the selected users list
    setAdminUsers(prev => prev.filter(id => users.includes(id)));
    setShowAssigneeModal(false);
  };

  const toggleAdminAccess = (userId: string) => {
    // Prevent event propagation
    return (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setAdminUsers(prev => {
        if (prev.includes(userId)) {
          return prev.filter(id => id !== userId);
        } else {
          return [...prev, userId];
        }
      });
    };
  };

  const handleSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      if (!values.is_public && selectedUsers.length === 0) {
        toast.error('Private boards must have at least one user with access')
        return
      }

      const boardData = {
        name: values.name,
        description: values.description,
        is_public: values.is_public,
        board_type: values.board_type,
        board_config: {
          requires_deal: values.board_config.requires_deal,
          requires_work_date: values.board_config.requires_work_date,
          requires_due_date: values.board_config.requires_due_date,
          show_in_my_work: values.board_config.show_in_my_work,
          enable_time_tracking: values.board_config.enable_time_tracking,
          enable_subtasks: values.board_config.enable_subtasks,
          simplify_main_tasks: values.board_config.simplify_main_tasks,
          enable_automations: values.board_config.enable_automations,
          enable_in_flows: values.board_config.enable_in_flows,
          enable_calendar_view: values.board_config.enable_calendar_view,
          enable_recurring_tasks: values.board_config.enable_recurring_tasks,
          enable_completion_events: values.board_config.enable_completion_events,
          icon: values.board_config.icon,
          kanban_source: kanbanSource,
          default_view: values.board_config.default_view,
          progress_status_options: values.board_config.progress_status_options.map((status, index) => ({
            id: status.id || index + 1,
            label: status.label,
            color: status.color || '#1890ff',
            description: status.description || null,
            order: index,
            is_completed_state: status.is_completed_state
          })),
          board_status_options: values.board_config.board_status_options?.map((status, index) => ({
            id: status.id || index + 1,
            label: status.label,
            color: status.color || '#1890ff',
            description: status.description || null,
            order: index,
            is_completed_state: status.is_completed_state
          })) || [],
          notifications: values.board_config.notifications,
          card_fields: values.board_config.card_fields,
          completion_settings: values.board_config.completion_settings,
          recurring_task_settings: values.board_config.recurring_task_settings
        },
        user_access: !values.is_public ? selectedUsers : [],
        admin_access: !values.is_public ? adminUsers : []
      }

      const response = await axios.post('/custom-board/boards', boardData)
      
      if (response.status === 200) {
        toast.success('Board created successfully')
        form.reset()
        setSelectedUsers([])
        setAdminUsers([])
        setIsPublic(true)
        onSuccess()
        onClose()
        
        navigate(`/custom-boards/${response.data.id}`)
      }
    } catch (error) {
      console.error('Error creating board:', error)
      toast.error('Failed to create board')
    }
  }

  const handlePublicChange = (checked: boolean) => {
    setIsPublic(checked)
    if (!checked && currentUser) {
      setSelectedUsers([currentUser.user_id.toString()])
    } else {
      setSelectedUsers([])
    }
  }

  const resetModal = () => {
    form.reset()
    setIsPublic(true)
    setKanbanSource('progress')
    setShowAssigneeModal(false)
    setSelectedUsers([])
    setAdminUsers([])
    setUseBoardStatus(false)
  }

  useEffect(() => {
    if (open) {
      resetModal()
    }
  }, [open])

  useEffect(() => {
    if (!isPublic && selectedUsers.length === 0 && currentUser) {
      setSelectedUsers([currentUser.user_id.toString()])
    }
  }, [isPublic])

  return (
    <>
      <Dialog open={open} onOpenChange={onClose}>
        <DialogContent className="max-w-3xl">
          <DialogHeader>
            <DialogTitle>Create New Board</DialogTitle>
            <DialogDescription className="sr-only">
              Create a new board by configuring its name, description, privacy settings, and status options
            </DialogDescription>
          </DialogHeader>

          <Form {...form}>
            <form id="create-board-form" onSubmit={form.handleSubmit(handleSubmit)} className="flex flex-col flex-1 min-h-0">
              <Tabs defaultValue="general" className="flex flex-col flex-1 min-h-0">
                <div className="px-6 border-b">
                  <TabsList>
                    <TabsTrigger value="general">General</TabsTrigger>
                    <TabsTrigger value="status">Status Options</TabsTrigger>
                  </TabsList>
                </div>

                <DialogBody maxBodyHeight>
                  <TabsContent value="general" className="space-y-6 mt-0">
                    <FormField
                      control={form.control}
                      name="name"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Board Name</FormLabel>
                          <FormControl>
                            <Input placeholder="Enter board name" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="description"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Description</FormLabel>
                          <FormControl>
                            <Input placeholder="Enter board description (optional)" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="board_type"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Board Type</FormLabel>
                          <Select
                            onValueChange={field.onChange}
                            value={field.value}
                          >
                            <SelectTrigger className="w-full">
                              <SelectValue placeholder="Select board type" />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectItem value="taskboard">Taskboard</SelectItem>
                              <SelectItem value="project">Project</SelectItem>
                            </SelectContent>
                          </Select>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="board_config.icon"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Board Icon</FormLabel>
                          <FormControl>
                            <IconSelector
                              value={field.value}
                              onChange={field.onChange}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <div className="space-y-4 border rounded-lg p-4">
                      <h3 className="font-medium">Privacy Settings</h3>
                      <FormField
                        control={form.control}
                        name="is_public"
                        render={({ field }) => (
                          <FormItem className="flex items-center justify-between">
                            <div>
                              <FormLabel>Public Board</FormLabel>
                              <p className="text-sm text-muted-foreground">
                                Make this board visible to all users
                              </p>
                            </div>
                            <FormControl>
                              <Switch
                                checked={field.value}
                                onCheckedChange={(checked) => {
                                  field.onChange(checked);
                                  setIsPublic(checked);
                                  if (!checked) {
                                    if (currentUser) {
                                      setSelectedUsers([currentUser.user_id.toString()]);
                                    }
                                  } else {
                                    setSelectedUsers([]);
                                    setAdminUsers([]);
                                  }
                                }}
                              />
                            </FormControl>
                          </FormItem>
                        )}
                      />

                      {/* Admin section - only show for public boards */}
                      {isPublic && (
                        <div className="space-y-4 border rounded-lg p-4">
                          <h3 className="font-medium">Board Administrators</h3>
                          <div className="flex items-center justify-between mb-2">
                            <div>
                              <p className="text-sm text-muted-foreground">
                                Select users who can edit board settings
                              </p>
                            </div>
                            <Button
                              variant="outline"
                              size="sm"
                              type="button"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setShowAdminModal(true);
                              }}
                            >
                              Select Admins
                            </Button>
                          </div>
                          {adminUsers.length > 0 ? (
                            <div className="flex flex-wrap gap-2">
                              {adminUsers.map(userId => {
                                const user = users.find(u => String(u.user_id) === String(userId));
                                if (!user) return null;
                                return (
                                  <Badge 
                                    key={userId}
                                    variant="secondary"
                                    className="flex items-center gap-2"
                                  >
                                    {user.name} {user.surname}
                                    <Button
                                      variant="ghost"
                                      size="icon"
                                      className="h-4 w-4 p-0 hover:bg-transparent"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setAdminUsers(prev => prev.filter(id => id !== userId));
                                      }}
                                    >
                                      <X className="h-3 w-3" />
                                    </Button>
                                  </Badge>
                                );
                              })}
                            </div>
                          ) : (
                            <p className="text-sm text-muted-foreground italic">
                              No administrators selected. Only you will have admin rights.
                            </p>
                          )}
                        </div>
                      )}

                      {/* User access section - only show for private boards */}
                      {!isPublic && (
                        <div className="space-y-4 border rounded-lg p-4">
                          <h3 className="font-medium">User Access</h3>
                          <div className="flex items-center justify-between mb-2">
                            <div>
                              <p className="text-sm text-muted-foreground">
                                Select users who can access this board
                              </p>
                            </div>
                            <Button
                              variant="outline"
                              size="sm"
                              type="button"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setShowAssigneeModal(true);
                              }}
                            >
                              Select Users
                            </Button>
                          </div>
                          {selectedUsers.length > 0 ? (
                            <div className="flex flex-wrap gap-2">
                              {selectedUsers.map(userId => {
                                const user = users.find(u => String(u.user_id) === String(userId));
                                if (!user) return null;
                                const isCreator = currentUser?.user_id === Number(userId);
                                const isAdmin = adminUsers.includes(userId);
                                return (
                                  <Badge 
                                    key={userId}
                                    variant={isCreator ? "default" : "secondary"}
                                    className={cn(
                                      "flex items-center gap-2",
                                      isCreator && "bg-primary"
                                    )}
                                  >
                                    {user.name} {user.surname}
                                    <div className="flex items-center gap-1">
                                      {isCreator ? (
                                        <span className="text-xs ml-1">(Owner)</span>
                                      ) : (
                                        <>
                                          <Button
                                            variant="ghost"
                                            size="sm"
                                            className="h-5 px-1.5 text-xs"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              e.stopPropagation();
                                              if (isAdmin) {
                                                setAdminUsers(prev => prev.filter(id => id !== userId));
                                              } else {
                                                setAdminUsers(prev => [...prev, userId]);
                                              }
                                            }}
                                          >
                                            {isAdmin ? 'Admin ✓' : 'Make Admin'}
                                          </Button>
                                          <Button
                                            variant="ghost"
                                            size="icon"
                                            className="h-4 w-4 p-0 hover:bg-transparent"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              e.stopPropagation();
                                              setSelectedUsers(prev => prev.filter(id => id !== userId));
                                              setAdminUsers(prev => prev.filter(id => id !== userId));
                                            }}
                                          >
                                            <X className="h-3 w-3" />
                                          </Button>
                                        </>
                                      )}
                                    </div>
                                  </Badge>
                                );
                              })}
                            </div>
                          ) : (
                            <p className="text-sm text-muted-foreground italic">
                              No users selected. Only you will have access to this board.
                            </p>
                          )}
                        </div>
                      )}
                    </div>

                    <div className="space-y-4 border rounded-lg p-4">
                      <h3 className="font-medium">Task Requirements</h3>
                      <FormField
                        control={form.control}
                        name="board_config.requires_deal"
                        render={({ field }) => (
                          <FormItem className="flex items-center justify-between">
                            <div>
                              <FormLabel>Require Deal</FormLabel>
                              <p className="text-sm text-muted-foreground">
                                Tasks must be linked to a deal
                              </p>
                            </div>
                            <FormControl>
                              <Switch
                                checked={field.value}
                                onCheckedChange={field.onChange}
                              />
                            </FormControl>
                          </FormItem>
                        )}
                      />

                      <FormField
                        control={form.control}
                        name="board_config.requires_work_date"
                        render={({ field }) => (
                          <FormItem className="flex items-center justify-between">
                            <div>
                              <FormLabel>Require Work Date</FormLabel>
                              <p className="text-sm text-muted-foreground">
                                Tasks must have a work date specified
                              </p>
                              {form.watch('board_config.simplify_main_tasks') && (
                                <p className="text-sm text-destructive mt-1">
                                  This option is disabled when "Simplify Main Tasks" is enabled
                                </p>
                              )}
                            </div>
                            <FormControl>
                              <Switch
                                checked={field.value && !form.watch('board_config.simplify_main_tasks')}
                                onCheckedChange={field.onChange}
                                disabled={form.watch('board_config.simplify_main_tasks')}
                              />
                            </FormControl>
                          </FormItem>
                        )}
                      />

                      <FormField
                        control={form.control}
                        name="board_config.requires_due_date"
                        render={({ field }) => (
                          <FormItem className="flex items-center justify-between">
                            <div>
                              <FormLabel>Require Due Date</FormLabel>
                              <p className="text-sm text-muted-foreground">
                                Tasks must have a due date specified
                              </p>
                              {form.watch('board_config.simplify_main_tasks') && (
                                <p className="text-sm text-destructive mt-1">
                                  This option is disabled when "Simplify Main Tasks" is enabled
                                </p>
                              )}
                            </div>
                            <FormControl>
                              <Switch
                                checked={field.value && !form.watch('board_config.simplify_main_tasks')}
                                onCheckedChange={field.onChange}
                                disabled={form.watch('board_config.simplify_main_tasks')}
                              />
                            </FormControl>
                          </FormItem>
                        )}
                      />
                    </div>

                    <div className="space-y-4 border rounded-lg p-4">
                      <h3 className="font-medium">Additional Settings</h3>
                      <FormField
                        control={form.control}
                        name="board_config.default_view"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Default View</FormLabel>
                            <Select onValueChange={field.onChange} defaultValue={field.value}>
                              <FormControl>
                                <SelectTrigger>
                                  <SelectValue placeholder="Select default view" />
                                </SelectTrigger>
                              </FormControl>
                              <SelectContent>
                                <SelectItem value="kanban">Kanban</SelectItem>
                                <SelectItem value="table">Table</SelectItem>
                                {form.watch('board_config.enable_calendar_view') && (
                                  <SelectItem value="calendar">Calendar</SelectItem>
                                )}
                              </SelectContent>
                            </Select>
                          </FormItem>
                        )}
                      />

                      <FormField
                        control={form.control}
                        name="board_config.enable_calendar_view"
                        render={({ field }) => (
                          <FormItem className="flex items-center justify-between">
                            <div>
                              <FormLabel>Enable Calendar View</FormLabel>
                              <FormDescription>
                                Allow viewing tasks in a calendar layout
                              </FormDescription>
                            </div>
                            <FormControl>
                              <Switch
                                checked={field.value}
                                onCheckedChange={field.onChange}
                              />
                            </FormControl>
                          </FormItem>
                        )}
                      />

                      <FormField
                        control={form.control}
                        name="board_config.show_in_my_work"
                        render={({ field }) => (
                          <FormItem className="flex items-center justify-between">
                            <div>
                              <FormLabel>Show in My Work</FormLabel>
                              <p className="text-sm text-muted-foreground">
                                Display tasks from this board in the My Work section
                              </p>
                              {form.watch('board_config.simplify_main_tasks') && (
                                <p className="text-sm text-destructive mt-1">
                                  This option is disabled when "Simplify Main Tasks" is enabled
                                </p>
                              )}
                            </div>
                            <FormControl>
                              <Switch
                                checked={field.value && !form.watch('board_config.simplify_main_tasks')}
                                onCheckedChange={field.onChange}
                                disabled={form.watch('board_config.simplify_main_tasks')}
                              />
                            </FormControl>
                          </FormItem>
                        )}
                      />

                      <FormField
                        control={form.control}
                        name="board_config.enable_time_tracking"
                        render={({ field }) => (
                          <FormItem className="flex items-center justify-between">
                            <div>
                              <FormLabel>Enable Time Tracking</FormLabel>
                              <p className="text-sm text-muted-foreground">
                                Allow time tracking for tasks in this board
                              </p>
                            </div>
                            <FormControl>
                              <Switch
                                checked={field.value}
                                onCheckedChange={field.onChange}
                              />
                            </FormControl>
                          </FormItem>
                        )}
                      />

                      <FormField
                        control={form.control}
                        name="board_config.enable_subtasks"
                        render={({ field }) => (
                          <FormItem className="flex items-center justify-between">
                            <div>
                              <FormLabel>Enable Subtasks</FormLabel>
                              <p className="text-sm text-muted-foreground">
                                Allow tasks to have subtasks in this board
                              </p>
                            </div>
                            <FormControl>
                              <Switch
                                checked={field.value}
                                onCheckedChange={field.onChange}
                              />
                            </FormControl>
                          </FormItem>
                        )}
                      />

                      {form.watch('board_config.enable_subtasks') && (
                        <FormField
                          control={form.control}
                          name="board_config.simplify_main_tasks"
                          render={({ field }) => (
                            <FormItem className="flex items-center justify-between">
                              <div>
                                <FormLabel>Simplify Main Tasks</FormLabel>
                                <p className="text-sm text-muted-foreground">
                                  Simplify main tasks to only deal selection. This requires "Require Deal" to be enabled.
                                </p>
                                {field.value && !form.watch('board_config.requires_deal') && (
                                  <p className="text-sm text-destructive mt-1">
                                    This option requires "Require Deal" to be enabled
                                  </p>
                                )}
                              </div>
                              <FormControl>
                                <Switch
                                  checked={field.value}
                                  onCheckedChange={(checked) => {
                                    if (checked && !form.watch('board_config.requires_deal')) {
                                      toast.error('You must enable "Require Deal" to use this option');
                                      return;
                                    }
                                    if (checked) {
                                      // When enabling simplify_main_tasks, disable show_in_my_work and date requirements
                                      form.setValue('board_config.show_in_my_work', false);
                                      form.setValue('board_config.requires_work_date', false);
                                      form.setValue('board_config.requires_due_date', false);
                                    }
                                    field.onChange(checked);
                                  }}
                                  disabled={!form.watch('board_config.requires_deal')}
                                />
                              </FormControl>
                            </FormItem>
                          )}
                        />
                      )}

                      <FormField
                        control={form.control}
                        name="board_config.enable_automations"
                        render={({ field }) => (
                          <FormItem className="flex items-center justify-between">
                            <div>
                              <FormLabel>Enable Automations</FormLabel>
                              <p className="text-sm text-muted-foreground">
                                Enable automation rules for tasks and subtasks
                              </p>
                            </div>
                            <FormControl>
                              <Switch
                                checked={field.value}
                                onCheckedChange={field.onChange}
                              />
                            </FormControl>
                          </FormItem>
                        )}
                      />

                      <FormField
                        control={form.control}
                        name="board_config.enable_in_flows"
                        render={({ field }) => (
                          <FormItem className="flex items-center justify-between">
                            <div>
                              <FormLabel>Enable in Flows</FormLabel>
                              <p className="text-sm text-muted-foreground">
                                Make this board available as an option in automation flows
                              </p>
                            </div>
                            <FormControl>
                              <Switch
                                checked={field.value}
                                onCheckedChange={field.onChange}
                              />
                            </FormControl>
                          </FormItem>
                        )}
                      />

                      <FormField
                        control={form.control}
                        name="board_config.enable_recurring_tasks"
                        render={({ field }) => (
                          <FormItem className="flex items-center justify-between">
                            <div>
                              <FormLabel>Enable Recurring Tasks</FormLabel>
                              <p className="text-sm text-muted-foreground">
                                Allow tasks to be automatically recreated based on recurrence settings
                              </p>
                            </div>
                            <FormControl>
                              <Switch
                                checked={field.value}
                                onCheckedChange={field.onChange}
                              />
                            </FormControl>
                          </FormItem>
                        )}
                      />
                    </div>

                    <div className="space-y-4 border rounded-lg p-4">
                      <h3 className="font-medium">Completion Settings</h3>
                      <FormField
                        control={form.control}
                        name="board_config.enable_completion_events"
                        render={({ field }) => (
                          <FormItem className="flex items-center justify-between">
                            <div>
                              <FormLabel>Enable Completion Events</FormLabel>
                              <p className="text-sm text-muted-foreground">
                                Enable special events and notifications when tasks are completed
                              </p>
                            </div>
                            <FormControl>
                              <Switch
                                checked={field.value}
                                onCheckedChange={field.onChange}
                              />
                            </FormControl>
                          </FormItem>
                        )}
                      />
                    </div>

                    <div className="space-y-4 border rounded-lg p-4">
                      <h3 className="font-medium">Notification Settings</h3>
                      <FormField
                        control={form.control}
                        name="board_config.notifications.enabled"
                        render={({ field }) => (
                          <FormItem className="flex items-center justify-between">
                            <div>
                              <FormLabel>Enable Notifications</FormLabel>
                              <p className="text-sm text-muted-foreground">
                                Allow notifications for events in this board
                              </p>
                            </div>
                            <FormControl>
                              <Switch
                                checked={field.value}
                                onCheckedChange={field.onChange}
                              />
                            </FormControl>
                          </FormItem>
                        )}
                      />

                      {form.watch('board_config.notifications.enabled') && (
                        <div className="space-y-4 pl-4">
                          <FormField
                            control={form.control}
                            name="board_config.notifications.task_assignments"
                            render={({ field }) => (
                              <FormItem className="flex items-center justify-between">
                                <div>
                                  <FormLabel>Task Assignments</FormLabel>
                                  <p className="text-sm text-muted-foreground">
                                    Notify users when they are assigned to tasks
                                  </p>
                                </div>
                                <FormControl>
                                  <Switch
                                    checked={field.value}
                                    onCheckedChange={field.onChange}
                                  />
                                </FormControl>
                              </FormItem>
                            )}
                          />

                          <FormField
                            control={form.control}
                            name="board_config.notifications.subtask_assignments"
                            render={({ field }) => (
                              <FormItem className="flex items-center justify-between">
                                <div>
                                  <FormLabel>Subtask Assignments</FormLabel>
                                  <p className="text-sm text-muted-foreground">
                                    Notify users when they are assigned to subtasks
                                  </p>
                                </div>
                                <FormControl>
                                  <Switch
                                    checked={field.value}
                                    onCheckedChange={field.onChange}
                                  />
                                </FormControl>
                              </FormItem>
                            )}
                          />

                          <FormField
                            control={form.control}
                            name="board_config.notifications.task_comments"
                            render={({ field }) => (
                              <FormItem className="flex items-center justify-between">
                                <div>
                                  <FormLabel>Task Comments</FormLabel>
                                  <p className="text-sm text-muted-foreground">
                                    Send notifications for comments on tasks
                                  </p>
                                </div>
                                <FormControl>
                                  <Switch
                                    checked={field.value}
                                    onCheckedChange={field.onChange}
                                  />
                                </FormControl>
                              </FormItem>
                            )}
                          />

                          <FormField
                            control={form.control}
                            name="board_config.notifications.subtask_comments"
                            render={({ field }) => (
                              <FormItem className="flex items-center justify-between">
                                <div>
                                  <FormLabel>Subtask Comments</FormLabel>
                                  <p className="text-sm text-muted-foreground">
                                    Send notifications for comments on subtasks
                                  </p>
                                </div>
                                <FormControl>
                                  <Switch
                                    checked={field.value}
                                    onCheckedChange={field.onChange}
                                  />
                                </FormControl>
                              </FormItem>
                            )}
                          />

                          <FormField
                            control={form.control}
                            name="board_config.notifications.task_updates"
                            render={({ field }) => (
                              <FormItem className="flex items-center justify-between">
                                <div>
                                  <FormLabel>Task Updates</FormLabel>
                                  <p className="text-sm text-muted-foreground">
                                    Send notifications for task status and assignment changes
                                  </p>
                                </div>
                                <FormControl>
                                  <Switch
                                    checked={field.value}
                                    onCheckedChange={field.onChange}
                                  />
                                </FormControl>
                              </FormItem>
                            )}
                          />

                          <FormField
                            control={form.control}
                            name="board_config.notifications.subtask_updates"
                            render={({ field }) => (
                              <FormItem className="flex items-center justify-between">
                                <div>
                                  <FormLabel>Subtask Updates</FormLabel>
                                  <p className="text-sm text-muted-foreground">
                                    Send notifications for subtask status and assignment changes
                                  </p>
                                </div>
                                <FormControl>
                                  <Switch
                                    checked={field.value}
                                    onCheckedChange={field.onChange}
                                  />
                                </FormControl>
                              </FormItem>
                            )}
                          />
                        </div>
                      )}
                    </div>
                  </TabsContent>

                  <TabsContent value="status" className="space-y-6 mt-0">
                    <StatusOptionsConfig
                      form={form}
                      kanbanSource={kanbanSource as 'progress' | 'board'}
                      useBoardStatus={useBoardStatus}
                      setUseBoardStatus={(checked) => {
                        setUseBoardStatus(checked);
                        if (!checked) {
                          setKanbanSource('progress');
                          form.setValue('board_config.board_status_options', [], {
                            shouldDirty: true,
                            shouldTouch: true
                          });
                        }
                      }}
                      onKanbanSourceChange={(source) => {
                        setKanbanSource(source);
                        form.setValue('board_config.kanban_source', source, {
                          shouldDirty: true,
                          shouldTouch: true
                        });
                      }}
                    />
                  </TabsContent>
                </DialogBody>
              </Tabs>

              <DialogFooter>
                <Button variant="outline" onClick={onClose}>
                  Cancel
                </Button>
                <Button type="submit" form="create-board-form">Create Board</Button>
              </DialogFooter>
            </form>
          </Form>
        </DialogContent>
      </Dialog>

      <AssigneeSelection
        visible={showAssigneeModal}
        onClose={() => setShowAssigneeModal(false)}
        onSave={handleAssigneeSave}
        initialAssignees={selectedUsers}
      />

      <AssigneeSelection
        visible={showAdminModal}
        onClose={() => setShowAdminModal(false)}
        onSave={(users) => {
          setAdminUsers(users);
          setShowAdminModal(false);
        }}
        initialAssignees={adminUsers}
        title="Select Board Admins"
        description="Select users who can modify board settings"
      />
    </>
  )
}

export default CreateBoardModal