import { DropdownOption, DealConfigItem } from './DealDataConfig';

interface OnboardingSystemStatus {
  setup_required: boolean;
  setup_completed: boolean;
  setup_date?: Date;
  notes?: string;
}

interface OnboardingDataField extends DealConfigItem {
  required?: boolean;
  systemStatus?: OnboardingSystemStatus;
  multiSelect?: boolean;
}

export const dealOnboardingConfig: OnboardingDataField[] = [
  {
    key: 'onboarding_data.bookkeeping_start_date',
    label: 'Hvornår skal vi bogføre op fra?',
    type: 'date',
    required: true,
    editable: true,
  },
  {
    key: 'onboarding_data.new_accounting_system',
    label: 'Hvis kunden skal skifte regnskabsprogram, til hvilket?',
    type: 'dropdown',
    editable: true,
    options: [
      { value: 'economic', label: 'E-conomic', color: '#52c41a' },
      { value: 'dinero', label: 'Dinero', color: '#1890ff' },
      { value: 'not_willing', label: 'Kunden vil ikke skifte', color: '#a6a6a6' },
      { value: 'not_discussed', label: 'Ikke drøftet', color: '#722ed1' },
      { value: 'not_should', label: 'Kunden skal ikke skifte', color: '#eb2f96' },
      { value: 'is_willing', label: 'Kunden er villig til at skifte', color: '#faad14' },
    ],
  },
  {
    key: 'onboarding_data.tools_to_setup',
    label: 'Hvilke tools er det aftalt der skal sættes op',
    type: 'dropdown',
    editable: true,
    multiSelect: true,
    options: [
      { value: 'ingen', label: 'Ingen', color: '#52c41a' },
      { value: 'officebot', label: 'Officebot', color: '#1890ff' },
      { value: 'officebot_rykkere', label: 'Officebot Rykkere', color: '#722ed1' },
      { value: 'officebot_kassekladde', label: 'Officebot Kassekladde', color: '#eb2f96' },
      { value: 'officebot_bankafstemning', label: 'Officebot Bankafstemning', color: '#faad14' },
      { value: 'officebot_indløsning_afstemning', label: 'Officebot Indløsning & Afsstemning', color: '#13c2c2' },
      { value: 'officebot_rykkerbot', label: 'Officebot Rykkerbot', color: '#2f54eb' },
      { value: 'corpay_one', label: 'Corpay One', color: '#f5222d' },
      { value: 'pleo', label: 'Pleo', color: '#fa8c16' },
    ],
  },
  {
    key: 'onboarding_data.additional_tasks',
    label: 'Yderligere onboarding opgaver',
    type: 'text',
    editable: true,
  },
  {
    key: 'onboarding.notes',
    label: 'Onboarding Notes',
    type: 'text',
    editable: true,
  },
];

// Export types for use in other files
export type DealOnboardingConfigType = typeof dealOnboardingConfig;
export type OnboardingDataFieldType = OnboardingDataField;
