import React, { useState } from 'react'
import { Plus, Trash2, X, ArrowUp, ArrowDown, ChevronUp, ChevronDown, Pencil } from 'lucide-react'
import { useFieldArray, UseFormReturn } from 'react-hook-form'
import { Card } from "@/components/ui/card"
import { ScrollArea } from '@/components/ui/scroll-area'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Switch } from '@/components/ui/switch'
import { Badge } from '@/components/ui/badge'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogBody,
} from "@/components/ui/dialog"
import { AssigneeSelection } from '@/modals/AssigneeSelection'
import { dealDataConfig, type DealDataField } from '@/configs/DealDataConfig'
import { StoredUser } from '@/types/user'
import { ANT_COLORS } from '@/configs/colors'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible"
import { SubtaskStatusOption } from '@/types/custom-board'

interface SubtaskAssignmentCondition {
  field: string;
  operator: 'equals' | 'not_equals';
  value: string;
  assigned_to: string[];
}

interface SubtaskConfigProps {
  form: UseFormReturn<any>;
  users: StoredUser[];
  currentEditingSubtaskIndex: number | null;
  setCurrentEditingSubtaskIndex: (index: number | null) => void;
}

export function SubtaskConfig({ form, users, currentEditingSubtaskIndex, setCurrentEditingSubtaskIndex }: SubtaskConfigProps) {
  const [isSettingsOpen, setIsSettingsOpen] = useState(true)
  const [showConditionModal, setShowConditionModal] = useState(false)
  const [editingConditionIndex, setEditingConditionIndex] = useState<number | null>(null)
  const [currentSubtaskIndex, setCurrentSubtaskIndex] = useState<number | null>(null)
  const [isAssigneeSelectionOpen, setIsAssigneeSelectionOpen] = useState(false)
  const [tempCondition, setTempCondition] = useState<Partial<SubtaskAssignmentCondition>>({})
  const [conditionAssignees, setConditionAssignees] = useState<string[]>([])

  const subtaskArray = useFieldArray({
    control: form.control,
    name: 'subtask_config.predefined_subtasks'
  });

  const handleAddCondition = (subtaskIndex: number) => {
    setCurrentSubtaskIndex(subtaskIndex)
    setEditingConditionIndex(null)
    setTempCondition({})
    setConditionAssignees([])
    setShowConditionModal(true)
  }

  const handleEditCondition = (subtaskIndex: number, conditionIndex: number) => {
    const condition = form.watch(`subtask_config.predefined_subtasks.${subtaskIndex}.assignment_conditions.${conditionIndex}`)
    setCurrentSubtaskIndex(subtaskIndex)
    setEditingConditionIndex(conditionIndex)
    setTempCondition({
      field: condition.field,
      operator: condition.operator,
      value: condition.value
    })
    setConditionAssignees(condition.assigned_to || [])
    setShowConditionModal(true)
  }

  const handleSaveCondition = () => {
    if (currentSubtaskIndex === null || !tempCondition.field || !tempCondition.operator || !tempCondition.value) return

    const newCondition: SubtaskAssignmentCondition = {
      field: tempCondition.field,
      operator: tempCondition.operator,
      value: tempCondition.value,
      assigned_to: conditionAssignees
    }

    const currentConditions = form.watch(`subtask_config.predefined_subtasks.${currentSubtaskIndex}.assignment_conditions`) || []
    
    if (editingConditionIndex !== null) {
      const newConditions = [...currentConditions]
      newConditions[editingConditionIndex] = newCondition
      form.setValue(`subtask_config.predefined_subtasks.${currentSubtaskIndex}.assignment_conditions`, newConditions, {
        shouldDirty: true,
        shouldTouch: true
      })
    } else {
      form.setValue(`subtask_config.predefined_subtasks.${currentSubtaskIndex}.assignment_conditions`, [...currentConditions, newCondition], {
        shouldDirty: true,
        shouldTouch: true
      })
    }

    setShowConditionModal(false)
    setCurrentSubtaskIndex(null)
    setEditingConditionIndex(null)
    setTempCondition({})
    setConditionAssignees([])
  }

  const handleDeleteCondition = (subtaskIndex: number, conditionIndex: number) => {
    const currentConditions = form.watch(`subtask_config.predefined_subtasks.${subtaskIndex}.assignment_conditions`) || []
    const newConditions = currentConditions.filter((_: unknown, index: number) => index !== conditionIndex)
    form.setValue(`subtask_config.predefined_subtasks.${subtaskIndex}.assignment_conditions`, newConditions, {
      shouldDirty: true,
      shouldTouch: true
    })
  }

  // Add onKeyDown handler to prevent form submission on Enter for all inputs
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    <div className="space-y-6">
      <Collapsible
        open={isSettingsOpen}
        onOpenChange={setIsSettingsOpen}
        className="p-6 bg-muted rounded-lg space-y-4"
      >
        <div className="flex items-center justify-between">
          <div>
            <h3 className="text-lg font-semibold">Subtask Settings</h3>
            <p className="text-sm text-muted-foreground">
              Configure general subtask settings
            </p>
          </div>
          <CollapsibleTrigger asChild>
            <Button variant="ghost" size="sm">
              {isSettingsOpen ? (
                <ChevronUp className="h-4 w-4" />
              ) : (
                <ChevronDown className="h-4 w-4" />
              )}
            </Button>
          </CollapsibleTrigger>
        </div>

        <CollapsibleContent className="space-y-4">
          <FormField
            control={form.control}
            name="subtask_config.show_in_my_work"
            render={({ field }) => (
              <FormItem className="flex items-center justify-between space-y-0">
                <FormLabel>Show in My Work</FormLabel>
                <FormControl>
                  <Switch
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="subtask_config.enable_assignees"
            render={({ field }) => (
              <FormItem className="flex items-center justify-between space-y-0">
                <FormLabel>Enable Assignees</FormLabel>
                <FormControl>
                  <Switch
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="subtask_config.enable_subtask_progress"
            render={({ field }) => (
              <FormItem className="flex items-center justify-between space-y-0">
                <div>
                  <FormLabel>Enable Subtask Progress</FormLabel>
                  <p className="text-sm text-muted-foreground">
                    Show progress bar on main tasks based on completed subtasks
                  </p>
                </div>
                <FormControl>
                  <Switch
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
              </FormItem>
            )}
          />
        </CollapsibleContent>
      </Collapsible>

      <div className="space-y-4">
        <div className="flex items-center justify-between">
          <div>
            <h3 className="text-lg font-semibold">Predefined Subtasks</h3>
            <p className="text-sm text-muted-foreground">
              Configure subtasks that can be automatically added to tasks
            </p>
          </div>
        </div>

        {subtaskArray.fields.map((field, index) => (
          <div key={field.id} className="space-y-4 p-4 border rounded-lg">
            <div className="flex flex-col gap-4">
              <div className="flex items-center justify-between">
                <div className="flex-1">
                  <FormField
                    control={form.control}
                    name={`subtask_config.predefined_subtasks.${index}.name`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Name</FormLabel>
                        <FormControl>
                          <Input 
                            {...field} 
                            onKeyDown={handleKeyDown}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>

                <div className="flex items-center gap-2 ml-4">
                  <FormField
                    control={form.control}
                    name={`subtask_config.predefined_subtasks.${index}.auto_add`}
                    render={({ field }) => (
                      <FormItem className="flex items-center gap-2">
                        <FormLabel className="mt-0">Auto-add</FormLabel>
                        <FormControl>
                          <Switch
                            checked={field.value}
                            onCheckedChange={(checked) => {
                              field.onChange(checked)
                              form.setValue(`subtask_config.predefined_subtasks.${index}.auto_add`, checked, {
                                shouldDirty: true,
                                shouldTouch: true
                              })
                            }}
                          />
                        </FormControl>
                      </FormItem>
                    )}
                  />

                  <Button
                    variant="ghost"
                    size="icon"
                    type="button"
                    onClick={() => {
                      subtaskArray.remove(index)
                      form.setValue('subtask_config.predefined_subtasks', 
                        form.getValues('subtask_config.predefined_subtasks').filter((_: unknown, i: number) => i !== index),
                        { shouldDirty: true, shouldTouch: true }
                      )
                    }}
                  >
                    <Trash2 className="h-4 w-4" />
                  </Button>
                </div>
              </div>

              <FormField
                control={form.control}
                name={`subtask_config.predefined_subtasks.${index}.description`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Description</FormLabel>
                    <FormControl>
                      <Input 
                        {...field} 
                        placeholder="Optional description" 
                        onKeyDown={handleKeyDown}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            {/* Status options section */}
            <div className="space-y-2">
              <FormLabel>Status Options</FormLabel>
              <div className="space-y-2">
                {form.watch(`subtask_config.predefined_subtasks.${index}.status_options`)?.map((status: SubtaskStatusOption, statusIndex: number) => (
                  <div key={statusIndex} className="flex items-center gap-2">
                    <Input
                      placeholder="Status label"
                      value={status.label}
                      onKeyDown={handleKeyDown}
                      onChange={(e) => {
                        const newOptions = [...form.watch(`subtask_config.predefined_subtasks.${index}.status_options`)];
                        newOptions[statusIndex] = {
                          ...newOptions[statusIndex],
                          label: e.target.value,
                          id: status.id || statusIndex + 1,
                          order: status.order || statusIndex
                        };
                        form.setValue(`subtask_config.predefined_subtasks.${index}.status_options`, newOptions, {
                          shouldDirty: true,
                          shouldTouch: true
                        });
                      }}
                    />
                    <Select
                      value={status.color}
                      onValueChange={(value) => {
                        const newOptions = [...form.watch(`subtask_config.predefined_subtasks.${index}.status_options`)];
                        newOptions[statusIndex] = {
                          ...newOptions[statusIndex],
                          color: value
                        };
                        form.setValue(`subtask_config.predefined_subtasks.${index}.status_options`, newOptions, {
                          shouldDirty: true,
                          shouldTouch: true
                        });
                      }}
                    >
                      <SelectTrigger className="w-[180px]">
                        <SelectValue placeholder="Select color" />
                      </SelectTrigger>
                      <SelectContent>
                        {ANT_COLORS.map(color => (
                          <SelectItem
                            key={color.value}
                            value={color.value}
                          >
                            <div 
                              className="w-full h-5 flex items-center px-2 rounded"
                              style={{ 
                                backgroundColor: color.value,
                                color: ['#ffec3d', '#fff566', '#ffd666', '#fff1b8'].includes(color.value) ? '#000000' : '#ffffff'
                              }}
                            >
                              {color.name}
                            </div>
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <div className="flex items-center gap-2">
                      <Switch
                        checked={status.is_completed_state}
                        onCheckedChange={(checked) => {
                          const newOptions = [...form.watch(`subtask_config.predefined_subtasks.${index}.status_options`)];
                          newOptions[statusIndex] = {
                            ...newOptions[statusIndex],
                            is_completed_state: checked
                          };
                          form.setValue(`subtask_config.predefined_subtasks.${index}.status_options`, newOptions, {
                            shouldDirty: true,
                            shouldTouch: true
                          });
                        }}
                      />
                      <span className="text-sm">Completed State</span>
                    </div>
                    <div className="flex items-center gap-1">
                      <Button
                        variant="ghost"
                        size="icon"
                        disabled={statusIndex === 0}
                        onClick={() => {
                          const newOptions = [...form.watch(`subtask_config.predefined_subtasks.${index}.status_options`)];
                          const temp = newOptions[statusIndex];
                          newOptions[statusIndex] = {
                            ...newOptions[statusIndex - 1],
                            order: statusIndex
                          };
                          newOptions[statusIndex - 1] = {
                            ...temp,
                            order: statusIndex - 1
                          };
                          form.setValue(`subtask_config.predefined_subtasks.${index}.status_options`, newOptions, {
                            shouldDirty: true,
                            shouldTouch: true
                          });
                        }}
                      >
                        <ArrowUp className="h-4 w-4" />
                      </Button>
                      <Button
                        variant="ghost"
                        size="icon"
                        disabled={statusIndex === form.watch(`subtask_config.predefined_subtasks.${index}.status_options`).length - 1}
                        onClick={() => {
                          const newOptions = [...form.watch(`subtask_config.predefined_subtasks.${index}.status_options`)];
                          const temp = newOptions[statusIndex];
                          newOptions[statusIndex] = {
                            ...newOptions[statusIndex + 1],
                            order: statusIndex
                          };
                          newOptions[statusIndex + 1] = {
                            ...temp,
                            order: statusIndex + 1
                          };
                          form.setValue(`subtask_config.predefined_subtasks.${index}.status_options`, newOptions, {
                            shouldDirty: true,
                            shouldTouch: true
                          });
                        }}
                      >
                        <ArrowDown className="h-4 w-4" />
                      </Button>
                    </div>
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={() => {
                        const newOptions = form.watch(`subtask_config.predefined_subtasks.${index}.status_options`)
                          .filter((_: SubtaskStatusOption, i: number) => i !== statusIndex)
                          .map((opt: SubtaskStatusOption, i: number) => ({
                            ...opt,
                            order: i
                          }));
                        form.setValue(`subtask_config.predefined_subtasks.${index}.status_options`, newOptions, {
                          shouldDirty: true,
                          shouldTouch: true
                        });
                      }}
                    >
                      <Trash2 className="h-4 w-4" />
                    </Button>
                  </div>
                ))}
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => {
                    const currentOptions = form.watch(`subtask_config.predefined_subtasks.${index}.status_options`) || [];
                    const nextColorIndex = currentOptions.length % ANT_COLORS.length;
                    
                    form.setValue(`subtask_config.predefined_subtasks.${index}.status_options`, [
                      ...currentOptions,
                      {
                        id: currentOptions.length + 1,
                        label: '',
                        color: ANT_COLORS[nextColorIndex].value,
                        is_completed_state: false,
                        order: currentOptions.length
                      }
                    ], {
                      shouldDirty: true,
                      shouldTouch: true
                    });
                  }}
                >
                  <Plus className="h-4 w-4 mr-2" />
                  Add Status Option
                </Button>
              </div>
            </div>

            {/* Assignment rules section */}
            {form.watch('subtask_config.enable_assignees') && (
              <div className="space-y-4">
                <div className="flex items-center justify-between">
                  <FormLabel>Assignment Rules</FormLabel>
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => handleAddCondition(index)}
                    type="button"
                  >
                    <Plus className="h-4 w-4 mr-2" />
                    Add Rule
                  </Button>
                </div>

                {/* Display existing conditions */}
                {form.watch(`subtask_config.predefined_subtasks.${index}.assignment_conditions`)?.map((condition: SubtaskAssignmentCondition, conditionIndex: number) => (
                  <Card key={conditionIndex} className="p-3">
                    <div className="flex items-center justify-between">
                      <div>
                        <p className="text-sm font-medium">
                          If {condition.field.split('.').pop()} {condition.operator} "{condition.value}"
                        </p>
                        <div className="flex flex-wrap gap-2 mt-2">
                          {condition.assigned_to.map((userId) => {
                            const user = users.find(u => String(u.user_id) === String(userId));
                            if (!user) return null;
                            return (
                              <Badge 
                                key={userId}
                                variant="secondary"
                              >
                                {user.name} {user.surname}
                              </Badge>
                            );
                          })}
                        </div>
                      </div>
                      <div className="flex items-center gap-2">
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={() => handleEditCondition(index, conditionIndex)}
                          type="button"
                        >
                          <Pencil className="h-4 w-4" />
                        </Button>
                        <Button
                          variant="ghost"
                          size="icon"
                          type="button"
                          onClick={() => handleDeleteCondition(index, conditionIndex)}
                        >
                          <Trash2 className="h-4 w-4" />
                        </Button>
                      </div>
                    </div>
                  </Card>
                ))}

                {/* Default assignees section */}
                <div className="mt-4">
                  <div className="flex items-center justify-between">
                    <FormLabel>Default Assignees</FormLabel>
                    <div className="flex items-center gap-2">
                      <p className="text-sm text-muted-foreground">
                        {form.watch(`subtask_config.predefined_subtasks.${index}.assignment_conditions`)?.length > 0 
                          ? '(At least one assignee required)' 
                          : '(Optional)'}
                      </p>
                      <Button
                        variant="outline"
                        onClick={(e) => {
                          e.preventDefault();
                          setCurrentEditingSubtaskIndex(index);
                        }}
                        type="button"
                      >
                        Assign Users
                      </Button>
                    </div>
                  </div>
                  <div className="flex flex-wrap gap-2 mt-2">
                    {form.watch(`subtask_config.predefined_subtasks.${index}.assigned_to`)?.map((userId: string) => {
                      const user = users.find(u => String(u.user_id) === String(userId));
                      if (!user) return null;
                      return (
                        <Badge 
                          key={userId}
                          variant="secondary"
                          className="flex items-center gap-2"
                        >
                          {user.name} {user.surname}
                          <Button
                            variant="ghost"
                            size="icon"
                            className="h-4 w-4 p-0 hover:bg-transparent"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              const currentAssignees = form.watch(`subtask_config.predefined_subtasks.${index}.assigned_to`) || [];
                              form.setValue(
                                `subtask_config.predefined_subtasks.${index}.assigned_to`,
                                currentAssignees.filter((id: string) => id !== userId),
                                { shouldDirty: true, shouldTouch: true }
                              );
                            }}
                            type="button"
                          >
                            <X className="h-3 w-3" />
                          </Button>
                        </Badge>
                      );
                    })}
                  </div>
                  {form.watch(`subtask_config.predefined_subtasks.${index}.assignment_conditions`)?.length > 0 && 
                   !form.watch(`subtask_config.predefined_subtasks.${index}.assigned_to`)?.length && (
                      <p className="text-sm text-destructive mt-1">
                        At least one default assignee is required when using assignment rules
                      </p>
                    )}
                </div>
              </div>
            )}
          </div>
        ))}

        <Button
          type="button"
          variant="outline"
          onClick={() => {
            subtaskArray.append({
              name: '',
              description: '',
              auto_add: false,
              assigned_to: [],
              status_options: [],
              assignment_conditions: [],
            });
          }}
        >
          <Plus className="h-4 w-4 mr-2" />
          Add Predefined Subtask
        </Button>
      </div>

      {/* AssigneeSelection modal for default assignees */}
      {currentEditingSubtaskIndex !== null && !showConditionModal && (
        <AssigneeSelection
          visible={true}
          onClose={() => {
            setCurrentEditingSubtaskIndex(null);
          }}
          onSave={(assignees: string[]) => {
            form.setValue(
              `subtask_config.predefined_subtasks.${currentEditingSubtaskIndex}.assigned_to`,
              assignees,
              { shouldDirty: true, shouldTouch: true }
            );
            setCurrentEditingSubtaskIndex(null);
          }}
          initialAssignees={
            form.watch(`subtask_config.predefined_subtasks.${currentEditingSubtaskIndex}.assigned_to`) || []
          }
        />
      )}

      {/* AssigneeSelection modal for rule assignees */}
      {showConditionModal && isAssigneeSelectionOpen && (
        <AssigneeSelection
          visible={true}
          onClose={() => {
            setIsAssigneeSelectionOpen(false);
          }}
          onSave={(assignees: string[]) => {
            setConditionAssignees(assignees);
            setIsAssigneeSelectionOpen(false);
          }}
          initialAssignees={conditionAssignees}
          title="Select Rule Assignees"
          description="Select users to assign when this rule's conditions are met"
        />
      )}

      {/* Condition Modal */}
      <Dialog open={showConditionModal} onOpenChange={setShowConditionModal}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              {editingConditionIndex !== null ? 'Edit Assignment Rule' : 'Add Assignment Rule'}
            </DialogTitle>
            <DialogDescription className="sr-only">
              Configure conditional assignment rule for subtask
            </DialogDescription>
          </DialogHeader>

          <DialogBody>
            <div className="space-y-4">
              <div>
                <FormLabel>Deal Field</FormLabel>
                <Select
                  value={tempCondition.field}
                  onValueChange={(value) => setTempCondition(prev => ({ ...prev, field: value }))}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select field" />
                  </SelectTrigger>
                  <SelectContent>
                    {dealDataConfig.map((config: DealDataField) => (
                      <SelectItem key={config.key} value={config.key}>
                        {config.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              <div>
                <FormLabel>Operator</FormLabel>
                <Select
                  value={tempCondition.operator}
                  onValueChange={(value: string) => setTempCondition(prev => ({ ...prev, operator: value as 'equals' | 'not_equals' }))}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select operator" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="equals">Equals</SelectItem>
                    <SelectItem value="not_equals">Not Equals</SelectItem>
                  </SelectContent>
                </Select>
              </div>

              <div>
                <FormLabel>Value</FormLabel>
                <Input 
                  value={tempCondition.value || ''}
                  onChange={(e) => setTempCondition(prev => ({ ...prev, value: e.target.value }))}
                  placeholder="Enter value"
                  onKeyDown={handleKeyDown}
                />
              </div>

              <div>
                <FormLabel>Assign To</FormLabel>
                <Button
                  variant="outline"
                  onClick={() => {
                    setIsAssigneeSelectionOpen(true);
                  }}
                  className="mt-2 w-full"
                  type="button"
                >
                  Select Users
                </Button>
                <div className="flex flex-wrap gap-2 mt-2">
                  {conditionAssignees.map((userId) => {
                    const user = users.find(u => String(u.user_id) === String(userId));
                    if (!user) return null;
                    return (
                      <Badge 
                        key={userId}
                        variant="secondary"
                        className="flex items-center gap-2"
                      >
                        {user.name} {user.surname}
                        <Button
                          variant="ghost"
                          size="icon"
                          className="h-4 w-4 p-0 hover:bg-transparent"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setConditionAssignees(prev => prev.filter(id => id !== userId));
                          }}
                          type="button"
                        >
                          <X className="h-3 w-3" />
                        </Button>
                      </Badge>
                    );
                  })}
                </div>
              </div>
            </div>
          </DialogBody>

          <DialogFooter>
            <Button variant="outline" onClick={() => setShowConditionModal(false)} type="button">
              Cancel
            </Button>
            <Button 
              onClick={handleSaveCondition} 
              type="button"
              disabled={!tempCondition.field || !tempCondition.operator || !tempCondition.value || !conditionAssignees.length}
            >
              Save Rule
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  )
} 