import React from 'react';
import { Plus, X, Briefcase, Clock, Calendar, MessageSquare, Eye, ListTodo } from 'lucide-react';
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { dealDataConfig } from '@/configs/DealDataConfig';
import { dealSoftwareConfig } from '@/configs/DealSoftwareConfig';
import { dealOnboardingConfig } from '@/configs/DealOnboardingConfig';
import { FormDescription } from '@/components/ui/form';
import { Card } from '@/components/ui/card';
import { cn } from '@/lib/utils';
import { AvatarGroup } from '@/components/ui/avatars/avatar-group';
import { KANBAN_CARD_WIDTH } from '@/components/ui/specialized/kanban-card';

interface TaskCardLayoutConfigProps {
  selectedFields: string[];
  onFieldsChange: (fields: string[]) => void;
}

// Helper function to get field config from any of the three sources
const getFieldConfig = (key: string) => {
  return dealDataConfig.find(f => f.key === key) ||
         dealSoftwareConfig.find(f => f.key === key) ||
         dealOnboardingConfig.find(f => f.key === key);
};

// Preview Card Component
const PreviewCard = ({ selectedFields }: { selectedFields: string[] }) => {
  return (
    <Card className={cn(
      "w-[290px] p-2 bg-card/50 border-dashed mx-auto",
      "transition-all duration-200"
    )}
    >
      <div className="flex flex-col items-center text-center relative">
        {/* Deal Badge Skeleton */}
        <div className="w-32 h-5 bg-primary/10 rounded-full" />

        {/* Title Skeleton */}
        <div className="w-4/5 h-4 bg-primary/10 rounded-md mt-2" />
        <div className="w-3/5 h-4 bg-primary/10 rounded-md mt-1" />

        {/* Selected Field Badges */}
        <div className="mt-3 flex flex-wrap gap-1 justify-center">
          {selectedFields.map(field => {
            const fieldConfig = getFieldConfig(field);
            if (!fieldConfig) return null;
            return (
              <Badge 
                key={field} 
                variant="secondary"
                className="text-xs"
              >
                {fieldConfig.label}
              </Badge>
            );
          })}
        </div>

        {/* Date Buttons Skeleton */}
        <div className="grid grid-cols-2 gap-1.5 mt-3 w-full">
          <div className="h-8 bg-primary/10 rounded-md" />
          <div className="h-8 bg-primary/10 rounded-md" />
        </div>

        {/* Footer Skeleton */}
        <div className="flex justify-between items-center mt-3 w-full">
          {/* Avatar Skeleton */}
          <div className="flex -space-x-2">
            <div className="w-8 h-8 rounded-full bg-primary/10" />
            <div className="w-8 h-8 rounded-full bg-primary/10" />
          </div>

          {/* Action Buttons Skeleton */}
          <div className="flex gap-1">
            <div className="w-8 h-8 rounded-md bg-primary/10" />
            <div className="w-8 h-8 rounded-md bg-primary/10" />
            <div className="w-8 h-8 rounded-md bg-primary/10" />
          </div>
        </div>
      </div>
    </Card>
  );
};

export function TaskCardLayoutConfig({ selectedFields, onFieldsChange }: TaskCardLayoutConfigProps) {
  // Combine all field configs
  const allFields = [
    ...dealDataConfig.map(field => ({
      ...field,
      source: 'Deal Data'
    })),
    ...dealSoftwareConfig.map(field => ({
      ...field,
      source: 'Software'
    })),
    ...dealOnboardingConfig.map(field => ({
      ...field,
      source: 'Onboarding'
    }))
  ];

  const handleAddField = (field: string) => {
    if (!selectedFields.includes(field)) {
      const newFields = [...selectedFields, field];
      onFieldsChange(newFields);
    }
  };

  const handleRemoveField = (field: string) => {
    const newFields = selectedFields.filter(f => f !== field);
    onFieldsChange(newFields);
  };

  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-semibold mb-2">Card Fields</h3>
        <FormDescription>
          Select fields to display as badges on task cards
        </FormDescription>
      </div>

      <div className="flex items-center gap-2">
        <Select onValueChange={handleAddField}>
          <SelectTrigger className="w-[200px]">
            <SelectValue placeholder="Add field" />
          </SelectTrigger>
          <SelectContent>
            {allFields.map(field => (
              <SelectItem 
                key={field.key} 
                value={field.key}
                disabled={selectedFields.includes(field.key)}
              >
                <span className="flex items-center gap-2">
                  <span>{field.label}</span>
                  <Badge variant="outline" className="ml-auto text-xs">
                    {field.source}
                  </Badge>
                </span>
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      <div className="flex flex-wrap gap-2">
        {selectedFields.map(field => {
          const fieldConfig = getFieldConfig(field);
          if (!fieldConfig) return null;
          return (
            <Badge 
              key={field} 
              variant="secondary"
              className="flex items-center gap-1"
            >
              {fieldConfig.label}
              <Button
                variant="ghost"
                size="icon"
                className="h-4 w-4 p-0"
                onClick={() => handleRemoveField(field)}
              >
                <X className="h-3 w-3" />
              </Button>
            </Badge>
          );
        })}
      </div>

      <div className="border rounded-lg p-4 bg-background">
        <h4 className="text-sm font-medium mb-3">Preview</h4>
        <PreviewCard selectedFields={selectedFields} />
      </div>
    </div>
  );
} 