import React from 'react'
import { Badge } from '@/components/ui/badge'
import { Briefcase } from 'lucide-react'
import type { Deal } from '@/types/customers'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'

interface DealBadgeProps {
  deal: Pick<Deal, 'name' | 'pipeline' | 'onboard_status'> // Added onboard_status
  className?: string
  onClick?: () => void
}

export function DealBadge({ deal, className, onClick }: DealBadgeProps) {
  const showGreenlandFlag = deal.pipeline?.toLowerCase() === 'greenland'
  const isOnboarded = deal.onboard_status === 'onboarded' || deal.onboard_status === 'migrated'

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Badge 
            variant="secondary"
            className={`
              cursor-pointer hover:bg-secondary/80 
              ${isOnboarded 
                ? 'bg-[hsl(var(--badge-deal))] text-white hover:bg-[hsl(var(--badge-deal)/.8)]'
                : 'bg-gray-400 text-white hover:bg-gray-500'
              } 
              ${className}
            `}
            onClick={onClick}
          >
            <Briefcase className="w-3 h-3 flex-shrink-0" />
            <span className="max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap">
              {deal.name}
              {showGreenlandFlag && ' 🇬🇱'}
            </span>
          </Badge>
        </TooltipTrigger>
        {!isOnboarded && (
          <TooltipContent>
            Not onboarded
          </TooltipContent>
        )}
      </Tooltip>
    </TooltipProvider>
  )
} 