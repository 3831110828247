import React, { useState, useEffect } from 'react';
import { cn } from "@/lib/utils";
import axios from '@/api/axiosConfig';
import { toast } from 'sonner';
import { format } from "date-fns";
import { 
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerDescription,
} from "@/components/ui/drawer";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { Switch } from "@/components/ui/switch";
import { Input } from "@/components/ui/input";
import { Badge } from "@/components/ui/badge";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogBody,
} from "@/components/ui/dialog";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Calendar as CalendarIcon, Mail, ClipboardCheck, Loader2 } from 'lucide-react';
import { dealDataConfig, type DealDataField, type DropdownOption } from '@/configs/DealDataConfig';
import { dealSoftwareConfig, type SoftwareConfig } from '@/configs/DealSoftwareConfig';
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { 
  Receipt, 
  Wallet, 
  AppWindow, 
  FileText,
  Building2
} from 'lucide-react';
import { dealOnboardingConfig, type OnboardingDataFieldType } from '@/configs/DealOnboardingConfig';

interface DealDrawerProps {
  visible: boolean;
  onClose: () => void;
  dealId: number | null;
}

interface DealData {
  [key: string]: any;
}

interface Software {
  [key: string]: any;
}

interface Deal {
  id: number;
  name: string;
  data?: DealData;
  software?: Software;
  [key: string]: any;
}

interface EditableFieldConfig {
  key: string;
  label: string;
  type: 'text' | 'dropdown' | 'date' | 'number' | 'boolean';
  editable?: boolean;
  options?: DropdownOption[];
  required?: boolean;
  multiSelect?: boolean;
  placeholder?: string;
  systemStatus?: {
    setup_required: boolean;
    setup_completed: boolean;
    setup_date?: Date;
    notes?: string;
  };
}

interface DataGroup {
  title: string;
  icon: React.ReactNode;
  fields: Array<any>;
}

const dataGroups: DataGroup[] = [
  {
    title: "Company Info",
    icon: <Building2 className="h-5 w-5 text-primary" />,
    fields: [
      'data.virksomhedsform',
      'description',
      'cvr',
      'data.value',
      'is_holding',
      'data.aktiv_kunde',
      'data.hvidvask_compliant',
      'main_mailbox',
      'corpay_mailbox',
      'aiia_mailbox',
      'payment_details.reg',
      'payment_details.konto',
      'payment_details.betalingslinje',
      //'data.deal_source'
    ]
  },
  {
    title: "Accounting",
    icon: <Receipt className="h-5 w-5 text-primary" />,
    fields: [
      'data.momsfrekvens',
      'data.bogføringsfrekvens',
      'data.regnskabsprogram',
      'data.seneste_momsopgørelse',
      'data.ansvarlig_bogholder',
      'data.ansvarlig_revisor_for_årsregnskab',
      'data.hvem_er_ansvarlig_for_årsregnskab',
      'data.hvornår_slutter_næste_regnskabsår',
      'data.kan_bankkonto_integreres_i_bogføringssystem',
      'data.kundenummer_i_economic'
    ]
  },
  {
    title: "Payroll",
    icon: <Wallet className="h-5 w-5 text-primary" />,
    fields: [
      'data.lønfrekvens',
      'data.lønansvarlig',
      'data.ansvarlig_lønbogholder',
      'data.antal_medarbejdere_til_lønhandtering',
      'software.lønprogram'
    ]
  },
  {
    title: "Software",
    icon: <AppWindow className="h-5 w-5 text-primary" />,
    fields: [
      'software.lagerstyring',
      'software.kreditorstyring',
      'software.betalingsgateway',
      'software.abonnementsstyring',
      'software.debitorstyring_kredit',
      'software.debitorstyring_kontant',
      'software.projekt_og_tidsstyring',
      'software.kreditorstyring_kvitteringer',
      'software.andre_softwares',
      'software.lønprogram'
    ]
  }
];

// Create combined onboarding config with date
const onboardingConfigWithDate = [
  {
    key: 'data.onboarding_dato',
    label: 'Onboarding dato',
    type: 'date',
    editable: true,
  } as EditableFieldConfig,
  ...(dealOnboardingConfig as EditableFieldConfig[])
];

const DealDrawer: React.FC<DealDrawerProps> = ({ visible, onClose, dealId }): JSX.Element | null => {
  const [deal, setDeal] = useState<Deal | null>(null);
  const [loading, setLoading] = useState(true);
  const [editingFields, setEditingFields] = useState<Record<string, boolean>>({});
  const [changes, setChanges] = useState<Record<string, any>>({});
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [activeTab, setActiveTab] = useState("data");
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (dealId && visible) {
      fetchDealData();
    }
  }, [dealId, visible]);

  const fetchDealData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`/account/deals/${dealId}`);
      setDeal(response.data);
    } catch (error) {
      toast.error('Error fetching deal data');
    } finally {
      setLoading(false);
    }
  };

  const getNestedValue = (obj: any, path: string) => {
    return path.split('.').reduce((prev, curr) => {
      return prev ? prev[curr] : undefined;
    }, obj);
  };

  const handleEdit = (key: string, value: any) => {
    const isSoftwareField = dealSoftwareConfig.some(config => config.key === key);
    const isOnboardingField = key.startsWith('onboarding_data.');
    
    // Create a copy of the current changes
    const newChanges = { ...changes };
    
    if (isSoftwareField) {
      if (!newChanges.software) newChanges.software = {};
      newChanges.software[key] = value;
    } else if (isOnboardingField) {
      if (!newChanges.onboarding_data) newChanges.onboarding_data = {};
      const onboardingKey = key.split('.')[1];
      newChanges.onboarding_data[onboardingKey] = value;
      
      // Also update the deal state to reflect changes immediately in UI
      setDeal(prevDeal => {
        if (!prevDeal) return null;
        return {
          ...prevDeal,
          onboarding_data: {
            ...prevDeal.onboarding_data,
            [onboardingKey]: value
          }
        };
      });
    } else if (key === 'data.kundenummer_i_economic') {
      if (!newChanges.data) newChanges.data = {};
      newChanges.data.kundenummer_i_economic = value;
      newChanges.economic_customer_number = value;
    } else if (key.startsWith('data.')) {
      if (!newChanges.data) newChanges.data = {};
      const dataKey = key.split('.')[1];
      newChanges.data[dataKey] = value;
    } else {
      newChanges[key] = value;
    }
    
    setChanges(newChanges);
  };

  const handleSave = async () => {
    setConfirmVisible(true);
  };

  const confirmSave = async () => {
    try {
      setIsSaving(true);
      const updatedChanges: Record<string, any> = {};
      
      Object.entries(changes).forEach(([key, value]) => {
        // Convert empty strings to null for mailbox fields before saving
        const processedValue = (key === 'main_mailbox' || key === 'corpay_mailbox' || key === 'aiia_mailbox') && value === '' 
          ? null 
          : value;

        if (key.startsWith('data.')) {
          if (!updatedChanges.data) {
            updatedChanges.data = { ...(deal?.data || {}) };
          }
          const nestedKey = key.split('.')[1];
          updatedChanges.data[nestedKey] = processedValue;
        } else if (key.startsWith('software.')) {
          if (!updatedChanges.software) {
            updatedChanges.software = { ...(deal?.software || {}) };
          }
          const softwareKey = key.split('.')[1];
          updatedChanges.software[softwareKey] = processedValue;
        } else if (key.startsWith('onboarding_data.')) {
          if (!updatedChanges.onboarding_data) {
            updatedChanges.onboarding_data = { ...(deal?.onboarding_data || {}) };
          }
          const nestedKey = key.split('.')[1];
          const onboardingConfig = dealOnboardingConfig.find(config => config.key === key);
          
          if (onboardingConfig?.multiSelect) {
            updatedChanges.onboarding_data[nestedKey] = processedValue as string[];
          } else {
            updatedChanges.onboarding_data[nestedKey] = processedValue;
          }
        } else {
          updatedChanges[key] = processedValue;
        }
      });

      await axios.patch(`/account/deals/${dealId}`, updatedChanges);
      
      setDeal(prev => prev ? {
        ...prev,
        ...(updatedChanges.data ? { data: updatedChanges.data } : {}),
        ...(updatedChanges.software ? { software: updatedChanges.software } : {}),
        ...(updatedChanges.onboarding_data ? { onboarding_data: updatedChanges.onboarding_data } : {}),
        ...updatedChanges
      } : null);
      
      setEditingFields({});
      setChanges({});
      setConfirmVisible(false);
      setIsSaving(false);
      toast.success('Changes saved successfully');
    } catch (error) {
      console.error('Error updating deal data:', error);
      toast.error('Failed to save changes');
    }
  };

  const handleCloseDrawer = () => {
    setEditingFields({});
    setChanges({});
    onClose();
  };

  const renderEditableField = (fieldConfig: EditableFieldConfig, value: any) => {
    const isSoftwareField = dealSoftwareConfig.some(config => config.key === fieldConfig.key);
    
    // Get the current value from changes first, then fall back to original value
    let currentValue = isSoftwareField 
      ? changes.software?.[fieldConfig.key] ?? deal?.software?.[fieldConfig.key] ?? value
      : changes[fieldConfig.key] ?? value;

    // Convert null to empty string for mailbox fields
    if ((fieldConfig.key === 'main_mailbox' || fieldConfig.key === 'corpay_mailbox' || fieldConfig.key === 'aiia_mailbox') && currentValue === null) {
      currentValue = '';
    }

    if (!fieldConfig.editable) {
      return value?.toString() || 'N/A';
    }

    // Special handling for payment details fields
    if (fieldConfig.key === 'payment_details.reg' || fieldConfig.key === 'payment_details.konto') {
      const isReg = fieldConfig.key === 'payment_details.reg';
      const inputValue = currentValue ?? '';
      
      return (
        <Input
          type="text"
          value={inputValue}
          placeholder={fieldConfig.placeholder}
          onChange={(e) => {
            const value = e.target.value.replace(/[^0-9]/g, ''); // Only allow numbers
            if (isReg && value.length <= 4) { // Reg nr max 4 digits
              handleEdit(fieldConfig.key, value);
            } else if (!isReg && value.length <= 10) { // Konto nr max 10 digits
              handleEdit(fieldConfig.key, value);
            }
          }}
          className={cn(
            "h-9 px-2",
            isReg ? "w-20" : "flex-1" // Make reg nr input narrow, konto nr takes remaining space
          )}
        />
      );
    }

    switch (fieldConfig.type) {
      case 'text':
      case 'number':
        if (fieldConfig.key === 'data.value' || fieldConfig.key === 'data.seneste_momsopgørelse') {
          return (
            <div className="relative">
              <Input
                type={fieldConfig.type}
                value={currentValue ?? ''}
                placeholder={fieldConfig.placeholder}
                onChange={(e) => handleEdit(fieldConfig.key, e.target.value)}
                className="h-9 px-2 pr-12"
              />
              <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none text-sm text-muted-foreground">
                DKK
              </div>
            </div>
          );
        }
        if (fieldConfig.key === 'main_mailbox' || fieldConfig.key === 'corpay_mailbox' || fieldConfig.key === 'aiia_mailbox') {
          return (
            <div className="relative">
              <Input
                type={fieldConfig.type}
                value={currentValue ?? ''}
                placeholder={fieldConfig.placeholder}
                onChange={(e) => handleEdit(fieldConfig.key, e.target.value)}
                className="h-9 px-2 pr-10"
              />
              <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                <Mail className="h-4 w-4 text-muted-foreground" />
              </div>
            </div>
          );
        }
        return (
          <Input
            type={fieldConfig.type}
            value={currentValue ?? ''}
            placeholder={fieldConfig.placeholder}
            onChange={(e) => handleEdit(fieldConfig.key, e.target.value)}
            className="h-9 px-2"
          />
        );

      case 'dropdown':
        if (fieldConfig.multiSelect) {
          const selectedValues = Array.isArray(currentValue) ? currentValue : [currentValue].filter(Boolean);
          
          return (
            <Select
              onValueChange={(value: string) => {
                if (value === '') return;
                
                // Toggle selection
                const newValues = selectedValues.includes(value)
                  ? selectedValues.filter(v => v !== value)
                  : [...selectedValues, value];
                
                handleEdit(fieldConfig.key, newValues);
              }}
              value=""
            >
              <SelectTrigger className="editable-field-wrapper min-h-[36px] h-auto">
                <div className="flex flex-wrap gap-1 py-0.5">
                  {selectedValues.length > 0 ? (
                    selectedValues.map(value => {
                      const option = fieldConfig.options?.find(opt => opt.value === value);
                      if (!option) return null;
                      return (
                        <span
                          key={value}
                          className="inline-flex items-center gap-1 px-2 py-0.5 rounded text-white text-xs"
                          style={{ backgroundColor: option.color }}
                        >
                          {option.label}
                        </span>
                      );
                    })
                  ) : (
                    <SelectValue placeholder="Select options" />
                  )}
                </div>
              </SelectTrigger>
              <SelectContent>
                {fieldConfig.options?.map((option: { value: string; label: string; color: string }) => (
                  <SelectItem 
                    key={option.value} 
                    value={option.value}
                  >
                    <div className="flex items-center gap-2">
                      <div 
                        className={cn(
                          "w-4 h-4 rounded border flex items-center justify-center",
                          selectedValues.includes(option.value) 
                            ? "bg-primary border-primary" 
                            : "border-input"
                        )}
                      >
                        {selectedValues.includes(option.value) && (
                          <span className="text-white text-xs">✓</span>
                        )}
                      </div>
                      <span 
                        className="inline-block px-2 py-0.5 rounded text-white"
                        style={{ backgroundColor: option.color }}
                      >
                        {option.label}
                      </span>
                    </div>
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          );
        }
        
        return (
          <Select
            value={currentValue?.toString()}
            onValueChange={(value: string) => handleEdit(fieldConfig.key, value)}
          >
            <SelectTrigger className="editable-field-wrapper">
              <SelectValue placeholder="Select option" />
            </SelectTrigger>
            <SelectContent>
              {fieldConfig.options?.map((option: { value: string; label: string; color: string }) => (
                <SelectItem 
                  key={option.value} 
                  value={option.value}
                >
                  <span 
                    className="inline-block px-2 py-0.5 rounded text-white"
                    style={{ backgroundColor: option.color }}
                  >
                    {option.label}
                  </span>
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        );

      case 'boolean':
        return (
          <Switch
            checked={currentValue ?? false}
            onCheckedChange={(checked) => handleEdit(fieldConfig.key, checked)}
            className="data-[state=checked]:bg-primary"
          />
        );

      case 'date':
        return (
          <Popover>
            <PopoverTrigger asChild>
              <Button
                variant="outline"
                className={cn(
                  "w-full justify-start text-left font-normal",
                  !currentValue && "text-muted-foreground"
                )}
              >
                <CalendarIcon className="mr-2 h-4 w-4" />
                {currentValue ? format(new Date(currentValue), 'yyyy-MM-dd') : <span>Pick a date</span>}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
              <Calendar
                mode="single"
                selected={currentValue ? new Date(currentValue) : undefined}
                onSelect={(date) => {
                  // Format the date as YYYY-MM-DD before saving
                  if (date) {
                    const formattedDate = format(date, 'yyyy-MM-dd');
                    handleEdit(fieldConfig.key, formattedDate);
                  } else {
                    handleEdit(fieldConfig.key, null);
                  }
                }}
                initialFocus
              />
            </PopoverContent>
          </Popover>
        );

      default:
        return currentValue?.toString() || 'N/A';
    }
  };

  const renderGroupedData = () => {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 px-4">
        {dataGroups.map((group) => (
          <div 
            key={group.title} 
            className="flex flex-col bg-card rounded-lg shadow-sm"
          >
            <div className="flex items-center gap-2 p-3 border-b bg-muted/30 rounded-t-lg">
              {group.icon}
              <h3 className="font-semibold text-card-foreground">
                {group.title}
              </h3>
            </div>
            <ScrollArea className="h-[calc(85vh-180px)]">
              <div className="p-4 space-y-4">
                {group.fields.map((fieldKey) => {
                  const config = fieldKey.startsWith('software.') 
                    ? dealSoftwareConfig.find(c => `software.${c.key}` === fieldKey)
                    : dealDataConfig.find(c => c.key === fieldKey);
                  
                  if (!config) return null;

                  const value = getNestedValue(deal, fieldKey);
                  
                  // Special handling for payment details to show them on the same row
                  if (fieldKey === 'payment_details.reg') {
                    const kontoConfig = dealDataConfig.find(c => c.key === 'payment_details.konto');
                    const betalingslinjeConfig = dealDataConfig.find(c => c.key === 'payment_details.betalingslinje');
                    const kontoValue = getNestedValue(deal, 'payment_details.konto');
                    const betalingslinjeValue = getNestedValue(deal, 'payment_details.betalingslinje');
                    
                    return (
                      <div 
                        key={fieldKey} 
                        className="space-y-2 p-2 rounded-md hover:bg-muted/40 transition-colors"
                      >
                        <div className="text-sm font-medium text-muted-foreground">
                          Payment Details
                        </div>
                        <div className="flex flex-col gap-2">
                          <div className="flex gap-2">
                            <div className="flex flex-col gap-1">
                              <div className="text-xs text-muted-foreground">
                                {config.label}
                              </div>
                              {renderEditableField(config as EditableFieldConfig, value)}
                            </div>
                            <div className="flex flex-col gap-1 flex-1">
                              <div className="text-xs text-muted-foreground">
                                {kontoConfig?.label}
                              </div>
                              {kontoConfig && renderEditableField(kontoConfig as EditableFieldConfig, kontoValue)}
                            </div>
                          </div>
                          <div className="flex flex-col gap-1">
                            <div className="text-xs text-muted-foreground">
                              {betalingslinjeConfig?.label}
                            </div>
                            {betalingslinjeConfig && renderEditableField(betalingslinjeConfig as EditableFieldConfig, betalingslinjeValue)}
                          </div>
                        </div>
                      </div>
                    );
                  }
                  
                  // Skip konto and betalingslinje as they're handled with reg
                  if (fieldKey === 'payment_details.konto' || fieldKey === 'payment_details.betalingslinje') {
                    return null;
                  }

                  return (
                    <div 
                      key={fieldKey} 
                      className="space-y-2 p-2 rounded-md hover:bg-muted/40 transition-colors"
                    >
                      <div className="text-sm font-medium text-muted-foreground">
                        {config.label}
                      </div>
                      <div className="min-h-[32px]">
                        {renderEditableField(config as EditableFieldConfig, value)}
                      </div>
                    </div>
                  );
                })}
              </div>
            </ScrollArea>
          </div>
        ))}
      </div>
    );
  };

  const renderSetupTab = () => {
    if (!deal) return null;

    return (
      <div className="grid grid-cols-2 gap-4 px-6 h-[calc(85vh-180px)]">
        {/* Left Column */}
        <div className="flex flex-col min-h-0">
          <ScrollArea className="flex-1 min-h-0">
            <div className="space-y-3 pr-4">
              {/* Date and System Fields */}
              <div className="space-y-2">
                {onboardingConfigWithDate
                  .filter(field => field.type === 'date' || field.key === 'onboarding_data.new_accounting_system')
                  .map((fieldConfig) => {
                    const value = fieldConfig.key.startsWith('onboarding_data.') 
                      ? changes.onboarding_data?.[fieldConfig.key.split('.')[1]] ?? getNestedValue(deal, fieldConfig.key)
                      : getNestedValue(deal, fieldConfig.key);
                      
                    return (
                      <div key={fieldConfig.key} className="bg-card rounded-md p-2 border border-border">
                        <div className="flex flex-col gap-1">
                          <div className="text-sm font-medium text-muted-foreground flex items-center gap-2">
                            {fieldConfig.label}
                            {fieldConfig.required && <span className="text-red-500">*</span>}
                          </div>
                          {renderEditableField(fieldConfig, value)}
                        </div>
                      </div>
                    );
                })}
              </div>

              {/* Tools Setup Section */}
              {onboardingConfigWithDate
                .filter(field => field.key === 'onboarding_data.tools_to_setup')
                .map((fieldConfig) => {
                  const value = changes.onboarding_data?.tools_to_setup ?? getNestedValue(deal, fieldConfig.key);
                  const selectedTools = Array.isArray(value) ? value : [];
                  
                  return (
                    <div key={fieldConfig.key} className="bg-card rounded-lg border border-border">
                      <div className="p-3 border-b border-border bg-muted/30">
                        <h3 className="font-medium">
                          {fieldConfig.label}
                        </h3>
                      </div>
                      <div className="p-3">
                        <div className="grid grid-cols-2 gap-2">
                          {fieldConfig.options?.map((option) => (
                            <div 
                              key={option.value}
                              className={cn(
                                "p-2 rounded-md border border-border cursor-pointer transition-colors",
                                selectedTools.includes(option.value) 
                                  ? "bg-primary/10 border-primary" 
                                  : "hover:bg-muted"
                              )}
                              onClick={() => {
                                const newValue = selectedTools.includes(option.value)
                                  ? selectedTools.filter(t => t !== option.value)
                                  : [...selectedTools, option.value];
                                handleEdit('onboarding_data.tools_to_setup', newValue);
                              }}
                            >
                              <div className="flex items-center gap-2">
                                <div 
                                  className="w-2 h-2 rounded-full"
                                  style={{ backgroundColor: option.color }}
                                />
                                <span className="text-sm">
                                  {option.label}
                                </span>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  );
              })}
            </div>
          </ScrollArea>
        </div>

        {/* Right Column - Notes and Additional Tasks */}
        <div className="flex flex-col min-h-0">
          <ScrollArea className="flex-1 min-h-0">
            <div className="grid grid-rows-2 gap-4 pl-4">
              {onboardingConfigWithDate
                .filter(field => field.type === 'text')
                .map((fieldConfig) => {
                  const value = fieldConfig.key.startsWith('onboarding_data.') 
                    ? changes.onboarding_data?.[fieldConfig.key.split('.')[1]] ?? getNestedValue(deal, fieldConfig.key)
                    : getNestedValue(deal, fieldConfig.key);
                  
                  return (
                    <div 
                      key={fieldConfig.key} 
                      className="bg-card rounded-lg border border-border h-full"
                    >
                      <div className="p-3 border-b border-border bg-muted/30">
                        <h3 className="font-medium">
                          {fieldConfig.label}
                        </h3>
                      </div>
                      <div className="p-3 h-[calc(100%-48px)]">
                        {renderEditableField(fieldConfig, value)}
                      </div>
                    </div>
                  );
              })}
            </div>
          </ScrollArea>
        </div>
      </div>
    );
  };

  const renderChanges = () => {
    return Object.entries(changes).map(([key, value]) => {
      // Handle onboarding data changes
      if (key === 'onboarding_data') {
        return Object.entries(value as Record<string, unknown>).map(([onboardingKey, onboardingValue]) => {
          const fullKey = `onboarding_data.${onboardingKey}`;
          const fieldConfig = onboardingConfigWithDate.find(config => config.key === fullKey);
          if (!fieldConfig) return null;

          const originalValue = deal?.onboarding_data?.[onboardingKey];

          if (fieldConfig.type === 'dropdown') {
            if (fieldConfig.multiSelect && Array.isArray(onboardingValue)) {
              // Handle multi-select dropdowns (like tools_to_setup)
              const getSelectedLabels = (values: string[]) => {
                return values.map(val => {
                  const option = fieldConfig.options?.find(opt => opt.value === val);
                  return option?.label || val;
                }).join(', ') || 'None';
              };

              return (
                <div key={onboardingKey} className="flex items-center gap-2 py-2">
                  <span className="font-medium min-w-[200px]">{fieldConfig.label}:</span>
                  <Badge variant="secondary">
                    {getSelectedLabels(originalValue as string[] || [])}
                  </Badge>
                  <span className="text-muted-foreground mx-2">→</span>
                  <Badge variant="secondary">
                    {getSelectedLabels(onboardingValue as string[])}
                  </Badge>
                </div>
              );
            } else {
              // Handle single-select dropdowns
              const getOptionLabel = (val: string) => {
                const option = fieldConfig.options?.find(opt => opt.value === val);
                return option?.label || val;
              };

              const getOptionColor = (val: string) => {
                const option = fieldConfig.options?.find(opt => opt.value === val);
                return option?.color || '#d9d9d9';
              };

              return (
                <div key={onboardingKey} className="flex items-center gap-2 py-2">
                  <span className="font-medium min-w-[200px]">{fieldConfig.label}:</span>
                  <Badge 
                    variant="custom"
                    className="text-white"
                    style={{ backgroundColor: getOptionColor(String(originalValue)) }}
                  >
                    {getOptionLabel(String(originalValue)) || 'N/A'}
                  </Badge>
                  <span className="text-muted-foreground mx-2">→</span>
                  <Badge
                    variant="custom"
                    className="text-white"
                    style={{ backgroundColor: getOptionColor(String(onboardingValue)) }}
                  >
                    {getOptionLabel(String(onboardingValue))}
                  </Badge>
                </div>
              );
            }
          }

          // Handle date fields
          if (fieldConfig.type === 'date') {
            return (
              <div key={onboardingKey} className="flex items-center gap-2 py-2">
                <span className="font-medium min-w-[200px]">{fieldConfig.label}:</span>
                <Badge variant="secondary">
                  {originalValue ? format(new Date(originalValue as string), 'yyyy-MM-dd') : 'N/A'}
                </Badge>
                <span className="text-muted-foreground mx-2">→</span>
                <Badge variant="secondary">
                  {onboardingValue ? format(new Date(onboardingValue as string), 'yyyy-MM-dd') : 'N/A'}
                </Badge>
              </div>
            );
          }

          // Handle other field types (text, etc.)
          return (
            <div key={onboardingKey} className="flex items-center gap-2 py-2">
              <span className="font-medium min-w-[200px]">{fieldConfig.label}:</span>
              <Badge variant="secondary">{String(originalValue) || 'N/A'}</Badge>
              <span className="text-muted-foreground mx-2">→</span>
              <Badge variant="secondary">{String(onboardingValue)}</Badge>
            </div>
          );
        }).filter(Boolean);
      }

      // Handle software changes
      if (key === 'software') {
        return Object.entries(value as Record<string, unknown>).map(([softwareKey, softwareValue]) => {
          const softwareConfig = dealSoftwareConfig.find(config => config.key === softwareKey);
          if (!softwareConfig) return null;

          const originalValue = deal?.software?.[softwareKey];
          
          if (softwareConfig.type === 'dropdown' && softwareConfig.options) {
            const getOptionLabel = (val: string) => {
              const option = softwareConfig.options?.find(opt => opt.value === val);
              return option?.label || val;
            };

            const getOptionColor = (val: string) => {
              const option = softwareConfig.options?.find(opt => opt.value === val);
              return option?.color || '#d9d9d9';
            };

            return (
              <div key={softwareKey} className="flex items-center gap-2 py-2">
                <span className="font-medium min-w-[200px]">{softwareConfig.label}:</span>
                <Badge 
                  variant="custom"
                  className="text-white"
                  style={{ backgroundColor: getOptionColor(String(originalValue)) }}
                >
                  {getOptionLabel(String(originalValue)) || 'N/A'}
                </Badge>
                <span className="text-muted-foreground mx-2">→</span>
                <Badge
                  variant="custom"
                  className="text-white"
                  style={{ backgroundColor: getOptionColor(String(softwareValue)) }}
                >
                  {getOptionLabel(String(softwareValue))}
                </Badge>
              </div>
            );
          }
        }).filter(Boolean);
      }

      // Handle data changes
      if (key === 'data') {
        return Object.entries(value as Record<string, unknown>).map(([dataKey, dataValue]) => {
          const fieldConfig = dealDataConfig.find(config => config.key === `data.${dataKey}`);
          if (!fieldConfig) return null;

          const originalValue = deal?.data?.[dataKey];

          if (fieldConfig.type === 'dropdown' && fieldConfig.options) {
            const getOptionLabel = (val: string) => {
              const option = fieldConfig.options?.find(opt => opt.value === val);
              return option?.label || val;
            };

            const getOptionColor = (val: string) => {
              const option = fieldConfig.options?.find(opt => opt.value === val);
              return option?.color || '#d9d9d9';
            };

            return (
              <div key={dataKey} className="flex items-center gap-2 py-2">
                <span className="font-medium min-w-[200px]">{fieldConfig.label}:</span>
                <Badge 
                  variant="custom"
                  className="text-white"
                  style={{ backgroundColor: getOptionColor(String(originalValue)) }}
                >
                  {getOptionLabel(String(originalValue)) || 'N/A'}
                </Badge>
                <span className="text-muted-foreground mx-2">→</span>
                <Badge
                  variant="custom"
                  className="text-white"
                  style={{ backgroundColor: getOptionColor(String(dataValue)) }}
                >
                  {getOptionLabel(String(dataValue))}
                </Badge>
              </div>
            );
          }

          // Handle other field types
          return (
            <div key={dataKey} className="flex items-center gap-2 py-2">
              <span className="font-medium min-w-[200px]">{fieldConfig.label}:</span>
              <Badge variant="secondary">{String(originalValue) || 'N/A'}</Badge>
              <span className="text-muted-foreground mx-2">→</span>
              <Badge variant="secondary">{String(dataValue)}</Badge>
            </div>
          );
        }).filter(Boolean);
      }

      // Handle other direct field changes
      const fieldConfig = dealDataConfig.find(config => config.key === key);
      if (!fieldConfig) return null;

      const originalValue = getNestedValue(deal, key);

      return (
        <div key={key} className="flex items-center gap-2 py-2">
          <span className="font-medium min-w-[200px]">{fieldConfig.label}:</span>
          <Badge variant="secondary">{String(originalValue) || 'N/A'}</Badge>
          <span className="text-muted-foreground mx-2">→</span>
          <Badge variant="secondary">{String(value)}</Badge>
        </div>
      );
    }).filter(Boolean);
  };

  const isEconomicUser = deal?.data?.regnskabsprogram === 'Economic';

  if (!visible) return null;

  return (
    <>
      <Drawer open={visible} onOpenChange={handleCloseDrawer}>
        <DrawerContent className="h-[85vh]">
          <Tabs value={activeTab} onValueChange={setActiveTab} className="flex flex-col h-full">
            <DrawerHeader className="flex flex-row items-center justify-between space-x-4">
              <div className="flex-shrink-0">
                <DrawerTitle className="text-primary">
                  {deal?.name || 'Deal Details'}
                </DrawerTitle>
                <DrawerDescription>
                  Manage deal information and settings
                </DrawerDescription>
              </div>
              <TabsList className={cn(
                "grid w-[400px]",
                isEconomicUser ? "grid-cols-3" : "grid-cols-2"
              )}>
                <TabsTrigger value="data">Data</TabsTrigger>
                <TabsTrigger value="setup">Setup</TabsTrigger>
                {isEconomicUser && (
                  <TabsTrigger value="officebot">Officebot</TabsTrigger>
                )}
              </TabsList>
              <div className="flex gap-2 flex-shrink-0">
                <Button 
                  onClick={handleSave} 
                  disabled={!Object.keys(changes).length}
                  className="shrink-0"
                >
                  Save Changes
                </Button>
                <Button 
                  variant="outline" 
                  onClick={handleCloseDrawer}
                  className="shrink-0"
                >
                  Close
                </Button>
              </div>
            </DrawerHeader>

            {loading ? (
              <div className="flex justify-center items-center h-full">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary" />
              </div>
            ) : (
              <div className="flex-1 overflow-hidden">
                <TabsContent value="data" className="mt-0 h-full">
                  {renderGroupedData()}
                </TabsContent>
                <TabsContent value="setup" className="mt-0 h-full">
                  {renderSetupTab()}
                </TabsContent>
                {isEconomicUser && (
                  <TabsContent value="officebot" className="mt-0 h-full">
                    {/* Officebot content will go here */}
                  </TabsContent>
                )}
              </div>
            )}
          </Tabs>
        </DrawerContent>
      </Drawer>

      <Dialog open={confirmVisible} onOpenChange={setConfirmVisible}>
        <DialogContent className="max-w-3xl">
          <DialogHeader>
            <DialogTitle>Confirm Changes</DialogTitle>
            <DialogDescription className="sr-only">
              Review the changes before saving
            </DialogDescription>
          </DialogHeader>
          <DialogBody maxBodyHeight>
            {renderChanges()}
          </DialogBody>
          <DialogFooter>
            <Button variant="outline" onClick={() => setConfirmVisible(false)} disabled={isSaving}>
              Cancel
            </Button>
            <Button onClick={confirmSave} disabled={isSaving}>
              {isSaving ? (
                <>
                  <Loader2 className="h-4 w-4 animate-spin mr-2" />
                  Saving...
                </>
              ) : (
                'Save Changes'
              )}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export { DealDrawer };
