import * as React from "react";
import { Badge } from "@/components/ui/badge";
import { cn } from "@/lib/utils";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import { getTextColor } from "@/lib/color-utils";

interface TaskBadgeProps {
  items: Array<{
    key: string;
    value: string;
    color?: string;
    label: string;
  }>;
}

export const TaskBadge: React.FC<TaskBadgeProps> = ({ items }) => {

  if (!items.length) return null;

  return (
    <TooltipPrimitive.Provider>
      <div 
        className="flex flex-wrap gap-1" 
        onClick={(e) => e.stopPropagation()}
      >
        {items.map(({ key, value, color, label }) => (
          <TooltipPrimitive.Root key={key} delayDuration={0}>
            <TooltipPrimitive.Trigger asChild>
              <div className="inline-block">
                <Badge
                  variant="secondary"
                  className="cursor-default"
                  style={{
                    backgroundColor: color,
                    color: color ? getTextColor(color) : undefined
                  }}
                  onClick={(e) => e.stopPropagation()}
                >
                  {value}
                </Badge>
              </div>
            </TooltipPrimitive.Trigger>
            <TooltipPrimitive.Portal>
              <TooltipPrimitive.Content
                side="top"
                align="center"
                className="z-[100] overflow-hidden rounded-md bg-popover px-3 py-1.5 text-xs text-popover-foreground shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2"
                sideOffset={5}
              >
                <span>{label}: {value}</span>
                <TooltipPrimitive.Arrow className="fill-popover" />
              </TooltipPrimitive.Content>
            </TooltipPrimitive.Portal>
          </TooltipPrimitive.Root>
        ))}
      </div>
    </TooltipPrimitive.Provider>
  );
};