import React, { useState, useEffect } from 'react';
import axios from '@/api/axiosConfig';
import { toast } from 'sonner';
import { 
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle 
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Badge } from "@/components/ui/badge";
import { Separator } from "@/components/ui/separator";
import { 
  Download,
  Database,
  Users,
  Calendar,
  Tag,
  ChevronRight
} from 'lucide-react';
import { cn } from "@/lib/utils";

interface DealField {
  id: string;
  name: string;
  key: string;
  field_type: string;
  options: Array<{
    id: string;
    label: string;
  }>;
}

interface PipedriveUser {
  id: string;
  name: string;
  email: string;
  active: boolean;
}

interface PipedriveData {
  users: PipedriveUser[];
  who_booked_meeting: DealField | null;
}

const AdminPipedrive = () => {
  const [selectedStage, setSelectedStage] = useState('29');
  const [selectedStatus, setSelectedStatus] = useState('won');
  const [dealFields, setDealFields] = useState<PipedriveData | null>(null);
  const [loading, setLoading] = useState(false);

  const fetchDealFields = async () => {
    try {
      setLoading(true);
      const response = await axios.get('/admin/pipedrive/dealfields');
      setDealFields(response.data);
      toast.success('Deal fields fetched successfully!');
    } catch (error: any) {
      toast.error('Error: ' + (error.response?.data?.error || error.message));
    } finally {
      setLoading(false);
    }
  };

  const handleExportDeals = async () => {
    try {
      const response = await axios.get('/admin/pipedrive/export-deals', {
        params: {
          stage_id: selectedStage,
          status: selectedStatus
        },
        responseType: 'blob'
      });
      
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'pipedrive_deals.csv');
      document.body.appendChild(link);
      link.click();
      link.remove();
      
      toast.success('Deals exported successfully!');
    } catch (error: any) {
      toast.error('Error: ' + (error.response?.data?.error || error.message));
    }
  };

  return (
    <div className="container mx-auto p-6 space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-3xl font-bold">Pipedrive Administration</h1>
        <Button 
          onClick={fetchDealFields}
          disabled={loading}
          className="gap-2"
        >
          <Database className="h-4 w-4" />
          {loading ? 'Fetching...' : 'Fetch Pipedrive Data'}
        </Button>
      </div>

      {dealFields && (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {/* Pipedrive Users Card */}
          {dealFields.users.length > 0 && (
            <Card>
              <CardHeader>
                <CardTitle className="flex items-center gap-2">
                  <Users className="h-5 w-5 text-primary" />
                  Pipedrive Users
                </CardTitle>
                <CardDescription>
                  All users in your Pipedrive account
                </CardDescription>
              </CardHeader>
              <CardContent>
                <ScrollArea className="h-[400px] pr-4">
                  <div className="space-y-2">
                    {dealFields.users.map((user) => (
                      <div 
                        key={user.id}
                        className="flex items-center justify-between p-3 rounded-lg border bg-card hover:bg-accent/10 transition-colors"
                      >
                        <div className="flex items-center gap-3">
                          <div className="h-8 w-8 rounded-full bg-primary/10 flex items-center justify-center">
                            <Users className="h-4 w-4 text-primary" />
                          </div>
                          <div className="flex flex-col">
                            <span className="font-medium">{user.name}</span>
                            <span className="text-sm text-muted-foreground">{user.email}</span>
                          </div>
                        </div>
                        <Badge variant="outline">ID: {user.id}</Badge>
                      </div>
                    ))}
                  </div>
                </ScrollArea>
              </CardContent>
            </Card>
          )}

          {/* Who Booked Meeting Card */}
          {dealFields.who_booked_meeting && (
            <Card>
              <CardHeader>
                <CardTitle className="flex items-center gap-2">
                  <Calendar className="h-5 w-5 text-primary" />
                  Who Booked Meeting Field
                </CardTitle>
                <CardDescription>
                  Field ID: {dealFields.who_booked_meeting.id}
                </CardDescription>
              </CardHeader>
              <CardContent>
                <ScrollArea className="h-[400px] pr-4">
                  <div className="space-y-2">
                    {dealFields.who_booked_meeting.options.map((option) => (
                      <div 
                        key={option.id}
                        className="flex items-center justify-between p-3 rounded-lg border bg-card hover:bg-accent/10 transition-colors"
                      >
                        <div className="flex items-center gap-3">
                          <div className="h-8 w-8 rounded-full bg-primary/10 flex items-center justify-center">
                            <Calendar className="h-4 w-4 text-primary" />
                          </div>
                          <span className="font-medium">{option.label}</span>
                        </div>
                        <Badge variant="outline">ID: {option.id}</Badge>
                      </div>
                    ))}
                  </div>
                </ScrollArea>
              </CardContent>
            </Card>
          )}
        </div>
      )}

      {/* Export Deals Card */}
      <Card>
        <CardHeader>
          <CardTitle className="flex items-center gap-2">
            <Download className="h-5 w-5 text-primary" />
            Export Pipedrive Deals
          </CardTitle>
          <CardDescription>
            Export deals based on stage and status
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-4">
          <Select
            value={selectedStage}
            onValueChange={setSelectedStage}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select stage" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="29">Pending onboarding</SelectItem>
              <SelectItem value="32">Onboarded</SelectItem>
            </SelectContent>
          </Select>

          <Select
            value={selectedStatus}
            onValueChange={setSelectedStatus}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select status" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="won">All won deals</SelectItem>
              <SelectItem value="active">Aktive kunder</SelectItem>
            </SelectContent>
          </Select>
        </CardContent>
        <CardFooter>
          <Button 
            onClick={handleExportDeals}
            className="w-full gap-2"
          >
            <Download className="h-4 w-4" />
            Export Deals
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
};

export default AdminPipedrive;