import { CustomTask } from './custom-task'
import { StoredUser } from './user'
import { Contact, Product, Account } from './customers'
import { CardConfig } from '@/taskboards/config/card'
import { ColumnDef } from '@/components/ui/specialized/mod-table'

export interface BoardStatusOption {
  id: number;
  value: string;
  label: string;
  color: string;
  description?: string | null;
  order?: number;
  is_completed_state?: boolean;
}

export interface ProgressStatusOption extends BoardStatusOption {
  is_completed_state?: boolean;
}

export interface SubTask {
  id: number;
  title: string;
  description?: string;
  work_date?: string | null;
  due_date?: string | null;
  assigned_users?: string[];
  progress_status_id: number;
  created_by: number;
  created_at: string;
  updated_at: string;
  comments?: Comment[];
  updates?: Update[];
  parent_task_id?: number;
}

export interface SubtaskStatusOption {
  id: number;
  label: string;
  color: string;
  is_completed_state?: boolean;
  order?: number;
}

export interface PredefinedSubtask {
  name: string;
  description?: string;
  auto_add: boolean;
  assigned_to?: string[];
  status_options: SubtaskStatusOption[];
}

export interface AutomationRule {
  id: string;
  type: 'task' | 'subtask';
  trigger: {
    type: 'status_change';
    value: string;
    subtask_id?: string;
  };
  conditions: {
    type: 'deal_data' | 'main_task_status';
    field: string;
    operator: 'equals' | 'not_equals';
    value: string;
  }[];
  actions: {
    type: 'assign_users' | 'change_status' | 'change_subtask_status';
    users?: string[];
    status_id?: string;
    subtask_id?: string;
    target_type?: 'main_task' | 'subtask';
  }[];
}

export interface SubtaskConfig {
  show_in_my_work: boolean;
  enable_assignees: boolean;
  enable_subtask_progress: boolean;
  predefined_subtasks: PredefinedSubtask[];
  automations: AutomationRule[];
}

export interface BoardNotifications {
  enabled: boolean;
  task_assignments: boolean;
  subtask_assignments: boolean;
  task_comments: boolean;
  subtask_comments: boolean;
  task_updates: boolean;
  subtask_updates: boolean;
}

export type KanbanSource = 'progress' | 'board';

export interface CompletionDrawerSection {
  id: string;
  type: 'time_tracking' | 'comment' | 'number_input' | 'checkbox' | 'text_input' | 'select';
  title: string;
  description?: string;
  required: boolean;
  placeholder?: string;
  config?: {
    options?: Array<{ label: string; value: string; }>;  // For select type
    defaultValue?: any;
    min?: number;  // For number inputs
    max?: number;
    minLength?: number;  // For comments
    placeholder?: string;
    showHistory?: boolean;  // For time tracking
    allowComments?: boolean;  // For time tracking
  };
}

export interface CompletionSettings {
  enable_task_completion_events: boolean;
  enable_subtask_completion_events: boolean;
  show_confetti: boolean;
  show_completion_drawer: boolean;
  drawer_config?: {
    title?: string;
    description?: string;
    sections: CompletionDrawerSection[];
  };
}

export interface BoardConfig {
  requires_deal: boolean;
  requires_work_date: boolean;
  requires_due_date: boolean;
  show_in_my_work: boolean;
  enable_time_tracking: boolean;
  enable_subtasks: boolean;
  enable_assignees: boolean;
  simplify_main_tasks: boolean;
  enable_automations: boolean;
  enable_in_flows: boolean;
  icon: string;
  kanban_source: 'progress' | 'board';
  progress_status_options: ProgressStatusOption[];
  board_status_options: BoardStatusOption[];
  automations: AutomationRule[];
  notifications: {
    enabled: boolean;
    task_assignments: boolean;
    subtask_assignments: boolean;
    task_comments: boolean;
    subtask_comments: boolean;
    task_updates: boolean;
    subtask_updates: boolean;
  };
  card_fields: string[];
  enable_completion_events: boolean;
  completion_settings: CompletionSettings;
  default_view: 'kanban' | 'table' | 'calendar';
  enable_calendar_view: boolean;
  enable_recurring_tasks: boolean;
  recurring_task_settings: RecurrenceSettings;
}

interface BoardAccessPermission {
  user: StoredUser;
  user_id: number;
}

export interface Board {
  id: number;
  name: string;
  slug: string;
  description?: string;
  is_public: boolean;
  board_type: 'taskboard' | 'project';
  board_config: BoardConfig;
  subtask_config?: SubtaskConfig;
  created_by: number;
  created_at: string;
  updated_at: string;
  user_access?: number[];
  admin_access?: number[];
  access_permissions?: {
    user_id: number;
    is_admin: boolean;
    user: {
      user_id: number;
      name: string;
      surname: string;
      avatar_urls: string[];
    };
  }[];
  require_deal?: boolean;
}

export interface BoardSettingsModalProps {
  open: boolean
  onClose: () => void
  board: Board
  onBoardUpdate: (updatedBoard: Board) => void
  users: StoredUser[]
}

export interface CreateTaskModalProps {
  open: boolean
  onClose: () => void
  onSubmit: (values: Partial<CustomTask>) => void
  users: StoredUser[]
  board: Board | undefined
}

export interface CustomBoardKanbanProps {
  tasks: CustomTask[]
  statusOptions: BoardStatusOption[]
  onTaskUpdate: (taskId: number, updatedData: Partial<CustomTask> | null) => void
  users: StoredUser[]
  kanbanSource?: 'board' | 'progress'
  board: Board | null
}

export interface CustomBoardTask {
  id: number;
  board_id: number;
  deal_id?: number;
  deal_name?: string;
  title: string;
  description?: string;
  progress_status_id: number;
  board_status_id?: number;
  work_date?: string;
  due_date?: string;
  assigned_users: string[];
  worktime: {
    users: Record<string, {
      total_duration: number;
      sessions: {
        duration: number;
        timestamp: string;
      }[]
    }>;
    total_duration: number;
  };
  expected_workload?: number;
  updates: any[];
  comments: any[];
  activity_log: any[];
  created_by: number;
  created_at: string;
  updated_at: string;
  subtasks: any[];
  notes_count: number;
  task_type: 'custom';
  board: {
    board_config: BoardConfig;
  };
  contacts?: Contact[];
  products?: Product[];
  account?: Account;
  deal?: {
    id: number;
    name: string;
    cvr: string;
    phone: string;
    email: string;
    address: string;
    zipcode: string;
    city: string;
    description: string;
    economic_customer_number: string;
    type: string;
    is_holding: boolean;
    software: any;
    data: any;
  };
}

export interface Comment {
  id: number;
  user_id: number;
  content: string;
  timestamp: string;
  parent_id?: number;
}

export interface Update {
  id: number;
  user_id: string | number;
  type: 'progress_status' | 'assigned_users' | 'work_date' | 'due_date';
  old_value: any;
  new_value: any;
  message?: string;
  timestamp: string;
}

export type BoardConfigFormData = {
  board_config: BoardConfig;
  subtask_config: SubtaskConfig;
  name: string;
  description?: string;
  is_public: boolean;
  board_type: 'taskboard' | 'project';
  user_access?: number[];
  admin_access?: number[];
};

export const createColumns = <T extends CustomTask>(columns: ColumnDef<T>[]): ColumnDef<T>[] => {
  return columns;
};

export const convertBoardStatusOption = (options: BoardStatusOption[] | undefined): BoardStatusOption[] => {
  if (!options) return [];
  return options.map((option, index) => ({
    ...option,
    id: option.id || index + 1,
    order: option.order || index,
    value: option.value || option.label.toLowerCase().replace(/\s+/g, '_')
  }));
};

export type RecurrenceFrequency = 
  | 'daily'
  | 'weekly'
  | 'biweekly'
  | 'monthly'
  | 'quarterly'
  | 'semiannual'
  | 'annual'
  | 'custom';

export interface CustomRecurrenceConfig {
  interval?: number;
  weekday?: number;
  dayOfMonth?: number;
  monthOfYear?: number;
  weekOfMonth?: number;
}

export interface RecurrenceOption {
  id: string;
  name: string;
  type: 'preset' | 'custom';
  frequency: RecurrenceFrequency;
  custom_config?: CustomRecurrenceConfig;
}

export interface RecurrenceSettings {
  require_recurrence: boolean;
  recurrence_options: RecurrenceOption[];
}

export interface TaskBoardProps {
  title: string;
  fetchUrl: string;
  columns: ColumnDef<CustomTask>[];
  boardStatusOptions: BoardStatusOption[];
  cardConfig: CardConfig;
  dealDataConfig: any[];
  dealSoftwareConfig: any[];
  dealOnboardingConfig: any[];
  taskType: 'custom' | 'custom_subtask';
  defaultView?: 'table' | 'kanban' | 'calendar';
  users: StoredUser[];
  tasks: CustomTask[];
  setTasks: React.Dispatch<React.SetStateAction<CustomTask[]>>;
  loading?: boolean;
  board: Board | null;
  isCustomBoard?: boolean;
  boardConfig?: {
    kanban_source?: string;
    progress_status_options?: BoardStatusOption[];
    board_status_options?: BoardStatusOption[];
  };
  isBoardConfigLoading?: boolean;
  onBoardUpdate?: (result: BoardUpdateResult) => void;
}

export interface BoardUpdateResult {
  updatedBoard: Board;
  configChanged: boolean;
}

export interface BoardSettingsFormValues {
  name: string;
  board_config: {
    requires_deal: boolean;
    requires_work_date: boolean;
    requires_due_date: boolean;
    show_in_my_work: boolean;
    enable_time_tracking: boolean;
    enable_subtasks: boolean;
    enable_assignees: boolean;
    simplify_main_tasks: boolean;
    enable_automations: boolean;
    enable_in_flows: boolean;
    icon: string;
    kanban_source: 'progress' | 'board';
    progress_status_options: ProgressStatusOption[];
    board_status_options?: BoardStatusOption[];
    automations: AutomationRule[];
    notifications: {
      enabled: boolean;
      task_assignments: boolean;
      subtask_assignments: boolean;
      task_comments: boolean;
      subtask_comments: boolean;
      task_updates: boolean;
      subtask_updates: boolean;
    };
    card_fields: string[];
    enable_completion_events: boolean;
    completion_settings: {
      enable_task_completion_events: boolean;
      enable_subtask_completion_events: boolean;
      show_confetti: boolean;
      show_completion_drawer: boolean;
      drawer_config: {
        title?: string;
        description?: string;
        sections: CompletionDrawerSection[];
      };
    };
    default_view: 'kanban' | 'table' | 'calendar';
    enable_calendar_view: boolean;
    enable_recurring_tasks: boolean;
    recurring_task_settings: RecurrenceSettings;
  };
  description?: string;
  is_public?: boolean;
  board_type?: string;
  user_access?: string[];
  admin_access?: string[];
  subtask_config?: {
    show_in_my_work: boolean;
    enable_assignees: boolean;
    enable_subtask_progress: boolean;
    predefined_subtasks: Array<{
      name: string;
      description?: string;
      auto_add: boolean;
      assigned_to?: string[];
      status_options: SubtaskStatusOption[];
    }>;
    automations: AutomationRule[];
  };
}