import React, { useState } from 'react';
import axios from '@/api/axiosConfig';
import { toast } from 'sonner';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import type { BoardStatusOption } from '@/types/custom-board';
import type { CustomTask, TaskType } from '@/types/custom-task';
import { cn } from "@/lib/utils";
import type { Board } from '@/types/custom-board';

interface TaskStatusSelectorProps {
  task: CustomTask;
  onSuccess: (value: string | CustomTask) => void;
  className?: string;
  board: Board | null;
  value: string;
  taskId: number;
  taskType: 'custom' | 'custom_subtask';
  boardStatusOptions: BoardStatusOption[];
  progressStatus: string;
}

export const TaskStatusSelector: React.FC<TaskStatusSelectorProps> = ({
  task,
  onSuccess,
  className,
  board,
  value,
  taskId,
  taskType,
  boardStatusOptions,
  progressStatus
}) => {
  const [isUpdating, setIsUpdating] = useState(false);

  const handleStatusChange = async (newStatus: number) => {
    if (!board?.board_config) return;
    
    setIsUpdating(true);
    
    // Store original values for rollback
    const originalBoardStatusId = task.board_status_id;
    const originalProgressStatusId = task.progress_status_id;
    
    // Create base task data
    const baseTaskData: Partial<CustomTask> = {
      id: task.id,
      task_type: task.task_type,
      key: task.key,
      data: task.data || {}
    };

    // Create rollback function
    const rollback = () => {
      onSuccess({
        ...task,
        board_status_id: originalBoardStatusId,
        progress_status_id: originalProgressStatusId
      });
    };

    try {
      // Update with new status
      onSuccess({
        ...task,
        board_status_id: newStatus,
        progress_status_id: board.board_config.kanban_source === 'progress' ? newStatus : task.progress_status_id
      });

      // Make API call
      const response = await axios.put('/work/update_board_status', {
        task_id: task.id,
        task_type: task.task_type,
        new_status: newStatus,
        progress_status: board.board_config.kanban_source === 'progress' ? newStatus : task.progress_status_id
      });

      if (response.status !== 200) {
        rollback();
        toast.error('Failed to update status');
      }
    } catch (error) {
      console.error('Failed to update task status:', error);
      rollback();
    } finally {
      setIsUpdating(false);
    }
  };

  // Find the current status option to get its color
  const currentOption = board?.board_config?.board_status_options?.find(opt => 
    opt.value === task.board_status_id.toString()
  );
  const statusColor = currentOption?.color || '#8c8c8c';

  return (
    <Select
      value={task.board_status_id.toString()}
      onValueChange={(value: string) => handleStatusChange(parseInt(value, 10))}
    >
      <SelectTrigger className={cn("w-[180px]", className)}>
        <SelectValue>
          <div 
            className="w-full h-6 rounded flex items-center justify-center text-white text-sm"
            style={{ backgroundColor: statusColor }}
          >
            {currentOption?.label || 'No Status'}
          </div>
        </SelectValue>
      </SelectTrigger>
      <SelectContent>
        {(board?.board_config?.board_status_options || []).map((option) => (
          <SelectItem 
            key={option.value} 
            value={option.id.toString()}
            className="cursor-pointer"
          >
            <div 
              className="w-full h-6 rounded flex items-center justify-center text-white text-sm"
              style={{ backgroundColor: option.color }}
            >
              {option.label}
            </div>
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};