import React, { useState, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm, useFieldArray } from 'react-hook-form'
import * as z from 'zod'
import axios from '@/api/axiosConfig'
import { toast } from 'sonner'
import { Trash2, X, ChevronUp, ChevronDown } from 'lucide-react'
import { cn } from '@/lib/utils'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogDescription,
  DialogBody,
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormDescription,
} from '@/components/ui/form'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import { Switch } from '@/components/ui/switch'
import { Badge } from '@/components/ui/badge'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@/components/ui/tabs'
import { Separator } from '@/components/ui/separator'
import { ScrollArea } from '@/components/ui/scroll-area'
import { AssigneeSelection } from '@/modals/AssigneeSelection'
import { ANT_COLORS } from '@/configs/colors'
import { StoredUser } from '@/types/user'
import { Board } from '@/types/custom-board'
import { useUsers } from '@/contexts/UserContext'
import { IconSelector } from '@/taskboards/custom/components/icon-selector'
import { type BoardStatusOption, type ProgressStatusOption, type KanbanSource, type BoardConfig, BoardSettingsFormValues } from '@/types/custom-board'
import { AutomationConfig } from '../components/AutomationConfig'
import { SubtaskConfig } from '../components/SubtaskConfig'
import { TaskCardLayoutConfig } from '../components/TaskCardLayoutConfig'
import { TaskCompletionConfig } from '../components/TaskCompletionConfig'
import { StatusOptionsConfig } from '../components/StatusOptionsConfig'
import { RecurrenceConfig } from '../components/RecurrenceConfig'

interface AccessUser {
  user_id: string;
  is_admin: boolean;
  user: {
    user_id: string;
    name: string;
    surname: string;
    avatar_urls: string[];
  };
}

interface AccessPermission {
  user_id: number;
  is_admin: boolean;
  user: {
    user_id: number;
    name: string;
    surname: string;
    avatar_urls: string[];
  };
}

interface BoardUpdateResult {
  updatedBoard: Board;
  configChanged: boolean;
}

interface BoardSettingsModalProps {
  open: boolean
  onClose: () => void
  board: Board
  onBoardUpdate?: (result: BoardUpdateResult) => void
}

const progressStatusOptionSchema = z.object({
  id: z.number(),
  value: z.string(),
  label: z.string(),
  color: z.string(),
  description: z.string().optional().nullable(),
  order: z.number(),
  is_completed_state: z.boolean().optional()
})

const boardStatusOptionSchema = z.object({
  id: z.number(),
  value: z.string(),
  label: z.string(),
  color: z.string(),
  description: z.string().optional().nullable(),
  order: z.number()
})

const automationRuleSchema = z.object({
  id: z.string(),
  type: z.enum(['task', 'subtask']),
  trigger: z.object({
    type: z.literal('status_change'),
    value: z.string(),
    subtask_id: z.string().optional()
  }),
  conditions: z.array(z.object({
    type: z.enum(['deal_data', 'main_task_status']),
    field: z.string(),
    operator: z.enum(['equals', 'not_equals']),
    value: z.string()
  })),
  actions: z.array(z.object({
    type: z.enum(['assign_users', 'change_status', 'change_subtask_status']),
    users: z.array(z.string()).optional(),
    status_id: z.string().optional(),
    subtask_id: z.string().optional(),
    target_type: z.enum(['main_task', 'subtask']).optional()
  }))
})

const formSchema = z.object({
  name: z.string().min(1, 'Name is required'),
  description: z.string().optional(),
  is_public: z.boolean(),
  board_type: z.enum(['taskboard', 'project']),
  board_config: z.object({
    requires_deal: z.boolean(),
    requires_work_date: z.boolean(),
    requires_due_date: z.boolean(),
    show_in_my_work: z.boolean(),
    enable_time_tracking: z.boolean(),
    enable_subtasks: z.boolean(),
    simplify_main_tasks: z.boolean(),
    enable_automations: z.boolean(),
    enable_in_flows: z.boolean(),
    icon: z.string(),
    kanban_source: z.enum(['progress', 'board']),
    progress_status_options: z.array(progressStatusOptionSchema),
    board_status_options: z.array(boardStatusOptionSchema).optional(),
    automations: z.array(automationRuleSchema),
    notifications: z.object({
      enabled: z.boolean(),
      task_assignments: z.boolean(),
      subtask_assignments: z.boolean(),
      task_comments: z.boolean(),
      subtask_comments: z.boolean(),
      task_updates: z.boolean(),
      subtask_updates: z.boolean()
    }),
    card_fields: z.array(z.string()),
    enable_completion_events: z.boolean(),
    completion_settings: z.object({
      enable_task_completion_events: z.boolean(),
      enable_subtask_completion_events: z.boolean(),
      show_confetti: z.boolean(),
      show_completion_drawer: z.boolean()
    }),
    default_view: z.enum(['kanban', 'table', 'calendar']),
    enable_calendar_view: z.boolean(),
    enable_recurring_tasks: z.boolean().default(false),
    recurring_task_settings: z.object({
      require_recurrence: z.boolean().default(false),
      recurrence_options: z.array(z.object({
        id: z.string(),
        name: z.string(),
        type: z.enum(['preset', 'custom']),
        frequency: z.enum([
          'daily',
          'weekly',
          'biweekly',
          'monthly',
          'quarterly',
          'semiannual',
          'annual',
          'custom'
        ]),
        custom_config: z.object({
          interval: z.number().optional(),
          weekday: z.number().optional(),
          dayOfMonth: z.number().optional(),
          monthOfYear: z.number().optional(),
          weekOfMonth: z.number().optional(),
        }).optional(),
      })).default([])
    }).default({})
  }),
  subtask_config: z.object({
    show_in_my_work: z.boolean().default(true),
    enable_assignees: z.boolean().default(true),
    enable_subtask_progress: z.boolean().default(false),
    predefined_subtasks: z.array(z.object({
      name: z.string(),
      description: z.string().optional(),
      auto_add: z.boolean().default(false),
      assigned_to: z.array(z.string()).default([]),
      status_options: z.array(z.object({
        label: z.string(),
        value: z.string(),
        color: z.string(),
        is_completed_state: z.boolean().default(false)
      })).default([])
    })).default([]),
    automations: z.array(z.object({
      id: z.string(),
      type: z.enum(['task', 'subtask']),
      trigger: z.object({
        type: z.literal('status_change'),
        value: z.string(),
        subtask_id: z.string().optional()
      }),
      conditions: z.array(z.object({
        type: z.enum(['deal_data', 'main_task_status']),
        field: z.string(),
        operator: z.enum(['equals', 'not_equals']),
        value: z.string()
      })),
      actions: z.array(z.object({
        type: z.enum(['assign_users', 'change_status', 'change_subtask_status']),
        users: z.array(z.string()).optional(),
        status_id: z.string().optional(),
        subtask_id: z.string().optional(),
        target_type: z.enum(['main_task', 'subtask']).optional()
      }))
    })).default([])
  }),
  user_access: z.array(z.string()).optional(),
  admin_access: z.array(z.string()).optional()
})

type FormValues = z.infer<typeof formSchema>

const AdminList = ({ board, localBoard, toggleAdminAccess }: { 
  board: Board | null; 
  localBoard: Board | null;
  toggleAdminAccess: (userId: string) => (e: React.MouseEvent) => void;
}) => {
  const { users } = useUsers();
  
  // Always show the board owner
  const ownerUser = board?.created_by ? users.find(u => u.user_id === board.created_by) : null;
  
  // Get admin users from access_permissions
  const adminUsers = localBoard?.access_permissions?.filter(p => p.is_admin) || [];
  
  // Add owner if they're not already in the admin list
  const ownerInAdmins = adminUsers.some(p => p.user_id === board?.created_by);
  
  if (!ownerUser && adminUsers.length === 0) {
    return (
      <p className="text-sm text-muted-foreground italic">
        No administrators selected. Only the board owner has admin rights.
      </p>
    );
  }

  return (
    <div className="flex flex-wrap gap-2">
      {/* Always show owner first */}
      {ownerUser && !ownerInAdmins && (
        <Badge 
          variant="default"
          className="flex items-center gap-2 bg-primary"
        >
          {ownerUser.name} {ownerUser.surname}
          <span className="text-xs ml-1">(Owner)</span>
        </Badge>
      )}
      
      {/* Show other admins */}
      {adminUsers.map(permission => {
        const isCreator = board?.created_by === permission.user_id;
        return (
          <Badge 
            key={permission.user_id}
            variant={isCreator ? "default" : "secondary"}
            className={cn(
              "flex items-center gap-2",
              isCreator && "bg-primary"
            )}
          >
            {permission.user.name} {permission.user.surname}
            {isCreator && <span className="text-xs ml-1">(Owner)</span>}
            {!isCreator && (
              <Button
                variant="ghost"
                size="icon"
                className="h-4 w-4 p-0 hover:bg-transparent"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  toggleAdminAccess(String(permission.user_id))(e);
                }}
              >
                <X className="h-3 w-3" />
              </Button>
            )}
          </Badge>
        );
      })}
    </div>
  );
};

const BoardSettingsModal = ({
  open,
  onClose,
  board,
  onBoardUpdate = () => {}
}: BoardSettingsModalProps): JSX.Element => {
  const navigate = useNavigate()
  const [isPublic, setIsPublic] = useState(board?.is_public || false)
  const [kanbanSource, setKanbanSource] = useState<KanbanSource>(() => {
    const source = board?.board_config?.kanban_source
    return (source === 'progress' || source === 'board') ? source : 'progress'
  })
  const [showAssigneeModal, setShowAssigneeModal] = useState(false)
  const [showAdminModal, setShowAdminModal] = useState(false)
  const [selectedUsers, setSelectedUsers] = useState<string[]>([])
  const [useBoardStatus, setUseBoardStatus] = useState(
    Boolean(board?.board_config?.board_status_options?.length)
  )
  const { users, currentUser } = useUsers()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [localBoard, setLocalBoard] = useState<Board | null>(board)
  const [currentEditingSubtaskIndex, setCurrentEditingSubtaskIndex] = useState<number | null>(null)
  const [isDeleteTasksConfirmOpen, setIsDeleteTasksConfirmOpen] = useState(false)
  const [isDeleteTasksDoubleConfirmOpen, setIsDeleteTasksDoubleConfirmOpen] = useState(false)
  const [adminUsers, setAdminUsers] = useState<string[]>([])

  useEffect(() => {
    if (!board) return;

    const accessUserIds = new Set<string>();
    
    // Add users from access_permissions if it exists
    if (Array.isArray(board.access_permissions)) {
      board.access_permissions.forEach(permission => {
        if (permission?.user_id) {
          accessUserIds.add(String(permission.user_id));
        }
      });
    }

    // Add board creator if not already included
    if (board.created_by && !accessUserIds.has(String(board.created_by))) {
      accessUserIds.add(String(board.created_by));
    }

    const selectedUserIds = Array.from(accessUserIds);
    setSelectedUsers(selectedUserIds);
    
    // Set the board with properly typed access permissions
    setLocalBoard({
      ...board,
      access_permissions: board.access_permissions?.map(p => ({
        ...p,
        user_id: Number(p.user_id),
        is_admin: Boolean(p.is_admin)
      }))
    });
  }, [board]);

  // Initialize adminUsers from board data
  useEffect(() => {
    if (!board) return;
    
    const adminUserIds = board.access_permissions
      ?.filter(p => p.is_admin)
      .map(p => String(p.user_id)) || [];
    
    // Always include board creator as admin
    if (board.created_by && !adminUserIds.includes(String(board.created_by))) {
      adminUserIds.push(String(board.created_by));
    }
    
    console.log('Setting initial admin users:', adminUserIds);
    setAdminUsers(adminUserIds);
  }, [board]);

  const isUserAdmin = (userId: string) => {
    
    const permission = localBoard?.access_permissions?.find(p => {
      const matches = String(p.user_id) === userId;
      return matches;
    });
    
    const result = Boolean(permission?.is_admin);
    return result;
  };

  const getAdminUserIds = () => {
    return localBoard?.access_permissions
      ?.filter(p => p.is_admin)
      .map(p => String(p.user_id)) || [];
  };

  const handleAssigneeSave = (users: string[]) => {
    setSelectedUsers(users);
    // Update form state with new user access and admin access
    form.setValue('user_access', users, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true
    });
    // Keep existing admin users that are still in the selected users
    const currentAdmins = getAdminUserIds().filter(adminId => users.includes(adminId));
    form.setValue('admin_access', currentAdmins, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true
    });
    setShowAssigneeModal(false);
  }

  const handleAssigneeClose = () => {
    setShowAssigneeModal(false)
  }

  const form = useForm<BoardSettingsFormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: board?.name || '',
      description: board?.description || '',
      is_public: board?.is_public || false,
      board_type: board?.board_type || 'taskboard',
      board_config: {
        requires_deal: board?.board_config?.requires_deal || false,
        requires_work_date: board?.board_config?.requires_work_date || false,
        requires_due_date: board?.board_config?.requires_due_date || false,
        show_in_my_work: board?.board_config?.show_in_my_work ?? true,
        enable_time_tracking: board?.board_config?.enable_time_tracking ?? true,
        enable_subtasks: board?.board_config?.enable_subtasks ?? false,
        simplify_main_tasks: board?.board_config?.simplify_main_tasks ?? false,
        enable_automations: board?.board_config?.enable_automations ?? false,
        enable_in_flows: board?.board_config?.enable_in_flows ?? false,
        icon: board?.board_config?.icon || 'FolderKanban',
        kanban_source: (board?.board_config?.kanban_source as 'progress' | 'board') || 'progress',
        progress_status_options: board?.board_config?.progress_status_options || [],
        board_status_options: board?.board_config?.board_status_options || [],
        automations: board?.board_config?.automations || [],
        notifications: board?.board_config?.notifications || {
          enabled: false,
          task_assignments: false,
          subtask_assignments: false,
          task_comments: false,
          subtask_comments: false,
          task_updates: false,
          subtask_updates: false
        },
        card_fields: board?.board_config?.card_fields || [],
        enable_completion_events: board?.board_config?.enable_completion_events || false,
        completion_settings: board?.board_config?.completion_settings || {
          enable_task_completion_events: false,
          enable_subtask_completion_events: false,
          show_confetti: false,
          show_completion_drawer: false
        },
        default_view: board?.board_config?.default_view || 'kanban',
        enable_calendar_view: board?.board_config?.enable_calendar_view || false,
        enable_recurring_tasks: board?.board_config?.enable_recurring_tasks || false,
        recurring_task_settings: board?.board_config?.recurring_task_settings || {
          require_recurrence: false,
          recurrence_options: []
        }
      },
      subtask_config: board?.subtask_config || {
        show_in_my_work: true,
        enable_assignees: true,
        enable_subtask_progress: false,
        predefined_subtasks: [],
        automations: []
      },
      user_access: board?.access_permissions?.map(p => String(p.user_id)) || [],
      admin_access: board?.access_permissions?.filter(p => p.is_admin).map(p => String(p.user_id)) || []
    }
  })

  // Update the useEffect for initializing form values
  useEffect(() => {
    if (open && board) {
      form.reset({
        name: board.name,
        description: board.description,
        is_public: board.is_public,
        board_type: board.board_type || 'taskboard',
        board_config: {
          requires_deal: board.board_config?.requires_deal || false,
          requires_work_date: board.board_config?.requires_work_date || false,
          requires_due_date: board.board_config?.requires_due_date || false,
          show_in_my_work: board.board_config?.show_in_my_work ?? true,
          enable_time_tracking: board.board_config?.enable_time_tracking ?? true,
          enable_subtasks: board.board_config?.enable_subtasks ?? false,
          simplify_main_tasks: board.board_config?.simplify_main_tasks ?? false,
          enable_automations: board.board_config?.enable_automations || false,
          enable_in_flows: board.board_config?.enable_in_flows || false,
          icon: board.board_config?.icon || 'FolderKanban',
          kanban_source: (board.board_config?.kanban_source as 'progress' | 'board') || 'progress',
          progress_status_options: board.board_config?.progress_status_options || [],
          board_status_options: board.board_config?.board_status_options || [],
          automations: board.board_config?.automations || [],
          notifications: board.board_config?.notifications || {
            enabled: false,
            task_assignments: false,
            subtask_assignments: false,
            task_comments: false,
            subtask_comments: false,
            task_updates: false,
            subtask_updates: false
          },
          card_fields: board.board_config?.card_fields || [],
          enable_completion_events: board.board_config?.enable_completion_events || false,
          completion_settings: board.board_config?.completion_settings || {
            enable_task_completion_events: false,
            enable_subtask_completion_events: false,
            show_confetti: false,
            show_completion_drawer: false
          },
          default_view: board.board_config?.default_view || 'kanban',
          enable_calendar_view: board.board_config?.enable_calendar_view || false,
          enable_recurring_tasks: board.board_config?.enable_recurring_tasks || false,
          recurring_task_settings: board.board_config?.recurring_task_settings || {
            require_recurrence: false,
            recurrence_options: []
          }
        },
        subtask_config: board.subtask_config || {
          show_in_my_work: true,
          enable_assignees: true,
          enable_subtask_progress: false,
          predefined_subtasks: [],
          automations: []
        },
        user_access: board.access_permissions?.map(p => String(p.user_id)) || [],
        admin_access: board.access_permissions?.filter(p => p.is_admin).map(p => String(p.user_id)) || []
      });

      // Reset other state
      setIsPublic(board.is_public);
      setKanbanSource(board.board_config?.kanban_source as 'progress' | 'board' || 'progress');
      setUseBoardStatus(Boolean(board.board_config?.board_status_options?.length));
      setSelectedUsers(board.access_permissions?.map(p => String(p.user_id)) || []);
      setLocalBoard(board);
    }
  }, [board, open, form]);

  const progressStatusArray = useFieldArray({
    control: form.control,
    name: 'board_config.progress_status_options'
  });

  const boardStatusArray = useFieldArray({
    control: form.control,
    name: 'board_config.board_status_options'
  });

  const subtaskArray = useFieldArray({
    control: form.control,
    name: "subtask_config.predefined_subtasks"
  })

  const handleSubmit = async (values: BoardSettingsFormValues) => {
    try {
      console.log('Form submission started with values:', values);
      console.log('Current adminUsers state:', adminUsers);
      setIsSubmitting(true);
      
      if (!values.is_public && selectedUsers.length === 0) {
        console.log('Validation failed: No users selected for private board');
        toast.error('Private boards must have at least one user with access');
        setIsSubmitting(false);
        return;
      }

      // Create clean board data object with only the necessary fields
      const boardData = {
        name: values.name,
        description: values.description,
        is_public: values.is_public,
        board_type: values.board_type,
        board_config: {
          ...values.board_config,
          progress_status_options: values.board_config.progress_status_options.map((status, index) => ({
            ...status,
            order: index
          })),
          board_status_options: (values.board_config.board_status_options || []).map((status, index) => ({
            ...status,
            order: index
          })),
          notifications: values.board_config.notifications,
          automations: values.board_config.automations || []
        },
        subtask_config: values.subtask_config ? {
          ...values.subtask_config,
          automations: values.subtask_config?.automations || []
        } : undefined,
        user_access: !values.is_public ? selectedUsers.map(Number) : [],
        admin_access: adminUsers  // Use adminUsers state directly
      };

      console.log('Admin users being sent:', boardData.admin_access);
      console.log('Sending board data to backend:', boardData);
      console.log('PUT request URL:', `/custom-board/boards/${board.id}`);

      const response = await axios.put(`/custom-board/boards/${board.id}`, boardData);
      console.log('Response from backend:', response.data);
      console.log('Board config in response:', response.data.board_config);
      
      if (response.status === 200) {
        console.log('Board update successful');
        toast.success('Board settings updated successfully');
        onBoardUpdate({
          updatedBoard: response.data,
          configChanged: Object.keys(form.formState.dirtyFields)
            .some(field => field === 'board_config' || field.startsWith('board_config.'))
        });
        onClose();
      }
    } catch (err: any) {
      console.error('Error in handleSubmit:', err);
      toast.error('Failed to update board settings');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDelete = async () => {
    try {
      const response = await axios.delete(`/custom-board/boards/${board.id}`)
      
      if (response.status === 200) {
        toast.success('Board deleted successfully')
        onBoardUpdate({
          updatedBoard: board,
          configChanged: false
        });
        onClose()
        navigate('/boards')
      }
    } catch (error) {
      console.error('Error deleting board:', error)
      toast.error('Failed to delete board')
    }
  };

  const handleDeleteAllTasks = async () => {
    try {
      setIsSubmitting(true);
      const response = await axios.delete(`/custom-board/boards/${board.id}/tasks`);
      
      if (response.status === 200) {
        toast.success('All tasks deleted successfully');
        onBoardUpdate({
          updatedBoard: board,
          configChanged: false
        });
        setIsDeleteTasksDoubleConfirmOpen(false);
        setIsDeleteTasksConfirmOpen(false);
      }
    } catch (error: any) {
      console.error('Error deleting tasks:', error);
      toast.error(error.response?.data?.error || 'Failed to delete tasks');
    } finally {
      setIsSubmitting(false);
    }
  };

  const toggleAdminAccess = (userId: string) => (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    
    const isCurrentlyAdmin = adminUsers.includes(userId);
    console.log('Toggling admin access for user:', userId, 'Current status:', isCurrentlyAdmin);
    
    const newAdminUsers = isCurrentlyAdmin
      ? adminUsers.filter(id => id !== userId)
      : [...adminUsers, userId];
    
    console.log('New admin users list:', newAdminUsers);
    setAdminUsers(newAdminUsers);

    // Update form state to mark it as dirty
    form.setValue('admin_access', newAdminUsers, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true
    });
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="max-w-3xl">
        <DialogHeader>
          <DialogTitle>Board Settings - {board.name}</DialogTitle>
          <DialogDescription className="sr-only">
            Configure board settings and options
          </DialogDescription>
        </DialogHeader>

        <Form {...form}>
          <form 
            id="board-settings-form" 
            onSubmit={async (e) => {
              e.preventDefault();
              console.log('Form submitted');
              const values = form.getValues();
              console.log('Form values:', values);
              await handleSubmit(values);
            }} 
            className="flex flex-col flex-1 min-h-0"
          >
            <Tabs defaultValue="general" className="flex flex-col flex-1 min-h-0">
              <div className="px-6 border-b">
                <TabsList>
                  <TabsTrigger value="general">General</TabsTrigger>
                  <TabsTrigger value="status">Status Options</TabsTrigger>
                  {form.watch('board_config.enable_subtasks') && (
                    <TabsTrigger value="subtasks">Subtasks</TabsTrigger>
                  )}
                  {form.watch('board_config.enable_automations') && (
                    <TabsTrigger value="automations">Automations</TabsTrigger>
                  )}
                  <TabsTrigger value="card_layout">Card Layout</TabsTrigger>
                  {form.watch('board_config.enable_completion_events') && (
                    <TabsTrigger value="completions">Completions</TabsTrigger>
                  )}
                  {form.watch('board_config.enable_recurring_tasks') && (
                    <TabsTrigger value="recurrence">Recurrence</TabsTrigger>
                  )}
                </TabsList>
              </div>

              <DialogBody maxBodyHeight>
                <TabsContent value="general" className="space-y-6 mt-0">
                  <FormField
                    control={form.control}
                    name="name"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Board Name</FormLabel>
                        <FormControl>
                          <Input {...field} placeholder="Enter board name" />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="description"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Description</FormLabel>
                        <FormControl>
                          <Input {...field} placeholder="Enter board description" />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="board_config.icon"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Board Icon</FormLabel>
                        <FormControl>
                          <IconSelector
                            value={field.value || ''}
                            onChange={field.onChange}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <div className="space-y-4 border rounded-lg p-4">
                    <h3 className="font-medium">Privacy Settings</h3>
                    <FormField
                      control={form.control}
                      name="is_public"
                      render={({ field }) => (
                        <FormItem className="flex items-center justify-between">
                          <div>
                            <FormLabel>Public Board</FormLabel>
                            <p className="text-sm text-muted-foreground">
                              Make this board visible to all users
                            </p>
                          </div>
                          <FormControl>
                            <Switch
                              checked={field.value}
                              onCheckedChange={(checked) => {
                                field.onChange(checked);
                                setIsPublic(checked);
                                if (!checked) {
                                  // When switching to private, keep current access permissions
                                  const accessUserIds = new Set<string>();
                                  
                                  // Add users from user_access array if it exists
                                  if (Array.isArray(board?.user_access)) {
                                    board.user_access.forEach(userId => {
                                      accessUserIds.add(String(userId));
                                    });
                                  }

                                  // Add users from access_permissions if it exists
                                  if (Array.isArray(board?.access_permissions)) {
                                    board.access_permissions.forEach(permission => {
                                      if (typeof permission === 'object' && permission.user_id) {
                                        accessUserIds.add(String(permission.user_id));
                                      }
                                    });
                                  }

                                  // Add board creator if not already included
                                  if (board?.created_by && !accessUserIds.has(String(board.created_by))) {
                                    accessUserIds.add(String(board.created_by));
                                  }

                                  setSelectedUsers(Array.from(accessUserIds));
                                } else {
                                  setSelectedUsers([]);
                                }
                              }}
                            />
                          </FormControl>
                        </FormItem>
                      )}
                    />

                    {/* Admin section - only show for public boards */}
                    {isPublic && (
                      <div className="space-y-4 border rounded-lg p-4">
                        <h3 className="font-medium">Board Administrators</h3>
                        <div className="flex items-center justify-between mb-2">
                          <div>
                            <p className="text-sm text-muted-foreground">
                              Select users who can edit board settings
                            </p>
                          </div>
                          <Button
                            variant="outline"
                            size="sm"
                            type="button"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setShowAdminModal(true);
                            }}
                          >
                            Select Admins
                          </Button>
                        </div>
                        <div className="flex flex-wrap gap-2">
                          {localBoard?.access_permissions?.filter(p => p.is_admin).map(permission => {
                            const isCreator = board?.created_by === permission.user_id;
                            return (
                              <Badge 
                                key={permission.user_id}
                                variant={isCreator ? "default" : "secondary"}
                                className={cn(
                                  "flex items-center gap-2",
                                  isCreator && "bg-primary"
                                )}
                              >
                                {permission.user.name} {permission.user.surname}
                                {isCreator ? (
                                  <span className="text-xs ml-1">(Owner)</span>
                                ) : (
                                  <Button
                                    variant="ghost"
                                    size="icon"
                                    className="h-4 w-4 p-0 hover:bg-transparent"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      toggleAdminAccess(String(permission.user_id))(e);
                                    }}
                                  >
                                    <X className="h-3 w-3" />
                                  </Button>
                                )}
                              </Badge>
                            );
                          })}
                        </div>
                      </div>
                    )}

                    {/* User access section - only show for private boards */}
                    {!isPublic && (
                      <div className="space-y-4 border rounded-lg p-4">
                        <h3 className="font-medium">User Access</h3>
                        <div className="flex items-center justify-between mb-2">
                          <div>
                            <p className="text-sm text-muted-foreground">
                              Select users who can access this board
                            </p>
                          </div>
                          <Button
                            variant="outline"
                            size="sm"
                            type="button"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setShowAssigneeModal(true);
                            }}
                          >
                            Select Users
                          </Button>
                        </div>
                        {selectedUsers.length > 0 ? (
                          <div className="flex flex-wrap gap-2">
                            {selectedUsers.map(userId => {
                              const user = users.find(u => String(u.user_id) === String(userId));
                              if (!user) return null;
                              const isCreator = board?.created_by === Number(userId);
                              const isAdmin = adminUsers.includes(String(userId));
                              return (
                                <Badge 
                                  key={userId}
                                  variant={isCreator ? "default" : "secondary"}
                                  className={cn(
                                    "flex items-center gap-2",
                                    isCreator && "bg-primary"
                                  )}
                                >
                                  {user.name} {user.surname}
                                  <div className="flex items-center gap-1">
                                    {isCreator ? (
                                      <span className="text-xs ml-1">(Owner)</span>
                                    ) : (
                                      <>
                                        <Button
                                          variant="ghost"
                                          size="sm"
                                          className="h-5 px-1.5 text-xs"
                                          onClick={toggleAdminAccess(userId)}
                                        >
                                          {isAdmin ? 'Admin ✓' : 'Make Admin'}
                                        </Button>
                                        <Button
                                          variant="ghost"
                                          size="icon"
                                          className="h-4 w-4 p-0 hover:bg-transparent"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            const newUsers = selectedUsers.filter(id => id !== userId);
                                            setSelectedUsers(newUsers);
                                            // Update form state to mark it as dirty
                                            form.setValue('user_access', newUsers, {
                                              shouldDirty: true,
                                              shouldTouch: true,
                                              shouldValidate: true
                                            });
                                            // Also update admin users if the removed user was an admin
                                            if (adminUsers.includes(userId)) {
                                              const newAdminUsers = adminUsers.filter(id => id !== userId);
                                              setAdminUsers(newAdminUsers);
                                              form.setValue('admin_access', newAdminUsers, {
                                                shouldDirty: true,
                                                shouldTouch: true,
                                                shouldValidate: true
                                              });
                                            }
                                          }}
                                        >
                                          <X className="h-3 w-3" />
                                        </Button>
                                      </>
                                    )}
                                  </div>
                                </Badge>
                              );
                            })}
                          </div>
                        ) : (
                          <p className="text-sm text-muted-foreground italic">
                            No users selected. Only you will have access to this board.
                          </p>
                        )}
                      </div>
                    )}
                  </div>

                  <div className="space-y-4 border rounded-lg p-4">
                    <h3 className="font-medium">Task Requirements</h3>
                    <FormField
                      control={form.control}
                      name="board_config.requires_deal"
                      render={({ field }) => (
                        <FormItem className="flex items-center justify-between">
                          <div>
                            <FormLabel>Require Deal</FormLabel>
                            <p className="text-sm text-muted-foreground">
                              Tasks must be linked to a deal
                            </p>
                          </div>
                          <FormControl>
                            <Switch
                              checked={Boolean(field.value)}
                              onCheckedChange={field.onChange}
                            />
                          </FormControl>
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="board_config.requires_work_date"
                      render={({ field }) => (
                        <FormItem className="flex items-center justify-between">
                          <div>
                            <FormLabel>Require Work Date</FormLabel>
                            <p className="text-sm text-muted-foreground">
                              Tasks must have a work date specified
                            </p>
                          </div>
                          <FormControl>
                            <Switch
                              checked={Boolean(field.value)}
                              onCheckedChange={field.onChange}
                            />
                          </FormControl>
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="board_config.requires_due_date"
                      render={({ field }) => (
                        <FormItem className="flex items-center justify-between">
                          <div>
                            <FormLabel>Require Due Date</FormLabel>
                            <p className="text-sm text-muted-foreground">
                              Tasks must have a due date specified
                            </p>
                          </div>
                          <FormControl>
                            <Switch
                              checked={Boolean(field.value)}
                              onCheckedChange={field.onChange}
                            />
                          </FormControl>
                        </FormItem>
                      )}
                    />
                  </div>

                  <div className="space-y-4 border rounded-lg p-4">
                    <h3 className="font-medium">Additional Settings</h3>
                    <FormField
                      control={form.control}
                      name="board_config.default_view"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Default View</FormLabel>
                          <Select onValueChange={field.onChange} defaultValue={field.value}>
                            <FormControl>
                              <SelectTrigger>
                                <SelectValue placeholder="Select default view" />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              <SelectItem value="kanban">Kanban</SelectItem>
                              <SelectItem value="table">Table</SelectItem>
                              {form.watch('board_config.enable_calendar_view') && (
                                <SelectItem value="calendar">Calendar</SelectItem>
                              )}
                            </SelectContent>
                          </Select>
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="board_config.enable_calendar_view"
                      render={({ field }) => (
                        <FormItem className="flex items-center justify-between">
                          <div>
                            <FormLabel>Enable Calendar View</FormLabel>
                            <FormDescription>
                              Allow viewing tasks in a calendar layout
                            </FormDescription>
                          </div>
                          <FormControl>
                            <Switch
                              checked={field.value}
                              onCheckedChange={field.onChange}
                            />
                          </FormControl>
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="board_config.show_in_my_work"
                      render={({ field }) => (
                        <FormItem className="flex items-center justify-between">
                          <div>
                            <FormLabel>Show in My Work</FormLabel>
                            <p className="text-sm text-muted-foreground">
                              Display tasks from this board in the My Work section
                            </p>
                            {form.watch('board_config.simplify_main_tasks') && (
                              <p className="text-sm text-destructive mt-1">
                                This option is disabled when "Simplify Main Tasks" is enabled
                              </p>
                            )}
                          </div>
                          <FormControl>
                            <Switch
                              checked={field.value && !form.watch('board_config.simplify_main_tasks')}
                              onCheckedChange={field.onChange}
                              disabled={form.watch('board_config.simplify_main_tasks')}
                            />
                          </FormControl>
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="board_config.enable_time_tracking"
                      render={({ field }) => (
                        <FormItem className="flex items-center justify-between">
                          <div>
                            <FormLabel>Enable Time Tracking</FormLabel>
                            <p className="text-sm text-muted-foreground">
                              Allow time tracking for tasks in this board
                            </p>
                          </div>
                          <FormControl>
                            <Switch
                              checked={Boolean(field.value)}
                              onCheckedChange={field.onChange}
                            />
                          </FormControl>
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="board_config.enable_subtasks"
                      render={({ field }) => (
                        <FormItem className="flex items-center justify-between">
                          <div>
                            <FormLabel>Enable Subtasks</FormLabel>
                            <p className="text-sm text-muted-foreground">
                              Allow tasks to have subtasks in this board
                            </p>
                          </div>
                          <FormControl>
                            <Switch
                              checked={Boolean(field.value)}
                              onCheckedChange={field.onChange}
                            />
                          </FormControl>
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="board_config.simplify_main_tasks"
                      render={({ field }) => (
                        <FormItem className="flex items-center justify-between">
                          <div>
                            <FormLabel>Simplify Main Tasks</FormLabel>
                            <p className="text-sm text-muted-foreground">
                              Simplify main tasks to only deal selection. This requires "Require Deal" to be enabled.
                            </p>
                            {field.value && !form.watch('board_config.requires_deal') && (
                              <p className="text-sm text-destructive mt-1">
                                This option requires "Require Deal" to be enabled
                              </p>
                            )}
                          </div>
                          <FormControl>
                            <Switch
                              checked={Boolean(field.value)}
                              onCheckedChange={(checked) => {
                                if (checked && !form.watch('board_config.requires_deal')) {
                                  toast.error('You must enable "Require Deal" to use this option');
                                  return;
                                }
                                if (checked) {
                                  // When enabling simplify_main_tasks, disable show_in_my_work
                                  form.setValue('board_config.show_in_my_work', false);
                                }
                                field.onChange(checked);
                              }}
                              disabled={!form.watch('board_config.requires_deal')}
                            />
                          </FormControl>
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="board_config.enable_automations"
                      render={({ field }) => (
                        <FormItem className="flex items-center justify-between">
                          <div>
                            <FormLabel>Enable Automations</FormLabel>
                            <p className="text-sm text-muted-foreground">
                              Enable automation rules for tasks and subtasks
                            </p>
                          </div>
                          <FormControl>
                            <Switch
                              checked={Boolean(field.value)}
                              onCheckedChange={field.onChange}
                            />
                          </FormControl>
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="board_config.enable_in_flows"
                      render={({ field }) => (
                        <FormItem className="flex items-center justify-between">
                          <div>
                            <FormLabel>Enable in Flows</FormLabel>
                            <p className="text-sm text-muted-foreground">
                              Make this board available as an option in automation flows
                            </p>
                          </div>
                          <FormControl>
                            <Switch
                              checked={Boolean(field.value)}
                              onCheckedChange={field.onChange}
                            />
                          </FormControl>
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="board_config.enable_recurring_tasks"
                      render={({ field }) => (
                        <FormItem className="flex items-center justify-between">
                          <div>
                            <FormLabel>Enable Recurring Tasks</FormLabel>
                            <p className="text-sm text-muted-foreground">
                              Allow tasks to be automatically recreated based on recurrence settings
                            </p>
                          </div>
                          <FormControl>
                            <Switch
                              checked={field.value}
                              onCheckedChange={field.onChange}
                            />
                          </FormControl>
                        </FormItem>
                      )}
                    />
                  </div>

                  <div className="space-y-4 border rounded-lg p-4">
                    <h3 className="font-medium">Notification Settings</h3>
                    <FormField
                      control={form.control}
                      name="board_config.notifications.enabled"
                      render={({ field }) => (
                        <FormItem className="flex items-center justify-between">
                          <div>
                            <FormLabel>Enable Notifications</FormLabel>
                            <p className="text-sm text-muted-foreground">
                              Allow notifications for events in this board
                            </p>
                          </div>
                          <FormControl>
                            <Switch
                              checked={field.value}
                              onCheckedChange={field.onChange}
                            />
                          </FormControl>
                        </FormItem>
                      )}
                    />

                    {form.watch('board_config.notifications.enabled') && (
                      <div className="space-y-4 pl-4">
                        <FormField
                          control={form.control}
                          name="board_config.notifications.task_assignments"
                          render={({ field }) => (
                            <FormItem className="flex items-center justify-between">
                              <div>
                                <FormLabel>Task Assignments</FormLabel>
                                <p className="text-sm text-muted-foreground">
                                  Notify users when they are assigned to tasks
                                </p>
                              </div>
                              <FormControl>
                                <Switch
                                  checked={field.value}
                                  onCheckedChange={field.onChange}
                                />
                              </FormControl>
                            </FormItem>
                          )}
                        />

                        <FormField
                          control={form.control}
                          name="board_config.notifications.subtask_assignments"
                          render={({ field }) => (
                            <FormItem className="flex items-center justify-between">
                              <div>
                                <FormLabel>Subtask Assignments</FormLabel>
                                <p className="text-sm text-muted-foreground">
                                  Notify users when they are assigned to subtasks
                                </p>
                              </div>
                              <FormControl>
                                <Switch
                                  checked={field.value}
                                  onCheckedChange={field.onChange}
                                />
                              </FormControl>
                            </FormItem>
                          )}
                        />

                        <FormField
                          control={form.control}
                          name="board_config.notifications.task_comments"
                          render={({ field }) => (
                            <FormItem className="flex items-center justify-between">
                              <div>
                                <FormLabel>Task Comments</FormLabel>
                                <p className="text-sm text-muted-foreground">
                                  Send notifications for comments on tasks
                                </p>
                              </div>
                              <FormControl>
                                <Switch
                                  checked={field.value}
                                  onCheckedChange={field.onChange}
                                />
                              </FormControl>
                            </FormItem>
                          )}
                        />

                        <FormField
                          control={form.control}
                          name="board_config.notifications.subtask_comments"
                          render={({ field }) => (
                            <FormItem className="flex items-center justify-between">
                              <div>
                                <FormLabel>Subtask Comments</FormLabel>
                                <p className="text-sm text-muted-foreground">
                                  Send notifications for comments on subtasks
                                </p>
                              </div>
                              <FormControl>
                                <Switch
                                  checked={field.value}
                                  onCheckedChange={field.onChange}
                                />
                              </FormControl>
                            </FormItem>
                          )}
                        />

                        <FormField
                          control={form.control}
                          name="board_config.notifications.task_updates"
                          render={({ field }) => (
                            <FormItem className="flex items-center justify-between">
                              <div>
                                <FormLabel>Task Updates</FormLabel>
                                <p className="text-sm text-muted-foreground">
                                  Send notifications for task status and assignment changes
                                </p>
                              </div>
                              <FormControl>
                                <Switch
                                  checked={field.value}
                                  onCheckedChange={field.onChange}
                                />
                              </FormControl>
                            </FormItem>
                          )}
                        />

                        <FormField
                          control={form.control}
                          name="board_config.notifications.subtask_updates"
                          render={({ field }) => (
                            <FormItem className="flex items-center justify-between">
                              <div>
                                <FormLabel>Subtask Updates</FormLabel>
                                <p className="text-sm text-muted-foreground">
                                  Send notifications for subtask status and assignment changes
                                </p>
                              </div>
                              <FormControl>
                                <Switch
                                  checked={field.value}
                                  onCheckedChange={field.onChange}
                                />
                              </FormControl>
                            </FormItem>
                          )}
                        />
                      </div>
                    )}
                  </div>

                  <div className="space-y-4 border rounded-lg p-4">
                    <h3 className="font-medium">Completion Settings</h3>
                    <FormField
                      control={form.control}
                      name="board_config.enable_completion_events"
                      render={({ field }) => (
                        <FormItem className="flex items-center justify-between">
                          <div>
                            <FormLabel>Enable Completion Events</FormLabel>
                            <p className="text-sm text-muted-foreground">
                              Enable special events and notifications when tasks are completed
                            </p>
                          </div>
                          <FormControl>
                            <Switch
                              checked={field.value}
                              onCheckedChange={field.onChange}
                            />
                          </FormControl>
                        </FormItem>
                      )}
                    />
                  </div>
                </TabsContent>

                <TabsContent value="status" className="space-y-6 mt-0">
                  <StatusOptionsConfig
                    form={form}
                    kanbanSource={kanbanSource}
                    useBoardStatus={useBoardStatus}
                    setUseBoardStatus={setUseBoardStatus}
                  />
                </TabsContent>

                {(form.watch('board_config.enable_subtasks') || board.board_config.enable_subtasks) && (
                  <TabsContent value="subtasks" className="space-y-6 mt-0">
                    <SubtaskConfig
                      form={form}
                      users={users}
                      currentEditingSubtaskIndex={currentEditingSubtaskIndex}
                      setCurrentEditingSubtaskIndex={setCurrentEditingSubtaskIndex}
                    />
                  </TabsContent>
                )}

                {(form.watch('board_config.enable_automations') || board.board_config.enable_automations) && (
                  <TabsContent value="automations" className="space-y-6 mt-0">
                    <AutomationConfig
                      board={board}
                      onAutomationsChange={(automations) => {
                        // Use setTimeout to prevent immediate form updates
                        setTimeout(() => {
                          form.setValue('board_config.automations', automations, {
                            shouldDirty: true,
                            shouldTouch: true,
                            shouldValidate: false
                          });
                        }, 0);
                      }}
                    />
                  </TabsContent>
                )}

                <TabsContent value="card_layout" className="space-y-6 mt-0">
                  <TaskCardLayoutConfig
                    selectedFields={form.watch('board_config.card_fields') || []}
                    onFieldsChange={(fields) => {
                      form.setValue('board_config.card_fields', fields, {
                        shouldDirty: true,
                        shouldTouch: true
                      });
                    }}
                  />
                </TabsContent>

                {form.watch('board_config.enable_completion_events') && (
                  <TabsContent value="completions" className="space-y-6 mt-0">
                    <TaskCompletionConfig form={form} />
                  </TabsContent>
                )}

                {form.watch('board_config.enable_recurring_tasks') && (
                  <TabsContent value="recurrence" className="space-y-6 mt-0">
                    <RecurrenceConfig form={form} />
                  </TabsContent>
                )}
              </DialogBody>
            </Tabs>

            <DialogFooter>
              <div className="flex w-full justify-between gap-2">
                <div className="flex gap-2">
                  <AlertDialog open={isDeleteTasksConfirmOpen} onOpenChange={setIsDeleteTasksConfirmOpen}>
                    <AlertDialogTrigger asChild>
                      <Button
                        variant="destructive"
                        type="button"
                        disabled={isSubmitting}
                      >
                        Delete All Tasks
                      </Button>
                    </AlertDialogTrigger>
                    <AlertDialogContent>
                      <AlertDialogHeader>
                        <AlertDialogTitle>Delete All Tasks</AlertDialogTitle>
                        <AlertDialogDescription>
                          Are you sure you want to delete all tasks in this board? This will also delete all subtasks and cannot be undone.
                        </AlertDialogDescription>
                      </AlertDialogHeader>
                      <AlertDialogFooter>
                        <AlertDialogCancel>Cancel</AlertDialogCancel>
                        <AlertDialog open={isDeleteTasksDoubleConfirmOpen} onOpenChange={setIsDeleteTasksDoubleConfirmOpen}>
                          <AlertDialogTrigger asChild>
                            <Button variant="destructive">Yes, Delete All Tasks</Button>
                          </AlertDialogTrigger>
                          <AlertDialogContent>
                            <AlertDialogHeader>
                              <AlertDialogTitle>Final Confirmation</AlertDialogTitle>
                              <AlertDialogDescription>
                                This will permanently delete ALL tasks and subtasks in this board. This action cannot be undone. Are you absolutely sure?
                              </AlertDialogDescription>
                            </AlertDialogHeader>
                            <AlertDialogFooter>
                              <AlertDialogCancel onClick={() => setIsDeleteTasksDoubleConfirmOpen(false)}>
                                No, Keep Tasks
                              </AlertDialogCancel>
                              <AlertDialogAction onClick={handleDeleteAllTasks}>
                                Yes, Delete Everything
                              </AlertDialogAction>
                            </AlertDialogFooter>
                          </AlertDialogContent>
                        </AlertDialog>
                      </AlertDialogFooter>
                    </AlertDialogContent>
                  </AlertDialog>

                  <Button
                    variant="destructive"
                    onClick={handleDelete}
                    disabled={isSubmitting}
                    type="button"
                  >
                    Delete Board
                  </Button>
                </div>
                <div className="flex gap-2">
                  <Button 
                    variant="outline" 
                    onClick={onClose} 
                    disabled={isSubmitting}
                    type="button"
                  >
                    Cancel
                  </Button>
                  <Button 
                    type="submit"
                    disabled={isSubmitting || !form.formState.isDirty}
                  >
                    {isSubmitting ? "Saving..." : "Save Changes"}
                  </Button>
                </div>
              </div>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>

      <AssigneeSelection
        visible={showAssigneeModal}
        onClose={() => setShowAssigneeModal(false)}
        onSave={handleAssigneeSave}
        initialAssignees={selectedUsers}
      />

      {currentEditingSubtaskIndex !== null && (
        <AssigneeSelection
          visible={true}
          onClose={() => {
            setCurrentEditingSubtaskIndex(null);
          }}
          onSave={(assignees) => {
            form.setValue(
              `subtask_config.predefined_subtasks.${currentEditingSubtaskIndex}.assigned_to`,
              assignees
            );
            setCurrentEditingSubtaskIndex(null);
          }}
          initialAssignees={form.watch(`subtask_config.predefined_subtasks.${currentEditingSubtaskIndex}.assigned_to`) || []}
        />
      )}

      <AssigneeSelection
        visible={showAdminModal}
        onClose={() => setShowAdminModal(false)}
        onSave={(users) => {
          if (!localBoard?.access_permissions) return;
          
          const updatedBoard = {
            ...localBoard,
            access_permissions: localBoard.access_permissions.map(permission => ({
              ...permission,
              is_admin: users.includes(String(permission.user_id))
            }))
          };
          setLocalBoard(updatedBoard);
          setShowAdminModal(false);
        }}
        initialAssignees={getAdminUserIds()}
        title="Select Board Admins"
        description="Select users who can modify board settings"
      />
    </Dialog>
  )
}

export default BoardSettingsModal