import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, Routes, Route, useParams, useLocation } from 'react-router-dom';
import { Search, Filter, History, Star, X, Briefcase, Settings, Plus, SwitchCamera, UserCheck, CheckSquare, ChevronDown, ChevronUp, SplitSquareHorizontal } from 'lucide-react';
import axios from '../api/axiosConfig';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Tooltip, TooltipContent, TooltipTrigger, TooltipProvider } from "@/components/ui/tooltip";
import { ScrollArea } from "@/components/ui/scroll-area";

import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/components/ui/tabs';
import { toast } from 'sonner';
import { Badge } from "@/components/ui/badge";
import { cn } from "@/lib/utils";

import { BoardActivity } from './modals/BoardActivity';
import { TaskKanban } from './views/TaskKanban';
import { TaskCalendar } from './views/TaskCalendar';
import { useRealTimeUpdates } from '../hooks/useRealTimeUpdates';
import { TaskView } from './views/TaskView';
import TaskTable from './views/TaskTable';
import { CustomTaskFilter } from './components/CustomTaskFilter';
import { TaskSorter, type SortOption, type SortableField } from './components/TaskSorter';
import { TaskDistributionBar } from './components/TaskDistributionBar';
import { StoredUser } from '@/types/user';
import { CustomTask, TaskType, ExtendedCustomTask } from '@/types/custom-task';
import { Board, BoardStatusOption, type KanbanSource, type BoardConfig, type TaskBoardProps, type BoardUpdateResult } from '@/types/custom-board';
import { DealConfigItem } from '@/configs/DealDataConfig';
import { BoardInfo } from './components/BoardInfo';
import BoardSettings from './custom/modals/BoardSettings';
import CreateTaskModal from './custom/modals/CreateTaskModal';
import { useUsers } from '@/contexts/UserContext';
import { Skeleton } from "@/components/ui/skeleton";
import { KanbanColumnSkeleton } from './views/TaskKanban';
import { BoardInfoSkeleton } from './components/BoardInfo';
import { dealDataConfig as defaultDealDataConfig } from '@/configs/DealDataConfig';
import { TaskCompleteDrawer } from './drawers/TaskCompleteDrawer';
import { DropdownOption } from '@/configs/DealDataConfig';

dayjs.extend(utc);
dayjs.extend(timezone);

const ENABLE_REALTIME = false; // Toggle this to enable/disable real-time updates

interface TaskActionsProps {
  task: CustomTask;
}

export interface TaskFilters {
  assignedUsers: string[];
  subtaskAssignedUsers: string[];
  progressStatus: number[];
  boardStatus: number[];
  subtaskFilters: Array<{
    name: string;
    statusId: number;
  }>;
  dealDataFilters: Array<{
    field: string;
    value: string;
    operator?: 'equals' | 'not_equals';
  }>;
  useOrLogic: boolean;
}

interface FilterGroup {
  type: string;
  id: string | number;
  label: string;
}

interface DealDataFilter {
  field: string;
  value: string;
  label: string;
}

interface FilterGroups {
  assignees: FilterGroup[];
  progressStatus: FilterGroup[];
  boardStatus: FilterGroup[];
  dealData: Array<{
    fieldKey: string;
    filters: DealDataFilter[];
  }>;
  subtaskAssignedUsers: string[];
}

interface DealDataConfigItem extends DealConfigItem {
  editable: boolean;
}

export function TaskBoards({
  title,
  fetchUrl,
  columns,
  boardStatusOptions,
  cardConfig,
  dealDataConfig: propDealDataConfig,
  dealSoftwareConfig,
  dealOnboardingConfig,
  taskType,
  defaultView = 'table',
  users: externalUsers,
  tasks,
  setTasks,
  loading: externalLoading = true,
  board: initialBoard,
  isCustomBoard,
  boardConfig,
  isBoardConfigLoading: externalBoardConfigLoading = true,
  onBoardUpdate,
}: TaskBoardProps) {
  const [board, setBoard] = useState<Board | null>(initialBoard);
  const [filteredTasks, setFilteredTasks] = useState<CustomTask[]>([]);
  const [viewMode, setViewMode] = useState<'table' | 'kanban' | 'calendar'>(defaultView);
  const [searchText, setSearchText] = useState('');
  const [isActivityModalVisible, setIsActivityModalVisible] = useState(false);
  const [isSettingsModalVisible, setIsSettingsModalVisible] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { taskId } = useParams();
  const [users, setUsers] = useState<StoredUser[]>([]);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [activeFilters, setActiveFilters] = useState<TaskFilters>({
    assignedUsers: [],
    subtaskAssignedUsers: [],
    progressStatus: [],
    boardStatus: [],
    subtaskFilters: [],
    dealDataFilters: [],
    useOrLogic: false
  });
  const [currentSort, setCurrentSort] = useState<SortOption | undefined>(undefined);
  const [isCreateTaskModalOpen, setIsCreateTaskModalOpen] = useState(false);
  const [isKanbanReversed, setIsKanbanReversed] = useState(false);
  const { currentUser } = useUsers();
  const [isTasksLoading, setIsTasksLoading] = useState(true);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [localTasks, setLocalTasks] = useState<CustomTask[]>([]);
  const [localKanbanTasks, setLocalKanbanTasks] = useState<CustomTask[]>(tasks);
  const [isCompleteDrawerVisible, setIsCompleteDrawerVisible] = useState(false);
  const [taskToComplete, setTaskToComplete] = useState<CustomTask | null>(null);

  const isBoardLoading = externalBoardConfigLoading;

  const effectiveDealDataConfig = propDealDataConfig?.length ? propDealDataConfig : defaultDealDataConfig

  useEffect(() => {
    const storedUsersData = localStorage.getItem('usersData');
    if (storedUsersData) {
      const usersObject = JSON.parse(storedUsersData);
      const usersArray = Object.values(usersObject) as StoredUser[];
      setUsers(usersArray);
    }
  }, []);

  useEffect(() => {
    setBoard(initialBoard);
  }, [initialBoard]);

  const fetchTasks = async () => {
    setIsTasksLoading(true);
    try {
      const response = await axios.get(fetchUrl);
      const tasks = response.data;

      if (board?.board_config?.enable_subtasks) {
        const tasksWithDeals = await Promise.all(tasks.map(async (task: any) => {
          if (task.deal_id && !task.deal_name) {
            try {
              const dealResponse = await axios.get(`/deals/${task.deal_id}`);
              return {
                ...task,
                deal_name: dealResponse.data.name
              };
            } catch (error) {
              console.error(`Error fetching deal for task ${task.id}:`, error);
              return task;
            }
          }
          return task;
        }));

        setTasks(tasksWithDeals);
      } else {
        setTasks(tasks);
      }
      setFilteredTasks(tasks);
    } catch (error) {
      console.error('Error fetching tasks:', error);
      toast.error('Failed to fetch tasks');
    } finally {
      setIsTasksLoading(false);
    }
  };

  useEffect(() => {
    setTasks([]);
    setIsTasksLoading(true);
    fetchTasks();
  }, [taskType, fetchUrl, board?.board_config?.enable_subtasks]);

  const filterTasks = (tasksToFilter: CustomTask[]): CustomTask[] => {
    return tasksToFilter.filter(task => {
      // First apply search filter if exists
      const searchMatch = !searchText || (
        (task.title?.toLowerCase().includes(searchText.toLowerCase()) ||
         task.deal_name?.toLowerCase().includes(searchText.toLowerCase()) ||
         task.description?.toLowerCase().includes(searchText.toLowerCase()))
      );

      if (!searchMatch) return false;

      // If no active filters, return after search match
      const hasActiveFilters = Object.values(activeFilters).some(filter => 
        Array.isArray(filter) ? filter.length > 0 : filter
      );
      if (!hasActiveFilters) return true;

      // Check assigned users
      const assignedUsersMatch = activeFilters.assignedUsers.length === 0 || 
        activeFilters.assignedUsers.some(userId => task.assigned_users?.includes(userId));

      // Check subtask assigned users
      const subtaskAssigneesMatch = activeFilters.subtaskAssignedUsers.length === 0 ||
        task.subtasks?.some(subtask => 
          activeFilters.subtaskAssignedUsers.some(userId => 
            subtask.assigned_users?.includes(userId)
          )
        );

      // Check progress status
      const progressStatusMatch = activeFilters.progressStatus.length === 0 ||
        activeFilters.progressStatus.includes(task.progress_status_id);

      // Check board status
      const boardStatusMatch = activeFilters.boardStatus.length === 0 ||
        activeFilters.boardStatus.includes(task.board_status_id);

      // Check subtask filters
      const subtaskFiltersMatch = activeFilters.subtaskFilters.length === 0 ||
        task.subtasks?.some(subtask =>
          activeFilters.subtaskFilters.some(filter =>
            filter.name === subtask.title &&
            filter.statusId === subtask.progress_status_id
          )
        );

      // Check deal data filters
      const dealDataMatch = activeFilters.dealDataFilters.length === 0 ||
        activeFilters.dealDataFilters.every(filter => {
          // Get the field config to check if it's a boolean field
          const fieldConfig = effectiveDealDataConfig.find(f => 
            f.key === filter.field || f.key === filter.field.replace('data.', '')
          );

          // Get the value from the task
          let value;
          if (filter.field.startsWith('data.')) {
            value = task.deal?.data?.[filter.field.replace('data.', '')];
          } else if (filter.field === 'is_holding') {
            value = task.deal?.is_holding;
          } else {
            value = task.deal_data?.[filter.field];
          }

          // For boolean fields, handle null as false
          if (fieldConfig?.type === 'boolean') {
            value = value === null ? false : value;
            const filterValue = filter.value === 'true';
            if (filter.operator === 'not_equals') {
              return value !== filterValue;
            }
            return value === filterValue;
          }

          // For non-boolean fields, compare as before
          if (filter.operator === 'not_equals') {
            return value !== filter.value;
          }
          return value === filter.value;
        });

      // Apply OR/AND logic based on useOrLogic flag
      if (activeFilters.useOrLogic) {
        return assignedUsersMatch || 
               subtaskAssigneesMatch || 
               progressStatusMatch || 
               boardStatusMatch || 
               subtaskFiltersMatch || 
               dealDataMatch;
      }

      return assignedUsersMatch && 
             subtaskAssigneesMatch && 
             progressStatusMatch && 
             boardStatusMatch && 
             subtaskFiltersMatch && 
             dealDataMatch;
    });
  };

  const sortTasks = (tasksToSort: CustomTask[], sort: SortOption | undefined): CustomTask[] => {
    if (!sort) return tasksToSort;
    return [...tasksToSort].sort((a, b) => {
      const aValue = (a as Record<string, any>)[sort.field];
      const bValue = (b as Record<string, any>)[sort.field];
      return sort.direction === 'asc' ? 
        (aValue > bValue ? 1 : -1) : 
        (aValue < bValue ? 1 : -1);
    });
  };

  useEffect(() => {
    const filtered = filterTasks(tasks);
    const sorted = sortTasks(filtered, currentSort);
    setFilteredTasks(sorted);
    
    // Also update kanban tasks if we're in kanban view
    if (viewMode === 'kanban') {
      setLocalKanbanTasks(sorted);
    }
  }, [tasks, activeFilters, searchText, currentSort, viewMode]);

  // Remove the old useEffect that was updating localKanbanTasks
  // This was causing the filter reset issue
  useEffect(() => {
    if (viewMode === 'kanban') {
      const filtered = filterTasks(tasks);
      const sorted = sortTasks(filtered, currentSort);
      setLocalKanbanTasks(sorted);
    }
  }, [viewMode]);

  const getLastActivity = (task: ExtendedCustomTask): string | null => {
    const dates: string[] = [];
    
    if (task.updates?.length) {
      dates.push(task.updates[task.updates.length - 1].timestamp);
    }
    if (task.comments?.length) {
      dates.push(task.comments[task.comments.length - 1].timestamp);
    }
    
    return dates.length ? dates.sort().reverse()[0] : null;
  };

  const getNestedValue = (obj: any, path: string): any => {
    // Handle special case for deal data fields
    if (path.startsWith('data.')) {
      const dealData = obj.deal?.data;
      if (!dealData) return undefined;
      const fieldKey = path.replace('data.', '');
      return dealData[fieldKey];
    }

    // Handle direct fields on deal object
    if (path === 'main_mailbox' || path === 'corpay_mailbox' || path === 'aiia_mailbox') {
      return obj.deal?.[path];
    }

    // Handle payment details
    if (path.startsWith('payment_details.')) {
      const paymentDetails = obj.deal?.payment_details;
      if (!paymentDetails) return undefined;
      const fieldKey = path.replace('payment_details.', '');
      return paymentDetails[fieldKey];
    }

    // Handle other nested paths
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  };

  const handleOpenTask = (task: CustomTask) => {
    navigate(`/custom-boards/${board?.id}/${task.id}`, { 
      state: { 
        tasks: filteredTasks, 
        taskType,
        selectedTask: task 
      }
    });
  };

  const handleTaskUpdate = async (taskId: string, updatedFields: Partial<CustomTask>) => {
    try {
      const response = await axios.put<CustomTask>(`/custom-board/tasks/${taskId}`, updatedFields);
      
      // Find the existing task to preserve its properties
      const existingTask = tasks.find(t => t.id.toString() === taskId);
      if (!existingTask) return;

      const updatedTask = {
        ...existingTask,
        ...response.data,
        is_custom: true,
        task_type: existingTask.task_type,
        board_config: existingTask.board_config
      };

      // Update tasks while preserving filters
      const updatedTasks = tasks.map(task => 
        task.id.toString() === taskId ? updatedTask : task
      );
      setTasks(updatedTasks);

      // Reapply filters to maintain filtered state
      const filtered = filterTasks(updatedTasks);
      const sorted = sortTasks(filtered, currentSort);
      
      // Update filtered tasks and kanban tasks
      setFilteredTasks(sorted);
      if (viewMode === 'kanban') {
        setLocalKanbanTasks(sorted);
      }
    } catch (error) {
      console.error('Error updating task:', error);
      throw error;
    }
  };

  const handleAssigneeUpdate = async (taskId: number, newAssignees: string[]) => {
    try {
      const response = await axios.put(`/custom-board/tasks/${taskId}`, {
        assigned_users: newAssignees
      });
      
      if (response.status === 200) {
        toast.success('Assignees updated successfully');
        setTasks(prevTasks => 
          prevTasks.map(task => 
            task.id === taskId 
              ? { ...task, assigned_users: newAssignees }
              : task
          )
        );
      }
    } catch (error) {
      console.error('Error updating assignees:', error);
      toast.error('Failed to update assignees');
    }
  };

  const handleSubtaskClick = (taskId: number, subtaskId: number) => {
    // Update URL without navigation
    window.history.pushState(
      {}, 
      '', 
      `/custom-boards/${board?.id}/${taskId}/${subtaskId}`
    );
  };

  useEffect(() => {
    // Listen for popstate to handle browser back/forward
    const handlePopState = () => {
      const match = window.location.pathname.match(/\/custom-boards\/\d+\/task\/(\d+)\/subtask\/(\d+)/);
      if (!match) {
        // Reset URL to board view if no subtask in path
        window.history.pushState(
          {}, 
          '', 
          `/custom-boards/${board?.id}`
        );
      }
    };

    window.addEventListener('popstate', handlePopState);
    return () => window.removeEventListener('popstate', handlePopState);
  }, [board?.id]);

  useRealTimeUpdates(taskType, null, (updatedTask: CustomTask) => {
    if (!ENABLE_REALTIME) return;
    
    if (updatedTask && updatedTask.id) {
      setTasks((prevTasks: CustomTask[]) => 
        prevTasks.map(task => 
          task.id === updatedTask.id ? updatedTask : task
        )
      );
    }
  });

  const handleBoardUpdate = async (result: BoardUpdateResult) => {
    // Always update the board state
    setBoard(result.updatedBoard);
    
    // If board_config was changed, refetch tasks and board config
    if (result.configChanged) {
      setIsTasksLoading(true);
      setTasks([]); // Clear existing tasks
      fetchTasks(); // Refetch tasks with new config
    }

    // Call the parent's onBoardUpdate if provided
    onBoardUpdate?.(result);
  };

  const handleCreateTask = async (values: Partial<CustomTask> | Partial<CustomTask>[]) => {
    try {
      // Extract board slug from fetchUrl
      const boardSlug = fetchUrl.split('/')[3];
      
      const isProgressKanban = board?.board_config?.kanban_source === 'progress';
      const statusOptions = isProgressKanban 
        ? board?.board_config?.progress_status_options 
        : board?.board_config?.board_status_options;
      const initialStatusId = board?.board_config?.progress_status_options?.[0]?.id ?? 1;
      const initialBoardStatusId = board?.board_config?.board_status_options?.[0]?.id;

      // Handle bulk task creation
      if (Array.isArray(values)) {
        const tasksData = values.map(value => ({
          ...value,
          board_id: board?.id, // Use board ID from board object
          progress_status_id: value.progress_status_id || initialStatusId,
          board_status_id: value.board_status_id || (isProgressKanban ? undefined : initialBoardStatusId),
          task_type: 'custom' as const,
          is_custom: true,
          board_config: board?.board_config
        }));

        const response = await axios.post(`/custom-board/boards/${board?.id}/tasks`, tasksData);
        
        if (response.status === 200) {
          toast.success('Tasks created successfully');
          
          // Add all new tasks to state with complete data from response
          const newTasks = response.data.map((task: any) => ({
            ...task,
            task_type: 'custom',
            is_custom: true,
            board_config: board?.board_config,
            // Ensure these fields are included from the response
            deal_id: task.deal_id,
            deal_name: task.deal_name,
            progress_status_id: task.progress_status_id,
            board_status_id: task.board_status_id
          }));
          
          setTasks(prevTasks => [...prevTasks, ...newTasks]);
        }
      }
      // Handle single task creation
      else {
        const taskData = {
          ...values,
          board_id: board?.id, // Use board ID from board object
          progress_status_id: values.progress_status_id || initialStatusId,
          board_status_id: values.board_status_id || (isProgressKanban ? undefined : initialBoardStatusId),
          task_type: 'custom' as const,
          is_custom: true,
          board_config: board?.board_config
        };

        const response = await axios.post(`/custom-board/boards/${board?.id}/tasks`, taskData);
        
        if (response.status === 200) {
          toast.success('Task created successfully');
          
          // Add the new task to state with complete data from response
          const newTask = {
            ...response.data,
            task_type: 'custom',
            is_custom: true,
            board_config: board?.board_config,
            // Ensure these fields are included from the response
            deal_id: response.data.deal_id,
            deal_name: response.data.deal_name,
            progress_status_id: response.data.progress_status_id,
            board_status_id: response.data.board_status_id
          };
          
          setTasks(prevTasks => [...prevTasks, newTask]);
        }
      }
    } catch (error) {
      console.error('Error creating task:', error);
      toast.error('Failed to create task');
    }
  };

  const canReverseKanban = () => {
    if (!board?.board_config) return false;
    const { progress_status_options, board_status_options } = board.board_config;
    return taskType === 'custom' && 
           Array.isArray(progress_status_options) && progress_status_options.length > 0 && 
           Array.isArray(board_status_options) && board_status_options.length > 0;
  };

  // Update the isAdmin check to use currentUser from useUsers
  const isAdmin = useMemo(() => {
    if (!currentUser) return false;
    return board?.created_by === currentUser.user_id || 
           board?.access_permissions?.some(p => p.user_id === currentUser.user_id && p.is_admin);
  }, [board, currentUser]);

  const handleFiltersChange = (newFilters: TaskFilters) => {
    setActiveFilters({
      ...newFilters,
      subtaskAssignedUsers: newFilters.subtaskAssignedUsers || [],
      subtaskFilters: newFilters.subtaskFilters || []
    });
  };

  const renderFilterBadges = () => {
    // Group filters by category
    const filterGroups: FilterGroups = {
      assignees: activeFilters.assignedUsers.map(userId => ({
        type: 'assignee',
        id: userId,
        label: users.find(u => String(u.user_id) === userId)?.name || ''
      })),
      progressStatus: activeFilters.progressStatus.map(statusId => ({
        type: 'progress',
        id: statusId,
        label: board?.board_config?.progress_status_options.find(s => s.id === statusId)?.label || ''
      })),
      boardStatus: activeFilters.boardStatus.map(statusId => ({
        type: 'board',
        id: statusId,
        label: board?.board_config?.board_status_options?.find(s => s.id === statusId)?.label || ''
      })),
      dealData: activeFilters.dealDataFilters.reduce<Array<{fieldKey: string; filters: DealDataFilter[]}>>((acc, filter) => {
        const fieldKey = filter.field;
        const existingGroup = acc.find(g => g.fieldKey === fieldKey);
        const fieldConfig = effectiveDealDataConfig.find(f => 
          f.key === fieldKey || f.key === fieldKey.replace('data.', '')
        );
        
        const newFilter: DealDataFilter = {
          field: filter.field,
          value: filter.value,
          label: fieldConfig?.label || fieldKey
        };

        if (!existingGroup) {
          acc.push({
            fieldKey,
            filters: [newFilter]
          });
        } else {
          existingGroup.filters.push(newFilter);
        }
        return acc;
      }, []),
      subtaskAssignedUsers: activeFilters.subtaskAssignedUsers || []
    };

    const hasAnyFilters = Object.values(filterGroups).some(group => 
      Array.isArray(group) ? group.length > 0 : Object.keys(group).length > 0
    );

    if (!hasAnyFilters) return null;

    // Count total active filters
    const totalFilters = 
      filterGroups.assignees.length + 
      filterGroups.progressStatus.length + 
      filterGroups.boardStatus.length + 
      filterGroups.dealData.reduce((acc, group) => acc + group.filters.length, 0) +
      activeFilters.subtaskAssignedUsers.length;

    // If 3 or fewer filters are active, show them individually
    if (totalFilters <= 3) {
      const badges: JSX.Element[] = [];

      // Add assignee badges
      filterGroups.assignees.forEach(assignee => {
        badges.push(
          <Badge 
            key={`assignee-${assignee.id}`}
            variant="secondary"
            className="flex items-center gap-1"
          >
            <UserCheck className="h-3 w-3" />
            <span className="max-w-[300px] truncate">{assignee.label}</span>
            <Button
              variant="ghost"
              size="icon"
              className="h-4 w-4 p-0"
              onClick={() => setActiveFilters(prev => ({
                ...prev,
                assignedUsers: prev.assignedUsers.filter(id => id !== assignee.id.toString())
              }))}
            >
              <X className="h-3 w-3" />
            </Button>
          </Badge>
        );
      });

      // Add subtask assignee badges
      filterGroups.subtaskAssignedUsers?.forEach(userId => {
        const user = users.find(u => String(u.user_id) === userId);
        if (user) {
          badges.push(
            <Badge 
              key={`subtask-assignee-${userId}`}
              variant="secondary"
              className="flex items-center gap-1"
            >
              <SplitSquareHorizontal className="h-3 w-3" />
              <span className="max-w-[300px] truncate">Subtask: {user.name}</span>
              <Button
                variant="ghost"
                size="icon"
                className="h-4 w-4 p-0"
                onClick={() => setActiveFilters(prev => ({
                  ...prev,
                  subtaskAssignedUsers: prev.subtaskAssignedUsers.filter(id => id !== userId)
                }))}
              >
                <X className="h-3 w-3" />
              </Button>
            </Badge>
          );
        }
      });

      // Add progress status badges
      filterGroups.progressStatus.forEach(status => {
        badges.push(
          <Badge 
            key={`progress-${status.id}`}
            variant="secondary"
            className="flex items-center gap-1"
          >
            <Filter className="h-3 w-3" />
            <span className="max-w-[300px] truncate">Progress: {status.label}</span>
            <Button
              variant="ghost"
              size="icon"
              className="h-4 w-4 p-0"
              onClick={() => setActiveFilters(prev => ({
                ...prev,
                progressStatus: prev.progressStatus.filter(id => id !== status.id)
              }))}
            >
              <X className="h-3 w-3" />
            </Button>
          </Badge>
        );
      });

      // Add board status badges
      filterGroups.boardStatus.forEach(status => {
        badges.push(
          <Badge 
            key={`board-${status.id}`}
            variant="secondary"
            className="flex items-center gap-1"
          >
            <Filter className="h-3 w-3" />
            <span className="max-w-[300px] truncate">Board: {status.label}</span>
            <Button
              variant="ghost"
              size="icon"
              className="h-4 w-4 p-0"
              onClick={() => setActiveFilters(prev => ({
                ...prev,
                boardStatus: prev.boardStatus.filter(id => id !== status.id)
              }))}
            >
              <X className="h-3 w-3" />
            </Button>
          </Badge>
        );
      });

      // Add deal data badges
      filterGroups.dealData.forEach(group => {
        group.filters.forEach(filter => {
          const fieldConfig = effectiveDealDataConfig.find(f => 
            f.key === filter.field || f.key === filter.field.replace('data.', '')
          );
          const option = fieldConfig?.options?.find((opt: DropdownOption) => opt.value === filter.value);
          
          badges.push(
            <Badge 
              key={`deal-${filter.field}-${filter.value}`}
              variant="secondary"
              className="flex items-center gap-1"
            >
              <Briefcase className="h-3 w-3" />
              <span className="max-w-[300px] truncate">
                {fieldConfig?.label || filter.field}: {option?.label || filter.value}
              </span>
              <Button
                variant="ghost"
                size="icon"
                className="h-4 w-4 p-0"
                onClick={() => setActiveFilters(prev => ({
                  ...prev,
                  dealDataFilters: prev.dealDataFilters.filter(f => 
                    !(f.field === filter.field && f.value === filter.value)
                  )
                }))}
              >
                <X className="h-3 w-3" />
              </Button>
            </Badge>
          );
        });
      });

      return <div className="flex gap-2 flex-wrap">{badges}</div>;
    }

    // If more than 3 filters are active, show count with tooltip
    return (
      <TooltipProvider>
        <Tooltip delayDuration={0}>
          <TooltipTrigger asChild>
            <div className="inline-flex">
              <Badge 
                variant="secondary"
                className="flex items-center gap-1 cursor-help"
              >
                <Filter className="h-3 w-3" />
                <span>{totalFilters} filters active</span>
                <Button
                  variant="ghost"
                  size="icon"
                  className="h-4 w-4 p-0"
                  onClick={(e) => {
                    e.stopPropagation();
                    setActiveFilters({
                      assignedUsers: [],
                      subtaskAssignedUsers: [],
                      progressStatus: [],
                      boardStatus: [],
                      subtaskFilters: [],
                      dealDataFilters: [],
                      useOrLogic: false
                    });
                  }}
                >
                  <X className="h-3 w-3" />
                </Button>
              </Badge>
            </div>
          </TooltipTrigger>
          <TooltipContent side="bottom" align="start" className="max-w-sm p-2 z-50">
            <div className="space-y-1">
              {filterGroups.assignees.length > 0 && (
                <div className="text-sm">
                  <span className="font-medium">Assignees:</span> {filterGroups.assignees.map(a => a.label).join(', ')}
                </div>
              )}
              {filterGroups.progressStatus.length > 0 && (
                <div className="text-sm">
                  <span className="font-medium">Progress Status:</span> {filterGroups.progressStatus.map(s => s.label).join(', ')}
                </div>
              )}
              {filterGroups.boardStatus.length > 0 && (
                <div className="text-sm">
                  <span className="font-medium">Board Status:</span> {filterGroups.boardStatus.map(s => s.label).join(', ')}
                </div>
              )}
              {filterGroups.dealData.map(group => {
                const fieldConfig = effectiveDealDataConfig.find(f => 
                  f.key === group.fieldKey || f.key === group.fieldKey.replace('data.', '')
                );
                return (
                  <div key={group.fieldKey} className="text-sm">
                    <span className="font-medium">{fieldConfig?.label || group.fieldKey}:</span>{' '}
                    {group.filters.map(f => {
                      const option = fieldConfig?.options?.find((opt: DropdownOption) => opt.value === f.value);
                      return option?.label || f.value;
                    }).join(', ')}
                  </div>
                );
              })}
              {filterGroups.subtaskAssignedUsers?.length > 0 && (
                <div className="text-sm">
                  <span className="font-medium">Subtask Assignees:</span>{' '}
                  {filterGroups.subtaskAssignedUsers
                    .map(userId => users.find(u => String(u.user_id) === userId)?.name)
                    .filter(Boolean)
                    .join(', ')}
                </div>
              )}
            </div>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  };

  const handleSortChange = (newSort: SortOption | undefined) => {
    setCurrentSort(newSort);
  };

  const safeBoard = board || null;

  // Update renderHeader to add logging
  const renderHeader = () => {
    return (
      <div className="flex-none pb-0">
        <div className="flex justify-between items-center mb-1">
          {isBoardLoading ? (
            <div className="flex items-center gap-4">
              <Skeleton className="h-8 w-48" /> {/* Board title */}
              <Skeleton className="h-8 w-8" /> {/* Collapse button */}
            </div>
          ) : (
            <div className="flex items-center gap-4">
              {board?.board_config && board?.description ? (
                <TooltipProvider>
                  <Tooltip delayDuration={0}>
                    <TooltipTrigger asChild>
                      <h1 className="text-2xl text-primary cursor-help">{title}</h1>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>{board.description}</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              ) : (
                <h1 className="text-2xl text-primary">{title}</h1>
              )}
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={() => setIsCollapsed(!isCollapsed)}
                      className="h-8 w-8"
                    >
                      {isCollapsed ? (
                        <ChevronDown className="h-4 w-4" />
                      ) : (
                        <ChevronUp className="h-4 w-4" />
                      )}
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>{isCollapsed ? "Expand view" : "Collapse view"}</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          )}
          {isBoardLoading ? (
            <BoardInfoSkeleton />
          ) : (
            <BoardInfo 
              isCustomBoard={board?.board_config ? true : false}
              board={board}
              taskType={taskType}
            />
          )}
        </div>

        <div className={cn(
          "space-y-2",
          isCollapsed ? "h-0 opacity-0 overflow-hidden" : "h-auto opacity-100 mb-0"
        )}>
          {isBoardLoading ? (
            <>
              <div className="flex items-center gap-2">
                {/* View mode tabs skeleton */}
                <Skeleton className="h-9 w-[300px] rounded-lg" />
                {/* Switch view button skeleton */}
                <Skeleton className="h-7 w-7" />
              </div>

              <div className="flex justify-between items-start">
                <div className="flex-1 flex gap-2 items-center">
                  {/* Search input skeleton */}
                  <Skeleton className="h-9 w-[300px]" />
                  {/* Filter button skeleton */}
                  <Skeleton className="h-9 w-9" />
                  {/* Sort button skeleton */}
                  <Skeleton className="h-9 w-9" />
                </div>
                
                <div className="flex gap-2 items-center">
                  {/* Distribution bar skeleton */}
                  <Skeleton className="h-8 w-40" />
                  {/* Action buttons skeleton */}
                  <Skeleton className="h-9 w-[90px]" /> {/* New Task button */}
                  <Skeleton className="h-9 w-9" /> {/* History button */}
                  <Skeleton className="h-9 w-9" /> {/* Settings button */}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="flex items-center gap-2">
                <Tabs 
                  defaultValue={defaultView || 'table'} 
                  onValueChange={(value) => {
                    setViewMode(value as 'table' | 'kanban' | 'calendar');
                    if (value !== 'kanban') {
                      setIsKanbanReversed(false);
                    }
                  }} 
                  className="w-auto"
                >
                  <TabsList>
                    <TabsTrigger value="table">
                      Table
                      {defaultView === 'table' && <Star className="ml-1 h-3 w-3 text-primary" />}
                    </TabsTrigger>
                    <TabsTrigger value="kanban">
                      Kanban
                      {defaultView === 'kanban' && <Star className="ml-1 h-3 w-3 text-primary" />}
                    </TabsTrigger>
                    {board?.board_config?.enable_calendar_view && (
                      <TabsTrigger value="calendar">
                        Calendar
                        {defaultView === 'calendar' && <Star className="ml-1 h-3 w-3 text-primary" />}
                      </TabsTrigger>
                    )}
                  </TabsList>
                </Tabs>
                {viewMode === 'kanban' && canReverseKanban() && (
                  <TooltipProvider>
                    <Tooltip delayDuration={0}>
                      <TooltipTrigger asChild>
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={() => setIsKanbanReversed(!isKanbanReversed)}
                          className={cn(
                            "h-7 w-7",
                            isKanbanReversed && "bg-primary text-primary-foreground hover:bg-primary/90"
                          )}
                        >
                          <SwitchCamera className="h-4 w-4" />
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>
                          {isKanbanReversed 
                            ? `Switch back to ${board?.board_config?.kanban_source === 'progress' ? 'progress' : 'board'} status view`
                            : `Switch to ${board?.board_config?.kanban_source === 'progress' ? 'board' : 'progress'} status view`}
                        </p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                )}
              </div>

              <div className="flex justify-between items-start">
                <div className="flex-1 flex gap-2 items-center">
                  <div className="relative w-[100px] group focus-within:w-[300px] transition-[width] duration-200 ease-out">
                    <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
                    <Input
                      placeholder={searchText ? "Search by company or task name..." : "Search..."}
                      onFocus={(e) => e.target.placeholder = "Search by company or task name..."}
                      onBlur={(e) => e.target.placeholder = searchText ? "Search by company or task name..." : "Search..."}
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                      className="pl-8 w-full"
                    />
                  </div>
                  <TooltipProvider>
                    <Tooltip delayDuration={0}>
                      <TooltipTrigger asChild>
                        <Button 
                          variant="outline" 
                          size="icon"
                          onClick={() => setIsFilterModalOpen(true)}
                          className={cn(
                            "h-9 w-9",
                            Object.values(activeFilters).some(filter => 
                              Array.isArray(filter) ? filter.length > 0 : filter
                            ) && "bg-accent"
                          )}
                        >
                          <Filter className="h-4 w-4" />
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent side="bottom">
                        <p>Filter tasks</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                  <TaskSorter
                    currentSort={currentSort}
                    onSortChange={handleSortChange}
                  />
                  {renderFilterBadges()}
                </div>
                <div className="flex gap-2 items-center">
                  <div className="px-1 py-1 overflow-visible">
                    <TaskDistributionBar
                      tasks={viewMode === 'kanban' ? localKanbanTasks : filteredTasks}
                      statusOptions={board?.board_config ? (
                        isKanbanReversed 
                          ? (board.board_config.kanban_source === 'progress' 
                              ? board.board_config.board_status_options || [] 
                              : board.board_config.progress_status_options || [])
                          : (board.board_config.kanban_source === 'progress'
                              ? board.board_config.progress_status_options || []
                              : board.board_config.board_status_options || [])
                      ) : boardStatusOptions}
                      board={board}
                      isKanbanReversed={isKanbanReversed}
                    />
                  </div>
                  <Button onClick={() => setIsCreateTaskModalOpen(true)}>
                    <Plus className="mr-0 h-4 w-4" />
                    Task
                  </Button>
                  <TooltipProvider>
                    <Tooltip delayDuration={0}>
                      <TooltipTrigger asChild>
                        <Button variant="outline" size="icon" onClick={() => setIsActivityModalVisible(true)}>
                          <History className="h-4 w-4" />
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>View activity</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                  {isAdmin && (
                    <TooltipProvider>
                      <Tooltip delayDuration={0}>
                        <TooltipTrigger asChild>
                          <Button variant="outline" size="icon" onClick={() => setIsSettingsModalVisible(true)}>
                            <Settings className="h-4 w-4" />
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>Board settings</p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  const handleTaskComplete = async (task: CustomTask) => {
    if (!board?.board_config?.completion_settings?.show_completion_drawer) {
      // If no drawer is configured, just update the status
      handleTaskUpdate(task.id.toString(), {
        progress_status_id: task.progress_status_id,
        board_status_id: task.board_status_id
      });
      return;
    }

    setTaskToComplete(task);
    setIsCompleteDrawerVisible(true);
  };

  const handleCompleteDrawerSubmit = async (values: any) => {
    if (!taskToComplete || !board?.board_config?.completion_settings?.drawer_config) return;

    try {
      const response = await axios.put(`/custom-board/tasks/${taskToComplete.id}`, {
        progress_status_id: taskToComplete.progress_status_id,
        board_status_id: taskToComplete.board_status_id,
        completion_data: values
      });

      if (response.status === 200) {
        toast.success('Task completed successfully');
        
        // Update tasks in state
        setTasks(prevTasks => 
          prevTasks.map(t => 
            t.id === taskToComplete.id 
              ? { ...t, ...response.data }
              : t
          )
        );

        // Close drawer
        setIsCompleteDrawerVisible(false);
        setTaskToComplete(null);
      }
    } catch (error) {
      console.error('Error completing task:', error);
      toast.error('Failed to complete task');
    }
  };

  return (
    <div className={cn(
      "flex flex-col h-full",
      viewMode === 'kanban' ? "overflow-hidden px-0" : "min-h-[calc(100vh-3.5rem)]"
    )}>
      <div className="px-6">
        {renderHeader()}
      </div>

      <div className={cn(
        "flex-1 min-h-0 relative transition-all duration-200",
        isCollapsed ? "h-[calc(100vh-4.5rem)]" : "h-[calc(100vh-11.5rem)]",
        // Add padding only for table view
        viewMode === 'table' && "px-6"
      )}>
        {isBoardLoading ? (
          <div className="pl-6 flex gap-3">
            {Array.from({ length: 3 }).map((_, index) => (
              <KanbanColumnSkeleton key={`column-skeleton-${index}`} className="mt-4" />
            ))}
          </div>
        ) : (
          !taskId && (
            <Tabs value={viewMode} onValueChange={(value) => setViewMode(value as 'table' | 'kanban' | 'calendar')}>
              <TabsContent value="table" className="h-full">
                <TaskTable
                  dataSource={filteredTasks}
                  columns={columns}
                  handleOpenTask={handleOpenTask}
                  loading={isTasksLoading}
                  isBoardConfigLoading={isBoardLoading}
                  onAssigneeUpdate={handleAssigneeUpdate}
                  board={safeBoard}
                  onSubtaskClick={handleSubtaskClick}
                  onDataSourceChange={setTasks}
                  isCollapsed={isCollapsed}
                />
              </TabsContent>
              <TabsContent value="kanban" className="h-full overflow-hidden">
                <ScrollArea className="h-full w-full" enableKeyboardNav scrollAmount={400}>
                  <div className="min-w-max">
                    <TaskKanban
                      tasks={localKanbanTasks}
                      onTaskUpdate={handleTaskUpdate}
                      onAssigneeUpdate={handleAssigneeUpdate}
                      boardStatusOptions={board?.board_config ? (
                        isKanbanReversed 
                          ? (board.board_config.kanban_source === 'progress' 
                              ? board.board_config.board_status_options || [] 
                              : board.board_config.progress_status_options || [])
                          : (board.board_config.kanban_source === 'progress'
                              ? board.board_config.progress_status_options || []
                              : board.board_config.board_status_options || [])
                      ) : boardStatusOptions}
                      cardConfig={cardConfig}
                      dealDataConfig={effectiveDealDataConfig}
                      dealSoftwareConfig={dealSoftwareConfig}
                      dealOnboardingConfig={dealOnboardingConfig}
                      taskType={taskType}
                      setTasks={setTasks}
                      loading={isTasksLoading}
                      board={board}
                      isKanbanReversed={isKanbanReversed}
                      onSubtaskClick={handleSubtaskClick}
                      isBoardConfigLoading={isBoardLoading}
                      isCollapsed={isCollapsed}
                      users={users}
                      onLocalTasksChange={(updatedTasks) => {
                        setLocalKanbanTasks(updatedTasks);
                        // Also update filtered tasks to keep distribution bar in sync
                        const filtered = filterTasks(updatedTasks);
                        const sorted = sortTasks(filtered, currentSort);
                        setFilteredTasks(sorted);
                      }}
                    />
                  </div>
                </ScrollArea>
              </TabsContent>
              <TabsContent value="calendar" className="h-full">
                <TaskCalendar tasks={filteredTasks} />
              </TabsContent>
            </Tabs>
          )
        )}
      </div>

      {/* Modals and dialogs */}
      <BoardActivity 
        open={isActivityModalVisible}
        onClose={() => setIsActivityModalVisible(false)} 
        board={board}
      />

      {board?.board_config && board && (
        <BoardSettings
          open={isSettingsModalVisible}
          onClose={() => setIsSettingsModalVisible(false)}
          board={board}
          onBoardUpdate={handleBoardUpdate}
        />
      )}

      <Routes>
        <Route 
          path=":taskId" 
          element={
            <TaskView 
              onClose={() => navigate(`/custom-boards/${board?.id}`, { replace: true })}
              tasks={filteredTasks}
              taskType={taskType}
              boardStatusOptions={boardStatusOptions}
              task={(location.state as any)?.selectedTask || filteredTasks.find(t => t.id.toString() === taskId)}
              fromWork={false}
              getBoardName={() => board?.name || ''}
              setTasks={setTasks}
              users={users}
              board={board}
              dealName={board?.name || ''}
            />
          } 
        />
      </Routes>

      <CustomTaskFilter
        open={isFilterModalOpen}
        onOpenChange={setIsFilterModalOpen}
        onFiltersChange={handleFiltersChange}
        board={board}
        currentFilters={activeFilters}
        users={users}
      />

      {board?.board_config && board && (
        <CreateTaskModal
          open={isCreateTaskModalOpen}
          onClose={() => setIsCreateTaskModalOpen(false)}
          onSubmit={handleCreateTask}
          board={board}
        />
      )}

      <TaskCompleteDrawer
        visible={isCompleteDrawerVisible}
        onClose={() => setIsCompleteDrawerVisible(false)}
        task={taskToComplete}
        onComplete={handleCompleteDrawerSubmit}
        sections={board?.board_config?.completion_settings?.drawer_config?.sections || []}
        title={board?.board_config?.completion_settings?.drawer_config?.title}
        description={board?.board_config?.completion_settings?.drawer_config?.description}
      />
    </div>
  );
}

export default TaskBoards;