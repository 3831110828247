import * as React from "react"
import { cn } from "@/lib/utils"
import { ScrollArea } from "@/components/ui/scroll-area"
import { getTextColor } from "@/lib/color-utils"

export const KANBAN_COLUMN_WIDTH = 315;
export const KANBAN_CARD_WIDTH = 297; // This should match the width used in the cards

const KanbanCard = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      "rounded-xl border border-border bg-card text-card-foreground shadow",
      "flex flex-col",
      "h-full",
      "flex-shrink-0",
      className
    )}
    style={{ width: `${KANBAN_COLUMN_WIDTH}px` }}
    {...props}
  />
))
KanbanCard.displayName = "KanbanCard"

const KanbanCardHeader = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & {
    color?: string;
  }
>(({ className, color, ...props }, ref) => {
  // Get the appropriate text color based on the background color
  const textColor = color ? getTextColor(color) : undefined;

  return (
    <div
      ref={ref}
      className={cn(
        "flex items-center justify-between p-4 rounded-t-xl font-semibold",
        "flex-shrink-0",
        className
      )}
      style={{ 
        backgroundColor: color,
        color: textColor
      }}
      {...props}
    />
  );
})
KanbanCardHeader.displayName = "KanbanCardHeader"

const KanbanCardContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div className="flex-1 min-h-0">
    <ScrollArea
      ref={ref}
      className={cn(
        "h-full",
        className
      )}
    >
      <div className="p-2" {...props} />
    </ScrollArea>
  </div>
))
KanbanCardContent.displayName = "KanbanCardContent"

export { KanbanCard, KanbanCardHeader, KanbanCardContent }