import React, { useState, useEffect, useRef } from 'react';
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { Send, Trash2, Loader2, ChevronDown, ChevronUp, Reply } from "lucide-react";
import axios from '@/api/axiosConfig';
import dayjs from 'dayjs';
import { UserAvatar } from "@/components/user-avatar";
import { StoredUser } from '@/types/user';
import { ScrollArea } from "@/components/ui/scroll-area";
import { toast } from 'sonner';
import { cn } from "@/lib/utils";
import { useUsers } from '@/contexts/UserContext';

interface Comment {
  id: string | number;
  content: string;
  timestamp: string;
  user_id: string | number;
  subtask_id?: number;
  subtask_title?: string;
  parent_id?: string | number;
  replies?: Comment[];
}

interface Task {
  id: string | number;
  comments?: Comment[];
  task_type?: string;
  board?: {
    board_config: {
      enable_subtasks?: boolean;
    };
  };
  subtasks?: Array<{
    id: number;
    title: string;
    comments?: Comment[];
  }>;
}

interface TaskViewNotesProps {
  task: Task | null;
  taskType: string;
  users: StoredUser[];
  onUpdate: (updatedTask?: Task) => void;
}

const TaskViewNotes: React.FC<TaskViewNotesProps> = ({ task, taskType, users, onUpdate }) => {
  const [newComment, setNewComment] = useState('');
  const [replyTexts, setReplyTexts] = useState<Record<string | number, string>>({});
  const [loading, setLoading] = useState(false);
  const [allComments, setAllComments] = useState<Comment[]>([]);
  const [expandedThreads, setExpandedThreads] = useState<Set<string | number>>(new Set());
  const [replyTo, setReplyTo] = useState<string | number | null>(null);
  const replyTextareaRef = useRef<HTMLTextAreaElement>(null);
  const { currentUser } = useUsers();

  // Add optimistic state tracking
  const [deletingComments, setDeletingComments] = useState<Set<string | number>>(new Set());
  const [optimisticDeletes, setOptimisticDeletes] = useState<Set<string | number>>(new Set());

  useEffect(() => {
    if (task) {
      let comments: Comment[] = task.comments || [];

      // If this is a custom task with subtasks enabled and has subtasks
      if (task.task_type === 'custom' && 
          task.board?.board_config?.enable_subtasks && 
          task.subtasks && 
          task.subtasks.length > 0) {
        // Add comments from subtasks
        const subtaskComments = task.subtasks.flatMap(subtask => {
          return (subtask.comments || []).map(comment => ({
            ...comment,
            subtask_id: subtask.id,
            subtask_title: subtask.title
          }));
        });
        
        comments = [...comments, ...subtaskComments];
      }

      // Sort all comments by timestamp
      comments.sort((a, b) => 
        new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
      );

      // Group replies with their parent comments
      const commentMap = new Map<string | number, Comment>();
      const rootComments: Comment[] = [];

      // First pass: collect all root comments and create map entries for all comments
      comments.forEach(comment => {
        // Create a copy of the comment with empty replies array
        const commentCopy = { ...comment, replies: [] };
        commentMap.set(comment.id, commentCopy);
        
        if (!comment.parent_id) {
          rootComments.push(commentCopy);
        }
      });

      // Second pass: attach replies to their parents
      comments.forEach(comment => {
        if (comment.parent_id) {
          const parentComment = commentMap.get(comment.parent_id);
          if (parentComment && parentComment.replies) {
            parentComment.replies.push(commentMap.get(comment.id) || comment);
          }
        }
      });

      // Sort replies by timestamp for each parent comment
      rootComments.forEach(comment => {
        if (comment.replies) {
          comment.replies.sort((a, b) => 
            new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
          );
        }
      });

      setAllComments(rootComments);
    }
  }, [task]);

  // Auto-focus reply textarea when replyTo changes
  useEffect(() => {
    if (replyTo && replyTextareaRef.current) {
      replyTextareaRef.current.focus();
    }
  }, [replyTo]);

  const handleSubmit = async () => {
    if (!newComment.trim() || !task) return;

    setLoading(true);
    
    // Create optimistic comment
    const optimisticComment = {
      id: `temp-${Date.now()}`,
      content: newComment.trim(),
      timestamp: new Date().toISOString(),
      user_id: currentUser?.user_id || ''
    };

    // Optimistically update UI
    setAllComments(prev => [optimisticComment, ...prev]);
    setNewComment('');

    try {
      const response = await axios.post(`/custom-board/tasks/${task.id}/comments`, {
        content: optimisticComment.content
      });
      
      if (response.status === 200) {
        // On success, just notify parent of update but keep our optimistic state
        onUpdate?.(response.data);
        toast.success('Comment added successfully');
      }
    } catch (error) {
      console.error('Error adding comment:', error);
      toast.error('Failed to add comment');
      // Revert optimistic update on error
      setAllComments(prev => prev.filter(c => c.id !== optimisticComment.id));
      setNewComment(optimisticComment.content); // Restore the comment text
    } finally {
      setLoading(false);
    }
  };

  const canDeleteComment = (comment: Comment) => {
    if (!currentUser) return false;
    
    // Check if user created this comment
    if (comment.user_id === currentUser.user_id) return true;
    
    // For parent comments, check if user created all replies
    if (!comment.parent_id && comment.replies) {
      return comment.user_id === currentUser.user_id && 
        comment.replies.every(reply => reply.user_id === currentUser.user_id);
    }
    
    return false;
  };

  const handleDelete = async (commentId: string | number, subtaskId?: number) => {
    if (!task) return;

    // Optimistically remove the comment
    setOptimisticDeletes(prev => new Set([...Array.from(prev), commentId]));
    setDeletingComments(prev => new Set([...Array.from(prev), commentId]));

    // If this is a parent comment, also optimistically remove all replies
    const comment = allComments.find(c => c.id === commentId);
    if (comment && !comment.parent_id && comment.replies) {
      comment.replies.forEach(reply => {
        setOptimisticDeletes(prev => new Set([...Array.from(prev), reply.id]));
      });
    }

    try {
      const endpoint = subtaskId 
        ? `/custom-board/subtasks/${subtaskId}/comments/${commentId}`
        : `/custom-board/tasks/${task.id}/comments/${commentId}`;

      const response = await axios.delete(endpoint);
      if (response.status === 200) {
        toast.success('Comment deleted successfully');
        onUpdate();
      }
    } catch (error) {
      console.error('Error deleting comment:', error);
      toast.error('Failed to delete comment');
      
      // Revert optimistic deletes
      setOptimisticDeletes(prev => {
        const newSet = new Set(Array.from(prev));
        newSet.delete(commentId);
        if (comment && !comment.parent_id && comment.replies) {
          comment.replies.forEach(reply => newSet.delete(reply.id));
        }
        return newSet;
      });
    } finally {
      setDeletingComments(prev => {
        const newSet = new Set(Array.from(prev));
        newSet.delete(commentId);
        return newSet;
      });
    }
  };

  const toggleThread = (commentId: string | number) => {
    setExpandedThreads(prev => {
      const newSet = new Set(prev);
      if (newSet.has(commentId)) {
        newSet.delete(commentId);
      } else {
        newSet.add(commentId);
      }
      return newSet;
    });
  };

  const getCommentUniqueId = (comment: Comment): string => {
    const baseId = comment.id.toString();
    const parentPart = comment.parent_id ? `-reply-${comment.parent_id}` : '';
    const subtaskPart = comment.subtask_id ? `-subtask-${comment.subtask_id}` : '';
    const timestamp = new Date(comment.timestamp).getTime();
    
    return `comment-${baseId}${parentPart}${subtaskPart}-${timestamp}`;
  };

  const handleReplyClick = (comment: Comment) => {
    const uniqueId = getCommentUniqueId(comment);
    
    // If already replying to this comment, cancel it
    if (replyTo === uniqueId) {
      setReplyTo(null);
      setReplyTexts(prev => ({ ...prev, [uniqueId]: '' }));
      return;
    }

    // Expand the thread when starting a reply
    setExpandedThreads(prev => {
      const newSet = new Set(prev);
      newSet.add(comment.id);
      return newSet;
    });

    // Set the reply state using the unique ID
    setReplyTo(uniqueId);
  };

  const handleReply = async () => {
    if (!task || !replyTo) return;
    
    const replyText = replyTexts[replyTo];
    if (!replyText?.trim()) return;

    setLoading(true);

    // Find the parent comment
    const parentComment = allComments.find(c => getCommentUniqueId(c) === replyTo);
    if (!parentComment) return;

    // Create optimistic reply
    const optimisticReply = {
      id: `temp-${Date.now()}`,
      content: replyText.trim(),
      timestamp: new Date().toISOString(),
      user_id: currentUser?.user_id || '',
      parent_id: parentComment.id,
      subtask_id: parentComment.subtask_id
    };

    // Optimistically update UI - add new reply at the end of replies array
    setAllComments(prev => prev.map(comment => {
      if (getCommentUniqueId(comment) === replyTo) {
        return {
          ...comment,
          replies: [...(comment.replies || []), optimisticReply]
        };
      }
      return comment;
    }));

    // Clear reply text and state
    setReplyTexts(prev => ({ ...prev, [replyTo]: '' }));
    setReplyTo(null);

    try {
      const endpoint = parentComment.subtask_id
        ? `/custom-board/subtasks/${parentComment.subtask_id}/comments`
        : `/custom-board/tasks/${task.id}/comments`;

      const response = await axios.post(endpoint, {
        content: replyText.trim(),
        parent_id: parentComment.id
      });

      if (response.status === 200) {
        // On success, just notify parent of update but keep our optimistic state
        onUpdate?.(response.data);
        toast.success('Reply added successfully');
      }
    } catch (error) {
      console.error('Error adding reply:', error);
      toast.error('Failed to add reply');
      // Revert optimistic update on error
      setAllComments(prev => prev.map(comment => {
        if (getCommentUniqueId(comment) === replyTo) {
          return {
            ...comment,
            replies: (comment.replies || []).filter(r => r.id !== optimisticReply.id)
          };
        }
        return comment;
      }));
      // Restore reply text
      setReplyTexts(prev => ({ ...prev, [replyTo]: replyText }));
      setReplyTo(replyTo);
    } finally {
      setLoading(false);
    }
  };

  // Filter out optimistically deleted comments
  const filteredComments = allComments.filter(comment => !optimisticDeletes.has(comment.id));

  const renderComment = (comment: Comment, isReply = false) => {
    const user = users.find(u => u.user_id === comment.user_id) || {
      user_id: comment.user_id,
      name: '',
      surname: '',
      email: ''
    };

    const replyCount = comment.replies?.length ?? 0;
    const hasReplies = replyCount > 0;
    const isExpanded = expandedThreads.has(comment.id);
    const uniqueId = getCommentUniqueId(comment);
    const isReplying = replyTo === uniqueId;

    // Don't render if optimistically deleted
    if (optimisticDeletes.has(comment.id)) return null;

    return (
      <div 
        key={uniqueId}
        className={cn(
          "relative",
          isReply ? "ml-8 mt-4" : "mt-5 first:mt-0"
        )}
      >
        <div className={cn(
          "flex gap-4",
          isReply ? "group/reply" : "group/parent"
        )}>
          <div className="flex-shrink-0">
            <UserAvatar user={user} size={32} />
          </div>
          <div className="flex flex-col gap-1 flex-1">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <p className="text-sm font-medium">
                  {user ? `${user.name} ${user.surname}` : `User ${comment.user_id}`}
                </p>
                {comment.subtask_id && (
                  <span className="text-sm text-muted-foreground">
                    in subtask "{comment.subtask_title}"
                  </span>
                )}
              </div>
              <div className="flex gap-2">
                {!isReply && (
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => handleReplyClick(comment)}
                    className="opacity-0 group-hover/parent:opacity-100 transition-opacity"
                  >
                    <Reply className="h-4 w-4" />
                  </Button>
                )}
                {canDeleteComment(comment) && (
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => handleDelete(comment.id, comment.subtask_id)}
                    disabled={deletingComments.has(comment.id)}
                    className={cn(
                      "opacity-0 transition-opacity",
                      isReply ? "group-hover/reply:opacity-100" : "group-hover/parent:opacity-100"
                    )}
                  >
                    {deletingComments.has(comment.id) ? (
                      <Loader2 className="h-4 w-4 animate-spin" />
                    ) : (
                      <Trash2 className="h-4 w-4" />
                    )}
                  </Button>
                )}
              </div>
            </div>
            <p className="text-sm text-foreground whitespace-pre-wrap break-words">
              {comment.content}
            </p>
            <time className="text-xs text-muted-foreground">
              {dayjs(comment.timestamp).format('YYYY-MM-DD HH:mm')}
            </time>

            {!isReply && hasReplies && (
              <div className="flex gap-2 mt-2">
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => toggleThread(comment.id)}
                  className="h-auto py-1 px-2 text-xs hover:bg-muted"
                >
                  {isExpanded ? (
                    <>
                      <ChevronUp className="h-3 w-3 mr-1" />
                      Hide {replyCount} {replyCount === 1 ? 'reply' : 'replies'}
                    </>
                  ) : (
                    <>
                      <ChevronDown className="h-3 w-3 mr-1" />
                      Show {replyCount} {replyCount === 1 ? 'reply' : 'replies'}
                    </>
                  )}
                </Button>
              </div>
            )}
          </div>
        </div>

        {!isReply && (
          <>
            {/* Always render replies if they exist, but control visibility with isExpanded */}
            {hasReplies && (
              <div className={cn("transition-all", isExpanded || isReplying ? "block" : "hidden")}>
                {comment.replies?.map(reply => renderComment(reply, true))}
              </div>
            )}

            {/* Only show reply textarea after existing replies */}
            {isReplying && (
              <div className="mt-2 ml-8">
                <Textarea
                  ref={replyTextareaRef}
                  placeholder="Write a reply..."
                  value={replyTexts[uniqueId] || ''}
                  onChange={(e) => setReplyTexts(prev => ({ ...prev, [uniqueId]: e.target.value }))}
                  className="resize-none"
                  rows={2}
                />
                <div className="flex justify-end gap-2 mt-2">
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => {
                      setReplyTo(null);
                      setReplyTexts(prev => ({ ...prev, [uniqueId]: '' }));
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="sm"
                    onClick={handleReply}
                    disabled={!replyTexts[uniqueId]?.trim() || loading}
                  >
                    <Send className="h-3 w-3 mr-1" />
                    Reply
                  </Button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  if (!task) {
    return (
      <div className="flex justify-center items-center min-h-[100px] rounded-lg p-5">
        <p className="text-sm text-muted-foreground">Task not found</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-5 p-5">
      <div className="flex flex-col gap-2">
        <Textarea
          placeholder="Write a comment..."
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          className="min-h-[80px] resize-none"
        />
        <div className="flex justify-end">
          <Button
            onClick={handleSubmit}
            disabled={!newComment.trim() || loading}
            className="w-24"
          >
            {loading ? (
              <div className="flex items-center gap-2">
                <Loader2 className="h-4 w-4 animate-spin" />
                <span>Posting</span>
              </div>
            ) : (
              <div className="flex items-center gap-2">
                <Send className="h-4 w-4" />
                <span>Post</span>
              </div>
            )}
          </Button>
        </div>
      </div>

      <ScrollArea className="flex-1">
        {!filteredComments.length ? (
          <div className="flex justify-center items-center min-h-[100px] rounded-lg">
            <p className="text-sm text-muted-foreground">No comments yet</p>
          </div>
        ) : (
          <div className="space-y-5">
            {filteredComments.map(comment => renderComment(comment))}
          </div>
        )}
      </ScrollArea>
    </div>
  );
};

export default TaskViewNotes; 