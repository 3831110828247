import { useEffect, useState, ReactNode } from 'react'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { UserAvatar } from '@/components/user-avatar'
import { TrendingUp, Users, Building2, Package, Clock, ChevronLeft, ChevronRight } from 'lucide-react'
import axios from '@/api/axiosConfig'
import { BarChartHorizontalMix } from '@/components/ui/charts/bar-horizontal-mix'
import { PieDonutChart } from '@/components/ui/charts/pie-donut'
import { FilteredBarChart, DataPoint } from "@/components/ui/charts/filtered-bar-chart"
import { StatCard } from "@/components/ui/dashboard/stat-card"
import { ScrollArea } from "@/components/ui/scroll-area"
import { formatDate } from "@/lib/utils"
import { useUsers } from '@/contexts/UserContext'
import { StoredUser } from "@/types/user"
import { Switch } from "@/components/ui/switch"
import { Label } from "@/components/ui/label"
import { Button } from "@/components/ui/button"
import { toast } from "sonner"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { Skeleton } from "@/components/ui/skeleton"
import { Treemap } from "@/components/ui/charts/treemap"

interface ProductSale {
  name: string
  total_sales: number
  product_code: string
}

interface CustomerSale {
  name: string
  total_sales: number
  customer_type: string
  customer_id: number
}

interface MonthlySaleData {
  mrr_paid: number;
  mrr_unpaid: number;
  onboarding_paid: number;
  onboarding_unpaid: number;
  other_paid: number;
  other_unpaid: number;
}

interface TransformedMonthlySaleData extends DataPoint {
  month: string;
  mrr: { paid: number; unpaid: number };
  onboarding: { paid: number; unpaid: number };
  other: { paid: number; unpaid: number };
  total: { paid: number; unpaid: number };
  [key: string]: string | number | { paid: number; unpaid: number };
}

interface RecentSale {
  id: number
  deal_id: number
  deal_name: string
  deal_value: number
  sales_person_id: number
  meeting_booker_id: number | null
  onboarder_id: number | null
  created_at: string
}

interface SalesData {
  monthly_sales: Record<string, MonthlySaleData>
  quarterly_sales: Record<string, number>
  yearly_sales: Record<string, number>
  product_sales: ProductSale[]
  top_customers: CustomerSale[]
  company_type_sales: Record<string, number>
  industry_code_sales: Record<string, { total: number; customer_count: number }>
  current_month_total: number
  current_year_total: number
  month_trend: number
  year_trend: number
  month_period_trend: number
  year_period_trend: number
}

interface ApiResponse {
  with_vat: SalesData
  without_vat: SalesData
  active_customers_count: number
  avg_time_to_revenue: number
  recent_sales: RecentSale[]
}

interface PeriodFilter {
  type: 'all' | 'year' | 'half' | 'quarter' | 'month'
  current: boolean
  value: string
}

// Add this helper function at the top level
const formatTrend = (value: number) => {
  const prefix = value > 0 ? '+' : '';
  return `${prefix}${value.toFixed(1)}%`;
};

function SalesDashSkeleton() {
  return (
    <div className="space-y-6">
      {/* Stat Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {[1, 2, 3, 4].map((i) => (
          <Card key={i}>
            <CardHeader className="flex flex-row items-center justify-between pb-2">
              <Skeleton className="h-5 w-[120px]" />
              <Skeleton className="h-4 w-4" />
            </CardHeader>
            <CardContent>
              <Skeleton className="h-7 w-[140px] mb-2" />
              <Skeleton className="h-4 w-[100px]" />
            </CardContent>
          </Card>
        ))}
      </div>

      {/* Monthly Sales Trend */}
      <Card>
        <CardHeader>
          <div className="flex items-center justify-between">
            <Skeleton className="h-5 w-[200px]" />
            <Skeleton className="h-5 w-[120px]" />
          </div>
        </CardHeader>
        <CardContent>
          <div className="space-y-6">
            <div className="flex justify-between">
              <div className="flex gap-2">
                {[1, 2, 3, 4, 5].map((i) => (
                  <Skeleton key={i} className="h-8 w-20" />
                ))}
              </div>
              <div className="flex gap-2">
                <Skeleton className="h-8 w-[180px]" />
                <Skeleton className="h-8 w-[100px]" />
              </div>
            </div>
            <Skeleton className="h-[350px] w-full" />
          </div>
        </CardContent>
      </Card>

      {/* Three Charts Row */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {[1, 2, 3].map((i) => (
          <Card key={i}>
            <CardHeader>
              <Skeleton className="h-5 w-[150px]" />
            </CardHeader>
            <CardContent>
              <Skeleton className="h-[200px] w-full" />
            </CardContent>
          </Card>
        ))}
      </div>

      {/* Recent Sales */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <Card>
          <CardHeader>
            <Skeleton className="h-5 w-[120px]" />
          </CardHeader>
          <CardContent>
            <div className="space-y-4">
              {[1, 2, 3, 4, 5].map((i) => (
                <Skeleton key={i} className="h-[72px] w-full" />
              ))}
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  )
}

export default function SalesDash() {
  const { users } = useUsers()
  const [salesData, setSalesData] = useState<ApiResponse | null>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [includeVat, setIncludeVat] = useState(false)
  const [periodFilter, setPeriodFilter] = useState<PeriodFilter>({ 
    type: 'year', 
    current: true,
    value: new Date().getFullYear().toString()
  })
  const [selectedFilters, setSelectedFilters] = useState<string[]>(['all'])

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        setError(null)
        const response = await axios.get('/sales-dash/analytics')
        setSalesData(response.data)
      } catch (error) {
        console.error('Error fetching sales data:', error)
        setError('Failed to load sales dashboard data')
        toast.error('Failed to load sales dashboard data')
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [])

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      // Only handle if not typing in an input
      if (e.target instanceof HTMLInputElement || e.target instanceof HTMLTextAreaElement) {
        return
      }

      switch (e.key) {
        case 'ArrowLeft':
          handlePeriodNavigation('prev')
          break
        case 'ArrowRight':
          if (canNavigateNext(periodFilter)) {
            handlePeriodNavigation('next')
          }
          break
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [periodFilter]) // Include periodFilter in dependencies

  const fixSimulatedAmounts = async () => {
    try {
      const response = await axios.post('/sales-dash/fix-simulated-amounts')
      toast.success(`Fixed ${response.data.fixed_count} invoice lines`)
      // Refresh data after fix
      const analyticsResponse = await axios.get('/sales-dash/analytics')
      setSalesData(analyticsResponse.data)
    } catch (error) {
      console.error('Error fixing simulated amounts:', error)
      toast.error('Failed to fix simulated amounts')
    }
  }

  // Add a helper function to safely process industry code data
  const processIndustryCodeData = (data: Record<string, { total: number; customer_count: number }>) => {
    // Filter out any null/undefined keys and ensure values are numbers
    return Object.entries(data)
      .filter(([code]) => code && code !== 'Unknown' && code !== 'undefined')
      .map(([code, data], index) => ({
        name: code,
        value: typeof data.total === 'number' ? data.total : 0,
        customerCount: data.customer_count,
        fill: `hsl(var(--chart-multi-${(index % 5) + 1}))`
      }))
      .filter(item => item.value > 0) // Only include items with positive values
  }

  if (loading) return <SalesDashSkeleton />
  if (error) {
    return (
      <div className="p-8">
        <div className="flex flex-col items-center justify-center space-y-4">
          <p className="text-destructive">{error}</p>
          <Button onClick={() => window.location.reload()}>
            Retry
          </Button>
        </div>
      </div>
    )
  }
  if (!salesData) return null

  // Get the current data based on VAT toggle
  const currentData = includeVat ? salesData.with_vat : salesData.without_vat
  const { active_customers_count, avg_time_to_revenue, recent_sales } = salesData

  // Transform data for charts
  const monthlyTrendData: TransformedMonthlySaleData[] = Object.entries(currentData.monthly_sales).map(([month, data]) => {
    const total = (data.mrr_paid || 0) + (data.mrr_unpaid || 0) +
                 (data.onboarding_paid || 0) + (data.onboarding_unpaid || 0) +
                 (data.other_paid || 0) + (data.other_unpaid || 0)
    
    return {
      month,
      total: {
        paid: (data.mrr_paid || 0) + (data.onboarding_paid || 0) + (data.other_paid || 0),
        unpaid: (data.mrr_unpaid || 0) + (data.onboarding_unpaid || 0) + (data.other_unpaid || 0)
      },
      mrr: {
        paid: data.mrr_paid || 0,
        unpaid: data.mrr_unpaid || 0
      },
      onboarding: {
        paid: data.onboarding_paid || 0,
        unpaid: data.onboarding_unpaid || 0
      },
      other: {
        paid: data.other_paid || 0,
        unpaid: data.other_unpaid || 0
      }
    } as TransformedMonthlySaleData
  })

  const productDistributionData = currentData.product_sales.map((product, index) => ({
    name: product.name,
    value: product.total_sales,
    code: product.product_code,
    fill: `var(--chart-${(index % 12) + 1})`
  }))

  const customerData = currentData.top_customers.map((customer, index) => ({
    name: customer.name,
    value: customer.total_sales,
    type: customer.customer_type,
    fill: `var(--chart-${(index % 12) + 1})`
  }))

  const companyTypeData = Object.entries(currentData.company_type_sales).map(([type, amount]) => ({
    name: type,
    value: amount
  }))

  const salesFilters = [
    { id: 'total', label: 'Total', color: `hsl(var(--chart-multi-2))` },
    { id: 'mrr', label: 'MRR', color: `hsl(var(--chart-multi-3))` },
    { id: 'onboarding', label: 'Onboarding', color: `hsl(var(--chart-multi-4))` },
    { id: 'other', label: 'Other', color: `hsl(var(--chart-multi-5))` }
  ]

  // Add calculation for monthly total
  const currentMonthTotal = Object.values(currentData.monthly_sales).reduce((total, data) => {
    return total + (data.mrr_paid || 0) + (data.mrr_unpaid || 0) +
      (data.onboarding_paid || 0) + (data.onboarding_unpaid || 0) +
      (data.other_paid || 0) + (data.other_unpaid || 0)
  }, 0)
  
  const importHistoricalData = async () => {
    try {
      const response = await axios.post('/invoice/import-historical')
      toast.success(`Import completed: ${response.data.imported} imported, ${response.data.skipped} skipped`)
      // Refresh data after import
      const analyticsResponse = await axios.get('/sales-dash/analytics')
      setSalesData(analyticsResponse.data)
    } catch (error) {
      console.error('Error importing historical data:', error)
      toast.error('Failed to import historical data')
    }
  }

  const handleFilterClick = (filterId: string) => {
    if (filterId === 'all') {
      setSelectedFilters(['all'])
    } else if (filterId === 'total') {
      // If total is clicked, deselect everything else
      setSelectedFilters(['total'])
    } else {
      // If any other filter is clicked
      const newFilters = selectedFilters.filter(f => f !== 'all' && f !== 'total')
      if (newFilters.includes(filterId)) {
        newFilters.splice(newFilters.indexOf(filterId), 1)
      } else {
        newFilters.push(filterId)
      }
      setSelectedFilters(newFilters.length ? newFilters : ['all'])
    }
  }

  const getFilteredData = (data: TransformedMonthlySaleData[], filter: PeriodFilter) => {
    // First apply period filter
    const filteredByPeriod = data.filter(item => {
      const [year, month] = item.month.split('-').map(Number)
      
      switch (filter.type) {
        case 'all':
          return true
        case 'year':
          return year.toString() === filter.value
        case 'half':
          const [filterYear, half] = filter.value.split('-H')
          const monthInHalf = half === '1' ? month <= 6 : month > 6
          return year.toString() === filterYear && monthInHalf
        case 'quarter':
          const [qYear, quarter] = filter.value.split('-Q')
          const quarterMonth = Math.ceil(month / 3)
          return year.toString() === qYear && quarterMonth.toString() === quarter
        case 'month':
          return item.month === filter.value
        default:
          return true
      }
    })

    // Then apply sales type filter and transform data
    return filteredByPeriod.map(item => {
      const result: TransformedMonthlySaleData = {
        month: item.month
      } as TransformedMonthlySaleData

      if (selectedFilters.includes('total')) {
        // Calculate total of all categories
        result.total = {
          paid: (item.mrr?.paid || 0) + (item.onboarding?.paid || 0) + (item.other?.paid || 0),
          unpaid: (item.mrr?.unpaid || 0) + (item.onboarding?.unpaid || 0) + (item.other?.unpaid || 0)
        }
        return result
      }

      if (selectedFilters.includes('all')) {
        // Include all categories except total
        result.mrr = item.mrr
        result.onboarding = item.onboarding
        result.other = item.other
        return result
      }

      // Only include selected categories
      selectedFilters.forEach(filter => {
        if (item[filter]) {
          result[filter] = item[filter]
        }
      })

      return result
    })
  }

  const canNavigateNext = (filter: PeriodFilter) => {
    const now = new Date()
    const currentYear = now.getFullYear()
    const currentMonth = now.getMonth() + 1
    const currentQuarter = Math.ceil(currentMonth / 3)
    const currentHalf = currentMonth <= 6 ? 1 : 2

    switch (filter.type) {
      case 'year':
        return parseInt(filter.value) < currentYear
      case 'half':
        const [year, half] = filter.value.split('-H').map(Number)
        return year < currentYear || (year === currentYear && half < currentHalf)
      case 'quarter':
        const [qYear, quarter] = filter.value.split('-Q').map(Number)
        return qYear < currentYear || (qYear === currentYear && quarter < currentQuarter)
      case 'month':
        const [mYear, month] = filter.value.split('-').map(Number)
        return mYear < currentYear || (mYear === currentYear && month < currentMonth)
      default:
        return false
    }
  }

  const handlePeriodNavigation = (direction: 'prev' | 'next') => {
    const [yearStr, extra] = periodFilter.value.split('-')
    const year = parseInt(yearStr)
    
    switch (periodFilter.type) {
      case 'year':
        setPeriodFilter({
          ...periodFilter,
          value: (year + (direction === 'next' ? 1 : -1)).toString()
        })
        break
        
      case 'half':
        // Half year is marked as H1 or H2
        const halfNum = parseInt(extra.substring(1))
        if (direction === 'next') {
          if (halfNum === 2) {
            setPeriodFilter({
              ...periodFilter,
              value: `${year + 1}-H1`
            })
          } else {
            setPeriodFilter({
              ...periodFilter,
              value: `${year}-H2`
            })
          }
        } else {
          if (halfNum === 1) {
            setPeriodFilter({
              ...periodFilter,
              value: `${year - 1}-H2`
            })
          } else {
            setPeriodFilter({
              ...periodFilter,
              value: `${year}-H1`
            })
          }
        }
        break
        
      case 'quarter':
        // Quarter is marked as Q1, Q2, Q3, or Q4
        const quarterNum = parseInt(extra.substring(1))
        if (direction === 'next') {
          if (quarterNum === 4) {
            setPeriodFilter({
              ...periodFilter,
              value: `${year + 1}-Q1`
            })
          } else {
            setPeriodFilter({
              ...periodFilter,
              value: `${year}-Q${quarterNum + 1}`
            })
          }
        } else {
          if (quarterNum === 1) {
            setPeriodFilter({
              ...periodFilter,
              value: `${year - 1}-Q4`
            })
          } else {
            setPeriodFilter({
              ...periodFilter,
              value: `${year}-Q${quarterNum - 1}`
            })
          }
        }
        break
        
      case 'month':
        const monthNum = parseInt(extra)
        const date = new Date(year, monthNum - 1)
        if (direction === 'next') {
          date.setMonth(date.getMonth() + 1)
        } else {
          date.setMonth(date.getMonth() - 1)
        }
        setPeriodFilter({
          ...periodFilter,
          value: `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}`
        })
        break
    }
  }

  const handlePeriodTypeChange = (value: PeriodFilter['type']) => {
    const now = new Date()
    let newValue = ''
    
    switch (value) {
      case 'year':
        newValue = now.getFullYear().toString()
        break
      case 'half':
        newValue = `${now.getFullYear()}-H${now.getMonth() < 6 ? '1' : '2'}`
        break
      case 'quarter':
        newValue = `${now.getFullYear()}-Q${Math.ceil((now.getMonth() + 1) / 3)}`
        break
      case 'month':
        newValue = `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}`
        break
      default:
        newValue = 'all'
    }
    
    // Update period filter and check if navigation is possible
    const newFilter = { type: value, current: true, value: newValue }
    setPeriodFilter(newFilter)
  }

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between px-8 pt-8">
        <Button 
          onClick={fixSimulatedAmounts}
          variant="outline"
          size="sm"
        >
          Fix Historical Amounts
        </Button>
      </div>

      {/* Top Stats */}
      <div className="grid grid-cols-[repeat(auto-fit,minmax(250px,1fr))] gap-4 px-8">
        <StatCard
          title="Total Sales This Year"
          value={currentData.current_year_total}
          icon={TrendingUp}
          valueFormatter={(val) => new Intl.NumberFormat('da-DK', { 
            style: 'currency', 
            currency: 'DKK',
            maximumFractionDigits: 0 
          }).format(val)}
          trend={{
            value: currentData.year_trend,
            isUpward: currentData.year_trend > 0,
            label: "vs last year",
            additionalTrends: currentData.year_period_trend !== undefined ? [
              {
                value: currentData.year_period_trend,
                isUpward: currentData.year_period_trend > 0,
                label: "vs this time last year"
              }
            ] : undefined
          }}
        />
        <StatCard
          title="Total Sales This Month"
          value={currentData.current_month_total}
          icon={TrendingUp}
          valueFormatter={(val) => new Intl.NumberFormat('da-DK', { 
            style: 'currency', 
            currency: 'DKK',
            maximumFractionDigits: 0 
          }).format(val)}
          trend={{
            value: currentData.month_trend,
            isUpward: currentData.month_trend > 0,
            label: "vs last month",
            additionalTrends: currentData.month_period_trend !== undefined ? [
              {
                value: currentData.month_period_trend,
                isUpward: currentData.month_period_trend > 0,
                label: "vs this time last year"
              }
            ] : undefined
          }}
        />
        <StatCard
          title="Active Customers"
          value={salesData.active_customers_count}
          icon={Users}
        />
        <StatCard
          title="Avg. Time to Revenue"
          value={salesData.avg_time_to_revenue ?? 45}
          icon={Clock}
          suffix="days"
        />
      </div>

      {/* Add temporary import button */}
      <Button 
        onClick={importHistoricalData}
        variant="outline"
        className="mb-4"
      >
        Import Historical Data
      </Button>

      {/* Charts Grid */}
      <div className="grid grid-cols-1 gap-4 px-8 pb-8">
        {/* Monthly Sales Trend */}
        <Card className="p-0">
          <CardHeader className="p-6">
            <div className="flex items-center justify-between">
              <CardTitle>Revenue (Invoiced)</CardTitle>
              <div className="flex items-center gap-2">
                <Label htmlFor="vat-toggle" className="text-sm text-muted-foreground">
                  {includeVat ? 'Including VAT' : 'Excluding VAT'}
                </Label>
                <Switch
                  id="vat-toggle"
                  checked={includeVat}
                  onCheckedChange={setIncludeVat}
                />
              </div>
            </div>
          </CardHeader>
          <CardContent className="space-y-6 p-6">
            {/* Filters and Period Selection Row */}
            <div className="flex justify-between items-center">
              {/* Filter Buttons */}
              <div className="flex flex-wrap gap-2">
                {[
                  { id: 'all', label: 'All', color: `hsl(var(--chart-multi-1))` },
                  ...salesFilters
                ].map((filter) => {
                  const isSelected = selectedFilters.includes(filter.id)
                  const isAllSelected = selectedFilters.includes('all')
                  const shouldBeFaded = isAllSelected && filter.id !== 'all' && filter.id !== 'total'
                  
                  return (
                    <Button
                      key={filter.id}
                      variant={isSelected ? "default" : shouldBeFaded ? "faded" : "outline"}
                      onClick={() => handleFilterClick(filter.id)}
                      className="h-8"
                      style={{
                        backgroundColor: isSelected ? filter.color : undefined,
                        borderColor: filter.color,
                      }}
                    >
                      {filter.label}
                    </Button>
                  )
                })}
              </div>

              {/* Period Selection */}
              <div className="flex items-center gap-2">
                <Select
                  value={periodFilter.type}
                  onValueChange={handlePeriodTypeChange}
                >
                  <SelectTrigger className="w-[180px]">
                    <SelectValue placeholder="Select period" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="all">All Time</SelectItem>
                    <SelectItem value="year">Year</SelectItem>
                    <SelectItem value="half">Half Year</SelectItem>
                    <SelectItem value="quarter">Quarter</SelectItem>
                    <SelectItem value="month">Month</SelectItem>
                  </SelectContent>
                </Select>

                <div className="flex items-center gap-2">
                  <Button
                    variant="outline"
                    size="icon"
                    onClick={() => handlePeriodNavigation('prev')}
                    title="Previous Period (←)"
                  >
                    <ChevronLeft className="h-4 w-4" />
                  </Button>
                  <span className="min-w-[100px] text-center">
                    {periodFilter.value}
                  </span>
                  <Button
                    variant="outline"
                    size="icon"
                    disabled={!canNavigateNext(periodFilter)}
                    onClick={() => handlePeriodNavigation('next')}
                    title="Next Period (→)"
                  >
                    <ChevronRight className="h-4 w-4" />
                  </Button>
                </div>
              </div>
            </div>

            {/* Chart */}
            <div className="h-[350px] w-full">
              <FilteredBarChart
                data={getFilteredData(monthlyTrendData, periodFilter)}
                filters={salesFilters}
                xAxisKey="month"
                valueFormatter={(value) => `${value.toLocaleString()} kr.`}
                variant="grouped-stacked"
                barGap={0}
                barGroupGap={8}
                showTotalLabels={getFilteredData(monthlyTrendData, periodFilter).length <= 20 ? 'always' : 'hover'}
              />
            </div>
          </CardContent>
        </Card>

        {/* Three charts in a row */}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
          <Card className="min-h-[200px] p-0">
            <BarChartHorizontalMix
              title="Product Distribution"
              data={productDistributionData}
              config={{}}
              description="Sales by product category"
              valueFormatter={(value) => `${value.toLocaleString()} kr.`}
              maxLabelLength={25}
              className="h-full"
              colorMode="multi"
            />
          </Card>

          <Card className="min-h-[200px] p-0">
            <PieDonutChart
              title="Company Types"
              data={companyTypeData}
              config={{
                value: { label: "Sales" }
              }}
              description="Sales distribution by company type"
              centerLabel={{
                value: Object.values(currentData.company_type_sales).reduce((a, b) => a + b, 0),
                label: "Total Sales"
              }}
              colorMode="multi"
            />
          </Card>

          <Card className="min-h-[200px] p-0">
            <BarChartHorizontalMix
              title="Top Customers"
              data={customerData}
              config={{}}
              description="Top 10 customers by sales"
              valueFormatter={(value) => `${value.toLocaleString()} kr.`}
              colorMode="multi"
            />
          </Card>
        </div>

        {/* Industry Code Treemap */}
        <Card className="p-0">
          <Treemap
            title="Sales by Industry"
            description="Distribution of sales across different industry segments"
            data={processIndustryCodeData(currentData.industry_code_sales)}
            valueFormatter={(value) => new Intl.NumberFormat('da-DK', { 
              style: 'currency', 
              currency: 'DKK',
              maximumFractionDigits: 0 
            }).format(value)}
            tooltipFormatter={(name: string, value: number, data: any) => {
              const totalSales = Object.values(currentData.industry_code_sales)
                .reduce((sum, item) => sum + item.total, 0);
              
              return (
                <div className="rounded-lg border bg-background p-2 shadow-md">
                  <p className="font-medium">{name}</p>
                  <p className="text-muted-foreground">
                    {new Intl.NumberFormat('da-DK', { 
                      style: 'currency', 
                      currency: 'DKK',
                      maximumFractionDigits: 0 
                    }).format(value)}
                  </p>
                  <p className="text-xs text-muted-foreground">
                    {((value / totalSales) * 100).toFixed(1)}% of total
                  </p>
                  <p className="text-xs text-muted-foreground">
                    {data.customerCount} customer{data.customerCount !== 1 ? 's' : ''}
                  </p>
                </div>
              );
            }}
            colorMode="multi"
          />
        </Card>

        {/* Recent Sales and Additional Charts */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          {/* Recent Sales */}
          <Card className="p-0">
            <CardHeader className="p-6">
              <CardTitle>Recent Sales</CardTitle>
            </CardHeader>
            <CardContent className="p-6">
              <ScrollArea className="h-[400px] pr-4">
                <div className="space-y-4">
                  {salesData.recent_sales.map((sale: RecentSale) => (
                    <div 
                      key={sale.id} 
                      className="flex items-center justify-between p-2 rounded-lg border border-border hover:bg-muted/50 transition-colors"
                    >
                      <div className="flex items-center gap-4">
                        <div className="flex -space-x-2">
                          <UserAvatar
                            user={users.find(u => u.user_id === sale.sales_person_id) as StoredUser}
                            size={40}
                            className="border-2 border-background"
                            tooltipPlacement="top"
                            tooltipContent={`Sales: ${users.find(u => u.user_id === sale.sales_person_id)?.name || ''} ${users.find(u => u.user_id === sale.sales_person_id)?.surname || ''}`}
                          />
                          {sale.meeting_booker_id && (
                            <UserAvatar
                              user={users.find(u => u.user_id === sale.meeting_booker_id) as StoredUser}
                              size={32}
                              className="border-2 border-background"
                              tooltipPlacement="bottom"
                              tooltipContent={`Meeting Booker: ${users.find(u => u.user_id === sale.meeting_booker_id)?.name || ''} ${users.find(u => u.user_id === sale.meeting_booker_id)?.surname || ''}`}
                            />
                          )}
                          {sale.onboarder_id && (
                            <UserAvatar
                              user={users.find(u => u.user_id === sale.onboarder_id) as StoredUser}
                              size={32}
                              className="border-2 border-background"
                              tooltipPlacement="right"
                              tooltipContent={`Onboarder: ${users.find(u => u.user_id === sale.onboarder_id)?.name || ''} ${users.find(u => u.user_id === sale.onboarder_id)?.surname || ''}`}
                            />
                          )}
                        </div>
                        <div>
                          <div className="font-medium text-foreground">{sale.deal_name}</div>
                          <div className="text-sm text-muted-foreground">
                            {formatDate(sale.created_at)}
                          </div>
                        </div>
                      </div>
                      <div className="font-medium text-foreground">
                        {new Intl.NumberFormat('da-DK', { 
                          style: 'currency', 
                          currency: 'DKK',
                          maximumFractionDigits: 0 
                        }).format(sale.deal_value || 0)}
                      </div>
                    </div>
                  ))}
                </div>
              </ScrollArea>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  )
}