import React from 'react';
import { Lock, Unlock, RefreshCw, FileText } from 'lucide-react';
import { Badge } from '@/components/ui/badge';
import { AvatarGroup } from '@/components/ui/avatars/avatar-group';
import { StoredUser } from '@/types/user';
import { Board } from '@/types/custom-board';
import { cn } from '@/lib/utils';
import { useUsers } from '@/contexts/UserContext';
import { Skeleton } from '@/components/ui/skeleton';

interface BoardInfoProps {
  isCustomBoard?: boolean;
  board?: Board | null;
  taskType: string;
  className?: string;
}

export function BoardInfo({ isCustomBoard, board, taskType, className }: BoardInfoProps) {
  const { users } = useUsers();

  // For non-custom boards, determine if it's recurring based on taskType
  const isRecurring = isCustomBoard 
    ? false // Custom boards are always non-recurring for now
    : ['bogforing', 'lon', 'moms', 'arsafslutning'].includes(taskType);

  // For non-custom boards, always public. For custom boards, check board.is_public
  const isPublic = !isCustomBoard || (board?.is_public ?? true);

  // Get users with access for private custom boards
  const usersWithAccess = React.useMemo(() => {
    if (!board || !isCustomBoard || board.is_public) return [];

    // Get all users from access permissions
    const accessUserIds = new Set<number>();
    
    // Add users from access_permissions if it exists
    if (Array.isArray(board.access_permissions)) {
      board.access_permissions.forEach(permission => {
        if (typeof permission === 'object' && permission.user_id) {
          accessUserIds.add(permission.user_id);
        }
      });
    }

    // Add board creator if not already included
    if (board.created_by && !accessUserIds.has(board.created_by)) {
      accessUserIds.add(board.created_by);
    }

    // Map user IDs to actual user objects
    const usersWithAccess = Array.from(accessUserIds)
      .map(userId => users.find(u => u.user_id === userId))
      .filter((user): user is StoredUser => user !== undefined);

    return usersWithAccess;
  }, [isCustomBoard, board, users]);

  return (
    <div className={cn("flex items-center gap-3", className)}>
      <Badge 
        variant="secondary" 
        className="flex items-center gap-1.5"
      >
        {isPublic ? (
          <>
            <Unlock className="h-3 w-3" />
            Public
          </>
        ) : (
          <>
            <Lock className="h-3 w-3" />
            Private
          </>
        )}
      </Badge>

      <Badge 
        variant="secondary"
        className="flex items-center gap-1.5"
      >
        {isRecurring ? (
          <>
            <RefreshCw className="h-3 w-3" />
            Recurring Tasks
          </>
        ) : (
          <>
            <FileText className="h-3 w-3" />
            Non-recurring Tasks
          </>
        )}
      </Badge>

      {/* Show users with access for private custom boards */}
      {isCustomBoard && !board?.is_public && usersWithAccess.length > 0 && (
        <div className="flex items-center gap-2">
          <AvatarGroup 
            users={usersWithAccess}
            max={4} 
          />
        </div>
      )}
    </div>
  );
}

export const BoardInfoSkeleton = () => {
  return (
    <div className="flex items-center gap-3">
      {/* Public/Private badge skeleton */}
      <Skeleton className="h-6 w-24 rounded-full" />
      
      {/* Task type badge skeleton */}
      <Skeleton className="h-6 w-32 rounded-full" />
      
      {/* Avatar group skeleton */}
      <div className="flex -space-x-2">
        <Skeleton className="h-8 w-8 rounded-full" />
        <Skeleton className="h-8 w-8 rounded-full" />
        <Skeleton className="h-8 w-8 rounded-full" />
      </div>
    </div>
  );
};