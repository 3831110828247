import { useState } from 'react'
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { toast } from "sonner"
import axios from '@/api/axiosConfig'
import { LoadingSpinner } from "@/components/ui/loading-spinner"

interface CvrData {
  name?: string
  address?: string
  zipcode?: string
  city?: string
  phone?: string
  email?: string
  startdate?: string
  employees?: string
  companydesc?: string
  industrydesc?: string
  industrycode?: string
  addressco?: string
  cityname?: string
  enddate?: string
  fax?: string
  main?: boolean
  pno?: string
  protected?: boolean
  [key: string]: string | boolean | undefined  // Allow any other string fields
}

export default function AdminCVR() {
  const [cvr, setCvr] = useState('')
  const [loading, setLoading] = useState(false)
  const [migrating, setMigrating] = useState(false)
  const [cvrData, setCvrData] = useState<CvrData | null>(null)
  const [updatingIndustryCodes, setUpdatingIndustryCodes] = useState(false)

  const handleLookup = async () => {
    if (!cvr || cvr.length !== 8) {
      toast.error('Please enter a valid 8-digit CVR number')
      return
    }

    setLoading(true)
    try {
      const response = await axios.get(`/admin/cvr-lookup/${cvr}`)
      setCvrData(response.data)
    } catch (error: any) {
      const errorMessage = error.response?.data?.error || 'Failed to fetch CVR data'
      toast.error(errorMessage)
      console.error('CVR lookup error:', error)
      setCvrData(null)
    } finally {
      setLoading(false)
    }
  }

  const handleMigration = async () => {
    setMigrating(true)
    try {
      const response = await axios.post('/admin/migrate-cvr-data')
      toast.success(`Migration completed: ${response.data.updated} deals updated`)
    } catch (error: any) {
      const errorMessage = error.response?.data?.error || 'Migration failed'
      toast.error(errorMessage)
      console.error('Migration error:', error)
    } finally {
      setMigrating(false)
    }
  }

  const handleUpdateIndustryCodes = async () => {
    setUpdatingIndustryCodes(true)
    try {
      const response = await axios.post('/admin/update-industry-codes')
      const message = response.data.stopped_early
        ? `Industry codes update stopped early due to ${response.data.consecutive_misses} consecutive misses. ${response.data.updated} deals updated.`
        : `Industry codes updated: ${response.data.updated} deals updated`
      toast.success(message)
    } catch (error: any) {
      const errorMessage = error.response?.data?.error || 'Failed to update industry codes'
      toast.error(errorMessage)
      console.error('Industry code update error:', error)
    } finally {
      setUpdatingIndustryCodes(false)
    }
  }

  return (
    <div className="space-y-6">
      <Card>
        <CardHeader>
          <CardTitle className="flex justify-between items-center">
            <span>CVR Lookup</span>
            <div className="flex gap-2">
              <Button 
                onClick={handleMigration} 
                disabled={migrating}
                variant="outline"
              >
                {migrating ? <LoadingSpinner size="sm" /> : 'Migrate CVR Data'}
              </Button>
              <Button
                onClick={handleUpdateIndustryCodes}
                disabled={updatingIndustryCodes}
                variant="outline"
              >
                {updatingIndustryCodes ? <LoadingSpinner size="sm" /> : 'Update Industry Codes'}
              </Button>
            </div>
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex gap-4">
            <Input
              placeholder="Enter CVR number..."
              value={cvr}
              onChange={(e) => setCvr(e.target.value)}
              maxLength={8}
              className="max-w-[200px]"
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !loading) {
                  handleLookup()
                }
              }}
            />
            <Button onClick={handleLookup} disabled={loading}>
              {loading ? <LoadingSpinner size="sm" /> : 'Lookup'}
            </Button>
          </div>
        </CardContent>
      </Card>

      {cvrData && (
        <Card>
          <CardHeader>
            <CardTitle>Company Information</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="grid grid-cols-2 gap-4">
              {Object.entries(cvrData).map(([key, value]) => {
                // Skip rendering if value is undefined or null
                if (value === undefined || value === null) return null;
                
                // Format boolean values
                const displayValue = typeof value === 'boolean' 
                  ? value.toString() 
                  : value;
                
                return (
                  <div key={key} className="space-y-1">
                    <div className="text-sm font-medium text-muted-foreground capitalize">
                      {key.replace(/([A-Z])/g, ' $1').toLowerCase()}
                    </div>
                    <div className="text-sm">
                      {displayValue}
                    </div>
                  </div>
                );
              })}
            </div>
          </CardContent>
        </Card>
      )}
    </div>
  )
}
