import React, { useState } from 'react';
import { Table } from '@/components/ui/specialized/mod-table';
import { cn } from '@/lib/utils';
import type { CustomTask } from '@/types/custom-task';
import type { Board, BoardStatusOption } from '@/types/custom-board';
import { Loading } from '@/components/ui/loading';
import { AvatarGroup } from "@/components/ui/avatars/avatar-group";
import { StoredUser } from "@/types/user";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { TaskDateEditor } from '@/components/TaskDateEditor';
import { TaskStatusSelector } from '@/taskboards/components/TaskStatusSelector';
import { TimeTrackerCell } from '@/components/TimeTrackerCell';
import { UserAvatar } from '@/components/user-avatar';
import { AssigneeSelection } from '@/modals/AssigneeSelection';
import { toast } from 'sonner';
import axios from '@/api/axiosConfig';
import { useUsers } from '@/contexts/UserContext';
import { SubTask } from '@/types/custom-board';
import { CheckSquare, Briefcase, ArrowUpRight, Check, MessageSquare, Clock } from 'lucide-react';
import { DealDrawer } from '@/drawers/DealDrawer';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import type { ColumnDef } from '@/components/ui/specialized/mod-table';
import { Skeleton } from '@/components/ui/skeleton';

dayjs.extend(relativeTime);

interface TaskTableProps {
  dataSource: CustomTask[];
  columns: ColumnDef<CustomTask>[];
  handleOpenTask: (task: CustomTask) => void;
  loading: boolean;
  isBoardConfigLoading: boolean;
  onAssigneeUpdate: (taskId: number, newAssignees: string[]) => Promise<void>;
  board?: Board | null;
  onSubtaskClick?: (taskId: number, subtaskId: number) => void;
  onDataSourceChange?: (dataSource: CustomTask[]) => void;
  isCollapsed?: boolean;
}

// Define a more specific column type for our use case
interface CustomColumnDef<T> {
  key: string;
  title: string;
  dataIndex: keyof T;
  cell: (props: { row: { original: T } }) => React.ReactNode;
}

const TaskTable: React.FC<TaskTableProps> = ({ 
  dataSource, 
  columns, 
  handleOpenTask, 
  loading,
  isBoardConfigLoading,
  onAssigneeUpdate,
  board,
  onSubtaskClick,
  onDataSourceChange,
  isCollapsed = false
}) => {
  const { users } = useUsers();
  const [isAssigneeModalOpen, setIsAssigneeModalOpen] = useState(false);
  const [selectedTaskForAssignee, setSelectedTaskForAssignee] = useState<CustomTask | null>(null);
  const [selectedDealId, setSelectedDealId] = useState<number | null>(null);
  const [isDealDrawerOpen, setIsDealDrawerOpen] = useState(false);

  const getAssignedUsers = (assignedUserIds: string[]): StoredUser[] => {
    if (!assignedUserIds?.length) return [];
    return assignedUserIds
      .map(userId => users.find(u => u.user_id.toString() === userId.toString()))
      .filter((user): user is StoredUser => user !== undefined);
  };

  const getStatusColor = (statusId: number, board: Board | null | undefined): string => {
    if (!board) return '#1890ff'; // Default color
    const options = board.board_config?.progress_status_options || [];
    return options.find(opt => opt.id === statusId)?.color || '#1890ff';
  };

  const getStatusLabel = (statusId: number, type: 'progress' | 'board'): string => {
    if (!board?.board_config) return String(statusId);

    const options = type === 'progress' 
      ? board.board_config.progress_status_options 
      : board.board_config.board_status_options || [];

    const status = options?.find(opt => opt.id === statusId);
    return status?.label || String(statusId);
  };

  const getSubtaskStatusColor = (subtask: SubTask): string => {
    if (!board?.subtask_config?.predefined_subtasks) return '#1890ff';

    const predefinedSubtask = board.subtask_config.predefined_subtasks.find(
      config => config.name === subtask.title
    );

    const statusOption = predefinedSubtask?.status_options?.find(opt => 
      opt.id === subtask.progress_status_id
    );

    return statusOption?.color || '#1890ff';
  };

  const getSubtaskStatusLabel = (subtask: SubTask): string => {
    if (!board?.subtask_config?.predefined_subtasks) return String(subtask.progress_status_id);

    const predefinedSubtask = board.subtask_config.predefined_subtasks.find(
      config => config.name === subtask.title
    );

    const statusOption = predefinedSubtask?.status_options?.find(opt => 
      opt.id === subtask.progress_status_id
    );

    return statusOption?.label || String(subtask.progress_status_id);
  };

  const getLastActivity = (subtask: SubTask): string => {
    const dates: string[] = [];
    
    if (subtask.comments?.length) {
      dates.push(...subtask.comments.map(c => c.timestamp));
    }
    
    if (subtask.updates?.length) {
      dates.push(...subtask.updates.map(u => u.timestamp));
    }
    
    if (subtask.created_at) {
      dates.push(subtask.created_at);
    }
    
    if (!dates.length) return '';
    
    return dates.sort((a, b) => new Date(b).getTime() - new Date(a).getTime())[0];
  };

  const getRecentActivities = (subtask: SubTask) => {
    const activities: any[] = [];
    
    if (subtask.comments?.length) {
      activities.push(...subtask.comments.map(c => ({
        id: c.id,
        timestamp: c.timestamp,
        type: 'comment',
        content: c.content,
        user_id: c.user_id,
        user: users.find(u => u.user_id === c.user_id)?.name || 'Unknown'
      })));
    }
    
    if (subtask.updates?.length) {
      const updateActivities = subtask.updates
        .map(u => {
          let activityType = null;
          
          switch (u.type) {
            case 'progress_status':
              activityType = 'status';
              break;
            case 'assigned_users':
              activityType = 'assignees';
              break;
            case 'work_date':
              activityType = 'work_date';
              break;
            case 'due_date':
              activityType = 'due_date';
              break;
          }

          if (!activityType) return null;

          return {
            id: u.id,
            timestamp: u.timestamp,
            type: activityType,
            user_id: u.user_id,
            user: users.find(u => u.user_id === u.user_id)?.name || 'Unknown',
            new_value: u.new_value
          };
        })
        .filter(item => item !== null);

      activities.push(...updateActivities);
    }
    
    return activities
      .sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime())
      .slice(0, 5);
  };

  const getRecentComments = (subtask: SubTask) => {
    if (!subtask.comments?.length) return [];
    
    return [...subtask.comments]
      .sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime())
      .slice(0, 3)
      .map(c => ({
        ...c,
        user: users.find(u => u.user_id === c.user_id)?.name || 'Unknown'
      }));
  };

  const handleDealClick = (e: React.MouseEvent, dealId: number) => {
    e.stopPropagation();
    setSelectedDealId(dealId);
    setIsDealDrawerOpen(true);
  };

  const handleStatusUpdate = async (taskId: number, field: 'progress_status_id' | 'board_status_id', newStatusId: number) => {
    // Store original value for rollback
    const originalTask = dataSource.find(t => t.id === taskId);
    const originalValue = originalTask?.[field];

    try {
      // Optimistically update local state
      const updatedDataSource = dataSource.map(task => 
        task.id === taskId 
          ? { ...task, [field]: newStatusId }
          : task
      );
      onDataSourceChange?.(updatedDataSource);

      const response = await axios.put(`/custom-board/tasks/${taskId}`, {
        [field]: newStatusId
      });

      if (response.status === 200) {
        toast.success('Status updated successfully');
      }
    } catch (error) {
      // Revert to original state on error
      const revertedDataSource = dataSource.map(task =>
        task.id === taskId
          ? { ...task, [field]: originalValue }
          : task
      );
      onDataSourceChange?.(revertedDataSource);
      
      console.error('Error updating status:', error);
      toast.error('Failed to update status');
    }
  };

  const handleSubtaskStatusUpdate = async (taskId: number, subtaskId: number, newStatus: string) => {
    // Find the task and subtask
    const task = dataSource.find(t => t.id === taskId);
    const subtask = task?.subtasks?.find(s => s.id === subtaskId);
    const originalStatus = subtask?.progress_status_id;

    try {
      // Optimistically update local state
      const updatedDataSource = dataSource.map(t => {
        if (t.id !== taskId) return t;
        
        return {
          ...t,
          subtasks: t.subtasks?.map(s => 
            s.id === subtaskId
              ? { ...s, progress_status_id: Number(newStatus) }
              : s
          )
        };
      });
      onDataSourceChange?.(updatedDataSource);

      await axios.put(`/custom-board/subtasks/${subtaskId}`, {
        progress_status: newStatus
      });
      
      toast.success('Subtask status updated successfully');
    } catch (error) {
      // Revert to original state on error
      const revertedDataSource = dataSource.map(t => {
        if (t.id !== taskId) return t;
        
        return {
          ...t,
          subtasks: t.subtasks?.map(s => 
            s.id === subtaskId
              ? { ...s, progress_status_id: originalStatus }
              : s
          )
        };
      });
      onDataSourceChange?.(revertedDataSource as CustomTask[]);

      console.error('Error updating subtask status:', error);
      toast.error('Failed to update subtask status');
    }
  };

  const renderCellValue = (value: any, column: ColumnDef<CustomTask>, task: CustomTask): React.ReactNode => {
    if (value === null || value === undefined) {
      return '';
    }

    if (column.dataIndex === 'assigned_users') {
      const assignedUsers = getAssignedUsers(value);
      return (
        <div 
          className="cursor-pointer hover:opacity-80 transition-opacity"
          onClick={(e) => {
            e.stopPropagation();
            setSelectedTaskForAssignee(task);
            setIsAssigneeModalOpen(true);
          }}
        >
          <AvatarGroup users={assignedUsers} max={3} />
        </div>
      );
    }

    // Handle progress status
    if (column.dataIndex === 'progress_status_id') {
      const color = getStatusColor(value, board);
      const label = getStatusLabel(value, 'progress');
      
      return (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <button type="button" onClick={(e) => e.stopPropagation()}>
              <Badge
                variant="custom"
                className="cursor-pointer"
                style={{ backgroundColor: color }}
              >
                {label}
              </Badge>
            </button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="start">
            {board?.board_config?.progress_status_options.map((option) => (
              <DropdownMenuItem
                key={option.id}
                onClick={() => handleStatusUpdate(task.id, 'progress_status_id', option.id)}
                className="flex items-center gap-2"
              >
                <div
                  className="w-2 h-2 rounded-full"
                  style={{ backgroundColor: option.color }}
                />
                <span>{option.label}</span>
                {option.id === task.progress_status_id && (
                  <Check className="w-4 w-4 ml-auto" />
                )}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      );
    }

    // Handle board status
    if (column.dataIndex === 'board_status_id') {
      const color = getStatusColor(value, board);
      const label = getStatusLabel(value, 'board');
      
      return (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <button type="button" onClick={(e) => e.stopPropagation()}>
              <Badge
                variant="custom"
                className="cursor-pointer"
                style={{ backgroundColor: color }}
              >
                {label}
              </Badge>
            </button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="start">
            {board?.board_config?.board_status_options?.map((option) => (
              <DropdownMenuItem
                key={option.id}
                onClick={() => handleStatusUpdate(task.id, 'board_status_id', option.id)}
                className="flex items-center gap-2"
              >
                <div
                  className="w-2 h-2 rounded-full"
                  style={{ backgroundColor: option.color }}
                />
                <span>{option.label}</span>
                {option.id === task.board_status_id && (
                  <Check className="w-4 w-4 ml-auto" />
                )}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      );
    }

    // Handle subtasks column
    if (column.dataIndex === 'subtasks') {
      if (!Array.isArray(value)) return null;
      return (
        <div className="space-y-2">
          {value.map((subtask: SubTask) => {
            const lastActivity = getLastActivity(subtask);
            const commentCount = subtask.comments?.length || 0;
            const recentActivities = getRecentActivities(subtask);
            const recentComments = getRecentComments(subtask);
            
            return (
              <div 
                key={subtask.id}
                className="flex items-center justify-between gap-2 group cursor-pointer hover:bg-muted/50 rounded-md p-1"
                onClick={(e) => {
                  e.stopPropagation();
                  onSubtaskClick?.(task.id, subtask.id);
                }}
              >
                <div className="flex items-center gap-2 min-w-0 group-hover:text-primary">
                  <CheckSquare className="h-4 w-4 flex-shrink-0" />
                  <span className="text-sm truncate">{subtask.title}</span>
                </div>

                <div className="flex items-center gap-3 ml-auto">
                  {lastActivity && (
                    <TooltipProvider>
                      <Tooltip delayDuration={0}>
                        <TooltipTrigger asChild>
                          <div className="flex items-center gap-1 text-xs text-muted-foreground hover:text-muted-foreground">
                            <Clock className="h-3 w-3" />
                            {dayjs(lastActivity).fromNow()}
                          </div>
                        </TooltipTrigger>
                        <TooltipContent side="top" className="max-w-[300px]">
                          <div className="space-y-2">
                            <p className="font-medium">Recent Activity</p>
                            {recentActivities.map((activity, idx) => (
                              <div key={idx} className="text-xs">
                                {activity.type === 'comment' ? (
                                  <span>
                                    <span className="font-medium">{activity.user}:</span>{' '}
                                    {activity.content}
                                  </span>
                                ) : (
                                  <span>
                                    <span className="font-medium">{activity.user}</span>{' '}
                                    {activity.type === 'status' && (
                                      <>
                                        changed status to{' '}
                                        <span className="font-medium">
                                          {board?.subtask_config?.predefined_subtasks
                                            ?.find(ps => ps.name === subtask.title)
                                            ?.status_options
                                            ?.find(so => so.id.toString() === activity.new_value?.toString())
                                            ?.label || activity.new_value}
                                        </span>
                                      </>
                                    )}
                                    {activity.type === 'assignees' && 'updated assignees'}
                                    {activity.type === 'work_date' && 'updated work date'}
                                    {activity.type === 'due_date' && 'updated due date'}
                                  </span>
                                )}
                                <span className="block text-muted-foreground">
                                  {dayjs(activity.timestamp).fromNow()}
                                </span>
                              </div>
                            ))}
                          </div>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  )}
                  
                  {commentCount > 0 && (
                    <TooltipProvider>
                      <Tooltip delayDuration={0}>
                        <TooltipTrigger asChild>
                          <div className="flex items-center gap-1 text-xs hover:text-muted-foreground">
                            <MessageSquare className="h-3 w-3" />
                            {commentCount}
                          </div>
                        </TooltipTrigger>
                        <TooltipContent side="top" className="max-w-[300px]">
                          <div className="space-y-2">
                            <p className="font-medium">Recent Comments</p>
                            {recentComments.map((comment, idx) => (
                              <div key={idx} className="text-xs">
                                <span>
                                  <span className="font-medium">{comment.user}:</span>{' '}
                                  {comment.content}
                                </span>
                                <span className="block text-muted-foreground">
                                  {dayjs(comment.timestamp).fromNow()}
                                </span>
                              </div>
                            ))}
                          </div>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  )}

                  {board?.subtask_config?.enable_assignees && subtask.assigned_users && subtask.assigned_users.length > 0 && (
                    <div className="flex-shrink-0">
                      <AvatarGroup
                        users={getAssignedUsers(subtask.assigned_users)}
                        max={2}
                      />
                    </div>
                  )}

                  <DropdownMenu>
                    <DropdownMenuTrigger asChild onClick={(e) => e.stopPropagation()}>
                      <button type="button" onClick={(e) => e.stopPropagation()}>
                        <Badge
                          variant="custom"
                          className="cursor-pointer"
                          style={{ backgroundColor: getSubtaskStatusColor(subtask) }}
                        >
                          {getSubtaskStatusLabel(subtask)}
                        </Badge>
                      </button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                      {board?.subtask_config?.predefined_subtasks
                        .find(config => config.name === subtask.title)
                        ?.status_options.map((option) => (
                          <DropdownMenuItem
                            key={option.id}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleSubtaskStatusUpdate(task.id, subtask.id, option.id.toString());
                            }}
                            className="flex items-center gap-2"
                          >
                            <div
                              className="w-2 h-2 rounded-full"
                              style={{ backgroundColor: option.color }}
                            />
                            <span>{option.label}</span>
                            {option.id === Number(subtask.progress_status_id) && (
                              <Check className="w-4 w-4 ml-auto" />
                            )}
                          </DropdownMenuItem>
                        ))}
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
              </div>
            );
          })}
        </div>
      );
    }

    // Handle deal name column
    if (column.dataIndex === 'deal_name' && task.deal_id) {
      return (
        <div className="flex items-center gap-2">
          <Badge 
            variant="secondary"
            className="cursor-pointer hover:bg-secondary/80 bg-[hsl(var(--badge-deal))] text-white hover:bg-[hsl(var(--badge-deal)/.8)]"
            onClick={(e) => task.deal_id && handleDealClick(e, task.deal_id)}
          >
            <Briefcase className="w-3 h-3 flex-shrink-0 mr-1" />
            <span className="max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap">
              {value}
            </span>
          </Badge>
          <button
            className="p-1 rounded-md hover:bg-muted transition-colors"
            onClick={(e) => {
              e.stopPropagation();
              handleOpenTask(task);
            }}
          >
            <ArrowUpRight className="h-4 w-4" />
          </button>
        </div>
      );
    }

    if (typeof value === 'object') {
      if ('Task' in value) {
        return (value as any).Task || '';
      }
      if ('name' in value) {
        return value.name;
      }
      return JSON.stringify(value);
    }
    return String(value);
  };

  if (isBoardConfigLoading) {
    return (
      <div className="space-y-4">
        <div className="flex items-center justify-between">
          <Skeleton className="h-10 w-[200px]" />
          <Skeleton className="h-10 w-[100px]" />
        </div>
        {Array.from({ length: 5 }).map((_, index) => (
          <div key={index} className="flex gap-4">
            {columns.map((col, colIndex) => (
              <Skeleton key={colIndex} className="h-16 flex-1" />
            ))}
          </div>
        ))}
      </div>
    );
  }

  if (loading) {
    return (
      <div className="space-y-4">
        {Array.from({ length: 5 }).map((_, index) => (
          <div key={index} className="flex gap-4">
            {columns.map((col, colIndex) => (
              <Skeleton key={colIndex} className="h-16 flex-1" />
            ))}
          </div>
        ))}
      </div>
    );
  }

  // Filter and transform columns based on board config
  const getFilteredColumns = () => {
    if (!board?.board_config) return columns;

    let filteredColumns = [...columns];

    // If simplify_main_tasks is enabled
    if (board.board_config.simplify_main_tasks) {
      // Remove description column
      filteredColumns = filteredColumns.filter(col => col.dataIndex !== 'description');
      
      // Replace title column with deal column if not already present
      const hasDealColumn = filteredColumns.some(col => col.dataIndex === 'deal_name');
      if (!hasDealColumn) {
        const titleIndex = filteredColumns.findIndex(col => col.dataIndex === 'title');
        if (titleIndex !== -1) {
          filteredColumns[titleIndex] = {
            title: 'Deal',
            key: 'deal_name',
            dataIndex: 'deal_name',
            cell: ({ row }: { row: { original: CustomTask } }) => renderCellValue(row.original.deal_name, { dataIndex: 'deal_name' } as ColumnDef<CustomTask>, row.original)
          };
        }
      }
    }

    // Add progress status column if not present
    const hasProgressStatus = filteredColumns.some(col => col.dataIndex === 'progress_status_id');
    if (!hasProgressStatus) {
      filteredColumns.push({
        title: 'Progress Status',
        key: 'progress_status_id',
        dataIndex: 'progress_status_id',
        cell: ({ row }: { row: { original: CustomTask } }) => renderCellValue(row.original.progress_status_id, { dataIndex: 'progress_status_id' } as ColumnDef<CustomTask>, row.original)
      });
    }

    // Add board status column if enabled and not present
    if (board?.board_config?.board_status_options && board.board_config.board_status_options.length > 0) {
      const hasBoardStatus = filteredColumns.some(col => col.dataIndex === 'board_status_id');
      if (!hasBoardStatus) {
        filteredColumns.push({
          title: 'Board Status',
          key: 'board_status_id',
          dataIndex: 'board_status_id',
          cell: ({ row }: { row: { original: CustomTask } }) => renderCellValue(
            row.original.board_status_id, 
            { dataIndex: 'board_status_id' } as ColumnDef<CustomTask>, 
            row.original
          )
        });
      }
    }

    // Add subtasks column if enabled
    if (board.board_config.enable_subtasks) {
      const hasSubtasks = filteredColumns.some(col => col.dataIndex === 'subtasks');
      if (!hasSubtasks) {
        filteredColumns.push({
          title: 'Subtasks',
          key: 'subtasks',
          dataIndex: 'subtasks',
          cell: ({ row }: { row: { original: CustomTask } }) => renderCellValue(row.original.subtasks, { dataIndex: 'subtasks' } as ColumnDef<CustomTask>, row.original)
        });
      }
    }

    return filteredColumns;
  };

  const convertedColumns = getFilteredColumns().map(col => {
    const baseColumn: CustomColumnDef<CustomTask> = {
      key: col.key || String(col.dataIndex) || `col-${Math.random()}`,
      title: col.title,
      dataIndex: col.dataIndex as keyof CustomTask,
      cell: ({ row }: { row: { original: CustomTask } }) => {
        if (!row?.original) return null;

        const isClickable = col.dataIndex === 'deal_name' || (!board?.board_config?.simplify_main_tasks && col.dataIndex === 'description');
        const value = row.original[col.dataIndex as keyof CustomTask];

        return (
          <div
            className={cn(
              isClickable && "cursor-pointer",
              "w-full"
            )}
            onClick={isClickable ? () => handleOpenTask(row.original) : undefined}
          >
            {col.cell ? col.cell({ row }) : renderCellValue(value, baseColumn, row.original)}
          </div>
        );
      }
    };

    return baseColumn;
  });

  return (
    <>
      <div className={cn(
        "h-[calc(100vh-11.5rem)]",
        isCollapsed && "h-[calc(100vh-4.5rem)]"
      )}>
        <Table
          data={dataSource}
          columns={convertedColumns as ColumnDef<any>[]}
          className="w-full"
        />
      </div>

      <AssigneeSelection
        visible={isAssigneeModalOpen}
        onClose={() => {
          setIsAssigneeModalOpen(false);
          setSelectedTaskForAssignee(null);
        }}
        onSave={(newAssignees) => {
          if (selectedTaskForAssignee) {
            onAssigneeUpdate(selectedTaskForAssignee.id, newAssignees);
          }
          setIsAssigneeModalOpen(false);
          setSelectedTaskForAssignee(null);
        }}
        initialAssignees={selectedTaskForAssignee?.assigned_users?.map(id => id.toString()) || []}
        maxAssignees={10}
      />

      <DealDrawer
        visible={isDealDrawerOpen}
        onClose={() => {
          setIsDealDrawerOpen(false);
          setSelectedDealId(null);
        }}
        dealId={selectedDealId}
      />
    </>
  );
};

export default TaskTable;