import { useState, useEffect, useRef } from 'react'
import { Button } from '@/components/ui/button'
import { 
  Dialog, 
  DialogContent, 
  DialogHeader, 
  DialogTitle, 
  DialogDescription,
  DialogBody,
  DialogFooter
} from '@/components/ui/dialog'
import { ScrollArea } from '@/components/ui/scroll-area'
import { Textarea } from '@/components/ui/textarea'
import { Send, Trash2, Loader2, ChevronDown, ChevronUp, Reply } from 'lucide-react'
import axios from '@/api/axiosConfig'
import dayjs from 'dayjs'
import { UserAvatar } from '@/components/user-avatar'
import { StoredUser } from '@/types/user'
import { CustomTask } from '@/types/custom-task'
import { toast } from 'sonner'
import { cn } from '@/lib/utils'
import { useUsers } from '@/contexts/UserContext'

interface TaskNotesProps {
  visible: boolean
  onClose: (refresh?: boolean) => void
  task: CustomTask
  users: StoredUser[]
  onTaskUpdate?: (updatedTask: CustomTask) => void
}

interface Comment {
  id: number
  user_id: number
  content: string
  timestamp: string
  parent_id?: number
}

interface ThreadMap {
  [key: string | number]: Comment[];
}

export function TaskNotes({ visible, onClose, task, users, onTaskUpdate }: TaskNotesProps) {
  const [newComment, setNewComment] = useState('')
  const [loading, setLoading] = useState(false)
  const [replyTo, setReplyTo] = useState<number | null>(null)
  const [expandedThreads, setExpandedThreads] = useState<number[]>([])
  const [localTask, setLocalTask] = useState<CustomTask>(task)
  const replyTextareaRef = useRef<HTMLTextAreaElement>(null)
  const { currentUser } = useUsers()
  const [deletingComments, setDeletingComments] = useState<Set<string | number>>(new Set())
  const [optimisticDeletes, setOptimisticDeletes] = useState<Set<string | number>>(new Set())

  // Update local task when prop changes
  useEffect(() => {
    setLocalTask(task)
  }, [task])

  // Auto-focus reply textarea when replyTo changes
  useEffect(() => {
    if (replyTo && replyTextareaRef.current) {
      replyTextareaRef.current.focus()
    }
  }, [replyTo])

  const handleSubmit = async () => {
    if (!newComment.trim()) return;

    setLoading(true);
    
    // Create optimistic comment
    const optimisticComment = {
      id: `temp-${Date.now()}`,
      content: newComment.trim(),
      timestamp: new Date().toISOString(),
      user_id: currentUser?.user_id || 0,
      parent_id: replyTo
    };

    // Optimistically update UI
    setLocalTask(prev => ({
      ...prev,
      comments: [optimisticComment, ...(prev.comments || [])]
    }));
    setNewComment('');

    try {
      // For TaskNotes, we always use the task endpoint for both comments and replies
      const response = await axios.post(`/custom-board/tasks/${task.id}/comments`, {
        content: optimisticComment.content,
        parent_id: replyTo
      });

      if (response.status === 200) {
        // On success, just notify parent of update but keep our optimistic state
        onTaskUpdate?.(response.data);
        toast.success('Comment added successfully');
        
        // Clear reply state if this was a reply
        if (replyTo) {
          setReplyTo(null);
        }
      }
    } catch (error) {
      console.error('Error adding comment:', error);
      toast.error('Failed to add comment');
      
      // Revert optimistic update on error
      setLocalTask(prev => ({
        ...prev,
        comments: prev.comments?.filter(c => c.id !== optimisticComment.id)
      }));
      setNewComment(optimisticComment.content); // Restore the comment text
    } finally {
      setLoading(false);
    }
  };

  const canDeleteComment = (comment: Comment) => {
    if (!currentUser) return false;
    
    // Check if user created this comment
    if (comment.user_id === currentUser.user_id) return true;
    
    // For parent comments, check if user created all replies
    if (!comment.parent_id && threads[comment.id]) {
      return comment.user_id === currentUser.user_id && 
        threads[comment.id].every(reply => reply.user_id === currentUser.user_id);
    }
    
    return false;
  };

  const handleDelete = async (commentId: number | string) => {
    // Optimistically remove the comment
    setOptimisticDeletes(prev => new Set([...Array.from(prev || []), commentId]));
    setDeletingComments(prev => new Set([...Array.from(prev || []), commentId]));

    // If this is a parent comment, also optimistically remove all replies
    const threads = groupComments(localTask?.comments).threads as ThreadMap;
    if (threads[commentId]) {
      threads[commentId].forEach((reply: Comment) => {
        setOptimisticDeletes(prev => new Set([...Array.from(prev || []), reply.id]));
      });
    }

    try {
      // If it's a temporary comment (optimistic), just remove it locally
      if (typeof commentId === 'string' && commentId.startsWith('temp-')) {
        setLocalTask(prev => ({
          ...prev,
          comments: prev.comments?.filter(c => c.id !== commentId)
        }));
        toast.success('Comment deleted successfully');
        return;
      }

      // Otherwise, delete from backend
      const response = await axios.delete(`/custom-board/tasks/${task.id}/comments/${commentId}`);
      if (response.status === 200) {
        const updatedTask = response.data;
        
        // Update both local and parent state
        setLocalTask(updatedTask);
        onTaskUpdate?.(updatedTask);
        
        toast.success('Comment deleted successfully');
      }
    } catch (error) {
      console.error('Error deleting comment:', error);
      toast.error('Failed to delete comment');
      
      // Revert optimistic deletes
      setOptimisticDeletes(prev => {
        const newSet = new Set(Array.from(prev || []));
        newSet.delete(commentId);
        if (threads[commentId]) {
          threads[commentId].forEach((reply: Comment) => newSet.delete(reply.id));
        }
        return newSet;
      });
    } finally {
      setDeletingComments(prev => {
        const newSet = new Set(Array.from(prev || []));
        newSet.delete(commentId);
        return newSet;
      });
    }
  };

  const toggleThread = (commentId: number) => {
    setExpandedThreads(prev => 
      prev.includes(commentId) 
        ? prev.filter(id => id !== commentId)
        : [...prev, commentId]
    )
  }

  // Group comments by parent_id
  const groupComments = (comments: Comment[] = []) => {
    const threads: { [key: number]: Comment[] } = {}
    const mainComments: Comment[] = []

    comments.forEach(comment => {
      if (comment.parent_id) {
        if (!threads[comment.parent_id]) {
          threads[comment.parent_id] = []
        }
        threads[comment.parent_id].push(comment)
      } else {
        mainComments.push(comment)
      }
    })

    return { mainComments, threads }
  }

  const { mainComments, threads } = groupComments(localTask?.comments)

  // Filter out optimistically deleted comments from mainComments
  const filteredMainComments = mainComments.filter(comment => !optimisticDeletes.has(comment.id))

  const handleReplyClick = (commentId: number) => {
    // If already replying to this comment, cancel it
    if (replyTo === commentId) {
      setReplyTo(null)
      return
    }

    // Expand the thread when starting a reply
    if (!expandedThreads.includes(commentId)) {
      setExpandedThreads(prev => [...prev, commentId])
    }
    
    setReplyTo(commentId)
  }

  const renderComment = (comment: Comment, isReply: boolean = false) => {
    const user = users.find(u => u.user_id === comment.user_id)
    const hasReplies = threads[comment.id]?.length > 0
    const isExpanded = expandedThreads.includes(comment.id)
    const isReplying = replyTo === comment.id

    // Don't render if optimistically deleted
    if (optimisticDeletes.has(comment.id)) return null

    return (
      <div key={comment.id} className={cn(
        "relative",
        isReply ? "ml-8 mt-2" : "mt-4 first:mt-0"
      )}>
        <div className="flex gap-2 group/item">
          <UserAvatar 
            user={user || { 
              name: 'Unknown',
              surname: 'User',
              user_id: comment.user_id,
            }} 
            size={32} 
          />
          <div className="flex-1 flex flex-col gap-1 ml-2 pr-8">
            <div className="flex justify-between items-start">
              <p className="text-sm font-medium">
                {user ? `${user.name} ${user.surname}` : `User ${comment.user_id}`}
              </p>
              <div className="flex gap-2">
                {!isReply && (
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => handleReplyClick(comment.id)}
                    className="opacity-0 group-hover/item:opacity-100 transition-opacity"
                  >
                    <Reply className="h-4 w-4" />
                  </Button>
                )}
                {canDeleteComment(comment) && (
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => handleDelete(comment.id)}
                    disabled={deletingComments.has(comment.id)}
                    className="opacity-0 group-hover/item:opacity-100 transition-opacity"
                  >
                    {deletingComments.has(comment.id) ? (
                      <Loader2 className="h-4 w-4 animate-spin" />
                    ) : (
                      <Trash2 className="h-4 w-4" />
                    )}
                  </Button>
                )}
              </div>
            </div>
            <p className="text-sm whitespace-pre-wrap">{comment.content}</p>
            <p className="text-xs text-muted-foreground">
              {dayjs(comment.timestamp).format('YYYY-MM-DD HH:mm')}
            </p>

            {!isReply && (
              <div className="mt-2">
                {hasReplies && (
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => toggleThread(comment.id)}
                    className="h-auto py-1 px-2 text-xs"
                  >
                    {isExpanded ? (
                      <>
                        <ChevronUp className="h-3 w-3 mr-1" />
                        Hide {threads[comment.id].length} {threads[comment.id].length === 1 ? 'reply' : 'replies'}
                      </>
                    ) : (
                      <>
                        <ChevronDown className="h-3 w-3 mr-1" />
                        Show {threads[comment.id].length} {threads[comment.id].length === 1 ? 'reply' : 'replies'}
                      </>
                    )}
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>

        {!isReply && (
          <>
            {/* Always render replies if they exist, but control visibility with isExpanded */}
            {hasReplies && (
              <div className={cn(
                "transition-all",
                isExpanded || isReplying ? "block" : "hidden"
              )}>
                {threads[comment.id].map(reply => renderComment(reply, true))}
              </div>
            )}

            {/* Only show reply textarea after existing replies */}
            {isReplying && (
              <div className="mt-2 ml-8">
                <Textarea
                  ref={replyTextareaRef}
                  placeholder="Write a reply..."
                  value={newComment}
                  onChange={(e) => setNewComment(e.target.value)}
                  className="resize-none"
                  rows={2}
                />
                <div className="flex justify-end gap-2 mt-2">
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => {
                      setReplyTo(null)
                      setNewComment('')
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="sm"
                    onClick={handleSubmit}
                    disabled={!newComment.trim() || loading}
                  >
                    {loading ? (
                      <Loader2 className="mr-2 h-3 w-3 animate-spin" />
                    ) : (
                      <Send className="mr-2 h-3 w-3" />
                    )}
                    Reply
                  </Button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    )
  }

  return (
    <Dialog open={visible} onOpenChange={() => onClose()}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Task Notes & Comments</DialogTitle>
          <DialogDescription className="sr-only">
            Add or view comments for this task
          </DialogDescription>
        </DialogHeader>
        
        <DialogBody maxBodyHeight>
          <div className="flex flex-col gap-5">
            {(!filteredMainComments || filteredMainComments.length === 0) ? (
              <div className="flex justify-center items-center min-h-[100px] rounded-md">
                <p className="text-muted-foreground">No comments yet</p>
              </div>
            ) : (
              filteredMainComments.map(comment => renderComment(comment))
            )}
          </div>
        </DialogBody>

        <DialogFooter>
          {!replyTo && (
            <div className="flex flex-col gap-2 w-full">
              <Textarea
                placeholder="Write a comment..."
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                className="resize-none"
                rows={2}
              />
              <Button
                onClick={handleSubmit}
                disabled={!newComment.trim() || loading}
                className="self-end"
              >
                {loading ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Posting...
                  </>
                ) : (
                  <>
                    <Send className="mr-2 h-4 w-4" />
                    Post
                  </>
                )}
              </Button>
            </div>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
