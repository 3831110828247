import React from 'react'
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Treemap as RechartsTreemap, ResponsiveContainer, Tooltip } from 'recharts'
import { cn } from "@/lib/utils"

export interface TreemapData {
  name: string
  value: number
  color?: string
  children?: TreemapData[]
  [key: string]: any  // Allow additional properties
}

type ColorMode = 'theme' | 'multi'

interface TreemapProps {
  title?: string
  description?: string
  data: TreemapData[]
  className?: string
  valueFormatter?: (value: number) => string
  colorMode?: ColorMode
  tooltipFormatter?: (name: string, value: number, data: TreemapData) => React.ReactNode
}

const CHART_COLORS: Record<ColorMode, string[]> = {
  theme: Array.from({ length: 9 }, (_, i) => `var(--chart-${i + 1})`),
  multi: Array.from({ length: 5 }, (_, i) => `var(--chart-multi-${i + 1})`)
}

interface CustomContentProps {
  x: number
  y: number
  width: number
  height: number
  index: number
  name: string
  colorMode: ColorMode
  root: {
    name: string
    value: number
    fill?: string
  }
  // Optional props that come from Recharts but we don't use
  depth?: number
  colors?: string[]
}

const CustomTooltip = ({ active, payload, valueFormatter, tooltipFormatter }: any) => {
  if (active && payload && payload.length > 0) {
    const data = payload[0].payload
    const { name, value } = data
    
    if (tooltipFormatter) {
      return tooltipFormatter(name, value, data)
    }

    return (
      <div className="rounded-lg border bg-background p-2 shadow-md">
        <p className="font-medium">{name}</p>
        <p className="text-muted-foreground">
          {valueFormatter ? valueFormatter(value) : value}
        </p>
      </div>
    )
  }
  return null
}

// Function to truncate text based on available width
const truncateText = (text: string | undefined | null, availableWidth: number, elementWidth: number, elementHeight: number) => {
  if (!text || elementWidth < 50 || elementHeight < 30) return '';
  const safeText = String(text); // Convert to string in case it's a number
  const maxChars = Math.floor(availableWidth / 7); // Approximate 7px per character
  if (safeText.length <= maxChars) return safeText;
  return safeText.slice(0, maxChars - 3) + '...';
};

const CustomContent = ({ x, y, width, height, index, name, colorMode, root }: CustomContentProps) => {
  const colorArray = CHART_COLORS[colorMode];
  const color = root?.fill || colorArray[index % colorArray.length];
  
  // Ensure name is a string and not undefined/null
  const displayName = name ? String(name) : '';
  
  return (
    <g>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        style={{
          fill: color.startsWith('hsl') ? color : `hsl(${color.replace('var(', '').replace(')', '')})`,
          stroke: 'hsl(var(--border))',
          strokeWidth: 1,
        }}
      />
      {width > 50 && height > 30 && displayName && (
        <text
          x={x + width / 2}
          y={y + height / 2}
          textAnchor="middle"
          dominantBaseline="middle"
          className="fill-current text-xs font-medium"
        >
          {truncateText(displayName, width - 20, width, height)} {/* -20 for padding */}
        </text>
      )}
    </g>
  )
}

export function Treemap({
  title,
  description,
  data,
  className,
  valueFormatter = (value: number) => value.toString(),
  colorMode = 'theme',
  tooltipFormatter,
}: TreemapProps) {
  const colors = CHART_COLORS[colorMode].map(color => color.replace('var(--chart-', '').replace(')', ''))

  const ContentElement = React.memo((props: any) => (
    <CustomContent {...props} colorMode={colorMode} />
  ))

  return (
    <Card className={cn("w-full", className)}>
      {title && (
        <CardHeader>
          <CardTitle>{title}</CardTitle>
          {description && (
            <p className="text-sm text-muted-foreground">{description}</p>
          )}
        </CardHeader>
      )}
      <CardContent>
        <div className="h-[400px] w-full">
          <ResponsiveContainer width="100%" height="100%">
            <RechartsTreemap
              data={data}
              dataKey="value"
              aspectRatio={1}
              stroke="hsl(var(--border))"
              content={React.createElement(ContentElement)}
            >
              <Tooltip
                content={
                  <CustomTooltip
                    valueFormatter={valueFormatter}
                    tooltipFormatter={tooltipFormatter}
                  />
                }
              />
            </RechartsTreemap>
          </ResponsiveContainer>
        </div>
      </CardContent>
    </Card>
  )
} 