import { useEffect, useState, useCallback, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import axios from '@/api/axiosConfig'
import {
  ArrowUp,
  ArrowDown,
  Mail,
  RefreshCw,
  FileText,
  User,
  Store,
  Clock,
  Calendar,
  Timer,
  Play,
  Users,
  ShoppingBag,
  BookOpen,
  DollarSign,
  Pause,
  Square,
  Edit,
  Building2,
  Calculator,
  UserPlus,
  Briefcase,
  UserCircle2,
  Package,
  Phone,
  Globe2
} from 'lucide-react'

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog"
import { Button } from '@/components/ui/button'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { Badge } from '@/components/ui/badge'
import { Textarea } from '@/components/ui/textarea'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import { ScrollArea } from '@/components/ui/scroll-area'
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card'

import TaskViewCommunication from '../components/TaskViewCommunication'
import TaskViewNotes from '../components/TaskViewNotes'
import DealNavBar from '../components/DealNavBar'
import { TaskDateEditor } from '@/components/TaskDateEditor'
import TaskViewActivity from '../components/TaskViewActivity'
import { DealDrawer } from '@/drawers/DealDrawer'
import ProductsDrawer from '@/drawers/ProductsDrawer'
import { TaskStatusSelector } from '../components/TaskStatusSelector'
import TaskCompleteDrawer from '../drawers/TaskCompleteDrawer'
import { TimeTrackerContext } from '@/contexts/TimeTrackerContext'
import PayrollDrawer from '@/drawers/PayrollDrawer'
import { dealSoftwareConfig } from '@/configs/DealSoftwareConfig'
import { Skeleton } from "@/components/ui/skeleton"
import TaskViewOverview from '../components/TaskViewOverview'
import { LoadingSpinner } from "@/components/ui/loading-spinner"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { UserAvatar } from '@/components/user-avatar'
import { AssigneeSelection } from "@/modals/AssigneeSelection"
import { TaskViewSubtasks } from '@/taskboards/components/TaskViewSubtasks'
import { ContactDrawer } from '@/drawers/ContactDrawer'
import { AccountDrawer } from '@/drawers/AccountDrawer'

import type { CustomTask, TaskType } from '@/types/custom-task'
import type { BoardStatusOption, ProgressStatusOption, Board, BoardConfig } from '@/types/custom-board'
import type { StoredUser } from '@/types/user'
import type { Contact, Account } from '@/types/customers'
import type { Product } from '@/types/customers'
import type { SubTask as CustomBoardSubTask } from '@/types/custom-board'

dayjs.extend(utc)
dayjs.extend(timezone)

export interface TaskViewProps {
  onClose: () => void;
  tasks: CustomTask[];
  setTasks: React.Dispatch<React.SetStateAction<CustomTask[]>>;
  taskType: 'custom' | 'custom_subtask';
  boardStatusOptions: BoardStatusOption[];
  task?: CustomTask;
  fromWork: boolean;
  getBoardName: () => string;
  dealName: string;
  users: StoredUser[];
  board: Board | null;
  onTaskUpdate?: (task: CustomTask) => void;
}

interface DealData {
  name?: string;
  cvr?: string;
  address?: string;
  zipcode?: string;
  city?: string;
  economic_customer_number?: string;
  onboard_status?: string;
}

interface ContactData {
  id?: number;
  name?: string;
  email?: string;
  phone?: string;
}

interface ContactInfo {
  id: number;
  text: string;
  user_id: number;
  is_public: boolean;
}

interface TaskToComplete {
  id: number;
  title: string;
  description: string;
  task_type: TaskType;
  progress_status_id?: number;
  board_status_id?: number;
}

interface SubTask extends CustomBoardSubTask {
  progress_status: string;
}

interface TaskUpdate {
  id: number;
  user_id: string | number;
  type: "work_date" | "due_date" | "progress_status" | "board_status" | "next_work_date" | "next_due_date" | "assigned_users" | "comment_new" | "comment_reply" | "comment_deleted";
  old_value: any;
  new_value: any;
  message?: string;
  timestamp: string;
}

interface TaskStatusSelectorProps {
  task: CustomTask;
  onSuccess: (task: CustomTask) => void;
  className?: string;
  board: Board | null;
  value?: string;
  taskId: number;
  taskType: 'custom' | 'custom_subtask';
  boardStatusOptions: BoardStatusOption[];
  progressStatus: string;
}

const PRODUCT_ICONS: Record<string, JSX.Element> = {
  'Bogføring': <Calculator className="h-5 w-5" />,
  'Onboarding': <UserPlus className="h-5 w-5" />,
  'Lønadministration': <Users className="h-5 w-5" />,
  'Årsregnskab': <FileText className="h-5 w-5" />
};

const truncateText = (text: string, maxLength: number = 13) => {
  if (text.length <= maxLength) return text;
  return text.slice(0, maxLength) + '...';
};

const fetchContactInfo = async (contactId: number, setContactInfo: (info: any) => void) => {
  try {
    const response = await axios.get(`/account/contacts/${contactId}/info`)
    setContactInfo(response.data.entries)
  } catch (error) {
    console.error('Error fetching contact info:', error)
  }
}

const TaskView: React.FC<TaskViewProps> = ({
  onClose,
  tasks,
  setTasks,
  taskType,
  boardStatusOptions,
  task: initialTask,
  fromWork,
  getBoardName: getBoardNameProp,
  dealName,
  users: initialUsers,
  board: initialBoard,
  onTaskUpdate
}): JSX.Element => {
  const { taskId } = useParams()
  const navigate = useNavigate()
  const [currentTask, setCurrentTask] = useState<CustomTask | undefined>(initialTask)
  const [loading, setLoading] = useState(!initialTask)
  const [localBoard, setLocalBoard] = useState<Board | null>(initialBoard)
  const [localUsers, setLocalUsers] = useState<StoredUser[]>(initialUsers)
  const [activeTab, setActiveTab] = useState('overview')
  const [accountData, setAccountData] = useState(null)
  const [productsData, setProductsData] = useState([])
  const [contactInfoVisible, setContactInfoVisible] = useState(false)
  const [contactInfo, setContactInfo] = useState<ContactInfo[]>([])
  const [users, setUsers] = useState<StoredUser[]>(initialUsers || [])
  const [dealDrawerVisible, setDealDrawerVisible] = useState(false)
  const [selectedAccount, setSelectedAccount] = useState<Account | null>(null)
  const [selectedContact, setSelectedContact] = useState<Contact | null>(null)
  const [accountModalVisible, setAccountModalVisible] = useState(false)
  const [contactModalVisible, setContactModalVisible] = useState(false)
  const [productsDrawerVisible, setProductsDrawerVisible] = useState(false)
  const [isCompleteDrawerVisible, setIsCompleteDrawerVisible] = useState(false)
  const [taskToComplete, setTaskToComplete] = useState<TaskToComplete | null>(null)
  const [lonConfig, setLonConfig] = useState<{ salary_type?: string; salary_period_hourly?: { start?: number; end?: number; display_start?: string; display_end?: string } }>({})
  const [currentPeriod, setCurrentPeriod] = useState(dayjs().format('YYYY-MM'))
  const [payrollDrawerVisible, setPayrollDrawerVisible] = useState(false)
  const [dealData, setDealData] = useState<DealData>({})
  const [contactData, setContactData] = useState<ContactData>({})
  const [isLoadingDealData, setIsLoadingDealData] = useState(true)
  const isCustomTask = currentTask?.task_type === 'custom'
  const [assigneeModalVisible, setAssigneeModalVisible] = useState(false)

  const { 
    isRunning, 
    isPaused, 
    currentTask: contextCurrentTask,
    startTracking,
    stopTracking,
    pauseTracking,
    resumeTimer
  } = useContext(TimeTrackerContext)

  const isCurrentTask = contextCurrentTask?.taskId === currentTask?.id

  useEffect(() => {
    if (initialUsers?.length) {
      setLocalUsers(initialUsers);
    }
  }, [initialUsers]);

  useEffect(() => {
    if (initialBoard) {
      setLocalBoard(initialBoard);
    }
  }, [initialBoard]);

  useEffect(() => {
    if (!currentTask && taskId) {
      const foundTask = tasks.find(t => t.id.toString() === taskId);
      if (foundTask) {
        setCurrentTask(foundTask);
      }
    }
  }, [taskId, tasks, currentTask]);

  const getBoardName = useCallback((task: CustomTask) => {
    if (getBoardNameProp) {
      return getBoardNameProp();
    }
    return task.task_type;
  }, [getBoardNameProp]);

  const fetchTaskDetails = useCallback(async () => {
    try {
      const endpoint = taskType === 'custom'
        ? `/custom-board/tasks/${taskId}`
        : `/custom-board/subtasks/${taskId}`;

      console.log('Fetching task details for:', { taskType, taskId, endpoint });
      const response = await axios.get(endpoint);
      console.log('Task details response:', response.data);
      
      setCurrentTask(response.data);
      setActiveTab(response.data.task_type === 'custom' ? 'overview' : '1');
      
      // Set deal data from the response
      if (response.data.deal) {
        console.log('Setting deal data:', response.data.deal);
        setDealData({
          name: response.data.deal.name,
          cvr: response.data.deal.cvr,
          address: response.data.deal.address,
          zipcode: response.data.deal.zipcode,
          city: response.data.deal.city,
          economic_customer_number: response.data.deal.economic_customer_number,
          onboard_status: response.data.deal.onboard_status
        });
      }
      
      // Set contact data from the first contact if available
      if (response.data.contacts && response.data.contacts.length > 0) {
        console.log('Setting contact data:', response.data.contacts[0]);
        setContactData(response.data.contacts[0]);
      }
      
      setLoading(false);
    } catch (error) {
      console.error('Error fetching task details:', error);
      setLoading(false);
    }
  }, [taskId, taskType]);

  useEffect(() => {
    if (initialTask) {
      setCurrentTask({
        ...initialTask,
        work_date: initialTask.work_date || undefined,
        due_date: initialTask.due_date || undefined
      });
      // Update active tab when task changes
      if (initialTask.task_type === 'custom') {
        setActiveTab('overview');
      } else {
        setActiveTab('1');
      }
      setLoading(false);
    } else if (taskId) {
      const taskFromList = tasks?.find(t => t.id.toString() === taskId);
      if (taskFromList) {
        setCurrentTask({
          ...taskFromList,
          work_date: taskFromList.work_date || undefined,
          due_date: taskFromList.due_date || undefined
        });
        // Update active tab when task is found from list
        if (taskFromList.task_type === 'custom') {
          setActiveTab('overview');
        } else {
          setActiveTab('1');
        }
        setLoading(false);
      } else {
        fetchTaskDetails();
      }
    }
  }, [taskId, initialTask, tasks, fetchTaskDetails]);

  const fetchDealData = async () => {
    if (currentTask?.deal_id) {
      setIsLoadingDealData(true)
      try {
        const response = await axios.get(`account/deals/${currentTask.deal_id}`)
        setDealData(response.data)
        if (response.data.contact) {
          setContactData(response.data.contact)
        }
      } catch (error) {
        console.error('Error fetching deal data:', error)
        toast.error('Failed to fetch deal data')
      } finally {
        setIsLoadingDealData(false)
      }
    }
  }

  useEffect(() => {
    if (currentTask?.deal_id) {
      fetchDealData()
    }
  }, [currentTask?.deal_id])

  useEffect(() => {
    if (currentTask?.deal_data?.contacts?.length) {
      fetchContactInfo(currentTask.deal_data.contacts[0].id, setContactInfo)
    }
  }, [currentTask])

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose()
    } else {
      navigate(-1)
    }
  }, [onClose, navigate])

  const handlePrevTask = () => {
    const currentIndex = tasks.findIndex(t => t.id === currentTask?.id);
    if (currentIndex > 0) {
      const prevTask = tasks[currentIndex - 1];
      
      if (taskType === 'custom') {
        setCurrentTask(prevTask);
        const taskId = prevTask.id;
        fetchTaskDetails();
      } else {
        navigate(`/boards/${taskType}/${prevTask.id}`, {
          state: { 
            fromKanban: true,
            tasks,
            selectedTask: prevTask
          },
          replace: true
        });
      }
    }
  };

  const handleNextTask = () => {
    const currentIndex = tasks.findIndex(t => t.id === currentTask?.id);
    if (currentIndex < tasks.length - 1) {
      const nextTask = tasks[currentIndex + 1];
      
      if (taskType === 'custom') {
        setCurrentTask(nextTask);
        const taskId = nextTask.id;
        fetchTaskDetails();
      } else {
        navigate(`/boards/${taskType}/${nextTask.id}`, {
          state: { 
            fromKanban: true,
            tasks,
            selectedTask: nextTask
          },
          replace: true
        });
      }
    }
  };

  const handleTaskUpdate = async (taskIdOrTask: string | CustomTask, updatedFields?: Partial<CustomTask>) => {
    try {
      let taskId: string;
      let fieldsToUpdate: Partial<CustomTask>;

      if (typeof taskIdOrTask === 'string') {
        taskId = taskIdOrTask;
        fieldsToUpdate = updatedFields || {};
      } else {
        taskId = taskIdOrTask.id.toString();
        fieldsToUpdate = taskIdOrTask;
      }

      const response = await axios.put<CustomTask>(`/custom-board/tasks/${taskId}`, fieldsToUpdate);
      
      // Find the existing task to preserve its properties
      const existingTask = tasks.find(t => t.id.toString() === taskId);
      if (!existingTask) return;

      const updatedTask = {
        ...existingTask,
        ...response.data,
        is_custom: true,
        task_type: existingTask.task_type,
        board_config: existingTask.board_config
      };

      setTasks((prevTasks: CustomTask[]) => 
        prevTasks.map(task => 
          task.id.toString() === taskId ? updatedTask : task
        )
      );

      // Update current task if it matches
      if (currentTask?.id.toString() === taskId) {
        setCurrentTask(updatedTask);
      }

      if (onTaskUpdate) {
        onTaskUpdate(updatedTask);
      }
    } catch (error) {
      console.error('Error updating task:', error);
      throw error;
    }
  };

  const handleStatusChange = (value: string | CustomTask) => {
    if (!currentTask) return;
    
    if (typeof value === 'string') {
      const statusId = parseInt(value, 10);
      const updatedTask = {
        ...currentTask,
        progress_status_id: statusId
      };
      handleTaskUpdate(updatedTask);
    } else {
      handleTaskUpdate(value);
    }
  };

  const handleBoardStatusChange = (value: string | CustomTask) => {
    if (!currentTask) return;
    
    if (typeof value === 'string') {
      const statusId = parseInt(value, 10);
      const updatedTask = {
        ...currentTask,
        board_status_id: statusId
      };
      handleTaskUpdate(updatedTask);
    } else {
      handleTaskUpdate(value);
    }
  };

  const handleCompleteDrawerClose = () => {
    setIsCompleteDrawerVisible(false)
    setTaskToComplete(null)
  }

  const handleCompleteDrawerSubmit = async (values: any) => {
    if (!taskToComplete || !localBoard?.board_config?.completion_settings?.drawer_config) return;

    try {
      const response = await axios.put(`/custom-board/tasks/${taskToComplete.id}`, {
        progress_status_id: taskToComplete.progress_status_id,
        board_status_id: taskToComplete.board_status_id,
        completion_data: values
      });

      if (response.status === 200) {
        toast.success('Task completed successfully');
        
        // Update tasks in state
        setTasks(prevTasks => 
          prevTasks.map(t => 
            t.id === taskToComplete.id 
              ? { ...t, ...response.data }
              : t
          )
        );

        // Close drawer
        setIsCompleteDrawerVisible(false);
        setTaskToComplete(null);

        // If this is the current task being viewed, update it
        if (currentTask?.id === taskToComplete.id) {
          setCurrentTask(prev => prev ? { ...prev, ...response.data } : null);
        }

        // Call onTaskUpdate if provided
        if (onTaskUpdate) {
          onTaskUpdate(response.data);
        }
      }
    } catch (error) {
      console.error('Error completing task:', error);
      toast.error('Failed to complete task');
    }
  };

  const formatDate = (dateString: string) => {
    return dayjs(dateString).format('YYYY-MM-DD')
  }

  const tabItems = [
    ...(isCustomTask ? [
      {
        value: "overview",
        label: (
          <div className="flex items-center gap-2">
            <FileText className="h-4 w-4" /> Overview
          </div>
        ),
        content: (
          <div className="flex flex-col h-full">
            <div className="flex-1">
              <TaskViewOverview 
                task={currentTask}
                board={localBoard}
                users={users}
                onDescriptionUpdate={async (newDescription) => {
                  try {
                    const response = await axios.put(`/custom-board/tasks/${currentTask.id}`, {
                      description: newDescription
                    });
                    setCurrentTask(prev => prev ? { ...prev, description: newDescription } : undefined);
                  } catch (error) {
                    console.error('Error updating description:', error);
                    throw error;
                  }
                }}
              />
            </div>
            {currentTask?.task_type === 'custom' && localBoard?.board_config?.enable_subtasks && (
              <div className="mt-auto">
                <TaskViewSubtasks
                  task={currentTask as CustomTask}
                  board={localBoard}
                  getAssignedUsers={(task: CustomTask | CustomBoardSubTask) => 
                    task.assigned_users?.map((userId: string) => 
                      users.find(u => u.user_id.toString() === userId)
                    ).filter(Boolean) as StoredUser[] || []
                  }
                  onSubtaskCreated={fetchTaskDetails}
                  onTaskUpdate={onTaskUpdate}
                />
              </div>
            )}
          </div>
        )
      }
    ] : []),
    ...(!isCustomTask || (isCustomTask && currentTask?.deal_id) ? [
      {
        value: "1",
        label: (
          <div className="flex items-center gap-2">
            <Mail className="h-4 w-4" /> Communication
          </div>
        ),
        content: (
          <TaskViewCommunication 
            dealId={currentTask?.deal_id} 
            contacts={currentTask?.deal_data?.contacts || []} 
          />
        )
      }
    ] : []),
    {
      value: "2",
      label: (
        <div className="flex items-center gap-2">
          <RefreshCw className="h-4 w-4" /> Notes / Comments
        </div>
      ),
      content: (
        <TaskViewNotes 
          task={currentTask || null}
          taskType={taskType}
          users={users}
          onUpdate={fetchTaskDetails}
        />
      )
    },
    {
      value: "3",
      label: (
        <div className="flex items-center gap-2">
          <FileText className="h-4 w-4" /> Activity Log
        </div>
      ),
      content: (
        <TaskViewActivity 
          updates={currentTask?.updates || []} 
          users={users}
          taskId={currentTask?.id}
          isCustomTask={currentTask?.task_type === 'custom'}
          task={currentTask}
        />
      )
    }
  ]

  const handleContactInfoClick = () => {
    setContactInfoVisible(true)
  }

  const handleContactInfoClose = () => {
    setContactInfoVisible(false)
    if (currentTask?.deal_data?.contacts?.length) {
      fetchContactInfo(currentTask.deal_data.contacts[0].id, setContactInfo)
    }
  }

  const showAccountDetails = async (account: any) => {
    setSelectedAccount(account)
    setAccountModalVisible(true)
    try {
      const response = await axios.get(`/account/accounts/${account.id}`)
      setSelectedAccount(response.data)
    } catch (error) {
      console.error('Error fetching account details:', error)
      toast.error('Failed to fetch account details')
    }
  }

  const showContactDetails = async (contact: any) => {
    if (contact?.id) {
      setSelectedContact(contact)
      setContactModalVisible(true)
      try {
        const response = await axios.get(`/account/contacts/${contact.id}`)
        setSelectedContact(response.data)
      } catch (error) {
        console.error('Error fetching contact details:', error)
        toast.error('Failed to fetch contact details')
      }
    }
  }

  const getTaskTypeAndRecurringType = (task: CustomTask | null) => {
    if (!task) return { taskType: '', recurringType: null };
    return {
      taskType: task.task_type,
      recurringType: task.recurring_type || null
    };
  };

  const handleStartTimer = () => {
    if (!currentTask) return

    const { taskType, recurringType } = getTaskTypeAndRecurringType(currentTask)
    
    const taskData = {
      taskId: currentTask.id,
      taskType: taskType,
      dealId: currentTask.deal_id,
      recurringType: recurringType,
      dealName: currentTask.deal_name || currentTask.deal?.name,
      taskDescription: currentTask.description || currentTask.data?.Task
    }
    
    startTracking(taskData)
  }

  const renderTimerButton = () => {
    if (!currentTask) return null

    if (isCurrentTask && isRunning) {
      return (
        <div className="flex gap-2 w-full">
          <Button
            variant={isPaused ? "default" : "secondary"}
            className="flex-1 h-10"
            onClick={() => isPaused ? resumeTimer() : pauseTracking()}
          >
            {isPaused ? (
              <Play className="mr-2 h-4 w-4" />
            ) : (
              <Pause className="mr-2 h-4 w-4" />
            )}
            {isPaused ? "Resume Timer" : "Pause Timer"}
          </Button>
          <Button
            variant="destructive"
            className="flex-1 h-10"
            onClick={stopTracking}
          >
            <Square className="mr-2 h-4 w-4" />
            Stop Timer
          </Button>
        </div>
      )
    }

    return (
      <Button
        className="w-full h-10"
        onClick={handleStartTimer}
        disabled={isRunning && !isCurrentTask}
      >
        <Play className="mr-2 h-4 w-4" />
        Start Timer
      </Button>
    )
  }

  const fetchLonConfig = async () => {
    // Implement this after creating a custom taskboard for løn tasks
  }

  useEffect(() => {
    fetchLonConfig()
  }, [currentTask?.deal_id, taskType])

  const handlePayrollConfigSave = () => {
    // Implement this after creating a custom taskboard for løn tasks
  }

  const shouldShowDealSections = () => {
    if (currentTask?.task_type === 'custom') {
      return !!currentTask.deal_id; // Only show if deal_id exists for custom tasks
    }
    return true; // Always show for normal taskboard tasks
  };

  const handleDescriptionUpdate = async (newDescription: string) => {
    if (!currentTask) return;
    
    try {
      const updatedTask: CustomTask = {
        ...currentTask,
        description: newDescription
      };
      await handleTaskUpdate(updatedTask);
    } catch (error) {
      console.error('Error updating description:', error);
      toast.error('Failed to update description');
    }
  };

  const handleDateUpdate = (updatedTask: CustomTask) => {
    setCurrentTask(prev => prev ? { ...prev, ...updatedTask } : prev);
    if (tasks && setTasks) {
      setTasks(prevTasks => 
        prevTasks.map(t => 
          t.id === currentTask?.id 
            ? { ...t, ...updatedTask }
            : t
        )
      );
    }
  };

  const renderEmptyState = () => (
    <div className="w-full h-full flex items-center justify-center">
      No task selected
    </div>
  );

  const renderTaskCard = () => {
    if (!currentTask) {
      return renderEmptyState();
    }

    if (currentTask.task_type === 'custom') {
      const boardConfig = currentTask.board?.board_config;
      if (!boardConfig) return <></>;

      const isSimplified = boardConfig?.simplify_main_tasks ?? false;

      return (
        <Card>
          <CardHeader>
            <CardTitle className="flex items-center gap-2">
              <FileText className="h-5 w-5" />
              Task
            </CardTitle>
          </CardHeader>
          <CardContent className="space-y-4">
            <div className="space-y-4">
              {/* Progress Status */}
              <div className="space-y-2">
                <div className="flex items-center gap-2 text-sm text-muted-foreground">
                  <Clock className="h-4 w-4" />
                  Progress Status
                </div>
                <div className="w-full overflow-hidden rounded-md">
                  <Select 
                    value={currentTask.progress_status_id?.toString() || ''}
                    onValueChange={handleStatusChange}
                  >
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="Select status">
                        {boardConfig?.progress_status_options?.find(so => so.id === currentTask.progress_status_id) && (
                          <div className="flex items-center gap-2">
                            <div 
                              className="w-3 h-3 rounded-full" 
                              style={{ backgroundColor: boardConfig?.progress_status_options?.find(so => so.id === currentTask.progress_status_id)?.color }}
                            />
                            {boardConfig?.progress_status_options?.find(so => so.id === currentTask.progress_status_id)?.label}
                          </div>
                        )}
                      </SelectValue>
                    </SelectTrigger>
                    <SelectContent>
                      {boardConfig?.progress_status_options?.map(status => (
                        <SelectItem key={status.id} value={status.id.toString()}>
                          <div className="flex items-center gap-2">
                            <div 
                              className="w-3 h-3 rounded-full" 
                              style={{ backgroundColor: status.color }}
                            />
                            {status.label}
                          </div>
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              </div>

              {/* Board Status */}
              {boardConfig?.board_status_options && boardConfig.board_status_options.length > 0 && (
                <div className="space-y-2">
                  <div className="flex items-center gap-2 text-sm text-muted-foreground">
                    <Clock className="h-4 w-4" />
                    Board Status
                  </div>
                  <div className="w-full overflow-hidden rounded-md">
                    <Select 
                      value={currentTask.board_status_id?.toString() || ''}
                      onValueChange={handleBoardStatusChange}
                    >
                      <SelectTrigger className="w-full">
                        <SelectValue placeholder="Select status">
                          {boardConfig?.board_status_options?.find(so => so.id === currentTask.board_status_id) && (
                            <div className="flex items-center gap-2">
                              <div 
                                className="w-3 h-3 rounded-full" 
                                style={{ backgroundColor: boardConfig?.board_status_options?.find(so => so.id === currentTask.board_status_id)?.color }}
                              />
                              {boardConfig?.board_status_options?.find(so => so.id === currentTask.board_status_id)?.label}
                            </div>
                          )}
                        </SelectValue>
                      </SelectTrigger>
                      <SelectContent>
                        {boardConfig?.board_status_options?.map(status => (
                          <SelectItem key={status.id} value={status.id.toString()}>
                            <div className="flex items-center gap-2">
                              <div 
                                className="w-3 h-3 rounded-full" 
                                style={{ backgroundColor: status.color }}
                              />
                              {status.label}
                            </div>
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                </div>
              )}
            </div>

            {/* Only show assignees and dates if not simplified */}
            {!isSimplified && (
              <>
                {/* Assignees */}
                <div className="space-y-2">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2 text-sm text-muted-foreground">
                      <Users className="h-4 w-4" />
                      Assignees
                    </div>
                    <Button
                      variant="ghost"
                      size="sm"
                      className="h-8 px-2"
                      onClick={() => setAssigneeModalVisible(true)}
                    >
                      <Edit className="h-4 w-4" />
                    </Button>
                  </div>
                  <div className="flex flex-wrap gap-2">
                    {currentTask.assigned_users?.map((userId) => {
                      const user = users.find(u => u.user_id.toString() === userId);
                      if (!user) return null;
                      return (
                        <div key={userId} className="flex items-center gap-2 bg-secondary rounded-lg px-2 py-1">
                          <UserAvatar user={user} size={24} />
                          <span className="text-sm">{user.name}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>

                {/* Dates */}
                <div className="grid grid-cols-2 gap-4">
                  {/* Work Date */}
                  <div className="space-y-2">
                    <div className="flex items-center gap-2 text-sm text-muted-foreground">
                      <Clock className="h-4 w-4" />
                      Work Date
                    </div>
                    <TaskDateEditor
                      date={currentTask.work_date || null}
                      dateType="work"
                      taskId={currentTask.id}
                      taskType="custom"
                      className="w-full"
                      onSuccess={handleDateUpdate}
                    />
                  </div>

                  {/* Due Date */}
                  <div className="space-y-2">
                    <div className="flex items-center gap-2 text-sm text-muted-foreground">
                      <Calendar className="h-4 w-4" />
                      Due Date
                    </div>
                    <TaskDateEditor
                      date={currentTask.due_date || null}
                      dateType="due"
                      taskId={currentTask.id}
                      taskType="custom"
                      className="w-full"
                      onSuccess={handleDateUpdate}
                    />
                  </div>
                </div>
              </>
            )}

            {/* Time Tracking (if enabled and not simplified) */}
            {boardConfig?.enable_time_tracking && !isSimplified && (
              <>
                {/* Timer Button */}
                {renderTimerButton()}
              </>
            )}
          </CardContent>
        </Card>
      );
    }

    // For non-custom tasks
    return (
      <Card>
        <CardHeader>
          <CardTitle className="flex items-center gap-2">
            <FileText className="h-5 w-5" />
            Task
          </CardTitle>
        </CardHeader>
        <CardContent className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <div className="flex items-center gap-2 text-sm text-muted-foreground">
                <Clock className="h-4 w-4" />
                Work Date
              </div>
              <TaskDateEditor
                date={currentTask.next_work_date || null}
                dateType="work"
                taskId={currentTask.id}
                taskType={taskType as TaskType}
                className="w-full"
                onSuccess={handleDateUpdate}
              />
            </div>
            <div className="space-y-2">
              <div className="flex items-center gap-2 text-sm text-muted-foreground">
                <Calendar className="h-4 w-4" />
                Due Date
              </div>
              <TaskDateEditor
                date={currentTask.next_due_date || null}
                dateType="due"
                taskId={currentTask.id}
                taskType={taskType as TaskType}
                className="w-full"
                onSuccess={handleDateUpdate}
              />
            </div>
          </div>

          <TaskStatusSelector
            task={currentTask}
            onSuccess={handleStatusChange}
            className="w-full"
            board={localBoard}
            value={currentTask.board_status_id?.toString() || ''}
            taskId={currentTask.id}
            taskType={taskType}
            boardStatusOptions={boardStatusOptions}
            progressStatus={currentTask.progress_status_id?.toString() || ''}
          />
          {renderTimerButton()}
        </CardContent>
      </Card>
    );
  };

  const renderAccountCard = () => {
    const account = currentTask?.deal_data?.account;
    if (!account) {
      return (
        <div className="text-sm text-muted-foreground italic">
          No account associated with this deal
        </div>
      );
    }

    return (
      <Badge 
        variant="secondary"
        className="cursor-pointer hover:bg-secondary/80 bg-[hsl(var(--badge-account))] text-white hover:bg-[hsl(var(--badge-account)/.8)]"
        onClick={() => showAccountDetails(account)}
      >
        <Building2 className="w-3 h-3 flex-shrink-0" />
        <span className="max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap">
          {account.company_name}
        </span>
      </Badge>
    );
  };

  const handleAssigneeUpdate = async (assignees: string[]) => {
    if (!currentTask) return;
    
    try {
      const response = await axios.put(`/custom-board/tasks/${currentTask.id}`, {
        assigned_users: assignees
      });
      
      const updatedTask = { ...currentTask, ...response.data };
      setCurrentTask(updatedTask);
      setAssigneeModalVisible(false);
    } catch (error) {
      console.error('Error updating assignees:', error);
      toast.error('Failed to update assignees');
    }
  };

  useEffect(() => {
    if (localBoard) {
      setLocalBoard(localBoard);
    } else if (currentTask?.task_type === 'custom' && currentTask.board_id) {
      const fetchBoard = async () => {
        try {
          const response = await axios.get(`/custom-board/boards/${currentTask.board_id}`);
          setLocalBoard(response.data);
        } catch (error) {
          console.error('Error fetching board:', error);
        }
      };
      fetchBoard();
    }
  }, [currentTask, localBoard]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <LoadingSpinner size="lg" />
      </div>
    )
  }

  if (!currentTask) return <div />;

  return (
    <>
      <Dialog open onOpenChange={handleClose} modal>
        <DialogContent 
          className="max-w-[90vw] h-[90vh] p-0 flex flex-col border-0"
          closeButtonClassName="text-white dark:text-white"
        >
          <div className="sr-only">
            <DialogHeader>
              <DialogTitle>Task Details</DialogTitle>
              <DialogDescription className="sr-only">
                Detailed view and management for this task
              </DialogDescription>
            </DialogHeader>
          </div>
          <div className="flex flex-col h-full overflow-hidden">
            <div className="bg-primary text-primary-foreground p-4 rounded-t-lg shrink-0">
              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center gap-2">
                  <div className="flex gap-2">
                  <Button
                    variant="secondary"
                    size="icon"
                    onClick={handlePrevTask}
                    disabled={tasks.findIndex(t => t.id === currentTask?.id) === 0}
                  >
                    <ArrowUp className="h-4 w-4" />
                  </Button>
                  <Button
                    variant="secondary"
                    size="icon"
                    onClick={handleNextTask}
                    disabled={tasks.findIndex(t => t.id === currentTask?.id) === tasks.length - 1}
                  >
                    <ArrowDown className="h-4 w-4" />
                  </Button>
                  </div>
                  <h2 className="text-lg font-semibold ml-4">
                    {currentTask?.task_type === 'custom' ? (
                      <>
                        {currentTask.deal_id ? `${dealData.name || dealName}: ` : ''}{currentTask.title}
                      </>
                    ) : (
                      `${dealData.name || dealName || 'N/A'}: ${currentTask.description}`
                    )}
                  </h2>
                </div>
                <div className="mr-8">
                  {shouldShowDealSections() && <DealNavBar dealId={currentTask?.deal_id} />}
                </div>
              </div>
            </div>

            <div className="flex flex-1 overflow-hidden">
              <div className="flex-[2] flex flex-col overflow-hidden">
                <Tabs value={activeTab} onValueChange={setActiveTab} className="flex flex-col h-full">
                  <div className="border-b border-border bg-muted shrink-0">
                    <TabsList className="w-full h-12 bg-transparent">
                      {tabItems.map((item) => (
                        <TabsTrigger
                          key={item.value}
                          value={item.value}
                          className="flex-1 data-[state=active]:bg-background data-[state=active]:shadow-sm relative px-8"
                        >
                          {item.label}
                          <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-primary opacity-0 transition-opacity data-[state=active]:opacity-100" />
                        </TabsTrigger>
                      ))}
                    </TabsList>
                  </div>
                  
                  <div className="flex-1 overflow-hidden">
                    <ScrollArea className="h-full">
                      <div className="p-4 space-y-4">
                        {tabItems.map((item) => (
                          <TabsContent key={item.value} value={item.value} className="mt-0 p-0 h-full">
                            {item.content}
                          </TabsContent>
                        ))}
                      </div>
                    </ScrollArea>
                  </div>
                </Tabs>
              </div>

              <div className="w-[400px] border-l border-border overflow-hidden">
                <ScrollArea className="h-[calc(90vh-5rem)]">
                  <div className="p-4 space-y-6 pb-16">
                    {renderTaskCard()}
                    {/* Payroll Card */}

                    {/* Not yet implemented. Should be displayed for a very specific taskboard with løn tasks */}
                    
                    {/* ... Payroll history graph would go here ... */}

                    {/* Contact Card */}
                    {(!isCustomTask || (isCustomTask && currentTask?.deal_id)) && (
                      <Card>
                        <CardHeader>
                          <div className="flex items-center justify-between">
                            <CardTitle className="flex items-center gap-2">
                              <UserCircle2 className="h-5 w-5" />
                              Contact
                            </CardTitle>
                          </div>
                        </CardHeader>
                        <CardContent className="space-y-4">
                          {isLoadingDealData ? (
                            <div className="space-y-4">
                              <div className="grid grid-cols-2 gap-4">
                                <div>
                                  <Skeleton className="h-4 w-20 mb-2" />
                                  <Skeleton className="h-5 w-32" />
                                </div>
                                <div>
                                  <Skeleton className="h-4 w-20 mb-2" />
                                  <Skeleton className="h-5 w-40" />
                                </div>
                                <div>
                                  <Skeleton className="h-4 w-20 mb-2" />
                                  <Skeleton className="h-5 w-28" />
                                </div>
                              </div>
                            </div>
                          ) : currentTask?.deal_data?.contacts && currentTask.deal_data.contacts.length > 0 ? (
                            <div className="space-y-4">
                              {currentTask.deal_data.contacts.map((contact: Contact, index: number) => (
                                <div key={index} className="space-y-4">
                                  <TooltipProvider>
                                    <Tooltip>
                                      <TooltipTrigger asChild>
                                        <div>
                                          <Badge 
                                            variant="secondary"
                                            className="cursor-pointer hover:bg-secondary/80 bg-[hsl(var(--badge-contact))] text-white hover:bg-[hsl(var(--badge-contact)/.8)]"
                                            onClick={() => showContactDetails(contact)}
                                          >
                                            <UserCircle2 className="w-3 h-3 flex-shrink-0" />
                                            <span className="max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap">
                                              {contact.name}
                                            </span>
                                          </Badge>
                                        </div>
                                      </TooltipTrigger>
                                      <TooltipContent>
                                        <div className="space-y-2">
                                          <p className="font-semibold">Contact Details:</p>
                                          {contactInfo.length > 0 ? (
                                            <ul className="list-disc pl-4">
                                              {contactInfo.map((entry, index) => (
                                                <li key={index}>{entry.text}</li>
                                              ))}
                                            </ul>
                                          ) : (
                                            <p>No info added yet, click to add</p>
                                          )}
                                        </div>
                                      </TooltipContent>
                                    </Tooltip>
                                  </TooltipProvider>

                                  <div className="space-y-2">
                                    {contact.email && (
                                      <div className="flex items-center gap-2">
                                        <Mail className="h-4 w-4 text-muted-foreground" />
                                        <a 
                                          href={`mailto:${contact.email}`}
                                          className="text-sm hover:underline"
                                        >
                                          {contact.email}
                                        </a>
                                      </div>
                                    )}
                                    {contact.phone && (
                                      <div className="flex items-center gap-2">
                                        <Phone className="h-4 w-4 text-muted-foreground" />
                                        <a 
                                          href={`tel:${contact.phone}`}
                                          className="text-sm hover:underline"
                                        >
                                          {contact.phone}
                                        </a>
                                      </div>
                                    )}
                                    <div className="flex items-center gap-2">
                                      <Globe2 className="h-4 w-4 text-muted-foreground" />
                                      <span className="text-sm">
                                        {contact.languages?.[0]?.name || 'Not specified'}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div className="text-sm text-muted-foreground italic">
                              No contacts associated with this deal
                            </div>
                          )}
                        </CardContent>
                      </Card>
                    )}

                    {/* Deal Card */}
                    {(!isCustomTask || (isCustomTask && currentTask?.deal_id)) && (
                      <Card>
                        <CardHeader>
                          <div className="flex items-center justify-between">
                            <CardTitle className="flex items-center gap-2">
                              <Building2 className="h-5 w-5" />
                              Deal
                            </CardTitle>
                            <Badge 
                              variant="secondary"
                              className="cursor-pointer hover:bg-secondary/80 bg-[hsl(var(--badge-deal))] text-white hover:bg-[hsl(var(--badge-deal)/.8)]"
                              onClick={() => setDealDrawerVisible(true)}
                            >
                              <Briefcase className="w-3 h-3 flex-shrink-0 mr-1" />
                              <span className="max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap">
                                {dealData.name}
                              </span>
                            </Badge>
                          </div>
                        </CardHeader>
                        <CardContent className="space-y-4">
                          <div className="grid grid-cols-2 gap-4">
                            <div>
                              <span className="text-sm text-muted-foreground">CVR</span>
                              <p>{dealData.cvr || 'N/A'}</p>
                            </div>
                            <div>
                              <span className="text-sm text-muted-foreground">Address</span>
                              <p>{`${dealData.address || ''} ${dealData.zipcode || ''} ${dealData.city || ''}`}</p>
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    )}

                    {/* Products Card */}
                    {(!isCustomTask || (isCustomTask && currentTask?.deal_id)) && (
                      <Card>
                        <CardHeader>
                          <div className="flex items-center justify-between">
                            <CardTitle className="flex items-center gap-2">
                              <ShoppingBag className="h-5 w-5" />
                              Products
                            </CardTitle>
                            <Badge 
                              variant="secondary"
                              className="cursor-pointer hover:bg-secondary/80 bg-[hsl(var(--badge-product))] text-white hover:bg-[hsl(var(--badge-product)/.8)]"
                              onClick={() => setProductsDrawerVisible(true)}
                            >
                              <Package className="w-3 h-3 flex-shrink-0 mr-1" />
                              <span className="max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap">
                                {currentTask?.deal_data?.products?.length || '0'} product(s)
                              </span>
                            </Badge>
                          </div>
                        </CardHeader>
                        <CardContent className="grid grid-cols-2 gap-4">
                          {isLoadingDealData ? (
                            <>
                              <div className="flex items-center gap-2 p-2 bg-background rounded-lg shadow">
                                <Skeleton className="h-10 w-10 rounded-md" />
                                <Skeleton className="h-5 w-24" />
                              </div>
                              <div className="flex items-center gap-2 p-2 bg-background rounded-lg shadow">
                                <Skeleton className="h-10 w-10 rounded-md" />
                                <Skeleton className="h-5 w-24" />
                              </div>
                            </>
                          ) : currentTask?.deal_data?.products && currentTask.deal_data.products.length > 0 ? (
                            currentTask.deal_data.products.map((product: Product, index: number) => (
                              <TooltipProvider key={index}>
                                <Tooltip>
                                  <TooltipTrigger asChild>
                                    <div className="flex items-center gap-2 p-2 bg-background rounded-lg shadow hover:shadow-lg transition">
                                      <div className="flex items-center justify-center w-10 h-10 bg-primary rounded-md text-primary-foreground">
                                        {PRODUCT_ICONS[product.name] || <ShoppingBag className="h-5 w-5" />}
                                      </div>
                                      <span className="text-sm font-medium truncate max-w-[100px]">
                                        {truncateText(product.name)}
                                      </span>
                                    </div>
                                  </TooltipTrigger>
                                  <TooltipContent>
                                    <div className="space-y-2">
                                      <p><span className="font-semibold">Name:</span> {product.name}</p>
                                      <p><span className="font-semibold">Code:</span> {product.code || 'N/A'}</p>
                                      <p><span className="font-semibold">Quantity:</span> {product.quantity || 1}</p>
                                      <p><span className="font-semibold">Price:</span> {product.item_price || 'N/A'}</p>
                                      <p><span className="font-semibold">Billing Start:</span> {product.billing_start_date || 'N/A'}</p>
                                      <p><span className="font-semibold">Frequency:</span> {product.billing_frequency || 'N/A'}</p>
                                    </div>
                                  </TooltipContent>
                                </Tooltip>
                              </TooltipProvider>
                            ))
                          ) : (
                            <div className="col-span-2 text-sm text-muted-foreground italic">
                              No products associated with this deal
                            </div>
                          )}
                        </CardContent>
                      </Card>
                    )}

                    {/* Account Card */}
                    {(!isCustomTask || (isCustomTask && currentTask?.deal_id)) && (
                      <Card className="mb-8">
                        <CardHeader>
                          <CardTitle className="flex items-center gap-2">
                            <Users className="h-5 w-5" />
                            Account
                          </CardTitle>
                        </CardHeader>
                        <CardContent>
                          {isLoadingDealData ? (
                            <div className="space-y-4">
                              <Skeleton className="h-8 w-40" />
                              <div className="grid grid-cols-2 gap-4">
                                <div>
                                  <Skeleton className="h-4 w-20 mb-2" />
                                  <Skeleton className="h-5 w-32" />
                                </div>
                                <div>
                                  <Skeleton className="h-4 w-20 mb-2" />
                                  <Skeleton className="h-5 w-40" />
                                </div>
                              </div>
                            </div>
                          ) : (
                            renderAccountCard()
                          )}
                        </CardContent>
                      </Card>
                    )}
                  </div>
                </ScrollArea>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <DealDrawer
        visible={dealDrawerVisible}
        onClose={() => setDealDrawerVisible(false)}
        dealId={currentTask?.deal_id || null}
      />
      <TaskCompleteDrawer
        visible={isCompleteDrawerVisible}
        onClose={handleCompleteDrawerClose}
        task={taskToComplete as CustomTask}
        onComplete={handleCompleteDrawerSubmit}
        sections={localBoard?.board_config?.completion_settings?.drawer_config?.sections || []}
        title={localBoard?.board_config?.completion_settings?.drawer_config?.title}
        description={localBoard?.board_config?.completion_settings?.drawer_config?.description}
      />
      <PayrollDrawer
        visible={payrollDrawerVisible}
        onClose={() => setPayrollDrawerVisible(false)}
        dealId={currentTask?.deal_id || null}
        onSave={handlePayrollConfigSave}
      />
      <ProductsDrawer
        visible={productsDrawerVisible}
        onClose={() => setProductsDrawerVisible(false)}
        products={currentTask?.deal_data?.products || []}
        dealId={currentTask?.deal_id}
        dealName={currentTask?.deal_name}
        onProductsChange={fetchTaskDetails}
      />
      <AssigneeSelection
        visible={assigneeModalVisible}
        onClose={() => setAssigneeModalVisible(false)}
        onSave={handleAssigneeUpdate}
        initialAssignees={currentTask?.assigned_users || []}
      />

      <AccountDrawer
        visible={accountModalVisible}
        onClose={() => setAccountModalVisible(false)}
        account={selectedAccount}
      />

      <ContactDrawer
        visible={contactModalVisible}
        onClose={() => setContactModalVisible(false)}
        contact={selectedContact}
      />
    </>
  )
}

export { TaskView };