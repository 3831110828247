import React, { useState, useEffect } from 'react';
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { Card } from "@/components/ui/card";
import { AvatarGroup } from "@/components/ui/avatars/avatar-group";
import { Plus, Check, X, UserPlus, CheckSquare } from 'lucide-react';
import { cn } from "@/lib/utils";
import { StoredUser } from '@/types/user';
import { CustomTask } from '@/types/custom-task';
import { Board, SubTask, SubtaskStatusOption } from '@/types/custom-board';
import { AssigneeSelection } from '@/modals/AssigneeSelection';
import { toast } from 'sonner';
import axios from '@/api/axiosConfig';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { SubtaskView } from '@/taskboards/views/SubtaskView';
import { useNavigate } from 'react-router-dom';

interface ExpandedSubtasksProps {
  task: CustomTask;
  board: Board | null;
  onDeleteSubtask: (taskId: number, subtaskId: number) => Promise<void>;
  onStatusUpdate: (taskId: number, subtaskId: number, newStatus: string) => Promise<SubTask>;
  getAssignedUsers: (task: CustomTask | SubTask & { task_type?: string }) => StoredUser[];
  onCreateSubtask: (taskId: number) => void;
  onTaskUpdate?: (task: CustomTask) => void;
  onSubtaskClick?: (taskId: number, subtaskId: number) => void;
}

const ExpandedSubtasks = ({
  task,
  board,
  onDeleteSubtask,
  onStatusUpdate,
  getAssignedUsers,
  onCreateSubtask,
  onTaskUpdate,
  onSubtaskClick
}: ExpandedSubtasksProps): React.ReactElement => {
  const [isAssigneeModalOpen, setIsAssigneeModalOpen] = useState(false);
  const [selectedSubtask, setSelectedSubtask] = useState<SubTask | null>(null);
  const [localTask, setLocalTask] = useState<CustomTask>(task);
  const [selectedSubtaskForView, setSelectedSubtaskForView] = useState<SubTask | null>(null);
  const navigate = useNavigate();

  // Update local task when parent task changes
  useEffect(() => {
    setLocalTask(task);
  }, [task]);

  const getStatusOptions = (subtask: SubTask): SubtaskStatusOption[] => {
    if (!board?.subtask_config?.predefined_subtasks) return [];

    const predefinedSubtask = board.subtask_config.predefined_subtasks.find(
      config => config.name === subtask.title
    );

    return predefinedSubtask?.status_options || [];
  };

  const getStatusColor = (subtask: SubTask, status: string): string => {
    if (!board?.subtask_config?.predefined_subtasks) return '#1890ff';

    const predefinedSubtask = board.subtask_config.predefined_subtasks.find(
      config => config.name === subtask.title
    );

    const statusOption = predefinedSubtask?.status_options?.find(opt => 
      opt.id === Number(subtask.progress_status_id)
    );

    return statusOption?.color || '#1890ff';
  };

  const getStatusLabel = (subtask: SubTask, status: string): string => {
    if (!board?.subtask_config?.predefined_subtasks) return status;

    const predefinedSubtask = board.subtask_config.predefined_subtasks.find(
      config => config.name === subtask.title
    );

    const statusOption = predefinedSubtask?.status_options?.find(opt => 
      opt.id === Number(subtask.progress_status_id)
    );

    return statusOption?.label || status;
  };

  const handleStatusUpdate = async (taskId: number, subtaskId: number, newStatus: string) => {
    // Store original state for rollback
    const originalTask = { ...localTask };

    // Optimistically update local state first
    const updatedSubtasks = localTask.subtasks?.map((st: SubTask) =>
      st.id === subtaskId ? { 
        ...st, 
        progress_status: newStatus,
        progress_status_id: Number(newStatus)
      } : st
    ) || [];

    const updatedTask = {
      ...localTask,
      subtasks: updatedSubtasks
    };

    // Update local state immediately
    setLocalTask(updatedTask);
    if (onTaskUpdate) {
      onTaskUpdate(updatedTask);
    }

    // Then call the API
    try {
      const response = await onStatusUpdate(taskId, subtaskId, newStatus);
      
      // Update local state with the full response from backend
      const updatedSubtasksWithResponse = localTask.subtasks?.map((st: SubTask) =>
        st.id === subtaskId ? { ...st, ...response } : st
      ) || [];

      const updatedTaskWithResponse = {
        ...localTask,
        subtasks: updatedSubtasksWithResponse
      };

      setLocalTask(updatedTaskWithResponse);
      if (onTaskUpdate) {
        onTaskUpdate(updatedTaskWithResponse);
      }
    } catch (error) {
      // Revert to original state on error
      setLocalTask(originalTask);
      if (onTaskUpdate) {
        onTaskUpdate(originalTask);
      }
      toast.error('Failed to update status');
    }
  };

  const handleAssigneeClick = (e: React.MouseEvent, subtask: SubTask) => {
    e.stopPropagation();
    setSelectedSubtask(subtask);
    setIsAssigneeModalOpen(true);
  };

  const handleAssigneeUpdate = async (newAssignees: string[]) => {
    if (!selectedSubtask) return;

    try {
      await axios.put(`/custom-board/subtasks/${selectedSubtask.id}`, {
        assigned_users: newAssignees
      });

      // Create a new subtasks array with the updated assignees
      const updatedSubtasks = localTask.subtasks?.map((st: SubTask) =>
        st.id === selectedSubtask.id ? { ...st, assigned_users: newAssignees } : st
      ) || [];

      // Create a new task object with the updated subtasks
      const updatedTask = {
        ...localTask,
        subtasks: updatedSubtasks
      };

      // Update both local and parent state
      setLocalTask(updatedTask);
      if (onTaskUpdate) {
        onTaskUpdate(updatedTask);
      }

      toast.success('Assignees updated successfully');
      setIsAssigneeModalOpen(false);
      setSelectedSubtask(null);
    } catch (error) {
      console.error('Error updating subtask assignees:', error);
      toast.error('Failed to update assignees');
    }
  };

  const handleSubtaskClick = (e: React.MouseEvent, subtask: SubTask) => {
    // Stop event propagation to prevent any parent handlers from interfering
    e.preventDefault();
    e.stopPropagation();

    // Ensure we have all required data
    if (!task || !board) {
      console.error('Missing required task or board data');
      return;
    }

    // Call the parent's onSubtaskClick handler if provided
    if (onSubtaskClick) {
      onSubtaskClick(task.id, subtask.id);
    }

    // Navigate to the subtask route with state
    navigate(`/custom-boards/${board.id}/task/${task.id}/subtask/${subtask.id}`, {
      replace: true,
      state: { 
        skipTaskView: true,
        subtask: {
          ...subtask,
          progress_status_id: Number(subtask.progress_status_id) || 0
        },
        task: task
      }
    });
  };

  const subtasks = localTask.subtasks || [];

  return (
    <>
      <Card 
        className="w-full mt-2 p-1 bg-muted/30"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="space-y-2">
          {/* Header */}
          <div className="flex items-center justify-between px-1">
            <h4 className="text-xs font-medium">Subtasks ({subtasks.length})</h4>
            <Button
              variant="outline"
              size="sm"
              className="h-7"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onCreateSubtask(task.id);
              }}
            >
              <Plus className="h-3 w-3 mr-1" />
              <span className="text-xs">Add</span>
            </Button>
          </div>

          {/* Subtasks List */}
          <div className="space-y-1">
            {subtasks.map((subtask: SubTask) => (
              <Card 
                key={subtask.id} 
                className="w-full p-2 bg-background hover:bg-muted/50 transition-colors cursor-pointer group/card"
                onClick={(e) => handleSubtaskClick(e, {
                  ...subtask,
                  progress_status_id: Number(subtask.progress_status_id) || 0
                })}
              >
                <div className="flex items-start justify-between">
                  <div className="flex-1 min-w-0">
                    <div className="flex items-center justify-between mb-1">
                      <div className="flex items-center gap-1 truncate group/title">
                        <CheckSquare className="h-3 w-3 flex-shrink-0" />
                        <span className="font-medium text-xs truncate group-hover/card:text-primary transition-colors">
                          {subtask.title}
                        </span>
                      </div>
                      <Button
                        variant="ghost"
                        size="icon"
                        className="h-5 w-5 ml-1 flex-shrink-0"
                        onClick={(e) => {
                          e.stopPropagation();
                          onDeleteSubtask(localTask.id, subtask.id);
                        }}
                      >
                        <X className="h-3 w-3" />
                      </Button>
                    </div>
                    {subtask.description && (
                      <p className="text-xs text-muted-foreground mt-1 truncate">
                        {subtask.description}
                      </p>
                    )}
                    
                    {/* Dates Section */}
                    {(subtask.work_date || subtask.due_date) && (
                      <div className="flex flex-wrap gap-2 mt-1 text-xs text-muted-foreground">
                        {subtask.work_date && (
                          <div className="flex items-center gap-1">
                            <span className="font-medium">Work:</span>
                            {new Date(subtask.work_date).toLocaleDateString()}
                          </div>
                        )}
                        {subtask.due_date && (
                          <div className="flex items-center gap-1">
                            <span className="font-medium">Due:</span>
                            {new Date(subtask.due_date).toLocaleDateString()}
                          </div>
                        )}
                      </div>
                    )}
                    
                    {/* Status Dropdown */}
                    <div className="my-2 status-badge">
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button 
                            variant="ghost" 
                            className="h-5 p-0 hover:bg-transparent"
                          >
                            <Badge
                              variant="custom"
                              className="cursor-pointer text-xs px-1.5 py-0"
                              style={{ backgroundColor: getStatusColor(subtask as SubTask, subtask.progress_status_id.toString()) }}
                            >
                              {getStatusLabel(subtask as SubTask, subtask.progress_status_id.toString())}
                            </Badge>
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="start" className="w-[160px]">
                          {getStatusOptions(subtask as SubTask).map((status) => (
                            <DropdownMenuItem
                              key={status.id}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleStatusUpdate(localTask.id, subtask.id, status.id.toString());
                              }}
                              className="flex items-center gap-2 cursor-pointer text-xs"
                            >
                              <div
                                className="w-2 h-2 rounded-full flex-shrink-0"
                                style={{ backgroundColor: status.color }}
                              />
                              <span className="truncate">{status.label}</span>
                              {status.id.toString() === subtask.progress_status_id.toString() && (
                                <Check className="w-3 h-3 ml-auto flex-shrink-0" />
                              )}
                            </DropdownMenuItem>
                          ))}
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </div>

                    {/* Assignees Section */}
                    {board?.subtask_config?.enable_assignees && (
                      <div className="mt-1 assignee-section">
                        {subtask.assigned_users?.length ? (
                          <div 
                            className="cursor-pointer hover:opacity-80 transition-opacity inline-flex"
                            onClick={(e) => handleAssigneeClick(e, {
                              ...subtask,
                              progress_status_id: Number(subtask.progress_status_id) || 0
                            })}
                          >
                            <AvatarGroup
                              users={getAssignedUsers({
                                ...subtask,
                                progress_status_id: Number(subtask.progress_status_id) || 0,
                                task_type: localTask.task_type,
                              })}
                              max={2}
                            />
                          </div>
                        ) : (
                          <Button
                            variant="ghost"
                            size="sm"
                            className="h-6 text-xs text-muted-foreground hover:text-foreground"
                            onClick={(e) => handleAssigneeClick(e, {
                              ...subtask,
                              progress_status_id: Number(subtask.progress_status_id) || 0
                            })}
                          >
                            <UserPlus className="h-3 w-3 mr-1" />
                            Add assignee
                          </Button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </Card>
            ))}
          </div>
        </div>
      </Card>

      {/* Assignee Selection Modal */}
      <AssigneeSelection
        visible={isAssigneeModalOpen}
        onClose={() => {
          setIsAssigneeModalOpen(false);
          setSelectedSubtask(null);
        }}
        onSave={handleAssigneeUpdate}
        initialAssignees={selectedSubtask?.assigned_users?.map(id => id.toString()) || []}
      />

      {selectedSubtaskForView && (
        <SubtaskView
          subtask={selectedSubtaskForView}
          task={localTask}
          board={board}
          onClose={() => {
            setSelectedSubtaskForView(null);
            // Update URL back to board view
            navigate(`/custom-boards/${board?.id}`, { replace: true });
          }}
        />
      )}
    </>
  );
};

export default ExpandedSubtasks; 