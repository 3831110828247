import axios from '@/api/axiosConfig';
import { showVersionToast } from '../toasts/version-toast';

interface Version {
  version: string;
  requiresRefresh: boolean;
  releaseNotes?: string;
  created_at: string;
  is_deployed: boolean;
}

export class VersionContext {
  private static currentVersion: string | null = localStorage.getItem('appVersion');
  private static checkInterval: number = 5 * 60 * 1000; // 5 minutes
  private static intervalId: ReturnType<typeof setInterval> | null = null;
  private static lastNotificationTime: number = 0;
  private static readonly NOTIFICATION_COOLDOWN = 1800000; // 30 minutes

  private static async checkVersion(): Promise<void> {
    try {
      // If no version stored, get the latest version first
      if (!this.currentVersion) {
        const response = await axios.get<Version[]>('/version/versions');
        if (response.data && response.data.length > 0) {
          const latestVersion = response.data[0];
          if (latestVersion.is_deployed) {
            localStorage.setItem('appVersion', latestVersion.version);
            this.currentVersion = latestVersion.version;
          }
        }
        return; // Exit after setting initial version
      }

      const response = await axios.get<Version[]>('/version/versions/since', {
        params: { version: this.currentVersion }
      });
      
      const updates = response.data;
      if (!updates || updates.length === 0) return;

      // Only consider deployed versions
      const deployedUpdates = updates.filter(update => update.is_deployed);
      if (deployedUpdates.length === 0) return;

      const latestVersion = deployedUpdates[deployedUpdates.length - 1];
      
      // Don't show notification if we're already on latest version
      if (latestVersion.version === this.currentVersion) return;

      if (deployedUpdates.some(update => update.requiresRefresh)) {
        showVersionToast({
          version: latestVersion.version,
          releaseNotes: latestVersion.releaseNotes,
          forceRefresh: true
        });
      } else {
        const now = Date.now();
        if (now - this.lastNotificationTime > this.NOTIFICATION_COOLDOWN) {
          showVersionToast({
            version: latestVersion.version,
            releaseNotes: latestVersion.releaseNotes,
            forceRefresh: false
          });
          this.lastNotificationTime = now;
        }
      }
    } catch (error) {
      console.error('Version check failed:', error);
    }
  }

  public static startVersionCheck(): void {
    // Initial check
    this.checkVersion();
    
    // Start periodic checks
    this.intervalId = setInterval(() => this.checkVersion(), this.checkInterval);
  }

  public static stopVersionCheck(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  // Add method to update current version
  public static updateCurrentVersion(version: string): void {
    this.currentVersion = version;
    localStorage.setItem('appVersion', version);
  }
} 