import React, { useEffect } from 'react';
import { toast } from "sonner"
import { Button } from "@/components/ui/button"
import { openNotificationsModal } from '@/contexts/NotificationContext';
import axios from '@/api/axiosConfig';  // Use configured axios instance

interface NotificationToastProps {
  title: string
  message: string
  navigation?: {
    route: string
    params?: Record<string, string | number>
  }
  onNavigate?: (route: string, params?: Record<string, string | number>) => void
}

function NotificationToast(props: NotificationToastProps) {
  const handleNavigation = () => {
    if (!props.navigation || !props.onNavigate) return;
    props.onNavigate(props.navigation.route, props.navigation.params);
  };

  return (
    <div className="grid gap-1">
      <div className="font-semibold">{props.title}</div>
      <div className="text-sm text-muted-foreground">{props.message}</div>
      <div className="flex items-center gap-2 mt-2">
        {props.navigation && props.onNavigate && (
          <Button
            variant="secondary"
            size="sm"
            onClick={handleNavigation}
          >
            View
          </Button>
        )}
        <Button
          variant="ghost"
          size="sm"
          onClick={() => {
            toast.dismiss(); // Dismiss the current toast
            openNotificationsModal(); // Open the notifications modal
          }}
        >
          Open Notifications
        </Button>
      </div>
    </div>
  );
}

export function showNotificationToast(props: NotificationToastProps) {
  toast(
    <NotificationToast {...props} />,
    {
      duration: 5000,
      className: "notification-toast",
      // Custom styling for notification toasts
      style: {
        background: "var(--background)",
        border: "1px solid var(--border)",
        color: "var(--foreground)",
      },
    }
  )
}

// Function to check for new notifications and show toasts
export async function checkAndShowNotifications() {
  try {
    const response = await axios.get('/notifications/unshown');
    const notifications = response.data;

    if (!Array.isArray(notifications)) {
      console.error('Invalid notifications response:', notifications);
      return;
    }

    // Show toasts for each unshown notification, excluding version updates
    notifications
      .filter((notification: any) => notification.type !== 'version_update')
      .forEach((notification: any) => {
        if (notification.message || notification.navigation) {
          showNotificationToast({
            title: notification.title,
            message: notification.message,
            navigation: notification.navigation,
            onNavigate: (route, params) => {
              window.location.href = `${route}${params ? '?' + new URLSearchParams(params as any).toString() : ''}`;
            }
          });
        }
      });
  } catch (error) {
    console.error('Error checking notifications:', error);
  }
}

// Hook to periodically check for new notifications
export function useNotificationChecker(interval = 15000) { // Check more frequently
  useEffect(() => {
    // Check immediately on mount
    checkAndShowNotifications();

    // Set up interval
    const intervalId = setInterval(checkAndShowNotifications, interval);

    // Clean up on unmount
    return () => clearInterval(intervalId);
  }, [interval]);
} 