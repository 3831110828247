import React from 'react';
import { Switch } from "@/components/ui/switch";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormDescription,
} from "@/components/ui/form";
import { Plus, Trash2, GripVertical } from 'lucide-react';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { CompletionDrawerSection, ProgressStatusOption, BoardStatusOption, AutomationRule, RecurrenceSettings, BoardSettingsFormValues } from '@/types/custom-board';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { Card, CardHeader, CardContent } from "@/components/ui/card";
import { Input } from "@/components/ui/input";

interface TaskCompletionConfigProps {
  form: UseFormReturn<BoardSettingsFormValues>;
}

export function TaskCompletionConfig({ form }: TaskCompletionConfigProps) {
  const sectionsArray = useFieldArray({
    control: form.control,
    name: "board_config.completion_settings.drawer_config.sections"
  });

  const addSection = (type: CompletionDrawerSection['type']) => {
    const newSection: CompletionDrawerSection = {
      id: crypto.randomUUID(),
      type,
      title: '',
      required: false,
      config: {}
    };

    if (type === 'time_tracking') {
      newSection.config = {
        showHistory: true,
        allowComments: true
      };
    } else if (type === 'select') {
      newSection.config = {
        options: []
      };
    }

    sectionsArray.append(newSection);
  };

  return (
    <div className="space-y-6">
      <FormField
        control={form.control}
        name="board_config.enable_completion_events"
        render={({ field }) => (
          <FormItem className="flex items-center justify-between">
            <div>
              <FormLabel>Enable Completion Events</FormLabel>
              <FormDescription>
                Enable special events when tasks are completed
              </FormDescription>
            </div>
            <FormControl>
              <Switch
                checked={field.value}
                onCheckedChange={field.onChange}
              />
            </FormControl>
          </FormItem>
        )}
      />

      {form.watch('board_config.enable_completion_events') && (
        <>
          <FormField
            control={form.control}
            name="board_config.completion_settings.enable_task_completion_events"
            render={({ field }) => (
              <FormItem className="flex items-center justify-between">
                <div>
                  <FormLabel>Task Completion Events</FormLabel>
                  <FormDescription>
                    Enable events for main task completions
                  </FormDescription>
                </div>
                <FormControl>
                  <Switch
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="board_config.completion_settings.enable_subtask_completion_events"
            render={({ field }) => (
              <FormItem className="flex items-center justify-between">
                <div>
                  <FormLabel>Subtask Completion Events</FormLabel>
                  <FormDescription>
                    Enable events for subtask completions
                  </FormDescription>
                </div>
                <FormControl>
                  <Switch
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="board_config.completion_settings.show_confetti"
            render={({ field }) => (
              <FormItem className="flex items-center justify-between">
                <div>
                  <FormLabel>Show Confetti</FormLabel>
                  <FormDescription>
                    Show confetti animation on task completion
                  </FormDescription>
                </div>
                <FormControl>
                  <Switch
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="board_config.completion_settings.show_completion_drawer"
            render={({ field }) => (
              <FormItem className="flex items-center justify-between">
                <div>
                  <FormLabel>Show Completion Drawer</FormLabel>
                  <FormDescription>
                    Show a drawer requiring additional information on task completion
                  </FormDescription>
                </div>
                <FormControl>
                  <Switch
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
              </FormItem>
            )}
          />
        </>
      )}

      {form.watch('board_config.completion_settings.show_completion_drawer') && (
        <div className="space-y-4 border rounded-lg p-4">
          <h3 className="font-medium">Completion Drawer Configuration</h3>
          
          <FormField
            control={form.control}
            name="board_config.completion_settings.drawer_config.title"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Drawer Title</FormLabel>
                <FormControl>
                  <Input {...field} placeholder="Complete Task" />
                </FormControl>
              </FormItem>
            )}
          />

          <div className="space-y-4">
            <div className="flex justify-between items-center">
              <h4 className="font-medium">Drawer Sections</h4>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="outline" size="sm">
                    <Plus className="h-4 w-4 mr-2" />
                    Add Section
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuItem onClick={() => addSection('time_tracking')}>
                    Time Tracking
                  </DropdownMenuItem>
                  <DropdownMenuItem onClick={() => addSection('comment')}>
                    Comment
                  </DropdownMenuItem>
                  <DropdownMenuItem onClick={() => addSection('number_input')}>
                    Number Input
                  </DropdownMenuItem>
                  <DropdownMenuItem onClick={() => addSection('checkbox')}>
                    Checkbox
                  </DropdownMenuItem>
                  <DropdownMenuItem onClick={() => addSection('text_input')}>
                    Text Input
                  </DropdownMenuItem>
                  <DropdownMenuItem onClick={() => addSection('select')}>
                    Select
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>

            {sectionsArray.fields.map((field, index) => {
              const section = field as CompletionDrawerSection;
              return (
                <Card key={section.id}>
                  <CardHeader>
                    <div className="flex items-center justify-between">
                      <FormField
                        control={form.control}
                        name={`board_config.completion_settings.drawer_config.sections.${index}.title`}
                        render={({ field }) => (
                          <FormItem>
                            <FormControl>
                              <Input {...field} placeholder="Section Title" />
                            </FormControl>
                          </FormItem>
                        )}
                      />
                      <Button
                        variant="ghost"
                        size="sm"
                        onClick={() => sectionsArray.remove(index)}
                      >
                        <Trash2 className="h-4 w-4" />
                      </Button>
                    </div>
                  </CardHeader>
                  <CardContent>
                    <div className="space-y-4">
                      <FormField
                        control={form.control}
                        name={`board_config.completion_settings.drawer_config.sections.${index}.description`}
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Description</FormLabel>
                            <FormControl>
                              <Input {...field} placeholder="Section description" />
                            </FormControl>
                          </FormItem>
                        )}
                      />

                      <FormField
                        control={form.control}
                        name={`board_config.completion_settings.drawer_config.sections.${index}.required`}
                        render={({ field }) => (
                          <FormItem className="flex items-center justify-between">
                            <FormLabel>Required</FormLabel>
                            <FormControl>
                              <Switch
                                checked={field.value}
                                onCheckedChange={field.onChange}
                              />
                            </FormControl>
                          </FormItem>
                        )}
                      />
                    </div>

                    {section.type === 'time_tracking' && (
                      <div className="space-y-4">
                        <FormField
                          control={form.control}
                          name={`board_config.completion_settings.drawer_config.sections.${index}.config.showHistory`}
                          render={({ field }) => (
                            <FormItem className="flex items-center justify-between">
                              <FormLabel>Show Time History</FormLabel>
                              <FormControl>
                                <Switch
                                  checked={field.value}
                                  onCheckedChange={field.onChange}
                                />
                              </FormControl>
                            </FormItem>
                          )}
                        />
                        <FormField
                          control={form.control}
                          name={`board_config.completion_settings.drawer_config.sections.${index}.config.allowComments`}
                          render={({ field }) => (
                            <FormItem className="flex items-center justify-between">
                              <FormLabel>Allow Comments</FormLabel>
                              <FormControl>
                                <Switch
                                  checked={field.value}
                                  onCheckedChange={field.onChange}
                                />
                              </FormControl>
                            </FormItem>
                          )}
                        />
                      </div>
                    )}

                    {section.type === 'number_input' && (
                      <div className="space-y-4">
                        <FormField
                          control={form.control}
                          name={`board_config.completion_settings.drawer_config.sections.${index}.config.min`}
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>Minimum Value</FormLabel>
                              <FormControl>
                                <Input type="number" {...field} />
                              </FormControl>
                            </FormItem>
                          )}
                        />
                        <FormField
                          control={form.control}
                          name={`board_config.completion_settings.drawer_config.sections.${index}.config.max`}
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>Maximum Value</FormLabel>
                              <FormControl>
                                <Input type="number" {...field} />
                              </FormControl>
                            </FormItem>
                          )}
                        />
                        <FormField
                          control={form.control}
                          name={`board_config.completion_settings.drawer_config.sections.${index}.config.defaultValue`}
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>Default Value</FormLabel>
                              <FormControl>
                                <Input type="number" {...field} />
                              </FormControl>
                            </FormItem>
                          )}
                        />
                      </div>
                    )}

                    {section.type === 'select' && (
                      <div className="space-y-4">
                        <div className="flex justify-between items-center">
                          <h4 className="text-sm font-medium">Options</h4>
                          <Button
                            type="button"
                            variant="outline"
                            size="sm"
                            onClick={() => {
                              const currentOptions = form.watch(`board_config.completion_settings.drawer_config.sections.${index}.config.options`) || [];
                              form.setValue(`board_config.completion_settings.drawer_config.sections.${index}.config.options`, [
                                ...currentOptions,
                                { label: '', value: '' }
                              ]);
                            }}
                          >
                            <Plus className="h-4 w-4 mr-2" />
                            Add Option
                          </Button>
                        </div>
                        {form.watch(`board_config.completion_settings.drawer_config.sections.${index}.config.options`)?.map((option: { label: string; value: string }, optionIndex: number) => (
                          <div key={optionIndex} className="flex gap-2">
                            <FormField
                              control={form.control}
                              name={`board_config.completion_settings.drawer_config.sections.${index}.config.options.${optionIndex}.label`}
                              render={({ field }) => (
                                <FormItem className="flex-1">
                                  <FormControl>
                                    <Input {...field} placeholder="Option Label" />
                                  </FormControl>
                                </FormItem>
                              )}
                            />
                            <FormField
                              control={form.control}
                              name={`board_config.completion_settings.drawer_config.sections.${index}.config.options.${optionIndex}.value`}
                              render={({ field }) => (
                                <FormItem className="flex-1">
                                  <FormControl>
                                    <Input {...field} placeholder="Option Value" />
                                  </FormControl>
                                </FormItem>
                              )}
                            />
                            <Button
                              type="button"
                              variant="ghost"
                              size="icon"
                              onClick={() => {
                                const currentOptions = form.watch(`board_config.completion_settings.drawer_config.sections.${index}.config.options`) || [];
                                form.setValue(
                                  `board_config.completion_settings.drawer_config.sections.${index}.config.options`,
                                  currentOptions.filter((_: any, i: number) => i !== optionIndex)
                                );
                              }}
                            >
                              <Trash2 className="h-4 w-4" />
                            </Button>
                          </div>
                        ))}
                      </div>
                    )}

                    {section.type === 'text_input' && (
                      <div className="space-y-4">
                        <FormField
                          control={form.control}
                          name={`board_config.completion_settings.drawer_config.sections.${index}.config.placeholder`}
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>Placeholder Text</FormLabel>
                              <FormControl>
                                <Input {...field} placeholder="Enter placeholder text" />
                              </FormControl>
                            </FormItem>
                          )}
                        />
                        <FormField
                          control={form.control}
                          name={`board_config.completion_settings.drawer_config.sections.${index}.config.defaultValue`}
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>Default Value</FormLabel>
                              <FormControl>
                                <Input {...field} placeholder="Default value (optional)" />
                              </FormControl>
                            </FormItem>
                          )}
                        />
                      </div>
                    )}

                    {section.type === 'comment' && (
                      <div className="space-y-4">
                        <FormField
                          control={form.control}
                          name={`board_config.completion_settings.drawer_config.sections.${index}.config.placeholder`}
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>Placeholder Text</FormLabel>
                              <FormControl>
                                <Input {...field} placeholder="Enter placeholder text" />
                              </FormControl>
                            </FormItem>
                          )}
                        />
                        <FormField
                          control={form.control}
                          name={`board_config.completion_settings.drawer_config.sections.${index}.config.minLength`}
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>Minimum Length</FormLabel>
                              <FormControl>
                                <Input 
                                  type="number" 
                                  {...field} 
                                  onChange={e => field.onChange(Number(e.target.value))}
                                  min={0}
                                />
                              </FormControl>
                            </FormItem>
                          )}
                        />
                      </div>
                    )}

                    {section.type === 'checkbox' && (
                      <div className="space-y-4">
                        <FormField
                          control={form.control}
                          name={`board_config.completion_settings.drawer_config.sections.${index}.config.defaultValue`}
                          render={({ field }) => (
                            <FormItem className="flex items-center justify-between">
                              <FormLabel>Default Value</FormLabel>
                              <FormControl>
                                <Switch
                                  checked={field.value}
                                  onCheckedChange={field.onChange}
                                />
                              </FormControl>
                            </FormItem>
                          )}
                        />
                      </div>
                    )}
                  </CardContent>
                </Card>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
} 