import { useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import dayjs, { type Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import axios from '../api/axiosConfig';
import { toast } from 'sonner';
import { 
  Search,
  X,
  AlertTriangle,
  Clock,
  ChevronDown,
  CalendarIcon
} from 'lucide-react';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Badge } from "@/components/ui/badge";
import { 
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/specialized/mod-table";
import { AvatarGroup } from "@/components/ui/avatars/avatar-group";
import { type StoredUser, type UserColors } from '@/types/user';
import { DatePickerWithRange } from "@/components/ui/date/date-picker-with-range";

import { type CustomTask, type TaskType } from '@/types/custom-task';
import { type BoardStatusOption, type Board } from '@/types/custom-board';

import { DealDrawer } from '@/drawers/DealDrawer';
import { TaskCompleteDrawer } from '@/taskboards/drawers/TaskCompleteDrawer';
import { TaskView } from '../taskboards/views/TaskView';
import { AssigneeSelection } from '@/modals/AssigneeSelection';
import { UserAvatar } from '@/components/user-avatar';
import { TaskDateEditor } from '@/components/TaskDateEditor';
import { TaskStatusSelector } from '@/taskboards/components/TaskStatusSelector';
import { TimeTrackerCell } from '@/components/TimeTrackerCell';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"

import { MultiSelectProps, DropdownProps } from '@/types/props';
import { ColumnDef } from '@/types/table';
import { StatusSelect } from "@/components/ui/select/StatusSelect";
import { cn } from "@/lib/utils";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Loading } from "@/components/ui/loading";
import { useUsers } from '@/contexts/UserContext';

// Define types
type User = StoredUser;

// Register the plugin
dayjs.extend(isBetween);

// Add these type definitions at the top
interface BoardNameMap {
  [key: string]: string;
}

interface RecurringTypeMap {
  [key: string]: string;
}

interface ErrorResponse {
  response?: {
    data?: {
      message?: string;
    };
  };
}

interface TaskData {
  Task: string;
  'Due Date': string | null;
  'Work Date': string | null;
  Status: string;
  Assignee: string[];
}

interface WorkFilters {
  assignedUsers: string[];
  subtaskAssignedUsers: string[];
  progressStatus: number[];
  boardStatus: number[];
  subtaskFilters: Array<{
    name: string;
    statusId: number;
  }>;
  dealDataFilters: Array<{
    field: string;
    value: any;
  }>;
}

// Update getBoardDisplayName function
const getBoardDisplayName = (boardName: string | undefined): string => {
  if (!boardName) return '';
  
  const boardNameMap: Record<string, string> = {
    'custom': 'Custom Board',
    'custom_subtask': 'Custom Subtask'
  };

  return boardNameMap[boardName] || boardName;
};

interface MenuClickEvent {
  key: string;
}

interface DatePickerRangeValue extends Array<Dayjs | null> {
  0: Dayjs | null;
  1: Dayjs | null;
}

interface DatePickerWithRangeProps {
  value: {
    from: Date | null;
    to: Date | null;
  };
  onChange: (dates: { from: Date | null; to: Date | null }) => void;
}

// Update boardStatusConfigs usage
const getBoardStatusOptions = (board: Board | null): BoardStatusOption[] => {
  return board?.board_config?.board_status_options ?? [];
};

interface CompletionValues {
  timeSpent: number;
  timeReason?: string;
  timeComment?: string;
  paySlips?: number;
  updateInvoice?: boolean;
  updateContract?: boolean;
}

// Add proper type for updatedTask
interface UpdatedTask extends CustomTask {
  board_status: string;
  progress_status: string;
}

// Add type for menu event
interface MenuEvent {
  key: string;
}

interface CustomColumnDef extends Omit<ColumnDef<CustomTask>, 'dataIndex'> {
  key: string;
  title: string;
  dataIndex?: keyof CustomTask;
}

interface CustomDateRange {
  from: Dayjs | null;
  to: Dayjs | null;
}

// Add type for the row prop
type TableCellProps<T> = {
  row: {
    original: T;
  };
};

interface BoardConfig {
  board_status_options?: BoardStatusOption[];
  progress_status_options?: BoardStatusOption[];
  kanban_source?: string;
}

interface WorkProps {
  tasks: CustomTask[];
  onTaskUpdate: (taskId: string, updatedFields: Partial<CustomTask>) => Promise<void>;
  boardStatusOptions: BoardStatusOption[];
  loading: boolean;
  board: Board | null;
}

export default function Work() {
  const { users, currentUser } = useUsers();
  const [selectedPeriod, setSelectedPeriod] = useState<string | null>(null);
  const [selectedTodo, setSelectedTodo] = useState<string>('todo');
  const [selectedAssignee, setSelectedAssignee] = useState<string[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [customDateRange, setCustomDateRange] = useState<CustomDateRange>({
    from: null,
    to: null
  });
  const [allTasks, setAllTasks] = useState<CustomTask[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [assignees, setAssignees] = useState<StoredUser[]>([]);
  const [userColors, setUserColors] = useState<UserColors>({});
  const [dealDrawerVisible, setDealDrawerVisible] = useState<boolean>(false);
  const [selectedDeal, setSelectedDeal] = useState<number | null>(null);
  const [filters, setFilters] = useState<{
    assignedUsers: string[];
    subtaskAssignedUsers: string[];
    progressStatus: number[];
    boardStatus: number[];
    subtaskFilters: Array<{
      name: string;
      statusId: number;
    }>;
    dealDataFilters: Array<{
      field: string;
      value: any;
    }>;
  }>({
    assignedUsers: [],
    subtaskAssignedUsers: [],
    progressStatus: [],
    boardStatus: [],
    subtaskFilters: [],
    dealDataFilters: []
  });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(15);
  const [taskCompleteDrawerVisible, setTaskCompleteDrawerVisible] = useState<boolean>(false);
  const [selectedTaskForCompletion, setSelectedTaskForCompletion] = useState<CustomTask | null>(null);
  const navigate = useNavigate();
  const { boardType, taskId } = useParams();
  const [isTaskViewVisible, setIsTaskViewVisible] = useState<boolean>(false);
  const [selectedTask, setSelectedTask] = useState<CustomTask | null>(null);
  const [assigneeModalVisible, setAssigneeModalVisible] = useState<boolean>(false);
  const [selectedTaskForAssignee, setSelectedTaskForAssignee] = useState<CustomTask | null>(null);
  const location = useLocation();
  const [tasks, setTasks] = useState<CustomTask[]>([]);

  useEffect(() => {
    if (currentUser) {
      setSelectedAssignee([String(currentUser.user_id)]);
    }
  }, [currentUser]);

  useEffect(() => {
    if (boardType && taskId) {
      const task = allTasks.find(t => t.id.toString() === taskId && getTaskboardName(t) === boardType);
      if (task) {
        setSelectedTask(task);
        setIsTaskViewVisible(true);
      }
    }
  }, [boardType, taskId, allTasks]);

  const fetchTasks = useCallback(async () => {
    try {
      const assigneeIds = selectedAssignee.length > 0 ? selectedAssignee : (currentUser ? [currentUser.user_id] : []);
      const params = new URLSearchParams({
        assignees: assigneeIds.join(','),
      });
      const response = await axios.get(`/work/assigned_tasks?${params}`);
      setAllTasks(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching tasks:', error);
      setLoading(false);
    }
  }, [currentUser, selectedAssignee]);

  // Update the allTasksData useMemo
  const allTasksData = useMemo(() => {
    return allTasks.map(task => ({
      ...task,
      key: `${task.task_type}-${task.id}`,
      board_name: task.board_name || 'Custom Board',
      deal_id: task.deal_id,
      deal_name: task.deal_name || (task.deal ? task.deal.name : undefined),
    }));
  }, [allTasks]);

  // Update getDateRange function
  const getDateRange = (key: string | null): [Dayjs | null, Dayjs | null] => {
    const today = dayjs();
    
    if (!key) return [null, null];

    switch (key) {
      case 'today':
        return [today.startOf('day'), today.endOf('day')];
      case 'thisMonth':
        return [today.startOf('month'), today.endOf('month')];
      case 'lastMonth':
        return [
          today.subtract(1, 'month').startOf('month'),
          today.subtract(1, 'month').endOf('month')
        ];
      case 'thisAndNextMonth':
        return [
          today.startOf('month'),
          today.add(1, 'month').endOf('month')
        ];
      case 'next3Months':
        return [today.startOf('day'), today.add(3, 'months').endOf('day')];
      case 'custom':
        if (!customDateRange || !customDateRange.from || !customDateRange.to) {
          return [null, null];
        }
        return [
          dayjs(customDateRange.from),
          dayjs(customDateRange.to)
        ];
      default:
        return [null, null];
    }
  };

  // Update isTaskInPeriod function
  const isTaskInPeriod = (task: CustomTask, period: string): boolean => {
    try {
      const rawDueDate = task.due_date;
      if (!rawDueDate) return false;
      const dueDate = dayjs(rawDueDate);
      if (!dueDate.isValid()) return false;

      const [startDate, endDate] = getDateRange(period);
      if (!startDate || !endDate) return false;

      // Use inclusive comparison
      return dueDate >= startDate && dueDate <= endDate;
    } catch (error) {
      console.error('Error checking task period:', error);
      return false;
    }
  };

  const isTaskOverdue = (task: CustomTask): boolean => {
    const dueDate = dayjs(task.due_date);
    return dueDate.isBefore(dayjs(), 'day');
  };

  const filteredTasks = useMemo(() => {
    return allTasksData.filter((task) => {
      const taskDescription = task.description || '';
      const boardName = task.board_name || '';
      const dealName = task.deal_name || '';
      
      const matchesSearch = searchText.toLowerCase().includes(' ') ?
        // For multi-word searches, check if all words are present
        searchText.toLowerCase().split(' ').every(word => 
          taskDescription.toLowerCase().includes(word) ||
          boardName.toLowerCase().includes(word) ||
          dealName.toLowerCase().includes(word)
        ) :
        // For single word searches, check if any field matches
        taskDescription.toLowerCase().includes(searchText.toLowerCase()) ||
        boardName.toLowerCase().includes(searchText.toLowerCase()) ||
        dealName.toLowerCase().includes(searchText.toLowerCase());
      
      const matchesPeriod = selectedPeriod ? isTaskInPeriod(task, selectedPeriod) : true;
      
      const matchesTodo = selectedTodo === 'todo' ? task.progress_status_id !== 1 :
                         selectedTodo === 'done' ? task.progress_status_id === 1 :
                         selectedTodo === 'overdue' ? isTaskOverdue(task) : true;
      
      return matchesSearch && matchesPeriod && matchesTodo;
    });
  }, [allTasksData, searchText, selectedPeriod, selectedTodo]);

  const sortedAndFilteredTasks = useMemo(() => {
    return filteredTasks.sort((a, b) => {
      const dateA = dayjs(a.work_date);
      const dateB = dayjs(b.work_date);
      return dateA.diff(dateB);
    });
  }, [filteredTasks]);

  useEffect(() => {
    if (currentUser && !isTaskViewVisible) {
      fetchTasks();
    }
  }, [currentUser, fetchTasks, isTaskViewVisible]);

  useEffect(() => {
    const fetchAssignees = async () => {
      try {
        const response = await axios.get('/user');
        setAssignees(response.data);
      } catch (error) {
        console.error('Error fetching assignees:', error);
      }
    };
    fetchAssignees();
  }, []);

  const getRowClassName = (record: CustomTask) => {
    const dueDate = dayjs(record.due_date);
    const workDate = dayjs(record.work_date);
    const today = dayjs().startOf('day');
    const status = record.progress_status_id;

    if (workDate?.isSame(today, 'day') || 
        (dueDate?.isBefore(today) && status !== 1) || 
        (workDate?.isBefore(today) && status !== 1)) {
      return 'needs-attention';
    }

    if (dueDate?.isSame(today, 'day')) {
      return 'due-today';
    } else if (dueDate?.isBefore(today)) {
      return 'overdue';
    }

    return '';
  };

  const handleDealClick = (dealId: string | number): void => {
    setSelectedDeal(typeof dealId === 'string' ? parseInt(dealId, 10) : dealId);
    setDealDrawerVisible(true);
  };

  const getUniqueValues = (data: CustomTask[], key: keyof CustomTask) => {
    if (key === 'board_name') {
      const uniqueBoards = new Set(data.map(item => item[key]));
      return Array.from(uniqueBoards).map(boardName => ({
        text: getBoardDisplayName(boardName as string),
        value: boardName
      }));
    }
    const values = new Set(data.map(item => item[key]));
    return Array.from(values).map(value => ({ text: value, value: value }));
  };

  // Update handleProgressStatusChange function
  const handleProgressStatusChange = async (taskId: number, taskType: string, newStatus: string): Promise<void> => {
    setAllTasks(prevTasks =>
      prevTasks.map(task =>
        task.id === taskId && task.task_type === taskType
          ? { 
              ...task, 
              progress_status: newStatus,
              data: {
                ...task.data,
                Status: newStatus
              }
            }
          : task
      )
    );
    
    try {
      await axios.patch(`/api/tasks/${taskId}/progress`, {
        status: newStatus,
        task_type: taskType
      });
      toast.success('Status updated successfully');
    } catch (error) {
      console.error('Error updating status:', error);
      toast.error('Failed to update status');
      // Rollback optimistic update
      setAllTasks(prevTasks =>
        prevTasks.map(task =>
          task.id === taskId
            ? { ...task, progress_status_id: task.progress_status_id || 0 }
            : task
        )
      );
    }
  };

  const handleTaskCompletion = async (values: CompletionValues) => {
    if (!selectedTaskForCompletion) return;
    
    const boardStatusOptions = selectedTaskForCompletion.board?.board_config?.board_status_options || [];
    const completedStatusOption = boardStatusOptions.find(opt => opt.is_completed_state);
    
    if (selectedTaskForCompletion.board_status_id === completedStatusOption?.id) {
      setSelectedTaskForCompletion(selectedTaskForCompletion);
      setTaskCompleteDrawerVisible(true);
    }
  };

  const getTaskboardName = (task: CustomTask): string => {
    if (task.task_type === 'custom' || task.task_type === 'custom_subtask') {
      return task.board_name || 'Custom Board';
    }
    return task.board_name || '';
  };

  const handleOpenTask = (task: CustomTask) => {
    setSelectedTask(task);
    setIsTaskViewVisible(true);
    navigate(`/work/${task.task_type}/${task.id}`, { 
      state: { previousPath: location.pathname }
    });
  };

  const handleCloseTask = () => {
    setIsTaskViewVisible(false);
    setSelectedTask(null);
    navigate('/work', { replace: true });
  };

  const handleAssigneeUpdate = async (newAssignees: string[]) => {
    if (!selectedTaskForAssignee) return;
    
    try {
      const response = await axios.put(`/work/update_assignees`, {
        task_id: selectedTaskForAssignee.id,
        task_type: selectedTaskForAssignee.task_type,
        assignees: newAssignees
      });
      
      if (response.status === 200) {
        setAllTasks(prevTasks => 
          prevTasks.map(task => 
            task.id === selectedTaskForAssignee.id && task.task_type === selectedTaskForAssignee.task_type
              ? { ...task, assigned_users: newAssignees }
              : task
          )
        );
        toast.success('Assignees updated successfully');
      }
    } catch (error) {
      console.error('Error updating assignees:', error);
      toast.error('Failed to update assignees');
    }
  };

  // Column definition for the mod-table
  const columns: ColumnDef<CustomTask>[] = [
    {
      title: 'Title',
      key: 'title',
      dataIndex: 'title',
      cell: ({ row }: { row: { original: CustomTask } }) => row.original.title || '',
    },
    {
      title: 'Description',
      key: 'description',
      dataIndex: 'description',
      cell: ({ row }: { row: { original: CustomTask } }) => row.original.description || '',
    },
    // Add more columns as needed
  ];

  const getPeriodLabel = (key: string | null): string => {
    if (!key) return 'Period';

    switch (key) {
      case 'today':
        return 'To do today';
      case 'thisMonth':
        return 'This month';
      case 'lastMonth':
        return 'Last month';
      case 'thisAndNextMonth':
        return 'This and next month';
      case 'next3Months':
        return 'Next 3 months';
      case 'custom':
        if (customDateRange && customDateRange.from && customDateRange.to) {
          return `${customDateRange.from.format('YYYY-MM-DD')} - ${customDateRange.to.format('YYYY-MM-DD')}`;
        }
        return 'Custom Period';
      default:
        return 'Period';
    }
  };

  const periodMenu = {
    items: [
      { key: 'today', label: 'To do today' },
      { key: 'thisMonth', label: 'This month' },
      { key: 'lastMonth', label: 'Last month' },
      { key: 'thisAndNextMonth', label: 'This and next month' },
      { key: 'next3Months', label: 'Next 3 months' },
      {
        key: 'custom',
        label: (
          <div 
            onClick={e => e.stopPropagation()}
            style={{ padding: '8px' }}
          >
            <DatePickerWithRange
              value={{
                from: customDateRange.from?.toDate() || null,
                to: customDateRange.to?.toDate() || null
              }}
              onChange={(dates) => {
                setCustomDateRange({
                  from: dates.from ? dayjs(dates.from) : null,
                  to: dates.to ? dayjs(dates.to) : null
                });
              }}
            />
          </div>
        ),
      },
    ],
    onClick: (e: MenuClickEvent) => {
      if (e.key !== 'custom') {
        setSelectedPeriod(e.key);
        const [start, end] = getDateRange(e.key);
        setCustomDateRange({
          from: start,
          to: end
        });
      }
    },
  };

  const todoOptions = [
    { key: 'todo', label: 'To do' },
    { key: 'overdue', label: 'Overdue' },
    { key: 'done', label: 'Done' },
  ];

  const todoMenu = {
    items: todoOptions.map(option => ({
      key: option.key,
      label: option.label,
    })),
    onClick: (e: MenuClickEvent) => {
      setSelectedTodo(e.key);
      if (e.key === 'done') {
        setFilters(prev => ({
          ...prev,
          progressStatus: [1]
        }));
      } else if (e.key === 'todo') {
        setFilters(prev => ({
          ...prev,
          progressStatus: []
        }));
      } else {
        setFilters(prev => ({
          ...prev,
          progressStatus: []
        }));
      }
    },
  };

  const renderMultiSelect = ({ options, placeholder, value, setValue }: MultiSelectProps) => ({
    items: [{
      key: 'content',
      label: (
        <Select
          value={value[0]}
          onValueChange={(newValue: string) => setValue([newValue])}
        >
          <SelectContent>
            {options.map((option) => (
              <SelectItem 
                key={option.user_id.toString()} 
                value={option.user_id.toString()}
              >
                {option.name}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      )
    }]
  });

  const renderDropdown = (menu: { items: Array<{ key: string; label: string | JSX.Element }> }, buttonText: string, className = '') => {
    return (
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button 
            variant="outline" 
            className={cn(
              "text-foreground border-primary",
              className
            )}
          >
            {buttonText}
            <ChevronDown className="ml-2 h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          {menu.items.map((item) => (
            <DropdownMenuItem key={item.key}>
              {item.label}
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    );
  };

  const clearFilters = useCallback(() => {
    setFilters({
      assignedUsers: [],
      subtaskAssignedUsers: [],
      progressStatus: [],
      boardStatus: [],
      subtaskFilters: [],
      dealDataFilters: []
    });
    setSelectedPeriod(null);
    setSelectedTodo('todo');
    if (currentUser) {
      setSelectedAssignee([String(currentUser.user_id)]);
    } else {
      setSelectedAssignee([]);
    }
    setSearchText('');
    setCustomDateRange({
      from: null,
      to: null
    });
    setCurrentPage(1);
  }, [currentUser, setFilters, setSelectedPeriod, setSelectedTodo, setSelectedAssignee, setSearchText, setCustomDateRange, setCurrentPage]);

  // Helper function to get status color
  const getStatusColor = (status: string): string => {
    switch (status) {
      case 'Completed':
        return 'green';
      case 'In progress':
        return 'orange';
      case 'Not started':
        return 'red';
      default:
        return 'gray';
    }
  };

  const handleTaskStatusChange = async (taskId: number, taskType: TaskType, newStatus: string): Promise<void> => {
    setAllTasks(prevTasks =>
      prevTasks.map(task =>
        task.id === taskId && task.task_type === taskType
          ? { 
              ...task, 
              progress_status_id: parseInt(newStatus),
            }
          : task
      )
    );
    
    try {
      await axios.patch(`/custom-board/tasks/${taskId}/progress`, {
        status: newStatus
      });
      toast.success('Status updated successfully');
    } catch (error) {
      console.error('Error updating status:', error);
      toast.error('Failed to update status');
      // Rollback optimistic update
      setAllTasks(prevTasks =>
        prevTasks.map(task =>
          task.id === taskId
            ? { ...task, progress_status_id: task.progress_status_id }
            : task
        )
      );
    }
  };

  const handleCompleteDrawerClose = () => {
    setTaskCompleteDrawerVisible(false);
    setSelectedTaskForCompletion(null);
  };

  const handleStatusChange = (record: CustomTask, newStatus: string) => {
    // Implementation here
  };

  const getApiTaskType = (task: CustomTask): TaskType => {
    return task.task_type;
  };

  const getConfigKeyFromTask = (task: CustomTask): string => {
    return task.task_type;
  };

  const isValidConfigKey = (key: string): boolean => {
    return key === 'custom' || key === 'custom_subtask';
  };

  // Add helper function for personal task check
  const isPersonalTask = (task: CustomTask): boolean => {
    return task.task_type === 'custom' && task.board?.is_public === false;
  };

  // Add pagination handlers
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (size: number) => {
    setPageSize(size);
    setCurrentPage(1); // Reset to first page when changing page size
  };

  // Update loading spinner component
  if (!users) {
    return <Loading message="Loading tasks..." />;
  }

  return (
    <div className="p-6 max-w-full overflow-x-hidden">
      <h1 className="text-2xl font-bold text-foreground">My Work</h1>
      
      <div className="flex flex-wrap items-center gap-4 my-6">
        {renderDropdown(periodMenu, getPeriodLabel(selectedPeriod), selectedPeriod ? 'filter-active' : '')}
        {renderDropdown(todoMenu, todoOptions.find(option => option.key === selectedTodo)?.label || 'To do', 'filter-active')}
        {renderDropdown(
          renderMultiSelect({
            options: users,
            placeholder: 'Select assignee',
            value: selectedAssignee,
            setValue: setSelectedAssignee,
          }),
          selectedAssignee.length > 0 ? `Assignee (${selectedAssignee.length})` : 'Assignee',
          selectedAssignee.length > 0 ? 'filter-active' : ''
        )}
        <Button 
          variant="ghost"
          onClick={clearFilters}
          className="gap-2 text-foreground"
        >
          <X className="h-4 w-4" />
          Clear Filters
        </Button>
      </div>

      <div className="mb-6 w-[280px]">
        <div className="relative">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-muted-foreground" />
          <Input
            type="text"
            placeholder="Search by customer or task name"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            className="pl-9 w-full"
          />
        </div>
      </div>

      <div className="w-full">
        <Table
          data={sortedAndFilteredTasks}
          columns={columns}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          className="w-full"
          alwaysShowPageSize
        />
      </div>

      {/* Render the TaskView modal */}
      {isTaskViewVisible && selectedTask && (
        <TaskView
          onClose={handleCloseTask}
          tasks={tasks}
          setTasks={setTasks}
          taskType={selectedTask.task_type}
          boardStatusOptions={selectedTask.board?.board_config?.board_status_options || []}
          task={selectedTask}
          fromWork={true}
          getBoardName={() => selectedTask?.board?.name || ''}
          dealName={selectedTask.deal_name || selectedTask.deal?.name || ''}
          users={users}
          board={selectedTask.board || null}
        />
      )}

      <DealDrawer 
        visible={dealDrawerVisible} 
        onClose={() => setDealDrawerVisible(false)} 
        dealId={selectedDeal}
      />
      <TaskCompleteDrawer
        visible={taskCompleteDrawerVisible}
        onClose={handleCompleteDrawerClose}
        task={selectedTaskForCompletion}
        onComplete={handleTaskCompletion}
        sections={[]}
      />
      <AssigneeSelection
        visible={assigneeModalVisible}
        onClose={() => {
          setAssigneeModalVisible(false);
          setSelectedTaskForAssignee(null);
        }}
        onSave={handleAssigneeUpdate}
        initialAssignees={selectedTaskForAssignee?.assigned_users || []}
      />
    </div>
  );
}