import React from 'react';
import { ArrowDownAZ, ArrowUpAZ, CalendarDays, History, ArrowUpDown, X } from 'lucide-react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import type { CustomTask } from '@/types/custom-task';

export type SortableField = 'work_date' | 'due_date' | 'title' | 'progress_status_id' | 'board_status_id';

export interface SortOption {
  field: SortableField;
  direction: 'asc' | 'desc';
}

interface TaskSorterProps {
  currentSort: SortOption | undefined;
  onSortChange: (sort: SortOption | undefined) => void;
}

export const TaskSorter: React.FC<TaskSorterProps> = ({
  currentSort,
  onSortChange
}) => {
  const sortFields: Array<{field: SortableField; label: string}> = [
    { field: 'work_date', label: 'Work Date' },
    { field: 'due_date', label: 'Due Date' },
    { field: 'title', label: 'Title' }
  ];

  const getIcon = (field: SortableField) => {
    switch (field) {
      case 'work_date':
      case 'due_date':
        return CalendarDays;
      default:
        return currentSort?.field === field && currentSort.direction === 'asc' ? ArrowUpAZ : ArrowDownAZ;
    }
  };

  const handleSort = (field: SortableField) => {
    if (!currentSort || currentSort.field !== field) {
      onSortChange({ field, direction: 'asc' });
    } else {
      if (currentSort.direction === 'asc') {
        onSortChange({ field, direction: 'desc' });
      } else {
        onSortChange(undefined);
      }
    }
  };

  return (
    <TooltipProvider>
      <Tooltip delayDuration={0}>
        <DropdownMenu>
          <TooltipTrigger asChild>
            <DropdownMenuTrigger asChild>
              <Button 
                variant="outline" 
                size="icon"
                className={cn(
                  "h-9 w-9",
                  currentSort && "bg-accent"
                )}
              >
                <ArrowUpDown className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
          </TooltipTrigger>
          <DropdownMenuContent align="end" className="w-48">
            {sortFields.map(({ field, label }, index) => {
              const Icon = getIcon(field);
              const isActive = currentSort?.field === field;

              return (
                <React.Fragment key={field}>
                  {index > 0 && <DropdownMenuSeparator />}
                  <DropdownMenuItem
                    className={cn(
                      "flex items-center gap-2",
                      isActive && "bg-accent"
                    )}
                    onClick={() => handleSort(field)}
                  >
                    <Icon className="h-4 w-4" />
                    <span>{label}</span>
                    {isActive && (
                      <span className="ml-auto text-xs text-muted-foreground">
                        {currentSort.direction === 'asc' ? '(A-Z)' : '(Z-A)'}
                      </span>
                    )}
                  </DropdownMenuItem>
                </React.Fragment>
              );
            })}
            {currentSort && (
              <>
                <DropdownMenuSeparator />
                <DropdownMenuItem
                  className="flex items-center gap-2 text-destructive"
                  onClick={() => onSortChange(undefined)}
                >
                  <X className="h-4 w-4" />
                  <span>Clear sorting</span>
                </DropdownMenuItem>
              </>
            )}
          </DropdownMenuContent>
        </DropdownMenu>
        <TooltipContent side="bottom">
          <p>Sort tasks</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
