import React, { useState, useEffect } from 'react';
import { cn } from "@/lib/utils";
import { format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import axios from '@/api/axiosConfig';
import { toast } from 'sonner';
import { type TaskType } from '@/types/custom-task';

interface TaskDateEditorProps {
  date: string | null;
  dateType: 'work' | 'due';
  taskId: number;
  taskType: TaskType;
  onSuccess?: (response: any) => void;
  className?: string;
  disabled?: boolean;
  variant?: 'default' | 'compact';
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  icon?: React.ReactNode;
}

const TaskDateEditor: React.FC<TaskDateEditorProps> = ({ 
  date, 
  dateType, 
  taskId, 
  taskType, 
  onSuccess, 
  className = '',
  disabled = false,
  variant = 'default',
  onClick,
  icon
}) => {
  const [localDate, setLocalDate] = useState<string | null>(date);
  const [isOpen, setIsOpen] = useState(false);

  // Update local date when prop changes
  useEffect(() => {
    setLocalDate(date);
  }, [date]);

  const mapTaskTypeToBackend = (frontendTaskType: TaskType): string => {
    const mapping: Record<TaskType, string> = {
      'custom': 'custom',
      'custom_subtask': 'custom_subtask'
    };
    return mapping[frontendTaskType] || 'other';
  };

  const getDateStatus = (date: string | null): string => {
    if (!date) return '';
    
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const dateValue = new Date(date);
    dateValue.setHours(0, 0, 0, 0);
    
    if (dateValue.getTime() === today.getTime()) {
      return 'due-today';
    } else if (dateValue < today) {
      return 'overdue';
    }
    return '';
  };

  const handleDateChange = async (selectedDate: Date | undefined) => {
    // Close the popover immediately
    setIsOpen(false);

    const formattedDate = selectedDate ? format(selectedDate, 'yyyy-MM-dd') : null;
    
    // Optimistically update local state
    setLocalDate(formattedDate);
    
    // If onSuccess is provided, call it with optimistic update
    if (onSuccess) {
      const optimisticUpdate = {
        work_date: dateType === 'work' ? formattedDate : undefined,
        due_date: dateType === 'due' ? formattedDate : undefined,
        next_work_date: dateType === 'work' ? formattedDate : undefined,
        next_due_date: dateType === 'due' ? formattedDate : undefined
      };
      onSuccess(optimisticUpdate);
    }

    try {
      let endpoint: string;
      let dateKey: string;

      if (taskType === 'custom') {
        // Check if this is a subtask by looking at the URL
        const isSubtask = window.location.pathname.includes('/subtask/');
        endpoint = isSubtask 
          ? `/custom-board/subtasks/${taskId}/dates`
          : `/custom-board/tasks/${taskId}/dates`;
        dateKey = `${dateType}_date`; // work_date or due_date
      } else {
        endpoint = `/taskboard/tasks/${mapTaskTypeToBackend(taskType)}/${taskId}/dates`;
        dateKey = dateType === 'work' ? 'next_work_date' : 'next_due_date';
      }
      
      const response = await axios.put(endpoint, { [dateKey]: formattedDate });

      if (response.status === 200) {
        // Success - no need to do anything as we already updated optimistically
      }
    } catch (error) {
      console.error('Error updating date:', error);
      toast.error('Failed to update date');
      
      // Revert to original date on error
      setLocalDate(date);
      if (onSuccess) {
        const revertUpdate = {
          work_date: dateType === 'work' ? date : undefined,
          due_date: dateType === 'due' ? date : undefined,
          next_work_date: dateType === 'work' ? date : undefined,
          next_due_date: dateType === 'due' ? date : undefined
        };
        onSuccess(revertUpdate);
      }
    }
  };

  const dateStatus = getDateStatus(date);
  const statusStyles = {
    'due-today': 'text-warning border-warning hover:border-warning focus:ring-warning/20',
    'overdue': 'text-destructive border-destructive hover:border-destructive focus:ring-destructive/20',
    '': ''
  };

  const formatDate = (date: string) => {
    if (variant === 'compact') {
      return format(new Date(date), 'MMM d'); // e.g., "Jan 15"
    }
    return format(new Date(date), 'MMM d, yyyy'); // e.g., "Jan 15, 2024"
  };

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          size={variant === 'compact' ? 'sm' : 'default'}
          className={cn(
            "justify-start text-left font-normal",
            variant === 'compact' ? "min-w-[90px] text-sm px-2 py-1" : "min-w-[140px]",
            !localDate && "text-muted-foreground",
            statusStyles[dateStatus as keyof typeof statusStyles],
            className
          )}
          disabled={disabled}
          onClick={onClick}
        >
          {(variant !== 'compact' || icon) && (
            <div className="mr-2 h-4 w-4 shrink-0">
              {icon || <CalendarIcon />}
            </div>
          )}
          <span className="truncate">
            {localDate ? formatDate(localDate) : 'Pick date'}
          </span>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start">
        <Calendar
          mode="single"
          selected={localDate ? new Date(localDate) : undefined}
          onSelect={(day) => handleDateChange(day || undefined)}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  );
};

export { TaskDateEditor };