import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '@/api/axiosConfig';
import { toast } from 'sonner';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Badge } from '@/components/ui/badge';
import { Loading } from '@/components/ui/loading';
import { ArrowLeft, Plus, Building2, Calendar, ArrowRight } from 'lucide-react';
import { format, isToday, parseISO } from 'date-fns';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { cn } from '@/lib/utils';

interface Deal {
  id: number;
  name: string;
  company: string;
  created_at: string;
  onboard_status: 'not_onboarded' | 'onboarding' | 'completed' | 'paused';
  value: number;
  data?: {
    onboarding_dato?: string;
    [key: string]: any;
  };
  onboarding?: {
    id: number;
    current_node_id: string;
    completed_nodes: string[];
    form_data: any;
    status: string;
    assigned_user: {
      id: number;
      name: string;
      email: string;
    } | null;
  };
}

export default function OnboardingNew() {
  const navigate = useNavigate();
  const [deals, setDeals] = useState<Deal[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchDeals();
  }, []);

  const fetchDeals = async () => {
    try {
      const [newDealsRes, onboardingDealsRes] = await Promise.all([
        axios.get('/onboarding/new-deals'),
        axios.get('/onboarding/current')
      ]);
      
      const allDeals = [...newDealsRes.data, ...onboardingDealsRes.data];
      
      // Sort deals by onboard_status and onboarding_dato
      const sortedDeals = allDeals.sort((a, b) => {
        // First priority: onboarding status
        if (a.onboard_status === 'onboarding' && b.onboard_status !== 'onboarding') return -1;
        if (b.onboard_status === 'onboarding' && a.onboard_status !== 'onboarding') return 1;

        // Second priority: onboarding_dato
        const dateA = a.data?.onboarding_dato ? new Date(a.data.onboarding_dato) : new Date(9999, 11, 31);
        const dateB = b.data?.onboarding_dato ? new Date(b.data.onboarding_dato) : new Date(9999, 11, 31);
        
        return dateA.getTime() - dateB.getTime();
      });

      setDeals(sortedDeals);
    } catch (error) {
      toast.error('Failed to fetch deals');
      console.error('Error fetching deals:', error);
    } finally {
      setLoading(false);
    }
  };

  const startOnboarding = async (dealId: number) => {
    try {
      const response = await axios.post(`/onboardingflow/start/${dealId}`, {}, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const { onboardingId } = response.data;
      
      // Open in a new window with a specific size
      window.open(
        `/customers/onboarding/flow/${dealId}/${onboardingId}`,
        '_blank',
        'width=1200,height=800'
      );
      
      // Refresh the deals list
      fetchDeals();
    } catch (error) {
      toast.error('Failed to start onboarding');
      console.error('Error starting onboarding:', error);
    }
  };

  const continueOnboarding = (dealId: number, onboardingId: number) => {
    window.open(
      `/customers/onboarding/flow/${dealId}/${onboardingId}`,
      '_blank',
      'width=1200,height=800'
    );
  };

  if (loading) {
    return <Loading message="Loading deals..." />;
  }

  return (
    <div className="p-8">
      <div className="mb-8 space-y-4">
        <Button
          variant="ghost"
          onClick={() => navigate('/customers/onboarding')}
          className="mb-4"
        >
          <ArrowLeft className="h-4 w-4 mr-2" />
          Back to Dashboard
        </Button>
        
        <div>
          <h1 className="text-3xl font-bold text-foreground">Onboardings</h1>
          <p className="text-muted-foreground">
            Start onboarding process for customers
          </p>
        </div>
      </div>

      <Card>
        <CardHeader>
          <CardTitle className="text-xl font-semibold">New Onboardings</CardTitle>
          <CardDescription>
            Deals that are ready to start onboarding. Click on a deal to begin its onboarding process.
          </CardDescription>
        </CardHeader>
        <CardContent>
          {deals.length === 0 ? (
            <div className="text-center text-muted-foreground">
              No new deals waiting to start onboarding.
            </div>
          ) : (
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Customer</TableHead>
                  <TableHead>Value</TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead>Assigned To</TableHead>
                  <TableHead>Onboarding Date</TableHead>
                  <TableHead>Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {deals.map((deal) => (
                  <TableRow key={deal.id}>
                    <TableCell className="font-medium">
                      <div className="space-y-1">
                        <div>{deal.name}</div>
                        <div className="flex items-center text-sm text-muted-foreground">
                          <Building2 className="h-3 w-3 mr-1" />
                          {deal.company}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      ${deal.value.toLocaleString()}
                    </TableCell>
                    <TableCell>
                      <Badge 
                        variant={
                          deal.onboard_status === 'not_onboarded' ? 'secondary' :
                          deal.onboard_status === 'onboarding' ? 'default' :
                          deal.onboard_status === 'completed' ? 'success' :
                          deal.onboard_status === 'paused' ? 'warning' :
                          'secondary'
                        }
                      >
                        {deal.onboard_status === 'not_onboarded' ? 'Not Started' :
                         deal.onboard_status === 'onboarding' ? 'In Progress' :
                         deal.onboard_status === 'completed' ? 'Completed' :
                         deal.onboard_status === 'paused' ? 'Paused' :
                         'Unknown Status'}
                      </Badge>
                    </TableCell>
                    <TableCell>
                      {deal.onboarding?.assigned_user?.name || 'Not Assigned'}
                    </TableCell>
                    <TableCell>
                      <div className={cn(
                        "flex items-center text-muted-foreground",
                        deal.data?.onboarding_dato && isToday(parseISO(deal.data.onboarding_dato)) && 
                        "bg-primary/10 text-primary font-medium rounded-md px-2 py-1"
                      )}>
                        <Calendar className="h-4 w-4 mr-2" />
                        {deal.data?.onboarding_dato ? 
                          format(parseISO(deal.data.onboarding_dato), 'PPP') : 
                          'Not set'}
                      </div>
                    </TableCell>
                    <TableCell>
                      {deal.onboard_status === 'not_onboarded' || deal.onboard_status === 'onboarding' ? (
                        <div className="flex gap-2">
                          <Button
                            onClick={() => navigate(`/customers/onboarding/temp/${deal.id}`)}
                            className="space-x-2"
                            variant="secondary"
                          >
                            <Plus className="h-4 w-4" />
                            <span>{deal.onboard_status === 'onboarding' ? 'Continue Onboarding' : 'Temporary Onboarding'}</span>
                          </Button>
                          {deal.onboard_status === 'not_onboarded' && (
                            <TooltipProvider>
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <div>
                                    <Button
                                      className="space-x-2"
                                      disabled
                                    >
                                      <Plus className="h-4 w-4" />
                                      <span>Start Onboarding</span>
                                    </Button>
                                  </div>
                                </TooltipTrigger>
                                <TooltipContent>
                                  <p>Coming soon</p>
                                </TooltipContent>
                              </Tooltip>
                            </TooltipProvider>
                          )}
                        </div>
                      ) : null}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </CardContent>
      </Card>
    </div>
  );
} 