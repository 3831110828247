import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import * as z from "zod"
import { toast } from 'sonner'
import axios from '@/api/axiosConfig'
import { useEffect } from 'react'
import { useFieldArray } from "react-hook-form"
import { X } from "lucide-react"

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogBody,
  DialogFooter,
} from "@/components/ui/dialog"
import { Button } from "@/components/ui/button"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { DatePicker } from "@/components/ui/date/date-picker"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"

import { dealProductConfig, type DealProduct as ConfigProduct } from '@/configs/DealProductConfig'
import type { Product } from '@/types/customers'

const formSchema = z.object({
  name: z.string().min(1, "Name is required"),
  code: z.string().min(1, "Code is required"),
  item_price: z.number().min(0, "Price must be positive"),
  quantity: z.number().min(1, "Quantity must be at least 1"),
  billing_start_date: z.date().optional(),
  billing_frequency: z.string().refine(val => ['monthly', 'quarterly', 'annually', 'one-time'].includes(val), {
    message: "Billing frequency must be monthly, quarterly, annually, or one-time"
  })
})

interface EditProductDialogProps {
  open: boolean
  onOpenChange: (open: boolean) => void
  product: Product
  onProductUpdated: () => void
}

const handleNumberInput = (
  e: React.ChangeEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>,
  field: any,
  type: 'keydown' | 'change'
) => {
  if (type === 'keydown') {
    const keyEvent = e as React.KeyboardEvent<HTMLInputElement>
    if (keyEvent.key === '.' || keyEvent.key === ',') {
      keyEvent.preventDefault()
      const input = keyEvent.target as HTMLInputElement
      const value = input.value
      const position = input.selectionStart || 0
      
      if (!value.includes(',')) {
        const newValue = value.slice(0, position) + ',' + value.slice(position)
        input.value = newValue
        field.onChange(parseFloat(newValue.replace(',', '.')))
        setTimeout(() => input.setSelectionRange(position + 1, position + 1))
      }
    }
  } else {
    const changeEvent = e as React.ChangeEvent<HTMLInputElement>
    const value = changeEvent.target.value.replace('.', ',')
    const numericValue = parseFloat(value.replace(',', '.'))
    field.onChange(numericValue)
  }
}

export function EditProductDialog({ 
  open, 
  onOpenChange, 
  product,
  onProductUpdated 
}: EditProductDialogProps) {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  })

  useEffect(() => {
    if (open && product) {
      const billingStartDate = product.billing_start_date 
        ? new Date(Date.UTC(
            new Date(product.billing_start_date).getUTCFullYear(),
            new Date(product.billing_start_date).getUTCMonth(),
            new Date(product.billing_start_date).getUTCDate(),
            12, 0, 0
          ))
        : undefined

      form.reset({
        name: product.name,
        code: product.code,
        item_price: product.item_price,
        quantity: product.quantity,
        billing_start_date: billingStartDate,
        billing_frequency: product.billing_frequency
      })
    } else if (!open) {
      form.reset()
    }
  }, [open, product, form])

  const handleUpdate = async (values: z.infer<typeof formSchema>) => {
    try {
      const formattedValues = {
        ...values,
        billing_start_date: values.billing_start_date 
          ? new Date(Date.UTC(
              values.billing_start_date.getUTCFullYear(),
              values.billing_start_date.getUTCMonth(),
              values.billing_start_date.getUTCDate(),
              12, 0, 0
            )).toISOString().split('T')[0]
          : null
      }
      
      await axios.put(`/account/products/${product.id}`, formattedValues)
      toast.success('Product updated successfully')
      onOpenChange(false)
      onProductUpdated()
    } catch (error) {
      console.error('Error updating product:', error)
      toast.error('Failed to update product')
    }
  }

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit Product</DialogTitle>
          <DialogDescription className="sr-only">Edit product details</DialogDescription>
        </DialogHeader>
        <DialogBody>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(handleUpdate)} className="space-y-4">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Name</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="code"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Code</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="item_price"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Price (DKK)</FormLabel>
                    <FormControl>
                      <Input 
                        type="number" 
                        {...field}
                        onChange={e => handleNumberInput(e, field, 'change')}
                        onKeyDown={e => handleNumberInput(e, field, 'keydown')}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="quantity"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Quantity</FormLabel>
                    <FormControl>
                      <Input 
                        type="number" 
                        {...field}
                        onChange={e => field.onChange(parseInt(e.target.value))}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="billing_start_date"
                render={({ field }) => (
                  <FormItem className="mt-4">
                    <FormLabel>Billing Start Date</FormLabel>
                    <div className="pt-1">
                      <FormControl>
                        <DatePicker
                          date={field.value}
                          onSelect={field.onChange}
                          variant="short"
                        />
                      </FormControl>
                    </div>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="billing_frequency"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Billing Frequency</FormLabel>
                    <Select
                      value={field.value}
                      onValueChange={field.onChange}
                    >
                      <SelectTrigger>
                        <SelectValue />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="monthly">Monthly</SelectItem>
                        <SelectItem value="quarterly">Quarterly</SelectItem>
                        <SelectItem value="annually">Annually</SelectItem>
                        <SelectItem value="one-time">One-time</SelectItem>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </form>
          </Form>
        </DialogBody>
        <DialogFooter>
          <Button onClick={form.handleSubmit(handleUpdate)}>Save Changes</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

interface AddProductDialogProps {
  open: boolean
  onOpenChange: (open: boolean) => void
  dealId: number
  onProductAdded: () => void
}

export function AddProductDialog({ 
  open, 
  onOpenChange, 
  dealId,
  onProductAdded 
}: AddProductDialogProps) {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      code: "",
      quantity: 1,
      item_price: 0,
      billing_frequency: "monthly",
      billing_start_date: new Date()
    }
  })

  const selectedProduct = dealProductConfig.products.find(
    (p: ConfigProduct) => p.name === form.watch('name')
  )

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      if (!values.billing_start_date) {
        throw new Error('Billing start date is required')
      }

      const formattedData = {
        ...values,
        billing_start_date: values.billing_start_date.toISOString().split('T')[0]
      }
      
      await axios.post(`/product/deals/${dealId}/products`, formattedData)
      toast.success('Product added successfully')
      onProductAdded()
      onOpenChange(false)
    } catch (error) {
      console.error('Error adding product:', error)
      toast.error('Failed to add product')
    }
  }

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add Product</DialogTitle>
          <DialogDescription className="sr-only">Add a new product</DialogDescription>
        </DialogHeader>
        <DialogBody>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Product</FormLabel>
                    <Select
                      value={field.value}
                      onValueChange={(value) => {
                        field.onChange(value)
                        const product = dealProductConfig.products.find((p: ConfigProduct) => p.name === value)
                        if (product) {
                          form.setValue('code', product.code)
                          form.setValue('item_price', product.defaultPrice)
                        }
                      }}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Select a product" />
                      </SelectTrigger>
                      <SelectContent>
                        {dealProductConfig.products.map((product: ConfigProduct) => (
                          <SelectItem key={product.code} value={product.name}>
                            {product.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="quantity"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Quantity</FormLabel>
                    <Input 
                      type="number" 
                      {...field}
                      onChange={e => field.onChange(parseInt(e.target.value))}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="item_price"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Price (DKK)</FormLabel>
                    <Input 
                      type="number" 
                      {...field}
                      onChange={e => handleNumberInput(e, field, 'change')}
                      onKeyDown={e => handleNumberInput(e, field, 'keydown')}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="billing_frequency"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Billing Frequency</FormLabel>
                    <Select
                      value={field.value}
                      onValueChange={field.onChange}
                    >
                      <SelectTrigger>
                        <SelectValue />
                      </SelectTrigger>
                      <SelectContent>
                        {selectedProduct && selectedProduct.frequencies && selectedProduct.frequencies.length > 0 ? (
                          selectedProduct.frequencies.map((frequency: string) => (
                            <SelectItem key={frequency} value={frequency}>
                              {frequency.charAt(0).toUpperCase() + frequency.slice(1)}
                            </SelectItem>
                          ))
                        ) : (
                          <>
                            <SelectItem value="monthly">Monthly</SelectItem>
                            <SelectItem value="quarterly">Quarterly</SelectItem>
                            <SelectItem value="annually">Annually</SelectItem>
                            <SelectItem value="one-time">One-time</SelectItem>
                          </>
                        )}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="billing_start_date"
                render={({ field }) => (
                  <FormItem className="mt-4">
                    <FormLabel>Billing Start Date</FormLabel>
                    <div className="pt-1">
                      <FormControl>
                        <DatePicker
                          date={field.value}
                          onSelect={field.onChange}
                          variant="short"
                        />
                      </FormControl>
                    </div>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </form>
          </Form>
        </DialogBody>
        <DialogFooter>
          <Button onClick={form.handleSubmit(onSubmit)}>Add Product</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

interface HistoricalInvoiceLine {
  product_id?: number
  product_name: string
  product_code: string
  quantity: number
  unit_price: number
  total_amount: number
}

interface HistoricalInvoiceFormValues {
  invoice_number: string
  economic_invoice_id: number
  invoice_date: Date
  due_date: Date
  lines: HistoricalInvoiceLine[]
}

const historicalInvoiceFormSchema = z.object({
  invoice_number: z.string().min(1, "Invoice number is required"),
  economic_invoice_id: z.number().min(1, "Economic invoice ID is required"),
  invoice_date: z.date(),
  due_date: z.date(),
  lines: z.array(z.object({
    product_id: z.number().optional(),
    product_name: z.string().min(1, "Product name is required"),
    product_code: z.string().min(1, "Product code is required"),
    quantity: z.number().min(1, "Quantity must be at least 1"),
    unit_price: z.number().min(0, "Price must be positive"),
    total_amount: z.number().min(0, "Total must be positive")
  })).min(1, "At least one product is required")
})

interface AddHistoricalInvoiceDialogProps {
  open: boolean
  onOpenChange: (open: boolean) => void
  dealId: number
  dealProducts: Product[]
  onInvoiceAdded: () => void
}

export function AddHistoricalInvoiceDialog({ 
  open, 
  onOpenChange, 
  dealId,
  dealProducts,
  onInvoiceAdded 
}: AddHistoricalInvoiceDialogProps) {
  const form = useForm<HistoricalInvoiceFormValues>({
    resolver: zodResolver(historicalInvoiceFormSchema),
    defaultValues: {
      invoice_number: "",
      economic_invoice_id: 0,
      invoice_date: new Date(),
      due_date: new Date(),
      lines: []
    }
  })

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "lines"
  })

  const onSubmit = async (values: HistoricalInvoiceFormValues) => {
    try {
      await axios.post('/invoice/add-historical-booked', {
        ...values,
        deal_id: dealId,
        invoice_date: values.invoice_date.toISOString().split('T')[0],
        due_date: values.due_date.toISOString().split('T')[0]
      })
      toast.success('Historical invoice added successfully')
      onOpenChange(false)
      onInvoiceAdded()
    } catch (error) {
      console.error('Error adding historical invoice:', error)
      toast.error('Failed to add historical invoice')
    }
  }

  const calculateTotalAmount = (values: HistoricalInvoiceFormValues) => {
    return values.lines.reduce((sum: number, line: HistoricalInvoiceLine) => sum + line.total_amount, 0)
  }

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-3xl">
        <DialogHeader>
          <DialogTitle>Add Historical Invoice</DialogTitle>
          <DialogDescription className="sr-only">Add a historical booked invoice</DialogDescription>
        </DialogHeader>
        <DialogBody>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
              <div className="grid grid-cols-2 gap-4">
                <FormField
                  control={form.control}
                  name="invoice_number"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Invoice Number</FormLabel>
                      <Input {...field} />
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="economic_invoice_id"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Economic Invoice ID</FormLabel>
                      <Input 
                        type="number" 
                        {...field}
                        onChange={(e) => field.onChange(Number(e.target.value))}
                      />
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="invoice_date"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Invoice Date</FormLabel>
                      <DatePicker
                        date={field.value}
                        onSelect={field.onChange}
                        variant="short"
                      />
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="due_date"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Due Date</FormLabel>
                      <DatePicker
                        date={field.value}
                        onSelect={field.onChange}
                        variant="short"
                      />
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div className="space-y-4">
                <div className="flex justify-between items-center">
                  <h4 className="text-sm font-medium">Invoice Lines</h4>
                  <Button
                    type="button"
                    variant="outline"
                    size="sm"
                    onClick={() => append({
                      product_name: "",
                      product_code: "",
                      quantity: 1,
                      unit_price: 0,
                      total_amount: 0
                    })}
                  >
                    Add Line
                  </Button>
                </div>

                {fields.map((field, index) => (
                  <div key={field.id} className="grid grid-cols-6 gap-4 items-end border p-4 rounded-lg">
                    <div className="col-span-2">
                      <FormField
                        control={form.control}
                        name={`lines.${index}.product_name`}
                        render={({ field: productField }) => (
                          <FormItem>
                            <FormLabel>Product</FormLabel>
                            <Select
                              value={productField.value}
                              onValueChange={(value) => {
                                const product = value === 'custom' ? null : dealProducts.find(p => p.name === value)
                                if (product) {
                                  form.setValue(`lines.${index}.product_id`, product.id)
                                  form.setValue(`lines.${index}.product_name`, product.name)
                                  form.setValue(`lines.${index}.product_code`, product.code)
                                  form.setValue(`lines.${index}.unit_price`, product.item_price)
                                  form.setValue(`lines.${index}.total_amount`, product.item_price * form.getValues(`lines.${index}.quantity`))
                                } else {
                                  // Clear fields for custom product
                                  form.setValue(`lines.${index}.product_id`, undefined)
                                  form.setValue(`lines.${index}.product_name`, '')
                                  form.setValue(`lines.${index}.product_code`, '')
                                  form.setValue(`lines.${index}.unit_price`, 0)
                                  form.setValue(`lines.${index}.total_amount`, 0)
                                }
                                productField.onChange(value)
                              }}
                            >
                              <SelectTrigger>
                                <SelectValue placeholder="Select a product" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="custom">Custom Product</SelectItem>
                                {dealProducts.map((product) => (
                                  <SelectItem key={product.id} value={product.name}>
                                    {product.name}
                                  </SelectItem>
                                ))}
                              </SelectContent>
                            </Select>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>
                    <FormField
                      control={form.control}
                      name={`lines.${index}.product_code`}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Code</FormLabel>
                          <Input {...field} />
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name={`lines.${index}.quantity`}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Quantity</FormLabel>
                          <Input 
                            type="number" 
                            {...field}
                            onChange={(e) => {
                              const quantity = Number(e.target.value)
                              field.onChange(quantity)
                              const unitPrice = form.getValues(`lines.${index}.unit_price`)
                              form.setValue(`lines.${index}.total_amount`, quantity * unitPrice)
                            }}
                          />
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name={`lines.${index}.unit_price`}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Unit Price</FormLabel>
                          <Input 
                            type="number" 
                            {...field}
                            onChange={(e) => handleNumberInput(e, field, 'change')}
                            onKeyDown={e => handleNumberInput(e, field, 'keydown')}
                          />
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name={`lines.${index}.total_amount`}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Total</FormLabel>
                          <Input 
                            type="number" 
                            {...field}
                            disabled
                          />
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <Button
                      type="button"
                      variant="ghost"
                      size="icon"
                      onClick={() => remove(index)}
                    >
                      <X className="h-4 w-4" />
                    </Button>
                  </div>
                ))}
              </div>

              <div className="flex justify-end gap-4 text-lg">
                <span className="font-semibold">Total (excl. VAT):</span>
                <span className="font-semibold tabular-nums">
                  {calculateTotalAmount(form.getValues()).toLocaleString('da-DK')} DKK
                </span>
              </div>
            </form>
          </Form>
        </DialogBody>
        <DialogFooter>
          <Button onClick={form.handleSubmit(onSubmit)}>
            Add Historical Invoice
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
} 