import React, { useState, useEffect, useCallback, useMemo, forwardRef, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import axios from '@/api/axiosConfig';
import { toast } from 'sonner';
import { cn } from '@/lib/utils';
import { Skeleton } from "@/components/ui/skeleton";
import { useUsers } from '@/contexts/UserContext';
import { buttonVariants } from "@/components/ui/button";

import {
  DndContext,
  DragOverlay,
  PointerSensor,
  useSensor,
  useSensors,
  DragStartEvent,
  DragEndEvent,
  TouchSensor,
  closestCenter,
  UniqueIdentifier,
  useDroppable,
  useDraggable,
  DragOverEvent,
  pointerWithin,
  rectIntersection,
  getFirstCollision,
  CollisionDetection,
  MeasuringStrategy,
} from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import {
  SortableContext,
  verticalListSortingStrategy,
  useSortable,
  arrayMove,
} from '@dnd-kit/sortable';

// Lucide Icons
import { 
  Eye, 
  MessageSquare, 
  Clock, 
  Calendar,
  Briefcase,
  Edit,
  Plus,
  Minus,
  X,
  ChevronDown,
  ChevronUp,
  Trash2,
  ListTodo,
  UserPlus,
} from 'lucide-react';

// Shadcn Components
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { getTextColor } from "@/lib/color-utils";
import { Card, CardContent } from "@/components/ui/card";

// Custom Components
import { TaskDateEditor } from '@/components/TaskDateEditor';
import { TaskNotes } from '../custom/modals/TaskNotes';
import { TaskCompleteDrawer } from '../drawers/TaskCompleteDrawer';
import { AssigneeSelection } from '@/modals/AssigneeSelection';
import { DealDrawer } from '@/drawers/DealDrawer';
import { KanbanCard, KanbanCardHeader, KanbanCardContent, KANBAN_COLUMN_WIDTH, KANBAN_CARD_WIDTH } from "@/components/ui/specialized/kanban-card"
import { TaskView } from './TaskView';
import { AvatarGroup } from "@/components/ui/avatars/avatar-group";

// Types
import type { CustomTask } from '@/types/custom-task';
import type { StoredUser } from '@/types/user';
import type { BoardStatusOption, Board, SubTask } from '@/types/custom-board';
import type { DealDataConfigItem } from '@/types/customers';
import type { CardConfig } from '@/taskboards/config/card';
import { TaskBadge } from "@/components/ui/specialized/task-badge";
import type { ColorScale } from '@/configs/DealDataConfig';
import EditTaskModal from '../custom/modals/EditTaskModal';
import CreateSubTask from '../modals/CreateSubTask';

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

import {
  Check,
} from "lucide-react";

import ExpandedSubtasks from '../components/ExpandedSubtasks';
import { LoadingSpinner } from '@/components/ui/loading-spinner';

import { SortableTaskCard } from '../components/SortableTaskCard';
import { ScrollArea } from "@/components/ui/scroll-area";

dayjs.extend(utc);
dayjs.extend(timezone);

const ENABLE_REALTIME = false; // Toggle this to enable/disable real-time updates

// Update the kanban styles
const kanbanStyles = {
  board: "flex gap-3",
  columnContent: "h-full overflow-y-auto scrollbar-thin scrollbar-thumb-secondary scrollbar-track-secondary/20",
  columnCount: "bg-white/20 rounded-full px-2 py-1 text-xs",
  column: "h-full flex-none",
};

// Update ForwardedBadge component
const ForwardedBadge = forwardRef<HTMLDivElement, React.ComponentProps<typeof Badge>>(
  ({ className, style = {}, ...props }, ref) => {
    if (style.backgroundColor) {
      style.color = getTextColor(style.backgroundColor as string);
    }
    return <Badge className={className} style={style} {...props} />;
  }
);
ForwardedBadge.displayName = 'ForwardedBadge';

const DroppableColumn: React.FC<{
  id: string;
  children: React.ReactNode;
  className?: string;
  tasks: CustomTask[];
  loading?: boolean;
  status: BoardStatusOption;
  taskType: 'custom' | 'custom_subtask';
}> = ({ id, children, className, tasks, loading, status, taskType }) => {
  const { isOver, setNodeRef } = useDroppable({ 
    id: `status-${status.id}`,
    data: {
      type: 'column',
      status: status
    }
  });

  return (
    <div 
      ref={setNodeRef} 
      className={cn(kanbanStyles.column)}
      style={{ width: KANBAN_COLUMN_WIDTH }}
    >
      <KanbanCard 
        className={cn(
          isOver && "ring-2 ring-primary ring-inset",
          "h-full flex flex-col rounded-lg"
        )}
      >
        <KanbanCardHeader 
          className="flex-none py-1.5"
          color={status.color}
        >
          <div className="flex items-center gap-2">
            {status.description ? (
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <span className="cursor-help">{status.label}</span>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>{status.description}</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            ) : (
              status.label
            )}
            <span className={kanbanStyles.columnCount}>
              {loading ? '...' : tasks.length}
            </span>
          </div>
        </KanbanCardHeader>
        <KanbanCardContent
          className={cn(
            "flex-1 overflow-hidden",
            className
          )}
        >
          <div className="h-full overflow-y-auto px-0 py-0">
            {loading ? (
              <div className="space-y-2">
                {Array.from({ length: 2 }).map((_, index) => (
                  <TaskCardSkeleton key={`skeleton-${index}`} />
                ))}
              </div>
            ) : (
              <div className="space-y-2">
                {children}
              </div>
            )}
          </div>
        </KanbanCardContent>
      </KanbanCard>
    </div>
  );
};

// Add this component for task card skeletons
const TaskCardSkeleton = () => {
  return (
    <div className="my-2 px-2">
      <Card className="w-full p-4">
        <div className="space-y-4">
          {/* Deal badge skeleton */}
          <div className="flex flex-col items-center text-center">
            <Skeleton className="h-6 w-32" /> {/* Deal name */}
            
            {/* Title skeleton */}
            <div className="mt-2 space-y-2">
              <Skeleton className="h-4 w-[80%]" />
              <Skeleton className="h-4 w-[60%]" />
            </div>
            
            {/* Frequency badge skeleton */}
            <Skeleton className="mt-2 h-5 w-20" />
          </div>

          {/* Date buttons skeleton */}
          <div className="grid grid-cols-2 gap-2">
            <Skeleton className="h-9 w-full rounded-md" />
            <Skeleton className="h-9 w-full rounded-md" />
          </div>

          {/* Tags skeleton */}
          <div className="flex justify-center gap-2">
            <Skeleton className="h-5 w-16 rounded-full" />
            <Skeleton className="h-5 w-16 rounded-full" />
          </div>

          {/* Footer skeleton */}
          <div className="flex justify-between items-center">
            {/* Avatars skeleton */}
            <div className="flex -space-x-2">
              <Skeleton className="h-8 w-8 rounded-full" />
              <Skeleton className="h-8 w-8 rounded-full" />
            </div>
            
            {/* Action buttons skeleton */}
            <div className="flex gap-2">
              <Skeleton className="h-8 w-8 rounded-md" />
              <Skeleton className="h-8 w-8 rounded-md" />
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

// Update the KanbanColumnSkeleton component to have rounded corners
export const KanbanColumnSkeleton = ({ className }: { className?: string }) => {
  return (
    <div className={cn(kanbanStyles.column, className)}>
      <KanbanCard className="h-full flex flex-col rounded-lg">
        <KanbanCardHeader className="flex-none py-1.5">
          <div className="flex items-center gap-2">
            <Skeleton className="h-5 w-24" /> {/* Column title */}
            <Skeleton className="h-5 w-6 rounded-full" /> {/* Count badge */}
          </div>
        </KanbanCardHeader>
        <KanbanCardContent className="flex-1 overflow-hidden">
          <div className="h-full overflow-y-auto px-0 py-0">
            <div className="space-y-2">
              {Array.from({ length: 2 }).map((_, index) => (
                <TaskCardSkeleton key={`skeleton-${index}`} />
              ))}
            </div>
          </div>
        </KanbanCardContent>
      </KanbanCard>
    </div>
  );
};

export interface TaskKanbanProps {
  tasks: CustomTask[];
  onTaskUpdate: (taskId: string, updatedFields: Partial<CustomTask>) => Promise<void>;
  boardStatusOptions: BoardStatusOption[];
  cardConfig: CardConfig;
  dealDataConfig: DealDataConfigItem[];
  dealSoftwareConfig: DealDataConfigItem[];
  dealOnboardingConfig: DealDataConfigItem[];
  taskType: 'custom' | 'custom_subtask';
  setTasks: React.Dispatch<React.SetStateAction<CustomTask[]>>;
  loading: boolean;
  onAssigneeUpdate: (taskId: number, newAssignees: string[]) => Promise<void>;
  board: Board | null;
  isKanbanReversed?: boolean;
  onSubtaskClick?: (taskId: number, subtaskId: number) => void;
  isBoardConfigLoading?: boolean;
  isCollapsed?: boolean;
  users: StoredUser[];
  onLocalTasksChange?: (tasks: CustomTask[]) => void;
}

export function TaskKanban({
  tasks,
  onTaskUpdate,
  boardStatusOptions,
  cardConfig,
  dealDataConfig,
  dealSoftwareConfig,
  dealOnboardingConfig,
  taskType,
  setTasks,
  onAssigneeUpdate,
  loading = true,
  board,
  isKanbanReversed = false,
  onSubtaskClick,
  isBoardConfigLoading = true,
  isCollapsed = false,
  users,
  onLocalTasksChange
}: TaskKanbanProps) {
  const { users: contextUsers } = useUsers();
  const [localTasks, setLocalTasks] = useState<CustomTask[]>(tasks);
  const navigate = useNavigate();
  const [selectedTask, setSelectedTask] = useState<CustomTask | null>(null);
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);
  const [selectedTaskForNotes, setSelectedTaskForNotes] = useState<CustomTask | null>(null);
  const [isCompleteDrawerOpen, setIsCompleteDrawerOpen] = useState(false);
  const [completedTask, setCompletedTask] = useState<CustomTask | null>(null);
  const [isAssigneeModalOpen, setIsAssigneeModalOpen] = useState(false);
  const [selectedTaskForAssignee, setSelectedTaskForAssignee] = useState<CustomTask | null>(null);
  const [isDealDrawerOpen, setIsDealDrawerOpen] = useState(false);
  const [selectedDealId, setSelectedDealId] = useState<number | null>(null);
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [activeTask, setActiveTask] = useState<CustomTask | null>(null);
  const [isTaskViewOpen, setIsTaskViewOpen] = useState(false);
  const [taskViewTask, setTaskViewTask] = useState<CustomTask | null>(null);
  const [originalTaskState, setOriginalTaskState] = useState<CustomTask | null>(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedTaskForEdit, setSelectedTaskForEdit] = useState<CustomTask | null>(null);
  const [kanbanColumns, setKanbanColumns] = useState<BoardStatusOption[]>(boardStatusOptions);
  const [isEditTaskModalOpen, setIsEditTaskModalOpen] = useState(false);
  const [selectedTaskForNormalEdit, setSelectedTaskForNormalEdit] = useState<CustomTask | null>(null);
  const [showSubtasks, setShowSubtasks] = useState<Record<string, boolean>>({});
  const [isCreateSubTaskModalOpen, setIsCreateSubTaskModalOpen] = useState(false);
  const [selectedTaskForSubtask, setSelectedTaskForSubtask] = useState<CustomTask | null>(null);
  const [taskToDelete, setTaskToDelete] = useState<CustomTask | null>(null);
  const [lastDraggedOverId, setLastDraggedOverId] = useState<UniqueIdentifier | null>(null);

  // Add ref for the ScrollArea viewport
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  // Add keyboard navigation
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      // Only handle if not in an input/textarea
      if (
        e.target instanceof HTMLInputElement || 
        e.target instanceof HTMLTextAreaElement ||
        e.target instanceof HTMLSelectElement
      ) {
        return;
      }

      const scrollContainer = document.getElementById('kanban-scroll-container');
      if (!scrollContainer) {
        console.log('No scroll container found');
        return;
      }

      const scrollAmount = 400;

      switch (e.key) {
        case 'ArrowLeft':
          e.preventDefault();
          scrollContainer.scrollBy({
            left: -scrollAmount,
            behavior: 'smooth'
          });
          break;
        case 'ArrowRight':
          e.preventDefault();
          scrollContainer.scrollBy({
            left: scrollAmount,
            behavior: 'smooth'
          });
          break;
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, []);

  // Update when tasks change
  useEffect(() => {
    // Only update local tasks if they're different from current tasks
    const tasksHaveChanged = JSON.stringify(tasks) !== JSON.stringify(localTasks);
    if (tasksHaveChanged) {
      setLocalTasks(tasks);
      onLocalTasksChange?.(tasks);
    }
  }, [tasks]);

  useEffect(() => {
    if (taskType === 'custom' && board?.board_config) {
      const { kanban_source, progress_status_options, board_status_options } = board.board_config;
      
      // Set the appropriate status options based on kanban_source and isKanbanReversed
      let newColumns;
      if (isKanbanReversed) {
        // When reversed, use the opposite of what's configured as the source
        newColumns = kanban_source === 'progress' ? board_status_options || [] : progress_status_options || [];
      } else {
        // Use the configured source
        newColumns = kanban_source === 'progress' ? progress_status_options || [] : board_status_options || [];
      }
      
      setKanbanColumns(newColumns);
    } else {
      // For non-custom boards, use the provided boardStatusOptions
      setKanbanColumns(boardStatusOptions);
    }
  }, [taskType, board?.board_config, boardStatusOptions, isKanbanReversed]);

  // Update the collision detection strategy
  const collisionDetectionStrategy: CollisionDetection = useCallback((args) => {
    // Use rectIntersection instead of pointerWithin for more accurate column detection
    const intersections = rectIntersection(args);
    
    // If we have intersections
    if (intersections.length > 0) {
      // Get all column collisions (items that start with 'status-')
      const columnCollisions = intersections.filter(
        collision => String(collision.id).startsWith('status-')
      );

      // Return the first column collision if any
      if (columnCollisions.length > 0) {
        return [columnCollisions[0]];
      }
    }

    return intersections;
  }, []);

  // Move all status change logic to handleDragEnd
  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;
    setActiveId(null);
    setActiveTask(null);
    
    if (!over) return;

    // Extract the status ID from the over.id (remove the 'status-' prefix)
    const targetStatusId = Number(over.id.toString().replace('status-', ''));
    const targetStatus = kanbanColumns.find(col => col.id === targetStatusId);
    
    if (!targetStatus) {
      console.error('Target status not found:', targetStatusId);
      return;
    }

    const taskId = active.id;
    const task = localTasks.find(t => t.id.toString() === taskId.toString());
    
    if (!task) {
      console.error('Task not found:', taskId);
      return;
    }

    try {
      // Store original task state for rollback
      const originalTask = { ...task };

      // Create updated task with new status
      const updatedTask = {
        ...task,
        ...(isKanbanReversed 
          ? board?.board_config?.kanban_source === 'progress'
            ? { board_status_id: targetStatus.id }
            : { progress_status_id: targetStatus.id }
          : board?.board_config?.kanban_source === 'progress'
            ? { progress_status_id: targetStatus.id }
            : { board_status_id: targetStatus.id }
        )
      };

      // Update local state immediately for optimistic update
      const updatedTasks = localTasks.map(t => 
        t.id.toString() === taskId.toString() ? updatedTask : t
      );
      setLocalTasks(updatedTasks);

      // Notify parent about local changes immediately
      onLocalTasksChange?.(updatedTasks);

      // Make the API call
      await onTaskUpdate(taskId.toString(), {
        ...(isKanbanReversed
          ? board?.board_config?.kanban_source === 'progress'
            ? { board_status_id: targetStatus.id }
            : { progress_status_id: targetStatus.id }
          : board?.board_config?.kanban_source === 'progress'
            ? { progress_status_id: targetStatus.id }
            : { board_status_id: targetStatus.id }
        )
      });

    } catch (error) {
      console.error('Error updating task status:', error);
      toast.error('Failed to update task status');

      // On error, revert local state to original
      const revertedTasks = localTasks.map(t => 
        t.id.toString() === taskId.toString() ? task : t
      );
      setLocalTasks(revertedTasks);

      // Notify parent about reverting changes
      onLocalTasksChange?.(revertedTasks);
    }
  };

  // Update the sensors configuration
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 3, // Reduced from 8px to 3px for easier activation
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250, // Increased from 200ms to 250ms for better touch handling
        tolerance: 5,
      },
    })
  );

  // Convert custom board status option to standard board status option
  const convertToStandardBoardStatus = (opt: BoardStatusOption): BoardStatusOption => ({
    id: opt.id,
    value: opt.value ?? '',
    label: opt.label ?? '',
    color: opt.color ?? '',
    description: opt.description ?? null,
    order: opt.order
  });

  // Determine which status options to use for kanban columns
  const getKanbanStatusOptions = (): BoardStatusOption[] => {
    if (taskType === 'custom' && board?.board_config) {
      const { kanban_source, progress_status_options, board_status_options } = board.board_config;
      
      let options;
      if (isKanbanReversed) {
        // When reversed, use the opposite of what's configured as the source
        options = kanban_source === 'progress' ? board_status_options : progress_status_options;
      } else {
        // Use the configured source
        options = kanban_source === 'progress' ? progress_status_options : board_status_options;
      }

      return options || [];
    }
    return boardStatusOptions;
  };

  const kanbanStatusOptions = getKanbanStatusOptions();

  const getTasksForColumn = (columnId: number) => {
    if (!localTasks) return [];
    
    return localTasks.filter(task => {
      if (!task) return false;
      
      const statusId = isKanbanReversed
        ? board?.board_config?.kanban_source === 'progress'
          ? task.board_status_id
          : task.progress_status_id
        : board?.board_config?.kanban_source === 'progress'
          ? task.progress_status_id
          : task.board_status_id;
      
      return statusId === columnId;
    });
  };

  const getColumnColor = (status: string): string => {
    const option = boardStatusOptions.find(opt => opt.id.toString() === status);
    return option?.color || '#e5e7eb';
  };

  const handleOpenTask = (taskId: number) => {
    const task = localTasks.find(t => t.id === taskId);
    if (task) {
      navigate(`/custom-boards/${board?.id}/task/${taskId}`, { 
        state: { 
          tasks: localTasks,
          taskType,
          selectedTask: task 
        }
      });
    }
  };

  const handleEditClick = (task: CustomTask) => {
    setSelectedTask(task);
    setIsEditModalOpen(true);
  };

  const getTagColor = (key: string, value: any): string => {
    return '#e5e7eb';
  };

  const mapFrequencyToLabel = (frequency?: { freq: string }): string => {
    if (!frequency?.freq) return 'N/A';
    const mapping: Record<string, string> = {
      DAILY: 'Daily',
      WEEKLY: 'Weekly',
      MONTHLY: 'Monthly',
      QUARTERLY: 'Quarterly',
      YEARLY: 'Yearly'
    };
    return mapping[frequency.freq.toUpperCase()] || frequency.freq;
  };

  const handleMessageIconClick = async (task: CustomTask, e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedTaskForNotes(task);
    setIsNotesModalOpen(true);
  };

  const getUpdatesCount = (task: CustomTask): number => {
    const updatesCount = task.updates?.length || 0;
    const commentsCount = task.comments?.length || 0;
    return updatesCount + commentsCount;
  };

  const handleDealClick = (e: React.MouseEvent, dealId: number) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedDealId(dealId);
    setIsDealDrawerOpen(true);
  };

  const getAssignedUsers = (task: CustomTask | (SubTask & { task_type?: string })): StoredUser[] => {
    return task.assigned_users?.map((id: string) => 
      contextUsers.find(u => u.user_id.toString() === id)
    ).filter(Boolean) as StoredUser[] || [];
  };

  // Update the getTaskTags function
  const getTaskTags = (task: CustomTask, dealDataConfig: DealDataConfigItem[], dealSoftwareConfig: DealDataConfigItem[], dealOnboardingConfig: DealDataConfigItem[]): JSX.Element => {
    const tags: { key: string; value: string; color?: string; label: string }[] = [];

    // Only proceed if we have a board with card_fields and a linked deal
    if (!board?.board_config?.card_fields || !task.deal) {
        return <TaskBadge items={tags} />;
    }

    // Helper function to get nested value and handle both data and software paths
    const getNestedValue = (obj: any, path: string) => {
        // For onboarding fields
        if (path.startsWith('onboarding_data.')) {
            const fieldKey = path.replace('onboarding_data.', '');
            const value = obj.deal?.onboarding_data?.[fieldKey];
            return value === null || value === '' || value === undefined ? undefined : value;
        }

        // For data fields
        if (path.startsWith('data.')) {
            const value = obj.deal?.data?.[path.replace('data.', '')];
            return value === null || value === '' || value === undefined ? undefined : value;
        }
        
        // For software fields
        if (path.startsWith('software.')) {
            const value = obj.deal?.software?.[path.replace('software.', '')];
            return value === null || value === '' || value === undefined ? undefined : value;
        }

        // For direct deal fields
        if (['main_mailbox', 'corpay_mailbox', 'aiia_mailbox', 'is_holding'].includes(path)) {
            const value = obj.deal?.[path];
            // Special handling for boolean fields
            if (path === 'is_holding') {
                return value === null ? false : value;
            }
            return value === null || value === '' || value === undefined ? undefined : value;
        }

        // For payment details
        if (path.startsWith('payment_details.')) {
            const value = obj.deal?.payment_details?.[path.replace('payment_details.', '')];
            return value === null || value === '' || value === undefined ? undefined : value;
        }

        // For any other nested paths
        const value = path.split('.').reduce((acc, part) => acc && acc[part], obj);
        return value === null || value === '' || value === undefined ? undefined : value;
    };

    // Process fields that are configured in board_config.card_fields
    board.board_config.card_fields.forEach(fieldKey => {

        // Find the field configuration from any of the three configs
        const fieldConfig = dealDataConfig.find(config => config.key === fieldKey) ||
                           dealSoftwareConfig.find(config => config.key === fieldKey) ||
                           dealOnboardingConfig.find(config => config.key === fieldKey);

        if (!fieldConfig) {
            return;
        }

        const value = getNestedValue(task, fieldKey);
        
        // Skip if no value (undefined, null, or empty string)
        if (value === undefined) {
            return;
        }

        let color;
        let displayValue = value;
        
        if (fieldConfig.type === 'number' && fieldConfig.colorScale) {
            const numValue = Number(value);
            const scale = fieldConfig.colorScale.find(s => numValue <= s.max);
            color = scale?.color;
        } else if (fieldConfig.type === 'dropdown' && fieldConfig.options) {
            const option = fieldConfig.options.find(opt => opt.value === value);
            color = option?.color;
        } else if (fieldConfig.type === 'boolean') {
            displayValue = value ? 'Yes' : 'No';
            color = value ? '#52c41a' : '#ff4d4f';  // Green for true, red for false
        }

        tags.push({
            key: fieldConfig.key,
            value: displayValue.toString(),
            color,
            label: fieldConfig.label
        });
    });

    return <TaskBadge items={tags} />;
  };

  // Function to fetch subtasks for a task
  const fetchSubtasks = async (taskId: number) => {
    try {
      const response = await axios.get(`/tasks/${taskId}/subtasks`);
      return response.data || [];
    } catch (error) {
      console.error('Error fetching subtasks:', error);
      toast.error('Failed to load subtasks');
      return [];
    }
  };

  // Function to handle subtask creation
  const handleCreateSubtask = async (taskId: number) => {
    const task = localTasks.find(t => t.id === taskId);
    if (!task) return;

    setSelectedTaskForSubtask(task);
    setIsCreateSubTaskModalOpen(true);
  };

  // Function to handle subtask submission
  const handleSubtaskSubmit = async (values: Partial<CustomTask>) => {
    if (!selectedTaskForSubtask) return;

    try {
      const response = await axios.post(`/custom-board/tasks/${selectedTaskForSubtask.id}/subtasks`, {
        title: values.title || '',
        description: values.description,
        work_date: values.work_date,
        due_date: values.due_date,
        assigned_users: values.assigned_users
      });
      
      if (response.status === 200) {
        // Update the tasks state with the new subtask
        setTasks(prevTasks => prevTasks.map(task => {
          if (task.id === selectedTaskForSubtask.id) {
            return {
              ...task,
              subtasks: [...(task.subtasks || []), response.data]
            };
          }
          return task;
        }));

        toast.success('Subtask created successfully');
        setIsCreateSubTaskModalOpen(false);
        setSelectedTaskForSubtask(null);
      }
    } catch (error) {
      console.error('Error creating subtask:', error);
      toast.error('Failed to create subtask');
    }
  };

  // Function to toggle subtask visibility
  const toggleSubtasks = async (taskId: number) => {
    const newShowSubtasks = { ...showSubtasks };
    newShowSubtasks[taskId] = !newShowSubtasks[taskId];
    setShowSubtasks(newShowSubtasks);

    // Fetch subtasks if they haven't been loaded yet
    if (newShowSubtasks[taskId]) {
      const task = localTasks.find(t => t.id === taskId);
      if (task && (!task.subtasks || task.subtasks.length === 0)) {
        const subtasks = await fetchSubtasks(taskId);
        const updatedTask = { ...task, subtasks };
        setLocalTasks(prevTasks =>
          prevTasks.map(t =>
            t.id === taskId ? updatedTask : t
          )
        );
      }
    }
  };

  // Function to handle subtask deletion
  const handleDeleteSubtask = async (taskId: number, subtaskId: number) => {
    try {
      await axios.delete(`/custom-board/subtasks/${subtaskId}`);
      toast.success('Subtask deleted successfully');
      
      // Update both local and external state
      const task = localTasks.find(t => t.id === taskId);
      if (task && task.subtasks) {
        const updatedSubtasks = task.subtasks.filter(st => st.id !== subtaskId);
        const updatedTask = { ...task, subtasks: updatedSubtasks };
        
        // Update both states consistently
        setLocalTasks(prevTasks =>
          prevTasks.map(t =>
            t.id === taskId ? updatedTask : t
          )
        );
        setTasks(prevTasks =>
          prevTasks.map(t =>
            t.id === taskId ? updatedTask : t
          )
        );
      }
    } catch (error) {
      console.error('Error deleting subtask:', error);
      toast.error('Failed to delete subtask');
    }
  };

  // Function to handle subtask status update
  const handleSubtaskStatusUpdate = async (taskId: number, subtaskId: number, newStatus: string) => {
    try {
      const response = await axios.put(`/custom-board/subtasks/${subtaskId}`, {
        progress_status: newStatus
      });
      
      // Update the local state with the full response data
      const task = localTasks.find(t => t.id === taskId);
      if (task && task.subtasks) {
        const updatedSubtasks = task.subtasks.map(st => 
          st.id === subtaskId ? { ...st, ...response.data } : st
        );
        const updatedTask = { ...task, subtasks: updatedSubtasks };
        setLocalTasks(prevTasks =>
          prevTasks.map(t =>
            t.id === taskId ? updatedTask : t
          )
        );
        setTasks(prevTasks =>
          prevTasks.map(t =>
            t.id === taskId ? updatedTask : t
          )
        );
      }
      
      toast.success('Subtask status updated');
      return response.data;
    } catch (error) {
      console.error('Error updating subtask status:', error);
      toast.error('Failed to update subtask status');
      throw error;
    }
  };

  // Update the helper function to handle null board
  const calculateSubtaskProgress = (task: CustomTask, board: Board | null | undefined): number | null => {
    if (!task.subtasks || !board?.subtask_config?.enable_subtask_progress) return null;

    const subtasks = task.subtasks;
    if (subtasks.length === 0) return 0;

    const completedSubtasks = subtasks.filter(subtask => {
      // Find the predefined subtask that matches this subtask
      const predefinedSubtask = board.subtask_config?.predefined_subtasks?.find(
        ps => ps.name === subtask.title
      );

      if (!predefinedSubtask) return false;

      // Check if the current status is marked as a completed state
      const currentStatusOption = predefinedSubtask.status_options?.find(
        so => so.id.toString() === subtask.progress_status_id.toString()
      );

      return currentStatusOption?.is_completed_state || false;
    });

    return (completedSubtasks.length / subtasks.length) * 100;
  };

  const handleSubtaskClick = (taskId: number, subtaskId: number) => {
    const parentTask = localTasks.find(t => t.id === taskId);
    if (!parentTask) return;
    
    const subtask = parentTask.subtasks?.find(s => s.id === subtaskId);
    if (!subtask) return;

    const taskViewData: CustomTask = {
      id: parentTask.id,
      key: parentTask.key,
      task_type: parentTask.task_type,
      title: parentTask.title || '',
      description: parentTask.description || '',
      board_status_id: parentTask.board_status_id || 0,
      progress_status_id: parentTask.progress_status_id || 0,
      deal_id: parentTask.deal_id,
      work_date: parentTask.work_date,
      due_date: parentTask.due_date,
      assigned_users: parentTask.assigned_users || [],
      activity_log: parentTask.activity_log || [],
      comments: parentTask.comments || [],
      data: parentTask.data || {},
      board_config: board?.board_config,
      board_id: board?.id
    };
    
    setTaskViewTask(taskViewData);
    setIsTaskViewOpen(true);
  };

  // Pass handleSubtaskClick to ExpandedSubtasks
  const renderTaskContent = (task: CustomTask) => {
    const displayTitle = taskType === 'custom' ? task.title : (task.data?.Task || task.description || '');
    const hasSubtasks = task.subtasks && task.subtasks.length > 0;
    const isSubtasksEnabled = board?.board_config?.enable_subtasks;
    const isSimplifiedMainTasks = board?.board_config?.simplify_main_tasks;
    const isExpanded = showSubtasks[task.id] || false;
    
    // Get the secondary status based on kanban source
    const getSecondaryStatus = () => {
      if (taskType !== 'custom' || !board?.board_config) return null;
      
      const { kanban_source, progress_status_options, board_status_options } = board.board_config;
      const isProgressKanban = isKanbanReversed 
        ? kanban_source !== 'progress'
        : kanban_source === 'progress';
      
      // Get the status ID and options based on which view we're in
      const statusId = isProgressKanban ? task.board_status_id : task.progress_status_id;
      const statusOptions = isProgressKanban ? board_status_options : progress_status_options;
      
      // If we don't have options or status ID, return null
      if (!statusOptions || !statusId) return null;
      
      // Find the status config by ID
      const statusConfig = statusOptions.find(opt => opt.id === statusId);
      if (!statusConfig) return null;
      
      return {
        label: statusConfig.label,
        color: statusConfig.color,
        value: statusConfig.value || statusConfig.label.toLowerCase().replace(/\s+/g, '_'),
        options: statusOptions
      };
    };

    const secondaryStatus = getSecondaryStatus();
    const subtaskProgress = calculateSubtaskProgress(task, board);

    // Function to handle status change
    const handleStatusChange = async (newStatusId: number) => {
      try {
        const response = await axios.put(`/custom-board/tasks/${task.id}`, {
          ...(isKanbanReversed
            ? board?.board_config?.kanban_source === 'progress'
              ? { board_status_id: newStatusId }
              : { progress_status_id: newStatusId }
            : board?.board_config?.kanban_source === 'progress'
              ? { board_status_id: newStatusId }
              : { progress_status_id: newStatusId }
          )
        });

        if (response.status === 200) {
          // Update local state
          const updatedTask = {
            ...task,
            ...(isKanbanReversed
              ? board?.board_config?.kanban_source === 'progress'
                ? { board_status_id: newStatusId }
                : { progress_status_id: newStatusId }
              : board?.board_config?.kanban_source === 'progress'
                ? { board_status_id: newStatusId }
                : { progress_status_id: newStatusId }
            )
          };

          setLocalTasks(prevTasks =>
            prevTasks.map(t => t.id === task.id ? updatedTask : t)
          );
          setTasks(prevTasks =>
            prevTasks.map(t => t.id === task.id ? updatedTask : t)
          );

          toast.success('Status updated successfully');
        }
      } catch (error) {
        console.error('Error updating status:', error);
        toast.error('Failed to update status');
      }
    };

    return (
      <div className="space-y-1.5">
        <Card className={cn(
          "w-full p-2 hover:shadow-md transition-shadow relative group cursor-grab",
          isExpanded && "mb-0"
        )}>
          <div 
            className="flex flex-col items-center text-center relative"
            onClick={(e) => e.stopPropagation()}
          >
            {task.deal_name && (
              <ForwardedBadge 
                variant="secondary"
                className="cursor-pointer hover:bg-secondary/80 bg-[hsl(var(--badge-deal))] text-white hover:bg-[hsl(var(--badge-deal)/.8)] text-xs"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  task.deal_id && handleDealClick(e, task.deal_id);
                }}
              >
                <Briefcase className="w-3 h-3 flex-shrink-0 mr-1" />
                <span className="max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap">
                  {task.deal_name}
                </span>
              </ForwardedBadge>
            )}
            <Button
              variant="ghost"
              size="icon"
              className="absolute right-0 top-0 opacity-0 group-hover:opacity-100 group-hover:bg-secondary transition-all hover:text-primary"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleEditClick(task);
              }}
            >
              <Edit className="h-4 w-4" />
            </Button>

            {/* Only show title and frequency if not simplified */}
            {!isSimplifiedMainTasks && (
              <>
                <p className="text-sm text-primary font-medium mt-1.5 line-clamp-2">
                  {displayTitle}
                </p>
                {cardConfig?.showFrequency && task.data?.frequency && (
                  <ForwardedBadge variant="secondary" className="mt-1.5 text-xs">
                    {mapFrequencyToLabel(task.data.frequency)}
                  </ForwardedBadge>
                )}
              </>
            )}

            {/* Status Badge - Always show for custom boards */}
            {taskType === 'custom' && board?.board_config && (
              <div className="mt-1.5">
                {secondaryStatus && (
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <ForwardedBadge 
                        variant="outline"
                        className="cursor-pointer"
                        style={{ 
                          borderColor: secondaryStatus.color,
                          color: secondaryStatus.color
                        }}
                      >
                        {secondaryStatus.label}
                      </ForwardedBadge>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="center">
                      {secondaryStatus.options.map((option) => (
                        <DropdownMenuItem
                          key={option.id}
                          onClick={() => handleStatusChange(option.id)}
                          className="flex items-center gap-2"
                        >
                          <div
                            className="w-2 h-2 rounded-full"
                            style={{ backgroundColor: option.color }}
                          />
                          {option.label}
                          {option.id === (isKanbanReversed
                            ? board.board_config.kanban_source === 'progress'
                              ? task.board_status_id
                              : task.progress_status_id
                            : board.board_config.kanban_source === 'progress'
                              ? task.board_status_id
                              : task.progress_status_id) && (
                            <Check className="w-4 w-4 ml-auto" />
                          )}
                        </DropdownMenuItem>
                      ))}
                    </DropdownMenuContent>
                  </DropdownMenu>
                )}
              </div>
            )}

            {/* Add configured card field badges */}
            {board?.board_config?.card_fields && task.deal && (
              <div 
                className="mt-1.5 flex flex-wrap gap-1 justify-center relative"
                onClick={(e) => e.stopPropagation()}
                style={{ zIndex: 1 }}
              >
                {getTaskTags(task, dealDataConfig, dealSoftwareConfig, dealOnboardingConfig)}
              </div>
            )}
          </div>

          {/* Progress bar for subtasks */}
          {subtaskProgress !== null && (
            <div className="w-full mt-2">
              <div className="h-1.5 w-full bg-muted rounded-full overflow-hidden">
                <div
                  className="h-full bg-primary transition-all duration-300 ease-in-out"
                  style={{ width: `${subtaskProgress}%` }}
                />
              </div>
              <p className="text-xs text-muted-foreground text-center mt-0.5">
                {Math.round(subtaskProgress)}% complete
              </p>
            </div>
          )}

          {/* Dates Section - Only show if not simplified */}
          {!isSimplifiedMainTasks && (
            <div className={cn(
              "grid gap-1.5 mt-2",
              ((task.work_date || task.next_work_date) && (task.due_date || task.next_due_date)) ? "grid-cols-2" : "grid-cols-1 justify-items-center"
            )}>
              {(task.work_date || task.next_work_date) && (
                <TaskDateEditor
                  date={task.work_date || task.next_work_date || null}
                  dateType="work"
                  taskId={task.id}
                  taskType={taskType as 'custom' | 'custom_subtask'}
                  onSuccess={(updatedTask) => {
                    // Update the task in local state
                    const updatedTaskData = {
                      ...task,
                      work_date: updatedTask.work_date,
                      next_work_date: updatedTask.next_work_date
                    };
                    setLocalTasks(prevTasks =>
                      prevTasks.map(t =>
                        t.id === task.id ? updatedTaskData : t
                      )
                    );
                  }}
                  variant="compact"
                  className="w-full max-w-[200px]"
                  icon={<Clock className="h-4 w-4 text-muted-foreground" />}
                />
              )}
              {(task.due_date || task.next_due_date) && (
                <TaskDateEditor
                  date={task.due_date || task.next_due_date || null}
                  dateType="due"
                  taskId={task.id}
                  taskType={taskType as 'custom' | 'custom_subtask'}
                  onSuccess={(updatedTask) => {
                    // Update the task in local state
                    const updatedTaskData = {
                      ...task,
                      due_date: updatedTask.due_date,
                      next_due_date: updatedTask.next_due_date
                    };
                    setLocalTasks(prevTasks =>
                      prevTasks.map(t =>
                        t.id === task.id ? updatedTaskData : t
                      )
                    );
                  }}
                  variant="compact"
                  className="w-full max-w-[200px]"
                  icon={<Calendar className="h-4 w-4 text-muted-foreground" />}
                />
              )}
            </div>
          )}

          {/* Footer Section */}
          <div 
            className="flex justify-between items-center mt-1"
            onClick={(e) => e.stopPropagation()}
          >
            {/* Left side: Assignees or Add Assignee button */}
            <div className="flex-1">
              {!isSimplifiedMainTasks && cardConfig?.showAssignees && (
                <>
                  {getAssignedUsers(task).length > 0 ? (
                    <div 
                      className="flex items-center gap-2 cursor-pointer"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setSelectedTaskForAssignee(task);
                        setIsAssigneeModalOpen(true);
                      }}
                    >
                      <AvatarGroup
                        users={getAssignedUsers(task)}
                        max={3}
                        className="hover:opacity-80 transition-opacity"
                      />
                    </div>
                  ) : (
                    <Button
                      variant="ghost"
                      size="icon"
                      className="opacity-0 group-hover:opacity-100 transition-opacity hover:text-primary"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setSelectedTaskForAssignee(task);
                        setIsAssigneeModalOpen(true);
                      }}
                    >
                      <UserPlus className="h-4 w-4" />
                    </Button>
                  )}
                </>
              )}
            </div>
            
            {/* Center: Eye icon */}
            <div className="flex-1 flex justify-center gap-2">
              <Button
                variant="ghost"
                size="icon"
                className="opacity-0 group-hover:opacity-100 transition-opacity"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleOpenTask(task.id);
                }}
              >
                <Eye className="h-4 w-4" />
              </Button>
            </div>
            
            {/* Right side: Message icon and Subtasks */}
            <div className="flex-1 flex justify-end items-center gap-2">
              {!isSimplifiedMainTasks && (
                <Button
                  variant="ghost"
                  size="icon"
                  className="relative"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleMessageIconClick(task, e);
                  }}
                >
                  <MessageSquare className="h-4 w-4" />
                  {getUpdatesCount(task) > 0 && (
                    <ForwardedBadge 
                      variant="compact"
                      className="absolute -top-2 -right-2 h-4 w-4 flex items-center justify-center"
                    >
                      {getUpdatesCount(task)}
                    </ForwardedBadge>
                  )}
                </Button>
              )}

              {/* Only show subtasks for custom boards with subtasks enabled */}
              {taskType === 'custom' && board?.board_config?.enable_subtasks && (
                <Button
                  variant="ghost"
                  size="icon"
                  className="relative"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    toggleSubtasks(task.id);
                  }}
                >
                  <ListTodo className="h-4 w-4" />
                  {hasSubtasks && (
                    <ForwardedBadge 
                      variant="compact"
                      className="absolute -top-2 -right-2 h-4 w-4 flex items-center justify-center"
                    >
                      {task.subtasks?.length || 0}
                    </ForwardedBadge>
                  )}
                </Button>
              )}
            </div>
          </div>

          {/* Expanded Subtasks Section */}
          {isExpanded && taskType === 'custom' && board?.board_config?.enable_subtasks && (
            <div className="mt-2">
              <ExpandedSubtasks
                task={task}
                board={board}
                onDeleteSubtask={handleDeleteSubtask}
                onStatusUpdate={handleSubtaskStatusUpdate}
                getAssignedUsers={getAssignedUsers}
                onCreateSubtask={handleCreateSubtask}
                onTaskUpdate={handleTaskUpdated}
                onSubtaskClick={handleSubtaskClick}
              />
            </div>
          )}
        </Card>
      </div>
    );
  };

  const handleCompleteDrawerClose = () => {
    if (originalTaskState) {
      setLocalTasks(prevTasks =>
        prevTasks.map(t => t.id === completedTask?.id ? originalTaskState : t)
      );
    }
    setCompletedTask(null);
    setOriginalTaskState(null);
  };

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;
    
    setActiveId(active.id);
    const draggedTask = localTasks.find(task => task.id.toString() === active.id);
    setActiveTask(draggedTask || null);
  };

  const getBackendTaskType = (frontendTaskType: 'custom' | 'custom_subtask'): 'custom' | 'custom_subtask' => {
    return frontendTaskType;
  };

  const handleTaskUpdated = (updatedTask: CustomTask | null) => {
    if (!updatedTask) {
      // Task was deleted
      setTasks(prevTasks => prevTasks.filter(t => t.id !== taskToDelete?.id));
      setTaskToDelete(null);
      return;
    }

    setTasks(prevTasks =>
      prevTasks.map(task =>
        task.id === updatedTask.id
          ? {
              ...task,
              ...updatedTask,
              task_type: task.task_type,
              is_custom: task.is_custom,
              board_config: task.board_config
            }
          : task
      )
    );
  };

  const handleEditTask = (task: CustomTask) => {
    if (taskType === 'custom') {
      const customTask: CustomTask = {
        key: task.id.toString(),
        id: task.id,
        task_type: 'custom' as const,
        title: task.title || '',
        description: task.description || '',
        board_status_id: task.board_status_id || 0,
        progress_status_id: task.progress_status_id || 0,
        deal_id: task.deal_id,
        work_date: task.work_date,
        due_date: task.due_date,
        assigned_users: task.assigned_users || [],
        activity_log: task.activity_log || [],
        data: task.data || {}
      };
      
      setSelectedTaskForEdit(customTask);
      setIsEditModalOpen(true);
    } else {
      setSelectedTaskForNormalEdit(task);
      setIsEditTaskModalOpen(true);
    }
  };

  // Add the minimal handleDragOver function to track active task for overlay
  const handleDragOver = useCallback((event: DragOverEvent) => {
    const { active } = event;
    const draggedTask = localTasks.find(task => task.id.toString() === active.id);
    setActiveTask(draggedTask || null);
  }, [localTasks]);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={collisionDetectionStrategy}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always
        },
      }}
    >
      <div className={cn(
        kanbanStyles.board,
        "h-[calc(100vh-14.5rem)]",
        isCollapsed && "h-[calc(100vh-8.5rem)]"
      )}>
        <div 
          className="pl-6 flex gap-3"
          style={{ outline: 'none' }}
        >
          {isBoardConfigLoading ? (
            <div className="grid grid-cols-3 gap-4 w-full">
              {Array.from({ length: 3 }).map((_, index) => (
                <KanbanColumnSkeleton key={`column-skeleton-${index}`} className="mt-4" />
              ))}
            </div>
          ) : (
            kanbanStatusOptions?.map((status) => {
              const columnTasks = getTasksForColumn(status.id);
              return (
                <DroppableColumn
                  key={`column-${status.id}`}
                  id={String(status.id)}
                  className={kanbanStyles.columnContent}
                  tasks={columnTasks}
                  loading={loading}
                  status={status}
                  taskType={taskType}
                >
                  {!loading && columnTasks.map((task) => (
                    <SortableTaskCard
                      key={`task-${task.id}`}
                      id={String(task.id)}
                      task={task}
                      renderContent={renderTaskContent}
                    />
                  ))}
                </DroppableColumn>
              );
            })
          )}
        </div>
        <div className="w-6 flex-none" />
      </div>

      <DragOverlay>
        {activeId && activeTask ? (
          <div 
            className="opacity-100 px-2"
            style={{ 
              width: `${KANBAN_CARD_WIDTH}px`,
              transform: 'rotate(3deg)',
              transition: 'transform 200ms cubic-bezier(0.25, 1, 0.5, 1)'
            }}
          >
            {renderTaskContent(activeTask)}
          </div>
        ) : null}
      </DragOverlay>

      <DealDrawer
        visible={isDealDrawerOpen}
        onClose={() => {
          setIsDealDrawerOpen(false);
          setSelectedDealId(null);
        }}
        dealId={selectedDealId}
      />

      <AssigneeSelection
        visible={isAssigneeModalOpen}
        onClose={() => {
          setIsAssigneeModalOpen(false);
          setSelectedTaskForAssignee(null);
        }}
        onSave={async (assignees) => {
          if (!selectedTaskForAssignee) return;
          await onAssigneeUpdate(selectedTaskForAssignee.id, assignees);
          setIsAssigneeModalOpen(false);
          setSelectedTaskForAssignee(null);
        }}
        initialAssignees={selectedTaskForAssignee?.assigned_users || []}
        maxAssignees={10}
      />

      {selectedTask && (
        <EditTaskModal
          visible={isEditModalOpen}
          onClose={(wasDeleted?: boolean) => {
            setIsEditModalOpen(false);
            setSelectedTask(null);
          }}
          task={selectedTask}
          board={board}
          onSave={(updatedTask: CustomTask | null) => {
            if (!updatedTask) {
              // Task was deleted - remove it from local state
              setLocalTasks(prev => prev.filter(t => t.id !== selectedTask.id));
              return;
            }
            // Normal update case
            setLocalTasks(prev => prev.map(t => t.id === updatedTask.id ? updatedTask : t));
          }}
          users={contextUsers}
        />
      )}

      {selectedTaskForNotes && (
        <TaskNotes
          visible={isNotesModalOpen}
          onClose={(refresh?: boolean) => {
            setIsNotesModalOpen(false);
            setSelectedTaskForNotes(null);
          }}
          task={selectedTaskForNotes}
          users={contextUsers}
          onTaskUpdate={(updatedTask) => {
            // Update both local and parent state
            setLocalTasks(prevTasks => 
              prevTasks.map(t => 
                t.id === updatedTask.id ? updatedTask : t
              )
            );
            setTasks(prevTasks => 
              prevTasks.map(t => 
                t.id === updatedTask.id ? updatedTask : t
              )
            );
          }}
        />
      )}

      {/* Add CreateSubTask modal */}
      {selectedTaskForSubtask && (
        <CreateSubTask
          open={isCreateSubTaskModalOpen}
          onClose={() => {
            setIsCreateSubTaskModalOpen(false);
            setSelectedTaskForSubtask(null);
          }}
          onSubmit={handleSubtaskSubmit}
          parentTask={selectedTaskForSubtask}
          board={board}
        />
      )}
    </DndContext>
  );
}

