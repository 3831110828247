import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Plus, Trash2 } from 'lucide-react';
import { Button } from "@/components/ui/button";
import { Switch } from "@/components/ui/switch";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormDescription,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

interface RecurrenceConfigProps {
  form: UseFormReturn<any>;
}

export function RecurrenceConfig({ form }: RecurrenceConfigProps) {
  return (
    <div className="space-y-6">
      <div className="p-6 bg-muted rounded-lg space-y-4">
        <div className="flex items-center justify-between">
          <div>
            <h3 className="text-lg font-semibold">Recurrence Settings</h3>
            <p className="text-sm text-muted-foreground">
              Configure how recurring tasks should behave
            </p>
          </div>
        </div>

        <FormField
          control={form.control}
          name="board_config.recurring_task_settings.require_recurrence"
          render={({ field }) => (
            <FormItem className="flex items-center justify-between">
              <div>
                <FormLabel>Require Recurrence</FormLabel>
                <FormDescription>
                  All tasks in this board must have a recurrence pattern
                </FormDescription>
              </div>
              <FormControl>
                <Switch
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
            </FormItem>
          )}
        />

        {/* Add recurrence options configuration here */}
      </div>
    </div>
  );
} 