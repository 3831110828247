import React, { useState, useEffect } from 'react';
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Switch } from "@/components/ui/switch";
import { DatePicker } from "@/components/ui/date/date-picker";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { ProgressSteps } from "@/components/ui/progress-steps";
import { Table, type ColumnDef } from "@/components/ui/specialized/mod-table";
import { Loading } from "@/components/ui/loading";
import { ScrollArea } from "@/components/ui/scroll-area";
import { toast } from "sonner";
import axios from '../api/axiosConfig';
import { dealDataConfig } from '../configs/DealDataConfig';
import { dealSoftwareConfig } from '../configs/DealSoftwareConfig';
import dayjs from 'dayjs';
import { UserAvatar } from '@/components/user-avatar';
import { AssigneeSelection } from '../modals/AssigneeSelection';
import { Loader2 } from 'lucide-react';
import { cn } from '@/lib/utils';
import type { Deal, Product, Software, DealData, SoftwareKey } from '@/types/customers';
import type { DealDataField as ImportedDealDataField, DropdownOption } from '../configs/DealDataConfig';

interface User {
  user_id: string;
  name?: string;
  surname?: string;
  avatar_urls?: {
    medium?: string;
    small?: string;
  };
  settings?: {
    avatar_color?: string;
  };
  status?: string;
  initials?: string;
}

interface DealChanges {
  data?: Record<string, any>;
  software?: Software;
  type?: 'drift' | 'holding' | 'primary' | 'secondary';
  is_holding?: boolean | null;
  [key: string]: any;
}

interface FieldConfig {
  key: string;
  label: string;
  type: string;
  editable: boolean;
  options?: DropdownOption[];
}

interface SoftwareConfig extends FieldConfig {
  type: 'dropdown' | 'text';
  multiSelect?: boolean;
}

interface DealDataField extends ImportedDealDataField {
  // Extend the imported interface if needed
}

type EditableFieldConfig = FieldConfig | SoftwareConfig;

interface Task {
  type: string;
  frequency: string;
  next_work_date: string;
  next_due_date: string;
  assignee: string;
  description: string;
}

const getNestedValue = (obj: Deal | null, path: string): any => {
  if (!obj) return undefined;
  return path.split('.').reduce((acc: any, key) => {
    if (acc === null || acc === undefined) return undefined;
    return acc[key];
  }, obj);
};

const MigrateDeal = () => {
  const [deals, setDeals] = useState<Deal[]>([]);
  const [selectedDeal, setSelectedDeal] = useState<Deal | null>(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [tasksStatus, setTasksStatus] = useState<string | null>(null);
  const [changes, setChanges] = useState<DealChanges>({});
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);
  const [taskConfig, setTaskConfig] = useState<Task[] | null>(null);
  const [editedTasks, setEditedTasks] = useState<Task[] | null>(null);
  const [users, setUsers] = useState<User[]>([]);
  const [assigneeModalVisible, setAssigneeModalVisible] = useState(false);
  const [currentEditingTaskIndex, setCurrentEditingTaskIndex] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);

  useEffect(() => {
    fetchDeals();
  }, []);

  const fetchDeals = async () => {
    try {
      const response = await axios.get('/migration/deals');
      setDeals(response.data);
    } catch (error) {
      toast.error('Error fetching migration deals');
    }
  };

  const fetchProducts = async (dealId: number) => {
    try {
      const response = await axios.get(`/migration/deal/${dealId.toString()}/products`);
      setProducts(response.data);
    } catch (error) {
      toast.error('Error fetching products');
    }
  };

  const columns: ColumnDef<Deal>[] = [
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
    },
    {
      key: 'cvr',
      title: 'CVR',
      dataIndex: 'cvr',
    },
    {
      key: 'email',
      title: 'Email',
      dataIndex: 'email',
    },
    {
      key: 'created_at',
      title: 'Created',
      dataIndex: 'created_at',
      render: (date: string) => dayjs(date).format('DD/MM/YYYY'),
    },
  ];

  const productColumns: ColumnDef<Product>[] = [
    { key: 'name', title: 'Name', dataIndex: 'name' },
    { key: 'code', title: 'Code', dataIndex: 'code' },
    { 
      key: 'billing_start_date', 
      title: 'Billing Start', 
      dataIndex: 'billing_start_date',
      render: (date: string) => date ? dayjs(date).format('DD/MM/YYYY') : 'N/A'
    },
    { key: 'billing_frequency', title: 'Frequency', dataIndex: 'billing_frequency' }
  ];

  const taskColumns: ColumnDef<Task>[] = [
    { key: 'type', title: 'Type', dataIndex: 'type' },
    { key: 'description', title: 'Description', dataIndex: 'description' },
    { 
      key: 'frequency', 
      title: 'Frequency', 
      dataIndex: 'frequency',
      cell: ({ row }) => (
        <Select
          value={row.original.frequency}
          onValueChange={(value) => handleTaskChange(
            editedTasks?.indexOf(row.original) ?? 0,
            'frequency',
            value
          )}
        >
          <SelectTrigger>
            <SelectValue placeholder="Select frequency" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="monthly">Monthly</SelectItem>
            <SelectItem value="quarterly">Quarterly</SelectItem>
            <SelectItem value="semi-annually">Semi-annually</SelectItem>
            <SelectItem value="yearly">Yearly</SelectItem>
          </SelectContent>
        </Select>
      )
    },
    {
      key: 'next_work_date',
      title: 'Work Date',
      dataIndex: 'next_work_date',
      cell: ({ row }) => (
        <DatePicker
          date={row.original.next_work_date ? new Date(row.original.next_work_date) : undefined}
          onSelect={(date) => handleTaskChange(
            editedTasks?.indexOf(row.original) ?? 0,
            'next_work_date',
            date ? dayjs(date).format('YYYY-MM-DD') : null
          )}
          variant="short"
        />
      )
    },
    {
      key: 'next_due_date',
      title: 'Due Date',
      dataIndex: 'next_due_date',
      cell: ({ row }) => (
        <DatePicker
          date={row.original.next_due_date ? new Date(row.original.next_due_date) : undefined}
          onSelect={(date) => handleTaskChange(
            editedTasks?.indexOf(row.original) ?? 0,
            'next_due_date',
            date ? dayjs(date).format('YYYY-MM-DD') : null
          )}
          variant="short"
        />
      )
    },
    {
      key: 'assignee',
      title: 'Assignee',
      dataIndex: 'assignee',
      cell: ({ row }) => {
        const assignedUser = users.find(user => user.user_id === row.original.assignee);
        
        return (
          <div 
            className="cursor-pointer"
            onClick={() => handleAssigneeSelect(editedTasks?.indexOf(row.original) ?? 0)}
          >
            {assignedUser && (
              <UserAvatar 
                user={assignedUser}
                size={32}
              />
            )}
          </div>
        );
      }
    }
  ];

  const steps = [
    {
      title: 'Review Deal Data',
      description: 'Review and update deal information'
    },
    {
      title: 'Configure Tasks',
      description: 'Set up recurring tasks'
    },
    {
      title: 'Complete Migration',
      description: 'Finalize the migration process'
    }
  ];

  const handleConfigureTasks = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`/migration/deal/${selectedDeal?.id}/tasks`);
      setTaskConfig(response.data.recurring_tasks);
      setEditedTasks(response.data.recurring_tasks);
      toast.success('Task recommendations generated');
    } catch (error) {
      toast.error('Error getting task recommendations');
    } finally {
      setLoading(false);
    }
  };

  const handleSaveTaskConfig = async () => {
    try {
      setLoading(true);
      await axios.post(`/migration/deal/${selectedDeal?.id}/tasks`, {
        recurring_tasks: editedTasks
      });
      setTasksStatus('success');
      toast.success('Tasks saved successfully');
    } catch (error) {
      setTasksStatus('error');
      toast.error('Error saving task configuration');
    } finally {
      setLoading(false);
    }
  };

  const handleTaskChange = (taskIndex: number, field: string, value: any) => {
    setEditedTasks(prev => {
      const updated = [...(prev ?? [])];
      updated[taskIndex] = {
        ...updated[taskIndex],
        [field]: value
      };
      return updated;
    });
  };

  const handleEdit = (key: string, value: any) => {
    setChanges(prev => ({
      ...prev,
      [key]: value
    }));
  };

  const handleSave = () => {
    setConfirmVisible(true);
  };

  const confirmSave = async () => {
    try {
      const updatedChanges: DealChanges = {};
      const updatedData = { ...(selectedDeal?.data || {})} as DealData;
      const updatedSoftware = { ...(selectedDeal?.software || {})} as Software;
      
      Object.entries(changes).forEach(([key, value]) => {
        if (key.startsWith('software.')) {
          const softwareKey = key.split('.')[1] as SoftwareKey;
          const softwareConfig = dealSoftwareConfig.find(config => config.key === softwareKey);
          
          if (softwareConfig?.multiSelect) {
            (updatedSoftware as any)[softwareKey] = value as string[];
          } else {
            (updatedSoftware as any)[softwareKey] = value as string;
          }
        } else if (key.startsWith('data.')) {
          const nestedKey = key.split('.')[1];
          updatedData[nestedKey] = value;
        } else {
          updatedChanges[key] = value;
        }
      });

      updatedChanges.data = updatedData;
      updatedChanges.software = updatedSoftware;

      await axios.patch(`/migration/pending-deal/${selectedDeal?.id}`, updatedChanges);
      
      if (selectedDeal) {
        setSelectedDeal({
          ...selectedDeal,
          data: updatedData,
          software: updatedSoftware,
          type: updatedChanges.type as Deal['type'] || selectedDeal.type,
          is_holding: updatedChanges.is_holding ?? selectedDeal.is_holding
        });
      }
      
      setChanges({});
      setConfirmVisible(false);
      toast.success('Changes saved successfully');
    } catch (error) {
      toast.error('Error saving changes');
    }
  };

  const renderEditableField = (fieldConfig: ImportedDealDataField | SoftwareConfig, value: any) => {
    const currentValue = changes[fieldConfig.key] ?? value;

    if (!fieldConfig.editable) {
      return value?.toString() || 'N/A';
    }

    switch (fieldConfig.type) {
      case 'text':
      case 'number':
        return (
          <Input
            type={fieldConfig.type}
            value={currentValue}
            onChange={(e) => handleEdit(fieldConfig.key, e.target.value)}
            className="editable-field-wrapper"
          />
        );

      case 'dropdown':
        if (fieldConfig.key.startsWith('software.')) {
          const softwareKey = fieldConfig.key.split('.')[1];
          const softwareConfig = dealSoftwareConfig.find(
            (config) => config.key === softwareKey
          );
          
          if (softwareConfig?.options) {
            if ((fieldConfig as SoftwareConfig).multiSelect) {
              const selectedValues = Array.isArray(currentValue) ? currentValue : [currentValue].filter(Boolean);
              
              return (
                <Select
                  value={selectedValues.join(',')}
                  onValueChange={(value: string) => {
                    const values = value.split(',').filter(Boolean);
                    handleEdit(fieldConfig.key, values);
                  }}
                >
                  <SelectTrigger className="editable-field-wrapper">
                    <SelectValue placeholder="Select options" />
                  </SelectTrigger>
                  <SelectContent>
                    {softwareConfig.options.map((option) => (
                      <SelectItem 
                        key={option.value} 
                        value={option.value}
                      >
                        <span 
                          className="inline-block px-2 py-0.5 rounded text-white"
                          style={{ backgroundColor: option.color }}
                        >
                          {option.label}
                        </span>
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              );
            }
          }
        }
        
        return (
          <Select
            value={currentValue?.toString()}
            onValueChange={(value: string) => handleEdit(fieldConfig.key, value)}
          >
            <SelectTrigger className="editable-field-wrapper">
              <SelectValue placeholder="Select option" />
            </SelectTrigger>
            <SelectContent>
              {fieldConfig.options?.map((option) => (
                <SelectItem 
                  key={option.value} 
                  value={option.value}
                >
                  <span 
                    className="inline-block px-2 py-0.5 rounded text-white"
                    style={{ backgroundColor: option.color }}
                  >
                    {option.label}
                  </span>
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        );

      case 'boolean':
        return (
          <Switch
            checked={currentValue ?? false}
            onCheckedChange={(checked) => handleEdit(fieldConfig.key, checked)}
            className="editable-field-wrapper"
          />
        );

      case 'date':
        return (
          <DatePicker
            date={currentValue ? new Date(currentValue) : undefined}
            onSelect={(date) => handleEdit(fieldConfig.key, date ? dayjs(date).format('YYYY-MM-DD') : null)}
            variant="short"
          />
        );

      default:
        return currentValue?.toString() || 'N/A';
    }
  };

  const renderDealData = () => {
    if (!selectedDeal) return null;

    const configWithoutSource = dealDataConfig.filter(
      fieldConfig => fieldConfig.key !== 'data.deal_source'
    );

    return (
      <div className="space-y-4">
        <div className="mb-6">
          <h3 className="text-lg font-semibold mb-4">Deal Information</h3>
          {configWithoutSource.map((fieldConfig) => {
            const value = getNestedValue(selectedDeal, fieldConfig.key);
            
            return (
              <div 
                key={fieldConfig.key} 
                className={cn(
                  "flex flex-col gap-1",
                  value === undefined && "border-l-4 border-yellow-500 pl-2"
                )}
              >
                <label className="font-semibold text-foreground">
                  {fieldConfig.label}
                  {value === undefined && (
                    <span className="ml-2 text-sm text-yellow-500">
                      (Not set)
                    </span>
                  )}
                </label>
                {renderEditableField(fieldConfig, value)}
              </div>
            );
          })}
        </div>

        <div className="mb-6">
          <h3 className="text-lg font-semibold mb-4">Software Configuration</h3>
          {dealSoftwareConfig.map((softwareConfig) => {
            const value = (selectedDeal.software as any)?.[softwareConfig.key];
            
            return (
              <div 
                key={`software.${softwareConfig.key}`} 
                className={cn(
                  "flex flex-col gap-1",
                  value === undefined && "border-l-4 border-yellow-500 pl-2"
                )}
              >
                <label className="font-semibold text-foreground">
                  {softwareConfig.label}
                  {value === undefined && (
                    <span className="ml-2 text-sm text-yellow-500">
                      (Not set)
                    </span>
                  )}
                </label>
                {renderEditableField({
                  ...softwareConfig,
                  key: `software.${softwareConfig.key}`,
                  editable: softwareConfig.editable
                }, value)}
              </div>
            );
          })}
        </div>

        <div className="flex flex-col gap-1">
          <label className="font-semibold text-foreground">Source</label>
          {renderEditableField(
            { key: 'data.deal_source', label: 'Deal Source', type: 'text', editable: true },
            selectedDeal?.data?.deal_source
          )}
        </div>
      </div>
    );
  };

  const getChangesSummary = () => {
    const changedFields = Object.keys(changes).length;
    const newFields = dealDataConfig.reduce((count, config) => {
      const value = getNestedValue(selectedDeal, config.key);
      if (value === undefined) count++;
      return count;
    }, 0);
    
    return {
      changedFields,
      newFields,
      total: changedFields + newFields
    };
  };

  const renderChanges = () => {
    return (
      <div className="space-y-4">
        {Object.keys(changes).map(key => {
          const fieldConfig = dealDataConfig.find(config => config.key === key);
          if (fieldConfig) {
            const newValue = changes[key];
            const originalValue = getNestedValue(selectedDeal, key);

            return (
              <p key={key} className="change-item text-foreground">
                <span className="change-label font-semibold">{fieldConfig.label}:</span>
                <span className="change-value">
                  {typeof originalValue === 'object' ? JSON.stringify(originalValue) : originalValue || 'N/A'}
                </span>
                <span className="change-arrow"> → </span>
                <span className="change-value">
                  {typeof newValue === 'object' ? JSON.stringify(newValue) : newValue || 'N/A'}
                </span>
              </p>
            );
          }
          return null;
        })}
      </div>
    );
  };

  const handleNext = () => {
    setCurrentStep(current => current + 1);
  };

  const handlePrev = () => {
    setCurrentStep(current => current - 1);
  };

  const handleCompleteMigration = async () => {
    try {
      setLoading(true);
      await axios.post(`/migration/deal/${selectedDeal?.id}/complete`);
      toast.success('Migration completed successfully');
      setTaskConfig(null);
      setEditedTasks(null);
      setTasksStatus(null);
      fetchDeals(); // Refresh the list
      setSelectedDeal(null);
    } catch (error) {
      toast.error('Error completing migration');
    } finally {
      setLoading(false);
    }
  };

  const handleAssigneeSelect = (taskIndex: number) => {
    setCurrentEditingTaskIndex(taskIndex);
    setAssigneeModalVisible(true);
  };

  useEffect(() => {
    if (selectedDeal) {
      fetchProducts(selectedDeal.id);
    }
  }, [selectedDeal]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('/user');
        setUsers(response.data);
      } catch (error) {
        toast.error('Error fetching users');
      }
    };
    fetchUsers();
  }, []);

  const handleRowClick = (deal: Deal) => {
    setSelectedDeal(deal);
    setCurrentStep(0);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (size: number) => {
    setPageSize(size);
    setCurrentPage(1); // Reset to first page when changing page size
  };

  return (
    <div className="p-6 space-y-6">
      <h1 className="text-2xl font-bold text-foreground">Deals to Migrate</h1>
      
      <Table
        data={deals}
        columns={columns}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        onRowClick={handleRowClick}
        className="w-full"
      />

      {selectedDeal && (
        <div className="space-y-6">
          <h2 className="text-xl font-semibold text-foreground">Migrating: {selectedDeal.name}</h2>
          
          <ProgressSteps
            steps={steps}
            currentStep={currentStep}
            variant="horizontal"
            className="mb-6"
          />

          <div className="p-4 border rounded-lg">
            {loading ? (
              <Loading message="Processing..." />
            ) : (
              <>
                {currentStep === 0 && (
                  <div className="space-y-4">
                    {renderDealData()}
                    <div className="flex justify-end gap-2">
                      <Button onClick={handleSave}>
                        {(() => {
                          const summary = getChangesSummary();
                          if (summary.total === 0) {
                            return 'Save All Fields';
                          }
                          return `Save (${summary.changedFields} changes${
                            summary.newFields > 0 ? `, ${summary.newFields} new fields` : ''
                          })`;
                        })()}
                      </Button>
                      <Button onClick={() => setCurrentStep(1)}>
                        Next
                      </Button>
                    </div>
                  </div>
                )}
                {currentStep === 1 && (
                  <div className="space-y-4">
                    <div className="products-section">
                      <h3 className="text-foreground font-semibold">Deal Products</h3>
                      <Table
                        data={products}
                        columns={productColumns}
                        pageSize={10}
                        onPageChange={(page) => {/* handle page change */}}
                        className="w-full"
                      />
                    </div>

                    <div className="tasks-section">
                      <h3 className="text-foreground font-semibold">Recurring Tasks Configuration</h3>
                      {!taskConfig ? (
                        <Button 
                          onClick={handleConfigureTasks}
                          disabled={loading}
                        >
                          {loading ? (
                            <>
                              <Loader2 className="h-4 w-4 animate-spin" />
                              Configuring...
                            </>
                          ) : (
                            'Configure Recurring Tasks'
                          )}
                        </Button>
                      ) : (
                        <>
                          <Table
                            data={editedTasks ?? []}
                            columns={taskColumns}
                            pageSize={10}
                            onPageChange={(page) => {/* handle page change */}}
                            className="w-full"
                          />
                          <Button 
                            onClick={handleSaveTaskConfig}
                            disabled={loading || tasksStatus === 'success'}
                          >
                            {loading ? (
                              <>
                                <Loader2 className="h-4 w-4 animate-spin" />
                                Saving...
                              </>
                            ) : (
                              'Save Task Configuration'
                            )}
                          </Button>
                        </>
                      )}
                    </div>

                    <div className="flex justify-end gap-2">
                      <Button onClick={handlePrev}>Previous</Button>
                      <Button onClick={handleNext}>Next</Button>
                    </div>
                  </div>
                )}
                {currentStep === 2 && (
                  <div className="space-y-4">
                    <h3 className="text-lg font-semibold text-foreground">Complete Migration</h3>
                    <p className="text-muted-foreground">
                      Review all changes and click the button below to complete the migration process.
                    </p>
                    <Button 
                      onClick={handleCompleteMigration}
                      disabled={loading}
                    >
                      {loading ? (
                        <>
                          <Loader2 className="h-4 w-4 animate-spin mr-2" />
                          Completing...
                        </>
                      ) : (
                        'Complete Migration'
                      )}
                    </Button>
                    <div className="flex justify-end gap-2">
                      <Button onClick={handlePrev}>Previous</Button>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}

      <Dialog open={confirmVisible} onOpenChange={setConfirmVisible}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Confirm Changes</DialogTitle>
          </DialogHeader>
          {renderChanges()}
          <div className="flex justify-end gap-2">
            <Button variant="outline" onClick={() => setConfirmVisible(false)}>
              Cancel
            </Button>
            <Button onClick={confirmSave}>
              Save Changes
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      <AssigneeSelection
        visible={assigneeModalVisible}
        onClose={() => {
          setAssigneeModalVisible(false);
          setCurrentEditingTaskIndex(null);
        }}
        onSave={(selectedUsers) => {
          if (currentEditingTaskIndex !== null && editedTasks) {
            const newTasks = [...editedTasks];
            const selectedUser = users.find(u => u.user_id === selectedUsers[0]);
            if (selectedUser) {
              newTasks[currentEditingTaskIndex].assignee = selectedUser.user_id;
              setEditedTasks(newTasks);
            }
          }
          setAssigneeModalVisible(false);
          setCurrentEditingTaskIndex(null);
        }}
        initialAssignees={
          currentEditingTaskIndex !== null && editedTasks 
            ? [editedTasks[currentEditingTaskIndex]?.assignee].filter(Boolean) as string[]
            : []
        }
        maxAssignees={1}
      />
    </div>
  );
};

export default MigrateDeal;