import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from '../api/axiosConfig'
import { toast } from 'sonner'

// UI Components
import { Button } from "@/components/ui/button"
import { Card, CardContent } from "@/components/ui/card"
import { Badge } from "@/components/ui/badge"
import { Loading } from "@/components/ui/loading"
import {
  Table,
  type ColumnDef
} from "@/components/ui/specialized/mod-table"
import { Input } from "@/components/ui/input"
import { DatePicker } from "@/components/ui/date/date-picker"

// Icons
import { Users, Clock, CheckCircle, Plus, Search } from 'lucide-react'

// Types
import type { ChurnStats, Churn, ChurnFormValues, ChurnReason } from '@/types/offboarding'

// Components
import ChurnModal from './ChurnModal'

// Constants
const churnReasons: ChurnReason[] = [
  { value: "price", label: "Price" },
  { value: "service", label: "Service" },
  { value: "product", label: "Product" },
  { value: "competitor", label: "Competitor" },
  { value: "oversold", label: "Oversold" },
  { value: "internal_accountant", label: "Internal accountant" },
  { value: "physical_accountant", label: "Wants physical accountant/auditor" },
  { value: "business_closed", label: "Business closed" },
  { value: "terminated", label: "Terminated" },
  { value: "lost_trust", label: "Lost trust" },
  { value: "communication_issues", label: "Lack of communication, miscommunication or spam" },
  { value: "errors", label: "Errors (onboarding, bookkeeping, etc.)" },
  { value: "other", label: "Other" }
]

// Utility function to get reason label
const getReasonLabel = (value: string): string => {
  const reason = churnReasons.find(r => r.value === value)
  return reason ? reason.label : value
}

const OffboardingDashboard = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [stats, setStats] = useState<ChurnStats>({ active: 0, pending: 0, completed: 0 })
  const [churns, setChurns] = useState<Churn[]>([])
  const [loading, setLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [searchQuery, setSearchQuery] = useState("")
  const navigate = useNavigate()

  useEffect(() => {
    fetchStats()
    fetchChurns()
  }, [])

  const fetchStats = async () => {
    try {
      const response = await axios.get('/churn/stats')
      setStats(response.data)
    } catch (error) {
      console.error('Error:', error)
      toast.error('Failed to fetch churn statistics')
    }
  }

  const fetchChurns = async () => {
    setLoading(true)
    try {
      const response = await axios.get('/churn/')
      setChurns(response.data)
    } catch (error) {
      console.error('Error:', error)
      toast.error('Failed to fetch churns')
    } finally {
      setLoading(false)
    }
  }

  const handleChurnSubmit = async (churnData: ChurnFormValues) => {
    try {
      await axios.post('/churn/', churnData)
      toast.success('Churn process initiated successfully')
      setCurrentPage(1)
      fetchStats()
      fetchChurns()
      setIsModalOpen(false)
    } catch (error) {
      console.error('Error:', error)
      toast.error('Failed to initiate churn process')
    }
  }

  const filteredChurns = churns.filter(churn => {
    const searchLower = searchQuery.toLowerCase()
    return churn.deal_name?.toLowerCase().includes(searchLower)
  })

  const columns: ColumnDef<Churn>[] = [
    {
      key: 'deal_name',
      title: 'Customer Name',
      dataIndex: 'deal_name',
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
      filterable: true,
      filters: [
        { text: 'Pending', value: 'new' },
        { text: 'In Progress', value: 'in_progress' },
        { text: 'Completed', value: 'churned' },
        { text: 'Reverted', value: 'reverted' }
      ],
      onFilter: (value: string, record: Churn) => record.status === value,
      cell: ({ row }) => {
        const statusMap = {
          new: { label: 'Pending', variant: 'secondary' },
          in_progress: { label: 'In Progress', variant: 'warning' },
          churned: { label: 'Completed', variant: 'success' },
          reverted: { label: 'Reverted', variant: 'destructive' }
        }
        const status = statusMap[row.original.status]
        return (
          <Badge variant={status.variant as any}>
            {status.label}
          </Badge>
        )
      }
    },
    {
      key: 'reason',
      title: 'Reason',
      dataIndex: 'reason',
      filterable: true,
      filters: churnReasons.map(reason => ({
        text: reason.label,
        value: reason.value
      })),
      onFilter: (value: string, record: Churn) => record.reason === value,
      cell: ({ row }) => getReasonLabel(row.original.reason)
    },
    {
      key: 'created_at',
      title: 'Created At',
      dataIndex: 'created_at',
      sortable: {
        enabled: true,
        type: 'date'
      },
      cell: ({ row }) => new Date(row.original.created_at).toLocaleDateString()
    },
    {
      key: 'churn_date',
      title: 'Churn Date',
      dataIndex: 'churn_date',
      sortable: {
        enabled: true,
        type: 'date'
      },
      cell: ({ row }) => {
        if (row.original.status === 'new') {
          return (
            <DatePicker
              date={row.original.churn_date ? new Date(row.original.churn_date) : undefined}
              onSelect={async (date: Date | undefined) => {
                if (date) {
                  try {
                    await axios.put(`/churn/${row.original.id}/churn-date`, {
                      churn_date: date.toISOString()
                    })
                    toast.success('Churn date updated')
                    fetchChurns()
                  } catch (error) {
                    console.error('Error:', error)
                    toast.error('Failed to update churn date')
                  }
                }
              }}
              variant="short"
            />
          )
        }
        return row.original.churn_date ? new Date(row.original.churn_date).toLocaleDateString() : '-'
      }
    },
    {
      key: 'actions',
      title: 'Actions',
      dataIndex: 'id',
      cell: ({ row }) => (
        <Button 
          variant="ghost"
          onClick={() => navigate(`/customers/offboarding/${row.original.id}`)}
        >
          View Details
        </Button>
      )
    }
  ]

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const handlePageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize)
    setCurrentPage(1)
  }

  if (loading) {
    return <Loading message="Loading churn dashboard..." />
  }

  return (
    <div className="space-y-8">
      <div className="flex items-center justify-between">
        <h1 className="text-3xl font-bold text-foreground">Offboarding</h1>
      </div>

      <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
        <Card>
          <CardContent className="flex items-center gap-4 pt-6">
            <Users className="h-8 w-8 text-primary" />
            <div>
              <p className="text-sm font-medium">Active Offboardings</p>
              <p className="text-2xl font-bold">{stats.active}</p>
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardContent className="flex items-center gap-4 pt-6">
            <Clock className="h-8 w-8 text-primary" />
            <div>
              <p className="text-sm font-medium">Pending Offboardings</p>
              <p className="text-2xl font-bold">{stats.pending}</p>
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardContent className="flex items-center gap-4 pt-6">
            <CheckCircle className="h-8 w-8 text-primary" />
            <div>
              <p className="text-sm font-medium">Completed Offboardings</p>
              <p className="text-2xl font-bold">{stats.completed}</p>
            </div>
          </CardContent>
        </Card>
      </div>

      <div className="flex items-center justify-between gap-4">
        <div className="relative flex-1 max-w-sm">
          <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
          <Input
            placeholder="Search by customer name..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="pl-8"
          />
        </div>
        <Button onClick={() => setIsModalOpen(true)}>
          <Plus className="mr-2 h-4 w-4" />
          Add Churned Customer
        </Button>
      </div>

      <div>
        <Table 
          data={filteredChurns}
          columns={columns}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </div>

      <ChurnModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleChurnSubmit}
      />
    </div>
  )
}

export default OffboardingDashboard