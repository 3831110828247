import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '@/api/axiosConfig';
import { toast } from 'sonner';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Badge } from '@/components/ui/badge';
import { Loading } from '@/components/ui/loading';
import { ArrowLeft, FormInput, Calendar, ArrowRight } from 'lucide-react';
import { format } from 'date-fns';

interface Deal {
  id: number;
  name: string;
  company: string;
  onboard_status: string;
  created_at: string;
  updated_at: string;
  onboarding?: {
    id: number;
    current_node_id: string;
    completed_nodes: string[];
    form_data: Record<string, any>;
    status: string;
    created_at: string;
    updated_at: string;
  };
}

export default function OnboardingCurrent() {
  const navigate = useNavigate();
  const [deals, setDeals] = useState<Deal[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchCurrentOnboardings();
  }, []);

  const fetchCurrentOnboardings = async () => {
    try {
      const response = await axios.get('/onboarding/current');
      if (response.data && Array.isArray(response.data)) {
        setDeals(response.data);
      } else {
        setDeals([]);
        toast.error('Invalid data format received from server');
      }
    } catch (error) {
      toast.error('Failed to fetch current onboardings');
      console.error('Error fetching onboardings:', error);
      setDeals([]);
    } finally {
      setLoading(false);
    }
  };

  const getProgressPercentage = (deal: Deal) => {
    if (!deal.onboarding) return 0;
    const { completed_nodes } = deal.onboarding;
    // This is a placeholder - you'll need to get the total nodes from the flow
    const totalNodes = 10; // Replace with actual total nodes
    return Math.round((completed_nodes.length / totalNodes) * 100);
  };

  const resumeOnboarding = (deal: Deal) => {
    if (deal.onboarding) {
      // For automated flow onboarding
      window.open(
        `/customers/onboarding/flow/${deal.id}/${deal.onboarding.id}`,
        '_blank',
        'width=1200,height=800'
      );
    } else {
      // For temporary onboarding
      navigate(`/customers/onboarding/temp/${deal.id}`);
    }
  };

  if (loading) {
    return <Loading message="Loading onboardings..." />;
  }

  return (
    <div className="p-8">
      <div className="mb-8 space-y-4">
        <Button
          variant="ghost"
          onClick={() => navigate('/customers/onboarding')}
          className="mb-4"
        >
          <ArrowLeft className="h-4 w-4 mr-2" />
          Back to Dashboard
        </Button>
        
        <div>
          <h1 className="text-3xl font-bold text-foreground">Current Onboardings</h1>
          <p className="text-muted-foreground">
            Manage ongoing customer onboarding processes
          </p>
        </div>
      </div>

      <Card>
        <CardHeader>
          <CardTitle>Active Onboardings</CardTitle>
          <CardDescription>
            Continue onboarding process for customers
          </CardDescription>
        </CardHeader>
        <CardContent>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Customer</TableHead>
                <TableHead>Status</TableHead>
                <TableHead>Progress</TableHead>
                <TableHead>Last Update</TableHead>
                <TableHead>Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {deals.map((deal) => (
                <TableRow key={deal.id}>
                  <TableCell className="font-medium">
                    <div className="space-y-1">
                      <div>{deal.name}</div>
                      <div className="text-sm text-muted-foreground">
                        Started {format(new Date(deal.onboarding?.created_at || deal.created_at), 'PPP')}
                      </div>
                    </div>
                  </TableCell>
                  <TableCell>
                    <Badge variant={deal.onboarding?.status === 'in_progress' ? 'default' : 'secondary'}>
                      {deal.onboarding ? (
                        deal.onboarding.status === 'in_progress' ? 'In Progress' : 'Paused'
                      ) : (
                        'Temporary Onboarding'
                      )}
                    </Badge>
                  </TableCell>
                  <TableCell>
                    {deal.onboarding ? (
                      <div className="flex items-center space-x-2">
                        <div className="w-full bg-secondary h-2 rounded-full">
                          <div
                            className="bg-primary h-2 rounded-full"
                            style={{ width: `${getProgressPercentage(deal)}%` }}
                          />
                        </div>
                        <span className="text-sm text-muted-foreground">
                          {getProgressPercentage(deal)}%
                        </span>
                      </div>
                    ) : (
                      <span className="text-sm text-muted-foreground">N/A</span>
                    )}
                  </TableCell>
                  <TableCell>
                    <div className="flex items-center text-muted-foreground">
                      <Calendar className="h-4 w-4 mr-2" />
                      {format(new Date(deal.onboarding?.updated_at || deal.updated_at), 'PPP p')}
                    </div>
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() => resumeOnboarding(deal)}
                      className="space-x-2"
                    >
                      <FormInput className="h-4 w-4" />
                      <span>Continue</span>
                      <ArrowRight className="h-4 w-4" />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
              {deals.length === 0 && (
                <TableRow>
                  <TableCell colSpan={5} className="text-center py-8 text-muted-foreground">
                    No active onboardings
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </div>
  );
} 