import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Routes, Route, useLocation, useSearchParams, Navigate, useParams, Outlet, useNavigate } from 'react-router-dom';
import { AppSidebar } from '@/components/app-sidebar';
import { SidebarProvider } from '@/contexts/SidebarContext';
import { useSidebar } from "@/contexts/SidebarContext";
import { cn } from '@/lib/utils';
import { useTheme } from '@/contexts/ThemeContext';
import { NotificationProvider } from '@/contexts/NotificationContext';
import { StoredUser } from '@/types/user';
import { CardConfig } from '@/taskboards/config/card';
import { AppHeader } from '@/components/app-header';
import { useNotificationChecker } from '@/toasts/notification-toast';
import { toast } from 'sonner';
import { ScrollArea } from "@/components/ui/scroll-area"

import Home from './Home';
import Work from './Work';
import Dashboard from './Dashboard';
import OverviewDash from '../digi-tal-dashboard/OverviewDash';
import TaskDash from '../digi-tal-dashboard/tasks/TaskDash';
import CustomerDash from '../digi-tal-dashboard/CustomerDash';
import SalesDash from '../digi-tal-dashboard/SalesDash';
import ReceivablesDash from '../digi-tal-dashboard/ReceivablesDash';

import Deals from '../customers/Deals';
import Setup from '../customers/Setup';
import Accounts from '../customers/Accounts';
import Contacts from '../customers/Contacts';
import Products from '../customers/Products';
import OnboardingDashboard from '../onboarding/OnboardingDashboard';
import OnboardingNew from '../onboarding/OnboardingNew';
import OnboardingCurrent from '../onboarding/OnboardingCurrent';
import OnboardingTemp from '../onboarding/OnboardingTemp';
import OffboardingDashboard from '../offboarding/OffboardingDashboard';
import ChurnDetails from '../offboarding/ChurnDetails';
import Revibot from './Revibot';
import { TaskView } from '../taskboards/views/TaskView';
import MigrateDeal from '../onboarding/MigrateDeal';
import OnboardingFlow from '../onboardingflow/OnboardingFlow';
import EmailMain from '../email/EmailMain';

import { TaskBoards } from '../taskboards/TaskBoards';
import { createDynamicColumns } from '@/taskboards/config/columnConfigs';
import { getCardConfig } from '../taskboards/config/card';
import type { ColumnDef } from '@/components/ui/specialized/mod-table';
import axios from '@/api/axiosConfig';
import { useUsers } from '@/contexts/UserContext';
import Kyc from '../customers/Kyc';
import TaskBoardsOverview from '../taskboards/views/TaskBoardsOverview';
import CustomBoardList from '@/taskboards/custom/components/CustomBoardList';
import { SubtaskView } from '@/taskboards/views/SubtaskView';
import type { CustomTask } from '@/types/custom-task';
import { 
  convertBoardStatusOption,
  createColumns,
  type Board, 
  type BoardStatusOption, 
  type BoardConfig,
  type SubTask,
  type BoardUpdateResult,
  type TaskBoardProps
} from '@/types/custom-board';

interface TaskViewProps {
  onClose: () => void;
  tasks: CustomTask[];
  taskType: 'custom' | 'custom_subtask';
  boardStatusOptions: BoardStatusOption[];
  task?: CustomTask;
  fromWork: boolean;
  getBoardName: (type: string) => string;
  dealName: string;
}

interface TaskBoardRouterProps {
  users: StoredUser[];
}

type ThemeType = 'light' | 'dark';

interface PagesUser {
  user_id: string;
  name: string;
  surname: string;
  email: string;
  status: string;
  birthday?: string;
  location_info?: {
    city: string;
    country: string;
    timezone: string;
    latitude: number;
    longitude: number;
  };
  settings?: {
    theme: string;
    language: string;
    color_scheme: string;
    avatar_color: string;
    desktop_notifications: boolean;
    email_notifications: boolean;
    auto_login: boolean;
    notification_preferences: {
      general_notifications: boolean;
      custom_board_notifications: {
        task_assignments: boolean;
        subtask_assignments: boolean;
        task_comments: boolean;
        subtask_comments: boolean;
        task_updates: boolean;
        subtask_updates: boolean;
      };
    };
  };
}

interface PagesProps {
  user?: PagesUser;
  tasks?: CustomTask[];
  onTaskUpdate?: (taskId: string, updatedData: Partial<CustomTask>) => void;
  boardStatusOptions?: BoardStatusOption[];
  progressStatusOptions?: BoardStatusOption[];
  users?: StoredUser[];
  loading?: boolean;
  board?: Board | null;
}

// Update the TaskViewComponent type
type TaskViewComponent = React.FC<{
  onClose: () => void;
  tasks: CustomTask[];
  setTasks: React.Dispatch<React.SetStateAction<CustomTask[]>>;
  taskType: 'custom' | 'custom_subtask';
  boardStatusOptions: BoardStatusOption[];
  task?: CustomTask;
  fromWork: boolean;
  getBoardName: () => string;
  dealName: string;
  boardType?: string;
  taskId?: string;
}>;

// Use type assertion with unknown intermediary
const TaskViewWithTypes = TaskView as unknown as TaskViewComponent;

// Add custom board columns and configs
const customBoardColumns: ColumnDef<CustomTask>[] = [
  {
    title: 'Title',
    key: 'title',
    dataIndex: 'title',
    cell: ({ row }: { row: { original: CustomTask } }) => row.original.title || '',
  },
  {
    title: 'Description',
    key: 'description',
    dataIndex: 'description',
    cell: ({ row }: { row: { original: CustomTask } }) => row.original.description || '',
  },
];

const customCardConfig: CardConfig = {
  title: true,
  showProgress: true,
  showDates: true,
  showAssignees: true,
  showFrequency: false,
  showAssignee: false,
  showDealName: true,
  showBoardName: true
};

// Update the CustomBoardRoute component
function CustomBoardRoute() {
  const { boardId } = useParams();
  const [board, setBoard] = useState<Board | null>(null);
  const [tasks, setTasks] = useState<CustomTask[]>([]);
  const [selectedTask, setSelectedTask] = useState<CustomTask | null>(null);
  const [selectedSubtask, setSelectedSubtask] = useState<SubTask | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { users } = useUsers();
  const [isBoardConfigLoading, setIsBoardConfigLoading] = useState(true);
  const [isTasksLoading, setIsTasksLoading] = useState(true);

  // Add tasks fetching
  useEffect(() => {
    const fetchTasks = async () => {
      if (!boardId || !board) return;
      
      setIsTasksLoading(true); // Set loading true before fetch
      try {
        const tasksRes = await axios.get(`/custom-board/boards/${boardId}/tasks`);
        setTasks(tasksRes.data);
      } catch (error) {
        toast.error('Failed to load tasks');
      } finally {
        setIsTasksLoading(false); // Set loading false after fetch completes
      }
    };

    if (board) {
      fetchTasks();
    }
  }, [boardId, board]);

  // Update board fetching to properly handle loading states
  useEffect(() => {
    const fetchBoardData = async () => {
      setIsBoardConfigLoading(true);
      setIsTasksLoading(true); // Reset tasks loading when board changes
      
      if (!boardId) {
        setIsBoardConfigLoading(false);
        setIsTasksLoading(false);
        return;
      }

      try {
        const boardRes = await axios.get(`/custom-board/boards/${boardId}`);
        setBoard(boardRes.data);
      } catch (error) {
        toast.error('Failed to load board data');
      } finally {
        setIsBoardConfigLoading(false);
      }
    };

    fetchBoardData();
  }, [boardId]);

  useEffect(() => {
    const match = location.pathname.match(/\/custom-boards\/\d+(?:\/task\/(\d+))?(?:\/subtask\/(\d+))?/);
    if (match) {
      const [, taskId, subtaskId] = match;
      const skipTaskView = location.state?.skipTaskView;
      const stateTask = location.state?.task;
      const stateSubtask = location.state?.subtask;

      if (taskId && subtaskId) {
        // If we have state data, use it immediately without waiting for tasks
        if (stateTask && stateSubtask) {
          setSelectedTask(stateTask);
          setSelectedSubtask(stateSubtask);
          return;
        }

        // Only look in tasks array if we don't have state data
        const task = tasks.find(t => t.id.toString() === taskId);
        if (task) {
          const subtask = task.subtasks?.find(s => s.id.toString() === subtaskId);
          if (subtask) {
            setSelectedTask(task);
            setSelectedSubtask({
              ...subtask,
              progress_status_id: Number(subtask.progress_status_id) || 0,
              created_by: subtask.created_by || 0,
              created_at: subtask.created_at || new Date().toISOString(),
              updated_at: subtask.updated_at || new Date().toISOString(),
            });
          }
        }
      } else if (taskId) {
        const task = tasks.find(t => t.id.toString() === taskId);
        setSelectedTask(task || null);
        setSelectedSubtask(null);
      } else {
        setSelectedTask(null);
        setSelectedSubtask(null);
      }
    }
  }, [location.pathname, location.state]); // Remove tasks dependency to prevent re-running

  const handleCloseTask = () => {
    navigate(`/custom-boards/${boardId}`, { replace: true });
    setSelectedTask(null);
    setSelectedSubtask(null);
  };

  const handleCloseSubtask = () => {
    navigate(`/custom-boards/${boardId}`, { replace: true });
    setSelectedSubtask(null);
    setSelectedTask(null);
  };

  // Create empty board status options for initial render
  const emptyBoardStatusOptions: BoardStatusOption[] = [];
  const emptyProgressStatusOptions: BoardStatusOption[] = [];

  // Use either the board's status options or empty arrays
  const boardStatusOptions = board?.board_config?.board_status_options 
    ? convertBoardStatusOption(board.board_config.board_status_options)
    : emptyBoardStatusOptions;
    
  const progressStatusOptions = board?.board_config?.progress_status_options
    ? convertBoardStatusOption(board.board_config.progress_status_options)
    : emptyProgressStatusOptions;

  const handleBoardUpdate = async (result: BoardUpdateResult) => {
    // Update board state
    setBoard(result.updatedBoard);
    
    // If config changed, trigger a refetch of tasks
    if (result.configChanged) {
      setIsTasksLoading(true);
      setTasks([]); // Clear existing tasks
      try {
        const tasksRes = await axios.get(`/custom-board/boards/${boardId}/tasks`);
        setTasks(tasksRes.data);
      } catch (error) {
        toast.error('Failed to reload tasks');
      } finally {
        setIsTasksLoading(false);
      }
    }
  };

  return (
    <>
      <TaskBoards 
        title={board?.name || ''}
        fetchUrl={`/custom-board/boards/${boardId}/tasks`}
        columns={customBoardColumns}
        boardStatusOptions={boardStatusOptions}
        cardConfig={customCardConfig}
        dealDataConfig={[]}
        dealSoftwareConfig={[]}
        dealOnboardingConfig={[]}
        taskType="custom"
        defaultView="kanban"
        users={users}
        tasks={tasks}
        setTasks={setTasks}
        isCustomBoard={true}
        boardConfig={{
          kanban_source: board?.board_config?.kanban_source,
          progress_status_options: progressStatusOptions,
          board_status_options: boardStatusOptions
        }}
        board={board}
        loading={isTasksLoading}
        isBoardConfigLoading={isBoardConfigLoading}
        onBoardUpdate={handleBoardUpdate}
      />
      {selectedTask && !selectedSubtask && !location.state?.skipTaskView && (
        <TaskView 
          onClose={handleCloseTask}
          tasks={tasks}
          setTasks={setTasks}
          taskType="custom"
          boardStatusOptions={board?.board_config?.progress_status_options || []}
          task={selectedTask}
          fromWork={false}
          getBoardName={() => board?.name || ''}
          dealName={selectedTask?.deal_name || ''}
          board={board}
          users={users}
        />
      )}
      {selectedTask && selectedSubtask && (
        <SubtaskView 
          subtask={{
            ...selectedSubtask,
            progress_status_id: Number(selectedSubtask.progress_status_id) || 0,
            created_by: selectedSubtask.created_by || 0,
            created_at: selectedSubtask.created_at || new Date().toISOString(),
            updated_at: selectedSubtask.updated_at || new Date().toISOString(),
          }}
          task={selectedTask}
          board={board}
          onClose={handleCloseSubtask}
          onTaskUpdate={(updatedTask) => {
            setTasks(prevTasks => 
              prevTasks.map(t => 
                t.id === updatedTask.id ? updatedTask : t
              )
            );
          }}
        />
      )}
    </>
  );
}

export function Pages({ 
  user,
  tasks = [],
  onTaskUpdate = () => {},
  boardStatusOptions = [],
  loading = false,
  board = null
}: PagesProps) {
  const { theme, toggleTheme } = useTheme();
  const { collapsed } = useSidebar();
  const location = useLocation();
  const { users = [] } = useUsers();
  const navigate = useNavigate();
  const isOnboardingForm = location.pathname.includes('/onboarding/customer-onboarding-form');

  if (!user) {
    return null;
  }

  const convertedBoardStatusOptions = boardStatusOptions?.map((option: BoardStatusOption, index: number) => ({
    ...option,
    id: option.id || index + 1,
    order: option.order || index
  })) || [];

  return (
    <SidebarProvider>
      <NotificationProvider>
        <div className="flex h-screen overflow-hidden">
          <AppSidebar 
            user={user}
            onUpdateUsers={() => {}}
          />
          <div className="flex-1 flex flex-col min-w-0">
            <AppHeader 
              theme={theme}
              toggleTheme={toggleTheme}
            />
            <main 
              className={cn(
                "flex-1 min-h-0 overflow-auto relative",
                "scrollbar-thin scrollbar-thumb-rounded-lg scrollbar-track-transparent",
                "scrollbar-thumb-muted-foreground/20 hover:scrollbar-thumb-muted-foreground/30",
                isOnboardingForm ? "p-0" : location.pathname.includes('/custom-boards') 
                  ? "pt-6"
                  : "p-6"
              )}
            >
              <div className={cn(
                "min-h-full relative",
                !isOnboardingForm && !location.pathname.includes('/custom-boards') && "after:content-[''] after:block after:h-6"
              )}>
                <Routes>
                  <Route path="/home" element={<Home user={user} />} />
                  <Route path="/work" element={<WorkWithTaskView />}>
                    <Route path=":taskId" element={null} />
                  </Route>
                  
                  <Route path="/dashboard" element={<Dashboard />}>
                    <Route index element={<OverviewDash />} />
                    <Route path="tasks" element={<TaskDash />} />
                    <Route path="customers" element={<CustomerDash />} />
                    <Route path="sales" element={<SalesDash />} />
                    <Route path="receivables" element={<ReceivablesDash />} />
                  </Route>
                  
                  <Route path="/customers" element={<Outlet />}>
                    <Route index element={<Navigate to="/customers/deals" replace />} />
                    <Route path="deals">
                      <Route index element={<Deals />} />
                      <Route path="setup" element={<Setup />} />
                    </Route>
                    <Route path="accounts" element={<Accounts />} />
                    <Route path="contacts" element={<Contacts />} />
                    <Route path="products" element={<Products />} />
                    <Route path="kyc" element={<Kyc />} />
                    
                    <Route path="onboarding">
                      <Route index element={<OnboardingDashboard />} />
                      <Route path="cases" element={<OnboardingNew />} />
                      <Route path="current" element={<OnboardingCurrent />} />
                      <Route path="migrations" element={<MigrateDeal />} />
                      <Route path="temp/:dealId" element={<OnboardingTemp />} />
                      <Route path="flow/:dealId/:onboardingId" element={<OnboardingFlow />} />
                    </Route>

                    <Route path="offboarding">
                      <Route index element={<OffboardingDashboard />} />
                      <Route path=":id" element={<ChurnDetails />} />
                    </Route>
                  </Route>

                  <Route path="/revibot" element={<Revibot />} />

                  <Route path="/custom-boards/*">
                    <Route index element={<CustomBoardList />} />
                    <Route path=":boardId/*" element={<CustomBoardRoute />} />
                  </Route>

                  <Route path="/email" element={<EmailMain />} />
                </Routes>
              </div>
            </main>
          </div>
        </div>
      </NotificationProvider>
    </SidebarProvider>
  );
}

function WorkWithTaskView() {
  const location = useLocation();
  const { taskId } = useParams();
  const [tasks, setTasks] = useState<CustomTask[]>([]);
  const [boardStatusOptions, setBoardStatusOptions] = useState<BoardStatusOption[]>([]);
  const [board, setBoard] = useState<Board | null>(null);

  useEffect(() => {
    if (taskId) {
      const fetchBoardAndTasks = async () => {
        try {
          const taskResponse = await axios.get(`/custom-board/tasks/${taskId}`);
          const boardResponse = await axios.get(`/custom-board/boards/${taskResponse.data.board_id}`);
          setBoard(boardResponse.data);
          setTasks([taskResponse.data]);
          setBoardStatusOptions(boardResponse.data.board_config?.progress_status_options || []);
        } catch (error) {
          console.error('Error fetching task and board:', error);
        }
      };
      fetchBoardAndTasks();
    }
  }, [taskId]);

  return (
    <>
      <Work />
      <Outlet />
      {taskId && board && (
        <TaskView 
          task={tasks[0]}
          tasks={tasks}
          setTasks={setTasks}
          taskType="custom"
          boardStatusOptions={boardStatusOptions}
          fromWork={true}
          getBoardName={() => board.name}
          dealName={tasks[0]?.deal_name || ""}
          onClose={() => {/* implement close handler */}}
          board={board}
          users={[]}
        />
      )}
    </>
  );
}

function TaskBoardRouter({ users }: TaskBoardRouterProps) {
  const { boardType } = useParams();
  const [board, setBoard] = useState<Board | null>(null);
  const [tasks, setTasks] = useState<CustomTask[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBoard = async () => {
      if (!boardType) return;
      
      try {
        const response = await axios.get(`/custom-board/boards/${boardType}`);
        setBoard(response.data);
      } catch (error) {
        console.error('Error fetching board:', error);
      }
    };

    fetchBoard();
  }, [boardType]);

  const defaultBoardConfig: BoardConfig = {
    requires_deal: false,
    requires_work_date: false,
    requires_due_date: false,
    show_in_my_work: false,
    enable_time_tracking: false,
    enable_subtasks: false,
    enable_assignees: false,
    simplify_main_tasks: false,
    enable_automations: false,
    enable_in_flows: false,
    icon: 'FolderKanban',
    kanban_source: 'progress',
    progress_status_options: [],
    board_status_options: [],
    automations: [],
    notifications: {
      enabled: false,
      task_assignments: false,
      subtask_assignments: false,
      task_comments: false,
      subtask_comments: false,
      task_updates: false,
      subtask_updates: false
    },
    card_fields: [],
    enable_completion_events: false,
    completion_settings: {
      enable_task_completion_events: false,
      enable_subtask_completion_events: false,
      show_confetti: false,
      show_completion_drawer: false
    },
    default_view: 'kanban',
    enable_calendar_view: false,
    enable_recurring_tasks: false,
    recurring_task_settings: {
      require_recurrence: false,
      recurrence_options: []
    }
  };

  const convertedColumns = createDynamicColumns(board?.board_config || defaultBoardConfig);

  return (
    <TaskBoards
      title={board?.name || ''}
      fetchUrl={`/custom-board/boards/${boardType}/tasks`}
      columns={convertedColumns}
      boardStatusOptions={convertBoardStatusOption(board?.board_config?.board_status_options)}
      cardConfig={getCardConfig(board)}
      dealDataConfig={[]}
      dealSoftwareConfig={[]}
      dealOnboardingConfig={[]}
      taskType={boardType as 'custom' | 'custom_subtask'}
      defaultView="kanban"
      users={users}
      tasks={tasks}
      setTasks={setTasks}
      loading={loading}
      boardConfig={{
        kanban_source: board?.board_config?.kanban_source,
        progress_status_options: board?.board_config?.progress_status_options || [],
        board_status_options: board?.board_config?.board_status_options || []
      }}
      board={board}
    />
  );
}

function BoardTypeRouter({ users }: { users: StoredUser[] }) {
  const { boardType } = useParams();
  
  if (boardType === 'custom') {
    return <Navigate to="/custom-boards" replace />;
  }
  
  return <TaskBoardRouter users={users} />;
}
