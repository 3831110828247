import React from 'react';
import { Plus, Minus, ArrowUp, ArrowDown, Trash2, ChevronUp, ChevronDown } from 'lucide-react';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Switch } from "@/components/ui/switch";
import { Badge } from "@/components/ui/badge";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Separator } from "@/components/ui/separator";
import { ANT_COLORS } from '@/configs/colors';
import { type BoardStatusOption, type ProgressStatusOption } from '@/types/custom-board';

interface StatusOptionsConfigProps {
  form: UseFormReturn<any>;
  kanbanSource: 'progress' | 'board';
  useBoardStatus: boolean;
  setUseBoardStatus: (value: boolean) => void;
  onKanbanSourceChange?: (source: 'progress' | 'board') => void;
}

const DEFAULT_STATUS_COLORS: string[] = [
  '#f5222d', // red
  '#faad14', // yellow
  '#1890ff', // blue
  '#52c41a', // green
  '#722ed1', // purple
  '#eb2f96', // pink
  '#fa8c16', // orange
  '#13c2c2', // cyan
  '#2f54eb', // geekblue
  '#a0d911'  // lime
];

export function StatusOptionsConfig({
  form,
  kanbanSource,
  useBoardStatus,
  setUseBoardStatus,
  onKanbanSourceChange
}: StatusOptionsConfigProps) {
  const progressStatusArray = useFieldArray({
    control: form.control,
    name: 'board_config.progress_status_options'
  });

  const boardStatusArray = useFieldArray({
    control: form.control,
    name: 'board_config.board_status_options'
  });

  const handleAddStatus = (type: 'progress' | 'board') => {
    const array = type === 'progress' ? progressStatusArray : boardStatusArray;
    const currentLength = array.fields.length;
    
    array.append({
      id: currentLength + 1,
      value: '',
      label: '',
      color: DEFAULT_STATUS_COLORS[currentLength % DEFAULT_STATUS_COLORS.length],
      description: '',
      order: currentLength,
      is_completed_state: false
    });
  };

  const handleRemoveStatus = (type: 'progress' | 'board', index: number) => {
    const array = type === 'progress' ? progressStatusArray : boardStatusArray;
    array.remove(index);
  };

  const handleMoveStatus = (type: 'progress' | 'board', index: number, direction: 'up' | 'down') => {
    const array = type === 'progress' ? progressStatusArray : boardStatusArray;
    const newIndex = direction === 'up' ? index - 1 : index + 1;
    
    if (newIndex >= 0 && newIndex < array.fields.length) {
      array.swap(index, newIndex);
    }
  };

  const renderStatusOptionsList = (type: 'progress' | 'board') => {
    const fieldArray = type === 'progress' ? progressStatusArray : boardStatusArray;
    const fieldName = type === 'progress' ? 'board_config.progress_status_options' : 'board_config.board_status_options';

    const isProgressStatus = (field: any): field is ProgressStatusOption => {
      return type === 'progress' && 'is_completed_state' in field;
    };

    return (
      <div className="space-y-4">
        {fieldArray.fields.map((field, index) => (
          <div key={field.id} className="flex flex-col gap-4">
            <div className="flex items-center gap-4">
              <FormField
                control={form.control}
                name={`${fieldName}.${index}.label` as const}
                render={({ field: labelField }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        {...labelField}
                        placeholder="Status label"
                        onChange={(e) => {
                          const newValue = e.target.value;
                          const currentField = form.getValues(`${fieldName}.${index}`);
                          const baseFields = {
                            id: currentField.id ?? index + 1,
                            value: newValue.toLowerCase().replace(/\s+/g, '_'),
                            label: newValue,
                            color: currentField.color || '#1890ff',
                            description: currentField.description || null,
                            order: currentField.order ?? index,
                          };

                          if (isProgressStatus(currentField)) {
                            form.setValue(`${fieldName}.${index}`, {
                              ...baseFields,
                              is_completed_state: currentField.is_completed_state ?? false
                            }, {
                              shouldDirty: true,
                              shouldTouch: true
                            });
                          } else {
                            form.setValue(`${fieldName}.${index}`, baseFields, {
                              shouldDirty: true,
                              shouldTouch: true
                            });
                          }
                        }}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name={`${fieldName}.${index}.color` as const}
                render={({ field: colorField }) => (
                  <FormItem>
                    <FormControl>
                      <Select
                        value={colorField.value}
                        onValueChange={(value) => {
                          const currentField = form.getValues(`${fieldName}.${index}`);
                          form.setValue(`${fieldName}.${index}`, {
                            ...currentField,
                            color: value
                          }, {
                            shouldDirty: true,
                            shouldTouch: true
                          });
                        }}
                      >
                        <SelectTrigger className="w-[180px]">
                          <SelectValue placeholder="Select color" />
                        </SelectTrigger>
                        <SelectContent>
                          {ANT_COLORS.map(color => (
                            <SelectItem
                              key={color.value}
                              value={color.value}
                            >
                              <div 
                                className="w-full h-5 flex items-center px-2 rounded"
                                style={{ 
                                  backgroundColor: color.value,
                                  color: ['#ffec3d', '#fff566', '#ffd666', '#fff1b8'].includes(color.value) ? '#000000' : '#ffffff'
                                }}
                              >
                                {color.name}
                              </div>
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                  </FormItem>
                )}
              />

              {type === 'progress' && (
                <FormField
                  control={form.control}
                  name={`board_config.progress_status_options.${index}.is_completed_state`}
                  render={({ field: completedField }) => (
                    <FormItem>
                      <FormControl>
                        <div className="flex items-center gap-2">
                          <Switch
                            checked={Boolean(completedField.value)}
                            onCheckedChange={(checked) => {
                              const currentField = form.getValues(`board_config.progress_status_options.${index}`);
                              if (isProgressStatus(currentField)) {
                                form.setValue(`board_config.progress_status_options.${index}`, {
                                  ...currentField,
                                  is_completed_state: checked
                                }, {
                                  shouldDirty: true,
                                  shouldTouch: true
                                });
                              }
                            }}
                          />
                          <span className="text-sm">Completed State</span>
                        </div>
                      </FormControl>
                    </FormItem>
                  )}
                />
              )}

              <div className="flex items-center gap-1">
                <Button
                  variant="ghost"
                  size="icon"
                  className="h-8 w-8"
                  onClick={() => handleMoveStatus(type, index, 'up')}
                  disabled={index === 0}
                  type="button"
                >
                  <ChevronUp className="h-4 w-4" />
                </Button>
                <Button
                  variant="ghost"
                  size="icon"
                  className="h-8 w-8"
                  onClick={() => handleMoveStatus(type, index, 'down')}
                  disabled={index === fieldArray.fields.length - 1}
                  type="button"
                >
                  <ChevronDown className="h-4 w-4" />
                </Button>
              </div>

              <Button
                variant="ghost"
                size="icon"
                onClick={() => fieldArray.remove(index)}
                type="button"
              >
                <Trash2 className="h-4 w-4" />
              </Button>
            </div>

            <FormField
              control={form.control}
              name={`${fieldName}.${index}.description` as const}
              render={({ field: descriptionField }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      {...descriptionField}
                      placeholder="Status description (optional)"
                      className="w-full"
                      value={descriptionField.value || ''}
                      onChange={(e) => {
                        const currentField = form.getValues(`${fieldName}.${index}`);
                        form.setValue(`${fieldName}.${index}`, {
                          ...currentField,
                          description: e.target.value || null
                        }, {
                          shouldDirty: true,
                          shouldTouch: true
                        });
                      }}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          </div>
        ))}

        <Button
          type="button"
          variant="outline"
          onClick={() => {
            const nextColorIndex = fieldArray.fields.length % ANT_COLORS.length;
            const baseFields = {
              id: fieldArray.fields.length + 1,
              value: '',
              label: '',
              color: ANT_COLORS[nextColorIndex].value,
              description: null,
              order: fieldArray.fields.length,
            };

            if (type === 'progress') {
              fieldArray.append({
                ...baseFields,
                is_completed_state: false
              });
            } else {
              fieldArray.append(baseFields);
            }

            // Mark form as dirty after append
            form.trigger();
          }}
        >
          <Plus className="h-4 w-4 mr-2" />
          Add Status Option
        </Button>
      </div>
    );
  };

  const renderKanbanSourceSelector = () => (
    <div className="bg-accent/50 p-3 rounded-md">
      <div className="flex gap-2 items-center">
        <p className="text-sm font-medium">Kanban Source:</p>
        <div className="flex gap-2">
          <Button
            type="button"
            variant={kanbanSource === 'progress' ? 'default' : 'outline'}
            onClick={(e) => {
              e.preventDefault();
              form.setValue('board_config.kanban_source', 'progress', {
                shouldDirty: true,
                shouldTouch: true
              });
              onKanbanSourceChange?.('progress');
            }}
            size="sm"
          >
            Progress Status
          </Button>
          <Button
            type="button"
            variant={kanbanSource === 'board' ? 'default' : 'outline'}
            onClick={(e) => {
              e.preventDefault();
              form.setValue('board_config.kanban_source', 'board', {
                shouldDirty: true,
                shouldTouch: true
              });
              onKanbanSourceChange?.('board');
            }}
            size="sm"
          >
            Board Status
          </Button>
        </div>
      </div>
      <p className="text-sm text-muted-foreground mt-2">
        Select which status type should be used for the Kanban board stages
      </p>
    </div>
  );

  return (
    <div className="space-y-6">
      <div className="p-6 bg-muted rounded-lg space-y-4">
        <h3 className="text-lg font-semibold">
          Progress Status Options (Required)
        </h3>
        <p className="text-sm text-muted-foreground mb-4">
          {kanbanSource === 'progress' && (
            <span className="text-primary font-medium">Currently selected as Kanban stages ✓</span>
          )}
        </p>
        {renderStatusOptionsList('progress')}
      </div>

      <Separator />

      <div className="p-6 bg-muted rounded-lg space-y-4">
        <div className="flex flex-col gap-4">
          <div className="flex items-center justify-between">
            <div>
              <h3 className="text-lg font-semibold">
                Additional Board Status
              </h3>
              {useBoardStatus && (
                <p className="text-sm text-muted-foreground mt-1">
                  {kanbanSource === 'board' && (
                    <span className="text-primary font-medium">Currently selected as Kanban stages ✓</span>
                  )}
                </p>
              )}
            </div>
            <Switch
              checked={useBoardStatus}
              onCheckedChange={(checked) => {
                setUseBoardStatus(checked);
                if (!checked && kanbanSource === 'board') {
                  form.setValue('board_config.kanban_source', 'progress', {
                    shouldDirty: true,
                    shouldTouch: true
                  });
                  onKanbanSourceChange?.('progress');
                }
              }}
            />
          </div>

          {useBoardStatus && (
            <div className="flex flex-col gap-2">
              {renderKanbanSourceSelector()}
              {renderStatusOptionsList('board')}
            </div>
          )}
        </div>
      </div>
    </div>
  );
} 