import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { 
  Home, 
  Users, 
  UserPlus, 
  UserMinus,
  Bot,
  LayoutDashboard,
  ClipboardList,
  Calculator,
  Receipt,
  FileSpreadsheet,
  ListTodo,
  Activity,
  FolderKanban,
  Plus,
  Wrench,
  Briefcase,
  ChevronRight,
  HandshakeIcon,
  Building2,
  UserRound,
  Package,
  Mail,
} from 'lucide-react';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@/components/ui/collapsible';
import {
  SidebarGroup,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuAction,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
} from '@/components/ui/sidebar';
import { Link } from 'react-router-dom';
import { cn } from '@/lib/utils';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { useLanguage } from '@/contexts/LanguageContext';
import { t } from '@/i18n/language';
import axios from '@/api/axiosConfig';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip"
import CreateBoardModal from '@/taskboards/custom/modals/CreateBoardModal';
import { useUsers } from '@/contexts/UserContext';
import * as LucideIcons from 'lucide-react';

// Update the tooltipStyles to be more specific
const tooltipStyles = `
  .truncated-text[data-truncated="true"] + [data-radix-tooltip-content] {
    display: block !important;
    visibility: visible !important;
    opacity: 1 !important;
  }
`;

interface CustomBoard {
  id: number;
  name: string;
  board_type: string;
  is_public: boolean;
  board_config?: {
    icon?: string;
  };
}

interface NavSubItem {
  title: string;
  url: string;
  icon?: React.ComponentType<any>;
  onClick?: (e: React.MouseEvent) => void;
  tooltip?: string;
}

interface NavItem {
  id: string;
  title: string;
  url: string;
  icon: React.ComponentType<any>;
  isActive?: boolean;
  items?: NavSubItem[];
}

export function NavMain({ collapsed }: { collapsed?: boolean }) {
  const [customBoards, setCustomBoards] = useState<CustomBoard[]>([]);
  const location = useLocation();
  const { language } = useLanguage();
  const [openMenus, setOpenMenus] = React.useState<{ [key: string]: boolean }>({
    "taskBoards": true,
    "tools": true,
    "projects": false,
  });
  const [isCreateBoardModalOpen, setIsCreateBoardModalOpen] = useState(false);
  const { currentUser } = useUsers();

  const fetchCustomBoards = async () => {
    try {
      const response = await axios.get('/custom-board/boards');
      setCustomBoards(response.data);
    } catch (error) {
      console.error('Error fetching custom boards:', error);
    }
  };

  useEffect(() => {
    fetchCustomBoards();
  }, []);

  // Separate boards by type
  const taskBoards = customBoards.filter(board => board.board_type === 'taskboard');
  const projectBoards = customBoards.filter(board => board.board_type === 'project');

  const handleMenuClick = (item: any, event: React.MouseEvent) => {
    if (item.items?.length && !collapsed) {
      event.preventDefault();
      setOpenMenus(prev => ({
        ...prev,
        [item.id]: !prev[item.id]
      }));
    }
  };

  const isMenuItemActive = (item: any): boolean => {
    if (item.url === location.pathname) return true;
    if (item.items?.length) {
      return item.items.some((subItem: any) => 
        location.pathname.startsWith(subItem.url)
      );
    }
    return location.pathname.startsWith(item.url);
  };

  const isSubMenuItemActive = (url: string): boolean => {
    return location.pathname.startsWith(url);
  };

  const handleCreateBoardSuccess = () => {
    fetchCustomBoards();
  };

  const defaultNavItems: NavItem[] = [
    {
      id: "home",
      title: t('nav-main.home', language),
      url: "/home",
      icon: Home,
      isActive: location.pathname === '/home',
    },
    {
      id: "myWork",
      title: t('nav-main.myWork', language),
      url: "/work",
      icon: Briefcase,
      isActive: location.pathname === '/my-work',
    },
    {
      id: "email",
      title: t('nav-main.email', language),
      url: "/email",
      icon: Mail,
      isActive: location.pathname.startsWith('/email'),
    },
    {
      id: "dashboard",
      title: t('nav-main.dashboard', language),
      url: "/dashboard",
      icon: LayoutDashboard,
      isActive: location.pathname === '/dashboard',
    },
    {
      id: "customers",
      title: t('nav-main.customers.title', language),
      url: "/customers",
      icon: Users,
      items: [
        { title: t('nav-main.customers.deals', language), url: "/customers/deals", icon: HandshakeIcon },
        { title: t('nav-main.customers.accounts', language), url: "/customers/accounts", icon: Building2 },
        { title: t('nav-main.customers.contacts', language), url: "/customers/contacts", icon: UserRound },
        { title: t('nav-main.customers.products', language), url: "/customers/products", icon: Package },
        { title: t('nav-main.customers.onboarding', language), url: "/customers/onboarding", icon: UserPlus },
        { title: t('nav-main.customers.offboarding', language), url: "/customers/offboarding", icon: UserMinus },
        { title: t('nav-main.customers.kyc', language), url: "/customers/kyc", icon: FileSpreadsheet },
      ],
    },
    {
      id: "tools",
      title: t('nav-main.tools.title', language),
      url: "/tools",
      icon: Wrench,
      items: [
        { title: t('nav-main.tools.revibot', language), url: "/revibot", icon: Bot },
      ],
    },
    {
      id: "taskBoards",
      title: t('nav-main.taskBoards.title', language),
      url: "/boards",
      icon: ClipboardList,
      isActive: location.pathname.startsWith('/boards'),
      items: [
        // Only keep custom taskboards
        ...taskBoards.map(board => ({
          title: board.name,
          url: `/custom-boards/${board.id}`,
          icon: (LucideIcons as any)[board.board_config?.icon || 'FolderKanban'],
          tooltip: board.name
        })),
        {
          title: t('nav-main.taskBoards.addBoard', language),
          url: "#",
          icon: Plus,
          onClick: (e: React.MouseEvent) => {
            e.preventDefault();
            setIsCreateBoardModalOpen(true);
          }
        },
      ],
    },
    {
      id: "projects",
      title: t('nav-main.projects.title', language),
      url: "/projects",
      icon: FolderKanban,
      items: [
        // Dynamic project boards
        ...projectBoards.map(board => ({
          title: board.name,
          url: `/projects/${board.id}`,
          icon: FolderKanban,
        })),
        {
          title: t('nav-main.projects.addBoard', language),
          url: "/projects/new",
          icon: Plus,
        },
      ],
    },
  ];

  return (
    <>
      <style>{tooltipStyles}</style>
      <SidebarGroup>
        <SidebarMenu className="space-y-0">
          {defaultNavItems.map((item) => (
            collapsed && item.items?.length ? (
              <DropdownMenu key={item.id}>
                <SidebarMenuItem>
                  <TooltipProvider delayDuration={0}>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <DropdownMenuTrigger asChild>
                          <SidebarMenuButton>
                            <div className="flex justify-center w-full">
                              <item.icon className="h-4 w-4 shrink-0" />
                            </div>
                          </SidebarMenuButton>
                        </DropdownMenuTrigger>
                      </TooltipTrigger>
                      <TooltipContent side="right" align="center">
                        <p>{item.title}</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                  <DropdownMenuContent side="right" align="start" className="min-w-48">
                    {item.items.map((subItem) => (
                      <DropdownMenuItem key={subItem.title} asChild>
                        {subItem.onClick ? (
                          <button
                            onClick={subItem.onClick}
                            className="flex items-center gap-2 w-full text-left hover:bg-gray-100"
                          >
                            {subItem.icon && <subItem.icon className="h-4 w-4 shrink-0" />}
                            <TooltipProvider delayDuration={0}>
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <span 
                                    className="truncated-text truncate max-w-[120px]"
                                    ref={(el) => {
                                      if (el) {
                                        const isTruncated = el.scrollWidth > el.clientWidth;
                                        el.dataset.truncated = String(isTruncated);
                                      }
                                    }}
                                  >
                                    {subItem.title}
                                  </span>
                                </TooltipTrigger>
                                {subItem.tooltip && (
                                  <TooltipContent 
                                    side="right"
                                    sideOffset={10}
                                    className="z-50 bg-popover px-3 py-1.5 text-sm text-popover-foreground animate-in fade-in-0"
                                  >
                                    {subItem.tooltip}
                                  </TooltipContent>
                                )}
                              </Tooltip>
                            </TooltipProvider>
                          </button>
                        ) : (
                          <Link to={subItem.url} className="flex items-center gap-2 hover:bg-gray-100">
                            {subItem.icon && <subItem.icon className="h-4 w-4 shrink-0" />}
                            <TooltipProvider delayDuration={0}>
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <span 
                                    className="truncated-text truncate max-w-[120px]"
                                    ref={(el) => {
                                      if (el) {
                                        const isTruncated = el.scrollWidth > el.clientWidth;
                                        el.dataset.truncated = String(isTruncated);
                                      }
                                    }}
                                  >
                                    {subItem.title}
                                  </span>
                                </TooltipTrigger>
                                {subItem.tooltip && (
                                  <TooltipContent 
                                    side="right"
                                    sideOffset={10}
                                    className="z-50 bg-popover px-3 py-1.5 text-sm text-popover-foreground animate-in fade-in-0"
                                  >
                                    {subItem.tooltip}
                                  </TooltipContent>
                                )}
                              </Tooltip>
                            </TooltipProvider>
                          </Link>
                        )}
                      </DropdownMenuItem>
                    ))}
                  </DropdownMenuContent>
                </SidebarMenuItem>
              </DropdownMenu>
            ) : (
              <Collapsible 
                key={item.id} 
                open={openMenus[item.id]}
                onOpenChange={(isOpen) => setOpenMenus(prev => ({ ...prev, [item.id]: isOpen }))}
              >
                <SidebarMenuItem>
                  {collapsed ? (
                    <TooltipProvider delayDuration={0}>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          {item.items?.length ? (
                            <SidebarMenuButton 
                              asChild={false}
                              onClick={(e) => handleMenuClick(item, e)}
                              className={cn(
                                isMenuItemActive(item) ? "text-primary" : "text-foreground"
                              )}
                            >
                              <div className={cn(
                                "flex items-center gap-2 transition-all duration-300 ease-in-out",
                                "justify-center w-full",
                                isMenuItemActive(item) ? "text-primary" : "text-foreground"
                              )}>
                                <item.icon className="h-4 w-4 shrink-0" />
                                {!collapsed && <span>{item.title}</span>}
                                {!collapsed && item.items?.length && (
                                  <ChevronRight className={cn(
                                    "ml-auto h-4 w-4 shrink-0 transition-transform",
                                    openMenus[item.id] && "rotate-90"
                                  )} />
                                )}
                              </div>
                            </SidebarMenuButton>
                          ) : (
                            <Link to={item.url} className="w-full">
                              <SidebarMenuButton 
                                asChild={false}
                                className={cn(
                                  isMenuItemActive(item) ? "text-primary" : "text-foreground",
                                  "hover:bg-gray-100 dark:hover:bg-secondary"
                                )}
                              >
                                <div className={cn(
                                  "flex items-center gap-2 transition-all duration-300 ease-in-out",
                                  collapsed ? "justify-center" : "",
                                  "w-full",
                                  isMenuItemActive(item) ? "text-primary" : "text-foreground"
                                )}>
                                  <item.icon className="h-4 w-4 shrink-0" />
                                  {!collapsed && <span>{item.title}</span>}
                                </div>
                              </SidebarMenuButton>
                            </Link>
                          )}
                        </TooltipTrigger>
                        <TooltipContent side="right" align="center">
                          <p>{item.title}</p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  ) : (
                    item.items?.length ? (
                      <SidebarMenuButton 
                        asChild={false}
                        onClick={(e) => handleMenuClick(item, e)}
                        className={cn(
                          isMenuItemActive(item) ? "text-primary" : "text-foreground",
                          "hover:bg-gray-100 dark:hover:bg-secondary"
                        )}
                      >
                        <div className={cn(
                          "flex items-center gap-2 transition-all duration-300 ease-in-out",
                          isMenuItemActive(item) ? "text-primary" : "text-foreground"
                        )}>
                          <item.icon className="h-4 w-4 shrink-0" />
                          {!collapsed && <span>{item.title}</span>}
                          {!collapsed && (
                            <ChevronRight className={cn(
                              "ml-auto h-4 w-4 shrink-0 transition-transform",
                              openMenus[item.id] && "rotate-90"
                            )} />
                          )}
                        </div>
                      </SidebarMenuButton>
                    ) : (
                      <Link to={item.url} className="w-full">
                        <SidebarMenuButton 
                          asChild={false}
                          className={cn(
                            isMenuItemActive(item) ? "text-primary" : "text-foreground",
                            "hover:bg-gray-100 dark:hover:bg-secondary"
                          )}
                        >
                          <div className={cn(
                            "flex items-center gap-2 transition-all duration-300 ease-in-out",
                            collapsed ? "justify-center" : "",
                            "w-full",
                            isMenuItemActive(item) ? "text-primary" : "text-foreground"
                          )}>
                            <item.icon className="h-4 w-4 shrink-0" />
                            {!collapsed && <span>{item.title}</span>}
                          </div>
                        </SidebarMenuButton>
                      </Link>
                    )
                  )}
                  {!collapsed && item.items?.length && (
                    <>
                      <CollapsibleContent>
                        <SidebarMenuSub>
                          {item.items?.map((subItem) => (
                            <SidebarMenuSubItem key={subItem.title}>
                              <SidebarMenuSubButton 
                                asChild
                                className={cn(
                                  isSubMenuItemActive(subItem.url) 
                                    ? "text-primary [&_svg]:text-primary hover:text-primary" 
                                    : "text-foreground hover:text-foreground",
                                  "hover:bg-gray-100 dark:hover:bg-secondary",
                                  "py-0.5"
                                )}
                              >
                                {subItem.onClick ? (
                                  <button
                                    onClick={subItem.onClick}
                                    className={cn(
                                      "flex items-center gap-2 w-full text-left",
                                      isSubMenuItemActive(subItem.url) ? "text-primary" : "text-foreground"
                                    )}
                                  >
                                    {subItem.icon && <subItem.icon className="h-4 w-4 shrink-0" />}
                                    <TooltipProvider delayDuration={0}>
                                      <Tooltip>
                                        <TooltipTrigger asChild>
                                          <span 
                                            className="truncated-text truncate max-w-[120px]"
                                            ref={(el) => {
                                              if (el) {
                                                const isTruncated = el.scrollWidth > el.clientWidth;
                                                el.dataset.truncated = String(isTruncated);
                                              }
                                            }}
                                          >
                                            {subItem.title}
                                          </span>
                                        </TooltipTrigger>
                                        {subItem.tooltip && (
                                          <TooltipContent 
                                            side="right"
                                            sideOffset={10}
                                            className="z-50 bg-popover px-3 py-1.5 text-sm text-popover-foreground animate-in fade-in-0"
                                          >
                                            {subItem.tooltip}
                                          </TooltipContent>
                                        )}
                                      </Tooltip>
                                    </TooltipProvider>
                                  </button>
                                ) : (
                                  <Link 
                                    to={subItem.url}
                                    className={cn(
                                      "flex items-center gap-2",
                                      isSubMenuItemActive(subItem.url) ? "text-primary" : "text-foreground"
                                    )}
                                  >
                                    {subItem.icon && <subItem.icon className="h-4 w-4 shrink-0" />}
                                    <TooltipProvider delayDuration={0}>
                                      <Tooltip>
                                        <TooltipTrigger asChild>
                                          <span 
                                            className="truncated-text truncate max-w-[120px]"
                                            ref={(el) => {
                                              if (el) {
                                                const isTruncated = el.scrollWidth > el.clientWidth;
                                                el.dataset.truncated = String(isTruncated);
                                              }
                                            }}
                                          >
                                            {subItem.title}
                                          </span>
                                        </TooltipTrigger>
                                        {subItem.tooltip && (
                                          <TooltipContent 
                                            side="right"
                                            sideOffset={10}
                                            className="z-50 bg-popover px-3 py-1.5 text-sm text-popover-foreground animate-in fade-in-0"
                                          >
                                            {subItem.tooltip}
                                          </TooltipContent>
                                        )}
                                      </Tooltip>
                                    </TooltipProvider>
                                  </Link>
                                )}
                              </SidebarMenuSubButton>
                            </SidebarMenuSubItem>
                          ))}
                        </SidebarMenuSub>
                      </CollapsibleContent>
                    </>
                  )}
                </SidebarMenuItem>
              </Collapsible>
            )
          ))}
        </SidebarMenu>
      </SidebarGroup>

      <CreateBoardModal
        open={isCreateBoardModalOpen}
        onClose={() => setIsCreateBoardModalOpen(false)}
        onSuccess={handleCreateBoardSuccess}
        currentUser={currentUser}
      />
    </>
  );
}
