import { useState, useEffect } from 'react'
import axios from '../api/axiosConfig'
import { toast } from 'sonner'

// UI Components
import { Button } from "@/components/ui/button"
import { Card, CardContent } from "@/components/ui/card"
import { Badge } from "@/components/ui/badge"
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog"
import { Loading } from "@/components/ui/loading"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  type ColumnDef
} from "@/components/ui/specialized/mod-table"
import { Input } from "@/components/ui/input"

// Icons
import { Users, User, Building2, Briefcase } from 'lucide-react'

// Types
import type { Account, Contact, Deal } from '../types/customers'

// Components
import AccountTree from './modals/AccountTree'
import { DealDrawer } from '../drawers/DealDrawer'
import { ContactDrawer } from '../drawers/ContactDrawer'

interface AccountTableRow extends Account {
  contacts: Contact[];
  deals: Deal[];
}

const Accounts = () => {
  const [accounts, setAccounts] = useState<Account[]>([])
  const [loading, setLoading] = useState(true)
  const [selectedContact, setSelectedContact] = useState<Contact | null>(null)
  const [contactDrawerVisible, setContactDrawerVisible] = useState(false)
  const [accountTreeVisible, setAccountTreeVisible] = useState(false)
  const [selectedAccount, setSelectedAccount] = useState<Account | null>(null)
  const [accountLoading, setAccountLoading] = useState(false)
  const [dealDrawerVisible, setDealDrawerVisible] = useState(false)
  const [selectedDealId, setSelectedDealId] = useState<number | null>(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(15)
  const [searchQuery, setSearchQuery] = useState("")
  const [filteredData, setFilteredData] = useState<Account[]>([])

  useEffect(() => {
    fetchAccounts()
  }, [])

  useEffect(() => {
    setFilteredData(accounts)
  }, [accounts])

  const fetchAccounts = async () => {
    try {
      const response = await axios.get('/account/accounts')
      setAccounts(response.data)
    } catch (error) {
      console.error('Error fetching accounts:', error)
      toast.error('Failed to fetch accounts')
    } finally {
      setLoading(false)
    }
  }

  const fetchAccountDetails = async (accountId: number) => {
    setAccountLoading(true)
    try {
      const response = await axios.get(`/account/accounts/${accountId}`)
      setSelectedAccount(response.data)
    } catch (error) {
      console.error('Error fetching account details:', error)
      toast.error('Failed to fetch account details')
    } finally {
      setAccountTreeVisible(true)
      setAccountLoading(false)
    }
  }

  const showContactDetails = (contact: Contact) => {
    setSelectedContact(contact)
    setContactDrawerVisible(true)
  }

  const showDealDetails = (deal: Deal) => {
    setSelectedDealId(deal.id)
    setDealDrawerVisible(true)
  }

  const showAccountTree = (account: Account) => {
    fetchAccountDetails(account.id)
    setSelectedAccount(account)
    setAccountTreeVisible(true)
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setSearchQuery(value)
    if (!value) {
      setFilteredData(accounts)
      return
    }
    
    const filtered = accounts.filter(item => 
      item.company_name.toLowerCase().includes(value.toLowerCase()) ||
      item.cvr?.toLowerCase().includes(value.toLowerCase()) ||
      item.deals.some(deal => 
        deal.name.toLowerCase().includes(value.toLowerCase())
      )
    )
    setFilteredData(filtered)
  }

  const columns: ColumnDef<AccountTableRow>[] = [
    {
      key: 'company_name',
      title: 'Account',
      dataIndex: 'company_name',
      cell: ({ row }: { row: { original: AccountTableRow } }) => (
        <Badge 
          variant="secondary"
          className="cursor-pointer hover:bg-secondary/80 bg-[hsl(var(--badge-account))] text-white hover:bg-[hsl(var(--badge-account)/.8)]"
          onClick={() => showAccountTree(row.original)}
        >
          <Building2 className="w-3 h-3 flex-shrink-0" />
          <span className="max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap">
            {row.original.company_name}
          </span>
        </Badge>
      ),
      sortable: {
        enabled: true
      }
    },
    {
      key: 'cvr',
      title: 'CVR',
      dataIndex: 'cvr'
    },
    {
      key: 'contacts',
      title: 'Contacts',
      dataIndex: 'contacts',
      cell: ({ row }: { row: { original: AccountTableRow } }) => (
        <div className="flex flex-wrap gap-2">
          {row.original.contacts.map((contact) => (
            <Badge 
              key={contact.id}
              variant="secondary"
              className="cursor-pointer hover:bg-secondary/80 bg-[hsl(var(--badge-contact))] text-white hover:bg-[hsl(var(--badge-contact)/.8)]"
              onClick={() => showContactDetails(contact)}
            >
              <User className="w-3 h-3 flex-shrink-0" />
              <span className="max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap">
                {contact.name}
              </span>
            </Badge>
          ))}
        </div>
      )
    },
    {
      key: 'deals',
      title: 'Deals',
      dataIndex: 'deals',
      cell: ({ row }: { row: { original: AccountTableRow } }) => (
        <div className="flex flex-wrap gap-2">
          {row.original.deals.map((deal) => (
            <Badge 
              key={deal.id}
              variant="secondary"
              className="cursor-pointer hover:bg-secondary/80 bg-[hsl(var(--badge-deal))] text-white hover:bg-[hsl(var(--badge-deal)/.8)]"
              onClick={() => showDealDetails(deal)}
            >
              <Briefcase className="w-3 h-3 flex-shrink-0" />
              <span className="max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap">
                {deal.name}
              </span>
            </Badge>
          ))}
        </div>
      )
    }
  ]

  if (loading) {
    return <Loading message="Loading accounts..." />
  }

  return (
    <>
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-3xl font-bold text-foreground">Customer Accounts</h2>
      </div>

      <Card className="mb-4">
        <CardContent className="flex items-center gap-4 pt-6">
          <Users className="h-8 w-8 text-primary" />
          <div>
            <p className="text-sm font-medium">Total Accounts</p>
            <p className="text-2xl font-bold">{accounts.length}</p>
          </div>
        </CardContent>
      </Card>

      <div className="w-full flex justify-between items-center mb-4">
        <Input 
          placeholder="Search accounts..."
          value={searchQuery}
          onChange={handleSearch}
          className="max-w-sm"
        />
      </div>

      <div>
        {filteredData.length === 0 && searchQuery ? (
          <div className="text-center py-8 text-muted-foreground">
            No results found for "{searchQuery}"
          </div>
        ) : (
          <Table 
            data={filteredData.length > 0 || searchQuery ? filteredData : accounts}
            columns={columns}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={setCurrentPage}
            onPageSizeChange={setPageSize}
          />
        )}
      </div>

      <AccountTree
        visible={accountTreeVisible}
        onClose={() => setAccountTreeVisible(false)}
        account={selectedAccount}
      />

      <DealDrawer
        visible={dealDrawerVisible}
        onClose={() => {
          setDealDrawerVisible(false)
          setSelectedDealId(null)
        }}
        dealId={selectedDealId}
      />

      <ContactDrawer
        visible={contactDrawerVisible}
        onClose={() => {
          setContactDrawerVisible(false)
          setSelectedContact(null)
        }}
        contact={selectedContact}
        onContactUpdate={fetchAccounts}
      />
    </>
  )
}

export default Accounts