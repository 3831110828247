import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { LucideIcon, TrendingDown, TrendingUp } from "lucide-react"
import { cn } from "@/lib/utils"

interface StatCardTrend {
  value: number
  isUpward: boolean
  label?: string
  additionalTrends?: Array<{
    value: number
    isUpward: boolean
    label: string
  }>
}

interface StatCardProps {
  title: string
  value: number
  icon?: LucideIcon
  trend?: StatCardTrend
  valueFormatter?: (value: number) => string
  suffix?: string
  description?: string
  className?: string
  minWidth?: string
}

export function StatCard({
  title,
  value,
  icon: Icon,
  suffix,
  description,
  trend,
  className,
  valueFormatter = (val) => val.toLocaleString(),
  minWidth = "250px",
}: StatCardProps) {
  return (
    <Card className={cn("min-w-[250px]", className)} style={{ minWidth }}>
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="text-sm font-medium text-muted-foreground">{title}</CardTitle>
        {Icon && <Icon className="h-4 w-4 text-muted-foreground" />}
      </CardHeader>
      <CardContent>
        <div className="text-2xl font-bold">
          {valueFormatter(value)}{suffix && ` ${suffix}`}
        </div>
        {(description || trend) && (
          <div className="mt-2 space-y-1">
            {trend && (
              <p className="text-sm text-muted-foreground flex items-center gap-1">
                {trend.isUpward ? (
                  <TrendingUp className="h-4 w-4 text-green-500" />
                ) : (
                  <TrendingDown className="h-4 w-4 text-red-500" />
                )}
                <span className={cn(
                  trend.isUpward ? "text-green-500" : "text-red-500"
                )}>
                  {trend.value.toFixed(1)}%
                </span>
                {trend.label && ` ${trend.label}`}
              </p>
            )}
            {trend?.additionalTrends?.map((additionalTrend, index) => (
              <p key={index} className="text-sm text-muted-foreground flex items-center gap-1">
                {additionalTrend.isUpward ? (
                  <TrendingUp className="h-4 w-4 text-green-500" />
                ) : (
                  <TrendingDown className="h-4 w-4 text-red-500" />
                )}
                <span className={cn(
                  additionalTrend.isUpward ? "text-green-500" : "text-red-500"
                )}>
                  {additionalTrend.value.toFixed(1)}%
                </span>
                {additionalTrend.label && ` ${additionalTrend.label}`}
              </p>
            ))}
            {description && (
              <p className="text-sm text-muted-foreground">
                {description}
              </p>
            )}
          </div>
        )}
      </CardContent>
    </Card>
  )
}