import { useState, useEffect } from 'react'
import axios from '../api/axiosConfig'
import { toast } from 'sonner'

// UI Components
import { Button } from "@/components/ui/button"
import { Card, CardContent } from "@/components/ui/card"
import { Badge } from "@/components/ui/badge"
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter, DialogDescription, DialogBody } from "@/components/ui/dialog"
import { Loading } from "@/components/ui/loading"
import { EditProductDialog, AddProductDialog, AddHistoricalInvoiceDialog } from '@/modals/ProductDialogs'
import {
  Table,
  type ColumnDef
} from "@/components/ui/specialized/mod-table"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { DatePicker } from "@/components/ui/date/date-picker"
import {
  Command,
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { Check, CheckCircle, XCircle, HelpCircle } from "lucide-react"
import { cn } from "@/lib/utils"
import { FileText } from "lucide-react"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip"
import {
  RadioGroup,
  RadioGroupItem,
} from "@/components/ui/radio-group"
import { Label } from "@/components/ui/label"
import { DealDrawer } from '../drawers/DealDrawer'

// Icons
import { Package2, Building2, Users2, Tag, X, ArrowUpDown } from 'lucide-react'

// Types
import type { Product, Deal, Account } from '@/types/customers'

// Form validation
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"

import { dealProductConfig, type DealProduct as ConfigProduct, invoiceProductConfig } from '@/configs/DealProductConfig'

// Add these type definitions
interface InvoiceProduct {
  name: string;
  code: string;
}

const invoiceFormSchema = z.object({
  product: z.string(),
  amount: z.number().min(0),
  invoiceType: z.enum(["next", "now"])
})

type InvoiceFormValues = z.infer<typeof invoiceFormSchema>

// Add the constant array of products
const invoiceProducts = [
  { name: "Rådgivning", code: "1011" },
  { name: "Gebyrer", code: "5" }
];

const formSchema = z.object({
  name: z.string().min(1, "Name is required"),
  code: z.string().min(1, "Code is required"),
  item_price: z.number().min(0, "Price must be positive"),
  quantity: z.number().min(1, "Quantity must be at least 1"),
  billing_start_date: z.date().optional(),
  billing_frequency: z.string().refine(val => ['monthly', 'quarterly', 'annually', 'one-time'].includes(val), {
    message: "Billing frequency must be monthly, quarterly, annually, or one-time"
  })
})

// First, define proper types for invoices
interface InvoiceLine {
  product_name: string
  product_code: string
  quantity: number
  unit_price: number
  total_amount: number
}

interface BaseInvoice {
  id: number
  invoice_number: string
  deal_id: number
  deal_name: string
  created_at: string
  paid_at: string | null
  paid_status: string
  type: 'draft' | 'booked'
}

interface BookedInvoice extends BaseInvoice {
  type: 'booked'
  invoice_date: string
  due_date: string | null
  total_amount_excl_vat: number
  total_amount_incl_vat: number
  lines: InvoiceLine[]
}

interface DraftInvoice extends BaseInvoice {
  type: 'draft'
  total_amount: number
  secondary_status?: 'approved' | 'not_approved' | 'unsure'
}

type Invoice = BookedInvoice | DraftInvoice

const InvoiceDetailsDialog = ({ 
  invoice, 
  open, 
  onOpenChange 
}: { 
  invoice: Invoice; 
  open: boolean; 
  onOpenChange: (open: boolean) => void;
}) => {
  const invoiceLineColumns: ColumnDef<InvoiceLine>[] = [
    {
      key: 'product_name',
      title: 'Product',
      dataIndex: 'product_name',
      cell: ({ row }) => {
        const productName = row.original.product_name
        return (
          <div className="flex items-center gap-2 max-w-[150px]">
            <Package2 className="h-4 w-4 flex-shrink-0" />
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <span className="truncate">
                    {productName}
                  </span>
                </TooltipTrigger>
                <TooltipContent>
                  {productName}
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        )
      }
    },
    {
      key: 'quantity',
      title: 'Qty',
      dataIndex: 'quantity',
      cell: ({ row }) => (
        <div className="w-12 text-right tabular-nums">
          {row.original.quantity}
        </div>
      )
    },
    {
      key: 'unit_price',
      title: 'Price',
      dataIndex: 'unit_price',
      cell: ({ row }) => (
        <div className="w-16 text-right tabular-nums">
          {row.original.unit_price.toLocaleString('da-DK')} DKK
        </div>
      )
    },
    {
      key: 'total_amount',
      title: 'Total',
      dataIndex: 'total_amount',
      cell: ({ row }) => (
        <div className="w-16 text-right tabular-nums">
          {row.original.total_amount.toLocaleString('da-DK')} DKK
        </div>
      )
    }
  ]

  // Helper function to determine if invoice is booked
  const isBookedInvoice = (invoice: Invoice): invoice is BookedInvoice => {
    return invoice.type === 'booked';
  }

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-3xl">
        <DialogHeader>
          <DialogTitle>Invoice Details</DialogTitle>
          <DialogDescription>
            Invoice #{invoice.invoice_number}
          </DialogDescription>
        </DialogHeader>

        <div className="space-y-6">
          {/* Invoice Summary */}
          <div className="grid grid-cols-2 gap-4 text-sm">
            <div>
              <p className="text-muted-foreground">Deal</p>
              <p className="font-medium">{invoice.deal_name}</p>
            </div>
            <div>
              <p className="text-muted-foreground">Date</p>
              <p className="font-medium">
                {isBookedInvoice(invoice) 
                  ? new Date(invoice.invoice_date).toLocaleDateString('da-DK')
                  : new Date(invoice.created_at).toLocaleDateString('da-DK')}
              </p>
            </div>
            <div>
              <p className="text-muted-foreground">Status</p>
              <Badge variant={invoice.paid_status === 'paid' ? 'success' : 'destructive'}>
                {invoice.paid_status.toUpperCase()}
              </Badge>
            </div>
            <div>
              <p className="text-muted-foreground">Due Date</p>
              <p className="font-medium">
                {isBookedInvoice(invoice) && invoice.due_date 
                  ? new Date(invoice.due_date).toLocaleDateString('da-DK')
                  : 'N/A'}
              </p>
            </div>
          </div>

          {/* Invoice Lines Table */}
          <div className="rounded-md border">
            <Table
              data={isBookedInvoice(invoice) ? invoice.lines : []}
              columns={invoiceLineColumns}
              pageSize={5}
            />
          </div>

          {/* Invoice Totals */}
          <div className="space-y-2 text-right">
            <div className="flex justify-end gap-4">
              <span className="text-muted-foreground">Subtotal:</span>
              <span className="font-medium tabular-nums">
                {(isBookedInvoice(invoice) 
                  ? invoice.total_amount_excl_vat 
                  : invoice.total_amount)?.toLocaleString('da-DK')} DKK
              </span>
            </div>
            {isBookedInvoice(invoice) && (
              <div className="flex justify-end gap-4">
                <span className="text-muted-foreground">VAT (25%):</span>
                <span className="font-medium tabular-nums">
                  {((invoice.total_amount_incl_vat - invoice.total_amount_excl_vat) || 0).toLocaleString('da-DK')} DKK
                </span>
              </div>
            )}
            <div className="flex justify-end gap-4 text-lg">
              <span className="font-semibold">Total:</span>
              <span className="font-semibold tabular-nums">
                {(isBookedInvoice(invoice) 
                  ? invoice.total_amount_incl_vat 
                  : invoice.total_amount)?.toLocaleString('da-DK')} DKK
              </span>
            </div>
          </div>
        </div>

        <DialogFooter className="gap-2">
          <Button
            variant="outline"
            onClick={() => onOpenChange(false)}
          >
            Close
          </Button>
          {isBookedInvoice(invoice) && (
            <Button
              onClick={async () => {
                try {
                  const response = await axios.get(
                    `/invoice/booked-invoices/${invoice.invoice_number}/pdf`,
                    { responseType: 'blob' }
                  )
                  const pdfBlob = new Blob([response.data], { type: 'application/pdf' })
                  const pdfUrl = URL.createObjectURL(pdfBlob)
                  window.open(pdfUrl, '_blank')
                } catch (error) {
                  console.error('Error fetching PDF:', error)
                  toast.error('Failed to fetch invoice PDF')
                }
              }}
            >
              <FileText className="mr-2 h-4 w-4" />
              View PDF
            </Button>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

const Products = () => {
  const [products, setProducts] = useState<Product[]>([])
  const [deals, setDeals] = useState<Deal[]>([])
  const [accounts, setAccounts] = useState<Account[]>([])
  const [selectedDealId, setSelectedDealId] = useState<number | null>(null)
  const [selectedAccountId, setSelectedAccountId] = useState<number | null>(null)
  const [loading, setLoading] = useState(true)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null)
  const [accountModalVisible, setAccountModalVisible] = useState(false)
  const [selectedAccountDetails, setSelectedAccountDetails] = useState<Account | null>(null)
  const [addProductOpen, setAddProductOpen] = useState(false)
  const [allProducts, setAllProducts] = useState<Product[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [currentPageSize, setCurrentPageSize] = useState(15)
  const [addInvoiceOpen, setAddInvoiceOpen] = useState(false)
  const [invoices, setInvoices] = useState<{
    draft_invoices: DraftInvoice[];
    booked_invoices: BookedInvoice[];
  }>({
    draft_invoices: [],
    booked_invoices: []
  });
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(null)
  const [dealDrawerVisible, setDealDrawerVisible] = useState(false)
  const [selectedDealForDrawer, setSelectedDealForDrawer] = useState<number | null>(null)
  const [addHistoricalInvoiceOpen, setAddHistoricalInvoiceOpen] = useState(false)

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      code: "",
      item_price: 0,
      quantity: 1,
      billing_frequency: "monthly"
    }
  })

  useEffect(() => {
    fetchAllProducts()
    fetchDeals()
    fetchAccounts()
    setLoading(false)
  }, [])

  useEffect(() => {
    if (selectedDealId) {
      const filteredProducts = allProducts.filter(product => {
        if (!product || !product.deal) return false;
        return product.deal.id === selectedDealId;
      });
      setProducts(filteredProducts);
      fetchInvoicesForDeal(selectedDealId);
    } else if (selectedAccountId) {
      const filteredProducts = allProducts.filter(product => {
        if (!product || !product.deal || !product.deal.account) return false;
        return product.deal.account.id === selectedAccountId;
      });
      setProducts(filteredProducts);
      fetchInvoicesForAccount(selectedAccountId);
    } else {
      setProducts(allProducts);
      setInvoices({ draft_invoices: [], booked_invoices: [] });
    }
  }, [selectedDealId, selectedAccountId, allProducts]);

  const fetchDeals = async () => {
    try {
      const response = await axios.get('/account/deals')
      setDeals(response.data)
    } catch (error) {
      console.error('Error fetching deals:', error)
      toast.error('Failed to fetch deals')
    }
  }

  const fetchAccounts = async () => {
    try {
      const response = await axios.get('/account/accounts')
      setAccounts(response.data)
    } catch (error) {
      console.error('Error fetching accounts:', error)
      toast.error('Failed to fetch accounts')
    }
  }

  const showProductDetails = (product: Product) => {
    setSelectedProduct(product)
    form.reset({
      name: product.name,
      code: product.code,
      item_price: product.item_price,
      quantity: product.quantity,
      billing_start_date: product.billing_start_date ? new Date(product.billing_start_date) : undefined,
      billing_frequency: product.billing_frequency
    })
    setEditModalOpen(true)
  }

  const handleUpdate = async (values: z.infer<typeof formSchema>) => {
    if (!selectedProduct) return

    try {
      await axios.put(`/account/products/${selectedProduct.id}`, values)
      toast.success('Product updated successfully')
      setEditModalOpen(false)
      fetchAllProducts()
    } catch (error) {
      console.error('Error updating product:', error)
      toast.error('Failed to update product')
    }
  }

  const showAccountDetails = async (account: Account) => {
    try {
      const response = await axios.get(`/account/accounts/${account.id}`)
      setSelectedAccountDetails(response.data)
      setAccountModalVisible(true)
    } catch (error) {
      console.error('Error fetching account details:', error)
      toast.error('Failed to fetch account details')
    }
  }

  const handleDeleteProduct = async (productId: number) => {
    try {
      await axios.put(`/product/products/${productId}/delete`)
      toast.success('Product deleted successfully')
      fetchAllProducts()
    } catch (error) {
      console.error('Error deleting product:', error)
      toast.error('Failed to delete product')
    }
  }

  const columns: ColumnDef<Product>[] = [
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
      cell: ({ row }) => (
        <div className="flex items-center gap-2">
          <Package2 className="h-4 w-4" />
          <span>{row.original.name || 'N/A'}</span>
        </div>
      ),
      filterable: true,
      filters: Array.from(new Set(products.map(p => p.name))).map(name => ({
        text: name,
        value: name
      })),
      onFilter: (value: string, record: Product) => record.name === value
    },
    {
      key: 'code',
      title: 'Code',
      dataIndex: 'code',
      cell: ({ row }) => (
        <div className="flex items-center gap-2">
          <Tag className="h-4 w-4" />
          <span>{row.original.code || 'N/A'}</span>
        </div>
      )
    },
    {
      key: 'quantity',
      title: 'Quantity',
      dataIndex: 'quantity',
      cell: ({ row }) => (
        <span>{row.original.quantity || 0}</span>
      ),
      sortable: {
        enabled: true,
        type: 'number'
      }
    },
    {
      key: 'item_price',
      title: 'Item Price',
      dataIndex: 'item_price',
      cell: ({ row }) => {
        const price = typeof row.original.item_price === 'number' ? row.original.item_price : 0
        return (
          <span>
            {price.toLocaleString('da-DK', { 
              minimumFractionDigits: 0, 
              maximumFractionDigits: 0 
            })} DKK
          </span>
        )
      },
      sortable: {
        enabled: true,
        type: 'number'
      }
    },
    {
      key: 'sum',
      title: 'Sum',
      dataIndex: 'sum',
      cell: ({ row }) => {
        const sum = typeof row.original.sum === 'number' ? row.original.sum : 0
        return (
          <span>
            {sum.toLocaleString('da-DK', { 
              minimumFractionDigits: 0, 
              maximumFractionDigits: 0 
            })} DKK
          </span>
        )
      },
      sortable: {
        enabled: true,
        type: 'number'
      }
    },
    {
      key: 'deal',
      title: 'Deal',
      dataIndex: 'deal',
      cell: ({ row }) => {
        const deal = row.original.deal
        if (!deal) return <span className="text-muted-foreground">N/A</span>
        
        return (
          <div className="flex items-center gap-2">
            <Badge 
              variant="secondary"
              className="cursor-pointer hover:bg-secondary/80 bg-[hsl(var(--badge-deal))] text-white hover:bg-[hsl(var(--badge-deal)/.8)]"
              onClick={() => {
                setSelectedDealForDrawer(deal.id)
                setDealDrawerVisible(true)
              }}
            >
              <Building2 className="h-3 w-3 flex-shrink-0" />
              {deal.name}
            </Badge>
            {!selectedDealId && (
              <Button
                variant="ghost"
                size="icon"
                className="h-6 w-6 opacity-50 hover:opacity-100"
                onClick={() => {
                  setSelectedAccountId(null)
                  setSelectedDealId(deal.id)
                }}
              >
                <ArrowUpDown className="h-3 w-3" />
              </Button>
            )}
          </div>
        )
      },
      sortable: {
        enabled: true,
        type: 'string',
        compare: (a, b) => {
          const aName = a.deal?.name || '';
          const bName = b.deal?.name || '';
          return aName.localeCompare(bName);
        }
      }
    },
    {
      key: 'account',
      title: 'Account',
      dataIndex: 'deal',
      cell: ({ row }) => {
        const account = row.original.deal?.account
        if (!account) return <span className="text-muted-foreground">N/A</span>
        
        return (
          <Badge 
            variant="secondary"
            className="cursor-pointer hover:bg-secondary/80 bg-[hsl(var(--badge-account))] text-white hover:bg-[hsl(var(--badge-account)/.8)]"
            onClick={() => showAccountDetails(account)}
          >
            <Users2 className="h-3 w-3 flex-shrink-0" />
            {account.company_name}
          </Badge>
        )
      },
      sortable: {
        enabled: true,
        type: 'string',
        compare: (a, b) => {
          const aName = a.deal?.account?.company_name || '';
          const bName = b.deal?.account?.company_name || '';
          return aName.localeCompare(bName);
        }
      }
    },
    {
      key: 'billing_start_date',
      title: 'Billing Start',
      dataIndex: 'billing_start_date',
      cell: ({ row }) => (
        <span>
          {row.original.billing_start_date 
            ? new Date(row.original.billing_start_date).toLocaleDateString('da-DK')
            : '-'}
        </span>
      ),
      sortable: {
        enabled: true,
        type: 'date'
      }
    },
    {
      key: 'last_invoice_date',
      title: 'Last Invoice',
      dataIndex: 'last_invoice_date',
      cell: ({ row }) => (
        <span>
          {row.original.last_invoice_date 
            ? new Date(row.original.last_invoice_date).toLocaleDateString('da-DK')
            : '-'}
        </span>
      ),
      sortable: {
        enabled: true,
        type: 'date'
      }
    },
    {
      key: 'billing_frequency',
      title: 'Frequency',
      dataIndex: 'billing_frequency',
      cell: ({ row }) => (
        <Badge variant="secondary">
          {row.original.billing_frequency?.charAt(0).toUpperCase() + 
           row.original.billing_frequency?.slice(1)}
        </Badge>
      ),
      filterable: true,
      filters: [
        { text: 'Monthly', value: 'monthly' },
        { text: 'Quarterly', value: 'quarterly' },
        { text: 'Annually', value: 'annually' },
        { text: 'One-time', value: 'one-time' }
      ],
      onFilter: (value: string, record: Product) => record.billing_frequency === value
    },
    {
      key: 'actions',
      title: 'Actions',
      dataIndex: 'id',
      cell: ({ row }) => (
        <div className="flex items-center gap-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => showProductDetails(row.original)}
          >
            Edit
          </Button>
          <Button
            variant="destructive"
            size="sm"
            onClick={() => handleDeleteProduct(row.original.id)}
          >
            Delete
          </Button>
        </div>
      )
    }
  ]

  const handleProductUpdated = () => {
    fetchAllProducts()
  }

  const fetchAllProducts = async () => {
    try {
      const response = await axios.get('/account/products')
      setProducts(response.data)
      setAllProducts(response.data)
    } catch (error) {
      console.error('Error fetching all products:', error)
      toast.error('Failed to fetch products')
    } finally {
      setLoading(false)
    }
  }

  const handleClearFilters = () => {
    setSelectedDealId(null)
    setSelectedAccountId(null)
  }

  const fetchInvoicesForDeal = async (dealId: number) => {
    try {
      const response = await axios.get(`/invoice/deals/${dealId}/invoices`);
      setInvoices(response.data);
    } catch (error) {
      console.error('Error fetching invoices:', error);
      toast.error('Failed to fetch invoices');
    }
  };

  const fetchInvoicesForAccount = async (accountId: number) => {
    try {
      const response = await axios.get(`/invoice/accounts/${accountId}/invoices`);
      setInvoices(response.data);
    } catch (error) {
      console.error('Error fetching invoices:', error);
      toast.error('Failed to fetch invoices');
    }
  };

  const invoiceColumns: ColumnDef<Invoice>[] = [
    {
      key: 'invoice_number',
      title: 'Invoice Number',
      dataIndex: 'invoice_number'
    },
    {
      key: 'date',
      title: 'Date',
      dataIndex: 'created_at',
      cell: ({ row }) => {
        const date = row.original.type === 'booked' 
          ? row.original.invoice_date 
          : row.original.created_at
        return new Date(date).toLocaleDateString('da-DK')
      }
    },
    {
      key: 'amount',
      title: 'Amount',
      dataIndex: 'id',
      cell: ({ row }) => {
        const amount = row.original.type === 'booked'
          ? row.original.total_amount_excl_vat
          : row.original.total_amount
        return (
          <span>
            {amount.toLocaleString('da-DK')} DKK
          </span>
        )
      }
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'type',
      cell: ({ row }) => {
        if (row.original.type === 'booked') {
          return (
            <Badge variant="default">
              Booked
            </Badge>
          )
        }

        // For draft invoices, show the secondary status
        const status = row.original.secondary_status
        switch (status) {
          case 'approved':
            return (
              <Badge variant="outline" className="bg-green-100 text-green-800">
                <CheckCircle className="h-3 w-3 mr-1" />
                Approved
              </Badge>
            )
          case 'not_approved':
            return (
              <Badge variant="outline" className="bg-red-100 text-red-800">
                <XCircle className="h-3 w-3 mr-1" />
                Not Approved
              </Badge>
            )
          case 'unsure':
            return (
              <Badge variant="outline" className="bg-yellow-100 text-yellow-800">
                <HelpCircle className="h-3 w-3 mr-1" />
                Unsure
              </Badge>
            )
          default:
            return (
              <Badge variant="outline" className="bg-muted text-muted-foreground">
                Pending Review
              </Badge>
            )
        }
      }
    },
    {
      key: 'paid_status',
      title: 'Payment Status',
      dataIndex: 'paid_status',
      cell: ({ row }) => (
        <Badge variant={row.original.paid_status === 'paid' ? 'success' : 'destructive'}>
          {row.original.paid_status}
        </Badge>
      )
    },
    {
      key: 'view',
      title: '',
      dataIndex: 'id',
      cell: ({ row }) => (
        <Button
          variant="ghost"
          size="sm"
          onClick={() => setSelectedInvoice(row.original)}
        >
          View Details
        </Button>
      )
    }
  ]

  const AddInvoiceDialog = ({ 
    open, 
    onOpenChange, 
    dealId,
    onProductAdded
  }: { 
    open: boolean; 
    onOpenChange: (open: boolean) => void; 
    dealId: number | null;
    onProductAdded: () => void;
  }) => {
    const form = useForm<InvoiceFormValues>({
      resolver: zodResolver(invoiceFormSchema),
      defaultValues: {
        product: "",
        invoiceType: "next",
        amount: 0
      }
    })

    const selectedProduct = form.watch('product')
    const selectedInvoiceType = form.watch('invoiceType')

    useEffect(() => {
      const product = invoiceProductConfig.products.find(p => p.code === selectedProduct)
      if (product) {
        form.setValue('amount', product.defaultPrice)
      }
    }, [selectedProduct, form])

    const onSubmit = async (values: InvoiceFormValues) => {
      try {
        if (!dealId) {
          toast.error('No deal selected')
          return
        }

        if (values.invoiceType === "now") {
          toast.error("Invoice now option is coming soon!")
          return
        }

        const productData = {
          name: invoiceProductConfig.products.find(p => p.code === values.product)?.name,
          code: values.product,
          quantity: 1,
          item_price: values.amount,
          sum: values.amount,
          billing_start_date: new Date().toISOString().split('T')[0],
          billing_frequency: "next",
          deal_id: dealId
        }

        await axios.post(`/product/deals/${dealId}/products`, productData)
        toast.success('Product added to next invoice')
        onOpenChange(false)
        onProductAdded()
      } catch (error) {
        console.error('Error adding product:', error)
        toast.error('Failed to add product')
      }
    }

    return (
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Add to Invoice</DialogTitle>
            <DialogDescription className="sr-only">Add a product to an invoice</DialogDescription>
          </DialogHeader>
          <DialogBody>
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
                <FormField
                  control={form.control}
                  name="product"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Product</FormLabel>
                      <Select
                        value={field.value}
                        onValueChange={field.onChange}
                      >
                        <SelectTrigger>
                          <SelectValue placeholder="Select a product" />
                        </SelectTrigger>
                        <SelectContent>
                          {invoiceProductConfig.products.map((product) => (
                            <SelectItem key={product.code} value={product.code}>
                              {product.name}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="amount"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Amount (DKK)</FormLabel>
                      <Input 
                        type="number" 
                        {...field}
                        onChange={e => {
                          // Replace period with comma for display
                          const value = e.target.value.replace('.', ',')
                          // Convert comma to period for parsing
                          const numericValue = parseFloat(value.replace(',', '.'))
                          field.onChange(numericValue)
                        }}
                        onKeyDown={e => {
                          // Allow comma or period for decimal input
                          if (e.key === '.' || e.key === ',') {
                            e.preventDefault()
                            // Get current value and cursor position
                            const input = e.target as HTMLInputElement
                            const value = input.value
                            const position = input.selectionStart || 0
                            
                            // Only add comma if there isn't one already
                            if (!value.includes(',')) {
                              const newValue = value.slice(0, position) + ',' + value.slice(position)
                              input.value = newValue
                              // Update form value
                              field.onChange(parseFloat(newValue.replace(',', '.')))
                              // Set cursor position after the comma
                              setTimeout(() => input.setSelectionRange(position + 1, position + 1))
                            }
                          }
                        }}
                      />
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="invoiceType"
                  render={({ field }) => (
                    <FormItem className="space-y-3">
                      <FormLabel>Invoice Options</FormLabel>
                      <div className="flex flex-col space-y-1">
                        <FormControl>
                          <RadioGroup
                            onValueChange={field.onChange}
                            defaultValue={field.value}
                            className="flex flex-col space-y-1"
                          >
                            <div className="flex items-center space-x-2">
                              <RadioGroupItem value="next" id="next" />
                              <Label htmlFor="next">Add to next invoice</Label>
                            </div>
                            <div className="flex items-center space-x-2">
                              <RadioGroupItem value="now" id="now" />
                              <Label htmlFor="now">Create invoice now</Label>
                            </div>
                          </RadioGroup>
                        </FormControl>
                      </div>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                {selectedInvoiceType === "now" && (
                  <div className="text-sm text-muted-foreground">
                    Creating invoice immediately is coming soon!
                  </div>
                )}
              </form>
            </Form>
          </DialogBody>
          <DialogFooter>
            <Button 
              onClick={form.handleSubmit(onSubmit)}
              disabled={selectedInvoiceType === "now"}
            >
              {selectedInvoiceType === "next" ? "Add to Next Invoice" : "Create Invoice Now"}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    )
  }

  if (loading) {
    return <Loading message="Loading products..." />
  }

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <h2 className="text-3xl font-bold text-foreground">Products</h2>
        {selectedDealId && (
          <div className="flex gap-2">
            <Button onClick={() => setAddProductOpen(true)}>
              Add Product
            </Button>
            <Button onClick={() => setAddInvoiceOpen(true)}>
              Add Invoice
            </Button>
          </div>
        )}
      </div>

      <div className="flex items-center gap-4">
        <div className="flex items-center gap-4">
          <Popover>
            <PopoverTrigger asChild>
              <Button
                variant="outline"
                className="w-[350px] justify-between"
              >
                {selectedDealId 
                  ? deals.find(d => d.id === selectedDealId)?.name
                  : "All deals"}
                <Building2 className="ml-2 h-4 w-4 shrink-0 opacity-50" />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-[350px] p-0" align="start">
              <Command>
                <CommandInput placeholder="Search deals..." />
                <CommandEmpty>No deal found.</CommandEmpty>
                <CommandList>
                  <CommandGroup>
                    <CommandItem
                      value="all"
                      onSelect={() => {
                        setSelectedDealId(null)
                        setSelectedAccountId(null)
                      }}
                    >
                      All deals
                      <Check
                        className={cn(
                          "ml-auto h-4 w-4",
                          !selectedDealId ? "opacity-100" : "opacity-0"
                        )}
                      />
                    </CommandItem>
                    {deals.map((deal) => (
                      <CommandItem
                        key={deal.id}
                        value={deal.name}
                        onSelect={() => {
                          setSelectedAccountId(null)
                          setSelectedDealId(deal.id)
                        }}
                      >
                        {deal.name}
                        <Check
                          className={cn(
                            "ml-auto h-4 w-4",
                            selectedDealId === deal.id ? "opacity-100" : "opacity-0"
                          )}
                        />
                      </CommandItem>
                    ))}
                  </CommandGroup>
                </CommandList>
              </Command>
            </PopoverContent>
          </Popover>

          <span className="text-muted-foreground">or</span>

          <Popover>
            <PopoverTrigger asChild>
              <Button
                variant="outline"
                className="w-[300px] justify-between"
              >
                {selectedAccountId 
                  ? accounts.find(a => a.id === selectedAccountId)?.company_name
                  : "All accounts"}
                <Users2 className="ml-2 h-4 w-4 shrink-0 opacity-50" />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-[350px] p-0">
              <Command>
                <CommandInput placeholder="Search accounts..." />
                <CommandEmpty>No account found.</CommandEmpty>
                <CommandList>
                  <CommandGroup>
                    <CommandItem
                      value="all"
                      onSelect={() => {
                        setSelectedDealId(null)
                        setSelectedAccountId(null)
                      }}
                    >
                      All accounts
                      <Check
                        className={cn(
                          "ml-auto h-4 w-4",
                          !selectedAccountId ? "opacity-100" : "opacity-0"
                        )}
                      />
                    </CommandItem>
                    {accounts.map((account) => (
                      <CommandItem
                        key={account.id}
                        value={account.company_name}
                        onSelect={() => {
                          setSelectedDealId(null)
                          setSelectedAccountId(account.id)
                        }}
                      >
                        {account.company_name}
                        <Check
                          className={cn(
                            "ml-auto h-4 w-4",
                            selectedAccountId === account.id ? "opacity-100" : "opacity-0"
                          )}
                        />
                      </CommandItem>
                    ))}
                  </CommandGroup>
                </CommandList>
              </Command>
            </PopoverContent>
          </Popover>

          {(selectedDealId || selectedAccountId) && (
            <Button
              variant="ghost"
              size="icon"
              onClick={handleClearFilters}
              className="h-9 w-9"
            >
              <X className="h-4 w-4 text-muted-foreground hover:text-foreground" />
              <span className="sr-only">Clear filters</span>
            </Button>
          )}
        </div>
      </div>

      <Card>
        <CardContent className="pt-6">
          <Table 
            data={products}
            columns={columns}
            pageSize={currentPageSize}
            currentPage={currentPage}
            onPageChange={setCurrentPage}
            onPageSizeChange={setCurrentPageSize}
          />
        </CardContent>
      </Card>

      {(invoices.draft_invoices.length > 0 || invoices.booked_invoices.length > 0) && (
        <Card>
          <CardContent className="pt-6">
            <h3 className="text-lg font-semibold mb-4">Invoices</h3>
            {invoices.draft_invoices.length > 0 && (
              <>
                <h4 className="text-md font-medium mb-2">Draft Invoices</h4>
                <Table 
                  data={invoices.draft_invoices}
                  columns={invoiceColumns}
                  pageSize={5}
                />
              </>
            )}
            {invoices.booked_invoices.length > 0 && (
              <>
                <h4 className="text-md font-medium mb-2 mt-4">Booked Invoices</h4>
                <div className="space-y-4">
                  <Table 
                    data={invoices.booked_invoices}
                    columns={invoiceColumns}
                    pageSize={5}
                  />
                  {selectedDealId && (
                    <div className="flex justify-end">
                      <Button
                        variant="outline"
                        onClick={() => setAddHistoricalInvoiceOpen(true)}
                      >
                        Add Historical Invoice
                      </Button>
                    </div>
                  )}
                </div>
              </>
            )}
          </CardContent>
        </Card>
      )}

      {selectedProduct && (
        <EditProductDialog
          open={editModalOpen}
          onOpenChange={setEditModalOpen}
          product={selectedProduct}
          onProductUpdated={handleProductUpdated}
        />
      )}

      {selectedDealId && (
        <AddProductDialog
          open={addProductOpen}
          onOpenChange={setAddProductOpen}
          dealId={selectedDealId}
          onProductAdded={handleProductUpdated}
        />
      )}

      {selectedInvoice && (
        <InvoiceDetailsDialog
          invoice={selectedInvoice}
          open={!!selectedInvoice}
          onOpenChange={(open) => !open && setSelectedInvoice(null)}
        />
      )}

      <DealDrawer
        visible={dealDrawerVisible}
        onClose={() => {
          setDealDrawerVisible(false)
          setSelectedDealForDrawer(null)
        }}
        dealId={selectedDealForDrawer}
      />

      {selectedDealId && (
        <AddInvoiceDialog
          open={addInvoiceOpen}
          onOpenChange={setAddInvoiceOpen}
          dealId={selectedDealId}
          onProductAdded={handleProductUpdated}
        />
      )}

      {selectedDealId && (
        <AddHistoricalInvoiceDialog
          open={addHistoricalInvoiceOpen}
          onOpenChange={setAddHistoricalInvoiceOpen}
          dealId={selectedDealId}
          dealProducts={products}
          onInvoiceAdded={() => {
            fetchInvoicesForDeal(selectedDealId)
          }}
        />
      )}
    </div>
  )
}

export default Products