import { useState, useEffect } from 'react';
import { format, parseISO } from 'date-fns';
import axios from '@/api/axiosConfig';
import ReactMarkdown from 'react-markdown';
import { Button } from '@/components/ui/button';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Card, CardContent } from '@/components/ui/card';
import { Calendar, Globe } from 'lucide-react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogBody,
} from "@/components/ui/dialog";

interface Version {
  id: number;
  version: string;
  version_type: string;  // Will be uppercase in DB: "PATCH", "MINOR", etc.
  requires_refresh: boolean;
  created_at: string;
  created_by_user_id: number;
  is_deployed: boolean;
  release_notes: {
    en: string;
    da: string;
  };
}

const markdownStyles = {
  'h3': 'text-lg font-semibold mt-4 mb-2',
  'ul': 'space-y-1 mb-4',
  'li': 'ml-4',
  'p': 'mb-2',
  'code': 'bg-muted px-1 py-0.5 rounded text-sm font-mono',
  'strong': 'font-semibold',
};

interface ChangeLogProps {
  visible: boolean;
  onClose: () => void;
}

export default function ChangeLog({ visible, onClose }: ChangeLogProps) {
  const [versions, setVersions] = useState<Version[]>([]);
  const [selectedLanguage, setSelectedLanguage] = useState<'en' | 'da'>('en');
  const [selectedVersion, setSelectedVersion] = useState<Version | null>(null);

  useEffect(() => {
    fetchVersions();
  }, []);

  const fetchVersions = async () => {
    try {
      const response = await axios.get<Version[]>('/version/versions');
      // Sort by created_at in descending order (newest first)
      const sortedVersions = response.data.sort((a, b) => 
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      );
      setVersions(sortedVersions);
      if (sortedVersions.length > 0) {
        setSelectedVersion(sortedVersions[0]);
      }
    } catch (error) {
      console.error('Failed to fetch versions:', error);
    }
  };

  const getVersionTypeColor = (type: string) => {
    switch (type.toLowerCase()) {
      case 'milestone': return 'bg-purple-100 text-purple-800 dark:bg-purple-900 dark:text-purple-100';
      case 'major': return 'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-100';
      case 'minor': return 'bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-100';
      case 'patch': return 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-100';
      default: return 'bg-gray-100 text-gray-800 dark:bg-gray-900 dark:text-gray-100';
    }
  };

  return (
    <Dialog open={visible} onOpenChange={onClose}>
      <DialogContent className="max-w-[1200px] p-0">
        <DialogHeader className="px-6 py-4">
          <div className="flex items-center gap-6">
            <DialogTitle>Release Notes</DialogTitle>
            <div className="flex items-center gap-2">
              <Globe className="h-4 w-4" />
              <div className="flex rounded-md overflow-hidden">
                <Button
                  variant={selectedLanguage === 'en' ? 'default' : 'outline'}
                  size="sm"
                  onClick={() => setSelectedLanguage('en')}
                  className="rounded-r-none"
                >
                  English
                </Button>
                <Button
                  variant={selectedLanguage === 'da' ? 'default' : 'outline'}
                  size="sm"
                  onClick={() => setSelectedLanguage('da')}
                  className="rounded-l-none"
                >
                  Danish
                </Button>
              </div>
            </div>
          </div>
        </DialogHeader>

        <div className="grid grid-cols-12 h-[calc(85vh-180px)]">
          {/* Timeline - Fixed */}
          <div className="col-span-3 border-r border-border">
            <div className="p-6">
              <div className="flex items-center gap-2 mb-3">
                <Calendar className="h-4 w-4" />
                <h2 className="font-semibold">Version Timeline</h2>
              </div>
              <ScrollArea className="h-[calc(85vh-300px)]">
                <div className="space-y-2 pr-4">
                  {versions.map((version) => (
                    <button
                      key={version.id}
                      onClick={() => setSelectedVersion(version)}
                      className={`w-full text-left p-3 rounded-lg transition-colors ${
                        selectedVersion?.id === version.id
                          ? 'bg-primary/10 text-primary'
                          : 'hover:bg-muted'
                      }`}
                    >
                      <div className="flex justify-between items-center">
                        <span className="font-medium">v{version.version}</span>
                        <span className={`text-xs px-2 py-1 rounded-full ${getVersionTypeColor(version.version_type)}`}>
                          {version.version_type.toLowerCase()}
                        </span>
                      </div>
                      <div className="text-sm text-muted-foreground mt-1">
                        {format(parseISO(version.created_at), 'MMM d, yyyy')}
                      </div>
                      {!version.is_deployed && (
                        <div className="text-xs text-yellow-600 dark:text-yellow-400 mt-1">
                          Pending deployment
                        </div>
                      )}
                    </button>
                  ))}
                </div>
              </ScrollArea>
            </div>
          </div>

          {/* Release Notes Content - Scrollable */}
          <div className="col-span-9 p-6 overflow-auto">
            {selectedVersion && (
              <div className="space-y-4">
                <div className="flex justify-between items-center sticky top-0 bg-background/50 backdrop-blur-sm pb-4 border-b border-border">
                  <div>
                    <h2 className="text-2xl font-bold">Version {selectedVersion.version}</h2>
                    <p className="text-muted-foreground">
                      Released on {format(parseISO(selectedVersion.created_at), 'MMMM d, yyyy')}
                    </p>
                  </div>
                  <div className="flex flex-col items-end gap-2">
                    <span className={`px-3 py-1 rounded-full text-sm font-medium ${getVersionTypeColor(selectedVersion.version_type)}`}>
                      {selectedVersion.version_type.toLowerCase()}
                    </span>
                    {selectedVersion.requires_refresh && (
                      <span className="text-xs text-yellow-600 dark:text-yellow-400">
                        Requires page refresh
                      </span>
                    )}
                  </div>
                </div>
                <div className="prose dark:prose-invert max-w-none">
                  <ReactMarkdown
                    components={{
                      h3: ({node, ...props}) => <h3 className={markdownStyles.h3} {...props}/>,
                      ul: ({node, ...props}) => <ul className={markdownStyles.ul} {...props}/>,
                      li: ({node, ...props}) => <li className={markdownStyles.li} {...props}/>,
                      p: ({node, ...props}) => <p className={markdownStyles.p} {...props}/>,
                      code: ({node, ...props}) => <code className={markdownStyles.code} {...props}/>,
                      strong: ({node, ...props}) => <strong className={markdownStyles.strong} {...props}/>,
                    }}
                  >
                    {selectedVersion.release_notes[selectedLanguage]}
                  </ReactMarkdown>
                </div>
              </div>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
