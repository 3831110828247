import React, { useEffect, useState } from 'react';
import { Bell, Loader2, Archive, Check, CheckSquare, ArchiveX } from 'lucide-react';
import { toast } from 'sonner';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogBody,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { useNavigate } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useNotifications } from '@/contexts/NotificationContext';
import axios from '@/api/axiosConfig';

interface Notification {
  id: number;
  title: string;
  message: string;
  type: string;
  data: Record<string, any>;
  navigation?: {
    route: string;
    params?: Record<string, string | number>;
  };
  is_read: boolean;
  is_archived: boolean;
  created_at: string;
}

interface NotificationsModalProps {
  visible: boolean;
  onClose: () => void;
}

const NotificationsModal: React.FC<NotificationsModalProps> = ({ visible, onClose }) => {
  const { notifications: contextNotifications, markAsRead, archiveNotifications, fetchNotifications } = useNotifications();
  const [localNotifications, setLocalNotifications] = useState<Notification[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState<'unread' | 'read'>('unread');
  const navigate = useNavigate();

  // Update local notifications when context notifications change
  useEffect(() => {
    setLocalNotifications(contextNotifications);
  }, [contextNotifications]);

  useEffect(() => {
    let mounted = true;

    if (visible) {
      setLoading(true);
      fetchNotifications()
        .then(() => {
          if (mounted) {
            setError(null);
          }
        })
        .catch((err) => {
          if (mounted) {
            const errorMessage = err.response?.data?.error || err.message || 'Failed to load notifications';
            setError(errorMessage);
            console.error('Error fetching notifications:', {
              message: err.message,
              status: err.response?.status,
              data: err.response?.data
            });
          }
        })
        .finally(() => {
          if (mounted) {
            setLoading(false);
          }
        });
    }

    return () => {
      mounted = false;
    };
  }, [visible]);

  const handleMarkAsRead = async (notificationIds: number[]) => {
    // Store previous state for rollback
    const previousNotifications = [...localNotifications];
    
    // Optimistically update local state
    setLocalNotifications(prevNotifications =>
      prevNotifications.map(notification =>
        notificationIds.includes(notification.id)
          ? { ...notification, is_read: true }
          : notification
      )
    );

    try {
      await markAsRead(notificationIds);
    } catch (err) {
      // Revert to previous state on error
      setLocalNotifications(previousNotifications);
      console.error('Error marking notifications as read:', err);
      toast.error('Failed to mark notifications as read');
    }
  };

  const handleArchive = async (notificationIds: number[]) => {
    // Store previous state for rollback
    const previousNotifications = [...localNotifications];
    
    // Optimistically update local state
    setLocalNotifications(prevNotifications =>
      prevNotifications.filter(notification => !notificationIds.includes(notification.id))
    );

    try {
      await archiveNotifications(notificationIds);
    } catch (err) {
      // Revert to previous state on error
      setLocalNotifications(previousNotifications);
      console.error('Error archiving notifications:', err);
      toast.error('Failed to archive notifications');
    }
  };

  const handleNotificationClick = async (notification: Notification) => {
    if (!notification.is_read) {
      await handleMarkAsRead([notification.id]);
    }
    
    if (notification.navigation) {
      const { route, params } = notification.navigation;
      
      // Special handling for custom board task routes
      if (route === '/boards' && params?.boardId && params?.taskId) {
        navigate(`/custom-boards/${params.boardId}/task/${params.taskId}`);
      } else if (params) {
        const queryParams = new URLSearchParams();
        Object.entries(params).forEach(([key, value]) => {
          queryParams.append(key, String(value));
        });
        navigate(`${route}?${queryParams.toString()}`);
      } else {
        navigate(route);
      }
      onClose();
    }
  };

  const handleMarkAllAsRead = async () => {
    // Store previous state for rollback
    const previousNotifications = [...localNotifications];
    
    // Optimistically update local state
    setLocalNotifications(prevNotifications =>
      prevNotifications.map(notification => ({
        ...notification,
        is_read: true
      }))
    );

    try {
      const response = await axios.post('/notifications/mark-all-read');

      if (response.data.count === 0) {
        throw new Error('No notifications were marked as read');
      }

      await fetchNotifications();
      toast.success('All notifications marked as read');
    } catch (err) {
      // Revert to previous state on error
      setLocalNotifications(previousNotifications);
      console.error('Error marking all notifications as read:', err);
      toast.error('Failed to mark all notifications as read');
    }
  };

  const handleArchiveAll = async (isRead: boolean) => {
    const notificationIdsToArchive = localNotifications
      .filter(n => n.is_read === isRead)
      .map(n => n.id);
    
    if (notificationIdsToArchive.length === 0) return;

    const previousNotifications = [...localNotifications];
    
    setLocalNotifications(prevNotifications =>
      prevNotifications.filter(notification => !notificationIdsToArchive.includes(notification.id))
    );

    try {
      const response = await axios.post('/notifications/archive', {
        notification_ids: notificationIdsToArchive
      });

      if (!response.data) throw new Error('Failed to archive notifications');

      toast.success('Notifications archived');
    } catch (err) {
      setLocalNotifications(previousNotifications);
      console.error('Error archiving notifications:', err);
      toast.error('Failed to archive notifications');
    }
  };

  const filteredNotifications = localNotifications.filter(n => 
    activeTab === 'unread' ? !n.is_read : n.is_read
  );

  const unreadCount = localNotifications.filter(n => !n.is_read).length;

  const renderNotificationsList = () => {
    if (loading) {
      return (
        <div className="flex items-center justify-center py-8">
          <Loader2 className="h-8 w-8 animate-spin text-muted-foreground" />
        </div>
      );
    }

    if (error) {
      return (
        <div className="flex flex-col items-center justify-center py-8 text-center">
          <p className="text-destructive">{error}</p>
          <Button
            variant="outline"
            size="sm"
            onClick={fetchNotifications}
            className="mt-4"
          >
            Try Again
          </Button>
        </div>
      );
    }

    if (filteredNotifications.length === 0) {
      return (
        <div className="flex flex-col items-center py-8 text-center">
          <Bell 
            className="h-16 w-16 mb-6 text-muted-foreground" 
            strokeWidth={1.5} 
          />
          <h3 className="text-lg font-semibold mb-2">
            {activeTab === 'unread' ? 'No new notifications' : 'No read notifications'}
          </h3>
          <p className="text-muted-foreground">
            {activeTab === 'unread' 
              ? "You're all caught up! Check back later for updates."
              : "You haven't read any notifications yet."}
          </p>
        </div>
      );
    }

    return (
      <div className="space-y-4">
        {filteredNotifications.map((notification) => (
          <div
            key={notification.id}
            className={`
              relative flex flex-col gap-2 p-4 rounded-lg border border-border cursor-pointer
              transition-colors duration-200
              ${notification.is_read ? 'bg-background' : 'bg-muted'}
              ${notification.navigation ? 'hover:bg-accent' : ''}
            `}
            onClick={() => handleNotificationClick(notification)}
          >
            <div className="flex items-start justify-between gap-2">
              <h4 className="font-semibold">{notification.title}</h4>
              <div className="flex items-center gap-2">
                {!notification.is_read && (
                  <TooltipProvider delayDuration={0}>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Button
                          variant="ghost"
                          size="icon"
                          className="h-8 w-8"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleMarkAsRead([notification.id]);
                          }}
                        >
                          <Check className="h-4 w-4" />
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>Mark as read</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                )}
                <TooltipProvider delayDuration={0}>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        variant="ghost"
                        size="icon"
                        className="h-8 w-8"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleArchive([notification.id]);
                        }}
                      >
                        <Archive className="h-4 w-4" />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Archive</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
            </div>
            <p className="text-sm text-muted-foreground">
              {notification.message}
            </p>
            <span className="text-xs text-muted-foreground">
              {formatDistanceToNow(new Date(notification.created_at), { addSuffix: true })}
            </span>
          </div>
        ))}
      </div>
    );
  };

  const renderActionButtons = () => {
    if (loading || error || filteredNotifications.length === 0) return null;

    return (
      <div className="flex justify-end gap-2 mb-4 mr-2">
        {activeTab === 'unread' && (
          <Button
            variant="outline"
            size="sm"
            className="flex items-center gap-1"
            onClick={handleMarkAllAsRead}
          >
            <CheckSquare className="h-4 w-4" />
            Mark all read
          </Button>
        )}
        <Button
          variant="outline"
          size="sm"
          className="flex items-center gap-1"
          onClick={() => handleArchiveAll(activeTab === 'read')}
        >
          <ArchiveX className="h-4 w-4" />
          Archive all {activeTab === 'unread' ? 'new' : 'read'}
        </Button>
      </div>
    );
  };

  return (
    <Dialog open={visible} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="flex items-center gap-2">
            <Bell className="h-5 w-5" />
            Notifications
          </DialogTitle>
          <DialogDescription className="sr-only">
            Your notifications and updates
          </DialogDescription>
        </DialogHeader>

        <div className="mb-4">
          <Tabs value={activeTab} onValueChange={(value) => setActiveTab(value as 'unread' | 'read')}>
            <TabsList className="grid w-full grid-cols-2">
              <TabsTrigger value="unread" className="relative">
                New
                {unreadCount > 0 && (
                  <span className="absolute -top-1 -right-1 flex h-4 w-4 items-center justify-center rounded-full bg-primary text-[10px] text-primary-foreground">
                    {unreadCount}
                  </span>
                )}
              </TabsTrigger>
              <TabsTrigger value="read">Read</TabsTrigger>
            </TabsList>
          </Tabs>
        </div>

        {renderActionButtons()}

        <DialogBody maxBodyHeight>
          {renderNotificationsList()}
        </DialogBody>
      </DialogContent>
    </Dialog>
  );
};

export default NotificationsModal;