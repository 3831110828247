import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from '@/api/axiosConfig';
import { toast } from 'sonner';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Switch } from '@/components/ui/switch';
import { DatePicker } from '@/components/ui/date/date-picker';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogBody, DialogFooter } from '@/components/ui/dialog';
import { Loading } from '@/components/ui/loading';
import { ArrowLeft, Loader2, Building2, AppWindow, ClipboardCheck, StickyNote, Mail, Copy } from 'lucide-react';
import { cn } from '@/lib/utils';
import { format } from 'date-fns';
import { dealDataConfig } from '../configs/DealDataConfig';
import { dealSoftwareConfig } from '../configs/DealSoftwareConfig';
import { dealOnboardingConfig } from '../configs/DealOnboardingConfig';
import { ProgressSteps } from '@/components/ui/progress-steps';
import type { Deal, Product, Software, DealData, SoftwareKey } from '@/types/customers';
import type { DealDataField, DropdownOption } from '../configs/DealDataConfig';
import { Textarea } from '@/components/ui/textarea';
import { Table, type ColumnDef } from '@/components/ui/specialized/mod-table';
import dayjs from 'dayjs';
import { Badge } from "@/components/ui/badge";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { Slider } from "@/components/ui/slider";
import { AvatarGroup } from "@/components/ui/avatars/avatar-group";
import { useUsers } from "@/contexts/UserContext";
import type { StoredUser } from "@/types/user";
import { Checkbox } from '@/components/ui/checkbox';
import { ScrollArea } from "@/components/ui/scroll-area";
import { Separator } from "@/components/ui/separator";

// Utility function for currency formatting
const formatCurrency = (amount: number): string => {
  return new Intl.NumberFormat('da-DK', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(amount);
};

interface DealChanges {
  data?: Record<string, any>;
  software?: Software;
  onboarding_data?: Record<string, any>;
  type?: 'drift' | 'holding' | 'primary' | 'secondary';
  is_holding?: boolean | null;
  payment_details?: Record<string, any>;
  contact_cpr?: string;
  [key: string]: any;
}

interface Task {
  type: string;
  frequency: string;
  next_work_date: string;
  next_due_date: string;
  assignee: string;
  description: string;
}

interface OnboardingProduct {
  id: number;
  name: string;
  sum: number;
}

interface ConfiguredTask {
  board_id: number;
  board_name: string;
  title: string;
  description?: string;
  assigned_users: string[];
  work_date: string;
  progress_status_id: number;
  deal_name?: string;
}

interface TaskConfigurationDialogProps {
  open: boolean;
  onClose: () => void;
  tasks: ConfiguredTask[];
  onConfirm: () => void;
  deal: Deal | null;
}

const getNestedValue = (obj: Deal | null, path: string): any => {
  if (!obj) return undefined;
  return path.split('.').reduce((acc: any, key) => {
    if (acc === null || acc === undefined) return undefined;
    return acc[key];
  }, obj);
};

const TaskConfigurationDialog: React.FC<TaskConfigurationDialogProps> = ({
  open,
  onClose,
  tasks,
  onConfirm,
  deal
}) => {
  const { users } = useUsers();

  // Define the columns for the mod-table
  const columns: ColumnDef<ConfiguredTask>[] = [
    { 
      key: 'board_name',
      title: 'Board',
      dataIndex: 'board_name'
    },
    { 
      key: 'title',
      title: 'Task',
      dataIndex: 'title'
    },
    {
      key: 'deal',
      title: 'Deal',
      dataIndex: 'title',
      cell: () => deal?.name || 'N/A'
    },
    { 
      key: 'assigned_users',
      title: 'Assignees',
      dataIndex: 'assigned_users',
      cell: ({ row }) => {
        const assignedUsers = row.original.assigned_users
          .map(userId => users.find(u => u.user_id.toString() === userId))
          .filter((user): user is StoredUser => user !== undefined);

        return assignedUsers.length > 0 ? (
          <AvatarGroup users={assignedUsers} max={3} />
        ) : (
          <span className="text-muted-foreground">No assignees</span>
        );
      }
    },
    { 
      key: 'work_date',
      title: 'Work Date',
      dataIndex: 'work_date'
    }
  ];

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="max-w-4xl">
        <DialogHeader>
          <DialogTitle>Configure Tasks</DialogTitle>
          <DialogDescription className="sr-only">
            Review tasks to be created
          </DialogDescription>
        </DialogHeader>
        
        <DialogBody maxBodyHeight>
          <div className="relative w-full">
            <Table
              data={tasks}
              columns={columns}
              pageSize={10}
            />
          </div>
        </DialogBody>

        <DialogFooter>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={onConfirm}>
            Create Tasks
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default function OnboardingTemp() {
  const { dealId } = useParams();
  const navigate = useNavigate();
  const [deal, setDeal] = useState<Deal | null>(null);
  const [loading, setLoading] = useState(true);
  const [changes, setChanges] = useState<DealChanges>({});
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [taskConfig, setTaskConfig] = useState<Task[] | null>(null);
  const [editedTasks, setEditedTasks] = useState<Task[] | null>(null);
  const [tasksStatus, setTasksStatus] = useState<string | null>(null);
  const [notes, setNotes] = useState<string>('');
  const [isSavingNotes, setIsSavingNotes] = useState(false);
  const [products, setProducts] = useState<OnboardingProduct[]>([]);
  const [onboardingProduct, setOnboardingProduct] = useState<OnboardingProduct | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [justCreatedInEconomic, setJustCreatedInEconomic] = useState(false);
  const [showPartialDialog, setShowPartialDialog] = useState(false);
  const [selectedPercentage, setSelectedPercentage] = useState(50);
  const [remainingMonths, setRemainingMonths] = useState('1');
  const [draftInvoice, setDraftInvoice] = useState<any>(null);
  const [configuredTasks, setConfiguredTasks] = useState<ConfiguredTask[]>([]);
  const [showTaskConfig, setShowTaskConfig] = useState(false);
  const [invoiceStatus, setInvoiceStatus] = useState<{
    has_been_invoiced: boolean;
    invoice_type?: 'draft' | 'booked';
    invoice_number?: string;
  } | null>(null);
  const [showEmailDialog, setShowEmailDialog] = useState(false);
  const [emailContent, setEmailContent] = useState<{
    contact_name: string;
    contact_email: string;
    bilagsmail: string;
    ansvarlig_bogholder: string;
  } | null>(null);
  const [emailSentConfirmed, setEmailSentConfirmed] = useState(false);
  const [emailError, setEmailError] = useState<string | null>(null);

  const steps = [
    {
      title: 'Deal Information',
      description: 'Review and update deal information'
    },
    {
      title: 'Create Customer',
      description: 'Create customer in E-conomic'
    },
    {
      title: 'Onboarding Invoice',
      description: 'Generate onboarding invoice'
    },
    {
      title: 'Configure Tasks',
      description: 'Set up recurring tasks'
    },
    {
      title: 'Complete Onboarding',
      description: 'Finalize the onboarding process'
    }
  ];

  useEffect(() => {
    fetchDeal();
  }, [dealId]);

  useEffect(() => {
    if (deal?.onboarding_data?.notes) {
      setNotes(deal.onboarding_data.notes);
    }
  }, [deal]);

  useEffect(() => {
    if (deal?.id) {
      fetchProducts(deal.id);
    }
  }, [deal]);

  useEffect(() => {
    const onboardingProd = products.find(p => p.name === "Onboarding");
    setOnboardingProduct(onboardingProd || null);
  }, [products]);

  useEffect(() => {
    if (currentStep === 2 && dealId) {
      checkInvoiceStatus();
    }
  }, [currentStep, dealId]);

  // Also check invoice status when entering the component if we're already on step 2
  useEffect(() => {
    if (currentStep === 2 && dealId) {
      checkInvoiceStatus();
    }
  }, []); // Run once on mount

  const fetchDeal = async () => {
    if (!dealId) return;
    
    try {
      const response = await axios.get(`/onboarding/deal/${dealId}`);
      setDeal(response.data);
    } catch (error) {
      toast.error('Failed to fetch deal');
    } finally {
      setLoading(false);
    }
  };

  const fetchProducts = async (dealId: number) => {
    try {
      const response = await axios.get(`/onboarding/deal/${dealId}/products`);
      setProducts(response.data);
    } catch (error) {
      toast.error('Error fetching products');
    }
  };

  const handleEdit = (key: string, value: any) => {
    setChanges(prev => ({
      ...prev,
      [key]: value
    }));
  };

  const handleSave = () => {
    setConfirmVisible(true);
  };

  const confirmSave = async () => {
    if (!dealId) return;
    
    try {
      setIsSaving(true);
      const updatedChanges: DealChanges = {};
      
      // Handle contact CPR update if changed
      if (deal?.contacts?.[0] && changes.contact_cpr) {
        try {
          await axios.patch(`/contact/${deal.contacts[0].id}`, {
            cpr: changes.contact_cpr
          });
          // Update local deal state to reflect CPR change
          if (deal.contacts) {
            deal.contacts[0].cpr = changes.contact_cpr;
          }
        } catch (error) {
          console.error('Error updating contact CPR:', error);
          toast.error('Failed to update contact CPR');
          return;
        }
      }

      // Start with ALL existing data to preserve it
      const updatedData = { ...deal?.data } as DealData;
      const updatedSoftware = { ...(deal?.software || {})} as Software;
      const updatedOnboardingData = { ...(deal?.onboarding_data || {})};
      const updatedPaymentDetails = { ...(deal?.payment_details || {})};
      
      // Debug log the changes being processed
      console.log('Processing changes:', changes);
      
      Object.entries(changes).forEach(([key, value]) => {
        if (key.startsWith('software.')) {
          const softwareKey = key.split('.')[1] as SoftwareKey;
          const softwareConfig = dealSoftwareConfig.find(config => config.key === softwareKey);
          
          if (softwareConfig?.multiSelect) {
            (updatedSoftware as any)[softwareKey] = value as string[];
          } else {
            (updatedSoftware as any)[softwareKey] = value as string;
          }
        } else if (key.startsWith('payment_details.')) {
          const paymentKey = key.split('.')[1];
          updatedPaymentDetails[paymentKey] = value;
        } else if (key.startsWith('data.')) {
          const nestedKey = key.split('.')[1];
          // For economic customer number, save to both places
          if (nestedKey === 'kundenummer_i_economic') {
            updatedChanges.economic_customer_number = value;
            updatedData[nestedKey] = value;
          } else {
            // For all data fields, always save to the nested data object
            updatedData[nestedKey] = value;
          }
        } else if (key.startsWith('onboarding_data.')) {
          const nestedKey = key.split('.')[1];
          const onboardingConfig = dealOnboardingConfig.find(config => config.key === key);
          
          if (onboardingConfig?.multiSelect) {
            updatedOnboardingData[nestedKey] = value as string[];
          } else {
            updatedOnboardingData[nestedKey] = value;
          }
        } else {
          // Handle direct model fields
          switch (key) {
            case 'main_mailbox':
            case 'corpay_mailbox':
            case 'aiia_mailbox':
            case 'description':
            case 'type':
            case 'is_holding':
              updatedChanges[key] = value;
              break;
            default:
              updatedChanges[key] = value;
          }
        }
      });

      // Always include the complete data object to preserve all fields
      updatedChanges.data = updatedData;
      
      // Only include these fields if they have values to update
      if (Object.keys(updatedSoftware).length > 0) {
        updatedChanges.software = updatedSoftware;
      }
      if (Object.keys(updatedOnboardingData).length > 0) {
        updatedChanges.onboarding_data = updatedOnboardingData;
      }
      if (Object.keys(updatedPaymentDetails).length > 0) {
        updatedChanges.payment_details = updatedPaymentDetails;
      }

      // Debug log the final update payload
      console.log('Sending update:', {
        changes: updatedChanges,
        data: updatedChanges.data,
        originalData: deal?.data
      });

      const response = await axios.patch(`/onboarding/deal/${dealId}`, updatedChanges);
      
      if (deal && response.data) {
        setDeal(response.data);
        setChanges({});
        setConfirmVisible(false);
        toast.success('Changes saved successfully');
      }
    } catch (error: any) {
      console.error('Error saving changes:', error);
      toast.error(error.response?.data?.error || 'Error saving changes');
    } finally {
      setIsSaving(false);
    }
  };

  const handleConfigureTasks = async () => {
    try {
      const response = await axios.get(`/onboarding/deal/${dealId}/configure-tasks`);
      if (response.data.error === 'existing_tasks') {
        toast.error(`Cannot configure tasks: ${response.data.message}`);
        return;
      }
      setConfiguredTasks(response.data.tasks);
      setShowTaskConfig(true);
    } catch (error: any) {
      if (error.response?.data?.error === 'existing_tasks') {
        toast.error(`This deal already has ${error.response.data.task_count} tasks configured`);
      } else {
        toast.error('Failed to configure tasks');
      }
    }
  };

  const handleCreateConfiguredTasks = async () => {
    try {
      await axios.post(`/onboarding/deal/${dealId}/create-configured-tasks`, {
        tasks: configuredTasks
      });
      toast.success('Tasks created successfully');
      setShowTaskConfig(false);
    } catch (error) {
      toast.error('Failed to create tasks');
    }
  };

  const handleSaveTaskConfig = async () => {
    try {
      setLoading(true);
      await axios.post(`/onboarding/deal/${dealId}/tasks`, {
        recurring_tasks: editedTasks
      });
      setTasksStatus('success');
      toast.success('Tasks saved successfully');
    } catch (error) {
      setTasksStatus('error');
      toast.error('Error saving task configuration');
    } finally {
      setLoading(false);
    }
  };

  const handleCompleteOnboarding = async () => {
    try {
      setLoading(true);
      await axios.post(`/onboarding/deal/${dealId}/complete`);
      toast.success('Onboarding completed successfully');
      navigate('/customers/onboarding/cases');
    } catch (error) {
      toast.error('Error completing onboarding');
      console.error('Error completing onboarding:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSaveNotes = async () => {
    try {
      setIsSavingNotes(true);
      await axios.patch(`/onboarding/deal/${dealId}`, {
        onboarding_data: {
          ...deal?.onboarding_data,
          notes
        }
      });
      toast.success('Notes saved successfully');
    } catch (error) {
      toast.error('Error saving notes');
    } finally {
      setIsSavingNotes(false);
    }
  };

  const productColumns: ColumnDef<Product>[] = [
    { key: 'name', title: 'Name', dataIndex: 'name' },
    { key: 'code', title: 'Code', dataIndex: 'code' },
    { 
      key: 'billing_start_date', 
      title: 'Billing Start', 
      dataIndex: 'billing_start_date',
      render: (date: string) => date ? dayjs(date).format('DD/MM/YYYY') : 'N/A'
    },
    { key: 'billing_frequency', title: 'Frequency', dataIndex: 'billing_frequency' }
  ];

  const renderEditableField = (fieldConfig: DealDataField | any, value: any) => {
    const currentValue = changes[fieldConfig.key] ?? value;

    if (!fieldConfig.editable) {
      return value?.toString() || 'N/A';
    }

    switch (fieldConfig.type) {
      case 'text':
      case 'number':
        return (
          <Input
            type={fieldConfig.type}
            value={currentValue}
            onChange={(e) => handleEdit(fieldConfig.key, e.target.value)}
            className="editable-field-wrapper"
          />
        );

      case 'dropdown':
        if (fieldConfig.multiSelect) {
          const selectedValues = Array.isArray(currentValue) ? currentValue : [currentValue].filter(Boolean);
          
          return (
            <Select
              onValueChange={(value: string) => {
                if (value === '') return;
                
                // Toggle selection
                const newValues = selectedValues.includes(value)
                  ? selectedValues.filter(v => v !== value)
                  : [...selectedValues, value];
                
                handleEdit(fieldConfig.key, newValues);
              }}
              value=""
            >
              <SelectTrigger className="editable-field-wrapper min-h-[36px] h-auto">
                <div className="flex flex-wrap gap-1 py-0.5">
                  {selectedValues.length > 0 ? (
                    selectedValues.map(value => {
                      const option = fieldConfig.options?.find((opt: { value: string; label: string; color: string }) => opt.value === value);
                      if (!option) return null;
                      return (
                        <span
                          key={value}
                          className="inline-flex items-center gap-1 px-2 py-0.5 rounded text-white text-xs"
                          style={{ backgroundColor: option.color }}
                        >
                          {option.label}
                        </span>
                      );
                    })
                  ) : (
                    <SelectValue placeholder="Select options" />
                  )}
                </div>
              </SelectTrigger>
              <SelectContent>
                {fieldConfig.options?.map((option: { value: string; label: string; color: string }) => (
                  <SelectItem 
                    key={option.value} 
                    value={option.value}
                  >
                    <div className="flex items-center gap-2">
                      <div 
                        className={cn(
                          "w-4 h-4 rounded border flex items-center justify-center",
                          selectedValues.includes(option.value) 
                            ? "bg-primary border-primary" 
                            : "border-input"
                        )}
                      >
                        {selectedValues.includes(option.value) && (
                          <span className="text-white text-xs">✓</span>
                        )}
                      </div>
                      <span 
                        className="inline-block px-2 py-0.5 rounded text-white"
                        style={{ backgroundColor: option.color }}
                      >
                        {option.label}
                      </span>
                    </div>
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          );
        }
        
        return (
          <Select
            value={currentValue?.toString()}
            onValueChange={(value: string) => handleEdit(fieldConfig.key, value)}
          >
            <SelectTrigger className="editable-field-wrapper">
              <SelectValue placeholder="Select option" />
            </SelectTrigger>
            <SelectContent>
              {fieldConfig.options?.map((option: { value: string; label: string; color: string }) => (
                <SelectItem 
                  key={option.value} 
                  value={option.value}
                >
                  <span 
                    className="inline-block px-2 py-0.5 rounded text-white"
                    style={{ backgroundColor: option.color }}
                  >
                    {option.label}
                  </span>
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        );

      case 'boolean':
        return (
          <Switch
            checked={currentValue ?? false}
            onCheckedChange={(checked) => handleEdit(fieldConfig.key, checked)}
            className="editable-field-wrapper"
          />
        );

      case 'date':
        return (
          <DatePicker
            date={currentValue ? new Date(currentValue) : undefined}
            onSelect={(date) => handleEdit(fieldConfig.key, date ? format(date, 'yyyy-MM-dd') : null)}
            variant="short"
          />
        );

      default:
        return currentValue?.toString() || 'N/A';
    }
  };

  const renderDealData = () => {
    if (!deal) return null;

    // Separate fields into empty and filled for each section
    const sections = {
      deal: {
        title: 'Deal Information',
        icon: 'Building2',
        empty: [] as any[],
        filled: [] as any[],
        description: 'Basic deal information and settings'
      },
      software: {
        title: 'Software Configuration',
        icon: 'AppWindow',
        empty: [] as any[],
        filled: [] as any[],
        description: 'Software integrations and tools'
      },
      onboarding: {
        title: 'Onboarding Information',
        icon: 'ClipboardCheck',
        empty: [] as any[],
        filled: [] as any[],
        description: 'Onboarding specific details and requirements'
      }
    };

    // Process deal data fields
    dealDataConfig
      .filter(config => 
        config.key !== 'data.seneste_momsopgørelse' &&
        config.key !== 'data.sidste_fakturadato' &&
        config.key !== 'data.aktiv_kunde' &&
        config.key !== 'data.value' &&
        config.key !== 'data.onboarding_dato' &&
        config.key !== 'data.deal_source' &&
        config.key !== 'type'
      )
      .map(config => {
        // Add explanation for Is holding
        if (config.key === 'is_holding') {
          return {
            ...config,
            label: 'Is Holding',
            description: 'Er dette et holdingselskab?'
          };
        }
        return config;
      })
      .forEach((fieldConfig) => {
        const value = getNestedValue(deal, fieldConfig.key);
        if (value === undefined || value === null || value === '') {
          sections.deal.empty.push({ ...fieldConfig });
        } else {
          sections.deal.filled.push({ ...fieldConfig });
        }
      });

    // Process software fields
    dealSoftwareConfig.forEach((fieldConfig) => {
      const value = getNestedValue(deal, `software.${fieldConfig.key}`);
      if (value === undefined || value === null || value === '') {
        sections.software.empty.push({ ...fieldConfig, key: `software.${fieldConfig.key}` });
      } else {
        sections.software.filled.push({ ...fieldConfig, key: `software.${fieldConfig.key}` });
      }
    });

    // Process onboarding fields
    dealOnboardingConfig
      .filter(config => config.key !== 'onboarding.notes') // Filter out notes
      .forEach((fieldConfig) => {
        const value = getNestedValue(deal, fieldConfig.key);
        if (value === undefined || value === null || value === '') {
          sections.onboarding.empty.push({ ...fieldConfig });
        } else {
          sections.onboarding.filled.push({ ...fieldConfig });
        }
      });

    const renderIcon = (iconName: string) => {
      const icons = {
        Building2: <Building2 className="h-5 w-5" />,
        AppWindow: <AppWindow className="h-5 w-5" />,
        ClipboardCheck: <ClipboardCheck className="h-5 w-5" />
      };
      return icons[iconName as keyof typeof icons];
    };

    const renderSection = (
      sectionKey: keyof typeof sections,
      fields: any[],
      isMissing: boolean = false
    ) => {
      if (fields.length === 0) return null;

      const section = sections[sectionKey];

      return (
        <div className={cn(
          "rounded-lg border bg-card text-card-foreground shadow-sm",
          isMissing && "border-l-4 border-l-yellow-500"
        )}>
          <div className="p-6">
            <div className="flex items-center gap-2 mb-2">
              <div className={cn(
                "p-2 rounded-full",
                isMissing ? "bg-yellow-500/10 text-yellow-500" : "bg-primary/10 text-primary"
              )}>
                {renderIcon(section.icon)}
              </div>
              <div>
                <h3 className={cn(
                  "text-lg font-semibold",
                  isMissing && "text-yellow-500"
                )}>
                  {isMissing ? `Missing ${section.title}` : section.title}
                </h3>
                <p className="text-sm text-muted-foreground">
                  {section.description}
                </p>
              </div>
            </div>

            <div className="space-y-4 mt-4">
              {fields.map((fieldConfig) => (
                <div key={fieldConfig.key} className="space-y-2">
                  <div className="flex flex-col gap-1">
                    <label className="font-semibold text-foreground flex items-center gap-2">
                      {fieldConfig.label}
                      {fieldConfig.required && (
                        <span className="text-sm text-red-500">*</span>
                      )}
                    </label>
                    {fieldConfig.description && (
                      <p className="text-sm text-muted-foreground">
                        {fieldConfig.description}
                      </p>
                    )}
                  </div>
                  {renderEditableField(fieldConfig, getNestedValue(deal, fieldConfig.key))}
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    };

    return (
      <div className="space-y-6">
        {/* Missing Fields Section */}
        {Object.keys(sections).map(sectionKey => {
          const section = sections[sectionKey as keyof typeof sections];
          if (section.empty.length === 0) return null;
          
          return (
            <div key={`${sectionKey}-missing`}>
              {renderSection(sectionKey as keyof typeof sections, section.empty, true)}
            </div>
          );
        })}

        {/* Filled Fields Section */}
        {Object.keys(sections).map(sectionKey => {
          const section = sections[sectionKey as keyof typeof sections];
          if (section.filled.length === 0) return null;
          
          return (
            <div key={`${sectionKey}-filled`}>
              {renderSection(sectionKey as keyof typeof sections, section.filled, false)}
            </div>
          );
        })}
      </div>
    );
  };

  const renderChanges = () => {
    return (
      <div className="space-y-2">
        {Object.keys(changes).map(key => {
          // Handle CPR changes
          if (key === 'contact_cpr') {
            return (
              <div key={key} className="grid grid-cols-2 items-center gap-4 py-2">
                <span className="font-medium text-sm">Contact CPR:</span>
                <div className="flex items-center gap-2">
                  <Badge variant="secondary">{deal?.contacts?.[0]?.cpr || 'N/A'}</Badge>
                  <span className="text-muted-foreground mx-2">→</span>
                  <Badge variant="secondary">{changes[key]}</Badge>
                </div>
              </div>
            );
          }

          const fieldConfig = [...dealDataConfig, ...dealSoftwareConfig.map(c => ({
            ...c,
            key: `software.${c.key}`
          })), ...dealOnboardingConfig].find(config => config.key === key);

          if (fieldConfig) {
            const newValue = changes[key];
            const originalValue = getNestedValue(deal, key);

            // Handle dropdown fields with options
            if (fieldConfig.type === 'dropdown' && fieldConfig.options) {
              const getOptionLabel = (value: string) => {
                const option = fieldConfig.options?.find(opt => opt.value === value);
                return option?.label || value;
              };

              const getOptionColor = (value: string) => {
                const option = fieldConfig.options?.find(opt => opt.value === value);
                return option?.color || '#d9d9d9';
              };

              const renderDropdownBadge = (value: string, isOriginal: boolean = false) => {
                const label = getOptionLabel(String(value)) || (isOriginal ? 'N/A' : value);
                const color = getOptionColor(String(value));
                
                return (
                  <div className="relative min-w-[100px] max-w-[300px]">
                    <div className="overflow-hidden" ref={(el) => {
                      if (el) {
                        const isTextTruncated = el.scrollWidth > el.clientWidth;
                        const tooltipTrigger = el.nextElementSibling as HTMLDivElement;
                        if (tooltipTrigger) {
                          tooltipTrigger.style.display = isTextTruncated ? 'block' : 'none';
                        }
                      }
                    }}>
                      <Badge 
                        variant="custom"
                        className="text-white whitespace-nowrap cursor-default w-full"
                        style={{ backgroundColor: color }}
                      >
                        {label}
                      </Badge>
                    </div>
                    <div className="hidden absolute inset-0">
                      <Tooltip>
                        <TooltipTrigger className="w-full h-full cursor-default" />
                        <TooltipContent side="top" align="start" className="max-w-sm">
                          <p className="break-words whitespace-pre-wrap">{label}</p>
                        </TooltipContent>
                      </Tooltip>
                    </div>
                  </div>
                );
              };

              return (
                <div key={key} className="grid grid-cols-2 items-center gap-4 py-2">
                  <div>
                    {fieldConfig.label.length > 50 ? (
                      <Tooltip delayDuration={0}>
                        <TooltipTrigger asChild>
                          <button type="button" className="text-left w-full">
                            <span className="font-medium text-sm truncate block w-full">
                              {fieldConfig.label}:
                            </span>
                          </button>
                        </TooltipTrigger>
                        <TooltipContent side="top" align="start">
                          <p className="break-words whitespace-pre-wrap">{fieldConfig.label}</p>
                        </TooltipContent>
                      </Tooltip>
                    ) : (
                      <span className="font-medium text-sm truncate block w-full">
                        {fieldConfig.label}:
                      </span>
                    )}
                  </div>
                  <div className="flex items-center gap-2">
                    {renderDropdownBadge(originalValue, true)}
                    <span className="text-muted-foreground mx-2 shrink-0">→</span>
                    {renderDropdownBadge(newValue)}
                  </div>
                </div>
              );
            }

            // Handle non-dropdown fields
            const renderBadgeWithTooltip = (value: any) => {
              const stringValue = String(value || 'N/A');
              
              return (
                <div className="relative min-w-[100px] max-w-[300px]">
                  <div className="overflow-hidden" ref={(el) => {
                    if (el) {
                      const isTextTruncated = el.scrollWidth > el.clientWidth;
                      const tooltipTrigger = el.nextElementSibling as HTMLDivElement;
                      if (tooltipTrigger) {
                        tooltipTrigger.style.display = isTextTruncated ? 'block' : 'none';
                      }
                    }
                  }}>
                    <Badge variant="secondary" className="whitespace-nowrap cursor-default w-full">
                      {stringValue}
                    </Badge>
                  </div>
                  <div className="hidden absolute inset-0">
                    <Tooltip>
                      <TooltipTrigger className="w-full h-full cursor-default" />
                      <TooltipContent side="top" align="start" className="max-w-sm">
                        <p className="break-words whitespace-pre-wrap">{stringValue}</p>
                      </TooltipContent>
                    </Tooltip>
                  </div>
                </div>
              );
            };

            return (
              <div key={key} className="grid grid-cols-2 items-center gap-4 py-2">
                <div>
                  {fieldConfig.label.length > 50 ? (
                    <Tooltip delayDuration={0}>
                      <TooltipTrigger asChild>
                        <button type="button" className="text-left w-full">
                          <span className="font-medium text-sm truncate block w-full">
                            {fieldConfig.label}:
                          </span>
                        </button>
                      </TooltipTrigger>
                      <TooltipContent side="top" align="start">
                        <p className="break-words whitespace-pre-wrap">{fieldConfig.label}</p>
                      </TooltipContent>
                    </Tooltip>
                  ) : (
                    <span className="font-medium text-sm truncate block w-full">
                      {fieldConfig.label}:
                    </span>
                  )}
                </div>
                <div className="flex items-center gap-2">
                  {renderBadgeWithTooltip(originalValue)}
                  <span className="text-muted-foreground mx-2 shrink-0">→</span>
                  {renderBadgeWithTooltip(newValue)}
                </div>
              </div>
            );
          }
          return null;
        })}
      </div>
    );
  };

  const handleGenerateInvoice = async (type: 'full' | 'partial') => {
    if (type === 'partial') {
      setShowPartialDialog(true);
      return;
    }
    
    try {
      setLoading(true);
      const response = await axios.post(`/invoice/onboarding/${dealId}/generate`, { type });
      setDraftInvoice(response.data);
      toast.success('Invoice generation started');
    } catch (error: any) {
      const errorMessage = error.response?.data?.details || error.response?.data?.error || 'Error generating invoice';
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handlePreviewInvoice = async () => {
    // Open window immediately
    const previewWindow = window.open('', '_blank');
    
    try {
        const response = await axios.get(
            `/invoice/economic/${draftInvoice.economic_invoice_id}/pdf`,
            { responseType: 'blob' }
        );
        
        // Create blob URL
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        
        // Navigate the preview window to the PDF
        if (previewWindow) {
            previewWindow.location.href = url;
        }
        
        // Clean up the blob URL after a delay to ensure it's loaded
        setTimeout(() => {
            window.URL.revokeObjectURL(url);
        }, 1000);
    } catch (error: any) {
        // Close the preview window if there was an error
        previewWindow?.close();
        const errorMessage = error.response?.data?.details || error.response?.data?.error || 'Error previewing invoice';
        toast.error(errorMessage);
    }
  };

  const handleBookInvoice = async () => {
    try {
      setLoading(true);
      await axios.post(`/invoice/book/${draftInvoice.id}`);
      setDraftInvoice(null);
      await checkInvoiceStatus();
      toast.success('Invoice booked successfully');
    } catch (error: any) {
      const errorMessage = error.response?.data?.details || error.response?.data?.error || 'Error booking invoice';
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmPartialInvoice = async () => {
    try {
      setLoading(true);
      const response = await axios.post(`/invoice/onboarding/${dealId}/generate`, {
        type: 'partial',
        percentage: selectedPercentage,
        remaining_months: remainingMonths
      });
      setDraftInvoice(response.data);
      setShowPartialDialog(false);
      toast.success('Partial invoice generated');
    } catch (error: any) {
      const errorMessage = error.response?.data?.details || error.response?.data?.error || 'Error generating partial invoice';
      toast.error(errorMessage);
      setShowPartialDialog(false);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteInvoice = async () => {
    try {
        await axios.delete(`/invoice/onboarding/${dealId}/invoice/${draftInvoice.id}`);
        setDraftInvoice(null);
        // Refresh products since we might have deleted a remaining fee product
        await fetchProducts(Number(dealId));
        toast.success('Invoice deleted successfully');
    } catch (error: any) {
        const errorMessage = error.response?.data?.details || error.response?.data?.error || 'Error deleting invoice';
        toast.error(errorMessage);
    }
  };

  const checkInvoiceStatus = async () => {
    try {
      const response = await axios.get(`/onboarding/deal/${dealId}/check-invoice-status`);
      setInvoiceStatus(response.data);
    } catch (error) {
      toast.error('Failed to check invoice status');
    }
  };

  const handleGenerateEmail = async () => {
    try {
      const response = await axios.get(`/onboarding/deal/${dealId}/generate-email`);
      setEmailContent(response.data);
      setEmailError(null);
      setShowEmailDialog(true);
    } catch (error: any) {
      setEmailError(error.response?.data?.error || 'Failed to generate email');
      toast.error(error.response?.data?.error || 'Failed to generate email');
    }
  };

  if (loading) {
    return <Loading message="Loading deal..." />;
  }

  return (
    <TooltipProvider>
      <div className="p-8">
        <div className="mb-8 space-y-4">
          <Button
            variant="ghost"
            onClick={() => navigate('/customers/onboarding/cases')}
            className="mb-4"
          >
            <ArrowLeft className="h-4 w-4 mr-2" />
            Back to Onboarding Cases
          </Button>
          
          <div>
            <h1 className="text-3xl font-bold text-foreground">Deal Onboarding</h1>
            <p className="text-muted-foreground">
              Complete onboarding for {deal?.name}
            </p>
          </div>

          <Card className="bg-card">
            <CardHeader className="flex flex-row items-center gap-2 pb-2">
              <StickyNote className="h-5 w-5 text-primary" />
              <div>
                <CardTitle className="text-lg">Onboarding Notes</CardTitle>
                <p className="text-sm text-muted-foreground mt-1">
                  These notes will be attached to all tasks created for this customer. 
                  Make sure to save your notes before configuring and creating tasks.
                </p>
              </div>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                <Textarea
                  placeholder="Add notes about the onboarding process..."
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  className="min-h-[100px] resize-none"
                />
                <div className="flex justify-end">
                  <Button 
                    onClick={handleSaveNotes}
                    disabled={isSavingNotes}
                    size="sm"
                  >
                    {isSavingNotes ? (
                      <>
                        <Loader2 className="h-4 w-4 animate-spin mr-2" />
                        Saving...
                      </>
                    ) : (
                      'Save Notes'
                    )}
                  </Button>
                </div>
              </div>
            </CardContent>
          </Card>

          <ProgressSteps
            steps={steps}
            currentStep={currentStep}
            variant="horizontal"
            className="mb-6"
            onStepClick={(stepIndex) => {
              // Allow moving to any step that's not further than one step ahead
              if (stepIndex <= currentStep + 1) {
                setCurrentStep(stepIndex);
              } else {
                toast.warning('Please complete the current step first');
              }
            }}
          />
        </div>

        <Card>
          <CardContent className="p-6">
            {currentStep === 0 && (
              <div className="space-y-4">
                <div className="grid grid-cols-1 lg:grid-cols-4 gap-4">
                  {/* Deal Information Card */}
                  <div className="flex flex-col bg-card rounded-lg shadow-sm lg:col-span-4">
                    <div className="flex items-center gap-2 p-3 border-b bg-muted/30 rounded-t-lg">
                      <Building2 className="h-5 w-5 text-primary" />
                      <h3 className="font-semibold text-card-foreground">
                        Deal Information
                      </h3>
                    </div>
                    <ScrollArea className="h-[calc(85vh-180px)]">
                      <div className="p-4 space-y-4">
                        {/* Contact Section */}
                        <div className="space-y-4">
                          <h4 className="text-sm font-medium text-muted-foreground">Contact Information</h4>
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div className="space-y-2">
                              <label className="text-sm font-medium">Name</label>
                              <Input
                                value={deal?.contacts?.[0]?.name || ''}
                                disabled
                              />
                            </div>
                            <div className="space-y-2">
                              <label className="text-sm font-medium">Email</label>
                              <Input
                                value={deal?.contacts?.[0]?.email || ''}
                                disabled
                              />
                            </div>
                            <div className="space-y-2">
                              <label className="text-sm font-medium">Phone</label>
                              <Input
                                value={deal?.contacts?.[0]?.phone || ''}
                                disabled
                              />
                            </div>
                            <div className="space-y-2">
                              <label className="text-sm font-medium">CPR</label>
                              <Input
                                value={changes.contact_cpr || deal?.contacts?.[0]?.cpr || ''}
                                onChange={(e) => handleEdit('contact_cpr', e.target.value)}
                                placeholder="XXXXXXXXXX"
                              />
                            </div>
                          </div>
                        </div>
                        <Separator />
                        {/* Deal Data Fields */}
                        <div className="space-y-4">
                          {renderDealData()}
                        </div>
                      </div>
                    </ScrollArea>
                  </div>
                </div>
                <div className="flex justify-end gap-2 mt-6">
                  <Button onClick={handleSave}>
                    Save Changes
                  </Button>
                  <Button onClick={() => setCurrentStep(1)}>
                    Next
                  </Button>
                </div>
              </div>
            )}

            {currentStep === 1 && (
              <div className="space-y-4">
                <div className="economic-section">
                  <h3 className="text-lg font-semibold mb-4">Create Customer in E-conomic</h3>
                  <p className="text-muted-foreground mb-4">
                    Create the customer in E-conomic with the provided information.
                  </p>

                  {/* Customer Details Card */}
                  <Card className="mb-6">
                    <CardHeader>
                      <CardTitle className="text-base">Customer Details</CardTitle>
                    </CardHeader>
                    <CardContent>
                      {deal?.economic_customer_number && !justCreatedInEconomic ? (
                        <div className="p-4 border-2 border-yellow-200 rounded-lg bg-yellow-50 dark:bg-yellow-950/10">
                          <p className="text-yellow-600 dark:text-yellow-400">
                            Customer already exists in E-conomic with number: {deal.economic_customer_number}
                          </p>
                        </div>
                      ) : deal?.economic_customer_number && justCreatedInEconomic ? (
                        <div className="p-4 border-2 border-green-200 rounded-lg bg-green-50 dark:bg-green-950/10">
                          <p className="text-green-600 dark:text-green-400">
                            Successfully created customer in E-conomic with number: {deal.economic_customer_number}
                          </p>
                        </div>
                      ) : !deal?.address || !deal?.zipcode || !deal?.city || !deal?.email ? (
                        <div className="p-4 border-2 border-red-200 rounded-lg bg-red-50 dark:bg-red-950/10">
                          <p className="text-red-500 mb-2">Missing required information:</p>
                          <ul className="list-disc list-inside space-y-1">
                            {!deal?.address && <li>Address is required</li>}
                            {!deal?.zipcode && <li>Zipcode is required</li>}
                            {!deal?.city && <li>City is required</li>}
                            {!deal?.email && <li>Email is required</li>}
                          </ul>
                        </div>
                      ) : (
                        <div className="grid grid-cols-2 gap-4">
                          <div>
                            <p className="font-medium text-sm text-muted-foreground">Address</p>
                            <p className="text-foreground">{deal.address}</p>
                          </div>
                          <div>
                            <p className="font-medium text-sm text-muted-foreground">Zipcode</p>
                            <p className="text-foreground">{deal.zipcode}</p>
                          </div>
                          <div>
                            <p className="font-medium text-sm text-muted-foreground">City</p>
                            <p className="text-foreground">{deal.city}</p>
                          </div>
                          <div>
                            <p className="font-medium text-sm text-muted-foreground">Email</p>
                            <p className="text-foreground">{deal.email}</p>
                          </div>
                          <div>
                            <p className="font-medium text-sm text-muted-foreground">Country</p>
                            <p className="text-foreground">Denmark</p>
                          </div>
                        </div>
                      )}
                    </CardContent>
                  </Card>

                  <div className="flex items-center gap-4">
                    <Button 
                      onClick={async () => {
                        try {
                          setLoading(true);
                          const response = await axios.post(`/onboarding/deal/${dealId}/create-economic`);
                          
                          if (response.data.success) {
                            // Update the deal with the new economic customer number
                            const updatedDeal = await axios.get(`/onboarding/deal/${dealId}`);
                            setDeal(updatedDeal.data);
                            setJustCreatedInEconomic(true);
                            toast.success(`Customer created successfully in E-conomic with number: ${response.data.economic_customer_number}`);
                          }
                        } catch (error: any) {
                          toast.error(error.response?.data?.error || 'Failed to create customer in E-conomic');
                        } finally {
                          setLoading(false);
                        }
                      }}
                      disabled={loading || (!justCreatedInEconomic && deal?.economic_customer_number !== undefined && deal?.economic_customer_number !== null) || !deal?.address || !deal?.zipcode || !deal?.city || !deal?.email}
                    >
                      {loading ? (
                        <>
                          <Loader2 className="h-4 w-4 animate-spin mr-2" />
                          Creating...
                        </>
                      ) : (
                        'Create in E-conomic'
                      )}
                    </Button>
                  </div>
                </div>
                <div className="flex justify-end gap-2">
                  <Button variant="outline" onClick={() => setCurrentStep(0)}>
                    Previous
                  </Button>
                  <Button 
                    onClick={() => setCurrentStep(2)}
                    disabled={!deal?.economic_customer_number}
                  >
                    Next
                  </Button>
                </div>
              </div>
            )}

            {currentStep === 2 && (
              <div className="space-y-4">
                <div className="invoice-section">
                  <h3 className="text-lg font-semibold mb-4">Generate Onboarding Invoice</h3>
                  <p className="text-muted-foreground mb-4">
                    Generate and manage onboarding invoice for the customer.
                  </p>
                  {!deal?.economic_customer_number ? (
                    <div className="flex flex-col items-center justify-center p-8 border-2 border-red-200 rounded-lg bg-red-50 dark:bg-red-950/10">
                      <p className="text-red-500 mb-4">
                        Economic customer number is required before proceeding with invoice generation
                      </p>
                      <Button
                        variant="outline"
                        onClick={() => setCurrentStep(1)}
                      >
                        Go Back to Add Economic Number
                      </Button>
                    </div>
                  ) : deal?.is_holding ? (
                    <div className="flex flex-col items-center justify-center p-8 border-2 border-yellow-200 rounded-lg bg-yellow-50 dark:bg-yellow-950/10">
                      <p className="text-yellow-500 mb-4">
                        This is a holding company. Onboarding invoices cannot be generated for holding companies.
                      </p>
                    </div>
                  ) : !products.some(p => p.name === "Onboarding") ? (
                    <div className="flex flex-col items-center justify-center p-8 border-2 border-yellow-200 rounded-lg bg-yellow-50 dark:bg-yellow-950/10">
                      <p className="text-yellow-500 mb-4">
                        No onboarding product found. Please add an onboarding product before generating an invoice.
                      </p>
                    </div>
                  ) : invoiceStatus?.has_been_invoiced ? (
                    <div className="flex flex-col items-center justify-center p-8 border-2 border-yellow-200 rounded-lg bg-yellow-50 dark:bg-yellow-950/10">
                      <p className="text-yellow-500 mb-4">
                        This deal has already been invoiced ({invoiceStatus.invoice_type} invoice #{invoiceStatus.invoice_number})
                      </p>
                    </div>
                  ) : (
                    <div className="space-y-6">
                      {/* Invoice Options */}
                      <div className="grid grid-cols-2 gap-4">
                        <Card className="p-6 hover:border-primary cursor-pointer transition-colors" onClick={() => handleGenerateInvoice('full')}>
                          <h4 className="font-semibold mb-2">Full Onboarding Fee</h4>
                          <p className="text-sm text-muted-foreground">Generate invoice for the total onboarding fee amount</p>
                        </Card>
                        <Card className="p-6 hover:border-primary cursor-pointer transition-colors" onClick={() => handleGenerateInvoice('partial')}>
                          <h4 className="font-semibold mb-2">Partial Onboarding Fee</h4>
                          <p className="text-sm text-muted-foreground">Generate invoice for part of the total onboarding fee</p>
                        </Card>
                      </div>

                      {/* Partial Payment Dialog */}
                      <Dialog open={showPartialDialog} onOpenChange={setShowPartialDialog}>
                        <DialogContent>
                          <DialogHeader>
                            <DialogTitle>Partial Onboarding Fee</DialogTitle>
                            <DialogDescription className="sr-only">
                              Select percentage and remaining payment schedule
                            </DialogDescription>
                          </DialogHeader>
                          <DialogBody>
                            <div className="space-y-6">
                              <div>
                                <label className="text-sm font-medium mb-2 block">
                                  Initial Payment Percentage: {selectedPercentage}%
                                </label>
                                <Slider
                                  value={[selectedPercentage]}
                                  onValueChange={(values: number[]) => setSelectedPercentage(values[0])}
                                  min={30}
                                  max={90}
                                  step={10}
                                  className="w-full"
                                />
                                <p className="text-sm text-muted-foreground mt-2">
                                  Amount: {onboardingProduct?.sum ? formatCurrency(onboardingProduct.sum * (selectedPercentage / 100)) : 'N/A'} DKK
                                </p>
                              </div>

                              <div>
                                <label className="text-sm font-medium mb-2 block">
                                  Remaining Payment Schedule
                                </label>
                                <Select value={remainingMonths} onValueChange={setRemainingMonths}>
                                  <SelectTrigger>
                                    <SelectValue placeholder="Select months" />
                                  </SelectTrigger>
                                  <SelectContent>
                                    <SelectItem value="1">In 1 month</SelectItem>
                                    <SelectItem value="2">In 2 months</SelectItem>
                                    <SelectItem value="3">In 3 months</SelectItem>
                                  </SelectContent>
                                </Select>
                              </div>
                            </div>
                          </DialogBody>
                          <DialogFooter>
                            <Button variant="outline" onClick={() => setShowPartialDialog(false)}>
                              Cancel
                            </Button>
                            <Button onClick={handleConfirmPartialInvoice}>
                              Generate Invoice
                            </Button>
                          </DialogFooter>
                        </DialogContent>
                      </Dialog>

                      {/* Draft Invoice Preview */}
                      {draftInvoice && (
                        <Card className="p-6">
                          <h4 className="font-semibold mb-4">Draft Invoice Details</h4>
                          <div className="space-y-2">
                            <p><span className="font-medium">Product:</span> {draftInvoice.items[0].name}</p>
                            <p><span className="font-medium">Quantity:</span> {draftInvoice.items[0].quantity}</p>
                            <p><span className="font-medium">Amount:</span> {formatCurrency(draftInvoice.total_amount)} DKK</p>
                            <div className="flex gap-2 mt-4">
                              <Button onClick={handlePreviewInvoice} variant="outline">
                                Preview Invoice
                              </Button>
                              <Button onClick={handleBookInvoice}>
                                Book Invoice
                              </Button>
                              <Button 
                                onClick={handleDeleteInvoice} 
                                variant="destructive"
                              >
                                Delete Invoice
                              </Button>
                            </div>
                          </div>
                        </Card>
                      )}
                    </div>
                  )}
                </div>
                <div className="flex justify-end gap-2">
                  <Button variant="outline" onClick={() => setCurrentStep(1)}>
                    Previous
                  </Button>
                  <Button 
                    onClick={() => setCurrentStep(3)}
                    disabled={!deal?.economic_customer_number}
                  >
                    Next
                  </Button>
                </div>
              </div>
            )}

            {currentStep === 3 && (
              <div className="space-y-4">
                <div className="tasks-section">
                  <h3 className="text-lg font-semibold mb-4">Task Configuration</h3>
                  <p className="text-muted-foreground mb-4">
                    Configure recurring tasks for this deal. This step is optional and can be configured later.
                  </p>

                  <div className="products-section mb-6">
                    <h3 className="text-foreground font-semibold mb-4">Deal Products</h3>
                    <Table
                      data={products}
                      columns={productColumns}
                      pageSize={10}
                      onPageChange={(page: number) => {/* handle page change */}}
                      className="w-full"
                    />
                  </div>

                  {!taskConfig ? (
                    <Button 
                      onClick={handleConfigureTasks}
                      disabled={loading}
                    >
                      {loading ? (
                        <>
                          <Loader2 className="h-4 w-4 animate-spin mr-2" />
                          Configuring...
                        </>
                      ) : (
                        'Configure Tasks'
                      )}
                    </Button>
                  ) : (
                    <Button 
                      onClick={handleSaveTaskConfig}
                      disabled={loading || tasksStatus === 'success'}
                    >
                      {loading ? (
                        <>
                          <Loader2 className="h-4 w-4 animate-spin mr-2" />
                          Saving...
                        </>
                      ) : (
                        'Save Task Configuration'
                      )}
                    </Button>
                  )}
                </div>
                <div className="flex justify-end gap-2">
                  <Button variant="outline" onClick={() => setCurrentStep(2)}>
                    Previous
                  </Button>
                  <Button onClick={() => setCurrentStep(4)}>
                    Next
                  </Button>
                </div>
              </div>
            )}

            {currentStep === 4 && (
              <div className="space-y-4">
                <h3 className="text-lg font-semibold">Complete Onboarding</h3>
                <p className="text-muted-foreground">
                  Review all changes and complete the onboarding process.
                </p>

                {emailError && (
                  <div className="p-4 border-2 border-red-200 rounded-lg bg-red-50 dark:bg-red-950/10">
                    <p className="text-red-500">{emailError}</p>
                  </div>
                )}

                <div className="flex flex-col gap-4">
                  <Button 
                    variant="outline"
                    onClick={handleGenerateEmail}
                    className="w-fit"
                  >
                    <Mail className="h-4 w-4 mr-2" />
                    Generate Onboarding Email
                  </Button>

                  <div className="flex items-center space-x-2">
                    <Checkbox
                      id="emailSent"
                      checked={emailSentConfirmed}
                      onCheckedChange={(checked) => setEmailSentConfirmed(checked as boolean)}
                    />
                    <label
                      htmlFor="emailSent"
                      className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    >
                      I confirm that I have sent the onboarding email to the customer
                    </label>
                  </div>

                  <Button 
                    onClick={handleCompleteOnboarding}
                    disabled={loading || !emailSentConfirmed}
                  >
                    {loading ? (
                      <>
                        <Loader2 className="h-4 w-4 animate-spin mr-2" />
                        Completing...
                      </>
                    ) : (
                      'Complete Onboarding'
                    )}
                  </Button>
                </div>

                <div className="flex justify-end gap-2">
                  <Button variant="outline" onClick={() => setCurrentStep(3)}>
                    Previous
                  </Button>
                </div>
              </div>
            )}
          </CardContent>
        </Card>

        <Dialog open={confirmVisible} onOpenChange={setConfirmVisible}>
          <DialogContent className="max-w-3xl">
            <TooltipProvider>
              <DialogHeader>
                <DialogTitle>Confirm Changes</DialogTitle>
                <DialogDescription className="sr-only">
                  Review the changes before saving
                </DialogDescription>
              </DialogHeader>
              <DialogBody>
                {renderChanges()}
              </DialogBody>
              <DialogFooter>
                <Button variant="outline" onClick={() => setConfirmVisible(false)} disabled={isSaving}>
                  Cancel
                </Button>
                <Button onClick={confirmSave} disabled={isSaving}>
                  {isSaving ? (
                    <>
                      <Loader2 className="h-4 w-4 animate-spin mr-2" />
                      Saving...
                    </>
                  ) : (
                    'Save Changes'
                  )}
                </Button>
              </DialogFooter>
            </TooltipProvider>
          </DialogContent>
        </Dialog>

        <TaskConfigurationDialog
          open={showTaskConfig}
          onClose={() => setShowTaskConfig(false)}
          tasks={configuredTasks}
          onConfirm={handleCreateConfiguredTasks}
          deal={deal}
        />

        <EmailDialog
          open={showEmailDialog}
          onClose={() => setShowEmailDialog(false)}
          emailContent={emailContent}
        />
      </div>
    </TooltipProvider>
  );
}

const EmailDialog: React.FC<{
  open: boolean;
  onClose: () => void;
  emailContent: {
    contact_name: string;
    contact_email: string;
    bilagsmail: string;
    ansvarlig_bogholder: string;
  } | null;
}> = ({ open, onClose, emailContent }) => {
  const [copied, setCopied] = useState(false);

  const getEmailText = () => {
    if (!emailContent) return '';

    const { contact_name, contact_email, bilagsmail, ansvarlig_bogholder } = emailContent;
    
    if (ansvarlig_bogholder === 'Dennis Suksuwat') {
      return `Hej ${contact_name},

Tak for mødet. Som lovet kommer her email med informationer om din bogholder:

Navn: Dennis Suksawat Wagner
Email: Dennis@digi-tal.dk
Telefon: +45 89 88 21 14
Book opkald her: https://calendly.com/dennis-digi-tal/onboarding

Vi har derudover oprettet din kvitteringsmail, som du allerede nu kan begynde at bruge. Hver gang du får en elektronisk kvittering, kan du blot videresende den hertil. Så klarer vi resten.
Mailen hedder: ${bilagsmail}

Ræk endelig ud hvis der er noget vi kan hjælpe med. Vi ser frem til samarbejdet.`;
    }

    return `Hej ${contact_name},

Tak for mødet. Som lovet kommer her email med informationer om din bogholder:

Navn: Mads Brock-Madsen
Email: Mads@digi-tal.dk
Telefon: +45 89 87 02 88

Vi har derudover oprettet din kvitteringsmail, som du allerede nu kan begynde at bruge. Hver gang du får en elektronisk kvittering, kan du blot videresende den hertil. Så klarer vi resten.
Mailen hedder: ${bilagsmail}

Ræk endelig ud hvis der er noget vi kan hjælpe med. Vi ser frem til samarbejdet.`;
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(getEmailText());
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Onboarding Email</DialogTitle>
          <DialogDescription className="sr-only">
            Generated onboarding email content
          </DialogDescription>
        </DialogHeader>
        <DialogBody>
          <div className="space-y-4">
            {emailContent && (
              <div className="flex items-center gap-2 text-sm text-muted-foreground">
                <span>To:</span>
                <span className="font-medium text-foreground">{emailContent.contact_email}</span>
              </div>
            )}
            <div className="relative">
              <pre className="whitespace-pre-wrap p-4 rounded-lg bg-muted font-mono text-sm">
                {getEmailText()}
              </pre>
              <Button
                size="sm"
                variant="outline"
                className="absolute top-2 right-2"
                onClick={handleCopy}
              >
                {copied ? (
                  'Copied!'
                ) : (
                  <>
                    <Copy className="h-4 w-4 mr-2" />
                    Copy
                  </>
                )}
              </Button>
            </div>
          </div>
        </DialogBody>
        <DialogFooter>
          <Button variant="outline" onClick={onClose}>
            Close
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
