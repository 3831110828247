import * as React from "react"
import { Bar, BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, LabelList } from "recharts"
import { Button } from "@/components/ui/button"
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from "@/components/ui/card"
import { useResizeObserver } from "@/hooks/use-resize-observer"

export interface DataPoint {
  [key: string]: string | number | { paid: number; unpaid: number }
}

interface ChartFilter {
  id: string
  label: string
  color: string
}

interface FilteredBarChartProps {
  data: DataPoint[]
  title?: React.ReactNode
  description?: string
  filters: ChartFilter[]
  xAxisKey: string
  valueFormatter?: (value: number) => string
  variant?: 'stacked' | 'grouped' | 'grouped-stacked'
  barGap?: number
  barGroupGap?: number
  showTotalLabels?: 'always' | 'hover' | 'never'
}

export function FilteredBarChart({
  data,
  title,
  description,
  filters,
  xAxisKey,
  valueFormatter = (value: number) => value.toLocaleString(),
  variant = 'stacked',
  barGap = 0,
  barGroupGap = 4,
  showTotalLabels = 'never',
}: FilteredBarChartProps) {
  const [ref, { width: containerWidth }] = useResizeObserver()

  // Filter out any data points that don't have values
  const filteredData = data.map(item => {
    const result: DataPoint = { [xAxisKey]: item[xAxisKey] }
    Object.keys(item).forEach(key => {
      if (key !== xAxisKey && item[key] && 
          (typeof item[key] === 'number' || 
           ((item[key] as any)?.paid !== 0 || (item[key] as any)?.unpaid !== 0))) {
        result[key] = item[key]
      }
    })
    return result
  })

  const calculateBarTotal = (dataPoint: DataPoint, filterId: string) => {
    if (variant === 'grouped-stacked') {
      const value = dataPoint[filterId] as { paid: number; unpaid: number }
      if (!value) return 0
      return (value.paid || 0) + (value.unpaid || 0)
    }
    return (dataPoint[filterId] as number) || 0
  }

  const renderBars = () => {
    if (variant === 'grouped-stacked') {
      // Only get filters that have actual data
      const activeFilters = filters.filter(f => 
        filteredData.some(d => d[f.id] && 
          ((d[f.id] as any)?.paid > 0 || (d[f.id] as any)?.unpaid > 0))
      )

      // Calculate dynamic bar width
      const periodsCount = data.length
      const availableWidth = (containerWidth || 400) - 80 // Reduced margin
      const maxBarWidth = Math.min(
        // Use 80% of available space per period, divided by number of active filters
        (availableWidth * 0.8) / (periodsCount * activeFilters.length),
        100 // Increased maximum bar width
      )

      return activeFilters.map((filter) => {
        const originalIndex = filters.findIndex(f => f.id === filter.id)
        const baseColor = `hsl(var(--chart-multi-${((originalIndex % 5) + 1)}))`
        const unpaidColor = `hsl(var(--chart-multi-${((originalIndex % 5) + 1)}) / 0.4)`

        return (
          <React.Fragment key={filter.id}>
            <Bar
              dataKey={`${filter.id}.paid`}
              stackId={filter.id}
              fill={baseColor}
              name={`${filter.label} (Paid)`}
              maxBarSize={maxBarWidth}
            />
            <Bar
              dataKey={`${filter.id}.unpaid`}
              stackId={filter.id}
              fill={unpaidColor}
              name={`${filter.label} (Unpaid)`}
              maxBarSize={maxBarWidth}
            >
              {showTotalLabels === 'always' && (
                <LabelList
                  dataKey={(entry: any) => calculateBarTotal(entry, filter.id)}
                  position="top"
                  content={({ x, y, width, value }: any) => {
                    if (!value) return null
                    return (
                      <g>
                        <text
                          x={x + width / 2}
                          y={y - 8}
                          textAnchor="middle"
                          fill="currentColor"
                          className="text-xs"
                        >
                          {valueFormatter(value)}
                        </text>
                      </g>
                    )
                  }}
                />
              )}
            </Bar>
          </React.Fragment>
        )
      })
    }
  }

  return (
    <div ref={ref} className="w-full h-full">
      <BarChart
        data={filteredData}
        margin={{ top: 20, right: 20, left: 20, bottom: 20 }}
        width={containerWidth || 400}
        height={350}
        barGap={barGap}
        barCategoryGap={barGroupGap}
      >
        <CartesianGrid strokeDasharray="3 3" className="stroke-muted" />
        <XAxis
          dataKey={xAxisKey}
          className="text-xs text-muted-foreground"
        />
        <YAxis
          className="text-xs text-muted-foreground"
          tickFormatter={valueFormatter}
        />
        <Tooltip
          content={({ active, payload, label }) => {
            if (active && payload && payload.length) {
              return (
                <div className="rounded-lg border border-border bg-background p-2 shadow-sm">
                  <div className="grid gap-2">
                    <div className="flex flex-col">
                      <span className="text-[0.70rem] uppercase text-muted-foreground">
                        {new Date(label).toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}
                      </span>
                      {payload.map((entry) => {
                        const value = typeof entry.value === 'object' 
                          ? (entry.value as any).paid + (entry.value as any).unpaid
                          : entry.value
                        return (
                          <div key={entry.name} className="flex items-center gap-2">
                            <div 
                              className="h-2 w-2 rounded-full" 
                              style={{ backgroundColor: entry.color }}
                            />
                            <span className="text-muted-foreground">
                              {entry.name}: <span className="font-bold">{valueFormatter(value as number)}</span>
                            </span>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              )
            }
            return null
          }}
          cursor={{ fill: 'transparent' }}
        />
        <Legend />
        {renderBars()}
      </BarChart>
    </div>
  )
}