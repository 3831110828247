import { useState, useEffect } from 'react'
import { toast } from 'sonner'
import axios from '../api/axiosConfig'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { Check, ChevronsUpDown } from "lucide-react"
import { cn } from "@/lib/utils"

// UI Components
import { Button } from "@/components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogBody,
  DialogDescription,
} from "@/components/ui/dialog"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { Textarea } from "@/components/ui/textarea"
import { DatePicker } from "@/components/ui/date/date-picker"
import { Loading } from "@/components/ui/loading"
import { MultiSelect } from "@/components/ui/select/multi-select"

// Form validation
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"

// Types
import type { Deal } from '@/types/customers'
import type { ChurnFormValues, ChurnReason } from '@/types/offboarding'

const churnReasons: ChurnReason[] = [
  { value: "price", label: "Price" },
  { value: "service", label: "Service" },
  { value: "product", label: "Product" },
  { value: "competitor", label: "Competitor" },
  { value: "oversold", label: "Oversold" },
  { value: "internal_accountant", label: "Internal accountant" },
  { value: "physical_accountant", label: "Wants physical accountant/auditor" },
  { value: "business_closed", label: "Business closed" },
  { value: "terminated", label: "Terminated" },
  { value: "lost_trust", label: "Lost trust" },
  { value: "communication_issues", label: "Lack of communication, miscommunication or spam" },
  { value: "errors", label: "Errors (onboarding, bookkeeping, etc.)" },
  { value: "other", label: "Other" }
]

interface ChurnModalProps {
  open: boolean
  onClose: () => void
  onSubmit: (values: ChurnFormValues) => void
}

const formSchema = z.object({
  dealId: z.number(),
  churnDate: z.date(),
  churnReasons: z.array(z.string()).min(1, "Select at least one reason").max(3, "Maximum 3 reasons allowed"),
  otherReason: z.string().optional(),
  details: z.string().min(1, "Please provide details")
})

type FormValues = z.infer<typeof formSchema>

const ChurnModal = ({ open, onClose, onSubmit }: ChurnModalProps) => {
  const [deals, setDeals] = useState<Deal[]>([])
  const [loading, setLoading] = useState(false)
  const [openCombobox, setOpenCombobox] = useState(false)

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      churnDate: new Date(),
      churnReasons: [],
      details: ""
    }
  })

  const showOtherReason = form.watch("churnReasons")?.includes("other")

  useEffect(() => {
    if (open) {
      fetchDeals()
      form.reset()
    }
  }, [open, form])

  const fetchDeals = async () => {
    setLoading(true)
    try {
      const response = await axios.get('/churn/deals')
      setDeals(response.data)
    } catch (error) {
      console.error('Error fetching deals:', error)
      toast.error('Failed to fetch deals')
    } finally {
      setLoading(false)
    }
  }

  const handleSubmit = (values: FormValues) => {
    onSubmit(values)
    form.reset()
  }

  return (
    <Dialog open={open} onOpenChange={() => {
      form.reset()
      onClose()
    }}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Add Churned Customer</DialogTitle>
          <DialogDescription className="sr-only">Form to register a churned customer and record churn details</DialogDescription>
        </DialogHeader>

        <DialogBody>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-4">
              <FormField
                control={form.control}
                name="dealId"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <FormLabel>Select Deal</FormLabel>
                    <Popover open={openCombobox} onOpenChange={setOpenCombobox}>
                      <PopoverTrigger asChild>
                        <FormControl>
                          <Button
                            variant="outline"
                            role="combobox"
                            aria-expanded={openCombobox}
                            className="w-full justify-between"
                          >
                            {field.value
                              ? deals.find((deal) => deal.id === field.value)?.name
                              : "Select a deal..."}
                            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                          </Button>
                        </FormControl>
                      </PopoverTrigger>
                      <PopoverContent className="w-[400px] p-0">
                        <Command>
                          <CommandInput placeholder="Search deals..." />
                          <CommandEmpty>No deal found.</CommandEmpty>
                          <CommandGroup>
                            {deals.map((deal) => (
                              <CommandItem
                                key={deal.id}
                                value={deal.name}
                                onSelect={() => {
                                  field.onChange(deal.id)
                                  setOpenCombobox(false)
                                }}
                              >
                                <Check
                                  className={cn(
                                    "mr-2 h-4 w-4",
                                    field.value === deal.id ? "opacity-100" : "opacity-0"
                                  )}
                                />
                                {deal.name}
                              </CommandItem>
                            ))}
                          </CommandGroup>
                        </Command>
                      </PopoverContent>
                    </Popover>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="churnDate"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Churn Date</FormLabel>
                    <DatePicker
                      date={field.value}
                      onSelect={field.onChange}
                      variant="short"
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="churnReasons"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Churn Reasons (Select up to 3)</FormLabel>
                    <MultiSelect
                      options={churnReasons}
                      selected={field.value}
                      onChange={field.onChange}
                      placeholder="Select reasons"
                      maxValues={3}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />

              {showOtherReason && (
                <FormField
                  control={form.control}
                  name="otherReason"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Other Reason</FormLabel>
                      <FormControl>
                        <Input placeholder="Specify the other reason" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}

              <FormField
                control={form.control}
                name="details"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Details</FormLabel>
                    <FormControl>
                      <Textarea
                        placeholder="Describe the churn reasons in detail"
                        className="resize-none"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </form>
          </Form>
        </DialogBody>

        <DialogFooter>
          <Button type="submit" onClick={form.handleSubmit(handleSubmit)} disabled={loading}>
            {loading && <Loading />}
            Submit
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default ChurnModal