import React from 'react';
import { Button } from '@/components/ui/button';
import { HelpCircle } from 'lucide-react';
import { cn } from '@/lib/utils';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip"

interface ComingSoonProps {
  isVisible: boolean;
  isMaximized?: boolean;
  onOpen: () => void;
  onClose: () => void;
}

const ComingSoon: React.FC<ComingSoonProps> = ({ 
  isVisible, 
  isMaximized = false, 
  onOpen, 
  onClose 
}) => {
  if (!isMaximized) {
    return (
      <div className={cn(
        'opacity-0 pointer-events-none transition-all duration-300',
        isVisible && 'opacity-100 pointer-events-auto'
      )}>
        <TooltipProvider delayDuration={100}>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="ghost"
                size="icon"
                className={cn(
                  '!w-12 !h-12 !rounded-2xl', 
                  'bg-gray-300',
                  'hover:translate-y-[-2px] hover:scale-105 transition-all duration-300',
                  'shadow-lg hover:shadow-blue-500/20'
                )}
                disabled
              >
                <HelpCircle size={32} className="text-white transform scale-150 opacity-50" />
              </Button>
            </TooltipTrigger>
            <TooltipContent side="left">
              <p>Coming Soon</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
    );
  }

  // No maximized view yet
  return null;
};

export default ComingSoon;