import React, { createContext, useContext, useEffect, useState } from 'react';
import api from '@/api/axiosConfig';
import { showNotificationToast } from '@/toasts/notification-toast';
import { useNavigate } from 'react-router-dom';

// Add event system for notifications modal
const SHOW_NOTIFICATIONS_EVENT = 'showNotificationsModal';
const POLLING_INTERVAL = 60000; // Poll every 60 seconds (1 minute)

export const openNotificationsModal = () => {
  window.dispatchEvent(new CustomEvent(SHOW_NOTIFICATIONS_EVENT));
};

interface Notification {
  id: number;
  title: string;
  message: string;
  type: string;
  data: Record<string, any>;
  navigation?: {
    route: string;
    params?: Record<string, string | number>;
  };
  is_read: boolean;
  is_archived: boolean;
  created_at: string;
}

interface NotificationContextType {
  notifications: Notification[];
  unreadCount: number;
  fetchNotifications: () => Promise<void>;
  markAsRead: (notificationIds: number[]) => Promise<void>;
  archiveNotifications: (notificationIds: number[]) => Promise<void>;
  openNotificationsDialog: () => void;
}

const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

export function NotificationProvider({ children }: { children: React.ReactNode }) {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [isNotificationsDialogOpen, setIsNotificationsDialogOpen] = useState(false);
  const navigate = useNavigate();

  const handleNavigation = (route: string, params?: Record<string, string | number>) => {
    // Special handling for custom board task routes
    if (route === '/boards' && params?.boardId && params?.taskId) {
      navigate(`/custom-boards/${params.boardId}/task/${params.taskId}`);
      return;
    }

    // Default handling for other routes
    if (params) {
      const queryParams = new URLSearchParams();
      Object.entries(params).forEach(([key, value]) => {
        queryParams.append(key, String(value));
      });
      navigate(`${route}?${queryParams.toString()}`);
    } else {
      navigate(route);
    }
  };

  const fetchNotifications = async () => {
    try {
      const [notificationsRes, unshownRes] = await Promise.all([
        api.get('/notifications/'),
        api.get('/notifications/unshown')
      ]);

      setNotifications(notificationsRes.data.notifications);
      setUnreadCount(notificationsRes.data.unread_count);

      // Handle unshown notifications for toasts
      const unshownNotifications = unshownRes.data;
      if (Array.isArray(unshownNotifications)) {
        unshownNotifications.forEach(notification => {
          if (notification.message || notification.navigation) {
            showNotificationToast({
              title: notification.title,
              message: notification.message,
              navigation: notification.navigation,
              onNavigate: handleNavigation
            });
          }
        });
      }
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  const markAsRead = async (notificationIds: number[]) => {
    try {
      await api.post('/notifications/mark-read', { notification_ids: notificationIds });
      await fetchNotifications(); // Refresh notifications
      window.dispatchEvent(new CustomEvent('notificationUpdate'));
    } catch (error) {
      console.error('Error marking notifications as read:', error);
    }
  };

  const archiveNotifications = async (notificationIds: number[]) => {
    try {
      await api.post('/notifications/archive', { notification_ids: notificationIds });
      await fetchNotifications(); // Refresh notifications
      window.dispatchEvent(new CustomEvent('notificationUpdate'));
    } catch (error) {
      console.error('Error archiving notifications:', error);
    }
  };

  useEffect(() => {
    // Initial fetch
    fetchNotifications();

    // Set up polling with a reasonable interval
    const interval = setInterval(fetchNotifications, POLLING_INTERVAL);

    // Add listener for notifications modal
    const handleShowNotifications = () => setIsNotificationsDialogOpen(true);
    window.addEventListener(SHOW_NOTIFICATIONS_EVENT, handleShowNotifications);

    return () => {
      clearInterval(interval);
      window.removeEventListener(SHOW_NOTIFICATIONS_EVENT, handleShowNotifications);
    };
  }, []);

  const value = {
    notifications,
    unreadCount,
    fetchNotifications,
    markAsRead,
    archiveNotifications,
    openNotificationsDialog: () => setIsNotificationsDialogOpen(true)
  };

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  );
}

export const useNotifications = () => {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error('useNotifications must be used within a NotificationProvider');
  }
  return context;
}; 