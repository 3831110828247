import { Building2, MapPin } from 'lucide-react'

import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerDescription,
} from "@/components/ui/drawer"
import { Button } from "@/components/ui/button"
import { ScrollArea } from "@/components/ui/scroll-area"
import { Badge } from "@/components/ui/badge"

import type { Account } from '@/types/customers'

interface AccountDrawerProps {
  visible: boolean
  onClose: () => void
  account: Account | null
}

export function AccountDrawer({ visible, onClose, account }: AccountDrawerProps) {
  return (
    <Drawer open={visible} onOpenChange={onClose}>
      <DrawerContent className="h-[50vh]">
        <DrawerHeader className="flex flex-row items-center justify-between space-x-4 pb-4">
          <div className="flex-shrink-0">
            <DrawerTitle className="text-primary">Account Details</DrawerTitle>
            <DrawerDescription>View account information</DrawerDescription>
          </div>
          <div className="flex gap-2 flex-shrink-0">
            <Button variant="outline" onClick={onClose}>
              Close
            </Button>
          </div>
        </DrawerHeader>
        
        <div className="px-4">
          <div className="flex flex-col bg-card rounded-lg shadow-sm">
            <div className="flex items-center gap-2 p-3 border-b bg-muted/30 rounded-t-lg">
              <Building2 className="h-5 w-5 text-primary" />
              <h3 className="font-semibold text-card-foreground">
                Account Information
              </h3>
            </div>
            <ScrollArea className="h-[calc(60vh-180px)]">
              <div className="p-4">
                <div className="mb-4">
                  <h3 className="text-lg font-semibold">{account?.company_name}</h3>
                  <Badge variant="outline" className="mt-2">
                    CVR: {account?.cvr || 'N/A'}
                  </Badge>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div className="space-y-2 p-2 rounded-md hover:bg-muted/40 transition-colors">
                    <div className="text-sm font-medium text-muted-foreground">Address</div>
                    <div className="flex items-center gap-2">
                      <MapPin className="h-4 w-4 text-muted-foreground" />
                      <span>{account?.address || 'N/A'}</span>
                    </div>
                  </div>

                  <div className="space-y-2 p-2 rounded-md hover:bg-muted/40 transition-colors">
                    <div className="text-sm font-medium text-muted-foreground">Location</div>
                    <div className="flex items-center gap-2">
                      <MapPin className="h-4 w-4 text-muted-foreground" />
                      <span>{`${account?.zipcode || 'N/A'} ${account?.city || 'N/A'}`}</span>
                    </div>
                  </div>
                </div>
              </div>
            </ScrollArea>
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  )
}

export default AccountDrawer