import React from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import * as z from 'zod'
import { format } from 'date-fns'
import { Calendar, UserPlus, Plus } from 'lucide-react'
import { toast } from 'sonner'
import axios from '@/api/axiosConfig'

import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogBody,
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import { Textarea } from '@/components/ui/textarea'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { cn } from '@/lib/utils'
import { Calendar as CalendarComponent } from '@/components/ui/calendar'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { DealSelector } from '../components/DealSelector'
import { AssigneeSelection } from '@/modals/AssigneeSelection'

import { StoredUser } from '@/types/user'
import { Board } from '@/types/custom-board'
import { CustomTask } from '@/types/custom-task'
import { useUsers } from '@/contexts/UserContext'

interface CreateTaskModalProps {
  open: boolean
  onClose: () => void
  onSubmit: (values: Partial<CustomTask> | Partial<CustomTask>[]) => void
  board: Board | undefined
}

const formSchema = z.object({
  title: z.string().min(1, 'Please enter a title'),
  description: z.string().optional(),
  work_date: z.date().optional(),
  due_date: z.date().optional(),
  deal_id: z.number().optional(),
  assigned_users: z.array(z.string()).optional(),
  board_status_id: z.number().optional(),
  progress_status_id: z.number().optional(),
})

type FormValues = z.infer<typeof formSchema>

interface TaskFormattedValues {
  title: string;
  description?: string;
  work_date?: string;
  due_date?: string;
  deal_id?: number;
  deal_name?: string;
  assigned_users?: string[];
  progress_status_id: number;
  board_status_id?: number;
}

interface Deal {
  id: number;
  name: string;
  cvr?: string;
  has_tasks?: boolean;
}

const CreateTaskModal: React.FC<CreateTaskModalProps> = ({
  open,
  onClose,
  onSubmit: handleSubmit,
  board,
}) => {
  const { users, currentUser } = useUsers();
  const [showAssigneeModal, setShowAssigneeModal] = React.useState(false)
  const [hideDealsWithTasks, setHideDealsWithTasks] = React.useState(true)
  const hasBoardStatus = Boolean(
    board?.board_config?.board_status_options && 
    board.board_config.board_status_options.length > 0
  )
  const hasProgressStatus = Boolean(
    board?.board_config?.progress_status_options && 
    board.board_config.progress_status_options.length > 0
  )
  const isSimplifiedMainTasks = board?.board_config?.simplify_main_tasks || false;
  const requiresDeal = board?.board_config?.requires_deal || false;
  const showCreateAll = isSimplifiedMainTasks && requiresDeal;
  const showStatusOptions = isSimplifiedMainTasks && hasBoardStatus && hasProgressStatus;

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: isSimplifiedMainTasks ? 'Main Task' : '',
      description: '',
      assigned_users: [],
      progress_status_id: board?.board_config?.progress_status_options?.[0]?.id ?? 1,
      board_status_id: board?.board_config?.board_status_options?.[0]?.id
    },
  })

  const handleAssigneeSelection = (assignees: string[]) => {
    form.setValue('assigned_users', assignees)
  }

  const handleAddSelf = () => {
    const currentAssignees = form.getValues('assigned_users') || [];
    if (currentUser && !currentAssignees.includes(currentUser.user_id.toString())) {
      const newAssignees = [...currentAssignees, currentUser.user_id.toString()];
      form.setValue('assigned_users', newAssignees);
    }
  }

  const submitForm = async (values: FormValues) => {
    try {
      if (!values.deal_id && (board?.board_config?.requires_deal || isSimplifiedMainTasks)) {
        toast.error('Please select a deal');
        return;
      }

      // Ensure we have a valid progress_status_id
      const defaultProgressStatusId = board?.board_config?.progress_status_options?.[0]?.id ?? 1;
      const progressStatusId = values.progress_status_id ?? defaultProgressStatusId;

      const formattedValues: TaskFormattedValues = {
        title: isSimplifiedMainTasks ? 'Main Task' : values.title,
        description: values.description,
        work_date: values.work_date ? format(values.work_date, 'yyyy-MM-dd') : undefined,
        due_date: values.due_date ? format(values.due_date, 'yyyy-MM-dd') : undefined,
        deal_id: values.deal_id,
        assigned_users: values.assigned_users,
        progress_status_id: progressStatusId,
        board_status_id: values.board_status_id
      }

      await handleSubmit(formattedValues);
      form.reset();
      onClose();
    } catch (error) {
      console.error('Error creating task:', error);
      toast.error('Failed to create task');
    }
  }

  const handleCreateAll = async () => {
    try {
      // Get all available deals based on the hideDealsWithTasks filter
      const response = await axios.get('/custom-board/deals/search', {
        params: { 
          board_id: board?.id,
          hide_with_tasks: hideDealsWithTasks,
          limit: 1000
        }
      });
      const deals = response.data;

      if (deals.length === 0) {
        toast.error('No deals available for task creation');
        return;
      }

      // Get form values to use for all tasks
      const formValues = form.getValues();
      const defaultProgressStatusId = board?.board_config?.progress_status_options?.[0]?.id ?? 1;
      const defaultBoardStatusId = board?.board_config?.board_status_options?.[0]?.id;
      const isProgressKanban = board?.board_config?.kanban_source === 'progress';

      // Create a task for each deal
      const tasks = deals.map((deal: Deal) => ({
        title: isSimplifiedMainTasks ? 'Main Task' : formValues.title,
        description: formValues.description,
        deal_id: deal.id,
        work_date: formValues.work_date ? format(formValues.work_date, 'yyyy-MM-dd') : undefined,
        due_date: formValues.due_date ? format(formValues.due_date, 'yyyy-MM-dd') : undefined,
        assigned_users: formValues.assigned_users,
        progress_status_id: formValues.progress_status_id || defaultProgressStatusId,
        board_status_id: formValues.board_status_id || defaultBoardStatusId
      }));

      await handleSubmit(tasks);
      onClose();
    } catch (error) {
      console.error('Error creating tasks:', error);
      toast.error('Failed to create tasks');
    }
  }

  return (
    <>
      <Dialog open={open} onOpenChange={onClose}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Create Task</DialogTitle>
            <DialogDescription className="sr-only">
              Create a new task in the custom taskboard
            </DialogDescription>
          </DialogHeader>

          <DialogBody maxBodyHeight>
            <Form {...form}>
              <form id="create-task-form" onSubmit={form.handleSubmit(submitForm)} className="space-y-4">
                {!isSimplifiedMainTasks && (
                  <>
                    <FormField
                      control={form.control}
                      name="title"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Title</FormLabel>
                          <FormControl>
                            <Input {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="description"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Description</FormLabel>
                          <FormControl>
                            <Textarea {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="progress_status_id"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Progress Status</FormLabel>
                          <Select onValueChange={(value) => field.onChange(Number(value))} defaultValue={field.value?.toString()}>
                            <FormControl>
                              <SelectTrigger>
                                <SelectValue placeholder="Select progress status" />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              {board?.board_config?.progress_status_options?.map((status) => (
                                <SelectItem key={status.id} value={status.id.toString()}>
                                  <div className="flex items-center gap-2">
                                    <div 
                                      className="w-3 h-3 rounded-full" 
                                      style={{ backgroundColor: status.color }}
                                    />
                                    {status.label}
                                  </div>
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    {hasBoardStatus && (
                      <FormField
                        control={form.control}
                        name="board_status_id"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Board Status</FormLabel>
                            <Select onValueChange={(value) => field.onChange(Number(value))} defaultValue={field.value?.toString()}>
                              <FormControl>
                                <SelectTrigger>
                                  <SelectValue placeholder="Select board status" />
                                </SelectTrigger>
                              </FormControl>
                              <SelectContent>
                                {board?.board_config?.board_status_options?.map((status) => (
                                  <SelectItem key={status.id} value={status.id.toString()}>
                                    <div className="flex items-center gap-2">
                                      <div 
                                        className="w-3 h-3 rounded-full" 
                                        style={{ backgroundColor: status.color }}
                                      />
                                      {status.label}
                                    </div>
                                  </SelectItem>
                                ))}
                              </SelectContent>
                            </Select>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    )}
                  </>
                )}

                <FormField
                  control={form.control}
                  name="deal_id"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        Deal
                        {(board?.board_config?.requires_deal || isSimplifiedMainTasks) && (
                          <span className="text-destructive"> *</span>
                        )}
                      </FormLabel>
                      <FormControl>
                        <DealSelector
                          value={field.value}
                          onChange={field.onChange}
                          boardId={board?.id}
                          hideDealsWithTasks={hideDealsWithTasks}
                          onHideDealsWithTasksChange={setHideDealsWithTasks}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                {!isSimplifiedMainTasks && (
                  <>
                    <FormField
                      control={form.control}
                      name="work_date"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>
                            Work Date
                            {board?.board_config?.requires_work_date && (
                              <span className="text-destructive"> *</span>
                            )}
                          </FormLabel>
                          <Popover>
                            <PopoverTrigger asChild>
                              <FormControl>
                                <Button
                                  variant="outline"
                                  className={cn(
                                    'w-full pl-3 text-left font-normal',
                                    !field.value && 'text-muted-foreground'
                                  )}
                                >
                                  {field.value ? (
                                    format(field.value, 'PPP')
                                  ) : (
                                    <span>Pick a date</span>
                                  )}
                                  <Calendar className="ml-auto h-4 w-4 opacity-50" />
                                </Button>
                              </FormControl>
                            </PopoverTrigger>
                            <PopoverContent className="w-auto p-0" align="start">
                              <CalendarComponent
                                mode="single"
                                selected={field.value}
                                onSelect={field.onChange}
                                initialFocus
                              />
                            </PopoverContent>
                          </Popover>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="due_date"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>
                            Due Date
                            {board?.board_config?.requires_due_date && (
                              <span className="text-destructive"> *</span>
                            )}
                          </FormLabel>
                          <Popover>
                            <PopoverTrigger asChild>
                              <FormControl>
                                <Button
                                  variant="outline"
                                  className={cn(
                                    'w-full pl-3 text-left font-normal',
                                    !field.value && 'text-muted-foreground'
                                  )}
                                >
                                  {field.value ? (
                                    format(field.value, 'PPP')
                                  ) : (
                                    <span>Pick a date</span>
                                  )}
                                  <Calendar className="ml-auto h-4 w-4 opacity-50" />
                                </Button>
                              </FormControl>
                            </PopoverTrigger>
                            <PopoverContent className="w-auto p-0" align="start">
                              <CalendarComponent
                                mode="single"
                                selected={field.value}
                                onSelect={field.onChange}
                                initialFocus
                              />
                            </PopoverContent>
                          </Popover>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="assigned_users"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Assignees</FormLabel>
                          <div className="flex gap-2">
                            <Button
                              type="button"
                              variant="outline"
                              className="w-full"
                              onClick={() => setShowAssigneeModal(true)}
                            >
                              Select Assignees ({field.value?.length || 0})
                            </Button>
                            <Button
                              type="button"
                              variant="outline"
                              size="icon"
                              onClick={handleAddSelf}
                            >
                              <UserPlus className="h-4 w-4" />
                            </Button>
                          </div>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </>
                )}

                {showStatusOptions && (
                  <>
                    <FormField
                      control={form.control}
                      name="progress_status_id"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Progress Status</FormLabel>
                          <Select onValueChange={(value) => field.onChange(Number(value))} defaultValue={field.value?.toString()}>
                            <FormControl>
                              <SelectTrigger>
                                <SelectValue placeholder="Select progress status" />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              {board?.board_config?.progress_status_options?.map((status) => (
                                <SelectItem key={status.id} value={status.id.toString()}>
                                  <div className="flex items-center gap-2">
                                    <div 
                                      className="w-3 h-3 rounded-full" 
                                      style={{ backgroundColor: status.color }}
                                    />
                                    {status.label}
                                  </div>
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    {hasBoardStatus && (
                      <FormField
                        control={form.control}
                        name="board_status_id"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Board Status</FormLabel>
                            <Select onValueChange={(value) => field.onChange(Number(value))} defaultValue={field.value?.toString()}>
                              <FormControl>
                                <SelectTrigger>
                                  <SelectValue placeholder="Select board status" />
                                </SelectTrigger>
                              </FormControl>
                              <SelectContent>
                                {board?.board_config?.board_status_options?.map((status) => (
                                  <SelectItem key={status.id} value={status.id.toString()}>
                                    <div className="flex items-center gap-2">
                                      <div 
                                        className="w-3 h-3 rounded-full" 
                                        style={{ backgroundColor: status.color }}
                                      />
                                      {status.label}
                                    </div>
                                  </SelectItem>
                                ))}
                              </SelectContent>
                            </Select>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    )}
                  </>
                )}
              </form>
            </Form>
          </DialogBody>

          <DialogFooter className="flex justify-between">
            <div className="flex gap-2">
              <Button variant="outline" onClick={onClose}>
                Cancel
              </Button>
              <Button
                type="button"
                variant="outline"
                onClick={handleCreateAll}
                className="flex items-center gap-2"
              >
                <Plus className="h-4 w-4" />
                Create for all deals
              </Button>
            </div>
            <Button type="submit" form="create-task-form">Create</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <AssigneeSelection
        visible={showAssigneeModal}
        onClose={() => setShowAssigneeModal(false)}
        onSave={handleAssigneeSelection}
        initialAssignees={form.getValues('assigned_users') || []}
      />
    </>
  )
}

export default CreateTaskModal