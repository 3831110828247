import React from 'react';
import { Tooltip, TooltipContent, TooltipTrigger, TooltipProvider } from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";
import type { CustomTask } from '@/types/custom-task';
import type { BoardStatusOption } from '@/types/custom-board';
import type { Board } from '@/types/custom-board';

interface TaskDistributionBarProps {
  tasks: CustomTask[];
  statusOptions: BoardStatusOption[];
  className?: string;
  board?: Board | null;
  isKanbanReversed?: boolean;
}

export const TaskDistributionBar: React.FC<TaskDistributionBarProps> = ({
  tasks,
  statusOptions,
  className,
  board,
  isKanbanReversed = false
}) => {
  // Get the correct status options based on kanban source and reversed state
  const getActiveStatusOptions = (): BoardStatusOption[] => {
    if (!board?.board_config) return statusOptions;

    const { kanban_source, progress_status_options, board_status_options } = board.board_config;
    
    // When reversed, use the opposite of what's configured as the source
    if (isKanbanReversed) {
      return kanban_source === 'progress' 
        ? (board_status_options || [])
        : (progress_status_options || []);
    }
    
    // Use the configured source
    return kanban_source === 'progress'
      ? (progress_status_options || [])
      : (board_status_options || []);
  };

  // Get the correct status ID based on kanban source and reversed state
  const getTaskStatusId = (task: CustomTask): number => {
    if (!board?.board_config) return task.progress_status_id;

    const isProgressKanban = isKanbanReversed
      ? board.board_config.kanban_source !== 'progress'
      : board.board_config.kanban_source === 'progress';

    return isProgressKanban ? task.progress_status_id : task.board_status_id;
  };

  const activeStatusOptions = getActiveStatusOptions();

  // Calculate total tasks and tasks per status using the correct status ID
  const totalTasks = tasks.length;
  const tasksByStatus = activeStatusOptions.reduce((acc, status) => {
    acc[status.id] = tasks.filter(task => getTaskStatusId(task) === status.id).length;
    return acc;
  }, {} as Record<number, number>);

  // Get visible status options (those with count > 0)
  const visibleStatuses = activeStatusOptions.filter(status => tasksByStatus[status.id] > 0);

  return (
    <TooltipProvider>
      {/* Outer container with padding to allow for hover effects */}
      <div className={cn(
        "w-40 relative",
        "py-2", // Padding to accommodate hover effect
        className
      )}>
        {/* Inner container that holds the actual bars */}
        <div className="w-full h-8 rounded-md overflow-visible flex border border-border relative">
          {activeStatusOptions.map((status, index) => {
            const count = tasksByStatus[status.id] || 0;
            const width = totalTasks > 0 ? (count / totalTasks) * 100 : 0;
            
            // Only render if there are tasks in this status
            if (width === 0) return null;

            // Find the index in visible statuses for proper corner rounding
            const visibleIndex = visibleStatuses.findIndex(s => s.id === status.id);
            const isFirst = visibleIndex === 0;
            const isLast = visibleIndex === visibleStatuses.length - 1;
            
            return (
              <Tooltip key={status.id} delayDuration={0}>
                <TooltipTrigger asChild>
                  <div
                    className={cn(
                      "h-full transition-all duration-200",
                      "hover:opacity-80 hover:-translate-y-1 hover:scale-110",
                      "hover:shadow-md hover:z-10",
                      "origin-bottom",
                      // Apply rounded corners based on position
                      isFirst && "rounded-l-md",
                      isLast && "rounded-r-md"
                    )}
                    style={{
                      width: `${width}%`,
                      backgroundColor: status.color,
                      minWidth: count > 0 ? '4px' : '0',
                      transitionProperty: 'transform, opacity, box-shadow',
                    }}
                  />
                </TooltipTrigger>
                <TooltipContent>
                  <p>{`${status.label}: ${count}`}</p>
                </TooltipContent>
              </Tooltip>
            );
          })}
        </div>
      </div>
    </TooltipProvider>
  );
}; 