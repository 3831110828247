/**
 * Determines if a color is light or dark based on its luminance.
 * @param color - The hex color code (e.g., '#FFFFFF')
 * @returns true if the color is light, false if dark
 */
export const isLightColor = (color: string): boolean => {
  // Convert hex to RGB
  const hex = color.replace('#', '');
  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);
  
  // Calculate relative luminance
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  return luminance > 0.7;
};

/**
 * Gets the appropriate text color (black or white) for a given background color.
 * @param backgroundColor - The hex color code of the background
 * @returns '#000000' for light backgrounds, '#ffffff' for dark backgrounds
 */
export const getTextColor = (backgroundColor: string | undefined): string => {
  if (!backgroundColor || !backgroundColor.startsWith('#')) return '';
  return isLightColor(backgroundColor) ? '#000000' : '#ffffff';
}; 