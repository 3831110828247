import { useState, useEffect } from 'react'
import { toast } from 'sonner'
import axios from '@/api/axiosConfig'
import { Edit, Trash2, Plus, Lock, Unlock, Mail, Phone, User, Building2, FileText, Globe2, Check, Edit2, Save, X, Copy } from 'lucide-react'
import { cn } from '@/lib/utils'

import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerDescription,
} from "@/components/ui/drawer"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Textarea } from "@/components/ui/textarea"
import { Switch } from "@/components/ui/switch"
import { Badge } from "@/components/ui/badge"
import { ScrollArea } from "@/components/ui/scroll-area"
import { Separator } from "@/components/ui/separator"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog"

import UserAvatar from '@/components/user-avatar'
import type { Contact } from '@/types/customers'

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"

import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command"

interface ContactInfoEntry {
  id: number
  text: string
  user_id: number
  is_public: boolean
}

interface User {
  user_id: string | number
  name?: string
  surname?: string
  avatar_urls?: {
    medium?: string
    small?: string
  }
  settings?: {
    avatar_color?: string
  }
  status?: string
  initials?: string
}

interface Language {
  code: string
  name: string
}

interface ContactDrawerProps {
  visible: boolean
  onClose: () => void
  contact: Contact | null
  onContactUpdate?: () => void
}

export function ContactDrawer({ visible, onClose, contact, onContactUpdate }: ContactDrawerProps) {
  const [entries, setEntries] = useState<ContactInfoEntry[]>([])
  const [newEntry, setNewEntry] = useState({ text: '', isPublic: true })
  const [editingIndex, setEditingIndex] = useState(-1)
  const [users, setUsers] = useState<Record<number, User>>({})
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false)
  const [entryToDelete, setEntryToDelete] = useState<number | null>(null)
  const [languages, setLanguages] = useState<Language[]>([])
  const [selectedLanguage, setSelectedLanguage] = useState<string>('')
  const [languagePopoverOpen, setLanguagePopoverOpen] = useState(false)
  const [isEditingContact, setIsEditingContact] = useState(false)
  const [editedContact, setEditedContact] = useState<{
    email: string;
    phone: string;
    cpr: string;
  }>({
    email: '',
    phone: '',
    cpr: ''
  })

  useEffect(() => {
    if (contact) {
      setEditedContact({
        email: contact.email || '',
        phone: contact.phone || '',
        cpr: contact.cpr || ''
      })
      setSelectedLanguage('')
    }
  }, [contact])

  useEffect(() => {
    if (visible && contact?.id) {
      fetchContactInfo()
      fetchLanguages()
    }
  }, [visible, contact?.id])

  const fetchContactInfo = async () => {
    try {
      const response = await axios.get(`/account/contacts/${contact?.id}/info`)
      setEntries(response.data.entries)
      if (response.data.entries.length > 0) {
        fetchUsers(response.data.entries.map((entry: ContactInfoEntry) => entry.user_id))
      }
    } catch (error) {
      console.error('Error fetching contact info:', error)
    }
  }

  const fetchLanguages = async () => {
    try {
      // First fetch available languages
      const allLangsResponse = await axios.get('/contact/languages')
      setLanguages(allLangsResponse.data)

      // Then fetch contact's languages
      if (contact?.id) {
        const contactLangsResponse = await axios.get(`/contact/${contact.id}/languages`)
        if (contactLangsResponse.data && contactLangsResponse.data.length > 0) {
          setSelectedLanguage(contactLangsResponse.data[0].code)
        } else {
          setSelectedLanguage('')
        }
      }
    } catch (error) {
      console.error('Error fetching languages:', error)
      toast.error('Failed to fetch languages')
    }
  }

  const fetchUsers = async (userIds: number[]) => {
    try {
      const response = await axios.get('/user/names-and-avatars', {
        params: { user_ids: userIds }
      })
      setUsers(response.data)
    } catch (error) {
      console.error('Error fetching user data:', error)
    }
  }

  const handleLanguageChange = async (code: string) => {
    try {
      await axios.put(`/contact/${contact?.id}/languages`, {
        language_codes: [code]
      })
      setSelectedLanguage(code)
      toast.success('Language updated successfully')
    } catch (error) {
      console.error('Error updating language:', error)
      toast.error('Failed to update language')
    }
  }

  const resetStates = () => {
    setNewEntry({ text: '', isPublic: true })
    setEditingIndex(-1)
  }

  const getUserInfo = (userId: number) => {
    return users[userId] || {}
  }

  const handleAddEntry = async () => {
    try {
      const response = await axios.post(`/account/contacts/${contact?.id}/info`, {
        text: newEntry.text,
        is_public: newEntry.isPublic,
      })
      toast.success('Contact info added successfully')
      setNewEntry({ text: '', isPublic: true })
      setEntries(response.data.entries)
    } catch (error) {
      console.error('Error adding contact info:', error)
      toast.error('Failed to add contact info')
    }
  }

  const handleUpdateEntry = async (entry: ContactInfoEntry) => {
    try {
      const response = await axios.put(`/account/contacts/${contact?.id}/info/${entry.id}`, {
        text: entry.text,
        is_public: entry.is_public,
      })
      toast.success('Contact info updated successfully')
      setEntries(response.data.entries)
      setEditingIndex(-1)
    } catch (error) {
      console.error('Error updating contact info:', error)
      toast.error('Failed to update contact info')
    }
  }

  const handleDeleteEntry = async (entryId: number) => {
    try {
      await axios.delete(`/account/contacts/${contact?.id}/info/${entryId}`)
      toast.success('Contact info deleted successfully')
      const response = await axios.get(`/account/contacts/${contact?.id}/info`)
      setEntries(response.data.entries)
    } catch (error) {
      console.error('Error deleting contact info:', error)
      toast.error('Failed to delete contact info')
    }
  }

  const handleClose = () => {
    resetStates()
    onClose()
  }

  const handleUpdateContact = async () => {
    if (!contact?.id) {
      console.error('No contact id available')
      return
    }

    try {
      console.log('Updating contact:', contact.id, editedContact)
      const response = await axios.patch(`/contact/${contact.id}`, {
        email: editedContact.email,
        phone: editedContact.phone,
        cpr: editedContact.cpr
      })
      
      if (response.data) {
        if (contact) {
          contact.email = editedContact.email
          contact.phone = editedContact.phone
          contact.cpr = editedContact.cpr
        }
        
        if (onContactUpdate) {
          onContactUpdate()
        }
        
        toast.success('Contact information updated successfully')
        setIsEditingContact(false)
      }
    } catch (error) {
      console.error('Error updating contact:', error)
      toast.error('Failed to update contact information')
    }
  }

  return (
    <>
      <Drawer open={visible} onOpenChange={handleClose}>
        <DrawerContent className="h-[85vh]">
          <DrawerHeader className="flex flex-row items-center justify-between space-x-4 pb-6">
            <div className="flex-shrink-0">
              <DrawerTitle className="text-primary">Contact Details</DrawerTitle>
              <DrawerDescription>View contact information and manage notes</DrawerDescription>
            </div>
            <div className="flex gap-2 flex-shrink-0">
              <Button variant="outline" onClick={handleClose}>
                Close
              </Button>
            </div>
          </DrawerHeader>
          
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 px-4">
            {/* Contact Info Card */}
            <div className="flex flex-col bg-card rounded-lg shadow-sm">
              <div className="flex items-center justify-between p-3 border-b bg-muted/30 rounded-t-lg">
                <div className="flex items-center gap-2">
                  <User className="h-5 w-5 text-primary" />
                  <h3 className="font-semibold text-card-foreground">
                    Contact Information
                  </h3>
                </div>
                {!isEditingContact ? (
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => setIsEditingContact(true)}
                  >
                    <Edit2 className="h-4 w-4" />
                  </Button>
                ) : (
                  <div className="flex gap-2">
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={() => {
                        setIsEditingContact(false)
                        setEditedContact({
                          email: contact?.email || '',
                          phone: contact?.phone || '',
                          cpr: contact?.cpr || ''
                        })
                      }}
                    >
                      <X className="h-4 w-4" />
                    </Button>
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={handleUpdateContact}
                    >
                      <Save className="h-4 w-4" />
                    </Button>
                  </div>
                )}
              </div>
              <ScrollArea className="h-[calc(85vh-180px)]">
                <div className="p-4 space-y-4">
                  <div className="flex items-center gap-4">
                    <UserAvatar 
                      user={{
                        name: contact?.name || '',
                        user_id: contact?.id || 0
                      }}
                      size={64}
                    />
                    <div>
                      <h3 className="text-lg font-semibold">{contact?.name}</h3>
                    </div>
                  </div>

                  <div className="space-y-4">
                    <div className="space-y-2 p-2 rounded-md hover:bg-muted/40 transition-colors">
                      <div className="text-sm font-medium text-muted-foreground">Email</div>
                      <div className="flex items-center gap-2">
                        <Mail className="h-4 w-4 text-muted-foreground" />
                        {isEditingContact ? (
                          <Input
                            value={editedContact.email}
                            onChange={(e) => setEditedContact(prev => ({ ...prev, email: e.target.value }))}
                            className="h-8"
                          />
                        ) : (
                          <div className="flex items-center gap-2 flex-1">
                            <a href={`mailto:${contact?.email}`} className="hover:underline">
                              {contact?.email || 'N/A'}
                            </a>
                            {contact?.email && (
                              <Button
                                variant="ghost"
                                size="icon"
                                className="h-6 w-6 opacity-50 hover:opacity-100"
                                onClick={() => {
                                  if (contact.email) {
                                    navigator.clipboard.writeText(contact.email)
                                    toast.success('Email copied to clipboard')
                                  }
                                }}
                              >
                                <Copy className="h-3 w-3" />
                              </Button>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="space-y-2 p-2 rounded-md hover:bg-muted/40 transition-colors">
                      <div className="text-sm font-medium text-muted-foreground">Phone</div>
                      <div className="flex items-center gap-2">
                        <Phone className="h-4 w-4 text-muted-foreground" />
                        {isEditingContact ? (
                          <Input
                            value={editedContact.phone}
                            onChange={(e) => setEditedContact(prev => ({ ...prev, phone: e.target.value }))}
                            className="h-8"
                          />
                        ) : (
                          <div className="flex items-center gap-2 flex-1">
                            <a href={`tel:${contact?.phone}`} className="hover:underline">
                              {contact?.phone || 'N/A'}
                            </a>
                            {contact?.phone && (
                              <Button
                                variant="ghost"
                                size="icon"
                                className="h-6 w-6 opacity-50 hover:opacity-100"
                                onClick={() => {
                                  if (contact.phone) {
                                    navigator.clipboard.writeText(contact.phone)
                                    toast.success('Phone number copied to clipboard')
                                  }
                                }}
                              >
                                <Copy className="h-3 w-3" />
                              </Button>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="space-y-2 p-2 rounded-md hover:bg-muted/40 transition-colors">
                      <div className="text-sm font-medium text-muted-foreground">CPR</div>
                      <div className="flex items-center gap-2">
                        <User className="h-4 w-4 text-muted-foreground" />
                        {isEditingContact ? (
                          <Input
                            value={editedContact.cpr}
                            onChange={(e) => setEditedContact(prev => ({ ...prev, cpr: e.target.value }))}
                            className="h-8"
                            placeholder="XXXXXX-XXXX"
                          />
                        ) : (
                          <span>{contact?.cpr || 'N/A'}</span>
                        )}
                      </div>
                    </div>
                    <div className="space-y-2 p-2 rounded-md hover:bg-muted/40 transition-colors">
                      <div className="text-sm font-medium text-muted-foreground">Language</div>
                      <div className="flex items-center gap-2">
                        <Globe2 className="h-4 w-4 text-muted-foreground" />
                        <Popover open={languagePopoverOpen} onOpenChange={setLanguagePopoverOpen}>
                          <PopoverTrigger asChild>
                            <Button
                              variant="outline"
                              role="combobox"
                              aria-expanded={languagePopoverOpen}
                              className="w-[200px] justify-between"
                            >
                              {selectedLanguage
                                ? languages.find((lang) => lang.code === selectedLanguage)?.name
                                : "Select language..."}
                            </Button>
                          </PopoverTrigger>
                          <PopoverContent className="w-[200px] p-0">
                            <Command className="max-h-[300px]">
                              <CommandInput placeholder="Search language..." />
                              <CommandEmpty>No language found.</CommandEmpty>
                              <CommandGroup>
                                {languages.map((lang) => (
                                  <CommandItem
                                    key={lang.code}
                                    value={`${lang.name.toLowerCase()} ${lang.code.toLowerCase()}`}
                                    onSelect={() => {
                                      handleLanguageChange(lang.code)
                                      setLanguagePopoverOpen(false)
                                    }}
                                  >
                                    <Check
                                      className={cn(
                                        "mr-2 h-4 w-4",
                                        selectedLanguage === lang.code ? "opacity-100" : "opacity-0"
                                      )}
                                    />
                                    {lang.name}
                                  </CommandItem>
                                ))}
                              </CommandGroup>
                            </Command>
                          </PopoverContent>
                        </Popover>
                      </div>
                    </div>
                  </div>
                </div>
              </ScrollArea>
            </div>

            {/* Notes Card */}
            <div className="flex flex-col bg-card rounded-lg shadow-sm lg:col-span-3">
              <div className="flex items-center gap-2 p-3 border-b bg-muted/30 rounded-t-lg">
                <FileText className="h-5 w-5 text-primary" />
                <h3 className="font-semibold text-card-foreground">
                  Notes
                </h3>
              </div>
              <ScrollArea className="h-[calc(85vh-180px)]">
                <div className="p-4 space-y-4">
                  {entries.map((entry, index) => {
                    const user = getUserInfo(entry.user_id)
                    return (
                      <div 
                        key={entry.id}
                        className="flex items-start gap-4 p-4 rounded-lg border bg-card"
                      >
                        <UserAvatar 
                          user={user}
                          size={32}
                          tooltipPlacement="right"
                        />
                        <div className="flex-1 space-y-2">
                          {editingIndex === index ? (
                            <div className="space-y-4">
                              <Textarea
                                value={entry.text}
                                onChange={(e) => {
                                  const updatedEntries = [...entries]
                                  updatedEntries[index].text = e.target.value
                                  setEntries(updatedEntries)
                                }}
                                className="min-h-[80px]"
                              />
                              <div className="flex items-center gap-4">
                                <div className="flex items-center gap-2">
                                  <Switch
                                    checked={entry.is_public}
                                    onCheckedChange={(checked) => {
                                      const updatedEntries = [...entries]
                                      updatedEntries[index].is_public = checked
                                      setEntries(updatedEntries)
                                    }}
                                  />
                                  <span className="text-sm">Public</span>
                                </div>
                                <Button onClick={() => handleUpdateEntry(entry)}>
                                  Save
                                </Button>
                              </div>
                            </div>
                          ) : (
                            <>
                              <p className="text-sm whitespace-pre-wrap">{entry.text}</p>
                              <Badge 
                                variant={entry.is_public ? "default" : "destructive"}
                                className="flex items-center gap-1"
                              >
                                {entry.is_public ? (
                                  <Unlock className="h-3 w-3" />
                                ) : (
                                  <Lock className="h-3 w-3" />
                                )}
                                {entry.is_public ? 'Public' : 'Private'}
                              </Badge>
                            </>
                          )}
                        </div>
                        <div className="flex gap-2">
                          <Button
                            variant="ghost"
                            size="icon"
                            onClick={() => setEditingIndex(index)}
                          >
                            <Edit className="h-4 w-4" />
                          </Button>
                          <Button
                            variant="ghost"
                            size="icon"
                            onClick={() => {
                              setEntryToDelete(entry.id)
                              setDeleteConfirmOpen(true)
                            }}
                          >
                            <Trash2 className="h-4 w-4" />
                          </Button>
                        </div>
                      </div>
                    )
                  })}

                  <Separator className="my-6" />

                  <div className="space-y-4">
                    <Input
                      placeholder="Add new note"
                      value={newEntry.text}
                      onChange={(e) => setNewEntry({ ...newEntry, text: e.target.value })}
                    />
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-4">
                        <div className="flex items-center gap-2">
                          <Switch
                            checked={newEntry.isPublic}
                            onCheckedChange={(checked) => setNewEntry({ ...newEntry, isPublic: checked })}
                          />
                          <span className="text-sm">Public</span>
                        </div>
                        <Badge 
                          variant={newEntry.isPublic ? "default" : "destructive"}
                          className="flex items-center gap-1"
                        >
                          {newEntry.isPublic ? (
                            <Unlock className="h-3 w-3" />
                          ) : (
                            <Lock className="h-3 w-3" />
                          )}
                          {newEntry.isPublic ? 'Public' : 'Private'}
                        </Badge>
                      </div>
                      <Button onClick={handleAddEntry} disabled={!newEntry.text.trim()}>
                        <Plus className="h-4 w-4 mr-2" />
                        Add Note
                      </Button>
                    </div>
                  </div>
                </div>
              </ScrollArea>
            </div>
          </div>
        </DrawerContent>
      </Drawer>

      <AlertDialog open={deleteConfirmOpen} onOpenChange={setDeleteConfirmOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Delete Note</AlertDialogTitle>
            <AlertDialogDescription>
              Are you sure you want to delete this note? This action cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={() => {
                if (entryToDelete !== null) {
                  handleDeleteEntry(entryToDelete)
                  setDeleteConfirmOpen(false)
                  setEntryToDelete(null)
                }
              }}
            >
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}

export default ContactDrawer
