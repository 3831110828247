import React, { useState, useEffect, useRef } from 'react';
import { Button } from '@/components/ui/button';
import { AppstoreOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import { DigiAI } from './DigiAI';
import { TimeTracker } from './TimeTracker';
import ComingSoon from './ComingSoon';
import { cn } from '@/lib/utils';

// Define types for overlays
type OverlayName = 'comingSoon' | 'timeTracker' | 'digiAI';

const OverlayHub: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [activeOverlay, setActiveOverlay] = useState<OverlayName | null>(null);
  const [isTimeTrackerRunning, setIsTimeTrackerRunning] = useState(false);
  const [isLiveTimerVisible, setIsLiveTimerVisible] = useState(false);
  const [displayAccount, setDisplayAccount] = useState('');
  const [displayTask, setDisplayTask] = useState('');
  const hideTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const location = useLocation();

  const hiddenPages = ['/', '/login', '/complete-registration', '/main-menu', '/digi-dashboard', '/bot', '/officebot', '/custom-gpts', '/admin'];
  const hiddenPaths = ['/invoice-overview', '/flows', '/customers/onboarding/flow'];

  const showOverlayHub = !hiddenPages.includes(location.pathname) && 
                         !hiddenPaths.some(path => location.pathname.startsWith(path));

  useEffect(() => {
    return () => {
      if (hideTimeoutRef.current) {
        clearTimeout(hideTimeoutRef.current);
      }
    };
  }, []);

  if (!showOverlayHub) return null;

  const showOverlays = () => {
    if (hideTimeoutRef.current) {
      clearTimeout(hideTimeoutRef.current);
    }
    setIsVisible(true);
  };

  const hideOverlays = () => {
    hideTimeoutRef.current = setTimeout(() => {
      if (!activeOverlay) {
        setIsVisible(false);
      }
    }, 300);
  };

  const handleOverlayOpen = (overlayName: OverlayName) => {
    setActiveOverlay(overlayName);
    setIsVisible(true);
  };

  const handleOverlayClose = () => {
    setActiveOverlay(null);
    if (!isTimeTrackerRunning) {
      setIsVisible(false);
    }
  };

  const handleTimeTrackerStatusChange = (isRunning: boolean, account: string, task: string) => {
    setIsTimeTrackerRunning(isRunning);
    setIsLiveTimerVisible(isRunning);
    setDisplayAccount(account);
    setDisplayTask(task);
    if (isRunning) {
      setActiveOverlay(null);
    }
  };

  return (
    <div className="fixed bottom-6 right-6 z-50">
      <div 
        className="relative"
        onMouseEnter={showOverlays}
        onMouseLeave={hideOverlays}
      >
        <Button
          variant="ghost"
          size="icon"
          className={cn(
            'w-14 h-14 p-0 flex justify-center items-center',
            'rounded-full overflow-hidden',
            'bg-background hover:bg-accent',
            'shadow-lg hover:shadow-accent/20',
            'hover:-translate-y-0.5 hover:scale-105',
            'transition-all duration-300',
            'border-0'
          )}
        >
          <AppstoreOutlined className="text-xl text-foreground" />
        </Button>

        <div 
          className={`absolute w-[400px] h-[400px] -left-[128px] -bottom-[16px] ${
            isVisible ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
        >
          <div className="absolute left-[55px] bottom-[20px]">
            <ComingSoon 
              isVisible={isVisible} 
              onOpen={() => handleOverlayOpen('comingSoon')} 
              isMaximized={false}
              onClose={handleOverlayClose}
            />
          </div>

          <div className="absolute -left-[-70px] bottom-[80px]">
            <TimeTracker 
              isVisible={isVisible} 
              onOpen={() => handleOverlayOpen('timeTracker')} 
              onStatusChange={handleTimeTrackerStatusChange}
              isRunning={isTimeTrackerRunning}
              isLiveTimerVisible={isLiveTimerVisible}
              isMaximized={false}
              onClose={handleOverlayClose}
            />
          </div>

          <div className="absolute -left-[-130px] bottom-[95px]">
            <DigiAI 
              isVisible={isVisible} 
              onOpen={() => handleOverlayOpen('digiAI')} 
              isMaximized={false}
              onClose={handleOverlayClose}
            />
          </div>
        </div>
      </div>

      {activeOverlay === 'digiAI' && (
        <DigiAI
          isVisible={true}
          isMaximized={true}
          onClose={handleOverlayClose}
          onOpen={() => {}}
        />
      )}

      {activeOverlay === 'timeTracker' && (
        <TimeTracker
          isVisible={true}
          isMaximized={true}
          onClose={handleOverlayClose}
          onStatusChange={handleTimeTrackerStatusChange}
          isRunning={isTimeTrackerRunning}
          isLiveTimerVisible={isLiveTimerVisible}
          onOpen={() => {}}
          displayAccountProp={displayAccount}
          displayTaskProp={displayTask}
        />
      )}
    </div>
  );
};

export default OverlayHub;