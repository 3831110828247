import React, { useState, useEffect, useCallback } from 'react';
import { Loader2, AlertCircle, CheckCircle2, AlertTriangle, X, Plus } from "lucide-react";
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import axios from '@/api/axiosConfig';
import { cn } from "@/lib/utils";

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Checkbox } from "@/components/ui/checkbox";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerFooter,
  DrawerDescription,
} from "@/components/ui/drawer";
import { Textarea } from "@/components/ui/textarea";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import type { CustomTask } from '@/types/custom-task';
import type { CompletionDrawerSection } from '@/types/custom-board';

dayjs.extend(duration);

const TIME_REASONS = [
  { value: 'complex_case', label: 'Complex Case' },
  { value: 'technical_issues', label: 'Technical Issues' },
  { value: 'client_communication', label: 'Extra Client Communication' },
  { value: 'data_quality', label: 'Poor Data Quality' },
  { value: 'training', label: 'Training/Learning' },
  { value: 'documentation', label: 'Extra Documentation Required' },
] as const;

type FormValues = Record<string, any>;

interface TaskCompleteDrawerProps {
  visible: boolean;
  onClose: () => void;
  task: CustomTask | null;
  onComplete: (values: any) => void;
  sections: CompletionDrawerSection[];
  title?: string;
  description?: string;
}

interface TimeSession {
  id: string;
  start_time: string;
  duration: number;
  pauses: {
    start: string;
    end?: string;
    duration?: number;
  }[];
}

interface TimeHistory {
  tracking_history: TimeSession[];
  total_duration: number;
}

export function TaskCompleteDrawer({ 
  visible, 
  onClose, 
  task, 
  onComplete,
  sections,
  title = "Complete Task",
  description = "Complete the task details below"
}: TaskCompleteDrawerProps) {
  const [timeHistory, setTimeHistory] = useState<TimeHistory | null>(null);
  const [loading, setLoading] = useState(false);
  const [showTimeComment, setShowTimeComment] = useState(false);

  // Dynamically build form schema based on sections
  const formSchema = z.object(
    sections.reduce((acc, section) => {
      switch (section.type) {
        case 'time_tracking':
          acc[section.id] = z.object({
            timeSpent: z.number().min(0),
            timeComment: z.string().optional(),
            timeReason: z.string().optional(),
          });
          break;
        case 'number_input':
          acc[section.id] = z.number()
            .min(section.config?.min || 0)
            .max(section.config?.max || Infinity);
          break;
        case 'text_input':
          acc[section.id] = section.required ? z.string().min(1) : z.string().optional();
          break;
        case 'checkbox':
          acc[section.id] = z.boolean();
          break;
        case 'select':
          acc[section.id] = section.required ? z.string().min(1) : z.string().optional();
          break;
        case 'comment':
          acc[section.id] = section.required ? z.string().min(1) : z.string().optional();
          break;
      }
      return acc;
    }, {} as Record<string, z.ZodType>)
  );

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: sections.reduce((acc, section) => {
      switch (section.type) {
        case 'time_tracking':
          acc[section.id] = {
            timeSpent: 0,
            timeComment: '',
            timeReason: undefined,
          };
          break;
        case 'number_input':
          acc[section.id] = section.config?.defaultValue || 0;
          break;
        case 'checkbox':
          acc[section.id] = section.config?.defaultValue || false;
          break;
        case 'select':
          acc[section.id] = section.config?.defaultValue || '';
          break;
        default:
          acc[section.id] = section.config?.defaultValue || '';
      }
      return acc;
    }, {} as FormValues)
  });

  const fetchTimeHistory = useCallback(async () => {
    if (!task) return;
    
    const timeSection = sections.find(s => s.type === 'time_tracking');
    if (!timeSection?.config?.showHistory) return;
    
    setLoading(true);
    try {
      const response = await axios.get(`/timetracker/task-time-history/${task.id}/${task.task_type}`);
      setTimeHistory(response.data);
      
      const totalMinutes = Math.round(response.data.total_duration / 60);
      form.setValue(`${timeSection.id}.timeSpent`, totalMinutes);
    } catch (error) {
      console.error('Failed to fetch time history:', error);
    } finally {
      setLoading(false);
    }
  }, [task, form, sections]);

  // Reset function
  const resetDrawerState = useCallback(() => {
    form.reset();
    setTimeHistory(null);
    setLoading(false);
    setShowTimeComment(false);
  }, [form]);

  useEffect(() => {
    if (visible && task) {
      console.log('Resetting form with task:', {
        taskId: task.id,
        taskType: task.task_type,
        defaultValues: sections.reduce((acc, section) => {
          switch (section.type) {
            case 'time_tracking':
              acc[section.id] = {
                timeSpent: 0,
                timeComment: '',
                timeReason: undefined,
              };
              break;
            case 'number_input':
              acc[section.id] = section.config?.defaultValue || 0;
              break;
            case 'checkbox':
              acc[section.id] = false;
              break;
            default:
              acc[section.id] = '';
          }
          return acc;
        }, {} as Record<string, any>)
      });
      
      form.reset(sections.reduce((acc, section) => {
        switch (section.type) {
          case 'time_tracking':
            acc[section.id] = {
              timeSpent: 0,
              timeComment: '',
              timeReason: undefined,
            };
            break;
          case 'number_input':
            acc[section.id] = section.config?.defaultValue || 0;
            break;
          case 'checkbox':
            acc[section.id] = false;
            break;
          default:
            acc[section.id] = '';
        }
        return acc;
      }, {} as Record<string, any>));
      fetchTimeHistory();
    }
    if (!visible) {
      resetDrawerState();
    }
  }, [visible, task, form, fetchTimeHistory, resetDrawerState, sections]);

  const formatDuration = (seconds: number) => {
    return dayjs.duration(seconds, 'seconds').format('HH:mm:ss');
  };

  function onSubmit(values: FormValues) {
    onComplete(values);
  }

  const renderSection = (section: CompletionDrawerSection, index: number) => {
    switch (section.type) {
      case 'time_tracking':
        return (
          <div className="space-y-4" key={section.id}>
            <FormField
              control={form.control}
              name={`${section.id}.timeSpent`}
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="mb-2">{section.title}</FormLabel>
                  <FormControl>
                    <div className="flex flex-col items-center space-y-1">
                      <div className="flex items-center space-x-2">
                        <Button
                          type="button"
                          variant="outline"
                          size="sm"
                          onClick={() => handleTimeChange(section.id, -10)}
                          className="h-7 text-sm"
                        >
                          --
                        </Button>
                        
                        <Button
                          type="button"
                          variant="outline"
                          onClick={() => handleTimeChange(section.id, -1)}
                        >
                          -
                        </Button>

                        <div className="flex flex-col items-center">
                          <div 
                            className="text-4xl font-semibold w-24 text-center"
                            onClick={() => {
                              const input = document.createElement('input');
                              input.type = 'number';
                              input.value = field.value.toString();
                              input.className = 'text-4xl font-semibold w-24 text-center';
                              input.onblur = (e: FocusEvent) => {
                                const target = e.target as HTMLInputElement;
                                if (target) {
                                  field.onChange(Number(target.value));
                                }
                              };
                            }}
                          >
                            {field.value}
                          </div>
                          <span className="text-sm text-muted-foreground">minutes</span>
                        </div>

                        <Button
                          type="button"
                          variant="outline"
                          onClick={() => handleTimeChange(section.id, 1)}
                        >
                          +
                        </Button>

                        <Button
                          type="button"
                          variant="outline"
                          size="sm"
                          onClick={() => handleTimeChange(section.id, 10)}
                          className="h-7 text-sm"
                        >
                          ++
                        </Button>
                      </div>
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* Time Comment Section */}
            {showTimeComment ? (
              <div className="space-y-4 bg-muted p-4 rounded-lg">
                <div className="flex justify-between items-center mb-4">
                  <h3 className="font-medium">Additional Information</h3>
                  <Button
                    type="button"
                    variant="ghost"
                    size="sm"
                    onClick={() => setShowTimeComment(false)}
                  >
                    <X className="h-4 w-4" />
                  </Button>
                </div>
                <FormField
                  control={form.control}
                  name={`${section.id}.timeReason`}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Reason</FormLabel>
                      <Select 
                        onValueChange={field.onChange} 
                        defaultValue={field.value}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder="Select a reason" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {TIME_REASONS.map(reason => (
                            <SelectItem key={reason.value} value={reason.value}>
                              {reason.label}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name={`${section.id}.timeComment`}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Additional Comments</FormLabel>
                      <FormControl>
                        <Textarea 
                          placeholder="Add any additional context..."
                          className="resize-none"
                          {...field}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
              </div>
            ) : (
              <Button
                type="button"
                variant="outline"
                onClick={() => setShowTimeComment(true)}
                className="w-full"
              >
                Add Comment
              </Button>
            )}

            {/* Time History */}
            {section.config?.showHistory && timeHistory && timeHistory.tracking_history.length > 0 && (
              <div className="space-y-4 bg-muted p-4 rounded-lg">
                <h3 className="font-medium">Time Tracking History:</h3>
                <div className="space-y-4">
                  {timeHistory.tracking_history.map((session, index) => (
                    <div key={session.id} className="space-y-2">
                      <div>
                        Session {index + 1}: {dayjs(session.start_time).format('YYYY-MM-DD HH:mm:ss')}
                      </div>
                      <div className="text-sm text-muted-foreground">
                        Duration: {formatDuration(session.duration)}
                      </div>
                      
                      {session.pauses.length > 0 && (
                        <div className="ml-6 space-y-1">
                          <div className="text-sm text-muted-foreground">Pauses:</div>
                          {session.pauses.map((pause, pIndex) => (
                            <div key={pIndex} className="text-sm text-muted-foreground">
                              {dayjs(pause.start).format('HH:mm:ss')} - 
                              {pause.end ? dayjs(pause.end).format('HH:mm:ss') : 'ongoing'}
                              {pause.duration && ` (${formatDuration(pause.duration)})`}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        );
      case 'number_input':
        return (
          <FormField
            key={section.id}
            control={form.control}
            name={section.id}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{section.title}</FormLabel>
                {section.description && (
                  <p className="text-sm text-muted-foreground">{section.description}</p>
                )}
                <FormControl>
                  <Input 
                    type="number"
                    {...field}
                    onChange={e => field.onChange(Number(e.target.value))}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        );
      case 'checkbox':
        return (
          <FormField
            key={section.id}
            control={form.control}
            name={section.id}
            render={({ field }) => (
              <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <div className="space-y-1 leading-none">
                  <FormLabel>
                    {section.title}
                  </FormLabel>
                </div>
              </FormItem>
            )}
          />
        );
      case 'select':
        return (
          <FormField
            key={section.id}
            control={form.control}
            name={section.id}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{section.title}</FormLabel>
                <FormControl>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder={section.config?.placeholder || 'Select an option'} />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {section.config?.options?.map((option: { label: string; value: string }, oIndex: number) => (
                        <SelectItem key={`${section.id}-${oIndex}`} value={option.value}>
                          {option.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        );
      case 'comment':
        return (
          <FormField
            key={section.id}
            control={form.control}
            name={section.id}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{section.title}</FormLabel>
                <FormControl>
                  <Textarea 
                    placeholder={section.config?.placeholder || 'Enter your comment'}
                    className="resize-none"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        );
      default:
        return null;
    }
  };

  const handleTimeChange = (sectionId: string, delta: number) => {
    const currentValue = form.getValues(`${sectionId}.timeSpent`);
    form.setValue(`${sectionId}.timeSpent`, Math.max(0, currentValue + delta));
  };

  return (
    <Drawer open={visible} onOpenChange={onClose}>
      <DrawerContent>
        <DrawerHeader>
          <DrawerTitle>{title}</DrawerTitle>
          <DrawerDescription>{description}</DrawerDescription>
        </DrawerHeader>

        <div className="px-4 py-4 flex-1 overflow-auto">
          {loading ? (
            <div className="flex justify-center items-center min-h-[200px]">
              <Loader2 className="h-8 w-8 animate-spin" />
            </div>
          ) : (
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
                {sections.map((section, index) => renderSection(section, index))}
              </form>
            </Form>
          )}
        </div>

        <DrawerFooter>
          <Button type="submit" onClick={form.handleSubmit(onSubmit)}>
            Complete
          </Button>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}

export default TaskCompleteDrawer;