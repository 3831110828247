import { useEffect, useState } from 'react'
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from '@/components/ui/dialog'
import { ScrollArea } from '@/components/ui/scroll-area'
import { ArrowRight, Calendar, Clock, MessageSquare, Send, Loader2 } from 'lucide-react'
import axios from '@/api/axiosConfig'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { UserAvatar } from '@/components/user-avatar'
import { Board } from '@/types/custom-board'
import { StoredUser } from '@/types/user'
import { cn } from '@/lib/utils'
import { useUsers } from '@/contexts/UserContext'
import { Button } from '@/components/ui/button'
import { Textarea } from '@/components/ui/textarea'
import { toast } from 'sonner'

dayjs.extend(relativeTime)

interface ActivityChange {
  id: number;
  type: 'progress_status' | 'board_status' | 'assigned_users' | 'work_date' | 'due_date' | 'reply' | 'comment_new' | 'comment_reply' | 'comment_deleted' | 'update';
  old_value: any;
  new_value: any;
  user_id: number;
  timestamp: string;
  task_id: number;
  task_title: string;
  parent_id?: number;
  content?: string;
  deal_name?: string;
  subtask_id?: number;
  subtask_title?: string;
  data?: {
    id: number;
    content?: string;
    user_id: number;
    parent_id?: number;
    timestamp: string;
    type?: string;
    old_value?: any;
    new_value?: any;
  };
}

interface Activity extends ActivityChange {
  changes?: {
    from: any;
    to: any;
  };
}

interface BoardActivityProps {
  open: boolean
  onClose: () => void
  board: Board | null
  taskId?: number
}

export function BoardActivity({ open, onClose, board, taskId }: BoardActivityProps) {
  const { users } = useUsers()
  const [activities, setActivities] = useState<Activity[]>([])
  const [loading, setLoading] = useState(true)
  const [replyingTo, setReplyingTo] = useState<number | null>(null)
  const [replyContent, setReplyContent] = useState('')
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    if (open && board) {
      fetchActivities()
    }
  }, [open, board?.id, taskId])

  const fetchActivities = async () => {
    try {
      setLoading(true)
      const endpoint = taskId 
        ? `/custom-board/tasks/${taskId}/activity`
        : `/custom-board/boards/${board?.id}/activity`
      const response = await axios.get(endpoint)
      
      // Sort activities by timestamp in ascending order (oldest first)
      const sortedActivities = response.data.sort((a: Activity, b: Activity) => {
        const aTimestamp = a.timestamp || a.data?.timestamp || '';
        const bTimestamp = b.timestamp || b.data?.timestamp || '';
        return new Date(bTimestamp).getTime() - new Date(aTimestamp).getTime();
      });
      
      setActivities(sortedActivities)
    } catch (error) {
      console.error('Error fetching activities:', error)
    } finally {
      setLoading(false)
    }
  }

  const handleReply = async (updateId: number) => {
    if (!replyContent.trim()) return

    try {
      setSubmitting(true)
      const response = await axios.post(`/custom-board/tasks/${taskId}/updates/${updateId}/reply`, {
        content: replyContent.trim()
      })
      
      if (response.status === 200) {
        toast.success('Reply added successfully')
        setReplyContent('')
        setReplyingTo(null)
        fetchActivities() // Refresh activities to show the new reply
      }
    } catch (error) {
      console.error('Error adding reply:', error)
      toast.error('Failed to add reply')
    } finally {
      setSubmitting(false)
    }
  }

  const getStatusColor = (statusId: number | string | null, type: 'progress' | 'board'): string => {
    if (!statusId || !board?.board_config) return '#94a3b8'
    
    const options = type === 'progress' 
      ? board.board_config.progress_status_options 
      : board.board_config.board_status_options

    const status = options?.find(opt => opt.id === Number(statusId))
    return status?.color || '#94a3b8'
  }

  const getStatusLabel = (statusId: number | string | null, type: 'progress' | 'board'): string => {
    if (!statusId || !board?.board_config) return 'None'
    
    const options = type === 'progress' 
      ? board.board_config.progress_status_options 
      : board.board_config.board_status_options

    const status = options?.find(opt => opt.id === Number(statusId))
    return status?.label || 'Unknown'
  }

  const renderActivityContent = (activity: Activity) => {
    // Get the old and new values from either format
    const oldValue = activity.changes?.from ?? activity.old_value
    const newValue = activity.changes?.to ?? activity.new_value

    // For comment activities, check both direct and nested data
    const commentContent = activity.data?.content || activity.content || newValue
    const commentTimestamp = activity.data?.timestamp || activity.timestamp

    // Get parent comment content if this is a reply
    const parentCommentActivity = activity.parent_id ? activities.find(a => {
      // Check both direct id and nested id in data
      const matchesId = a.id === activity.parent_id || a.data?.id === activity.parent_id;
      // Only match comments
      const isComment = a.type === 'comment_new' || a.type === 'comment_reply';
      return matchesId && isComment;
    }) : null;

    // Get the actual content from the parent comment
    const parentCommentContent = parentCommentActivity ? (parentCommentActivity.content || parentCommentActivity.data?.content) : null;

    // Common header for all activities
    const activityHeader = (title: string) => (
      <div className="flex items-center justify-between">
        <div className="flex flex-col gap-1">
          <span className="font-medium">{title}</span>
          {/* Add task and deal context */}
          <div className="text-sm text-muted-foreground">
            {activity.task_title && (
              <span>
                Task: {activity.task_title}
                {activity.deal_name && ` (${activity.deal_name})`}
              </span>
            )}
            {activity.subtask_title && (
              <span className="block">
                Subtask: {activity.subtask_title}
              </span>
            )}
          </div>
        </div>
        <span className="text-sm text-muted-foreground">
          {dayjs(activity.timestamp).fromNow()}
        </span>
      </div>
    );

    switch (activity.type) {
      case 'update':
        if (activity.data?.type === 'progress_status') {
          return (
            <div className="space-y-2 rounded-md bg-muted p-3">
              {activityHeader('Progress Status Changed')}
              <div className="flex items-center gap-2">
                <span
                  className="min-w-20 rounded px-2 py-0.5 text-center text-xs font-medium text-white shadow-sm"
                  style={{ backgroundColor: getStatusColor(activity.data.old_value, 'progress') }}
                >
                  {getStatusLabel(activity.data.old_value, 'progress')}
                </span>
                <ArrowRight className="h-4 w-4 text-muted-foreground" />
                <span
                  className="min-w-20 rounded px-2 py-0.5 text-center text-xs font-medium text-white shadow-sm"
                  style={{ backgroundColor: getStatusColor(activity.data.new_value, 'progress') }}
                >
                  {getStatusLabel(activity.data.new_value, 'progress')}
                </span>
              </div>
            </div>
          )
        }
        return null

      case 'comment_new':
        return (
          <div className="space-y-2 rounded-md bg-muted p-3">
            {activityHeader('New Comment')}
            <p className="text-sm whitespace-pre-wrap">{commentContent}</p>
          </div>
        )
      case 'comment_reply':
        return (
          <div className="space-y-2 rounded-md bg-muted p-3">
            {activityHeader('Reply')}
            {parentCommentContent && (
              <div className="text-sm text-muted-foreground bg-background/50 p-2 rounded-sm mb-2">
                <p className="font-medium">Replying to:</p>
                <p className="whitespace-pre-wrap">{parentCommentContent}</p>
              </div>
            )}
            <p className="text-sm whitespace-pre-wrap">{commentContent}</p>
          </div>
        )
      case 'comment_deleted':
        return (
          <div className="space-y-2 rounded-md bg-muted p-3">
            {activityHeader('Comment Deleted')}
            <p className="text-sm whitespace-pre-wrap line-through text-muted-foreground">
              {commentContent}
            </p>
          </div>
        )
      case 'reply':
        return (
          <div className="space-y-2 rounded-md bg-muted p-3">
            {activityHeader('Reply')}
            <p className="text-sm whitespace-pre-wrap">{newValue}</p>
          </div>
        )
      case 'progress_status':
        return (
          <div className="space-y-2 rounded-md bg-muted p-3">
            {activityHeader('Progress Status Changed')}
            <div className="flex items-center gap-2">
              <span
                className="min-w-20 rounded px-2 py-0.5 text-center text-xs font-medium text-white shadow-sm"
                style={{ backgroundColor: getStatusColor(oldValue, 'progress') }}
              >
                {getStatusLabel(oldValue, 'progress')}
              </span>
              <ArrowRight className="h-4 w-4 text-muted-foreground" />
              <span
                className="min-w-20 rounded px-2 py-0.5 text-center text-xs font-medium text-white shadow-sm"
                style={{ backgroundColor: getStatusColor(newValue, 'progress') }}
              >
                {getStatusLabel(newValue, 'progress')}
              </span>
            </div>
          </div>
        )
      case 'board_status':
        return (
          <div className="space-y-2 rounded-md bg-muted p-3">
            {activityHeader('Board Status Changed')}
            <div className="flex items-center gap-2">
              <span
                className="min-w-20 rounded px-2 py-0.5 text-center text-xs font-medium text-white shadow-sm"
                style={{ backgroundColor: getStatusColor(oldValue, 'board') }}
              >
                {getStatusLabel(oldValue, 'board')}
              </span>
              <ArrowRight className="h-4 w-4 text-muted-foreground" />
              <span
                className="min-w-20 rounded px-2 py-0.5 text-center text-xs font-medium text-white shadow-sm"
                style={{ backgroundColor: getStatusColor(newValue, 'board') }}
              >
                {getStatusLabel(newValue, 'board')}
              </span>
            </div>
          </div>
        )
      case 'assigned_users':
        const fromUsers = (Array.isArray(oldValue) ? oldValue : [])
          .map(id => users.find(u => u.user_id.toString() === id.toString())?.name)
          .filter(Boolean)
          .join(', ') || 'None'
        const toUsers = (Array.isArray(newValue) ? newValue : [])
          .map(id => users.find(u => u.user_id.toString() === id.toString())?.name)
          .filter(Boolean)
          .join(', ') || 'None'
        return (
          <div className="space-y-2 rounded-md bg-muted p-3">
            {activityHeader('Assignees Changed')}
            <div className="flex items-center gap-2">
              <span className="line-through text-muted-foreground">
                {fromUsers}
              </span>
              <ArrowRight className="h-4 w-4 text-muted-foreground" />
              <span>{toUsers}</span>
            </div>
          </div>
        )
      case 'work_date':
      case 'due_date':
        const isWorkDate = activity.type === 'work_date'
        return (
          <div className="space-y-2 rounded-md bg-muted p-3">
            {activityHeader(isWorkDate ? 'Work Date Updated' : 'Due Date Updated')}
            <div className="flex items-center gap-2">
              <span className="line-through text-muted-foreground">
                {oldValue ? dayjs(oldValue).format('DD/MM/YYYY') : 'None'}
              </span>
              <ArrowRight className="h-4 w-4 text-muted-foreground" />
              <span>
                {newValue ? dayjs(newValue).format('DD/MM/YYYY') : 'None'}
              </span>
            </div>
          </div>
        )
      default:
        return null
    }
  }

  const isValidUser = (user: StoredUser | undefined): user is StoredUser => {
    return user !== undefined && 'user_id' in user
  }

  const sortedActivities = activities.sort((a, b) => {
    const aTimestamp = a.timestamp || a.data?.timestamp || '';
    const bTimestamp = b.timestamp || b.data?.timestamp || '';
    return new Date(bTimestamp).getTime() - new Date(aTimestamp).getTime();
  });

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="max-h-[90vh] max-w-2xl">
        <DialogHeader>
          <DialogTitle>Recent Activities</DialogTitle>
          <DialogDescription>
            {activities.length} updates in the last 30 days
          </DialogDescription>
        </DialogHeader>

        <ScrollArea className="h-[calc(90vh-160px)] px-4">
          {activities.length > 0 ? (
            <div className="space-y-8 py-4">
              {sortedActivities.map((activity) => {
                // Create a unique key by combining activity.id with timestamp
                const uniqueKey = `${activity.id}-${activity.timestamp}`;
                const user = users.find(u => u.user_id === activity.user_id);
                
                return (
                  <div key={uniqueKey} className="relative pl-8">
                    <div className="absolute left-0 top-0">
                      {isValidUser(user) && <UserAvatar user={user} className="h-6 w-6" />}
                    </div>
                    <div className="space-y-1">
                      <div className="flex items-center justify-between gap-2">
                        <span className="font-medium">
                          {user && `${user.name} ${user.surname}`}
                        </span>
                      </div>
                      {renderActivityContent(activity)}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="flex h-[400px] items-center justify-center">
              <p className="text-muted-foreground">No recent activities found</p>
            </div>
          )}
        </ScrollArea>
      </DialogContent>
    </Dialog>
  )
}
