import { useState, useEffect } from 'react';
import { toast } from 'sonner';
import axios from '@/api/axiosConfig';
import dayjs from 'dayjs';
import {
  FileText,
  User,
  Clock,
  Calendar,
  Users,
  Building2,
  Edit,
  Send,
  Trash2,
  ChevronDown,
  ChevronUp,
  Reply,
  Settings,
  ArrowRight
} from 'lucide-react';

import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogBody, DialogFooter } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Badge } from '@/components/ui/badge';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { UserAvatar } from "@/components/user-avatar";
import { AssigneeSelection } from "@/modals/AssigneeSelection";
import { TaskDateEditor } from '@/components/TaskDateEditor';
import { LoadingSpinner } from "@/components/ui/loading-spinner";
import { Textarea } from "@/components/ui/textarea";
import { dealSoftwareConfig } from '@/configs/DealSoftwareConfig';

import type { SubTask, Board, Comment, Update } from '@/types/custom-board';
import type { CustomTask } from '@/types/custom-task';
import type { StoredUser } from '@/types/user';
import type { ChangeEvent } from 'react';

interface SubtaskViewProps {
  subtask: SubTask;
  task: CustomTask;
  board: Board | null;
  onClose: () => void;
  onTaskUpdate?: (updatedTask: CustomTask) => void;
}

export function SubtaskView({ subtask, task, board, onClose, onTaskUpdate }: SubtaskViewProps) {
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('notes');
  const [users, setUsers] = useState<StoredUser[]>([]);
  const [newComment, setNewComment] = useState('');
  const [replyTo, setReplyTo] = useState<number | null>(null);
  const [expandedThreads, setExpandedThreads] = useState<number[]>([]);
  const [assigneeModalVisible, setAssigneeModalVisible] = useState(false);
  const [localSubtask, setLocalSubtask] = useState<SubTask>(subtask);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('/user');
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
    fetchUsers();
  }, []);

  useEffect(() => {
    const fetchSubtaskDetails = async () => {
      try {
        const response = await axios.get(`/custom-board/subtasks/${subtask.id}`);
        setLocalSubtask(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching subtask details:', error);
        toast.error('Failed to fetch subtask details');
        // Don't unmount on error, just use the initial subtask data
        setLocalSubtask(subtask);
        setLoading(false);
      }
    };
    fetchSubtaskDetails();
  }, [subtask.id, subtask]);

  const handleStatusChange = async (newStatus: string) => {
    // Store the previous status for rollback
    const previousStatus = localSubtask.progress_status_id;
    
    // Optimistically update the local state
    setLocalSubtask(prev => ({
      ...prev,
      progress_status_id: parseInt(newStatus)
    }));

    try {
      const response = await axios.put(`/custom-board/subtasks/${subtask.id}`, {
        progress_status_id: parseInt(newStatus)
      });
      setLocalSubtask(response.data);
      toast.success('Status updated successfully');
    } catch (error) {
      // Revert to previous status on error
      setLocalSubtask(prev => ({
        ...prev,
        progress_status_id: previousStatus
      }));
      console.error('Error updating status:', error);
      toast.error('Failed to update status');
    }
  };

  const handleAssigneeUpdate = async (assignees: string[]) => {
    try {
      const response = await axios.put(`/custom-board/subtasks/${subtask.id}`, {
        assigned_users: assignees
      });
      setLocalSubtask(response.data);
      setAssigneeModalVisible(false);
      toast.success('Assignees updated successfully');
    } catch (error) {
      console.error('Error updating assignees:', error);
      toast.error('Failed to update assignees');
    }
  };

  const handleCommentSubmit = async () => {
    if (!newComment.trim()) return;

    try {
      const response = await axios.post(`/custom-board/subtasks/${subtask.id}/comments`, {
        content: newComment.trim(),
        parent_id: replyTo
      });

      if (response.status === 200) {
        toast.success(replyTo ? 'Reply added successfully' : 'Comment added successfully');
        setNewComment('');
        setReplyTo(null);
        
        // Update local state with new data
        setLocalSubtask(response.data);
      }
    } catch (error) {
      console.error('Error adding comment:', error);
      toast.error('Failed to add comment');
    }
  };

  const handleCommentDelete = async (commentId: number) => {
    try {
      const response = await axios.delete(`/custom-board/subtasks/${subtask.id}/comments/${commentId}`);
      if (response.status === 200) {
        toast.success('Comment deleted successfully');
        
        // Update local state with new data
        setLocalSubtask(response.data);
      }
    } catch (error) {
      console.error('Error deleting comment:', error);
      toast.error('Failed to delete comment');
    }
  };

  const toggleThread = (commentId: number) => {
    setExpandedThreads(prev => 
      prev.includes(commentId) 
        ? prev.filter(id => id !== commentId)
        : [...prev, commentId]
    );
  };

  // Group comments by parent_id
  const groupComments = (comments: Comment[] = []) => {
    const threads: { [key: number]: Comment[] } = {};
    const mainComments: Comment[] = [];

    comments.forEach(comment => {
      if (comment.parent_id) {
        if (!threads[comment.parent_id]) {
          threads[comment.parent_id] = [];
        }
        threads[comment.parent_id].push(comment);
      } else {
        mainComments.push(comment);
      }
    });

    return { mainComments, threads };
  };

  const { mainComments, threads } = groupComments(localSubtask?.comments);

  const renderComment = (comment: Comment, isReply: boolean = false) => {
    const user = users.find(u => u.user_id === comment.user_id);
    const hasReplies = threads[comment.id]?.length > 0;
    const isExpanded = expandedThreads.includes(comment.id);
    const replyCount = threads[comment.id]?.length || 0;

    return (
      <div key={comment.id} className={`flex gap-2 relative group ${isReply ? 'ml-8 mt-2' : ''}`}>
        <UserAvatar 
          user={user || { 
            name: 'Unknown',
            surname: 'User',
            user_id: comment.user_id,
          }} 
          size={32} 
        />
        <div className="flex-1 flex flex-col gap-1 ml-2 pr-8">
          <div className="flex justify-between items-start">
            <p className="text-sm font-medium">
              {user ? `${user.name} ${user.surname}` : `User ${comment.user_id}`}
            </p>
            <div className="flex gap-2">
              {!isReply && (
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => setReplyTo(replyTo === comment.id ? null : comment.id)}
                  className="opacity-0 group-hover:opacity-100 transition-opacity"
                >
                  <Reply className="h-4 w-4" />
                </Button>
              )}
              <Button
                variant="ghost"
                size="icon"
                onClick={() => handleCommentDelete(comment.id)}
                className="opacity-0 group-hover:opacity-100 transition-opacity"
              >
                <Trash2 className="h-4 w-4" />
              </Button>
            </div>
          </div>
          <p className="text-sm whitespace-pre-wrap">{comment.content}</p>
          <p className="text-xs text-muted-foreground">
            {dayjs(comment.timestamp).format('YYYY-MM-DD HH:mm')}
          </p>

          {!isReply && hasReplies && (
            <div className="mt-2">
              <Button
                variant="ghost"
                size="sm"
                onClick={() => toggleThread(comment.id)}
                className="h-auto py-1 px-2 text-xs"
              >
                {isExpanded ? (
                  <>
                    <ChevronUp className="h-3 w-3 mr-1" />
                    Hide {replyCount} {replyCount === 1 ? 'reply' : 'replies'}
                  </>
                ) : (
                  <>
                    <ChevronDown className="h-3 w-3 mr-1" />
                    Show {replyCount} {replyCount === 1 ? 'reply' : 'replies'}
                  </>
                )}
              </Button>
            </div>
          )}

          {!isReply && isExpanded && threads[comment.id]?.map(reply => 
            renderComment(reply, true)
          )}

          {replyTo === comment.id && (
            <div className="mt-2 ml-8">
              <Textarea
                placeholder="Write a reply..."
                value={newComment}
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setNewComment(e.target.value)}
                className="resize-none"
                rows={2}
              />
              <div className="flex justify-end gap-2 mt-2">
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => setReplyTo(null)}
                >
                  Cancel
                </Button>
                <Button
                  size="sm"
                  onClick={handleCommentSubmit}
                  disabled={!newComment.trim()}
                >
                  <Send className="mr-2 h-3 w-3" />
                  Reply
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <LoadingSpinner size="lg" />
      </div>
    );
  }

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent className="max-w-[90vw] h-[90vh] p-0 flex flex-col border-none">
        <div className="sr-only">
          <DialogTitle>Subtask Details</DialogTitle>
          <DialogDescription>View and manage subtask details</DialogDescription>
        </div>

        <div className="flex flex-col h-full overflow-hidden">
          <div className="bg-primary text-primary-foreground p-4 rounded-t-lg shrink-0">
            <div className="flex items-center justify-between mb-4">
              <h2 className="text-lg font-semibold">
                {board?.board_config?.simplify_main_tasks ? (
                  <>
                    {subtask.title}
                    {board?.board_config?.requires_deal && task.deal_id && task.deal_name && (
                      <span className="ml-2">- {task.deal_name}</span>
                    )}
                  </>
                ) : (
                  <>
                    {`${task.title}: ${subtask.title}`}
                    {board?.board_config?.requires_deal && task.deal_id && task.deal_name && (
                      <span className="ml-2">- {task.deal_name}</span>
                    )}
                  </>
                )}
              </h2>
              {board?.require_deal && task.deal_id && (
                <Badge variant="outline" className="text-blue-600">
                  {task.deal_name}
                </Badge>
              )}
            </div>
          </div>

          <div className="flex flex-1 overflow-hidden">
            <div className="flex-[2] flex flex-col overflow-hidden">
              <Tabs value={activeTab} onValueChange={setActiveTab} className="flex flex-col h-full">
                <div className="border-b bg-muted shrink-0">
                  <TabsList className="w-full h-12 bg-transparent">
                    <TabsTrigger value="notes" className="flex-1">
                      <div className="flex items-center gap-2">
                        <FileText className="h-4 w-4" /> Notes & Comments
                      </div>
                    </TabsTrigger>
                    <TabsTrigger value="updates" className="flex-1">
                      <div className="flex items-center gap-2">
                        <Clock className="h-4 w-4" /> Activity Log
                      </div>
                    </TabsTrigger>
                  </TabsList>
                </div>

                <div className="flex-1 overflow-hidden">
                  <ScrollArea className="h-full">
                    <div className="p-4 space-y-4">
                      <TabsContent value="notes" className="mt-0 p-0">
                        <div className="flex flex-col gap-5">
                          {(!mainComments || mainComments.length === 0) ? (
                            <div className="flex justify-center items-center min-h-[100px] rounded-md">
                              <p className="text-muted-foreground">No comments yet</p>
                            </div>
                          ) : (
                            mainComments.map(comment => renderComment(comment))
                          )}
                        </div>
                        {!replyTo && (
                          <div className="flex flex-col gap-2 mt-4">
                            <Textarea
                              placeholder="Write a comment..."
                              value={newComment}
                              onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setNewComment(e.target.value)}
                              className="resize-none"
                              rows={2}
                            />
                            <Button
                              onClick={handleCommentSubmit}
                              disabled={!newComment.trim()}
                              className="self-end"
                            >
                              <Send className="mr-2 h-4 w-4" />
                              Post
                            </Button>
                          </div>
                        )}
                      </TabsContent>

                      <TabsContent value="updates" className="mt-0 p-0">
                        <ScrollArea className="h-full w-full px-5">
                          <div className="space-y-8 py-4">
                            {localSubtask.updates?.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime())
                              .map((update: Update, index: number) => {
                                const user = users.find(u => u.user_id === update.user_id);
                                const isSystem = update.user_id === 'system';

                                return (
                                  <div key={index} className="relative pl-8">
                                    <div className="absolute left-0 top-0">
                                      {isSystem ? (
                                        <div className="h-6 w-6 rounded-full bg-muted flex items-center justify-center">
                                          <Settings className="h-4 w-4 text-muted-foreground" />
                                        </div>
                                      ) : (
                                        <UserAvatar 
                                          user={user || {
                                            name: 'Unknown',
                                            surname: 'User',
                                            user_id: update.user_id
                                          }}
                                          className="h-6 w-6"
                                        />
                                      )}
                                    </div>
                                    <div className="space-y-1">
                                      <span className="text-sm text-muted-foreground">
                                        {isSystem ? 'System' : user ? `${user.name} ${user.surname}` : 'Unknown User'}
                                      </span>
                                      <div className="space-y-2 rounded-md bg-muted p-3">
                                        <div className="flex items-center justify-between">
                                          <span className="font-medium">
                                            {update.type === 'progress_status' && 'Status Changed'}
                                            {update.type === 'assigned_users' && 'Assignees Changed'}
                                            {update.type === 'work_date' && 'Work Date Updated'}
                                            {update.type === 'due_date' && 'Due Date Updated'}
                                          </span>
                                          <span className="text-sm text-muted-foreground">
                                            {dayjs(update.timestamp).fromNow()}
                                          </span>
                                        </div>
                                        <div className="flex items-center gap-2">
                                          {update.type === 'progress_status' && (
                                            <>
                                              <span
                                                className="min-w-20 rounded px-2 py-0.5 text-center text-xs font-medium text-white shadow-sm"
                                                style={{ 
                                                  backgroundColor: board?.subtask_config?.predefined_subtasks
                                                    ?.find(ps => ps.name === subtask.title)
                                                    ?.status_options?.find(so => so.id.toString() === update.old_value?.toString())
                                                    ?.color || '#94a3b8'
                                                }}
                                              >
                                                {board?.subtask_config?.predefined_subtasks
                                                  ?.find(ps => ps.name === subtask.title)
                                                  ?.status_options?.find(so => so.id.toString() === update.old_value?.toString())
                                                  ?.label || 'Unknown'}
                                              </span>
                                              <ArrowRight className="h-4 w-4 text-muted-foreground" />
                                              <span
                                                className="min-w-20 rounded px-2 py-0.5 text-center text-xs font-medium text-white shadow-sm"
                                                style={{ 
                                                  backgroundColor: board?.subtask_config?.predefined_subtasks
                                                    ?.find(ps => ps.name === subtask.title)
                                                    ?.status_options?.find(so => so.id.toString() === update.new_value?.toString())
                                                    ?.color || '#94a3b8'
                                                }}
                                              >
                                                {board?.subtask_config?.predefined_subtasks
                                                  ?.find(ps => ps.name === subtask.title)
                                                  ?.status_options?.find(so => so.id.toString() === update.new_value?.toString())
                                                  ?.label || 'Unknown'}
                                              </span>
                                            </>
                                          )}
                                          {update.type === 'assigned_users' && (
                                            <>
                                              <span className="line-through text-muted-foreground">
                                                {Array.isArray(update.old_value) && update.old_value.length > 0
                                                  ? update.old_value
                                                      .map(id => users.find(u => u.user_id.toString() === id.toString())?.name)
                                                      .filter(Boolean)
                                                      .join(', ')
                                                  : 'None'}
                                              </span>
                                              <ArrowRight className="h-4 w-4 text-muted-foreground" />
                                              <span>
                                                {Array.isArray(update.new_value) && update.new_value.length > 0
                                                  ? update.new_value
                                                      .map(id => users.find(u => u.user_id.toString() === id.toString())?.name)
                                                      .filter(Boolean)
                                                      .join(', ')
                                                  : 'None'}
                                              </span>
                                            </>
                                          )}
                                          {(update.type === 'work_date' || update.type === 'due_date') && (
                                            <>
                                              <span className="line-through text-muted-foreground">
                                                {update.old_value ? dayjs(update.old_value).format('DD/MM/YYYY') : 'None'}
                                              </span>
                                              <ArrowRight className="h-4 w-4 text-muted-foreground" />
                                              <span>
                                                {update.new_value ? dayjs(update.new_value).format('DD/MM/YYYY') : 'None'}
                                              </span>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                            })}
                            {(!localSubtask.updates || localSubtask.updates.length === 0) && (
                              <div className="flex justify-center items-center min-h-[100px] rounded-lg p-5">
                                <p className="text-sm text-muted-foreground">
                                  No activity recorded yet
                                </p>
                              </div>
                            )}
                          </div>
                        </ScrollArea>
                      </TabsContent>
                    </div>
                  </ScrollArea>
                </div>
              </Tabs>
            </div>

            <div className="w-[400px] border-l overflow-hidden">
              <ScrollArea className="h-[calc(90vh-5rem)]">
                <div className="p-4 pb-8 space-y-6">
                  <Card>
                    <CardHeader>
                      <CardTitle className="flex items-center gap-2">
                        <FileText className="h-5 w-5" />
                        Subtask
                      </CardTitle>
                    </CardHeader>
                    <CardContent className="space-y-4">
                      {/* Status */}
                      <div className="space-y-2">
                        <div className="flex items-center gap-2 text-sm text-muted-foreground">
                          <Clock className="h-4 w-4" />
                          Status
                        </div>
                        <Select 
                          value={localSubtask.progress_status_id?.toString() || ''}
                          onValueChange={handleStatusChange}
                        >
                          <SelectTrigger className="w-full">
                            <SelectValue placeholder="Select status">
                              {board?.subtask_config?.predefined_subtasks
                                ?.find(ps => ps.name === localSubtask.title)
                                ?.status_options?.find(so => so.id.toString() === localSubtask.progress_status_id?.toString())
                                ? (
                                  <div className="flex items-center gap-2">
                                    <div 
                                      className="w-3 h-3 rounded-full" 
                                      style={{ backgroundColor: board?.subtask_config?.predefined_subtasks
                                        ?.find(ps => ps.name === localSubtask.title)
                                        ?.status_options?.find(so => so.id.toString() === localSubtask.progress_status_id?.toString())
                                        ?.color }}
                                    />
                                    {board?.subtask_config?.predefined_subtasks
                                      ?.find(ps => ps.name === localSubtask.title)
                                      ?.status_options?.find(so => so.id.toString() === localSubtask.progress_status_id?.toString())
                                      ?.label}
                                  </div>
                                ) : null}
                            </SelectValue>
                          </SelectTrigger>
                          <SelectContent>
                            {board?.subtask_config?.predefined_subtasks
                              ?.find(ps => ps.name === localSubtask.title)
                              ?.status_options?.map(status => (
                                <SelectItem key={status.id} value={status.id.toString()}>
                                  <div className="flex items-center gap-2">
                                    <div 
                                      className="w-3 h-3 rounded-full" 
                                      style={{ backgroundColor: status.color }}
                                    />
                                    {status.label}
                                  </div>
                                </SelectItem>
                              ))}
                          </SelectContent>
                        </Select>
                      </div>

                      {/* Assignees */}
                      <div className="space-y-2">
                        <div className="flex items-center justify-between">
                          <div className="flex items-center gap-2 text-sm text-muted-foreground">
                            <Users className="h-4 w-4" />
                            Assignees
                          </div>
                          <Button
                            variant="ghost"
                            size="sm"
                            className="h-8 px-2"
                            onClick={() => setAssigneeModalVisible(true)}
                          >
                            <Edit className="h-4 w-4" />
                          </Button>
                        </div>
                        <div className="flex flex-wrap gap-2">
                          {localSubtask.assigned_users?.map((userId) => {
                            const user = users.find(u => u.user_id.toString() === userId);
                            if (!user) return null;
                            return (
                              <div key={userId} className="flex items-center gap-2 bg-secondary rounded-lg px-2 py-1">
                                <UserAvatar user={user} size={24} />
                                <span className="text-sm">{user.name}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>

                      {/* Dates */}
                      <div className="grid grid-cols-2 gap-4">
                        {/* Work Date */}
                        <div className="space-y-2">
                          <div className="flex items-center gap-2 text-sm text-muted-foreground">
                            <Clock className="h-4 w-4" />
                            Work Date
                          </div>
                          <TaskDateEditor
                            date={localSubtask.work_date || null}
                            dateType="work"
                            taskId={localSubtask.id}
                            taskType="custom"
                            className="w-full"
                            onSuccess={(updatedSubtask) => {
                              const oldWorkDate = localSubtask.work_date;
                              const updatedLocalSubtask = {
                                ...localSubtask,
                                work_date: updatedSubtask.work_date,
                                updates: [
                                  ...(localSubtask.updates || []),
                                  {
                                    id: (localSubtask.updates?.length || 0) + 1,
                                    user_id: 'system',
                                    type: 'work_date',
                                    old_value: oldWorkDate,
                                    new_value: updatedSubtask.work_date,
                                    timestamp: new Date().toISOString()
                                  } as Update
                                ]
                              };
                              setLocalSubtask(updatedLocalSubtask);
                              
                              // Notify parent component of the update
                              if (task && onTaskUpdate) {
                                const updatedTask = {
                                  ...task,
                                  subtasks: task.subtasks?.map((st: SubTask) => 
                                    st.id === localSubtask.id ? {
                                      ...updatedLocalSubtask,
                                      progress_status: board?.subtask_config?.predefined_subtasks
                                        ?.find(ps => ps.name === updatedLocalSubtask.title)
                                        ?.status_options?.find(so => so.id === updatedLocalSubtask.progress_status_id)
                                        ?.label || 'Not Started'
                                    } : st
                                  )
                                };
                                onTaskUpdate(updatedTask);
                              }
                            }}
                          />
                        </div>

                        {/* Due Date */}
                        <div className="space-y-2">
                          <div className="flex items-center gap-2 text-sm text-muted-foreground">
                            <Calendar className="h-4 w-4" />
                            Due Date
                          </div>
                          <TaskDateEditor
                            date={localSubtask.due_date || null}
                            dateType="due"
                            taskId={localSubtask.id}
                            taskType="custom"
                            className="w-full"
                            onSuccess={(updatedSubtask) => {
                              const oldDueDate = localSubtask.due_date;
                              const updatedLocalSubtask = {
                                ...localSubtask,
                                due_date: updatedSubtask.due_date,
                                updates: [
                                  ...(localSubtask.updates || []),
                                  {
                                    id: (localSubtask.updates?.length || 0) + 1,
                                    user_id: 'system',
                                    type: 'due_date',
                                    old_value: oldDueDate,
                                    new_value: updatedSubtask.due_date,
                                    timestamp: new Date().toISOString()
                                  } as Update
                                ]
                              };
                              setLocalSubtask(updatedLocalSubtask);
                              
                              // Notify parent component of the update
                              if (task && onTaskUpdate) {
                                const updatedTask = {
                                  ...task,
                                  subtasks: task.subtasks?.map((st: SubTask) => 
                                    st.id === localSubtask.id ? {
                                      ...updatedLocalSubtask,
                                      progress_status: board?.subtask_config?.predefined_subtasks
                                        ?.find(ps => ps.name === updatedLocalSubtask.title)
                                        ?.status_options?.find(so => so.id === updatedLocalSubtask.progress_status_id)
                                        ?.label || 'Not Started'
                                    } : st
                                  )
                                };
                                onTaskUpdate(updatedTask);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </CardContent>
                  </Card>

                  {/* Deal Card (if required) */}
                  {board?.require_deal && task.deal_id && task.deal_data && (
                    <Card>
                      <CardHeader>
                        <CardTitle className="flex items-center gap-2">
                          <Building2 className="h-5 w-5" />
                          Deal
                        </CardTitle>
                      </CardHeader>
                      <CardContent className="space-y-4">
                        <div className="grid grid-cols-2 gap-4">
                          <div>
                            <span className="text-sm text-muted-foreground">Name</span>
                            <p>{task.deal_name || 'N/A'}</p>
                          </div>
                          <div>
                            <span className="text-sm text-muted-foreground">CVR</span>
                            <p>{task.deal_data.cvr || 'N/A'}</p>
                          </div>
                          <div>
                            <span className="text-sm text-muted-foreground">Address</span>
                            <p>{task.deal_data.address || 'N/A'}</p>
                          </div>
                          <div>
                            <span className="text-sm text-muted-foreground">Customer Number</span>
                            <p>{task.deal_data.economic_customer_number || 'N/A'}</p>
                          </div>
                          {task.deal_software && (
                            <div className="col-span-2">
                              <span className="text-sm text-muted-foreground">Software</span>
                              <div className="mt-1 flex flex-wrap gap-2">
                                {Object.entries(task.deal_software).map(([key, value]) => {
                                  const config = dealSoftwareConfig.find(c => c.key === key);
                                  if (!config || !value) return null;
                                  
                                  return (
                                    <Badge key={key} variant="outline">
                                      {config.label}: {Array.isArray(value) ? value.join(', ') : value}
                                    </Badge>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </div>
                      </CardContent>
                    </Card>
                  )}
                </div>
              </ScrollArea>
            </div>
          </div>
        </div>

        {/* Assignee Selection Modal */}
        <AssigneeSelection
          visible={assigneeModalVisible}
          onClose={() => setAssigneeModalVisible(false)}
          onSave={handleAssigneeUpdate}
          initialAssignees={localSubtask.assigned_users?.map(id => id.toString()) || []}
        />
      </DialogContent>
    </Dialog>
  );
} 