interface BaseProduct {
  name: string;
  code: string;
  defaultPrice: number;
}

interface DealProduct extends BaseProduct {
  frequencies: string[];
}

interface InvoiceProduct extends BaseProduct {
  type: 'invoice';
}

export const dealProductConfig = {
  products: [
    { 
      name: 'Bogføring', 
      code: '1001',
      defaultPrice: 995,
      frequencies: ['monthly', 'quarterly', 'annually']
    },
    { 
      name: 'Software', 
      code: '1002',
      defaultPrice: 0,
      frequencies: ['monthly', 'quarterly', 'annually', 'one-time']
    },
    { 
      name: 'Onboarding', 
      code: '1008',
      defaultPrice: 0,
      frequencies: ['one-time']
    },
    { 
      name: 'Lønadministration', 
      code: '1009',
      defaultPrice: 100,
      frequencies: ['monthly']
    },
    { 
      name: 'Holdingselskaber', 
      code: '1010',
      defaultPrice: 400,
      frequencies: ['monthly', 'annually']
    },
    { 
      name: 'Årsregnskab', 
      code: '1007',
      defaultPrice: 0,
      frequencies: ['annually', 'one-time']
    }
  ] as DealProduct[]
}

export const invoiceProductConfig = {
  products: [
    { name: 'Bogføring', code: '1001', defaultPrice: 995 },
    { name: 'Software', code: '1002', defaultPrice: 0 },
    { name: 'Erhvervskonto', code: '1004', defaultPrice: 0 },
    { name: 'Stiftelse af selskab', code: '1005', defaultPrice: 0 },
    { name: 'Årsregnskab', code: '1007', defaultPrice: 0 },
    { name: 'Onboarding', code: '1008', defaultPrice: 0 },
    { name: 'Lønadministration', code: '1009', defaultPrice: 100 },
    { name: 'Holding', code: '1010', defaultPrice: 400 },
    { name: 'Rådgivning', code: '1011', defaultPrice: 0 },
    { name: 'E-conomic', code: '1012', defaultPrice: 0 },
    { name: 'Genoptagelse', code: '1013', defaultPrice: 0 },
    { name: 'Gebyr til erhvervsstyrelsen', code: '1014', defaultPrice: 0 },
    { name: 'Ejerskifte', code: '1015', defaultPrice: 0 },
    { name: 'Fakturaservice', code: '1016', defaultPrice: 0 },
    { name: 'Administrationsgebyr', code: '1017', defaultPrice: 0 },
    { name: 'Rykkere', code: '9998', defaultPrice: 0 },
    { name: 'Dinero Pro', code: '9999', defaultPrice: 0 }
  ] as InvoiceProduct[]
}

export type { DealProduct, InvoiceProduct }