import * as React from "react"
import { ArrowUpDown, Filter } from "lucide-react"
import { useRef, useEffect, useState } from "react"

import { cn } from "@/lib/utils"
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
  PaginationEllipsis,
} from "@/components/ui/pagination"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { ScrollArea } from "@/components/ui/scroll-area"
import { Command, CommandGroup, CommandItem, CommandInput } from "@/components/ui/command"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { Badge } from "@/components/ui/badge"
import { Check } from "lucide-react"
import { Button } from "@/components/ui/button"

export interface ColumnDef<T> {
  key: string
  title: string
  dataIndex: keyof T
  cell?: (props: { row: { original: T } }) => React.ReactNode
  render?: (value: any) => React.ReactNode
  sortable?: {
    enabled: boolean
    type?: 'string' | 'number' | 'date' | 'custom'
    compare?: (a: T, b: T) => number
  }
  filterable?: boolean
  filters?: { text: string; value: string }[]
  onFilter?: {
    (value: string, record: T): boolean;
  } | {
    (values: string[], record: T): boolean;
  }
  multiSelect?: boolean
}

interface TableProps<T> extends React.HTMLAttributes<HTMLTableElement> {
  data: T[]
  columns: ColumnDef<T>[]
  pageSize?: number
  onPageChange?: (page: number) => void
  onPageSizeChange?: (size: number) => void
  currentPage?: number
  onSort?: (sortedData: T[]) => void
  onRowClick?: (row: T) => void
  alwaysShowPageSize?: boolean
}

const Table = React.forwardRef<HTMLTableElement, TableProps<any>>(
  ({ className, data, columns, pageSize = 15, currentPage = 1, onPageChange, onPageSizeChange, onSort, onRowClick, alwaysShowPageSize, ...props }, ref) => {
    const [sortConfig, setSortConfig] = React.useState<{ key: string; direction: 'asc' | 'desc' } | null>(null);
    const [filters, setFilters] = React.useState<Record<string, any[]>>({});
    const [localData, setLocalData] = React.useState(data);
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const [showLeftShadow, setShowLeftShadow] = useState(false);
    const [showRightShadow, setShowRightShadow] = useState(false);

    // Separate useEffect for data changes
    React.useEffect(() => {
      if (data) {
        applyFiltersAndSort(data);
      }
    }, [data]);

    // Separate useEffect for filter changes
    React.useEffect(() => {
      if (data) {
        applyFiltersAndSort(data);
      }
    }, [filters]);

    const applyFiltersAndSort = (dataToProcess: any[]) => {
      let processedData = [...dataToProcess];

      // Apply filters
      Object.keys(filters).forEach(key => {
        const col = columns.find(c => c.key === key);
        if (col?.onFilter && filters[key]?.length > 0) {
          if (col.multiSelect) {
            processedData = processedData.filter(record => 
              (col.onFilter as (values: any[], record: any) => boolean)(filters[key], record)
            );
          } else {
            processedData = processedData.filter(record => 
              (col.onFilter as (value: any, record: any) => boolean)(filters[key][0], record)
            );
          }
        }
      });

      // Apply sorting if active
      if (sortConfig) {
        const column = columns.find(c => c.key === sortConfig.key);
        if (column?.sortable?.enabled) {
          processedData.sort((a, b) => {
            let result;
            if (column.sortable?.compare) {
              result = column.sortable.compare(a, b);
            } else {
              const aValue = a[column.dataIndex];
              const bValue = b[column.dataIndex];
              switch (column.sortable?.type) {
                case 'number':
                  result = (Number(aValue) || 0) - (Number(bValue) || 0);
                  break;
                case 'date':
                  result = new Date(aValue || 0).getTime() - new Date(bValue || 0).getTime();
                  break;
                default:
                  result = String(aValue || '').localeCompare(String(bValue || ''));
              }
            }
            return sortConfig.direction === 'asc' ? result : -result;
          });
        }
      }

      setLocalData(processedData);

      // Check if we need to reset pagination
      const totalPages = Math.ceil(processedData.length / pageSize);
      if (currentPage > 1 && currentPage > totalPages) {
        onPageChange?.(1);
      }
    };

    const handleFilter = (column: ColumnDef<any>, values: string[]) => {
      setFilters(prevFilters => {
        const newFilters = { ...prevFilters };
        
        if (values.includes("clear")) {
          delete newFilters[column.key];
        } else {
          const filterValues = values.map(value => {
            if (value === "null") return null;
            return column.filters?.find(f => f.value?.toString() === value)?.value;
          }).filter(value => value !== undefined);
          
          if (filterValues.length > 0) {
            newFilters[column.key] = filterValues;
          } else {
            delete newFilters[column.key];
          }
        }
        
        return newFilters;
      });
    };

    const handleSort = (column: ColumnDef<any>) => {
      if (!column.sortable?.enabled) return;

      const newSortConfig: { key: string; direction: 'asc' | 'desc' } = {
        key: column.key,
        direction: sortConfig?.key === column.key && sortConfig.direction === 'asc' ? 'desc' : 'asc'
      };
      
      setSortConfig(newSortConfig);
      
      const sortedData = [...localData].sort((a, b) => {
        let result;
        if (column.sortable?.compare) {
          result = column.sortable.compare(a, b);
        } else {
          const aValue = a[column.dataIndex];
          const bValue = b[column.dataIndex];
          switch (column.sortable?.type) {
            case 'number':
              result = (Number(aValue) || 0) - (Number(bValue) || 0);
              break;
            case 'date':
              result = new Date(aValue || 0).getTime() - new Date(bValue || 0).getTime();
              break;
            default:
              result = String(aValue || '').localeCompare(String(bValue || ''));
          }
        }
        return newSortConfig.direction === 'asc' ? result : -result;
      });

      setLocalData(sortedData);
      onSort?.(sortedData);
    };

    // Add filter dropdown to the table header
    const renderFilterDropdown = (column: ColumnDef<any>) => {
      if (!column.filterable || !column.filters) return null;

      const currentValues = column.key in filters ? filters[column.key] : [];

      return (
        <Popover>
          <PopoverTrigger asChild>
            <Button
              variant="ghost"
              size="icon"
              className={cn(
                "h-8 w-8 p-0 hover:bg-muted/50 rounded-sm text-primary-foreground",
                currentValues.length > 0 && "bg-primary/10"
              )}
            >
              <Filter className={cn(
                "h-4 w-4",
                currentValues.length > 0 ? "text-primary-foreground" : "text-primary-foreground/50"
              )} />
              {currentValues.length > 0 && (
                <Badge
                  variant="secondary"
                  className="absolute -top-1 -right-1 h-4 w-4 p-0 flex items-center justify-center text-xs"
                >
                  {currentValues.length}
                </Badge>
              )}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-[200px] p-0" align="start">
            <Command className="max-h-[300px] overflow-hidden">
              <CommandInput placeholder="Search filters..." className="border-0" />
              <ScrollArea className="overflow-auto">
                <CommandGroup>
                  <CommandItem
                    onSelect={() => handleFilter(column, ["clear"])}
                    className="justify-between"
                  >
                    Clear filter
                    {currentValues.length === 0 && <Check className="h-4 w-4" />}
                  </CommandItem>
                  {column.filters.map((filter) => {
                    const value = filter.value?.toString() ?? "null";
                    const isSelected = currentValues.includes(filter.value);
                    return (
                      <CommandItem
                        key={value}
                        onSelect={() => {
                          if (!column.multiSelect) {
                            // For non-multiSelect columns, only allow one value
                            handleFilter(column, [value]);
                            return;
                          }
                          const newValues = isSelected
                            ? currentValues.filter(v => v !== filter.value)
                            : [...currentValues, filter.value];
                          handleFilter(column, newValues.length ? newValues.map(v => v?.toString() ?? "null") : ["clear"]);
                        }}
                        className="justify-between"
                      >
                        {filter.text}
                        {isSelected && <Check className="h-4 w-4" />}
                      </CommandItem>
                    );
                  })}
                </CommandGroup>
              </ScrollArea>
            </Command>
          </PopoverContent>
        </Popover>
      );
    };

    // Use localData for pagination instead of data
    const totalPages = Math.ceil(localData.length / pageSize);
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const currentData = localData.slice(startIndex, endIndex);

    const renderPaginationItems = () => {
      const items = [];
      const maxPagesOnEachSide = 1;
      
      // Previous button
      items.push(
        <PaginationItem key="prev">
          <PaginationPrevious 
            onClick={() => onPageChange?.(currentPage - 1)}
            className={cn(
              "bg-background hover:bg-muted",
              currentPage === 1 && "pointer-events-none opacity-50"
            )}
          />
        </PaginationItem>
      );

      // Calculate page range
      let startPage = Math.max(1, currentPage - maxPagesOnEachSide);
      let endPage = Math.min(totalPages, currentPage + maxPagesOnEachSide);

      // Add first page and ellipsis if necessary
      if (startPage > 1) {
        items.push(
          <PaginationItem key="first">
            <PaginationLink
              onClick={() => onPageChange?.(1)}
              className="bg-background hover:bg-muted"
            >
              1
            </PaginationLink>
          </PaginationItem>
        );
        if (startPage > 2) {
          items.push(<PaginationEllipsis key="ellipsis-start" />);
        }
      }

      // Add page numbers
      for (let page = startPage; page <= endPage; page++) {
        items.push(
          <PaginationItem key={`page-${page}`}>
            <PaginationLink
              onClick={() => onPageChange?.(page)}
              isActive={currentPage === page}
              className={cn(
                "bg-background hover:bg-muted",
                currentPage === page && "bg-primary text-primary-foreground hover:bg-primary/90"
              )}
            >
              {page}
            </PaginationLink>
          </PaginationItem>
        );
      }

      // Add last page and ellipsis if necessary
      if (endPage < totalPages) {
        if (endPage < totalPages - 1) {
          items.push(<PaginationEllipsis key="ellipsis-end" />);
        }
        items.push(
          <PaginationItem key="last">
            <PaginationLink
              onClick={() => onPageChange?.(totalPages)}
              className="bg-background hover:bg-muted"
            >
              {totalPages}
            </PaginationLink>
          </PaginationItem>
        );
      }

      // Next button
      items.push(
        <PaginationItem key="next">
          <PaginationNext 
            onClick={() => onPageChange?.(currentPage + 1)}
            className={cn(
              "bg-background hover:bg-muted",
              currentPage === totalPages && "pointer-events-none opacity-50"
            )}
          />
        </PaginationItem>
      );

      return items;
    };

    const updateShadows = () => {
      const container = scrollContainerRef.current?.querySelector('[data-radix-scroll-area-viewport]');
      if (container) {
        const { scrollLeft, scrollWidth, clientWidth } = container as HTMLDivElement;
        setShowLeftShadow(scrollLeft > 0);
        setShowRightShadow(scrollLeft < scrollWidth - clientWidth - 1);
      }
    };

    useEffect(() => {
      const container = scrollContainerRef.current?.querySelector('[data-radix-scroll-area-viewport]');
      if (container) {
        container.addEventListener('scroll', updateShadows);
        // Initial check
        updateShadows();
        
        // Check again after a brief delay to account for dynamic content
        const timeoutId = setTimeout(updateShadows, 100);

        return () => {
          container.removeEventListener('scroll', updateShadows);
          clearTimeout(timeoutId);
        };
      }
    }, []);

    return (
      <div className="w-full flex flex-col gap-4">
        <div className="relative w-full rounded-md">
          <div ref={scrollContainerRef}>
            <ScrollArea className="w-full rounded-md">
              <div className="min-w-[600px]">
                <table
                  ref={ref}
                  className={cn("w-full caption-bottom text-sm", className)}
                  {...props}
                >
                  <TableHeader>
                    <TableRow>
                      {columns.map((column) => (
                        <TableHead
                          key={column.key}
                          onClick={() => column.sortable?.enabled && handleSort(column)}
                          className={cn(
                            "group",
                            column.sortable?.enabled && "cursor-pointer select-none",
                            sortConfig?.key === column.key && "bg-primary/10"
                          )}
                        >
                          <div className="flex items-center justify-between gap-2">
                            <span>{column.title}</span>
                            <div className="flex items-center gap-1">
                              {column.filterable && renderFilterDropdown(column)}
                              {column.sortable?.enabled && (
                                <ArrowUpDown 
                                  className={cn(
                                    "h-3 w-3 text-primary-foreground",
                                    sortConfig?.key === column.key 
                                      ? "opacity-100" 
                                      : "opacity-50 group-hover:opacity-100",
                                    "transition-opacity"
                                  )}
                                />
                              )}
                            </div>
                          </div>
                        </TableHead>
                      ))}
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {currentData.map((row, rowIndex) => (
                      <TableRow 
                        key={rowIndex}
                        onClick={() => onRowClick?.(row)}
                        className={cn(
                          onRowClick && "cursor-pointer",
                          "border-b border-muted transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted"
                        )}
                      >
                        {columns.map((column) => (
                          <TableCell key={column.key}>
                            {column.cell 
                              ? column.cell({ row: { original: row } })
                              : column.render 
                              ? column.render(row[column.dataIndex]) 
                              : row[column.dataIndex]}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </table>
              </div>
            </ScrollArea>
          </div>
          
          {/* Shadow indicators */}
          {showLeftShadow && (
            <div className="absolute left-0 top-0 bottom-0 w-8 pointer-events-none
              bg-gradient-to-r from-background/50 to-transparent z-10" />
          )}
          {showRightShadow && (
            <div className="absolute right-0 top-0 bottom-0 w-8 pointer-events-none
              bg-gradient-to-l from-background/50 to-transparent z-10" />
          )}
        </div>

        {(data.length > pageSize || alwaysShowPageSize) && (
          <div className="flex items-center justify-between px-2">
            {data.length > pageSize && (
              <Pagination className="text-foreground">
                <PaginationContent>
                  {renderPaginationItems()}
                </PaginationContent>
              </Pagination>
            )}

            <div className={cn(
              "flex items-center gap-2",
              data.length <= pageSize && "ml-auto"
            )}>
              <span className="text-sm text-foreground">Rows per page:</span>
              <Select
                value={pageSize.toString()}
                onValueChange={(value) => onPageSizeChange?.(Number(value))}
              >
                <SelectTrigger className="w-[100px] text-foreground">
                  <span className="text-foreground">{pageSize}</span>
                </SelectTrigger>
                <SelectContent>
                  {[15, 30, 50, 100].map((size) => (
                    <SelectItem 
                      key={size} 
                      value={size.toString()}
                      className="text-foreground"
                    >
                      {size}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </div>
        )}
      </div>
    );
  }
);
Table.displayName = "Table"

const TableHeader = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <thead 
    ref={ref} 
    className={cn(
      "bg-primary",
      "first:[&_tr_th:first-child]:rounded-tl-md",
      "first:[&_tr_th:last-child]:rounded-tr-md",
      "[&_tr]:border-b border-muted",
      className
    )} 
    {...props} 
  />
))
TableHeader.displayName = "TableHeader"

const TableBody = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tbody
    ref={ref}
    className={cn("[&_tr:last-child]:border-0", className)}
    {...props}
  />
))
TableBody.displayName = "TableBody"

const TableFooter = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tfoot
    ref={ref}
    className={cn(
      "border-t bg-muted/50 font-medium [&>tr]:last:border-b-0",
      className
    )}
    {...props}
  />
))
TableFooter.displayName = "TableFooter"

const TableRow = React.forwardRef<
  HTMLTableRowElement,
  React.HTMLAttributes<HTMLTableRowElement>
>(({ className, ...props }, ref) => (
  <tr
    ref={ref}
    className={cn(
      "border-b border-muted transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted",
      className
    )}
    {...props}
  />
))
TableRow.displayName = "TableRow"

const TableHead = React.forwardRef<
  HTMLTableCellElement,
  React.ThHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <th
    ref={ref}
    className={cn(
      "h-10 px-2 text-left align-middle font-medium text-primary-foreground",
      "first:rounded-tl-md last:rounded-tr-md",
      "[&:has([role=checkbox])]:pr-0 [&>[role=checkbox]]:translate-y-[2px]",
      className
    )}
    {...props}
  />
))
TableHead.displayName = "TableHead"

const TableCell = React.forwardRef<
  HTMLTableCellElement,
  React.TdHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <td
    ref={ref}
    className={cn(
      "p-2 align-middle text-foreground [&:has([role=checkbox])]:pr-0 [&>[role=checkbox]]:translate-y-[2px]",
      className
    )}
    {...props}
  />
))
TableCell.displayName = "TableCell"

const TableCaption = React.forwardRef<
  HTMLTableCaptionElement,
  React.HTMLAttributes<HTMLTableCaptionElement>
>(({ className, ...props }, ref) => (
  <caption
    ref={ref}
    className={cn("mt-4 text-sm text-muted-foreground", className)}
    {...props}
  />
))
TableCaption.displayName = "TableCaption"

export {
  Table,
  TableHeader,
  TableBody,
  TableFooter,
  TableHead,
  TableRow,
  TableCell,
  TableCaption,
}
